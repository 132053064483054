import React from "react";

const Menu = () => {
  return (
    <div className="menu">
      <img src="/images/hamburger.png" alt="menu" />
    </div>
  );
};

export default Menu;
