import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import classes from "./index.module.scss";
import ClockIcon from "../../icons/Clock2";
import CalenderIcon from "../../icons/Calendar2";
import StadiumIcon from "../../icons/Stadium2";
import Tick2 from "../../icons/Tick2";
import DeleteIcon from "../../assets/delete.png";
import PlusIcon from "../../assets/plus.png";
import PowerPlayIcon from "../../assets/token.png";
import DeleteIcon2 from "../../assets/delete.svg";
import checkIcon from "../../assets/check2.svg"
import StarIcon from "../../icons/Star";
import TDShirtImage from "../../assets/td.png";
import TDBadge from "../../assets/tdBadge.png";
import ForwardArrow from "../../icons/ForwardArrow";
import { isEmpty } from "lodash";
// import NHLTeamStats from "./NHLTeamStats";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import Star3 from '../../assets/star_player/star_3.png'
import Star2 from '../../assets/star_player/star_2.png'
import Star1 from '../../assets/star_player/star_1.png'
function SportsTeamSelectionCard(props) {
  const selector_all_data = useSelector((state) => state?.mlb?.allData);
  const [currentStep, setCurrentStep] = useState(0);
  const {
    item = {},
    loading = false,
    onSelectDeselect = (id, matchId) => {},
    disabled = false,
    isSelected = false,
    btnTitle = "+ Select",
    btnIcon = "",
    mlbCard = false,
    image,
    
  } = props || {};
  const {
    id = "",
    name = "",
    market = "",
    nhl_team_season_stats: awayTeamStats = [],
    steps = [],
    playerStats = {},
    match_id,
    venue = {},
    teamStats = {},
    teamB = {},
    matchVenue = {},
    matchScheduled = "",
    is_starTeamD,
    is_starCategory=0
  } = item || {};
  const isMobile = useMediaQuery({ query: "(max-width: 414px)" });
  const { name: stadium = "" } = matchVenue || {};
  const { name: teamBName } = teamB;
  const date = moment.utc(matchScheduled).local().format("yyyy-MM-DD"),
    time = moment.utc(matchScheduled).local().format("h:mm A");

    const RenderMLBState = (team_action) => {
      
      let match_id = props?.item?.match_id;
      let team_id = props?.item?.id;
      let pitcherDetailsArray = selector_all_data.filter(x => {
        return ( (x.type == "p" || x.type == "P"))
      });
      let a;
      if (team_action.team == "home") {
        if(pitcherDetailsArray.find(x => x?.team?.name == team_action.name)) {
            a = pitcherDetailsArray.find(x => (x.team?.name == team_action.name&&x.isprojectedPitcher));
          // a=pitcherDetailsArray.find((page1) => props?.projectedPitcher.find(page2 => (page1?.isprojectedPitcher&&page1.team?.name == team_action.name )) )
          // pitcherDetailsArray.map((page1) => props?.projectedPitcher.map(page2 => {if(page1?.match?.id  === page2?.matchId ){
       
          // a = pitcherDetailsArray.find(x => props?.projectedPitcher.find(y=> (x?.match?.id ===y?.matchId&&x.team?.name == team_action.name)));
          if(!a){
          a = pitcherDetailsArray.find(x => x.team?.name == team_action.name);
          }
        }
      }
      if (team_action.team == "away") {
        if(pitcherDetailsArray.find(x => x?.team?.name == team_action.name)) {
          a = pitcherDetailsArray.find(x => (x.team?.name == team_action.name&&x.isprojectedPitcher));
          if(!a){
            a = pitcherDetailsArray.find(x => x?.team?.name == team_action.name);
          }
        }
      }
      return (
        <div
          className={`${classes.card_state_mlb} ${isSelected && classes.active}`}
        >
          <div>
            <p>
              {a?.full_name[0]}. {a?.lastName}{" "}
              <span>
                {a?.seasons[0]?.totals?.statistics?.pitching?.overall?.games?.win??0}-{a?.seasons[0]?.totals?.statistics?.pitching?.overall?.games?.loss??0},{" "}
                {parseFloat(a?.seasons[0]?.totals?.statistics?.pitching?.overall?.era??0).toFixed(2)} ERA
              </span>
            </p>
          </div>
        </div>
      );
    };
  
    // const getProjectedPitcherColor = (team_action, type) => {
    //   let match_id = props?.item?.match?.id;
    //   let team_id = props?.item?.id;
    //   let pitcherDetailsArray = selector_all_data.filter(x => {
    //     return ((x.match_id == match_id) && (x.type == "p" || x.type == "P"))
    //   })
    //   let a;
    //   let b;
    //   // if (team_action.team == "home") {
    //     if(pitcherDetailsArray.find(x => x?.team?.name == name)) {
    //       a = pitcherDetailsArray.find(x => x.team?.name == name);
    //     }
    //   // }
    //   // if (team_action.team == "away") {
    //     if(pitcherDetailsArray.find(x => x?.team?.name == teamBName)) {
    //       b = pitcherDetailsArray.find(x => x?.team?.name == teamBName);
    //     // }
    //   }
    //   var rec = props?.projectedPitcher?.findIndex(x => ((x?.firstName === a?.first_name)||(x?.firstName === b?.first_name)))
    //   console.log("kjasbdfdsf->",rec);
    //   if (rec !== -1) {
    //     var r = props.projectedPitcher[rec];
    //     // if (r.status === 'Expected') {
    //       if (type === 'color')
    //         return '#fb6e00'; // Probable Start
    //       else
    //         return 'Probable Start';
    //   }
    //   else {
    //     if (type === 'color')
    //       return '#5a5e66' // Not Starting
    //     else
    //       return 'Not Starting/Unknown';
    //   }
    // };

  return (
    <div
      className={`${classes.container_body_card} ${mlbCard && classes.inset}  ${isMobile && classes.mobile_team_d} 
      
      ${isMobile && classes.mobile_team_d}
      ${
        isSelected ? classes.activeBorder : ""
      }`}
    >
      <div className={classes.container_body_card_1}>
        <div className={classes.container_body_left}>
          {/* {isStarPlayer && (
            <span
              className={`${classes.container_body_card_start_power} ${
                mlbCard && classes.inset
              }`}
            >
              {" "}
              {mlbCard ? (
                <StarIcon solidcolor="#000" />
              ) : (
                <img src={PowerPlayIcon} />
              )}{" "}
              Star Power{" "}
            </span>
          )} */}
          {is_starTeamD ? (
            <span className={classes.container_body_card_start_power}>
              <>
                {/* <StarIcon solidcolor="#000" /> <p>Star Power</p> */}
                {
                  is_starCategory === 3 ?
                    (<><StarIcon solidcolor="#000" /><StarIcon solidcolor="#000" /><StarIcon solidcolor="#000" /> <p>Star Power</p> </>)
                    :
                    is_starCategory === 2 ?
                      (<><StarIcon solidcolor="#000" /><StarIcon solidcolor="#000" /> <p>Star Power</p> </>)
                      :
                      (<><StarIcon solidcolor="#000" /> <p>Star Power</p> </>)
                }
              </>
            </span>
          ) : null}
          <div
            className={`${classes.container_body_card_header} ${
              isSelected ? classes.header_flex : ""
            }`}
          >
            <p
              className={`${classes.container_selected_p} ${
                isSelected ? classes.active : ""
              }`}
            >
              {!isMobile? !isSelected ? (
              <>
              <img
                onClick={() => onSelectDeselect(id, match_id)}
                // className={disabled && classes.disabled}
                src={PlusIcon}
                disabled={disabled}
                style = {btnTitle !== "Swap" ? {
                  marginRight: 5,
                  position: 'relative',
                  top: '-3px'
                } : {}}
              />
                {/* {" "}
                + Select
              </button> */}
              </>
            ) : (
              // <div className={classes.container_selected}>
              //   <p className={classes.container_selected_p_1}>
              //     <Tick2 />{" "}
              //   </p>
                <img
                  src={DeleteIcon}
                  onClick={() => onSelectDeselect(id, match_id)}
                  style = {btnTitle !== "Swap" ? {
                    marginRight: 5,
                    position: 'relative',
                    top: '-3px'
                  } : {}}
                />
              // </div>
            ):<></>}
              <span>{market} {name}{item?.isFromHome &&!item?.isFromAway?('(H)'):('(A)')}</span> 
                    {/* <div title={getProjectedPitcherColor(id, 'tooltip')} style={{
                      width: 10, height: 10, background: getProjectedPitcherColor(id, 'color'), borderRadius: 100, display: 'inline-block', marginLeft: 5
                    }}></div> */}
                    
            </p>
            
          </div>
          {mlbCard && currentStep === 1 && (
            <div className={classes.card_mlb_vs}>
              <p>Vs {teamB?.market} {teamBName}</p>
            </div>
          )}
          <div
            className={`
                ${classes.container_body_card_state} 
                ${isSelected && classes.active}`}
          >
               <div
          className={`${classes.card_state_mlb} ${isSelected && classes.active}`}
        >
          <div className={classes.team_state_point}>
            <p className={classes.team_era}>
              Team ERA:{" "}
              <span>
               {item?.statistics?.pitching?.overall?.era?Number((item?.statistics?.pitching?.overall?.era)).toFixed(2):0}
              </span>
            </p>
            <p>
              HR Against:{" "}
              <span>
              {item?.statistics?.pitching?.overall?.onbase?.hr9?(item?.statistics?.pitching?.overall?.onbase?.hr9).toFixed(2):0}
              </span>
            </p>
          </div>
        </div>
            {mlbCard && currentStep === 1 ? (
              <RenderMLBState team="home" name={name} item={item}/>
            ) : isEmpty(playerStats) ? (
              <RenderMLBState team="home" name={name} item={item}/>
            ) : (
              <p>No Data</p>
            )}
          </div>
         
            
          {currentStep === 0 && (
            <>
              <div
                className={`${classes.team_vs} ${
                  isSelected ? classes.selectedColor : ""
                }`}
              >
                <p>Vs {teamB?.market} {teamBName}</p>
              </div>
              <div
                className={`
                ${classes.container_body_card_state} 
                ${isSelected && classes.active}`}
              >
                {/* {mlbCard && currentStep === 1 ? (
                  <RenderMLBState team="away" name={teamBName} />
                ) : isEmpty(playerStats) ? (
                  <RenderMLBState team="away" name={teamBName} />
                ) : (
                  <p>No Data</p>
                )} */}
              </div>
            </>
          )}
           <div
            className={`
                ${classes.container_body_card_state} 
                ${isSelected && classes.active}`}
          >
            {/* {mlbCard && currentStep === 1 ? (
              <RenderMLBState team="home" name={name} />
            ) : isEmpty(playerStats) ? (
              <RenderMLBState team="home" name={name} />
            ) */}
              {mlbCard && currentStep === 1 ? (
                  <RenderMLBState team="away" name={teamBName} item={item}/>
                ) : isEmpty(playerStats) ? (
                  <RenderMLBState team="away" name={teamBName} item={item}/>
                ) : (
                  <p>No Data</p>
                )}

            {/* <NHLTeamStats teamStats={teamStats} /> */}
          </div>
        </div>
        

        <div className={classes.container_body_right}>
          {image?.length>0 && (
          <div onClick={()=> window.open(image[0].trackingLink, "_blank")}>
            <div className={classes.right_img}>
              <img src={require(`../../assets/mlb_teamd/${image[0].shortName}.png`).default } alt={TDShirtImage}  />
              {/* <img src={TDShirtImage} alt="" /> */}
            </div>
          </div>

          )}
            {image?.length==0 && (
          <div className={classes.right_img}>
            <img src={TDShirtImage} alt="" />
          </div>
          )}
          {/* <img src={TDBadge} className={classes.img_badge} alt=""/>
          <p>Get Your Gear!</p> */}
        </div>
      </div>

      <div className={classes.container_card_footer_main}>
        <div className={classes.card_footer}>
          <div className={classes.divider}></div>
          <p className={classes.container_body_footer}>
            <span>
              <ClockIcon /> {time}
            </span>
            <span>
              <CalenderIcon /> {date}
            </span>
            <span>
              <StadiumIcon /> {stadium}
            </span>
          </p>
        </div>

        {/* {mlbCard && (
          <div className={classes.forwardArrow} onClick={nextStep}>
            <ForwardArrow color="#fb6e00" />
          </div>
        )} */}
          
      </div>
      {isMobile&&
       <div className={classes.footer_selected}>
       <p
           className={`${classes.container_selected_p} ${isSelected ? classes.active : ""
             }`}
         >
      {
        mlbCard ? (      
                <>
                {!isSelected ? (
                  <>
                    <button 
                      style={{position: btnTitle !== "+ Select" ? 'absolute':'relative',
                        right: btnTitle !== "+ Select" ? '16px': '', 
                        fontSize: 12,
                        fontWeight: 600, 
                        backgroundColor: btnTitle === "+ Select" ? 'transparent':'',
                        height: btnTitle === "+ Select" ? '0':'',
                        width: btnTitle === "+ Select" ? '0':'',
                        display: btnTitle === "+ Select" ? 'contents' : ''}}
                        onClick={() => {
                          if (mlbCard) {
                              onSelectDeselect(id, match_id);
                          }
                      }}>
                      + Select
                      {btnTitle !== "+ Select" && btnTitle}
                    </button>

                {/* {btnIcon && btnIcon} {btnTitle || "+"}
              </button> */}
              </>
            ) : (
              // <div className={classes.container_selected}>
              //   <p className={classes.container_selected_p_1}>
              //     <Tick2 /> Selected{" "}
                <div className={classes.footer__after_selected}>
                  <p>
                    <img
                      src={checkIcon}
                    />
                    Selected
                  </p>
                  <img
                    src={DeleteIcon2}
                    style = {btnTitle !== "Swap" ? {
                      // backgroundColor: "#f2f2f233",
                      // border: "none",
                      // color: "#a9a9a9",
                      // fontSize: 12,
                      // fontWeight: 600,
                      // width: 84,
                      // height: 28,
                      // borderRadius: 4,
                      // display: "flex",
                      // alignItems: "center",
                      // justifyContent: "space-evenly"
                    } : {}}
                    onClick={() => onSelectDeselect(id, match_id)}
                  />
                </div>
              //   </p>
              // </div>
            )}
                 
                </>
              ) : (
                <>
                </>
              )
        }</p></div>
      }
    </div>
  );
}

SportsTeamSelectionCard.propTypes = {
  item: {},
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  mlbCard: PropTypes.bool,
  steps: PropTypes.array,
  onSelectDeselect: PropTypes.func,
};

export default SportsTeamSelectionCard;
