import { useEffect } from "react";
import Header from "../../components/Header/Header";
import { Helmet } from "react-helmet"

const Blog = () => {

    useEffect(() => {

        const script = document.createElement('script');

        script.src = "https://io.dropinblog.com/js/embed.js";
        // script.dib_id = 'c198e903-ff17-4b90-9692-58b8da52c73d'
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <>
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-222300270-1">
                </script>
                <script>
                    window.dataLayer = window.dataLayer || [];
                    function gtag() &#123; dataLayer.push(arguments);&#125;
                    gtag('js', new Date());
                    gtag('config', 'UA-222300270-1');
                </script>
            </Helmet>
            <Header isStick={true} style={{ maxWidth: 1240 }} />
            <div id="dib-posts"></div>
        </>
    )
}

export default Blog;