import { useCallback, useState } from 'react';

import { PaymentCardInputs } from './PaymentCardInputs';
import styles from "./styles.module.scss";

const PaymentForm = ({
    amount,
    number,
    expirationMonth,
    expirationYear,
    cvv,
    paymentHandler,
    onSuccess,
    onDeclined,
    onPending,
    didChangeField,
    firstName,
    lastName
}) => {
    const [loading, setLoading] = useState(false)

    const didClick = useCallback(() => {
        if (loading) return

        setLoading(true)

        if (paymentHandler.type === "crypto") {
            const payment = {amount, currency: "BTC"}
            paymentHandler.handler(amount)
                .then(url => {
                    window.open(url, "_blank");
                    onPending(payment)
                })
                .catch(error => {
                    console.error(error)
                    onDeclined(payment, error)
                })
        } else {
            const payment = { amount, currency: "USD" }
            paymentHandler(number, parseInt(expirationMonth), parseInt(expirationYear), cvv, firstName, lastName, amount)
                .then(paymentResult => {
                    if (paymentResult.result === "declined") {
                        onDeclined(payment, paymentResult.reason)
                    } else if (paymentResult.result === "approved") {
                        onSuccess(payment)
                    }
                })
                .catch(error => {
                    console.error(error)
                    onDeclined(payment, error)
                })
        }
    }, [
        amount,
        setLoading,
        number,
        expirationMonth,
        expirationYear,
        loading,
        cvv,
        paymentHandler,
        onSuccess,
        onDeclined
    ])

    return (
        <div>
            {
                paymentHandler.type === "card" && (
                    <PaymentCardInputs
                        onFieldChangeHandler={didChangeField}
                        cardFirstName={firstName}
                        cardLastName={lastName}
                        CardNumber={number}
                        CardExpMonth={expirationMonth}
                        CardExpYear={expirationYear}
                        CardIDNumber={cvv}
                        price={amount}
                    />
                )
            }
            <button className={`${styles.submitbtn} w-100 d-block`}
                onClick={didClick}
                disabled={loading || (paymentHandler.type === "card" && (!number || !cvv || !firstName || !lastName || expirationMonth==0 || expirationYear==0 || cvv.length !== 3))}
            >             
                {loading ? "Depositing..." : `Deposit • $ ${amount} USD`}
            </button>
            <p className={`${styles.submitbtnlabel} w-100 d-block`}>You will be charged {amount} $USD by PowerPlay Systems Inc.</p>
        </div>
    )
}

export default PaymentForm