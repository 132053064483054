import React from "react";
import PropTypes from "prop-types";

function Baseball(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.size || "14"}
      height={props?.size || "14"}
      viewBox="0 0 14 14"
      className={`${{ height: "auto" }} ${{ ...props?.style }}`}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#F2F2F2" fillRule="nonzero">
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M2.141 9.896c.134-.165.376-.19.54-.056l.25.201c.138-.286.256-.582.353-.885L2.86 8.87c-.176-.118-.223-.357-.104-.533.119-.176.357-.223.533-.104l.2.135c.063-.322.104-.65.122-.983h-.385c-.212 0-.384-.172-.384-.384 0-.212.172-.384.384-.384h.385c-.018-.333-.06-.661-.122-.983l-.2.135c-.066.044-.14.066-.214.066-.124 0-.245-.06-.32-.17-.118-.176-.071-.415.105-.533l.425-.288c-.097-.303-.215-.598-.354-.884l-.248.201c-.072.058-.157.086-.242.086-.112 0-.223-.049-.299-.142-.134-.165-.108-.407.057-.541l.354-.287c-.216-.341-.463-.666-.74-.971C.687 3.548 0 5.195 0 7c0 1.805.687 3.452 1.813 4.695.276-.305.523-.63.739-.971l-.354-.287c-.165-.134-.19-.376-.057-.54z"
                    transform="translate(-568 -763) translate(59 503) translate(492 2) translate(16 219) translate(1 39)"
                  />
                  <path
                    d="M10.23 11.71c-.07.057-.156.085-.24.085-.113 0-.224-.049-.3-.142-.133-.165-.108-.407.057-.54l.448-.364c-.162-.31-.303-.629-.423-.956L9.465 10c-.066.044-.14.066-.215.066-.123 0-.244-.06-.319-.17-.118-.176-.072-.415.104-.533l.5-.337c-.136-.532-.218-1.081-.244-1.642h-.404c-.212 0-.384-.172-.384-.384 0-.212.172-.384.384-.384h.404c.026-.56.108-1.11.244-1.642l-.5-.337c-.176-.118-.222-.357-.104-.533.12-.176.358-.223.534-.104l.307.207c.12-.327.261-.647.423-.956l-.448-.363c-.165-.134-.19-.376-.057-.54.134-.165.376-.19.54-.057l.36.29c.236-.361.503-.705.799-1.03C10.188.581 8.66 0 7 0 5.34 0 3.812.582 2.611 1.551c.296.325.563.669.8 1.03l.358-.29c.165-.134.407-.109.54.056.134.165.109.407-.056.54l-.448.364c.162.31.303.629.423.956L4.535 4c.176-.119.415-.072.534.104.118.176.072.415-.104.533l-.5.337c.136.532.218 1.081.244 1.642h.404c.212 0 .384.172.384.384 0 .212-.172.384-.384.384h-.404c-.026.56-.108 1.11-.244 1.642l.5.337c.176.118.222.357.104.533-.075.11-.196.17-.32.17-.073 0-.148-.022-.214-.066l-.307-.207c-.12.327-.261.647-.423.956l.448.363c.165.134.19.376.057.54-.076.094-.187.143-.3.143-.084 0-.17-.028-.24-.086l-.36-.29c-.236.361-.503.705-.799 1.03C3.812 13.419 5.339 14 7 14c1.66 0 3.188-.582 4.389-1.551-.296-.325-.563-.669-.8-1.03l-.358.29z"
                    transform="translate(-568 -763) translate(59 503) translate(492 2) translate(16 219) translate(1 39)"
                  />
                  <path
                    d="M12.187 2.305c-.276.305-.523.63-.739.971l.354.287c.165.134.19.376.057.54-.076.094-.187.143-.299.143-.085 0-.17-.028-.242-.086l-.248-.201c-.139.286-.257.582-.354.885l.425.287c.176.118.223.357.104.533-.074.11-.195.17-.319.17-.074 0-.149-.022-.214-.066l-.2-.135c-.063.322-.104.65-.122.983h.384c.213 0 .385.172.385.384 0 .212-.172.384-.385.384h-.384c.018.333.059.661.122.983l.2-.135c.175-.119.414-.072.533.104.119.176.072.415-.104.533l-.425.288c.097.303.215.598.353.884l.25-.201c.164-.134.406-.108.54.056.133.165.108.407-.057.541l-.354.287c.216.341.463.666.74.971C13.312 10.452 14 8.805 14 7c0-1.805-.687-3.452-1.813-4.695z"
                    transform="translate(-568 -763) translate(59 503) translate(492 2) translate(16 219) translate(1 39)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

Baseball.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};

export default Baseball;
