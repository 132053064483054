import React, { useEffect, useState } from 'react';
import http from '../../config/http';
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import StarPower from "../../assets/success.png";
import {getFEURL,getApiURL } from '../../config/apiURL' 
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { redirectTo } from '../../utility/shared';
import * as userActions from '../../actions/userActions'
import { useHistory } from 'react-router-dom';
let FE_url=getFEURL()
let API_URL=getApiURL()

function Success(props){
    const { user } = useSelector((state) => state.auth);
    const [successResponse, setSuccessResponse] = useState(false);
    useSelector((state)=>console.log('state', state)); 
    const history = useHistory()

    // console.log("useridddddddddddd==>",user?.user_id);
    const params = new URLSearchParams(window.location.search);
// console.log("depositVerify==>",depositVerify);

    useEffect(() => {
      async function setSuccess(){ 

      let SubTotal=params.get('SubTotal')
      if(user?.user_id && params.get('Approved')==="APPROVED" &&params.get('SubTotal') &&params.get('ReturnCode')[0]==="Y"){
      let res=await http.get(`${API_URL}/api/v1/users/catch-thanks-url?user_id=${user?.user_id}&&SubTotal=${SubTotal}`)
      if(res)
        {
          setSuccessResponse(res) 
        }
        history.push({
          pathname:'/success',
        })
        // localStorage.setItem('depositApproved', false);
        // useDispatch.dispatch({
        //   type: userActions.USER_DEPOSIT_VERIFY,
        //   payload: {
        //     depositVerify:false,
        //   },
        // });

        // console.log("resssssss==>",res);
        // window.open('/my-game-center')
        // window.open(`${FE_url}/power-center?status=${res.data}`, "_self")
      }
    }
    setSuccess();
    }, [user?.user_id]);
    const onGoBack = () => {
      redirectTo(props, { path: "/power-center" });
    };
    return (
      <>
        <Header style={{maxWidth: 1240}}/>
          {!successResponse ?(
            <>
            Loading...
            </>
          ):(
          <div className={styles.depositSection}>
            <div className={styles.depositSectionInner}>
              <div className={styles.depositSectionIcon}>
                <img src={StarPower} alt="" />
              </div>
              <div className={styles.depositSectionDetail}>
                <h3>Deposit Successfull!</h3>
                <h4><b>{localStorage.getItem('currency') == 'USD' ? '$' + parseFloat(localStorage.getItem('amount')).toFixed(2) + ' USD' : '' }</b> has been added to your account</h4>
                <p>Deposited funds must be used to enter contests and are<br></br>not available for withdrawal.</p>
              </div>
              <div className={styles.depositSectionButton}>
                <button className={styles.depositButton} onClick={onGoBack}>Enter Games</button>
              </div>
            </div>
          </div>
        )}

        <Footer isBlack={true} />

   </>)
};

export default Success;