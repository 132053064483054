import * as Actions from "../../actions/teamManagerActions";
import { CONSTANTS } from "../../utility/constants";
const INITIAL_STATE = {
  gameID: 0,
  userID: 0,
  league: "",
  livePlayers: [],
  liveTeamD: [],
  playersPoints: [],
  playersActualScore: [],
  teamDActualScore: [],
  powersAvailable: [],
  swappedPlayers: [],
  teamLogs: [],
  rankings: [],
  fantacyGames: []
};
const TeamManagerReducer = (state = INITIAL_STATE, actions) => {
    
  switch (actions.type) {
    case CONSTANTS.TEAM_MANAGER.ACTIONTYPES.UPDATE_FULL_DATA:
        return {
            ...state,
            ...actions.payload,
        };
    default:
      return state;
  }
};
export default TeamManagerReducer;