import React from "react";
import PropTypes from "prop-types";

function CupIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.size || "354"}
      height={props?.size + 20 || "374"}
      viewBox="0 0 354 374"
      style={{ height: "auto" }}
      {...props}
    >
      <g fill="none" fillOpacity=".06" fillRule="evenodd">
        <g fill="#F2F2F2" fillRule="nonzero">
          <g>
            <path
              d="M245.31 193.532l85.454-77.832c25.676-23.935 28.696-63.497 6.76-88.164-.13-.143-13.396-14.51-36.52-14.51-9.6 0-19.428 2.49-29.329 7.388v-7.202C271.675 5.93 265.802 0 258.588 0H94.112c-7.214 0-13.087 5.93-13.087 13.212v6.786c-9.901-4.898-19.723-7.388-29.33-7.388-23.006 0-36.377 14.342-36.5 14.485-21.967 24.692-18.948 64.254 6.79 88.232l83.32 75.89c11.513 13.025 26.414 22.922 43.37 28.125v21.613c-7.847 7.593-12.3 18.048-12.3 29.119 0 11.07 4.453 21.525 12.3 29.119v23.661h-12.3c-23.733 0-43.05 19.495-43.05 43.461v6.209h166.05v-6.209c0-23.966-19.317-43.461-43.05-43.461h-12.3v-23.655c7.847-7.594 12.3-18.049 12.3-29.12 0-11.07-4.453-21.525-12.3-29.118v-21.613c15.94-4.892 30.073-13.926 41.285-25.816zm55.688-168.095c17.183 0 27.337 10.363 27.343 10.363 17.497 19.669 14.84 51.42-5.873 70.723l-55.27 50.347c1.992-6.29 3.272-12.809 3.936-19.452.018-.168.043-.33.055-.497.29-3.055.486-6.128.486-9.232V34.509c10.086-6.023 19.938-9.072 29.323-9.072zM85.496 156.454l-55.22-50.303C9.505 86.798 6.843 55.053 24.235 35.5c.104-.105 10.277-10.48 27.46-10.48 9.385 0 19.243 3.049 29.329 9.065v93.193c0 2.993.178 5.96.449 8.903.08.85.19 1.69.289 2.533.252 2.161.578 4.31.978 6.439.16.838.307 1.682.486 2.514.516 2.422 1.131 4.818 1.832 7.184.123.41.216.832.339 1.242.037.111.061.242.098.36zm43.782 20.228c-1.894-1.39-2.841-3.744-2.442-6.072l7.878-46.386-33.388-32.85c-1.673-1.652-2.275-4.116-1.556-6.364.726-2.248 2.65-3.887 4.97-4.228l46.13-6.768 20.634-42.2c1.033-2.118 3.173-3.465 5.51-3.465 2.337 0 4.484 1.341 5.51 3.465l20.628 42.2 46.13 6.768c2.32.341 4.238 1.98 4.97 4.228.72 2.248.123 4.712-1.556 6.364l-33.382 32.857 7.878 46.385c.394 2.328-.553 4.681-2.442 6.072-1.076.782-2.343 1.186-3.616 1.186-.978 0-1.962-.236-2.866-.714l-41.26-21.898-41.248 21.892c-2.073 1.099-4.576.919-6.482-.472z"
              transform="translate(-830 -449) translate(378 140) translate(452.3 309.018)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

CupIcon.propTypes = {
  size: PropTypes.number,
};

export default CupIcon;
