import { Carousel } from "react-responsive-carousel";
import { useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";
import { hasText } from "../../../utility/shared";
import styles from './styles.module.scss';
import ClockIcon from "../../../assets/icons/nhl/clock.svg";
import HockeyIcon from "../../../assets/icons/nhl/hockey.svg";
import BatsIcon from "../../../assets/icons/mlb/bats.svg";

import img1 from '../../../assets/combinedImageNew.png';
import SoccerJerseyIcon from "../../../assets/icons/nhl/soccer-jersey.svg";
import { useState } from "react";
import SwapModalMobile from "../../../components/mobilecomponents/SwapModal";
import * as nhlActions from "../../../actions/NHLActions";
import { isEmpty } from "lodash";
import { addTrailingZerons, getIsPowerAvailable, getIsPowerLocked, getSocialTooltip } from '../../../utility/shared';
//import cardTeamImage from '../../../assets/images/fanatics.jpg';
const TeamManagerMobileCard = (props) => {
  /*New Data*/
  const dispatch = useDispatch();
  const { selectedView = "fv", data = {}, powersAvailable = [], league = "", gameType} = props;
  const {
      fantasyPlayerPosition = "",
      full_name = "",
      gameID = 0,
      isFromAwayTeam = false,
      isFromHomeTeam = false,
      positionID = 0,
      userID = 0,
      match = {},
      id:pid = "",
      playerId = ""
  } = data;
  const { away = {}, home = {}, status = "scheduled", scheduled = ""} = match;
  const { 
      data: nhlData = [],
      live_team_logs = [],
      live_clock = "20:00", 
      setNhlEventData = [], 
      live_period = 0, 
      live_strength = "even",
      match_status = []
  } = useSelector((state) => state.nhl);
  const { playersActualScore=[],playersPoints={},teamDActualScore={},matchStatus=[]}= useSelector((state) => state.teamManager);
  const { 
      positionP = 0,
      positionC = 0,
      positionSS = 0,
      positionXB1 = 0,
      positionXB2 = 0,
      positionOF1 = 0,
      positionOF2 = 0,
      teamDPoints = 0,
  } = playersPoints;
  const { 
      posD1Points = 0,
      posD2Points = 0,
      posXW1Points = 0,
      posXW2Points = 0,
      posXW3Points = 0,
      posCenterPoints = 0,
      posGoaliePoints = 0,
      playerActualScore=[],
      teamDActual = []
  } = live_team_logs;
  const [timeLeft, setTimeLeft] = useState("20:00");
  const [checkClockData, setCheckClockData] = useState("20:00");
  const [showReplaceModal, setReplaceModalState] = useState(false);
  const [showscrollable, setShowscrollable] = useState(true);
  const [playerList, setPlayerList] = useState({});
  const [loadingPlayerList, setLoadingPlayerList] = useState(false);
  const closeRenderModal = () => {
      setReplaceModalState(false);
  };
  const calculateTimeLeft = (newClock) => {
      setCheckClockData(newClock);
      let myString1 = newClock
      let newminute1;
      let newSecond1;
      let difference1;
      let myStringParts1;
      let myStringParts;
      let newminute;
      let newSecond;
      let newClockData;
      let i = 0;
      const interval =  setInterval(async () => {
          if(newClockData === null || newClockData === undefined) {
              myStringParts1 = myString1?.split(':');
              newminute1 = myStringParts1[0]
              newSecond1 = myStringParts1[1];
              difference1 = moment({ minutes: "00", seconds: "10"}).add({ minutes: newminute1, seconds: newSecond1}).format('mm:ss');
              myStringParts = difference1.split(':');
              newminute= myStringParts[0];
              newSecond= myStringParts[1];
          }
          else{
              myStringParts = myString1?.split(':')
              newminute= myStringParts[0]
              newSecond= myStringParts[1];
              myStringParts1 = newClockData?.split(':')
              newminute1= myStringParts1[0]
              newSecond1= myStringParts1[1];
              difference1=moment({ minutes: newminute1, seconds: newSecond1}).subtract({ minutes: newminute1, seconds: newSecond1}).format('mm:ss');
          }
          let difference2=moment({ minutes: newminute, seconds: newSecond}).subtract({ minutes: newminute1, seconds: newSecond1}).format('mm:ss');
          let upperTime=moment.duration(`00:${difference1}`).asSeconds();
          let difference = upperTime - i;
          if (moment.duration(`00:${difference2}`).asSeconds() >=i) {
              let formatted = moment.utc(difference*1000).format('mm:ss');
              setTimeLeft(formatted)
              if (moment.duration(`00:${difference2}`).asSeconds() == i) {
                  newClockData = formatted;
                  return true
              }
              i++;
          }
      }, 1000);
  };
  const timerComponents = [];
  timeLeft && Object.keys(timeLeft).forEach((interval) => {
      if (!timeLeft[interval]) {
          return;
      }
      if(interval=='minutes')
          timerComponents.push(
              <span>
              {timeLeft[interval]}{":"}
              </span>
          );
      else {
          timerComponents.push(
              <span>
                  {timeLeft[interval]}
              </span>
          );
      }
  });
 
  const getTeamPoints = (id, id2) => {
      let filteredData = matchStatus.filter(x => x?.gameFeedId == id);
      if(filteredData?.length > 0)
      {
          let a = filteredData[filteredData.length - 1];
          if(a.away.id == id2){
              if(a?.away?.runs) return a?.away?.runs;
              else return false
          }
          if(a.home.id == id2){
              if(a?.home?.runs)return a?.home?.runs;
              else return false
          }
      }
      return false;
  };
  const getCurrentOuts=(id,id2)=>{
    let filteredData = matchStatus.filter(x => x?.gameFeedId == id);
    if(filteredData?.length > 0)
    {
        let a = filteredData[filteredData.length - 1];
        if(a.away.id == id2){
            if(a?.away?.currentOuts) return a?.away?.currentOuts;
            else return false
        }
        if(a.home.id == id2){
            if(a?.home?.currentOuts)return a?.home?.currentOuts;
            else return false
        }
    }
}
  const getInningHalf = (id) => {
    let filteredData = matchStatus.filter(x => x?.gameFeedId == id);
    if(filteredData?.length > 0)
    {
        let a = filteredData[filteredData.length - 1];
        if(a?.outcome?.currentInningHalf){
            if(a?.outcome?.currentInningHalf==="TOP" &&a?.outcome?.currentInning){
                if(a?.outcome?.currentInning==1){
                    return "Top 1st"
                }
                else if(a?.outcome?.currentInning==2){
                    return "Top 2nd"
                }
                else if(a?.outcome?.currentInning==3){
                    return "Top 3rd"
                }
                else{
                    return `Top ${a?.outcome?.currentInning}th`
                }
            }
            else if(a?.outcome?.currentInningHalf==="BOTTOM" &&a?.outcome?.currentInning){
                if(a?.outcome?.currentInning==1){
                    return "Bot 1st"
                }
                else if(a?.outcome?.currentInning==2){
                    return "Bot 2nd"
                }
                else if(a?.outcome?.currentInning==3){
                    return "Bot 3rd"
                }
                else{
                    return `Bot ${a?.outcome?.currentInning}th`
                }
            }
            else return false
        }
        return false
    }
    return false;
};
 
  const getStatus = () => {     
      if (
        `${status}`?.toLocaleLowerCase() === "scheduled" &&
        moment().diff(moment.utc(scheduled).local().format()) < 0
      ) {
        return `${moment.utc(scheduled).local().format("MMM Do")} - ${moment.utc(
          scheduled
        ).local().format("hh:mm A")}`;
      } 
      if(setNhlEventData?.length>0){
          let getMatchStatusDetails = match_status?.filter(x => x?.id == match?.id);
          if(getMatchStatusDetails.length > 0)
          {              
              let liveClockData = setNhlEventData.filter(x => x?.matchId  == match?.id);        
              if(liveClockData?.length > 0)
              {
                  let a = liveClockData[0];
                  if(a?.clock!==checkClockData){
                      setCheckClockData(a?.clock)
                      if(a?.clock=="00:00"){
                          return "End Period"
                      }
                      else {
                          return match?.status;
                      }
                  }
              }
          }
          return status;
        }
      return status;
    };
    
  
  const getAveragePoints=()=>{
      let pointData=props.fantasyPlayers?.find(x => x.id === playerId)?.seasons[0]?.totals?.statistics?.hitting?.overall?.avg
      if(pointData){
          if(pointData<1){
              let data=addTrailingZerons(pointData)?.toString()?.split(".")[1]
              return "."+data
          }
          else{
              return addTrailingZerons(pointData)
          }
      }else return ".000"
  }
  const getPlayerStatsByType = (type) => {
      if(type === 'ERA') {
          if(props?.fantasyPlayers.length > 0)
          {
              let eraData= props?.fantasyPlayers?.find(x => x.id === playerId)?.seasons[0]?.totals?.statistics?.pitching?.overall?.era
              if(eraData){
                  return parseFloat(eraData).toFixed(2)
              }
              return "0.00"
          }
          else
          return "0.00";
      }
      if(type === 'AVG') {
          if(props?.fantasyPlayers?.length > 0)
          return getAveragePoints();
          else
          return ".000";
      }
  };
  const getTeamStatsByType = () => {

      // if(teamDActualScore?.wins!==undefined&&teamDActualScore?.loss!==undefined){
      //   return teamDActualScore?.wins+"-"+teamDActualScore?.loss
      // }
       if(props?.fantasyTeam.length > 0)
        {
          return ((props.fantasyTeam?.find(x => x.id === match?.id)?.statistics?.standing?.wins??0) + "-" + (props.fantasyTeam.find(x => x.id === match?.id)?.statistics?.standing?.losses??0))
        }
      else
          return "0-0";
  };
  /*New Data*/
    const { playerData = {}, type = '', setSecondModal, starPlayerCount,  selectedTeam,  updateReduxState, powersAvailables } = props;
    // const { gameID } = selectedTeam || {};
    //const [showReplaceModal, setReplaceModalState] = useState(false);
    // const closeRenderModal = () => {
    //     setReplaceModalState(false);
    // };
    //const { powersAvailable = [], gameType } = selectedTeam;

    

    const {
        //fantasyPlayerPosition = "",
       // full_name = "",
        id = "",
        //match = {},
        //positionID = 0,
        teamId = "",
        name = "",
        //gameID,
    } = playerData;
    // const { home = {}, away = {}, status = "scheduled", scheduled = "", id: matchId } = match;
    const { 
        live_match_events = {}, 
        // match_status = [],
        // live_clock = "20:00",
        // live_period = 1,
        // live_strength = "even",
        // setNhlEventData = [],
        // live_team_logs = [],
        teamDPts = 0
    } = useSelector((state) => state.nhl);
    const { playerActualScores = []}=live_team_logs
    // const { 
    //     posD1Points = 0,
    //     posD2Points = 0,
    //     posXW1Points = 0,
    //     posXW2Points = 0,
    //     posXW3Points = 0,
    //     posCenterPoints = 0,
    //     posGoaliePoints = 0,
    //     teamDActual = []
    // } = live_team_logs?.team || {};


    


    // const getTeamPoints = (id, id2) => {
    //     let filteredData = match_status.filter(x => x.id == id);
    //     if(filteredData.length > 0)
    //     {
    //       let a = filteredData[filteredData.length - 1];
    //       if(a.away.id == id2){
    //         return a?.away?.points;
    //       }
    //       if(a.home.id == id2){
    //         return a?.home?.points;
    //       }
    //     }
    //     return false;
    // };
    const getTeamDetails = (id) => {
        let filteredData = match_status.filter(x => x?.id == id);
        if(filteredData?.length > 0)
        {
          let a = filteredData[filteredData.length - 1];
          return a;
        }
        return false;
    };
///live clock setup....

   // const [timeLeft, setTimeLeft] = useState("20:00");
    const [timeLeftG, setTimeLeftG] = useState("20:00");
    const [timeLeftC, setTimeLeftC] = useState("20:00");
    const [timeLeftXW1, setTimeLeftXW1] = useState("20:00");
    const [timeLeftXW2, setTimeLeftXW2] = useState("20:00");
    const [timeLeftXW3, setTimeLeftXW3] = useState("20:00");
    const [timeLeftD1, setTimeLeftD1] = useState("20:00");
    const [timeLeftD2, setTimeLeftD2] = useState("20:00");
    
    //const [checkClockData, setCheckClockData] = useState("20:00")
    const [checkClockDataG, setCheckClockDataG] = useState("20:00")
    const [checkClockDataXW1, setCheckClockDataXW1] = useState("20:00")
    const [checkClockDataXW2, setCheckClockDataXW2] = useState("20:00")
    const [checkClockDataXW3, setCheckClockDataXW3] = useState("20:00")
    const [checkClockDataD1, setCheckClockDataD1] = useState("20:00")
    const [checkClockDataD2, setCheckClockDataD2] = useState("20:00")
    const [checkClockDataC, setCheckClockDataC] = useState("20:00")
    
    const [firstRenderClock, setFirstRenderClock] = useState(true)
    const [firstRenderClockG, setFirstRenderClockG] = useState(true)
    const [firstRenderClockXW1, setFirstRenderClockXW1] = useState(true)
    const [firstRenderClockXW2, setFirstRenderClockXW2] = useState(true)
    const [firstRenderClockXW3, setFirstRenderClockXW3] = useState(true)
    const [firstRenderClockD1, setFirstRenderClockD1] = useState(true)
    const [firstRenderClockD2, setFirstRenderClockD2] = useState(true)
    const [firstRenderClockC, setFirstRenderClockC] = useState(true)
    
    const [clockWithNew, setClockWithNew] = useState(true)
    const [clockWithNewG, setClockWithNewG] = useState(true)
    const [clockWithNewXW1, setClockWithNewXW1] = useState(true)
    const [clockWithNewXW2, setClockWithNewXW2] = useState(true)
    const [clockWithNewXW3, setClockWithNewXW3] = useState(true)
    const [clockWithNewD1, setClockWithNewD1] = useState(true)
    const [clockWithNewD2, setClockWithNewD2] = useState(true)
    const [clockWithNewC, setClockWithNewC] = useState(true)
    
    let interval;
    // const calculateTimeLeft = async(newClock,preClock) => {
    //   await clearInterval(interval)
    //   setClockWithNew(false)
    //   setFirstRenderClock(false)
    //   setCheckClockData(newClock)
    //   let myString1 = newClock
    //   let difference1;
    //   let myStringParts
    //   let newminute
    //   let newSecond
    //   let i=moment.duration(`00:${myString1}`).asSeconds()
    //   interval =  setInterval(async () => {
      
    //     myStringParts = myString1?.split(':')
    //     newminute= myStringParts[0]
    //     newSecond= myStringParts[1];
    //     let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
    //     let difference=upperTime-i
    //     if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
    //       let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
    //       difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
    //       setTimeLeft(difference1)
    //       i++
    //       }else{
    //         if(newClock=="00:00"){
    //           setTimeLeft("20:00")
    //         }
    //         setClockWithNew(true)
    //         clearInterval(intervalD2)
    //       }
    //     }, 1000);
    
    // };
    
    //const timerComponents = [];
    timeLeft &&Object.keys(timeLeft).forEach((interval) => {
      if (!timeLeft[interval]) {
        return;
      }
      if(interval=='minutes')
      timerComponents.push(
        <span>
          {timeLeft[interval]}{":"}
        </span>
      );
      else{
        timerComponents.push(
          <span>
            {timeLeft[interval]}
          </span>
        );
      }
    });
    
      
      // console.log("setNhlEventData==>",setNhlEventData);
    
    
      // useEffect(() => {
      //   let difference2
      //   let newminute1="18"
      //   let newSecond1="30"
      // //   console.log(`initializing interval`);
      // //   let j="19:55"
      // //   // const interval =  setInterval(() => {
      // //     // setTimeLeft(calculateTimeLeft("20:00","19:20"));
      // //     for(let i=0;i<9;i++)
      // //     {
      // //       calculateTimeLeft(j);
      // //     }
      // //   // }, 5000);
      //   setInterval(() => {
      // //     // setTimeLeft(calculateTimeLeft("20:00","19:20"));
      // //     for(let i=0;i<9;i++)
      // //     {
      //   difference2=moment({ minutes: "20", seconds: "00"}).subtract({ minutes: newminute1, seconds: newSecond1}).format('mm:ss');
      //       calculateTimeLeft(difference2);
      //       newminute1="19"
      //       newSecond1="00"
      // //     }
      //   }, 12000);
        // return () => {
        //   console.log(`clearing interval`);
        //   clearInterval(interval);
        // };
      // }, []);
     
    
      // const getTeamData = (id) => {
      //   let a;
      //   let priviouseEvent;
      //   let upperTime;
      //   let lowerTime;
      //   let difference;
      //   Object.keys(setNhlEventData).forEach((item, i) => {
      //   if(item===id){
      //   // if(item!=='type'){
      //     if(setNhlEventData[item]?.length!==0){
      //       let liveClockData = setNhlEventData[item].filter(x => x?.id  == id);
      //       if(liveClockData?.length > 0)
      //       {
      //         a = liveClockData[liveClockData?.length-1];
      //         if(liveClockData?.length > 1){
      //           let i=1;
      //           liveClockData?.forEach((element,index) => {
      //             if(liveClockData[index-i]?.eventData?.clock==a?.eventData?.clock){
      //               i++;
      //             }
      //             else if(liveClockData[index-i]?.eventData?.clock!=a?.eventData?.clock){
      //               priviouseEvent=liveClockData[index-i]
      //             }
      //           });
      //           // priviouseEvent=liveClockData[liveClockData?.length-2]
      //           upperTime=moment.duration(`00:${priviouseEvent?.eventData?.clock}`).asSeconds()
      //           lowerTime=moment.duration(`00:${a?.eventData?.clock}`).asSeconds()
      //           difference=upperTime-lowerTime
      //         }
      //         if(a?.eventData?.clock!==checkClockData){
      //           if(a?.eventData?.clock=="00:00"){
      //               // setTimeLeft("00:00")
      //               setTimeout(() => {
      //                 setTimeLeft(a?.eventData?.clock)
      //               }, 500);
      //             }
      //           else if(priviouseEvent &&difference>0&&clockWithNew&&firstRenderClock) {
      //               calculateTimeLeft(a?.eventData?.clock,priviouseEvent?.eventData?.clock);
      //             }  
      //             else if(priviouseEvent && difference>0 &&clockWithNew&&!firstRenderClock) {
      //               calculateTimeLeft(a?.eventData?.clock,timeLeft);
      //             } 
      //           else {
      //             // calculateTimeLeft(a?.eventData?.clock,"20:00");
      //             setTimeout(() => {
      //               setTimeLeft(a?.eventData?.clock)
      //             }, 500);
      //           } 
      //           }
      //       }
      //       else{
      //         if(!a)
      //           a = false;
      //         }
      //       }
      //   }
      //   })
      //   if(a)
      //     return a
      //   };
    
        let intervalG
        const calculateTimeLeftG = async(newClock,preClock) => {
          await clearInterval(intervalG)
          setClockWithNewG(false)
          setFirstRenderClockG(false)
          setCheckClockDataG(newClock)
          let myString1 = newClock
          let difference1;
          let myStringParts
          let newminute
          let newSecond
          let i=moment.duration(`00:${myString1}`).asSeconds()
    
           intervalG =  setInterval(async () => {
    
            myStringParts = myString1?.split(':')
            newminute= myStringParts[0]
            newSecond= myStringParts[1];
            let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
            let difference=upperTime-i
            if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
              let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
              difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
              setTimeLeftG(difference1)
              i++
              }else{
                if(newClock=="00:00"){
                  setTimeLeftG("20:00")
                }
                setClockWithNewG(true)
                clearInterval(intervalD2)
              }
            }, 1000);
    
        
        };
    
    
    const timerComponentsG = [];
    timeLeftG &&Object.keys(timeLeftG).forEach((interval) => {
      if (!timeLeftG[interval]) {
        return;
      }
      if(interval=='minutes')
      timerComponentsG.push(
        <span>
          {timeLeftG[interval]}{":"}
        </span>
      );
      else{
        timerComponentsG.push(
          <span>
            {timeLeftG[interval]}
          </span>
        );
      }
    });
    // console.log("setNhlEventData==>",setNhlEventData);
    const getTeamDataG = (id) => {
      let a;
      let priviouseEvent;
      let upperTime;
      let lowerTime;
      let difference;
      Object.keys(setNhlEventData).forEach((item, i) => {
      if(item===id){
      // if(item!=='type'){
        if(setNhlEventData[item]?.length!==0){
          let liveClockData = setNhlEventData[item].filter(x => x?.id  == id);
          if(liveClockData?.length > 0)
          {
            a = liveClockData[liveClockData?.length-1];
            if(liveClockData?.length > 1){
              let i=1;
              liveClockData?.forEach((element,index) => {
                if(liveClockData[index-i]?.eventData?.clock==a?.eventData?.clock){
                  i++;
                }
                else if(liveClockData[index-i]?.eventData?.clock!=a?.eventData?.clock){
                  priviouseEvent=liveClockData[index-i]
                }
              });
              // priviouseEvent=liveClockData[liveClockData?.length-2]
              upperTime=moment.duration(`00:${priviouseEvent?.eventData?.clock}`).asSeconds()
              lowerTime=moment.duration(`00:${a?.eventData?.clock}`).asSeconds()
              difference=upperTime-lowerTime
            }
            if(a?.eventData?.clock!==checkClockDataG){
              if(a?.eventData?.clock=="00:00"){
                  // setTimeLeft("00:00")
                  setTimeout(() => {
                    setTimeLeftG(a?.eventData?.clock)
                  }, 500);
                }
              else if(priviouseEvent &&difference>0&&clockWithNewG&&firstRenderClockG) {
                  calculateTimeLeftG(a?.eventData?.clock,priviouseEvent?.eventData?.clock);
                } 
                else if(priviouseEvent && difference>0 &&clockWithNewG&&!firstRenderClockG) {
                  calculateTimeLeftG(a?.eventData?.clock,timeLeftG);
                } 
              else {
                // calculateTimeLeftG(a?.eventData?.clock,"20:00");
                setTimeout(() => {
                  setTimeLeftG(a?.eventData?.clock)
                }, 500);
              } 
              }
          }
          else{
            if(!a)
              a = false;
            }
          }
      }
      })
      if(a)
        return a
      };
    
    //get clock and period for XW1
    let intervalXW1
    const calculateTimeLeftXW1 = async(newClock,preClock) => {
      await clearInterval(intervalXW1)
      setClockWithNewXW1(false)
      setFirstRenderClockXW1(false)
      setCheckClockDataXW1(newClock)
      let myString1 = newClock
      let difference1;
      let myStringParts
      let newminute
      let newSecond
      let i=moment.duration(`00:${myString1}`).asSeconds()
       intervalXW1 =  setInterval(async () => {
      
        myStringParts = myString1?.split(':')
        newminute= myStringParts[0]
        newSecond= myStringParts[1];
        let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
        let difference=upperTime-i
        if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
          let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
          difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
          setTimeLeftXW1(difference1)
          i++
          }else{
            if(newClock=="00:00"){
              setTimeLeftXW1("20:00")
            }
            setClockWithNewXW1(true)
            clearInterval(intervalD2)
          }
        }, 1000);
    
    };
    
    
    const timerComponentsXW1 = [];
    timeLeftXW1 &&Object.keys(timeLeftXW1).forEach((interval) => {
      if (!timeLeftXW1[interval]) {
        return;
      }
      if(interval=='minutes')
      timerComponentsXW1.push(
        <span>
          {timeLeftXW1[interval]}{":"}
        </span>
      );
      else{
        timerComponentsXW1.push(
          <span>
            {timeLeftXW1[interval]}
          </span>
        );
      }
    });
    const getTeamDataXW1 = (id) => {
      let a;
      let priviouseEvent;
      let upperTime;
      let lowerTime;
      let difference;
      Object.keys(setNhlEventData).forEach((item, i) => {
      if(item===id){
      // if(item!=='type'){
        if(setNhlEventData[item]?.length!==0){
          let liveClockData = setNhlEventData[item].filter(x => x?.id  == id);
          if(liveClockData?.length > 0)
          {
            a = liveClockData[liveClockData?.length-1];
            if(liveClockData?.length > 1){
              let i=1;
              liveClockData?.forEach((element,index) => {
                if(liveClockData[index-i]?.eventData?.clock==a?.eventData?.clock){
                  i++;
                }
                else if(liveClockData[index-i]?.eventData?.clock!=a?.eventData?.clock){
                  priviouseEvent=liveClockData[index-i]
                }
              });
              // priviouseEvent=liveClockData[liveClockData?.length-2]
              upperTime=moment.duration(`00:${priviouseEvent?.eventData?.clock}`).asSeconds()
              lowerTime=moment.duration(`00:${a?.eventData?.clock}`).asSeconds()
              difference=upperTime-lowerTime
            }
            if(a?.eventData?.clock!==checkClockDataXW1){
              if(a?.eventData?.clock=="00:00"){
                  // setTimeLeft("00:00")
                  setTimeout(() => {
                    setTimeLeftXW1(a?.eventData?.clock)
                  }, 500);
                }
              else if(priviouseEvent &&difference>0&&clockWithNewXW1&&firstRenderClockXW1) {
                  calculateTimeLeftXW1(a?.eventData?.clock,priviouseEvent?.eventData?.clock);
                }  
                else if(priviouseEvent && difference>0 &&clockWithNewXW1&&!firstRenderClockXW1) {
                  calculateTimeLeftXW1(a?.eventData?.clock,timeLeftXW1);
                }  
              else {
                // calculateTimeLeftXW1(a?.eventData?.clock,"20:00");
                setTimeout(() => {
                  setTimeLeftXW1(a?.eventData?.clock)
                }, 500);
              } 
              }
          }
          else{
            if(!a)
              a = false;
            }
          }
      }
      })
      if(a)
        return a
      };
    // get clcok and period for XW2
    let intervalXW2
    const calculateTimeLeftXW2 = async(newClock,preClock) => {
      await clearInterval(intervalXW2)
      setClockWithNewXW2(false)
      setFirstRenderClockXW2(false)
      setCheckClockDataXW2(newClock)
      let myString1 = newClock
      let difference1;
      let myStringParts
      let newminute
      let newSecond
      let i=moment.duration(`00:${myString1}`).asSeconds()
       intervalXW2 =  setInterval(async () => {
      
        myStringParts = myString1?.split(':')
        newminute= myStringParts[0]
        newSecond= myStringParts[1];
        let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
        let difference=upperTime-i
        if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
          let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
          difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
          setTimeLeftXW2(difference1)
          i++
          }else{
            if(newClock=="00:00"){
              setTimeLeftXW2("20:00")
            }
            setClockWithNewXW2(true)
            clearInterval(intervalD2)
          }
        }, 1000);
    
    };
    
    
    const timerComponentsXW2 = [];
    timeLeftXW2 &&Object.keys(timeLeftXW2).forEach((interval) => {
      if (!timeLeftXW2[interval]) {
        return;
      }
      if(interval=='minutes')
      timerComponentsXW2.push(
        <span>
          {timeLeftXW2[interval]}{":"}
        </span>
      );
      else{
        timerComponentsXW2.push(
          <span>
            {timeLeftXW2[interval]}
          </span>
        );
      }
    });
    const getTeamDataXW2 = (id) => {
      let a;
      let priviouseEvent;
      let upperTime;
      let lowerTime;
      let difference;
      Object.keys(setNhlEventData).forEach((item, i) => {
      if(item===id){
      // if(item!=='type'){
        if(setNhlEventData[item]?.length!==0){
          let liveClockData = setNhlEventData[item].filter(x => x?.id  == id);
          if(liveClockData?.length > 1)
          {
            a = liveClockData[liveClockData?.length-1];
            if(liveClockData?.length > 1){
              let i=1;
              liveClockData?.forEach((element,index) => {
                if(liveClockData[index-i]?.eventData?.clock==a?.eventData?.clock){
                  i++;
                }
                else if(liveClockData[index-i]?.eventData?.clock!=a?.eventData?.clock){
                  priviouseEvent=liveClockData[index-i]
                }
              });
              // priviouseEvent=liveClockData[liveClockData?.length-2]
              upperTime=moment.duration(`00:${priviouseEvent?.eventData?.clock}`).asSeconds()
              lowerTime=moment.duration(`00:${a?.eventData?.clock}`).asSeconds()
              difference=upperTime-lowerTime
            }
            if(a?.eventData?.clock!==checkClockDataXW2){
              if(a?.eventData?.clock=="00:00"){
                  // setTimeLeft("00:00")
                  setTimeout(() => {
                    setTimeLeftXW2(a?.eventData?.clock)
                  }, 500);
                }
              else if(priviouseEvent &&difference>0&&clockWithNewXW2&&firstRenderClockXW2) {
                calculateTimeLeftXW2(a?.eventData?.clock,priviouseEvent?.eventData?.clock);
                }  
              else if(priviouseEvent && difference>0 &&clockWithNewXW2&&!firstRenderClockXW2) {
                calculateTimeLeftXW2(a?.eventData?.clock,timeLeftXW2);
              }  
              else {
                // calculateTimeLeftXW2(a?.eventData?.clock,"20:00");
                setTimeout(() => {
                  setTimeLeftXW2(a?.eventData?.clock)
                }, 500);
              } 
              }
            }
              // setTimeout(() => {
              //   setTimeLeftXW2(a?.eventData?.clock)
              // }, 500);
          
          else{
            if(!a)
              a = false;
            }
          }
      }
      })
      if(a)
        return a
      };
    //get clock and perod for XW3
    let intervalXW3
    const calculateTimeLeftXW3 = async(newClock,preClock) => {
      await clearInterval(intervalXW3)
      setClockWithNewXW3(false)
      setFirstRenderClockXW3(false)
      setCheckClockDataXW3(newClock)
      let myString1 = newClock
      let difference1;
      let myStringParts
      let newminute
      let newSecond
      let i=moment.duration(`00:${myString1}`).asSeconds()
       intervalXW3 =  setInterval(async () => {
      
        myStringParts = myString1?.split(':')
        newminute= myStringParts[0]
        newSecond= myStringParts[1];
        let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
        let difference=upperTime-i
        if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
          let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
          difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
          setTimeLeftXW3(difference1)
          i++
          }else{
            if(newClock=="00:00"){
              setTimeLeftXW3("20:00")
            }
            setClockWithNewXW3(true)
            clearInterval(intervalD2)
          }
        }, 1000);
    
    };
    
    
    const timerComponentsXW3 = [];
    timeLeftXW3 &&Object.keys(timeLeftXW3).forEach((interval) => {
      if (!timeLeftXW3[interval]) {
        return;
      }
      if(interval=='minutes')
      timerComponentsXW3.push(
        <span>
          {timeLeftXW3[interval]}{":"}
        </span>
      );
      else{
        timerComponentsXW3.push(
          <span>
            {timeLeftXW3[interval]}
          </span>
        );
      }
    });
    const getTeamDataXW3 = (id) => {
    let a;
    let priviouseEvent;
    let upperTime;
    let lowerTime;
    let difference;
    Object.keys(setNhlEventData).forEach((item, i) => {
    if(item===id){
    // if(item!=='type'){
      if(setNhlEventData[item]?.length!==0){
        let liveClockData = setNhlEventData[item].filter(x => x?.id  == id);
        if(liveClockData?.length > 0)
        {
          a = liveClockData[liveClockData?.length-1];
          if(liveClockData?.length > 1){
            let i=1;
            liveClockData?.forEach((element,index) => {
              if(liveClockData[index-i]?.eventData?.clock==a?.eventData?.clock){
                i++;
              }
              else if(liveClockData[index-i]?.eventData?.clock!=a?.eventData?.clock){
                priviouseEvent=liveClockData[index-i]
              }
            });
            // priviouseEvent=liveClockData[liveClockData?.length-2]
            upperTime=moment.duration(`00:${priviouseEvent?.eventData?.clock}`).asSeconds()
            lowerTime=moment.duration(`00:${a?.eventData?.clock}`).asSeconds()
            difference=upperTime-lowerTime
          }
          if(a?.eventData?.clock!==checkClockDataXW3){
            if(a?.eventData?.clock=="00:00"){
                // setTimeLeft("00:00")
                setTimeout(() => {
                  setTimeLeftXW3(a?.eventData?.clock)
                }, 500);
              }
            else if(priviouseEvent &&difference>0 &&clockWithNewXW3&&firstRenderClockXW3) {
                calculateTimeLeftXW3(a?.eventData?.clock,priviouseEvent?.eventData?.clock);
              }  
            else if(priviouseEvent && difference>0 &&clockWithNewXW3&&!firstRenderClockXW3) {
              calculateTimeLeftXW3(a?.eventData?.clock,timeLeftXW3);
            }    
            else {
              // calculateTimeLeftXW3(a?.eventData?.clock,"20:00");
              setTimeout(() => {
                setTimeLeftXW3(a?.eventData?.clock)
              }, 500);
            } 
            }
        }
        else{
          if(!a)
            a = false;
          }
        }
    }
    })
    if(a)
      return a
    };
    //get clock and  period for D1
    let intervalD1
    const calculateTimeLeftD1 =async (newClock,preClock) => {
      await clearInterval(intervalD1)
      setClockWithNewD1(false)
      setFirstRenderClockD1(false)
      setCheckClockDataD1(newClock)
      let myString1 = newClock
      let difference1;
      let myStringParts
      let newminute
      let newSecond
      let i=moment.duration(`00:${myString1}`).asSeconds()
       intervalD1 =  setInterval(async () => {
      
        myStringParts = myString1?.split(':')
        newminute= myStringParts[0]
        newSecond= myStringParts[1];
        let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
        let difference=upperTime-i
        if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
          let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
          difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
          setTimeLeftD1(difference1)
          i++
          }else{
            if(newClock=="00:00"){
              setTimeLeftD1("20:00")
            }
            setClockWithNewD1(true)
            clearInterval(intervalD2)
          }
        }, 1000);
    
    };
    
    
    const timerComponentsD1 = [];
    timeLeftD1 &&Object.keys(timeLeftD1).forEach((interval) => {
      if (!timeLeftD1[interval]) {
        return;
      }
      if(interval=='minutes')
      timerComponentsD1.push(
        <span>
          {timeLeftD1[interval]}{":"}
        </span>
      );
      else{
        timerComponentsD1.push(
          <span>
            {timeLeftD1[interval]}
          </span>
        );
      }
    });
    const getTeamDataD1 = (id) => {
      let a;
      let priviouseEvent;
      let upperTime;
      let lowerTime;
      let difference;
      Object.keys(setNhlEventData).forEach((item, i) => {
      if(item===id){
      // if(item!=='type'){
        if(setNhlEventData[item]?.length!==0){
          let liveClockData = setNhlEventData[item].filter(x => x?.id  == id);
          if(liveClockData?.length > 0)
          {
            a = liveClockData[liveClockData?.length-1];
            if(liveClockData?.length > 1){
              let i=1;
              liveClockData?.forEach((element,index) => {
                if(liveClockData[index-i]?.eventData?.clock==a?.eventData?.clock){
                  i++;
                }
                else if(liveClockData[index-i]?.eventData?.clock!=a?.eventData?.clock){
                  priviouseEvent=liveClockData[index-i]
                }
              });
              // priviouseEvent=liveClockData[liveClockData?.length-2]
              upperTime=moment.duration(`00:${priviouseEvent?.eventData?.clock}`).asSeconds()
              lowerTime=moment.duration(`00:${a?.eventData?.clock}`).asSeconds()
              difference=upperTime-lowerTime
            }
            if(a?.eventData?.clock!==checkClockDataD1){
              if(a?.eventData?.clock=="00:00"){
                  // setTimeLeft("00:00")
                  setTimeout(() => {
                    setTimeLeftD1(a?.eventData?.clock)
                  }, 500);
                }
              else if(priviouseEvent &&difference>0&&clockWithNewD1&&firstRenderClockD1) {
                  calculateTimeLeftD1(a?.eventData?.clock,priviouseEvent?.eventData?.clock);
                }  
              else if(priviouseEvent && difference>0 &&clockWithNewD1&&!firstRenderClockD1) {
                calculateTimeLeftD1(a?.eventData?.clock,timeLeftD1);
              }    
              else {
                // calculateTimeLeftD1(a?.eventData?.clock,"20:00");
                setTimeout(() => {
                  setTimeLeftD1(a?.eventData?.clock)
                }, 500);
              } 
              }
          }
          else{
            if(!a)
              a = false;
            }
          }
      }
      })
      if(a)
        return a
      };
    //get clock and period for D2
    let intervalD2
    
    const calculateTimeLeftD2 = async(newClock,preClock) => {
      await clearInterval(intervalD2)
      setClockWithNewD2(false)
      setFirstRenderClockD2(false)
      setCheckClockDataD2(newClock)
      let myString1 = newClock
      let difference1;
      let myStringParts
      let newminute
      let newSecond
      let i=moment.duration(`00:${myString1}`).asSeconds()
       intervalD2 =  setInterval(async () => {
    
        myStringParts = myString1?.split(':')
        newminute= myStringParts[0]
        newSecond= myStringParts[1];
        let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
        let difference=upperTime-i
        if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
          let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
          difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
          setTimeLeftD2(difference1)
          i++
          }else{
            if(newClock=="00:00"){
              setTimeLeftD2("20:00")
            }
            setClockWithNewD2(true)
            clearInterval(intervalD2)
          }
        }, 1000);
        
    
    };
    
    
    const timerComponentsD2 = [];
    timeLeftD2 &&Object.keys(timeLeftD2).forEach((interval) => {
      if (!timeLeftD2[interval]) {
        return;
      }
      if(interval=='minutes')
      timerComponentsD2.push(
        <span>
          {timeLeftD2[interval]}{":"}
        </span>
      );
      else{
        timerComponentsD2.push(
          <span>
            {timeLeftD2[interval]}
          </span>
        );
      }
    });
    
    const getTeamDataD2 = (id) => {
      let a;
      let priviouseEvent;
      let upperTime;
      let lowerTime;
      let difference;
      Object.keys(setNhlEventData).forEach((item, i) => {
    
      if(item===id){
      // if(item!=='type'){
        if(setNhlEventData[item]?.length!==0){
          let liveClockData = setNhlEventData[item].filter(x => x?.id  == id);
    
          if(liveClockData?.length > 0)
          {
            a = liveClockData[liveClockData?.length-1];
            if(liveClockData?.length > 1){
              let i=1;
              liveClockData?.forEach((element,index) => {
                if(liveClockData[index-i]?.eventData?.clock==a?.eventData?.clock){
                  i++;
                }
                else if(liveClockData[index-i]?.eventData?.clock!=a?.eventData?.clock){
                  priviouseEvent=liveClockData[index-i]
                }
              });
              // priviouseEvent=liveClockData[liveClockData?.length-2]
              upperTime=moment.duration(`00:${priviouseEvent?.eventData?.clock}`).asSeconds()
              lowerTime=moment.duration(`00:${a?.eventData?.clock}`).asSeconds()
              difference=upperTime-lowerTime
            }
    
            if(a?.eventData?.clock!==checkClockDataD2){
              if(timeLeftD2=="00:00"){
                  // setTimeLeft("00:00")
                  setTimeout(() => {
                    setTimeLeftD2(a?.eventData?.clock)
                  }, 500);
                }
              else if(priviouseEvent && difference>0 &&clockWithNewD2 &&firstRenderClockD2) {
                  calculateTimeLeftD2(a?.eventData?.clock,priviouseEvent?.eventData?.clock);
                } 
              else if(priviouseEvent && difference>0 &&clockWithNewD2&&!firstRenderClockD2) {
                calculateTimeLeftD2(a?.eventData?.clock,timeLeftD2);
              }  
              else {
                // calculateTimeLeftD2(a?.eventData?.clock,"20:00");
                setTimeout(() => {
                  setTimeLeftD2(a?.eventData?.clock)
                }, 500);
              } 
              }
          }
          else{
              if(!a)
                a = false;
            }
          }
      }
      })
      if(a)
        return a
      };
    
    //get clock and period for C
    let intervalC
    const calculateTimeLeftC = async(newClock,preClock) => {
      await clearInterval(intervalC)
      setClockWithNewC(false)
      setFirstRenderClockC(false)
      setCheckClockDataC(newClock)
      let myString1 = newClock
      let difference1;
      let myStringParts
      let newminute
      let newSecond
      let i=moment.duration(`00:${myString1}`).asSeconds()
       intervalC =  setInterval(async () => {
      
        myStringParts = myString1?.split(':')
        newminute= myStringParts[0]
        newSecond= myStringParts[1];
        let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
        let difference=upperTime-i
        if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
          let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
          difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
          setTimeLeftC(difference1)
          i++
          }else{
            if(newClock=="00:00"){
              setTimeLeftC("20:00")
            }
            setClockWithNewC(true)
            clearInterval(intervalD2)
          }
        }, 1000);
    
    };
    
    const timerComponentsC = [];
    timeLeftC &&Object.keys(timeLeftC).forEach((interval) => {
      if (!timeLeftC[interval]) {
        return;
      }
      if(interval=='minutes')
      timerComponentsC.push(
        <span>
          {timeLeftC[interval]}{":"}
        </span>
      );
      else{
        timerComponentsC.push(
          <span>
            {timeLeftC[interval]}
          </span>
        );
      }
    });
    const getTeamDataC = (id) => {
      let a;
      let priviouseEvent;
      let upperTime;
      let lowerTime;
      let difference;
      Object.keys(setNhlEventData).forEach((item, i) => {
      if(item===id){
      // if(item!=='type'){
        if(setNhlEventData[item]?.length!==0){
          let liveClockData = setNhlEventData[item].filter(x => x?.id  == id);
          if(liveClockData?.length > 0)
          {
            a = liveClockData[liveClockData?.length-1];
            if(liveClockData?.length > 1){
              let i=1;
              liveClockData?.forEach((element,index) => {
                if(liveClockData[index-i]?.eventData?.clock==a?.eventData?.clock){
                  i++;
                }
                else if(liveClockData[index-i]?.eventData?.clock!=a?.eventData?.clock){
                  priviouseEvent=liveClockData[index-i]
                }
              });
              // priviouseEvent=liveClockData[liveClockData?.length-2]
              upperTime=moment.duration(`00:${priviouseEvent?.eventData?.clock}`).asSeconds()
              lowerTime=moment.duration(`00:${a?.eventData?.clock}`).asSeconds()
              difference=upperTime-lowerTime
            }
            if(a?.eventData?.clock!==checkClockDataC){
              if(a?.eventData?.clock=="00:00"){
                  // setTimeLeft("00:00")
                  setTimeout(() => {
                    setTimeLeftC(a?.eventData?.clock)
                  }, 500);
                }
              else if(priviouseEvent &&difference>0&&clockWithNewC&&firstRenderClockC) {
                  calculateTimeLeftC(a?.eventData?.clock,priviouseEvent?.eventData?.clock);
                }  
              else if(priviouseEvent && difference>0 &&clockWithNewC&&!firstRenderClockC) {
                calculateTimeLeftC(a?.eventData?.clock,timeLeftC);
              }   
              else {
                // calculateTimeLeftC(a?.eventData?.clock,"20:00");
                setTimeout(() => {
                  setTimeLeftC(a?.eventData?.clock)
                }, 500);
              } 
              }
          }
          else{
            if(!a)
              a = false;
            }
          }
      }
      })
      if(a)
        return a
      };
    // const getStatus = (type) => {
    //     if (
    //       `${status}`?.toLocaleLowerCase() === "scheduled" &&
    //       moment().diff(moment(scheduled).format()) < 0
    //     ) {
    //       return `${moment(scheduled).format("MMM Do")} - ${moment(
    //         scheduled
    //       ).format("hh:mm A")}`;
    //     } else {
    //       let getMatchStatusDetails = match_status?.filter(x => x?.id == (type=='nhl'?matchId:match?.id));
    //       if(getMatchStatusDetails.length > 0)
    //       {
    //         let a = getMatchStatusDetails[getMatchStatusDetails.length - 1];
    //         return a?.status;
    //       }
    //       return status;
    //     }
    //     return status;
    // };
    const RenderStatus = ({ success = false, danger = false }) => (
        <p className={styles.status}>
            {(getStatus("nhl")=='inprogress' ||getStatus("nhl")=='created')? (Object.keys(setNhlEventData).length)<=1?"starting soon":<p className={styles.inprogressgame}>In Progress</p>:getStatus("nhl")}
        </p>
    );
    const getLiveGameData = (id) => {
        let a=false;
        Object.keys(setNhlEventData).forEach((item, i) => {
        if(item===id){
          let liveClockData = setNhlEventData[item].filter(x => x?.id  == id);
            if(liveClockData?.length > 0)
            {
              a= liveClockData[0];
              }
            }
          })
          return a
        };
    const onSwap = async (player,swapPlayer) => {
        // console.log("swapResponse", swapResponse);
        // console.log("props.swapCount", props.swapCount);
        if (props.swapCount === 0) {
          alert("You cannot swap the players.");
          return;
        }
        let getLiveData=await getLiveGameData(swapPlayer?.match?.id)

        const [swapablePlayer] =
          !isEmpty(playerList) &&
          playerList?.listData?.length &&
          playerList?.listData?.filter(
            (player1) =>
              player1?.id === swapPlayer?.id && player1?.match?.id === swapPlayer?.match?.id
          );
    
    
        if (swapablePlayer) {
          let payload = {
            gameID: gameID,
            userID: localStorage.getItem('PERSONA_USER_ID'),
            playerID: player?.id,
            prePlayerName:player?.full_name,
            swapPlayerID: swapPlayer?.id,
            swapPlayerPositionID: player?.fantasyPlayerPosition + player?.positionID,
            period: getLiveData? getLiveData?.period:live_period,
            timeApplied:getLiveData?getLiveData?.eventData?.clock : live_clock
          }
          let swapResponse = await nhlActions.swapPlayer(payload);
          if(swapResponse.status == 200) {
            updateReduxState(player, swapablePlayer);
            closeRenderModal();
            props.getFantasyTeam();
            //props.useSwap(player, true);
          }
        }
      };

    return (
        <div className={styles.liveCards}>
            <div className={styles.overLine}></div>
            <div className={styles.positionHeading}>
            {fantasyPlayerPosition === "XW" || fantasyPlayerPosition === "D"
                            ? fantasyPlayerPosition + positionID 
                            : fantasyPlayerPosition}:{" "}
                            <span>
                            
                            {fantasyPlayerPosition === "P" ? positionP : null}
                            {fantasyPlayerPosition === "C" ? positionC : null}
                            {fantasyPlayerPosition === "SS" 
                            ? positionSS
                            : null}
                            {fantasyPlayerPosition === "XB1" 
                            ? positionXB1
                            : null}
                            {fantasyPlayerPosition === "XB2" 
                            ? positionXB2
                            : null}
                            {fantasyPlayerPosition === "OF1" 
                            ? positionOF1
                            : null}
                            {fantasyPlayerPosition === "OF2" 
                            ? positionOF2
                            : null}
                            {fantasyPlayerPosition === "TeamD"
                            ? teamDPoints
                            : null}
                            {" "}Pts</span> 
            </div>
            <div className={styles.mainDiv}>
                <div className={styles.leftSide + " leftSide"}>
                <Carousel
                autoPlay={false}
                showArrows={false}
                showIndicators={false}
                showStatus={false}
                infiniteLoop={true}
                interval={300000}
                swipeScrollTolerance={100}
                >
                    <div className={styles.cardStats} style={{
                      borderRadius: 20
                    }}>
                      <div className={styles.topNameSection}>
                      <div className={styles.leftSection}>
                      <p className={styles.playerName}>{type == "TD" ? name : full_name}</p>
                        <p className={styles.playersStats}>
                        
                            {(fantasyPlayerPosition==='p'||fantasyPlayerPosition==="P")&&`ERA: ${getPlayerStatsByType('ERA')}`}
                            {(fantasyPlayerPosition==='td'||fantasyPlayerPosition==="TeamD")&&`W-L: ${getTeamStatsByType()}`}
                            {(fantasyPlayerPosition!=='P'&&fantasyPlayerPosition!=="TeamD")&&`Avg: ${getPlayerStatsByType('AVG')}`}
                           
                            {fantasyPlayerPosition!=="TeamD"&&(fantasyPlayerPosition!=='P'&& (<>&nbsp;&nbsp;|&nbsp;&nbsp;{(playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.hits??0)+"-"+(playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.atBats??0)}</>))}

                        </p>
                      </div>
                      <div className={styles.rightSection}>
                        
                        <span>
                            
                            {fantasyPlayerPosition === "P" ? positionP : null}
                            {fantasyPlayerPosition === "C" ? positionC : null}
                            {fantasyPlayerPosition === "SS" 
                            ? positionSS
                            : null}
                            {fantasyPlayerPosition === "XB1" 
                            ? positionXB1
                            : null}
                            {fantasyPlayerPosition === "XB2" 
                            ? positionXB2
                            : null}
                            {fantasyPlayerPosition === "OF1" 
                            ? positionOF1
                            : null}
                            {fantasyPlayerPosition === "OF2" 
                            ? positionOF2
                            : null}
                            {fantasyPlayerPosition === "TeamD"
                            ? teamDPoints
                            : null}
                            </span>
                            <p>Points</p>
                      </div>
                      </div>
                        
                      <div className={styles.statisticsMLBFantasy}>
                                    {/* {playersActualScore?.map((item)=>{ */}
                                        
                                        {fantasyPlayerPosition == "P" && (
                                            <div className={styles.otherPlayer}>
                                              <div className={styles.pitcherTwo}>
                                              <p>Inning Pitched: <span>{playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.inningsPitched??0}</span></p>
                                                <p>Strike-outs:  <span>{playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.strikeOuts??0}</span></p>
                                               
                                              </div>
                                              <div>
                                                <p> Runs allowed:  <span>{playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.runsAllowed??0}</span></p>
                                                <p> Walks:  <span>{playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.bb??0}</span></p>
                                                </div>
                                            </div>
                                        )}

                                        {(fantasyPlayerPosition !== "P" && fantasyPlayerPosition !== "TeamD") && 
                                            <div className={styles.otherPlayer1}>
                                            <p>HR: <span>{playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.hr?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.hr:0}</span></p>
                                            <p>S: <span>{playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.s?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.s:0}</span></p>
                                            <p>D: <span>{playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.d?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.d:0}</span></p>
                                            
                                            </div>
                                        }
                                        {(fantasyPlayerPosition !== "P" && fantasyPlayerPosition !== "TeamD") && 
                                            <div className={styles.otherPlayer1}>
                                            <p>T: <span>{playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.t?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.t:0}</span></p>
                                            <p>BB: <span>{playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.bb?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.bb:0}</span></p>
                                            <p>SB: <span>{playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.sb?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.sb:0}</span></p>
                                            </div>
                                        }
                                        {(fantasyPlayerPosition !== "P" && fantasyPlayerPosition !== "TeamD") && 
                                            <div className={styles.otherPlayer1}>
                                            
                                            <p>RS: <span>{playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.rs?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.rs:0}</span></p>
                                            <p>RBI: <span>{playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.rbi?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.rbi:0}</span></p>
                                            <p>Sac: <span>{playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.sac?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.sac:0}</span></p>
                                            </div>
                                        }
                                        { fantasyPlayerPosition === "TeamD"&&
                                         <div className={styles.otherPlayer}>
                                         <p>ER Against: {teamDActualScore?.runsAgainst?teamDActualScore?.runsAgainst:0}</p>
                                         <p>HR Against: {teamDActualScore?.homeRunsAgainst?teamDActualScore?.homeRunsAgainst:0}</p>
                                         </div>
                                        }
                                        
                                        
                                    </div>
                        <div className={styles.bottomStats}>
                            <p className={styles.teams}>
                                <span>
                                    <img src={BatsIcon} alt="Hockey Icon" width={12} height={12} />
                                </span>
                                {fantasyPlayerPosition == "TeamD" ? (
                                    isFromAwayTeam == true ? (
                                        <><span>{away?.name} {getTeamPoints(match?.matchId, match?.away?.id) !== false? getTeamPoints(match?.matchId, match?.away?.id):0}</span> vs {home?.name} {getTeamPoints(match?.matchId, match?.home?.id) !== false? getTeamPoints(match?.matchId, match?.home?.id):0}</>
                                    ) : (
                                      <><span>{away?.name} {getTeamPoints(match?.matchId, match?.away?.id) !== false? getTeamPoints(match?.matchId, match?.away?.id):0}</span> vs {home?.name} {getTeamPoints(match?.matchId, match?.home?.id) !== false? getTeamPoints(match?.matchId, match?.home?.id):0}</>
                                      )
                                    ):(
                                    isFromAwayTeam == true ? (
                                        <><span>{away?.name} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0}</span> vs {home?.name} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</>
                                    ) : (
                                        <>{away?.name} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0} vs <span>{home?.name} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</span></>
                                    ))}
                            </p>
                            <p className={styles.teams}>
                              <span>
                                <img src={img1} alt="Bat Icon" width={11}  />
                              </span>
                              {(`${status}`?.toLocaleLowerCase() !== "scheduled" &&
                                    moment().diff(moment.utc(scheduled).local().format()) >= 0)? (  
                                    <>
                                        <>{getInningHalf(match?.id)!==false?getInningHalf(match?.id):(match?.outcomes?.top_or_bot??"Bot 1st")} |{" "}
                                        {
                                          fantasyPlayerPosition !== "TeamD"?
                                          getCurrentOuts(match?.id,match?.home?.id)!==false?getCurrentOuts(match?.id,match?.home?.id):(match?.outcomes?.count?.outs??0):
                                          getCurrentOuts(match?.matchId,match?.home?.id)!==false?getCurrentOuts(match?.matchId,match?.home?.id):(match?.outcomes?.count?.outs??0)
                                        } Out</>
                                    </>
                                ):(
                                    scheduled?
                                    `${moment.utc(scheduled).local().format("MMM Do")} - ${moment.utc(
                                    scheduled
                                ).local().format("hh:mm A")}`:'Loading...'
                                )}
                            </p>
                            
                        </div>
                    </div>
                    {/* <div className={styles.playerStatsSection}>
                        <p className={styles.playerName}>Aaron Rodgers</p>
                        <p className={styles.pointSummary}>
                            Points Summary
                        </p>
                        <div className={styles.statsTable}>
                            <div className={styles.top}>
                                <p>Time</p>
                                <p>Type</p>
                                <p>Power</p>
                                <p>Pts</p>
                            </div>
                            <div className={styles.bottom}>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                            </div>
                        </div>
                        <p className={styles.status}>
                            Total Points: 27
                        </p>
                    </div>
                    <div className={styles.teamImage}>
                        <img src={cardTeamImage} />
                    </div> */}
                </Carousel>
                </div>
                {/* <div className={styles.fixedRight}>
                    <div className={styles.points}>
                        <p>Points</p>
                        {type == 'TD' ? (
                            <span className={styles.points}>{teamDPts}</span>
                        ) : (
                          <span>
                            
                          {fantasyPlayerPosition === "P" ? positionP : null}
                          {fantasyPlayerPosition === "C" ? positionC : null}
                          {fantasyPlayerPosition === "SS" 
                          ? positionSS
                          : null}
                          {fantasyPlayerPosition === "XB1" 
                          ? positionXB1
                          : null}
                          {fantasyPlayerPosition === "XB2" 
                          ? positionXB2
                          : null}
                          {fantasyPlayerPosition === "OF1" 
                          ? positionOF1
                          : null}
                          {fantasyPlayerPosition === "OF2" 
                          ? positionOF2
                          : null}
                          {fantasyPlayerPosition === "TeamD"
                          ? teamDPoints
                          : null}
                          {" "}</span>
                        )}
                    </div>
                    <div className={styles.powers}>
                        
                        
                        {type == 'TD' ? (
                            <>
                              { (`${status}`?.toLocaleLowerCase() === "scheduled" &&
                                moment().diff(moment(scheduled).format()) < 0)||(`${status}`?.toLocaleLowerCase() === "closed")?
                              (
                                <button hidden={gameType?.toLowerCase().includes("fantasy")} style={{ background: "none", borderWidth: 0, opacity: 0.5 }}>
                                    <img
                                        src={`/images/challenge-power.svg`}
                                        alt="Challenge"
                                    />
                                </button>
                              ):(
                                <button hidden={gameType?.toLowerCase().includes("fantasy")} style={{ background: "none", borderWidth: 0, opacity: isPowerAvailable("Challenge") ? 1 : 0.5 }}>
                                    <img
                                        src={`/images/challenge-power.svg`}
                                        alt="Challenge"
                                    />
                                </button>
                              )}
                                {
                                 (`${status}`?.toLocaleLowerCase() === "scheduled" &&
                                 moment().diff(moment(scheduled).format()) < 0)||(`${status}`?.toLocaleLowerCase() === "closed")?
                                 (
                                  <button hidden={gameType?.toLowerCase().includes("fantasy")} style={{ background: "none", borderWidth: 0, opacity: 0.5 }}>
                                  <img
                                      src={`/images/sheilds.svg`}
                                      alt="D-Wall"
                                  />
                                   </button>
                                 ):(
                                  <button hidden={gameType?.toLowerCase().includes("fantasy")} style={{ background: "none", borderWidth: 0, opacity: isPowerAvailable("D-Wall") ? 1 : 0.5 }}>
                                  <img
                                      src={`/images/sheilds.svg`}
                                      alt="D-Wall"
                                  />
                                   </button>
                                 )
                                }
            
                            </>
                        ) : (
                            <>
                            {(`${status}`?.toLocaleLowerCase() === "scheduled" &&
                                 moment().diff(moment(scheduled).format()) < 0)||(`${status}`?.toLocaleLowerCase() === "closed")?
                                 (
                                  <button hidden={gameType?.toLowerCase().includes("fantasy")} style={{ background: "none", borderWidth: 0, opacity:  0.5 }} >
                                  <img
                                      src={`/images/repeat.svg`}
                                      alt="Swap"
                                  />
                                  </button>
                                 ):(
                                  <button hidden={gameType?.toLowerCase().includes("fantasy")} style={{ background: "none", borderWidth: 0, opacity: isPowerAvailable("Swap") ? 1 : 0.5 }} 
                                  onClick={() => {
                                     if( isPowerAvailable("Swap")) { toggleReplaceModal(playerData); setReplaceModalState(true) }
                                  }}>
                                  <img
                                      src={`/images/repeat.svg`}
                                      alt="Swap"
                                  />
                                 </button>
                                 )}
                                {
                                  (`${status}`?.toLocaleLowerCase() === "scheduled" &&
                                  moment().diff(moment(scheduled).format()) < 0)||(`${status}`?.toLocaleLowerCase() === "closed")?
                                  (
                                    <button hidden={gameType?.toLowerCase().includes("fantasy")} style={{ background: "none", borderWidth: 0, opacity:  0.5}}>
                                    <img
                                        src={`/images/xp.svg`}
                                        alt="Point Booster"
                                    />
                                      </button>
                                  ):(
                                    <button hidden={gameType?.toLowerCase().includes("fantasy")} style={{ background: "none", borderWidth: 0, opacity: isPowerAvailable("Point Booster") ? 1 : 0.5}} 
                                    onClick={() => {if( isPowerAvailable("Point Booster")) setSecondModal(true)}}>
                                    <img
                                        src={`/images/xp.svg`}
                                        alt="Point Booster"
                                    />
                                </button>
                                  )
                                }

                            </>
                        )}
                        
                    </div>
                </div> */}
            </div>
            <SwapModalMobile
                player={playerData}
                visible={showReplaceModal}
                onClose={closeRenderModal}
                onSwap={onSwap}
                scrollable={true}
                playerList={playerList}
                starPlayerCount={starPlayerCount}
                loading={loadingPlayerList}
                dataMain={selectedTeam}
                pageType="nhl"
            />
    </div>
    );
};
export default TeamManagerMobileCard;