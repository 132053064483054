import classes from './styles.module.scss';
import TwitterIcon from "../../icons/TwitterIcon";
import FacebookIcon from "../../icons/FacebookIcon";
const SocialShare = () => {
    const shareButton = (type = '') => {
        let left = window.screen.width / 2 - 600 / 2;
        let top = window.screen.height / 2 - 600 / 2;
        let url = '';
        if(type == 'facebook') {
            url = `https://www.facebook.com/dialog/share?app_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&display=popup&href=http://defygames.io&quote=${process.env.REACT_APP_POST_SHARING_TEXT}&redirect_uri=http://defygames.io`;
        }
        else {
            url = `https://twitter.com/intent/tweet?text=${process.env.REACT_APP_POST_SHARING_TEXT}`;
        }
        window.open(
            url,
            "targetWindow",
            "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600,left=" +
            left +
            ",top=" +
            top
        );
    };
    return (
        <div className={classes.OuterDiv}>
            <p className={classes.shareToLockTitle}> Share to unlock: </p>
            <div className={classes.buttonsDiv}>
                <button onClick={() => shareButton('facebook')}>
                    <FacebookIcon size={25}/>
                </button>
                <button onClick={() => shareButton('twitter')}>
                    <TwitterIcon size={25}/>
                </button>
            </div>
        </div>
    );
};
export default SocialShare;