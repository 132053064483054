import http from "../config/http";
import { URLS } from "../config/urls";
import moment from "moment";
import { CONSTANTS } from "../utility/constants";
import {getTeamManagerServer} from "../config/apiURL";
import { getMLBApiURL } from "../config/environment";
let REACT_APP_API_URL= getTeamManagerServer();
let SET_MLB_API_SERVER_URL=getMLBApiURL()

export const updateTeamManagerData = (gameID, userID) => {
    return async (dispatch) => {
        try {
            const responseFromAPI = await http.post(`${REACT_APP_API_URL}/api/v1/services/fantasy/getFantasyTeam`,
            {
                gameID,
                userID
            }
            );
            console.log("responseFromAPINEW", responseFromAPI);
            const {
                fantasyTeam = {},
            } = responseFromAPI.data || {};
            const payload = {
                gameID: fantasyTeam.gameID,
                    userID: fantasyTeam.userID,
                    league: "NHL",
                    livePlayers: fantasyTeam.players,
                    liveTeamD: fantasyTeam.teamD,
                    playersPoints: {
                        posCenterPoints: fantasyTeam.posCenterPoints,
                        posD1Points: fantasyTeam.posD1Points,
                        posD2Points: fantasyTeam.posD2Points,
                        posGoaliePoints: fantasyTeam.posGoaliePoints,
                        posXW1Points: fantasyTeam.posXW1Points,
                        posXW2Points: fantasyTeam.posXW2Points,
                        posXW3Points: fantasyTeam.posXW3Points,
                        teamDPts: fantasyTeam.teamDPts
                    },
                    playersActualScore: fantasyTeam.playerActualScore,
                    teamDActualScore: [],
                    powersAvailable: fantasyTeam.powersAvailable,
                    swappedPlayers: fantasyTeam.swappedPlayers,
                    teamLogs: fantasyTeam.teamLogs,
                    rankings: [],
                    fantacyGames: []
            }
            console.log("payload", payload);
            dispatch({
                type: CONSTANTS.TEAM_MANAGER.ACTIONTYPES.UPDATE_FULL_DATA,
                payload: payload
            });
        }
        catch (err) {
            return err;
        }
    };
};
export const updateTeamManagerDataDummy = (gameID, userID, data) => {
    return async (dispatch) => {
        try {
            let fantasyTeam = data;
            // let fantasyPlayers={...fantasyTeam?.players,match:data?.matchStatus?.filter((item)=>item?.data?.gameFeedId==="01acd7f3-de6e-4fd8-b72d-2d33013c4534")}
            // console.log("fantasyPlayers=>",fantasyPlayers);
            fantasyTeam?.players?.map((item,index)=>{
                let matchdata=data?.matchStatus?.filter((dt)=>dt.gameFeedId===item?.matchId)
                if(matchdata?.length>0){
                    fantasyTeam.players[index].match=matchdata[0];
                    if(matchdata[0]?.away?.id===item?.teamId){
                        fantasyTeam.players[index].isFromAwayTeam=true;
                        fantasyTeam.players[index].isFromHomeTeam=false;
                    }
                    if(matchdata[0]?.home?.id===item?.teamId){
                        fantasyTeam.players[index].isFromAwayTeam=false;
                        fantasyTeam.players[index].isFromHomeTeam=true;
                    }
                }
            })
            let matchdataTeamD=data?.matchStatus?.filter((dt)=>dt.gameFeedId===fantasyTeam?.team?.teamD?.matchId)
            if(matchdataTeamD[0]){
                if(matchdataTeamD[0]?.away.id===fantasyTeam?.team?.teamD?.id){
                    fantasyTeam.team.isFromAwayTeam=true
                    fantasyTeam.team.isFromHomeTeam=false
                }
                if(matchdataTeamD[0]?.home.id===fantasyTeam?.team?.teamD?.id){
                    fantasyTeam.team.isFromAwayTeam=false
                    fantasyTeam.team.isFromHomeTeam=true                }
            }
            let fantasyteamD={...fantasyTeam.team, type:'td',match:matchdataTeamD[0]}
            const payload = {
                    gameID: data?.team?.gameID,
                    userID: data?.team?.userID,
                    league: "MLB",
                    livePlayers: fantasyTeam.players,
                    liveTeamD: fantasyteamD,
                    playersPoints: fantasyTeam.team,
                    playersActualScore: fantasyTeam.playerScoresList,
                    teamDActualScore: data?.team?.teamDStats,
                    powersAvailable: fantasyTeam?.powersAvailable,
                    swappedPlayers: fantasyTeam?.swappedPlayers,
                    teamLogs: fantasyTeam.teamLogs,
                    rankings: [],
                    fantacyGames: [],
                    matchStatus:data?.matchStatus
            }
            console.log("payload", payload);
            dispatch({
                type: CONSTANTS.TEAM_MANAGER.ACTIONTYPES.UPDATE_FULL_DATA,
                payload: payload
            });
        }
        catch (err) {
            return err;
        }
    };
};
