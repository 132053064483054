import React, { useEffect } from "react";
import http from "../../config/http";
import { getApiURL } from "../../config/apiURL";
import StarPower from "../../assets/declined.png";
import styles from "./styles.module.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useDispatch } from "react-redux";
import { showDepositForm } from "../../actions/uiActions";
import { redirectTo } from "../../utility/shared";
let API_URL = getApiURL();

function Declined(props) {
  useEffect(async () => {
    let res = await http.get(`${API_URL}/api/v1/users/catch-nothanks-url`);
    console.log(res)
  }, []);
  const dispatch = useDispatch();

  const setShowDepositModal = () => dispatch(showDepositForm());
  const onGoBack = () => {
    redirectTo(props, { path: "/my-game-center" });
  };
  return (
    <>
      <Header style={{ maxWidth: 1240 }} />
      <div className={styles.depositSection}>
        <div className={styles.depositSectionInner}>
          <div className={styles.depositSectionIcon}>
            <img src={StarPower} alt="" />
          </div>
          <div className={styles.depositSectionDetail}>
            <p>
              It seems there was an issue with the transaction.
              <br />
              Please try again or use a different deposit
              <br />
              method.
            </p>
          </div>
          <div className={styles.depositSectionButton}>
            <button
              className={styles.depositButton}
              onClick={setShowDepositModal}
            >
              Deposit
            </button>
            <button className={styles.gameCenterButton} onClick={onGoBack}>
              Go to My Game Center
            </button>
          </div>
        </div>
      </div>
      <Footer isBlack={true} />
    </>
  );
}

export default Declined;
