export const DiamondIcons = {
    twos: () => (
        <>
           <path fill="#DF3439" d="M5.386 17.439c.674 1.36 1.658 2.543 2.871 3.455-1.211.908-2.195 2.085-2.871 3.44-.689-1.356-1.676-2.538-2.888-3.456 1.212-.918 2.2-2.1 2.888-3.455v.016zM67.452 77.218c.683 1.354 1.665 2.536 2.871 3.455-1.206.92-2.188 2.101-2.871 3.455-.683-1.354-1.665-2.536-2.871-3.455 1.206-.92 2.188-2.1 2.871-3.455zM36.419 10.544c1.705 3.388 4.163 6.34 7.186 8.63-3.015 2.292-5.472 5.237-7.186 8.615-1.723-3.381-4.192-6.326-7.219-8.614 3.035-2.287 5.505-5.24 7.219-8.63zM36.419 73.779c1.709 3.385 4.167 6.336 7.186 8.63-3.015 2.291-5.472 5.236-7.186 8.614-1.723-3.38-4.192-6.326-7.219-8.614"/> 
        </>
    ),
    threes: () => (
        <>
            <path fill="#DF3439" d="M5.386 17.439c.674 1.36 1.658 2.543 2.871 3.455-1.211.908-2.195 2.085-2.871 3.44-.689-1.356-1.676-2.538-2.888-3.456 1.212-.918 2.2-2.1 2.888-3.455v.016zM67.452 77.218c.683 1.354 1.665 2.536 2.871 3.455-1.206.92-2.188 2.101-2.871 3.455-.683-1.354-1.665-2.536-2.871-3.455 1.206-.92 2.188-2.1 2.871-3.455zM36.419 10.544c1.7 3.392 4.158 6.345 7.186 8.63-3.02 2.288-5.477 5.234-7.186 8.615-1.723-3.381-4.192-6.326-7.219-8.614 3.035-2.287 5.505-5.24 7.219-8.63zM36.419 42.178c1.704 3.388 4.162 6.341 7.186 8.63-3.02 2.287-5.477 5.233-7.186 8.614-1.721-3.387-4.19-6.338-7.219-8.63 3.03-2.286 5.499-5.232 7.219-8.614zM36.419 73.779c1.704 3.388 4.162 6.34 7.186 8.63-3.02 2.287-5.477 5.233-7.186 8.614-1.723-3.38-4.192-6.326-7.219-8.614"/>
        </>
    ),
    fours: () => (
        <>
            <path fill="#DF3439" d="M5.386 17.439c.674 1.36 1.658 2.543 2.871 3.455-1.211.908-2.195 2.085-2.871 3.44-.683-1.36-1.672-2.542-2.888-3.456 1.216-.914 2.205-2.096 2.888-3.455v.016zM67.452 77.218c.683 1.354 1.665 2.536 2.871 3.455-1.206.92-2.188 2.101-2.871 3.455-.683-1.354-1.665-2.536-2.871-3.455 1.206-.92 2.188-2.1 2.871-3.455zM18.899 10.544c1.694 3.39 4.147 6.344 7.17 8.63-3.015 2.288-5.468 5.235-7.17 8.615-1.725-3.375-4.187-6.319-7.203-8.614 3.024-2.294 5.487-5.245 7.203-8.63zM18.899 73.779c1.698 3.387 4.151 6.34 7.17 8.63-3.015 2.287-5.468 5.234-7.17 8.614-1.725-3.375-4.187-6.319-7.203-8.614 3.02-2.298 5.483-5.248 7.203-8.63zM53.955 10.544c1.703 3.385 4.156 6.336 7.17 8.63-3.01 2.293-5.462 5.238-7.17 8.615-1.714-3.378-4.17-6.323-7.186-8.614 3.02-2.294 5.477-5.245 7.186-8.63zM53.955 73.779c1.703 3.384 4.156 6.335 7.17 8.63-3.01 2.292-5.462 5.237-7.17 8.614-1.714-3.378-4.17-6.323-7.186-8.614 3.02-2.294 5.477-5.245 7.186-8.63zM8.257"/>
        </>
    ),
    fives: () => (
        <>
            <path fill="#DF3439" d="M5.386 17.439c.674 1.36 1.658 2.543 2.871 3.455-1.211.908-2.195 2.085-2.871 3.44-.683-1.36-1.672-2.542-2.888-3.456 1.216-.914 2.205-2.096 2.888-3.455v.016zM67.452 77.218c.683 1.354 1.665 2.536 2.871 3.455-1.206.92-2.188 2.101-2.871 3.455-.683-1.354-1.665-2.536-2.871-3.455 1.206-.92 2.188-2.1 2.871-3.455zM18.899 10.544c1.694 3.39 4.147 6.344 7.17 8.63-3.015 2.288-5.468 5.235-7.17 8.615-1.725-3.375-4.187-6.319-7.203-8.614 3.024-2.294 5.487-5.245 7.203-8.63zM36.419 42.178c1.705 3.388 4.163 6.34 7.186 8.63-3.015 2.291-5.472 5.237-7.186 8.614-1.721-3.387-4.19-6.338-7.219-8.63 3.033-2.282 5.503-5.229 7.219-8.614zM18.899 73.779c1.698 3.387 4.151 6.34 7.17 8.63-3.015 2.287-5.468 5.234-7.17 8.614-1.725-3.375-4.187-6.319-7.203-8.614 3.02-2.298 5.483-5.248 7.203-8.63zM53.955 10.544c1.7 3.387 4.152 6.34 7.17 8.63-3.01 2.293-5.462 5.238-7.17 8.615-1.714-3.378-4.17-6.323-7.186-8.614 3.023-2.29 5.481-5.243 7.186-8.63zM53.955 73.779c1.703 3.384 4.156 6.335 7.17 8.63-3.01 2.292-5.462 5.237-7.17 8.614-1.714-3.378-4.17-6.323-7.186-8.614"/>
        </>
    ),
    sixs: () => (
        <>
            <path fill="#DF3439" d="M5.386 17.439c.674 1.36 1.658 2.543 2.871 3.455-1.211.908-2.195 2.085-2.871 3.44-.683-1.36-1.672-2.542-2.888-3.456 1.216-.914 2.205-2.096 2.888-3.455v.016zM67.452 77.218c.683 1.354 1.665 2.536 2.871 3.455-1.206.92-2.188 2.101-2.871 3.455-.683-1.354-1.665-2.536-2.871-3.455 1.206-.92 2.188-2.1 2.871-3.455zM18.899 10.544c1.694 3.39 4.147 6.344 7.17 8.63-3.015 2.288-5.468 5.235-7.17 8.615-1.725-3.375-4.187-6.319-7.203-8.614 3.024-2.294 5.487-5.245 7.203-8.63zM18.899 42.178c1.698 3.387 4.151 6.34 7.17 8.63-3.015 2.288-5.468 5.234-7.17 8.614-1.725-3.375-4.187-6.319-7.203-8.614 3.02-2.298 5.483-5.248 7.203-8.63zM18.899 73.779c1.698 3.387 4.151 6.34 7.17 8.63-3.015 2.287-5.468 5.234-7.17 8.614-1.725-3.375-4.187-6.319-7.203-8.614 3.02-2.298 5.483-5.248 7.203-8.63zM53.955 10.544c1.7 3.387 4.152 6.34 7.17 8.63-3.01 2.293-5.462 5.238-7.17 8.615-1.714-3.378-4.17-6.323-7.186-8.614 3.023-2.29 5.481-5.243 7.186-8.63zM53.955 42.178c1.703 3.384 4.156 6.335 7.17 8.63-3.01 2.292-5.462 5.237-7.17 8.614-1.714-3.377-4.17-6.323-7.186-8.614 3.02-2.294 5.477-5.245 7.186-8.63zM53.955 73.779c1.703 3.384 4.156 6.335 7.17 8.63-3.01 2.292-5.462 5.237-7.17 8.614-1.714-3.378-4.17-6.323-7.186-8.614 3.02-2.294 5.477-5.245 7.186-8.63zM7.51"/>
        </>
    ),
    sevens: () => (
        <>
            <path fill="#DF3439" d="M5.386 17.439c.674 1.36 1.658 2.543 2.871 3.455-1.211.908-2.195 2.085-2.871 3.44-.689-1.356-1.676-2.538-2.888-3.456 1.212-.918 2.2-2.1 2.888-3.455v.016zM67.452 77.218c.683 1.354 1.665 2.536 2.871 3.455-1.206.92-2.188 2.101-2.871 3.455-.683-1.354-1.665-2.536-2.871-3.455 1.206-.92 2.188-2.1 2.871-3.455zM18.899 10.544c1.694 3.39 4.147 6.344 7.17 8.63-3.015 2.288-5.468 5.235-7.17 8.615-1.725-3.375-4.187-6.319-7.203-8.614 3.024-2.294 5.487-5.245 7.203-8.63zM18.899 42.178c1.698 3.387 4.151 6.34 7.17 8.63-3.015 2.288-5.468 5.234-7.17 8.614-1.725-3.375-4.187-6.319-7.203-8.614 3.02-2.298 5.483-5.248 7.203-8.63zM18.899 73.779c1.698 3.387 4.151 6.34 7.17 8.63-3.015 2.287-5.468 5.234-7.17 8.614-1.725-3.375-4.187-6.319-7.203-8.614 3.02-2.298 5.483-5.248 7.203-8.63zM53.955 10.544c1.7 3.387 4.152 6.34 7.17 8.63-3.01 2.293-5.462 5.238-7.17 8.615-1.714-3.378-4.17-6.323-7.186-8.614 3.023-2.29 5.481-5.243 7.186-8.63zM53.955 42.178c1.703 3.384 4.156 6.335 7.17 8.63-3.01 2.292-5.462 5.237-7.17 8.614-1.714-3.377-4.17-6.323-7.186-8.614 3.02-2.294 5.477-5.245 7.186-8.63zM53.955 73.779c1.703 3.384 4.156 6.335 7.17 8.63-3.01 2.292-5.462 5.237-7.17 8.614-1.714-3.378-4.17-6.323-7.186-8.614 3.02-2.294 5.477-5.245 7.186-8.63zM36.419 23.198c1.709 3.385 4.167 6.336 7.186 8.63-3.015 2.291-5.472 5.237-7.186 8.614-1.723-3.38-4.192-6.326-7.219-8.614 3.02-2.294 5.477-5.245 7.186-8.63h.033zM2.498"/>
        </>
    ),
    eights: () => (
        <>
            <path fill="#DF3439" d="M5.386 17.439c.674 1.36 1.658 2.543 2.871 3.455-1.211.908-2.195 2.085-2.871 3.44-.683-1.36-1.672-2.542-2.888-3.456 1.216-.914 2.205-2.096 2.888-3.455v.016zM67.452 77.218c.683 1.354 1.665 2.536 2.871 3.455-1.206.92-2.188 2.101-2.871 3.455-.683-1.354-1.665-2.536-2.871-3.455 1.206-.92 2.188-2.1 2.871-3.455zM18.899 10.544c1.694 3.39 4.147 6.344 7.17 8.63-3.015 2.288-5.468 5.235-7.17 8.615-1.725-3.375-4.187-6.319-7.203-8.614 3.024-2.294 5.487-5.245 7.203-8.63zM18.899 42.178c1.698 3.387 4.151 6.34 7.17 8.63-3.015 2.288-5.468 5.234-7.17 8.614-1.725-3.375-4.187-6.319-7.203-8.614 3.02-2.298 5.483-5.248 7.203-8.63zM18.899 73.779c1.698 3.387 4.151 6.34 7.17 8.63-3.015 2.287-5.468 5.234-7.17 8.614-1.725-3.375-4.187-6.319-7.203-8.614 3.02-2.298 5.483-5.248 7.203-8.63zM53.955 10.544c1.7 3.387 4.152 6.34 7.17 8.63-3.01 2.293-5.462 5.238-7.17 8.615-1.714-3.378-4.17-6.323-7.186-8.614 3.023-2.29 5.481-5.243 7.186-8.63zM53.955 42.178c1.703 3.384 4.156 6.335 7.17 8.63-3.01 2.292-5.462 5.237-7.17 8.614-1.714-3.377-4.17-6.323-7.186-8.614 3.02-2.294 5.477-5.245 7.186-8.63zM53.955 73.779c1.703 3.384 4.156 6.335 7.17 8.63-3.01 2.292-5.462 5.237-7.17 8.614-1.714-3.378-4.17-6.323-7.186-8.614 3.02-2.294 5.477-5.245 7.186-8.63zM36.435 27.22c1.698 3.389 4.151 6.341 7.17 8.631-3.015 2.288-5.467 5.234-7.17 8.614-1.734-3.378-4.207-6.322-7.235-8.614 3.02-2.297 5.482-5.248 7.203-8.63h.032zM36.435 57.102c1.698 3.388 4.151 6.34 7.17 8.63-3.015 2.288-5.467 5.234-7.17 8.614-1.734-3.378-4.207-6.322-7.235-8.614 3.02-2.297 5.482-5.247"/>
        </>
    ),
    nines: () => (
        <>
            <path fill="#DF3439" fillRule="nonzero" d="M5.386 17.439c.674 1.36 1.658 2.543 2.871 3.455-1.211.908-2.195 2.085-2.871 3.44-.683-1.36-1.672-2.542-2.888-3.456 1.216-.914 2.205-2.096 2.888-3.455v.016zM67.452 77.218c.683 1.354 1.665 2.536 2.871 3.455-1.206.92-2.188 2.101-2.871 3.455-.683-1.354-1.665-2.536-2.871-3.455 1.206-.92 2.188-2.1 2.871-3.455zM18.899 10.544c1.694 3.39 4.147 6.344 7.17 8.63-3.015 2.288-5.468 5.235-7.17 8.615-1.725-3.375-4.187-6.319-7.203-8.614 3.024-2.294 5.487-5.245 7.203-8.63zM18.899 31.633c1.702 3.385 4.155 6.337 7.17 8.63-3.014 2.282-5.467 5.223-7.17 8.598-1.725-3.37-4.187-6.308-7.203-8.597 3.017-2.302 5.478-5.25 7.203-8.63zM18.899 52.706c1.702 3.385 4.155 6.336 7.17 8.63-3.015 2.288-5.468 5.234-7.17 8.614-1.725-3.375-4.187-6.318-7.203-8.614 3.017-2.301 5.478-5.25 7.203-8.63zM18.899 73.779c1.698 3.387 4.151 6.34 7.17 8.63-3.015 2.287-5.468 5.234-7.17 8.614-1.725-3.375-4.187-6.319-7.203-8.614 3.02-2.298 5.483-5.248 7.203-8.63zM53.955 10.544c1.7 3.387 4.152 6.34 7.17 8.63-3.01 2.293-5.462 5.238-7.17 8.615-1.714-3.378-4.17-6.323-7.186-8.614 3.023-2.29 5.481-5.243 7.186-8.63zM53.955 31.633c1.708 3.382 4.16 6.332 7.17 8.63-3.014 2.283-5.466 5.223-7.17 8.598-1.71-3.375-4.168-6.316-7.186-8.597 3.016-2.298 5.473-5.248 7.186-8.63zM53.955 52.706c1.708 3.381 4.16 6.332 7.17 8.63-3.01 2.292-5.462 5.238-7.17 8.614-1.714-3.377-4.17-6.322-7.186-8.614 3.016-2.297 5.473-5.248 7.186-8.63zM53.955 73.779c1.703 3.384 4.156 6.335 7.17 8.63-3.01 2.292-5.462 5.237-7.17 8.614-1.714-3.378-4.17-6.323-7.186-8.614 3.02-2.294 5.477-5.245 7.186-8.63zM36.419 42.178c1.705 3.388 4.163 6.34 7.186 8.63-3.015 2.291-5.472 5.237-7.186 8.614-1.721-3.387-4.19-6.338-7.219-8.63 3.033-2.282 5.503-5.229 7.219-8.614z"/>
            <path d="M5.402 6.878c-.283-.007-.562.06-.811.195-.232.125-.436.296-.6.503-.369.52-.557 1.147-.536 1.784-.007.478.099.95.309 1.38.168.359.454.65.81.826.245.132.518.2.796.195.277-.006.55-.078.794-.21.24-.121.447-.3.6-.52.367-.527.555-1.159.536-1.8.02-.615-.175-1.218-.552-1.704-.326-.41-.822-.649-1.346-.649z"/>
        </>
    ),
    tens: () => (
        <>
            <path fill="#DF3439" d="M5.386 17.439c.674 1.36 1.658 2.543 2.871 3.455-1.211.908-2.195 2.085-2.871 3.44-.683-1.36-1.672-2.542-2.888-3.456 1.216-.914 2.205-2.096 2.888-3.455v.016zM67.452 77.218c.683 1.354 1.665 2.536 2.871 3.455-1.206.92-2.188 2.101-2.871 3.455-.683-1.354-1.665-2.536-2.871-3.455 1.206-.92 2.188-2.1 2.871-3.455zM18.899 10.544c1.694 3.39 4.147 6.344 7.17 8.63-3.015 2.288-5.468 5.235-7.17 8.615-1.725-3.375-4.187-6.319-7.203-8.614 3.024-2.294 5.487-5.245 7.203-8.63zM18.899 31.633c1.702 3.385 4.155 6.337 7.17 8.63-3.014 2.282-5.467 5.223-7.17 8.598-1.725-3.37-4.187-6.308-7.203-8.597 3.017-2.302 5.478-5.25 7.203-8.63zM18.899 52.706c1.702 3.385 4.155 6.336 7.17 8.63-3.015 2.288-5.468 5.234-7.17 8.614-1.725-3.375-4.187-6.318-7.203-8.614 3.017-2.301 5.478-5.25 7.203-8.63zM18.899 73.779c1.698 3.387 4.151 6.34 7.17 8.63-3.015 2.287-5.468 5.234-7.17 8.614-1.725-3.375-4.187-6.319-7.203-8.614 3.02-2.298 5.483-5.248 7.203-8.63zM53.955 10.544c1.7 3.387 4.152 6.34 7.17 8.63-3.01 2.293-5.462 5.238-7.17 8.615-1.714-3.378-4.17-6.323-7.186-8.614 3.023-2.29 5.481-5.243 7.186-8.63zM53.955 31.633c1.708 3.382 4.16 6.332 7.17 8.63-3.01 2.287-5.461 5.227-7.17 8.598-1.714-3.372-4.171-6.312-7.186-8.597 3.016-2.298 5.473-5.248 7.186-8.63zM53.955 52.706c1.703 3.384 4.156 6.336 7.17 8.63-3.01 2.292-5.462 5.238-7.17 8.614-1.714-3.377-4.17-6.322-7.186-8.614 3.02-2.293 5.477-5.245 7.186-8.63zM53.955 73.779c1.703 3.384 4.156 6.335 7.17 8.63-3.01 2.292-5.462 5.237-7.17 8.614-1.714-3.378-4.17-6.323-7.186-8.614 3.02-2.294 5.477-5.245 7.186-8.63zM36.419 22.046c1.704 3.389 4.162 6.341 7.186 8.63-3.02 2.287-5.477 5.233-7.186 8.614-1.723-3.38-4.192-6.326-7.219-8.614 3.02-2.293 5.477-5.245 7.186-8.63h.033zM36.419 62.277c1.704 3.389 4.162 6.341 7.186 8.63-3.016 2.278-5.473 5.213-7.186 8.582-1.727-3.37-4.195-6.303-7.219-8.582 3.02-2.293 5.477-5.245 7.186-8.63h.033zM2.498"/>
        </>
    ),
}