import React from 'react'

function ClubAce() {
    return (
        <>
            <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
        <g transform="translate(0 1)">
            <path fill="#010000" fillRule="nonzero" d="M5.37 17.455c-.4.024-.773.212-1.03.518-.258.307-.378.706-.333 1.104-.003.456.185.891.519 1.2.097.098 0 .195 0 .13-.196-.12-.42-.187-.649-.194-.74.11-1.288.744-1.288 1.492s.548 1.383 1.288 1.493c.489-.015.928-.304 1.136-.746v-.098.195c-.03.616-.173 1.22-.422 1.784.255-.063.516-.101.779-.113.268.012.534.05.794.113-.288-.588-.46-1.227-.502-1.881v-.195.097c.207.443.646.732 1.135.746.74-.109 1.288-.744 1.288-1.492s-.548-1.383-1.288-1.492c-.23.007-.453.074-.649.194-.113 0-.146 0 0-.13.334-.31.522-.745.52-1.2.038-.772-.53-1.44-1.298-1.525zM67.452 84.128c.401-.024.775-.21 1.035-.517.26-.306.384-.705.344-1.105.003-.455-.185-.89-.52-1.2-.113-.098 0-.211 0-.146.195.122.42.19.65.194.76-.08 1.337-.72 1.337-1.484s-.577-1.405-1.337-1.484c-.487.008-.927.29-1.136.73 0 0 0 .113-.081.113s0 0 0-.21c.05-.65.226-1.284.52-1.866-.264.048-.53.08-.796.097-.261-.016-.521-.049-.778-.097.28.585.451 1.218.502 1.865V79.116c-.217-.44-.661-.72-1.151-.73-.76.08-1.337.72-1.337 1.484s.577 1.405 1.337 1.484c.235-.005.464-.072.665-.194.097 0 .146 0 0 .146-.334.31-.522.745-.52 1.2-.086.794.475 1.513 1.266 1.622zM36.419 42.178c-2.007.13-3.534 1.853-3.423 3.86-.012 1.136.453 2.225 1.282 3.002.26.243.146.503-.114.34-.488-.303-1.048-.47-1.622-.486-1.863.247-3.256 1.835-3.256 3.715s1.393 3.468 3.256 3.715c1.22-.026 2.324-.734 2.855-1.834 0-.113.097-.26.178-.26.081 0 .163.18.163.52-.126 1.623-.555 3.208-1.266 4.672.644-.155 1.301-.247 1.963-.276.662.029 1.32.121 1.963.276-.718-1.461-1.148-3.048-1.265-4.672 0-.34 0-.52.162-.52s.113.147.178.26c.529 1.095 1.624 1.802 2.84 1.834 1.862-.247 3.255-1.836 3.255-3.715 0-1.88-1.393-3.468-3.256-3.715-.574.016-1.134.183-1.622.486-.276.163-.39-.097-.114-.34.829-.777 1.294-1.866 1.282-3.001.11-2.014-1.426-3.74-3.44-3.861z"/>
            <path d="M4.526 12.426L6.278 12.426 5.402 7.738z"/>
            <path fill="#000" fillRule="nonzero" d="M7.592 14.795L5.97 5.954H4.867l-1.623 8.84h-.746v.925h2.223v-.924h-.65l.26-1.363H6.49l.26 1.363h-.714v.924h2.222v-.924h-.665zm-3.066-2.369l.876-4.688.876 4.688H4.526z"/>
            <path d="M68.312 89.141L66.56 89.157 67.436 93.829z"/>
            <path fill="#000" fillRule="nonzero" d="M65.23 86.789l1.622 8.825h1.103l1.622-8.825h.681v-.941h-2.125v.94h.633l-.243 1.347h-2.158l-.243-1.346h.665v-.941H64.58v.94h.649zm3.082 2.433l-.876 4.688-.925-4.688h1.8z"/>
        </g>
    </g>
        </>
    )
}

export default ClubAce
