export const nhlData = {
  gameID: 1543,
  userID: 95,
  league: 'NHL',
  gameType: 'Fantasy',
  livePlayers: [
  {
    _id: '6232bcb7c917cef5b827109d',
    gameID: 1543,
    id: 'a3c2916b-f92a-11e2-a133-f4ce4684ea4c',
    userID: 95,
    fantasyPlayerPosition: 'C',
    full_name: 'Elias Lindholm',
    isFromAwayTeam: false,
    isFromHomeTeam: true,
    is_starPlayer: true,
    matchId: '32c8e541-491c-47b0-9f48-32788c3f08c0',
    positionID: 1,
    star_category: 2,
    teamId: '44159241-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c67',
      id: '32c8e541-491c-47b0-9f48-32788c3f08c0',
      away: {
        id: '4416d559-0f24-11e2-8525-18a905767e44',
        name: 'Buffalo Sabres',
        alias: 'BUF',
        sr_id: 'sr:team:3678',
        reference: '7'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44159241-0f24-11e2-8525-18a905767e44',
        name: 'Calgary Flames',
        alias: 'CGY',
        sr_id: 'sr:team:3679',
        reference: '20'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20980',
      scheduled: '2022-03-19T01:00:00Z',
      status: 'scheduled',
      venue: {
        id: '152d63d2-825f-4928-a08a-70564268b405',
        name: 'Scotiabank Saddledome',
        capacity: 19289,
        address: '555 Saddledome Rise SE',
        city: 'Calgary',
        state: 'AB',
        country: 'CAN',
        time_zone: 'US/Mountain',
        sr_id: 'sr:venue:5952'
      },
      broadcasts: [
        {
          network: 'SNW',
          type: 'TV',
          locale: 'International'
        },
        {
          network: 'MSG-B',
          type: 'TV',
          locale: 'Away',
          channel: '635'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b827109e',
    gameID: 1543,
    id: '3de1bc1d-ac48-4b05-8072-086729333f26',
    userID: 95,
    fantasyPlayerPosition: 'XW',
    full_name: 'Peyton Krebs',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: false,
    matchId: '32c8e541-491c-47b0-9f48-32788c3f08c0',
    positionID: 1,
    star_category: 0,
    teamId: '4416d559-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c67',
      id: '32c8e541-491c-47b0-9f48-32788c3f08c0',
      away: {
        id: '4416d559-0f24-11e2-8525-18a905767e44',
        name: 'Buffalo Sabres',
        alias: 'BUF',
        sr_id: 'sr:team:3678',
        reference: '7'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44159241-0f24-11e2-8525-18a905767e44',
        name: 'Calgary Flames',
        alias: 'CGY',
        sr_id: 'sr:team:3679',
        reference: '20'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20980',
      scheduled: '2022-03-19T01:00:00Z',
      status: 'scheduled',
      venue: {
        id: '152d63d2-825f-4928-a08a-70564268b405',
        name: 'Scotiabank Saddledome',
        capacity: 19289,
        address: '555 Saddledome Rise SE',
        city: 'Calgary',
        state: 'AB',
        country: 'CAN',
        time_zone: 'US/Mountain',
        sr_id: 'sr:venue:5952'
      },
      broadcasts: [
        {
          network: 'SNW',
          type: 'TV',
          locale: 'International'
        },
        {
          network: 'MSG-B',
          type: 'TV',
          locale: 'Away',
          channel: '635'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b827109f',
    gameID: 1543,
    id: '088c414c-ec04-4d96-a2bc-581fdfbf45bb',
    userID: 95,
    fantasyPlayerPosition: 'XW',
    full_name: 'Brett Murray',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: false,
    matchId: '32c8e541-491c-47b0-9f48-32788c3f08c0',
    positionID: 2,
    star_category: null,
    teamId: '4416d559-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c67',
      id: '32c8e541-491c-47b0-9f48-32788c3f08c0',
      away: {
        id: '4416d559-0f24-11e2-8525-18a905767e44',
        name: 'Buffalo Sabres',
        alias: 'BUF',
        sr_id: 'sr:team:3678',
        reference: '7'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44159241-0f24-11e2-8525-18a905767e44',
        name: 'Calgary Flames',
        alias: 'CGY',
        sr_id: 'sr:team:3679',
        reference: '20'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20980',
      scheduled: '2022-03-19T01:00:00Z',
      status: 'scheduled',
      venue: {
        id: '152d63d2-825f-4928-a08a-70564268b405',
        name: 'Scotiabank Saddledome',
        capacity: 19289,
        address: '555 Saddledome Rise SE',
        city: 'Calgary',
        state: 'AB',
        country: 'CAN',
        time_zone: 'US/Mountain',
        sr_id: 'sr:venue:5952'
      },
      broadcasts: [
        {
          network: 'SNW',
          type: 'TV',
          locale: 'International'
        },
        {
          network: 'MSG-B',
          type: 'TV',
          locale: 'Away',
          channel: '635'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b82710a0',
    gameID: 1543,
    id: '371333ea-0157-4c17-9d08-58de23433cfd',
    userID: 95,
    fantasyPlayerPosition: 'XW',
    full_name: 'Nicolas Aube-Kubel',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: false,
    matchId: 'd3d2d64c-e299-4e3b-9852-cf3283f1ebe0',
    positionID: 3,
    star_category: 0,
    teamId: '4415ce44-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c75',
      id: 'd3d2d64c-e299-4e3b-9852-cf3283f1ebe0',
      away: {
        id: '4415ce44-0f24-11e2-8525-18a905767e44',
        name: 'Colorado Avalanche',
        alias: 'COL',
        sr_id: 'sr:team:3682',
        reference: '21'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44155909-0f24-11e2-8525-18a905767e44',
        name: 'San Jose Sharks',
        alias: 'SJ',
        sr_id: 'sr:team:3696',
        reference: '28'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20982',
      scheduled: '2022-03-19T02:30:00Z',
      status: 'scheduled',
      venue: {
        id: '1da65282-af4c-4b81-a9de-344b76bb20b0',
        name: 'SAP Center at San Jose',
        capacity: 17562,
        address: '525 W Santa Clara Street',
        city: 'San Jose',
        state: 'CA',
        zip: '95113',
        country: 'USA',
        time_zone: 'US/Pacific',
        sr_id: 'sr:venue:6046'
      },
      broadcasts: [
        {
          network: 'NBCS-CA',
          type: 'TV',
          locale: 'Home',
          channel: '698'
        },
        {
          network: 'ALT',
          type: 'TV',
          locale: 'Away',
          channel: '681'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b82710a1',
    gameID: 1543,
    id: '434f3b9c-0f24-11e2-8525-18a905767e44',
    userID: 95,
    fantasyPlayerPosition: 'D',
    full_name: 'John Carlson',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: true,
    matchId: '2460e2b8-c803-4b35-b8b9-27342a79dee2',
    positionID: 1,
    star_category: 1,
    teamId: '4417eede-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c4f',
      id: '2460e2b8-c803-4b35-b8b9-27342a79dee2',
      away: {
        id: '4417eede-0f24-11e2-8525-18a905767e44',
        name: 'Washington Capitals',
        alias: 'WSH',
        sr_id: 'sr:team:3691',
        reference: '15'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44182a9d-0f24-11e2-8525-18a905767e44',
        name: 'Carolina Hurricanes',
        alias: 'CAR',
        sr_id: 'sr:team:3680',
        reference: '12'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20978',
      scheduled: '2022-03-18T23:00:00Z',
      status: 'scheduled',
      venue: {
        id: '1ea58cb6-6a28-4619-b010-747ecbc30aa7',
        name: 'PNC Arena',
        capacity: 18680,
        address: '1400 Edwards Mill Road',
        city: 'Raleigh',
        state: 'NC',
        zip: '27607',
        country: 'USA',
        time_zone: 'US/Eastern',
        sr_id: 'sr:venue:5956'
      },
      broadcasts: [
        {
          network: 'BSSO',
          type: 'TV',
          locale: 'Home',
          channel: '646-1'
        },
        {
          network: 'NBCS-DC+',
          type: 'TV',
          locale: 'Away',
          channel: '642-1'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b82710a2',
    gameID: 1543,
    id: '6164c9ca-61b3-11e2-9e6b-f4ce4684ea4c',
    userID: 95,
    fantasyPlayerPosition: 'D',
    full_name: 'Matt Irwin',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: false,
    matchId: '2460e2b8-c803-4b35-b8b9-27342a79dee2',
    positionID: 2,
    star_category: 0,
    teamId: '4417eede-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c4f',
      id: '2460e2b8-c803-4b35-b8b9-27342a79dee2',
      away: {
        id: '4417eede-0f24-11e2-8525-18a905767e44',
        name: 'Washington Capitals',
        alias: 'WSH',
        sr_id: 'sr:team:3691',
        reference: '15'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44182a9d-0f24-11e2-8525-18a905767e44',
        name: 'Carolina Hurricanes',
        alias: 'CAR',
        sr_id: 'sr:team:3680',
        reference: '12'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20978',
      scheduled: '2022-03-18T23:00:00Z',
      status: 'scheduled',
      venue: {
        id: '1ea58cb6-6a28-4619-b010-747ecbc30aa7',
        name: 'PNC Arena',
        capacity: 18680,
        address: '1400 Edwards Mill Road',
        city: 'Raleigh',
        state: 'NC',
        zip: '27607',
        country: 'USA',
        time_zone: 'US/Eastern',
        sr_id: 'sr:venue:5956'
      },
      broadcasts: [
        {
          network: 'BSSO',
          type: 'TV',
          locale: 'Home',
          channel: '646-1'
        },
        {
          network: 'NBCS-DC+',
          type: 'TV',
          locale: 'Away',
          channel: '642-1'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b82710a3',
    gameID: 1543,
    id: '104295a8-ec3a-46c2-bd03-b252d119a5b7',
    userID: 95,
    fantasyPlayerPosition: 'G',
    full_name: 'Carter Hart',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: false,
    matchId: '5b2b12e2-9bb8-4050-aa92-2b1078c2cb72',
    positionID: 1,
    star_category: 0,
    teamId: '44179d47-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c5a',
      id: '5b2b12e2-9bb8-4050-aa92-2b1078c2cb72',
      away: {
        id: '44179d47-0f24-11e2-8525-18a905767e44',
        name: 'Philadelphia Flyers',
        alias: 'PHI',
        sr_id: 'sr:team:3699',
        reference: '4'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '4416f5e2-0f24-11e2-8525-18a905767e44',
        name: 'Ottawa Senators',
        alias: 'OTT',
        sr_id: 'sr:team:3700',
        reference: '9'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20977',
      scheduled: '2022-03-18T23:00:00Z',
      status: 'scheduled',
      venue: {
        id: 'e1e675a3-9c13-48b3-9e22-4c063dd9905d',
        name: 'Canadian Tire Centre',
        capacity: 18572,
        address: '1000 Palladium Drive',
        city: 'Ottawa',
        state: 'ON',
        country: 'CAN',
        time_zone: 'US/Eastern',
        sr_id: 'sr:venue:6066'
      },
      broadcasts: [
        {
          network: 'TSN5',
          type: 'TV',
          locale: 'International'
        },
        {
          network: 'RDS',
          type: 'TV',
          locale: 'International'
        },
        {
          network: 'NBCS-PH+',
          type: 'TV',
          locale: 'Away'
        }
      ]
    }
  }
  ],
  liveTeamD: {
  _id: '6151c2e80676e7bcd60960c9',
  id: '4418464d-0f24-11e2-8525-18a905767e44',
  alias: 'FLA',
  market: 'Florida',
  name: 'Panthers',
  venue: {
    id: '7cc8e88f-9196-4dbc-aa6a-d6f1a0550673',
    name: 'FLA Live Arena',
    country: 'USA'
  },
  teamStats: {
    own_record: {
      total: {
        games_played: 27,
        goals: 100
      }
    },
    goaltending: {
      total: {
        losses: 5,
        wins: 18
      },
      average: {
        goals_against: 2.63,
        shots_against: 30.48
      }
    }
  },
  type: 'td',
  match_id: 'c97bec1e-f411-466a-8d11-c2ab188992d6',
  teamB: {
    id: '441862de-0f24-11e2-8525-18a905767e44',
    name: 'Anaheim Ducks',
    alias: 'ANA'
  },
  matchVenue: {
    id: 'eb3866a7-4163-4517-bd42-d24e8c2522fe',
    name: 'Honda Center'
  },
  matchScheduled: '2022-03-19T02:00:00Z',
  match: {
    id: 'c97bec1e-f411-466a-8d11-c2ab188992d6',
    away: {
      id: '4418464d-0f24-11e2-8525-18a905767e44',
      name: 'Florida Panthers',
      alias: 'FLA',
      sr_id: 'sr:team:3687',
      reference: '13'
    },
    home: {
      id: '441862de-0f24-11e2-8525-18a905767e44',
      name: 'Anaheim Ducks',
      alias: 'ANA',
      sr_id: 'sr:team:3675',
      reference: '24'
    },
    scheduled: '2022-03-19T02:00:00Z',
    status: 'scheduled',
    venue: {
      id: 'eb3866a7-4163-4517-bd42-d24e8c2522fe',
      name: 'Honda Center',
      capacity: 17174,
      address: '2695 E Katella Avenue',
      city: 'Anaheim',
      state: 'CA',
      zip: '92806',
      country: 'USA',
      time_zone: 'US/Pacific',
      sr_id: 'sr:venue:5934'
    }
  }
  },
  playersPoints: {
    posCenterPoints: 0,
    posD1Points: 0,
    posD2Points: 0,
    posGoaliePoints: 0,
    posXW1Points: 0,
    posXW2Points: 0,
    posXW3Points: 0,
    teamDPts: 0
  },
  playersActualScore: [],
  teamDActualScore: [],
  powersAvailable: [],
  swappedPlayers: [],
  teamLogs: [],
  rankings: [],
  fantacyGames: []
};

export const mlbData = {
  gameID: 1543,
  userID: 95,
  league: 'MLB',
  gameType: 'Fantasy',
  livePlayers: [
  {
    _id: '6232bcb7c917cef5b827109d',
    gameID: 1543,
    id: 'a3c2916b-f92a-11e2-a133-f4ce4684ea4c',
    userID: 95,
    fantasyPlayerPosition: 'P',
    full_name: 'Elias Lindholm',
    isFromAwayTeam: false,
    isFromHomeTeam: true,
    is_starPlayer: true,
    matchId: '32c8e541-491c-47b0-9f48-32788c3f08c0',
    positionID: 1,
    star_category: 2,
    teamId: '44159241-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c67',
      id: '32c8e541-491c-47b0-9f48-32788c3f08c0',
      away: {
        id: '4416d559-0f24-11e2-8525-18a905767e44',
        name: 'Buffalo Sabres',
        alias: 'BUF',
        sr_id: 'sr:team:3678',
        reference: '7'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44159241-0f24-11e2-8525-18a905767e44',
        name: 'Calgary Flames',
        alias: 'CGY',
        sr_id: 'sr:team:3679',
        reference: '20'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20980',
      scheduled: '2022-03-19T01:00:00Z',
      status: 'scheduled',
      venue: {
        id: '152d63d2-825f-4928-a08a-70564268b405',
        name: 'Scotiabank Saddledome',
        capacity: 19289,
        address: '555 Saddledome Rise SE',
        city: 'Calgary',
        state: 'AB',
        country: 'CAN',
        time_zone: 'US/Mountain',
        sr_id: 'sr:venue:5952'
      },
      broadcasts: [
        {
          network: 'SNW',
          type: 'TV',
          locale: 'International'
        },
        {
          network: 'MSG-B',
          type: 'TV',
          locale: 'Away',
          channel: '635'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b827109e',
    gameID: 1543,
    id: '3de1bc1d-ac48-4b05-8072-086729333f26',
    userID: 95,
    fantasyPlayerPosition: 'C',
    full_name: 'Peyton Krebs',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: false,
    matchId: '32c8e541-491c-47b0-9f48-32788c3f08c0',
    positionID: 1,
    star_category: 0,
    teamId: '4416d559-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c67',
      id: '32c8e541-491c-47b0-9f48-32788c3f08c0',
      away: {
        id: '4416d559-0f24-11e2-8525-18a905767e44',
        name: 'Buffalo Sabres',
        alias: 'BUF',
        sr_id: 'sr:team:3678',
        reference: '7'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44159241-0f24-11e2-8525-18a905767e44',
        name: 'Calgary Flames',
        alias: 'CGY',
        sr_id: 'sr:team:3679',
        reference: '20'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20980',
      scheduled: '2022-03-19T01:00:00Z',
      status: 'scheduled',
      venue: {
        id: '152d63d2-825f-4928-a08a-70564268b405',
        name: 'Scotiabank Saddledome',
        capacity: 19289,
        address: '555 Saddledome Rise SE',
        city: 'Calgary',
        state: 'AB',
        country: 'CAN',
        time_zone: 'US/Mountain',
        sr_id: 'sr:venue:5952'
      },
      broadcasts: [
        {
          network: 'SNW',
          type: 'TV',
          locale: 'International'
        },
        {
          network: 'MSG-B',
          type: 'TV',
          locale: 'Away',
          channel: '635'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b827109f',
    gameID: 1543,
    id: '088c414c-ec04-4d96-a2bc-581fdfbf45bb',
    userID: 95,
    fantasyPlayerPosition: 'SS',
    full_name: 'Brett Murray',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: false,
    matchId: '32c8e541-491c-47b0-9f48-32788c3f08c0',
    positionID: 2,
    star_category: null,
    teamId: '4416d559-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c67',
      id: '32c8e541-491c-47b0-9f48-32788c3f08c0',
      away: {
        id: '4416d559-0f24-11e2-8525-18a905767e44',
        name: 'Buffalo Sabres',
        alias: 'BUF',
        sr_id: 'sr:team:3678',
        reference: '7'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44159241-0f24-11e2-8525-18a905767e44',
        name: 'Calgary Flames',
        alias: 'CGY',
        sr_id: 'sr:team:3679',
        reference: '20'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20980',
      scheduled: '2022-03-19T01:00:00Z',
      status: 'scheduled',
      venue: {
        id: '152d63d2-825f-4928-a08a-70564268b405',
        name: 'Scotiabank Saddledome',
        capacity: 19289,
        address: '555 Saddledome Rise SE',
        city: 'Calgary',
        state: 'AB',
        country: 'CAN',
        time_zone: 'US/Mountain',
        sr_id: 'sr:venue:5952'
      },
      broadcasts: [
        {
          network: 'SNW',
          type: 'TV',
          locale: 'International'
        },
        {
          network: 'MSG-B',
          type: 'TV',
          locale: 'Away',
          channel: '635'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b82710a0',
    gameID: 1543,
    id: '371333ea-0157-4c17-9d08-58de23433cfd',
    userID: 95,
    fantasyPlayerPosition: 'XB1',
    full_name: 'Nicolas Aube-Kubel',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: false,
    matchId: 'd3d2d64c-e299-4e3b-9852-cf3283f1ebe0',
    positionID: 3,
    star_category: 0,
    teamId: '4415ce44-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c75',
      id: 'd3d2d64c-e299-4e3b-9852-cf3283f1ebe0',
      away: {
        id: '4415ce44-0f24-11e2-8525-18a905767e44',
        name: 'Colorado Avalanche',
        alias: 'COL',
        sr_id: 'sr:team:3682',
        reference: '21'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44155909-0f24-11e2-8525-18a905767e44',
        name: 'San Jose Sharks',
        alias: 'SJ',
        sr_id: 'sr:team:3696',
        reference: '28'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20982',
      scheduled: '2022-03-19T02:30:00Z',
      status: 'scheduled',
      venue: {
        id: '1da65282-af4c-4b81-a9de-344b76bb20b0',
        name: 'SAP Center at San Jose',
        capacity: 17562,
        address: '525 W Santa Clara Street',
        city: 'San Jose',
        state: 'CA',
        zip: '95113',
        country: 'USA',
        time_zone: 'US/Pacific',
        sr_id: 'sr:venue:6046'
      },
      broadcasts: [
        {
          network: 'NBCS-CA',
          type: 'TV',
          locale: 'Home',
          channel: '698'
        },
        {
          network: 'ALT',
          type: 'TV',
          locale: 'Away',
          channel: '681'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b82710a1',
    gameID: 1543,
    id: '434f3b9c-0f24-11e2-8525-18a905767e44',
    userID: 95,
    fantasyPlayerPosition: 'XB2',
    full_name: 'John Carlson',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: true,
    matchId: '2460e2b8-c803-4b35-b8b9-27342a79dee2',
    positionID: 1,
    star_category: 1,
    teamId: '4417eede-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c4f',
      id: '2460e2b8-c803-4b35-b8b9-27342a79dee2',
      away: {
        id: '4417eede-0f24-11e2-8525-18a905767e44',
        name: 'Washington Capitals',
        alias: 'WSH',
        sr_id: 'sr:team:3691',
        reference: '15'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44182a9d-0f24-11e2-8525-18a905767e44',
        name: 'Carolina Hurricanes',
        alias: 'CAR',
        sr_id: 'sr:team:3680',
        reference: '12'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20978',
      scheduled: '2022-03-18T23:00:00Z',
      status: 'scheduled',
      venue: {
        id: '1ea58cb6-6a28-4619-b010-747ecbc30aa7',
        name: 'PNC Arena',
        capacity: 18680,
        address: '1400 Edwards Mill Road',
        city: 'Raleigh',
        state: 'NC',
        zip: '27607',
        country: 'USA',
        time_zone: 'US/Eastern',
        sr_id: 'sr:venue:5956'
      },
      broadcasts: [
        {
          network: 'BSSO',
          type: 'TV',
          locale: 'Home',
          channel: '646-1'
        },
        {
          network: 'NBCS-DC+',
          type: 'TV',
          locale: 'Away',
          channel: '642-1'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b82710a2',
    gameID: 1543,
    id: '6164c9ca-61b3-11e2-9e6b-f4ce4684ea4c',
    userID: 95,
    fantasyPlayerPosition: 'OF1',
    full_name: 'Matt Irwin',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: false,
    matchId: '2460e2b8-c803-4b35-b8b9-27342a79dee2',
    positionID: 2,
    star_category: 0,
    teamId: '4417eede-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c4f',
      id: '2460e2b8-c803-4b35-b8b9-27342a79dee2',
      away: {
        id: '4417eede-0f24-11e2-8525-18a905767e44',
        name: 'Washington Capitals',
        alias: 'WSH',
        sr_id: 'sr:team:3691',
        reference: '15'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44182a9d-0f24-11e2-8525-18a905767e44',
        name: 'Carolina Hurricanes',
        alias: 'CAR',
        sr_id: 'sr:team:3680',
        reference: '12'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20978',
      scheduled: '2022-03-18T23:00:00Z',
      status: 'scheduled',
      venue: {
        id: '1ea58cb6-6a28-4619-b010-747ecbc30aa7',
        name: 'PNC Arena',
        capacity: 18680,
        address: '1400 Edwards Mill Road',
        city: 'Raleigh',
        state: 'NC',
        zip: '27607',
        country: 'USA',
        time_zone: 'US/Eastern',
        sr_id: 'sr:venue:5956'
      },
      broadcasts: [
        {
          network: 'BSSO',
          type: 'TV',
          locale: 'Home',
          channel: '646-1'
        },
        {
          network: 'NBCS-DC+',
          type: 'TV',
          locale: 'Away',
          channel: '642-1'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b82710a3',
    gameID: 1543,
    id: '104295a8-ec3a-46c2-bd03-b252d119a5b7',
    userID: 95,
    fantasyPlayerPosition: 'OF2',
    full_name: 'Carter Hart',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: false,
    matchId: '5b2b12e2-9bb8-4050-aa92-2b1078c2cb72',
    positionID: 1,
    star_category: 0,
    teamId: '44179d47-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c5a',
      id: '5b2b12e2-9bb8-4050-aa92-2b1078c2cb72',
      away: {
        id: '44179d47-0f24-11e2-8525-18a905767e44',
        name: 'Philadelphia Flyers',
        alias: 'PHI',
        sr_id: 'sr:team:3699',
        reference: '4'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '4416f5e2-0f24-11e2-8525-18a905767e44',
        name: 'Ottawa Senators',
        alias: 'OTT',
        sr_id: 'sr:team:3700',
        reference: '9'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20977',
      scheduled: '2022-03-18T23:00:00Z',
      status: 'scheduled',
      venue: {
        id: 'e1e675a3-9c13-48b3-9e22-4c063dd9905d',
        name: 'Canadian Tire Centre',
        capacity: 18572,
        address: '1000 Palladium Drive',
        city: 'Ottawa',
        state: 'ON',
        country: 'CAN',
        time_zone: 'US/Eastern',
        sr_id: 'sr:venue:6066'
      },
      broadcasts: [
        {
          network: 'TSN5',
          type: 'TV',
          locale: 'International'
        },
        {
          network: 'RDS',
          type: 'TV',
          locale: 'International'
        },
        {
          network: 'NBCS-PH+',
          type: 'TV',
          locale: 'Away'
        }
      ]
    }
  }
  ],
  liveTeamD: {
  _id: '6151c2e80676e7bcd60960c9',
  id: '4418464d-0f24-11e2-8525-18a905767e44',
  alias: 'FLA',
  market: 'Florida',
  name: 'Panthers',
  venue: {
    id: '7cc8e88f-9196-4dbc-aa6a-d6f1a0550673',
    name: 'FLA Live Arena',
    country: 'USA'
  },
  teamStats: {
    own_record: {
      total: {
        games_played: 27,
        goals: 100
      }
    },
    goaltending: {
      total: {
        losses: 5,
        wins: 18
      },
      average: {
        goals_against: 2.63,
        shots_against: 30.48
      }
    }
  },
  type: 'td',
  match_id: 'c97bec1e-f411-466a-8d11-c2ab188992d6',
  teamB: {
    id: '441862de-0f24-11e2-8525-18a905767e44',
    name: 'Anaheim Ducks',
    alias: 'ANA'
  },
  matchVenue: {
    id: 'eb3866a7-4163-4517-bd42-d24e8c2522fe',
    name: 'Honda Center'
  },
  matchScheduled: '2022-03-19T02:00:00Z',
  match: {
    id: 'c97bec1e-f411-466a-8d11-c2ab188992d6',
    away: {
      id: '4418464d-0f24-11e2-8525-18a905767e44',
      name: 'Florida Panthers',
      alias: 'FLA',
      sr_id: 'sr:team:3687',
      reference: '13'
    },
    home: {
      id: '441862de-0f24-11e2-8525-18a905767e44',
      name: 'Anaheim Ducks',
      alias: 'ANA',
      sr_id: 'sr:team:3675',
      reference: '24'
    },
    scheduled: '2022-03-19T02:00:00Z',
    status: 'scheduled',
    venue: {
      id: 'eb3866a7-4163-4517-bd42-d24e8c2522fe',
      name: 'Honda Center',
      capacity: 17174,
      address: '2695 E Katella Avenue',
      city: 'Anaheim',
      state: 'CA',
      zip: '92806',
      country: 'USA',
      time_zone: 'US/Pacific',
      sr_id: 'sr:venue:5934'
    }
  }
  },
  playersPoints: {
    P: 0,
    C: 0,
    SS: 0,
    XB1: 0,
    XB2: 0,
    OF1: 0,
    OF2: 0,
    teamDPts: 0
  },
  playersActualScore: [],
  teamDActualScore: [],
  powersAvailable: [],
  swappedPlayers: [],
  teamLogs: [],
  rankings: [],
  fantacyGames: []
};

export const nbaData = {
  gameID: 1543,
  userID: 95,
  league: 'NBA',
  gameType: 'Fantasy',
  livePlayers: [
  {
    _id: '6232bcb7c917cef5b827109d',
    gameID: 1543,
    id: 'a3c2916b-f92a-11e2-a133-f4ce4684ea4c',
    userID: 95,
    fantasyPlayerPosition: 'C',
    full_name: 'Elias Lindholm',
    isFromAwayTeam: false,
    isFromHomeTeam: true,
    is_starPlayer: true,
    matchId: '32c8e541-491c-47b0-9f48-32788c3f08c0',
    positionID: 1,
    star_category: 2,
    teamId: '44159241-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c67',
      id: '32c8e541-491c-47b0-9f48-32788c3f08c0',
      away: {
        id: '4416d559-0f24-11e2-8525-18a905767e44',
        name: 'Buffalo Sabres',
        alias: 'BUF',
        sr_id: 'sr:team:3678',
        reference: '7'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44159241-0f24-11e2-8525-18a905767e44',
        name: 'Calgary Flames',
        alias: 'CGY',
        sr_id: 'sr:team:3679',
        reference: '20'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20980',
      scheduled: '2022-03-19T01:00:00Z',
      status: 'scheduled',
      venue: {
        id: '152d63d2-825f-4928-a08a-70564268b405',
        name: 'Scotiabank Saddledome',
        capacity: 19289,
        address: '555 Saddledome Rise SE',
        city: 'Calgary',
        state: 'AB',
        country: 'CAN',
        time_zone: 'US/Mountain',
        sr_id: 'sr:venue:5952'
      },
      broadcasts: [
        {
          network: 'SNW',
          type: 'TV',
          locale: 'International'
        },
        {
          network: 'MSG-B',
          type: 'TV',
          locale: 'Away',
          channel: '635'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b827109e',
    gameID: 1543,
    id: '3de1bc1d-ac48-4b05-8072-086729333f26',
    userID: 95,
    fantasyPlayerPosition: 'PG1',
    full_name: 'Peyton Krebs',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: false,
    matchId: '32c8e541-491c-47b0-9f48-32788c3f08c0',
    positionID: 1,
    star_category: 0,
    teamId: '4416d559-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c67',
      id: '32c8e541-491c-47b0-9f48-32788c3f08c0',
      away: {
        id: '4416d559-0f24-11e2-8525-18a905767e44',
        name: 'Buffalo Sabres',
        alias: 'BUF',
        sr_id: 'sr:team:3678',
        reference: '7'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44159241-0f24-11e2-8525-18a905767e44',
        name: 'Calgary Flames',
        alias: 'CGY',
        sr_id: 'sr:team:3679',
        reference: '20'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20980',
      scheduled: '2022-03-19T01:00:00Z',
      status: 'scheduled',
      venue: {
        id: '152d63d2-825f-4928-a08a-70564268b405',
        name: 'Scotiabank Saddledome',
        capacity: 19289,
        address: '555 Saddledome Rise SE',
        city: 'Calgary',
        state: 'AB',
        country: 'CAN',
        time_zone: 'US/Mountain',
        sr_id: 'sr:venue:5952'
      },
      broadcasts: [
        {
          network: 'SNW',
          type: 'TV',
          locale: 'International'
        },
        {
          network: 'MSG-B',
          type: 'TV',
          locale: 'Away',
          channel: '635'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b827109f',
    gameID: 1543,
    id: '088c414c-ec04-4d96-a2bc-581fdfbf45bb',
    userID: 95,
    fantasyPlayerPosition: 'PG2',
    full_name: 'Brett Murray',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: false,
    matchId: '32c8e541-491c-47b0-9f48-32788c3f08c0',
    positionID: 2,
    star_category: null,
    teamId: '4416d559-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c67',
      id: '32c8e541-491c-47b0-9f48-32788c3f08c0',
      away: {
        id: '4416d559-0f24-11e2-8525-18a905767e44',
        name: 'Buffalo Sabres',
        alias: 'BUF',
        sr_id: 'sr:team:3678',
        reference: '7'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44159241-0f24-11e2-8525-18a905767e44',
        name: 'Calgary Flames',
        alias: 'CGY',
        sr_id: 'sr:team:3679',
        reference: '20'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20980',
      scheduled: '2022-03-19T01:00:00Z',
      status: 'scheduled',
      venue: {
        id: '152d63d2-825f-4928-a08a-70564268b405',
        name: 'Scotiabank Saddledome',
        capacity: 19289,
        address: '555 Saddledome Rise SE',
        city: 'Calgary',
        state: 'AB',
        country: 'CAN',
        time_zone: 'US/Mountain',
        sr_id: 'sr:venue:5952'
      },
      broadcasts: [
        {
          network: 'SNW',
          type: 'TV',
          locale: 'International'
        },
        {
          network: 'MSG-B',
          type: 'TV',
          locale: 'Away',
          channel: '635'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b82710a0',
    gameID: 1543,
    id: '371333ea-0157-4c17-9d08-58de23433cfd',
    userID: 95,
    fantasyPlayerPosition: 'SG1',
    full_name: 'Nicolas Aube-Kubel',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: false,
    matchId: 'd3d2d64c-e299-4e3b-9852-cf3283f1ebe0',
    positionID: 3,
    star_category: 0,
    teamId: '4415ce44-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c75',
      id: 'd3d2d64c-e299-4e3b-9852-cf3283f1ebe0',
      away: {
        id: '4415ce44-0f24-11e2-8525-18a905767e44',
        name: 'Colorado Avalanche',
        alias: 'COL',
        sr_id: 'sr:team:3682',
        reference: '21'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44155909-0f24-11e2-8525-18a905767e44',
        name: 'San Jose Sharks',
        alias: 'SJ',
        sr_id: 'sr:team:3696',
        reference: '28'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20982',
      scheduled: '2022-03-19T02:30:00Z',
      status: 'scheduled',
      venue: {
        id: '1da65282-af4c-4b81-a9de-344b76bb20b0',
        name: 'SAP Center at San Jose',
        capacity: 17562,
        address: '525 W Santa Clara Street',
        city: 'San Jose',
        state: 'CA',
        zip: '95113',
        country: 'USA',
        time_zone: 'US/Pacific',
        sr_id: 'sr:venue:6046'
      },
      broadcasts: [
        {
          network: 'NBCS-CA',
          type: 'TV',
          locale: 'Home',
          channel: '698'
        },
        {
          network: 'ALT',
          type: 'TV',
          locale: 'Away',
          channel: '681'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b82710a1',
    gameID: 1543,
    id: '434f3b9c-0f24-11e2-8525-18a905767e44',
    userID: 95,
    fantasyPlayerPosition: 'SG2',
    full_name: 'John Carlson',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: true,
    matchId: '2460e2b8-c803-4b35-b8b9-27342a79dee2',
    positionID: 1,
    star_category: 1,
    teamId: '4417eede-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c4f',
      id: '2460e2b8-c803-4b35-b8b9-27342a79dee2',
      away: {
        id: '4417eede-0f24-11e2-8525-18a905767e44',
        name: 'Washington Capitals',
        alias: 'WSH',
        sr_id: 'sr:team:3691',
        reference: '15'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44182a9d-0f24-11e2-8525-18a905767e44',
        name: 'Carolina Hurricanes',
        alias: 'CAR',
        sr_id: 'sr:team:3680',
        reference: '12'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20978',
      scheduled: '2022-03-18T23:00:00Z',
      status: 'scheduled',
      venue: {
        id: '1ea58cb6-6a28-4619-b010-747ecbc30aa7',
        name: 'PNC Arena',
        capacity: 18680,
        address: '1400 Edwards Mill Road',
        city: 'Raleigh',
        state: 'NC',
        zip: '27607',
        country: 'USA',
        time_zone: 'US/Eastern',
        sr_id: 'sr:venue:5956'
      },
      broadcasts: [
        {
          network: 'BSSO',
          type: 'TV',
          locale: 'Home',
          channel: '646-1'
        },
        {
          network: 'NBCS-DC+',
          type: 'TV',
          locale: 'Away',
          channel: '642-1'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b82710a2',
    gameID: 1543,
    id: '6164c9ca-61b3-11e2-9e6b-f4ce4684ea4c',
    userID: 95,
    fantasyPlayerPosition: 'F1',
    full_name: 'Matt Irwin',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: false,
    matchId: '2460e2b8-c803-4b35-b8b9-27342a79dee2',
    positionID: 2,
    star_category: 0,
    teamId: '4417eede-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c4f',
      id: '2460e2b8-c803-4b35-b8b9-27342a79dee2',
      away: {
        id: '4417eede-0f24-11e2-8525-18a905767e44',
        name: 'Washington Capitals',
        alias: 'WSH',
        sr_id: 'sr:team:3691',
        reference: '15'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '44182a9d-0f24-11e2-8525-18a905767e44',
        name: 'Carolina Hurricanes',
        alias: 'CAR',
        sr_id: 'sr:team:3680',
        reference: '12'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20978',
      scheduled: '2022-03-18T23:00:00Z',
      status: 'scheduled',
      venue: {
        id: '1ea58cb6-6a28-4619-b010-747ecbc30aa7',
        name: 'PNC Arena',
        capacity: 18680,
        address: '1400 Edwards Mill Road',
        city: 'Raleigh',
        state: 'NC',
        zip: '27607',
        country: 'USA',
        time_zone: 'US/Eastern',
        sr_id: 'sr:venue:5956'
      },
      broadcasts: [
        {
          network: 'BSSO',
          type: 'TV',
          locale: 'Home',
          channel: '646-1'
        },
        {
          network: 'NBCS-DC+',
          type: 'TV',
          locale: 'Away',
          channel: '642-1'
        }
      ]
    }
  },
  {
    _id: '6232bcb7c917cef5b82710a3',
    gameID: 1543,
    id: '104295a8-ec3a-46c2-bd03-b252d119a5b7',
    userID: 95,
    fantasyPlayerPosition: 'F2',
    full_name: 'Carter Hart',
    isFromAwayTeam: true,
    isFromHomeTeam: false,
    is_starPlayer: false,
    matchId: '5b2b12e2-9bb8-4050-aa92-2b1078c2cb72',
    positionID: 1,
    star_category: 0,
    teamId: '44179d47-0f24-11e2-8525-18a905767e44',
    match: {
      _id: '6151bbdf0676e7bcd6ff3c5a',
      id: '5b2b12e2-9bb8-4050-aa92-2b1078c2cb72',
      away: {
        id: '44179d47-0f24-11e2-8525-18a905767e44',
        name: 'Philadelphia Flyers',
        alias: 'PHI',
        sr_id: 'sr:team:3699',
        reference: '4'
      },
      coverage: 'full',
      created_date: '2021-09-27 12:41:00',
      home: {
        id: '4416f5e2-0f24-11e2-8525-18a905767e44',
        name: 'Ottawa Senators',
        alias: 'OTT',
        sr_id: 'sr:team:3700',
        reference: '9'
      },
      last_updated: '2022-02-01 13:50:49',
      reference: '20977',
      scheduled: '2022-03-18T23:00:00Z',
      status: 'scheduled',
      venue: {
        id: 'e1e675a3-9c13-48b3-9e22-4c063dd9905d',
        name: 'Canadian Tire Centre',
        capacity: 18572,
        address: '1000 Palladium Drive',
        city: 'Ottawa',
        state: 'ON',
        country: 'CAN',
        time_zone: 'US/Eastern',
        sr_id: 'sr:venue:6066'
      },
      broadcasts: [
        {
          network: 'TSN5',
          type: 'TV',
          locale: 'International'
        },
        {
          network: 'RDS',
          type: 'TV',
          locale: 'International'
        },
        {
          network: 'NBCS-PH+',
          type: 'TV',
          locale: 'Away'
        }
      ]
    }
  }
  ],
  liveTeamD: {
  _id: '6151c2e80676e7bcd60960c9',
  id: '4418464d-0f24-11e2-8525-18a905767e44',
  alias: 'FLA',
  market: 'Florida',
  name: 'Panthers',
  venue: {
    id: '7cc8e88f-9196-4dbc-aa6a-d6f1a0550673',
    name: 'FLA Live Arena',
    country: 'USA'
  },
  teamStats: {
    own_record: {
      total: {
        games_played: 27,
        goals: 100
      }
    },
    goaltending: {
      total: {
        losses: 5,
        wins: 18
      },
      average: {
        goals_against: 2.63,
        shots_against: 30.48
      }
    }
  },
  type: 'td',
  match_id: 'c97bec1e-f411-466a-8d11-c2ab188992d6',
  teamB: {
    id: '441862de-0f24-11e2-8525-18a905767e44',
    name: 'Anaheim Ducks',
    alias: 'ANA'
  },
  matchVenue: {
    id: 'eb3866a7-4163-4517-bd42-d24e8c2522fe',
    name: 'Honda Center'
  },
  matchScheduled: '2022-03-19T02:00:00Z',
  match: {
    id: 'c97bec1e-f411-466a-8d11-c2ab188992d6',
    away: {
      id: '4418464d-0f24-11e2-8525-18a905767e44',
      name: 'Florida Panthers',
      alias: 'FLA',
      sr_id: 'sr:team:3687',
      reference: '13'
    },
    home: {
      id: '441862de-0f24-11e2-8525-18a905767e44',
      name: 'Anaheim Ducks',
      alias: 'ANA',
      sr_id: 'sr:team:3675',
      reference: '24'
    },
    scheduled: '2022-03-19T02:00:00Z',
    status: 'scheduled',
    venue: {
      id: 'eb3866a7-4163-4517-bd42-d24e8c2522fe',
      name: 'Honda Center',
      capacity: 17174,
      address: '2695 E Katella Avenue',
      city: 'Anaheim',
      state: 'CA',
      zip: '92806',
      country: 'USA',
      time_zone: 'US/Pacific',
      sr_id: 'sr:venue:5934'
    }
  }
  },
  playersPoints: {
    P: 0,
    C: 0,
    SS: 0,
    XB1: 0,
    XB2: 0,
    OF1: 0,
    OF2: 0,
    teamDPts: 0
  },
  playersActualScore: [],
  teamDActualScore: [],
  powersAvailable: [],
  swappedPlayers: [],
  teamLogs: [],
  rankings: [],
  fantacyGames: []
};
export const nfaData = {
  "team": {
      "id": "Team-1768-95",
      "userId": 95,
      "gameId": 1768,
      "userDisplayName": "test2",
      "entryFees": 0,
      "created_at": null,
      "updated_at": "2022-04-27T13:47:23.643Z",
      "positionP": 0,
      "positionC": 10,
      "positionSS": 5,
      "positionXB1": 2,
      "positionXB2": 0,
      "positionOF1": 1,
      "positionOF2": 13,
      "teamDPoints": -5,
      "teamD": {
          "id": "MLB-Team-125",
          "name": "Athletics",
          "abbr": "OAK",
          "market": "Oakland",
          "matchId": "MLB-Schedule-68995"
      },
      "teamDStats": {
          "runsAgainst": 0,
          "homeRunsAgainst": 0,
          "wins": 1,
          "loss": 0
      }
  },
  "players": [
      {
          "id": "6269496b117c1f561cc2cbca",
          "playerId": "MLB-Player-15641",
          "position": "QB",
          "primaryPosition": null,
          "fantasyPosition": "QB",
          "full_name": "Cristian Javier",
          "isSwapped": false,
          "matchId": "MLB-Schedule-68993",
          "teamId": "MLB-Team-122",
          "created_at": "2022-04-27T13:47:23.580+00:00",
          "userId": 95,
          "gameId": 1768
      },
      {
          "id": "6269496b117c1f561cc2cbcb",
          "playerId": "MLB-Player-13122",
          "position": "RB1",
          "primaryPosition": null,
          "fantasyPosition": "RB1",
          "full_name": "Mitch Garver",
          "isSwapped": false,
          "matchId": "MLB-Schedule-68993",
          "teamId": "MLB-Team-121",
          "created_at": "2022-04-27T13:47:23.580+00:00",
          "userId": 95,
          "gameId": 1768
      },
      {
          "id": "6269496b117c1f561cc2cbcc",
          "playerId": "MLB-Player-10902",
          "position": "RB2",
          "primaryPosition": null,
          "fantasyPosition": "RB2",
          "full_name": "Brandon Crawford",
          "isSwapped": false,
          "matchId": "MLB-Schedule-68995",
          "teamId": "MLB-Team-136",
          "created_at": "2022-04-27T13:47:23.580+00:00",
          "userId": 95,
          "gameId": 1768
      },
      {
          "id": "6269496b117c1f561cc2cbcd",
          "playerId": "MLB-Player-10496",
          "position": "WR1",
          "primaryPosition": null,
          "fantasyPosition": "WR1",
          "full_name": "Alex Bregman",
          "isSwapped": false,
          "matchId": "MLB-Schedule-68993",
          "teamId": "MLB-Team-122",
          "created_at": "2022-04-27T13:47:23.580+00:00",
          "userId": 95,
          "gameId": 1768
      },
      {
          "id": "6269496b117c1f561cc2cbce",
          "playerId": "MLB-Player-10901",
          "position": "WR2",
          "primaryPosition": null,
          "fantasyPosition": "WR2",
          "full_name": "Brandon Belt",
          "isSwapped": false,
          "matchId": "MLB-Schedule-68995",
          "teamId": "MLB-Team-136",
          "created_at": "2022-04-27T13:47:23.580+00:00",
          "userId": 95,
          "gameId": 1768
      },
      {
          "id": "6269496b117c1f561cc2cbcf",
          "playerId": "MLB-Player-12321",
          "position": "TE",
          "primaryPosition": null,
          "fantasyPosition": "TE",
          "full_name": "Myles Straw",
          "isSwapped": false,
          "matchId": "MLB-Schedule-68997",
          "teamId": "MLB-Team-116",
          "created_at": "2022-04-27T13:47:23.580+00:00",
          "userId": 95,
          "gameId": 1768
      },
      {
          "id": "6269496b117c1f561cc2cbd0",
          "playerId": "MLB-Player-14747",
          "position": "K",
          "primaryPosition": null,
          "fantasyPosition": "K",
          "full_name": "Adolis Garcia",
          "isSwapped": false,
          "matchId": "MLB-Schedule-68993",
          "teamId": "MLB-Team-121",
          "created_at": "2022-04-27T13:47:23.580+00:00",
          "userId": 95,
          "gameId": 1768
      }
  ],
  "playerScoresList": [
      {
          "id": "Team-1768-95-MLB-Player-13122",
          "userId": 95,
          "gameId": 1768,
          "playerId": "MLB-Player-13122",
          "fantasyPosition": "QB",
          "totalFantasyPoints": 17,
          "hr": 1,
          "s": 1,
          "d": 0,
          "t": 0,
          "bb": 0,
          "sb": 0,
          "rs": 1,
          "rbi": 1,
          "sac": 0,
          "era": "0",
          "avg": 0.5,
          "wins": 0,
          "loss": 0,
          "inningsPitched": 0,
          "strikeOuts": 0,
          "runsAllowed": 0,
          "updatedAt": "2022-04-28T04:42:20.661+00:00"
      },
      {
          "id": "Team-1768-95-MLB-Player-10496",
          "userId": 95,
          "gameId": 1768,
          "playerId": "MLB-Player-10496",
          "fantasyPosition": "RB1",
          "totalFantasyPoints": 2,
          "hr": 0,
          "s": 0,
          "d": 0,
          "t": 0,
          "bb": 2,
          "sb": 0,
          "rs": 0,
          "rbi": 0,
          "sac": 0,
          "era": "0",
          "avg": 0,
          "wins": 0,
          "loss": 0,
          "inningsPitched": 0,
          "strikeOuts": 0,
          "runsAllowed": 0,
          "updatedAt": "2022-04-28T04:42:20.736+00:00"
      },
      {
          "id": "Team-1768-95-MLB-Player-14747",
          "userId": 95,
          "gameId": 1768,
          "playerId": "MLB-Player-14747",
          "fantasyPosition": "RB2",
          "totalFantasyPoints": 13,
          "hr": 0,
          "s": 0,
          "d": 1,
          "t": 0,
          "bb": 1,
          "sb": 1,
          "rs": 1,
          "rbi": 0,
          "sac": 0,
          "era": "0",
          "avg": 0.333,
          "wins": 0,
          "loss": 0,
          "inningsPitched": 0,
          "strikeOuts": 0,
          "runsAllowed": 0,
          "updatedAt": "2022-04-28T04:42:20.811+00:00"
      },
      {
          "id": "Team-1768-95-MLB-Player-15641",
          "userId": 95,
          "gameId": 1768,
          "playerId": "MLB-Player-15641",
          "fantasyPosition": "WR1",
          "totalFantasyPoints": 8,
          "hr": 0,
          "s": 0,
          "d": 0,
          "t": 0,
          "bb": 2,
          "sb": 0,
          "rs": 0,
          "rbi": 0,
          "sac": 0,
          "era": "3.6",
          "avg": 0,
          "wins": 1,
          "loss": 0,
          "inningsPitched": 5,
          "strikeOuts": 4,
          "runsAllowed": 2,
          "updatedAt": "2022-04-28T04:42:20.582+00:00"
      },
      {
          "id": "Team-1768-95-MLB-Player-12321",
          "userId": 95,
          "gameId": 1768,
          "playerId": "MLB-Player-12321",
          "fantasyPosition": "WR2",
          "totalFantasyPoints": 4,
          "hr": 0,
          "s": 0,
          "d": 0,
          "t": 0,
          "bb": 1,
          "sb": 0,
          "rs": 0,
          "rbi": 1,
          "sac": 1,
          "era": "0",
          "avg": 0,
          "wins": 0,
          "loss": 0,
          "inningsPitched": 0,
          "strikeOuts": 0,
          "runsAllowed": 0,
          "updatedAt": "2022-04-28T06:02:21.629+00:00"
      },
      {
          "id": "Team-1768-95-MLB-Player-10902",
          "userId": 95,
          "gameId": 1768,
          "playerId": "MLB-Player-10902",
          "fantasyPosition": "TE",
          "totalFantasyPoints": 3,
          "hr": 0,
          "s": 1,
          "d": 0,
          "t": 0,
          "bb": 0,
          "sb": 0,
          "rs": 0,
          "rbi": 0,
          "sac": 0,
          "era": "0",
          "avg": 0.25,
          "wins": 0,
          "loss": 0,
          "inningsPitched": 0,
          "strikeOuts": 0,
          "runsAllowed": 0,
          "updatedAt": "2022-04-28T06:02:22.481+00:00"
      },
      {
          "id": "Team-1768-95-MLB-Player-10901",
          "userId": 95,
          "gameId": 1768,
          "playerId": "MLB-Player-10901",
          "fantasyPosition": "K",
          "totalFantasyPoints": 0,
          "hr": 0,
          "s": 0,
          "d": 0,
          "t": 0,
          "bb": 0,
          "sb": 0,
          "rs": 0,
          "rbi": 0,
          "sac": 0,
          "era": "0",
          "avg": 0,
          "wins": 0,
          "loss": 0,
          "inningsPitched": 0,
          "strikeOuts": 0,
          "runsAllowed": 0,
          "updatedAt": "2022-04-28T06:02:22.559+00:00"
      }
  ],
  "matchStatus": [
      {
          "id": {
              "timestamp": 1651120940,
              "date": "2022-04-28T04:42:20.000+00:00"
          },
          "gameFeedId": "MLB-Schedule-68993",
          "status": "closed",
          "gameNumber": 1,
          "finalInnings": {
              "inning": 9,
              "inning_half": null
          },
          "outcome": {
              "type": null,
              "count": null,
              "hitter": null,
              "pitcher": null,
              "allInnings": [
                  {
                      "inningNumber": 1,
                      "awayScore": 1,
                      "homeScore": 0,
                      "scoringPlays": [
                          {
                              "inningHalf": "TOP",
                              "team": {
                                  "id": 122,
                                  "abbreviation": "HOU"
                              },
                              "scoreChange": 1,
                              "awayScore": 1,
                              "homeScore": 0,
                              "playDescription": "Chas McCormick homers (1) on a fly ball to right center field."
                          }
                      ]
                  },
                  {
                      "inningNumber": 2,
                      "awayScore": 0,
                      "homeScore": 2,
                      "scoringPlays": [
                          {
                              "inningHalf": "BOTTOM",
                              "team": {
                                  "id": 121,
                                  "abbreviation": "TEX"
                              },
                              "scoreChange": 1,
                              "awayScore": 0,
                              "homeScore": 1,
                              "playDescription": "Mitch Garver homers (2) on a fly ball to left field."
                          },
                          {
                              "inningHalf": "BOTTOM",
                              "team": {
                                  "id": 121,
                                  "abbreviation": "TEX"
                              },
                              "scoreChange": 1,
                              "awayScore": 0,
                              "homeScore": 2,
                              "playDescription": "Brad Miller singles on a ground ball to left fielder Yordan Alvarez.   Adolis Garcia scores.    Jonah Heim to 2nd."
                          }
                      ]
                  },
                  {
                      "inningNumber": 3,
                      "awayScore": 0,
                      "homeScore": 0,
                      "scoringPlays": []
                  },
                  {
                      "inningNumber": 4,
                      "awayScore": 0,
                      "homeScore": 0,
                      "scoringPlays": []
                  },
                  {
                      "inningNumber": 5,
                      "awayScore": 3,
                      "homeScore": 0,
                      "scoringPlays": [
                          {
                              "inningHalf": "TOP",
                              "team": {
                                  "id": 122,
                                  "abbreviation": "HOU"
                              },
                              "scoreChange": 3,
                              "awayScore": 3,
                              "homeScore": 0,
                              "playDescription": "Kyle Tucker doubles (1) on a sharp line drive to center fielder Adolis Garcia.   Chas McCormick scores.    Michael Brantley scores.    Yordan Alvarez scores."
                          }
                      ]
                  },
                  {
                      "inningNumber": 6,
                      "awayScore": 0,
                      "homeScore": 0,
                      "scoringPlays": []
                  },
                  {
                      "inningNumber": 7,
                      "awayScore": 0,
                      "homeScore": 0,
                      "scoringPlays": []
                  },
                  {
                      "inningNumber": 8,
                      "awayScore": 0,
                      "homeScore": 0,
                      "scoringPlays": []
                  },
                  {
                      "inningNumber": 9,
                      "awayScore": 0,
                      "homeScore": 1,
                      "scoringPlays": [
                          {
                              "inningHalf": "BOTTOM",
                              "team": {
                                  "id": 121,
                                  "abbreviation": "TEX"
                              },
                              "scoreChange": 1,
                              "awayScore": 0,
                              "homeScore": 1,
                              "playDescription": "Brad Miller grounds out, shortstop Jeremy Pena to first baseman Yuli Gurriel.   Willie Calhoun scores.    Adolis Garcia to 3rd."
                          }
                      ]
                  }
              ],
              "current_inning": 9,
              "current_inning_half": null
          },
          "home": {
              "id": "MLB-Team-121",
              "name": "Rangers",
              "market": "Texas",
              "runs": 0,
              "hits": 0,
              "errors": 0,
              "win": 3,
              "loss": 4,
              "scoring": null,
              "probable_pitcher": null,
              "starting_pitcher": null
          },
          "away": {
              "id": "MLB-Team-122",
              "name": "Astros",
              "market": "Houston",
              "runs": 0,
              "hits": 0,
              "errors": 0,
              "win": 4,
              "loss": 3,
              "scoring": null,
              "probable_pitcher": null,
              "starting_pitcher": null
          },
          "updatedAt": "2022-04-28T04:42:20.886Z"
      },
      {
          "id": {
              "timestamp": 1651125741,
              "date": "2022-04-28T06:02:21.000+00:00"
          },
          "gameFeedId": "MLB-Schedule-68997",
          "status": "closed",
          "gameNumber": 1,
          "finalInnings": {
              "inning": 9,
              "inning_half": null
          },
          "outcome": {
              "type": null,
              "count": null,
              "hitter": null,
              "pitcher": null,
              "allInnings": [
                  {
                      "inningNumber": 1,
                      "awayScore": 2,
                      "homeScore": 2,
                      "scoringPlays": [
                          {
                              "inningHalf": "TOP",
                              "team": {
                                  "id": 116,
                                  "abbreviation": "CLE"
                              },
                              "scoreChange": 2,
                              "awayScore": 2,
                              "homeScore": 0,
                              "playDescription": "Jose Ramirez homers (5) on a fly ball to center field.   Amed Rosario scores."
                          },
                          {
                              "inningHalf": "BOTTOM",
                              "team": {
                                  "id": 124,
                                  "abbreviation": "LAA"
                              },
                              "scoreChange": 1,
                              "awayScore": 2,
                              "homeScore": 1,
                              "playDescription": "Mike Trout doubles (5) on a sharp line drive to left fielder Richie Palacios.   Taylor Ward scores."
                          },
                          {
                              "inningHalf": "BOTTOM",
                              "team": {
                                  "id": 124,
                                  "abbreviation": "LAA"
                              },
                              "scoreChange": 1,
                              "awayScore": 2,
                              "homeScore": 2,
                              "playDescription": "Anthony Rendon singles on a sharp ground ball to left fielder Richie Palacios.   Mike Trout scores."
                          }
                      ]
                  },
                  {
                      "inningNumber": 2,
                      "awayScore": 0,
                      "homeScore": 4,
                      "scoringPlays": [
                          {
                              "inningHalf": "BOTTOM",
                              "team": {
                                  "id": 124,
                                  "abbreviation": "LAA"
                              },
                              "scoreChange": 4,
                              "awayScore": 0,
                              "homeScore": 4,
                              "playDescription": "Taylor Ward hits a grand slam (4) to center field.   Jack Mayfield scores.    Max Stassi scores.    Tyler Wade scores."
                          }
                      ]
                  },
                  {
                      "inningNumber": 3,
                      "awayScore": 0,
                      "homeScore": 0,
                      "scoringPlays": []
                  },
                  {
                      "inningNumber": 4,
                      "awayScore": 0,
                      "homeScore": 1,
                      "scoringPlays": [
                          {
                              "inningHalf": "BOTTOM",
                              "team": {
                                  "id": 124,
                                  "abbreviation": "LAA"
                              },
                              "scoreChange": 1,
                              "awayScore": 0,
                              "homeScore": 1,
                              "playDescription": "Jared Walsh singles on a sharp line drive to right fielder Josh Naylor.   Taylor Ward scores.  Shohei Ohtani out at home on the throw, right fielder Josh Naylor to catcher Luke Maile to shortstop Amed Rosario to catcher Luke Maile.   Mike Trout to 2nd."
                          }
                      ]
                  },
                  {
                      "inningNumber": 5,
                      "awayScore": 0,
                      "homeScore": 0,
                      "scoringPlays": []
                  },
                  {
                      "inningNumber": 6,
                      "awayScore": 0,
                      "homeScore": 2,
                      "scoringPlays": [
                          {
                              "inningHalf": "BOTTOM",
                              "team": {
                                  "id": 124,
                                  "abbreviation": "LAA"
                              },
                              "scoreChange": 1,
                              "awayScore": 0,
                              "homeScore": 1,
                              "playDescription": "Shohei Ohtani doubles (4) on a sharp line drive to right fielder Josh Naylor.   Taylor Ward scores."
                          },
                          {
                              "inningHalf": "BOTTOM",
                              "team": {
                                  "id": 124,
                                  "abbreviation": "LAA"
                              },
                              "scoreChange": 1,
                              "awayScore": 0,
                              "homeScore": 2,
                              "playDescription": "Mike Trout doubles (6) on a sharp fly ball to left fielder Richie Palacios.   Shohei Ohtani scores."
                          }
                      ]
                  },
                  {
                      "inningNumber": 7,
                      "awayScore": 0,
                      "homeScore": 0,
                      "scoringPlays": []
                  },
                  {
                      "inningNumber": 8,
                      "awayScore": 2,
                      "homeScore": 0,
                      "scoringPlays": [
                          {
                              "inningHalf": "TOP",
                              "team": {
                                  "id": 116,
                                  "abbreviation": "CLE"
                              },
                              "scoreChange": 2,
                              "awayScore": 2,
                              "homeScore": 0,
                              "playDescription": "Jose Ramirez homers (6) on a fly ball to right field.    Amed Rosario scores."
                          }
                      ]
                  },
                  {
                      "inningNumber": 9,
                      "awayScore": 1,
                      "homeScore": 0,
                      "scoringPlays": [
                          {
                              "inningHalf": "TOP",
                              "team": {
                                  "id": 116,
                                  "abbreviation": "CLE"
                              },
                              "scoreChange": 1,
                              "awayScore": 1,
                              "homeScore": 0,
                              "playDescription": "Myles Straw out on a sacrifice fly to right fielder Taylor Ward.   Luke Maile scores."
                          }
                      ]
                  }
              ],
              "current_inning": 9,
              "current_inning_half": null
          },
          "home": {
              "id": "MLB-Team-124",
              "name": "Angels",
              "market": "Los Angeles",
              "runs": 0,
              "hits": 0,
              "errors": 0,
              "win": 9,
              "loss": 5,
              "scoring": null,
              "probable_pitcher": null,
              "starting_pitcher": null
          },
          "away": {
              "id": "MLB-Team-116",
              "name": "Guardians",
              "market": "Cleveland",
              "runs": 0,
              "hits": 0,
              "errors": 0,
              "win": 5,
              "loss": 9,
              "scoring": null,
              "probable_pitcher": null,
              "starting_pitcher": null
          },
          "updatedAt": "2022-04-28T06:02:21.706Z"
      },
      {
          "id": {
              "timestamp": 1651125742,
              "date": "2022-04-28T06:02:22.000+00:00"
          },
          "gameFeedId": "MLB-Schedule-68995",
          "status": "closed",
          "gameNumber": 1,
          "finalInnings": {
              "inning": 9,
              "inning_half": null
          },
          "outcome": {
              "type": null,
              "count": null,
              "hitter": null,
              "pitcher": null,
              "allInnings": [
                  {
                      "inningNumber": 1,
                      "awayScore": 1,
                      "homeScore": 0,
                      "scoringPlays": [
                          {
                              "inningHalf": "TOP",
                              "team": {
                                  "id": 125,
                                  "abbreviation": "OAK"
                              },
                              "scoreChange": 1,
                              "awayScore": 1,
                              "homeScore": 0,
                              "playDescription": "Chad Pinder homers (3) on a fly ball to left center field."
                          }
                      ]
                  },
                  {
                      "inningNumber": 2,
                      "awayScore": 0,
                      "homeScore": 0,
                      "scoringPlays": []
                  },
                  {
                      "inningNumber": 3,
                      "awayScore": 0,
                      "homeScore": 0,
                      "scoringPlays": []
                  },
                  {
                      "inningNumber": 4,
                      "awayScore": 0,
                      "homeScore": 0,
                      "scoringPlays": []
                  },
                  {
                      "inningNumber": 5,
                      "awayScore": 0,
                      "homeScore": 0,
                      "scoringPlays": []
                  },
                  {
                      "inningNumber": 6,
                      "awayScore": 0,
                      "homeScore": 0,
                      "scoringPlays": []
                  },
                  {
                      "inningNumber": 7,
                      "awayScore": 0,
                      "homeScore": 0,
                      "scoringPlays": []
                  },
                  {
                      "inningNumber": 8,
                      "awayScore": 0,
                      "homeScore": 0,
                      "scoringPlays": []
                  },
                  {
                      "inningNumber": 9,
                      "awayScore": 0,
                      "homeScore": 0,
                      "scoringPlays": []
                  }
              ],
              "current_inning": 9,
              "current_inning_half": null
          },
          "home": {
              "id": "MLB-Team-136",
              "name": "Giants",
              "market": "San Francisco",
              "runs": 0,
              "hits": 0,
              "errors": 0,
              "win": 0,
              "loss": 1,
              "scoring": null,
              "probable_pitcher": null,
              "starting_pitcher": null
          },
          "away": {
              "id": "MLB-Team-125",
              "name": "Athletics",
              "market": "Oakland",
              "runs": 0,
              "hits": 0,
              "errors": 0,
              "win": 1,
              "loss": 0,
              "scoring": null,
              "probable_pitcher": null,
              "starting_pitcher": null
          },
          "updatedAt": "2022-04-28T06:02:22.634Z"
      }
  ]
}

export const selectedTeamData = {
  "pointSystem": {
      "skater": {
          "goal": 7,
          "shots": 3,
          "assists": 5,
          "shortHanded": 12,
          "OTGoal": 12
      },
      "goalie": {
          "goalsAgainst": -2,
          "save": 1
      },
      "teamD": {
          "goalsAgainst": -3,
          "shotsAgainst": -0.5
      }
  },
  "_id": "6232b63cc917cef5b8268243",
  "gameID": 1543,
  "challenge_amount": 0,
  "currency": "USD",
  "endDate": "2022-03-19",
  "enrolled_users": 1,
  "entryFees": 1,
  "gameStatus": "Activated",
  "gameType": "NHL_Fantasy",
  "games": [
      {
          "id": "d3d2d64c-e299-4e3b-9852-cf3283f1ebe0",
          "away": {
              "id": "4415ce44-0f24-11e2-8525-18a905767e44",
              "name": "Colorado Avalanche",
              "alias": "COL",
              "sr_id": "sr:team:3682",
              "reference": "21"
          },
          "home": {
              "id": "44155909-0f24-11e2-8525-18a905767e44",
              "name": "San Jose Sharks",
              "alias": "SJ",
              "sr_id": "sr:team:3696",
              "reference": "28"
          },
          "status": "scheduled"
      },
      {
          "id": "32c8e541-491c-47b0-9f48-32788c3f08c0",
          "away": {
              "id": "4416d559-0f24-11e2-8525-18a905767e44",
              "name": "Buffalo Sabres",
              "alias": "BUF",
              "sr_id": "sr:team:3678",
              "reference": "7"
          },
          "home": {
              "id": "44159241-0f24-11e2-8525-18a905767e44",
              "name": "Calgary Flames",
              "alias": "CGY",
              "sr_id": "sr:team:3679",
              "reference": "20"
          },
          "status": "scheduled"
      },
      {
          "id": "5b2b12e2-9bb8-4050-aa92-2b1078c2cb72",
          "away": {
              "id": "44179d47-0f24-11e2-8525-18a905767e44",
              "name": "Philadelphia Flyers",
              "alias": "PHI",
              "sr_id": "sr:team:3699",
              "reference": "4"
          },
          "home": {
              "id": "4416f5e2-0f24-11e2-8525-18a905767e44",
              "name": "Ottawa Senators",
              "alias": "OTT",
              "sr_id": "sr:team:3700",
              "reference": "9"
          },
          "status": "scheduled"
      },
      {
          "id": "c97bec1e-f411-466a-8d11-c2ab188992d6",
          "away": {
              "id": "4418464d-0f24-11e2-8525-18a905767e44",
              "name": "Florida Panthers",
              "alias": "FLA",
              "sr_id": "sr:team:3687",
              "reference": "13"
          },
          "home": {
              "id": "441862de-0f24-11e2-8525-18a905767e44",
              "name": "Anaheim Ducks",
              "alias": "ANA",
              "sr_id": "sr:team:3675",
              "reference": "24"
          },
          "status": "scheduled"
      },
      {
          "id": "5c71484f-e458-4172-a865-ea3440700fe2",
          "away": {
              "id": "4416ba1a-0f24-11e2-8525-18a905767e44",
              "name": "Boston Bruins",
              "alias": "BOS",
              "sr_id": "sr:team:3677",
              "reference": "6"
          },
          "home": {
              "id": "44180e55-0f24-11e2-8525-18a905767e44",
              "name": "Winnipeg Jets",
              "alias": "WPG",
              "sr_id": "sr:team:3676",
              "reference": "52"
          },
          "status": "scheduled"
      },
      {
          "id": "2460e2b8-c803-4b35-b8b9-27342a79dee2",
          "away": {
              "id": "4417eede-0f24-11e2-8525-18a905767e44",
              "name": "Washington Capitals",
              "alias": "WSH",
              "sr_id": "sr:team:3691",
              "reference": "15"
          },
          "home": {
              "id": "44182a9d-0f24-11e2-8525-18a905767e44",
              "name": "Carolina Hurricanes",
              "alias": "CAR",
              "sr_id": "sr:team:3680",
              "reference": "12"
          },
          "status": "scheduled"
      }
  ],
  "league": "NHL",
  "powersAvailable": [],
  "reward": [
      {
          "id": 1713,
          "PowerDfsGameId": 1543,
          "from": "1",
          "to": "1",
          "prize": "1",
          "amount": "50",
          "createdAt": "2022-03-17T04:17:00.000Z",
          "updatedAt": "2022-03-17T04:17:00.000Z"
      }
  ],
  "startDate": "2022-03-18",
  "startTime": "17:00",
  "target": "21",
  "prize_currency": "USD",
  "totalEntries": {
      "enrolled_users": 1,
      "gameID": 1543
  }
};

