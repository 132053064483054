import React from 'react';

const BasketBall = props => (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="29" viewBox="0 0 25 29" {...props}>
                <g fill="currentColor" fillRule="nonzero">
                        <path d="M21.956 8.794c-.058-.648-.18-1.29-.365-1.913l-1.685.505c.132.46.222.931.27 1.408H16.77c.166-1.495.757-2.911 1.704-4.08.311.378.588.784.825 1.214l1.55-.83C18.318.389 12.449-1.376 7.74 1.156 4.88 2.693 2.983 5.561 2.685 8.794H0v4.569L1.295 16.6c1.473 3.674 2.228 7.595 2.225 11.553h1.76c-.002-1.447-.099-2.892-.29-4.326l3.986 3.984 3.344-5.574 3.344 5.574 3.986-3.984c-.192 1.434-.289 2.879-.29 4.326h1.76c-.003-3.958.752-7.88 2.225-11.553l1.295-3.238v-4.57h-2.684zM11.44 1.806v3.468h1.76v-3.46c1.469.16 2.863.729 4.024 1.642-1.269 1.51-2.043 3.373-2.218 5.338H13.2v-1.76h-1.76v1.76H9.634C9.46 6.832 8.688 4.97 7.422 3.463c1.157-.919 2.55-1.493 4.018-1.657zm-5.28 2.9v.004c.949 1.17 1.542 2.587 1.709 4.084H4.452c.167-1.498.76-2.917 1.708-4.088zM2.93 15.948l-.75-1.874h4.244l-2.552 4.588c-.273-.914-.585-1.82-.942-2.714zm5.694 9.027l-4.013-4.014 3.338-6.007 3.344 5.574-2.669 4.447zm.85-10.901h5.692l-2.846 4.743-2.846-4.743zm6.542 10.901l-2.669-4.448 3.344-5.573 3.338 6.008-4.013 4.013zm5.694-9.027c-.357.894-.67 1.8-.946 2.714l-2.548-4.588h4.245l-.75 1.874zm1.17-3.634H1.76v-1.76h21.12v1.76z" transform="translate(-540 -1475) translate(363 1466) translate(146) translate(31 9)" />
                </g>
        </svg>
)

export default BasketBall;