import React from 'react';

const Calendar2 = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props?.size || "98"} height={props?.size || "24"} viewBox="0 0 98 24" {...props}>
        <g fill={props?.color} fillRule="evenodd">
        <g fillRule="nonzero">
            <g>
                <g>
                    <g>
                        <g>
                            <g>
                                <path fill="#F50" d="M0 24L98 24 98 20 0 20z" transform="translate(-400 -724) translate(110 446) translate(0 45) translate(30 22) translate(178 209) translate(82 2)"/>
                                <path fill="#FFF" d="M77 1L80.822 1 80.822 13.34 87 13.34 87 17 77 17zM88 1L88 17 98 17 98 13.449 91.685 13.449 91.685 10.731 98 10.731 98 7.181 91.685 7.181 91.685 4.551 98 4.551 98 1z" transform="translate(-400 -724) translate(110 446) translate(0 45) translate(30 22) translate(178 209) translate(82 2)"/>
                                <g fill="#FFF">
                                    <path d="M3.827 4.587v4.828H5.91c1.378 0 2.406-.957 2.406-2.403 0-1.446-1.028-2.425-2.406-2.425H3.827zM0 1.05h6.063c3.74 0 6.123 2.692 6.123 5.94 0 3.27-2.362 6.029-6.145 6.029H3.827v4.271H0V1.05zM13.171 17.29L17.02 17.29 17.02 1.05 13.171 1.05zM18.157 1.05L22.224 1.05 28.978 10.77 28.978 1.05 32.762 1.05 32.762 17.29 28.978 17.29 21.962 6.912 21.962 17.29 18.157 17.29zM54.015 12.158l1.437-3.186 1.436 3.186h-2.873zm5.186 5.132h4.455L55.588.026h-.273L47.247 17.29h4.455l.8-1.776H58.4l.801 1.776zM73.235 11.017c-.656 1.535-1.968 2.647-3.87 2.647-2.537 0-4.265-2.024-4.265-4.494 0-2.514 1.771-4.538 4.308-4.538 1.75 0 3.018.912 3.74 2.358l3.346-2.158C75.072 2.43 72.47.828 69.386.828c-4.701 0-8.179 3.604-8.179 8.342l.001.042 3.106 6.644c1.344 1.042 3.07 1.657 5.072 1.657 3.412 0 6.014-1.891 7.37-4.583l-3.52-1.913zM46.094 16.916L48.555 11.65 48.555 1.05 44.772 1.05 44.772 10.77 38.018 1.05 33.95 1.05 33.95 17.29 37.755 17.29 37.755 6.912 44.772 17.29 45.919 17.29z" transform="translate(-400 -724) translate(110 446) translate(0 45) translate(30 22) translate(178 209) translate(82 2)"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default Calendar2;