import React from "react";
import classes from "./powerCenterCard.module.scss";
import MLBPlayer from "../../assets/mlb-player.png";
import MLBPlayerOppsite from "../../assets/baseball-player-copynew.png";
import MLBFantasyPWRS from '../../assets/mlbFantasyPWRSbg.png'
import MLBFantasyPWRSDark from '../../assets/mlbFantasyPWRSDark.png'
import NHLFantasyPWRS from '../../assets/nhlFantasyPWRS.png'
import NHLFantasyPWRSDark from '../../assets/nhlFantasyPWRSDark.png'
import EnterWithPWRS from '../../assets/enterWithPWRS.png'
import MLBFantasyPWRSBanner from '../../assets/MLBFantasyPWRSBanner.png'
import NHLFantasyPWRSBanner from '../../assets/NHLFantasyPWRSBanner.png'
import pwrsCardBanner from '../../assets/pwrsCardBanner.svg'
import PWRSIcon from '../../assets/power.png'
import ClockIcon from '../../assets/clockIcon.svg'
import PrizeCup from '../../assets/prizecup.svg'
import NFLPlayer from "../../assets/nflCardBg.png";
import NBAPlayer from "../../assets/nbaCardBg.png";
import NHLPlayer from "../../assets/new-hockey-playerlogo.png";
import InfiniteEntry from "../../assets/invalid-name.svg";
import PowerCenterCardDetails from "../PowerCenterCardDetails";
import OutlineButton from "../OutlineButton";
import OutlineButtonViceVersa from "../OutlineButtonViceVersa";

import PowerCurrency from "../../assets/power-white.png";
import BtcCurrency from "../../assets/btc-white.png";
import EthCurrency from "../../assets/ethereum-white.png";

import OrangePowerCurrency from "../../assets/power-orange.png";
import OrangeBtcCurrency from "../../assets/btc-orange.png";
import OrangeEthCurrency from "../../assets/ethereum-orange.png";
import rechargeIcon from "../../assets/group-25.png";

import onenflbg from "../../assets/group-3-one-nfl.png";
import onenhlbg from "../../assets/group-3-one-nhl.png";
import nhlfantasychallengecardbg from '../../assets/nhlfantasychallengecardbg.png';
import rapidImage from "../../assets/rapid-rocket.png";

import chaseImage from "../../assets/background-chase-light.png";
import chaseImageDark from "../../assets/background-chase-dark.png";
import chasePrizePoolImage from "../../assets/chase-prize-pool.png";

import { getTimeZone } from "../../utility/shared";
import rechargeHeading from "../../assets/rechargeIcon.png";
import { useState } from "react";
import PowerCenterCardDetailsChaseTheAce from "../PowerCenterCardDetails/PowerCenterCardDetailsChaseTheAce";

const PowerCenterCard = (props) => {
  const {
    id = null,
    title = "",
    prize = 0,
    currency = "$",
    prize_currency = "USD",
    outOf = 0,
    total = 0,
    percent = 0,
    game_type = "PowerdFS",
    game_set_start = "",
    start_time = "",
    paid_game = false,
    entry_fee = 0,
    targeted_game = false,
    showDetails = false,
    totalPoints = 0,
    onDetailsClick = () => { },
    onBackClick = () => { },
    onNextClick = () => { },
    onEnter = () => { },
    PointsSystem = [],
    Power = [],
    PrizePayout = [],
    userHasEntered = false,
    totalEntries = 0,
    user={},
    powerdfs_challenge_amount = 0
  } = props || {};

  const [PWRSHover, setPWRSHover] = useState(false)

  const getBackgroundImageWithStyle = () => {
    let backgroundImageStyle = {
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "inherit",
    };
    if(game_type==="Fantasy_Chase_The_Ace"){
      if(PWRSHover){
        backgroundImageStyle.backgroundImage = `url(${chaseImageDark})`;
      }else{
      backgroundImageStyle.backgroundImage = `url(${chaseImage})`;
      }

    }
    else if (title === "MLB" && currency !== "PWRS") {
      backgroundImageStyle.backgroundImage = `url(${MLBPlayerOppsite})`;
      //backgroundImageStyle.backgroundPosition = "100px 0px";
    } else if (title === "MLB" && currency === "PWRS" && game_type.toLowerCase().includes("fantasy")) {
      if (PWRSHover) {
        backgroundImageStyle.backgroundImage = `url(${MLBFantasyPWRSDark})`;
        backgroundImageStyle.backgroundSize = "contain";
      }
      else {
        backgroundImageStyle.backgroundImage = `url(${MLBFantasyPWRS})`;
        backgroundImageStyle.backgroundSize = "contain";
      }
    } else if (title === "NFL") {
      backgroundImageStyle.backgroundImage = `url(${NFLPlayer})`;
      //backgroundImageStyle.backgroundPosition = "65px 60px";
    } else if (title === "NBA") {
      backgroundImageStyle.backgroundImage = `url(${NBAPlayer})`;
      //backgroundImageStyle.backgroundPosition = "-75px 68px";
    } else if (title === "NHL" && currency !== "PWRS" && (game_type === "PowerdFs_One" || game_type === "Fantasy_Promo")) {
      backgroundImageStyle.backgroundImage = `url(${onenhlbg})`;
    } else if (title === "NHL" && currency !== "PWRS" && (game_type === "Fantasy_Challenge")) {
      backgroundImageStyle.backgroundImage = `url(${nhlfantasychallengecardbg})`;
    } else if (title === "NHL" && currency === "PWRS") {
      if (PWRSHover) {
        backgroundImageStyle.backgroundImage = `url(${NHLFantasyPWRSDark})`;
        backgroundImageStyle.backgroundSize = "contain";
      }
      else {
        backgroundImageStyle.backgroundImage = `url(${NHLFantasyPWRS})`;
        backgroundImageStyle.backgroundSize = "contain";
      }
    } else if (title === "NFL" && game_type === "PowerdFs_One") {
      backgroundImageStyle.backgroundImage = `url(${onenflbg})`;
    } else {
      backgroundImageStyle.backgroundImage = `url(${NHLPlayer})`;
    }
    return backgroundImageStyle;
  };

  const numberWithCommas = (x) => {
    if (x >= 10000) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return x;
  };

  const getCurrency = (currency) => {
    if (currency.toUpperCase() === "BTC") {
      return BtcCurrency;
    } else if (currency.toUpperCase() === "ETH") {
      return EthCurrency;
    } else if (currency.toUpperCase() === "PWRS") {
      return PowerCurrency;
    }
  };

  const getEnterCurrency = (currency) => {
    if (currency.toUpperCase() === "PWRS") {
      return PWRSIcon;
    } else if (currency.toUpperCase() === "BTC") {
      return OrangeBtcCurrency;
    } else if (currency.toUpperCase() === "ETH") {
      return OrangeEthCurrency;
    } else if (currency.toUpperCase() === "USD") {
      return "$"
    }
  };

  const onEnterModal = () => { };

  const getPWRSBannerByLeague = (League) => {
    if (League === "NHL") {
      return (
        <div>
          <img className={PWRSHover ? classes.__PWRSBannerImage_Hover : classes.__PWRSBannerImage} src={NHLFantasyPWRSBanner} alt="NHLFantasyPWRSBanner" />
        </div>
      )
    } else if (League === "MLB") {
      return (
        <div>
          <img className={PWRSHover ? classes.__PWRSBannerImage_Hover : classes.__PWRSBannerImage} src={MLBFantasyPWRSBanner} alt="MLBFantasyPWRSBanner" />
        </div>
      )
    }
  }

  const getTitleContestByGameType = (gameType) => {

    if (gameType === "PowerdFS" && currency !=="PWRS") {
      return (
        <div className={classes.__power_center_card_powerdfs}>
          <span
            className={
              classes.__power_center_card_powerdfs_hr +
              " " +
              classes.__power_center_card_powerdfs_hr_left
            }
          ></span>
          <p className={classes.__power_center_card_powerdfs_title}>
            <span className={classes.__power_center_card_powerdfs_title_first}>
              {title}
            </span>{" "}
            PowerdFS
          </p>
          <span
            className={
              classes.__power_center_card_powerdfs_hr +
              " " +
              classes.__power_center_card_powerdfs_hr_right
            }
          ></span>
        </div>
      );
    } else if (gameType === "PowerdFs_promo" && currency !=="PWRS") {
      return (
        <div className={classes.__card_title}>
          <p className={classes.__card_title_text}>
            {title}{" "}
            <span className={classes.__card__title_first}>PowerdFS</span>
            <br /> Manager Challenge!
          </p>
        </div>
      );
    } else if (gameType === "PowerdFs_Recharge" && currency !=="PWRS") {
      return (
        <div className={classes.__card_title}>
          <p className={classes.__card_title_text}>
            {title}
            <img src={rechargeHeading} style={{ marginLeft: 2 }} />
          </p>
        </div>
      );
    } else if (gameType === "PowerdFs_One" && currency !=="PWRS") {
      return (
        <div className={classes.__card_title}>
          <p className={classes.__card_title_text} style={{ fontSize: 20 }}>
            {title}{" "}
            <span className={classes.__card__title_first}>PowerdFS One</span>
          </p>
        </div>
      );
    } else if (gameType === "Fantasy_Promo" && currency !=="PWRS") {
      return (
        <div className={classes.__card_title}>
          <p className={classes.__card_title_text}>
            {title}{" "}
            <span className={classes.__card__title_first}>Fantasy</span>
            <br /> Manager Challenge
          </p>
        </div>
      );
    } else if (gameType === "Fantasy_Challenge" && currency !=="PWRS") {
      return (
        <div className={classes.__card_title}>
          <p className={classes.__card_title_text}>
            {title}{" "}
            <span className={classes.__card__title_first}>Fantasy</span>
            <br /> {powerdfs_challenge_amount} Point Challenge
          </p>
        </div>
      );
    }
     else {
      return (
        <div className={classes.__card_title} hidden={currency === "PWRS"}>
          {gameType!=="Fantasy_Chase_The_Ace"&&(
          <p className={classes.__card_title_text}>
            {title}{" "}
            <span className={classes.__card__title_first}>{
              gameType === "Rapid_Fantasy"?"Rapid":
            (gameType === "NHL_Fantasy"  || gameType === "Fantasy") ? 'Fantasy' : 'PowerdFS'}</span>
            {(gameType === "NHL_Fantasy" ||gameType === "Rapid_Fantasy"|| gameType === "Fantasy") ?
              '' :
              <> <br />{totalPoints} Point Challenge!</>
            }
            <> {gameType === "Rapid_Fantasy"&&"Fantasy"}</>
          </p>
          )}
        </div>
      );
    }
  };

  const getDateContent = () => {
    return (
      <div className={classes.__start_end_date} hidden={(currency === "PWRS"||game_type==="Fantasy_Chase_The_Ace") && PWRSHover}>
        <span className={classes.__date_text} style={{ marginBottom: 0 }}>
          {game_set_start} | {start_time} ET
        </span>
      </div>
    );
  };
  const getDateContentChase = () => {
    return (
      <div className={classes.__start_end_date_chase}>
        <span className={classes.__date_text} style={{ marginBottom: 0 }}>
          {game_set_start} | {start_time} ET
        </span>
      </div>
    );
  };

  const getPrizeContestByGameType = (gameType) => {
    if (gameType === "PowerdFS" && currency !=="PWRS") {
      return (
        <div className={classes.__power_center_card_prize_pool}>
          <p
            className={
              classes.__power_center_card_prize_pool_common +
              " " +
              classes.__power_center_card_prize_pool_price
            }
          >
            {currency === "USD" ? (
              `$`
            ) : currency === "PWRS" ? (
              prize_currency === "USD" ? (
                `$`
              ) : (
                <img src={getCurrency(prize_currency)} width="20" alt="" />
              )
            ) : (
              <img src={getCurrency(currency)} width="20" alt="" />
            )}
            {numberWithCommas(prize)}
          </p>
          <p
            className={
              classes.__power_center_card_prize_pool_common +
              " " +
              classes.__power_center_card_prize_pool_text
            }
          >
            Prize Pool
          </p>
        </div>
      );
    } else if (gameType === "PowerdFs_promo" && currency !=="PWRS") {
      return (
        <div className={classes.__current_jackpot}>
          <span className={classes.__current_jackpot_text}>
            Manage your team to victory and win!
          </span>
          <h1 className={classes.__current_jackpot_amount}>
            {" "}
            {currency === "USD" ? (
              `$`
            ) : currency === "PWRS" ? (
              prize_currency === "USD" ? (
                `$`
              ) : (
                <img src={getCurrency(prize_currency)} width="20" alt="" />
              )
            ) : (
              <img src={getCurrency(currency)} width="20" alt="" />
            )}
            {numberWithCommas(prize)}
          </h1>
          <p
            className={
              classes.__power_center_card_prize_pool_common +
              " " +
              classes.__power_center_card_prize_pool_text
            }
          >
            Prize Pool
          </p>
        </div>
      );
    } else if (gameType === "PowerdFs_Recharge" && currency !=="PWRS") {
      return (
        <div className={classes.__current_jackpot}>
          <span
            className={classes.__current_jackpot_text}
            style={{ fontWeight: 400 }}
          >
            Power your team <br />
            to victory!
          </span>
          <h1
            className={classes.__current_jackpot_amount}
            style={{ marginBottom: 0 }}
          >
            {" "}
            {currency === "USD" ? (
              `$`
            ) : currency === "PWRS" ? (
              prize_currency === "USD" ? (
                `$`
              ) : (
                <img src={getCurrency(prize_currency)} width="20" alt="" />
              )
            ) : (
              <img src={getCurrency(currency)} width="20" alt="" />
            )}
            {numberWithCommas(prize)}
          </h1>
          <p
            style={{
              marginBottom: 25,
              color: "#f2f2f2",
              opacity: 0.6,
              marginTop: 10,
            }}
          >
            Prize Pool
          </p>
        </div>
      );
    } else if (gameType === "PowerdFs_One" && currency !=="PWRS") {
      return (
        <div className={classes.__current_jackpot}>
          <span
            className={classes.__current_jackpot_text}
            style={{ fontWeight: 400, height: "auto", marginTop: 16 }}
          >
            Try our fast paced <br />
            {title == "NFL" || title == "NBA"
              ? "One-Quarter"
              : title == "MLB"
                ? "One-Inning"
                : "One-Period"}{" "}
            Game!
          </span>
          <h1
            className={classes.__current_jackpot_amount}
            style={{ marginBottom: 10, marginTop: 20 }}
          >
            {" "}
            {currency === "USD" ? (
              `$`
            ) : currency === "PWRS" ? (
              prize_currency === "USD" ? (
                `$`
              ) : (
                <img src={getCurrency(prize_currency)} width="20" alt="" />
              )
            ) : (
              <img src={getCurrency(currency)} width="20" alt="" />
            )}
            {numberWithCommas(prize)}
          </h1>
          <p
            style={{
              marginBottom: 48,
              color: "#f2f2f2",
              opacity: 0.6,
              marginTop: 10,
            }}
          >
            Prize Pool
          </p>
        </div>
      );
    } else if (gameType === "Fantasy_Promo" && currency !=="PWRS") {
      return (
        <div className={classes.__current_jackpot}>
          <span className={classes.__current_jackpot_text}>
            Manage your team to victory and win!
          </span>
          <h1 className={classes.__current_jackpot_amount}>
            {" "}
            {currency === "USD" ? (
              `$`
            ) : currency === "PWRS" ? (
              prize_currency === "USD" ? (
                `$`
              ) : (
                <img src={getCurrency(prize_currency)} width="20" alt="" />
              )
            ) : (
              <img src={getCurrency(currency)} width="20" alt="" />
            )}
            {numberWithCommas(prize)}
          </h1>
          <p
            style={{
              marginBottom: 52,
              color: "#f2f2f2",
              opacity: 0.6,
              marginTop: -10,
            }}
          >
            Prize Pool
          </p>
        </div>
      );
    } else if (gameType === "Fantasy_Challenge" && currency !=="PWRS") {
      return (
        <div className={classes.__current_jackpot}>
          <span className={classes.__current_jackpot_text}>
            If your team gets {powerdfs_challenge_amount} points you win!
          </span>
          <h1 className={classes.__current_jackpot_amount}>
            {" "}
            {currency === "USD" ? (
              `$`
            ) : currency === "PWRS" ? (
              prize_currency === "USD" ? (
                `$`
              ) : (
                <img src={getCurrency(prize_currency)} width="20" alt="" />
              )
            ) : (
              <img src={getCurrency(currency)} width="20" alt="" />
            )}
            {numberWithCommas(prize)}
          </h1>
          <p
            style={{
              marginBottom: 52,
              color: "#f2f2f2",
              opacity: 0.6,
              marginTop: -10,
            }}
          >
            Prize Pool
          </p>
        </div>
      );
    }
    else if (gameType === "Fantasy_Chase_The_Ace") {
      return (
        <>{!PWRSHover&&<div className={classes.__current_jackpot +" "+ classes.chaseTheAcePrize}>
           <p
            // style={{
            //   marginBottom: 52,
            //   color: "#f2f2f2",
            //   opacity: 0.6,
            //   marginTop: -10,
            // }}
          >
            Prize Pool
          </p>
          <h1 className={classes.chaseTheAcePrizeAmount}>
            {" "}
            {currency === "USD" ? (
              `$`
            ) : currency === "PWRS" ? (
              prize_currency === "USD" ? (
                `$`
              ) : (
                <img src={getCurrency(prize_currency)} width="20" alt="" />
              )
            ) : (
              <img src={getCurrency(currency)} width="20" alt="" />
            )}
            {numberWithCommas(props?.chasePrizePool)}
          </h1>
          {gameType === "Fantasy_Chase_The_Ace"&&!PWRSHover&&
            <div className={classes.chasePrizePoolImage}>
            <img src={chasePrizePoolImage} alt=""/>
            </div>
            }
        </div>
        }</>
      );
    }
     else {
      return (
        <div className={classes.__current_jackpot} hidden={currency === "PWRS" && PWRSHover}>
          <span className={classes.__current_jackpot_text} hidden={currency === "PWRS"}>
          {gameType === "Rapid_Fantasy"?<span className={classes.__current_jackpot_text}>Manage your team <br /> to victory and win!</span>
            :(gameType === "NHL_Fantasy" || gameType === "Fantasy") ? <span className={classes.__current_jackpot_text}>Pick your team <br /> and Win!</span> : gameType==="Fantasy_Chase_The_Ace"?"":`Manage your team to ${totalPoints} points and win`}
          </span>
          <h1 className={classes.__current_jackpot_amount} style={{ textAlign: currency === "PWRS" ? "center" : "", marginTop: currency === "PWRS" ? "95px" : "" }}>
            {" "}
            {currency === "USD" ? (
              `$`
            ) : currency === "PWRS" ? (
              prize_currency === "USD" ? (
                `$`
              ) : (
                <img src={getCurrency(prize_currency)} width="20" alt="" />
              )
            ) : (
              <img src={getCurrency(currency)} width="20" alt="" />
            )}
            {(gameType === "NHL_Fantasy" ||gameType === "Rapid_Fantasy"|| gameType === "Fantasy"||gameType==="Fantasy_Chase_The_Ace") ? `${numberWithCommas(prize)}` : `${numberWithCommas(prize)}`}
          </h1>
          {(gameType === "NHL_Fantasy" ||gameType === "Rapid_Fantasy"|| gameType === "Fantasy" ||gameType==="Fantasy_Chase_The_Ace"|| currency==="PWRS") ?
            <p
              style={{
                marginBottom: 52,
                color: "#f2f2f2",
                opacity: currency === "PWRS" ? 1 : 0.6,
                marginTop: -10,
                textAlign: currency === "PWRS" ? "center" : "left"
              }}
            >
              Prize Pool 
             
            </p>
            : ""
          }
           {gameType === "Rapid_Fantasy"&&
              <div className={classes.rapidCard}>
              <div className={classes.rapidDuration}>
                <div className={classes.rapidImageText}>
                <img src={rapidImage} alt=""/>
                  <p className={classes.rapidText}>Rapid {props?.duration}-inning Fanasy Game</p>
                </div>
              </div>
              </div>}
             
        </div>
      );
    }
  };

  return !showDetails ? (
    <div onMouseEnter={() => setPWRSHover(true)} onMouseLeave={() => setPWRSHover(false)}
      className={`${game_type === "PowerdFS" && currency !== "PWRS"
          ? classes.__power_center_card
          : classes.__power_center__challenge_card
        }`}
      style={getBackgroundImageWithStyle()}
    >
      <span className={classes.topId}>ID {id}</span>
      {game_type !== "PowerdFS" && game_type !== "Fantasy" && game_type !== "NHL_Fantasy" &&game_type!=="Fantasy_Chase_The_Ace"&&game_type !== "Rapid_Fantasy"&& game_type !== "Fantasy_Promo" && game_type !== "Fantasy_Challenge" && (
        <div className={classes.__win_power}>
          <span className={classes.__win_power_span}>
            You have the Powers to win!
          </span>
        </div>
      )}
      {getTitleContestByGameType(game_type)}
      {getDateContent()}
      {currency === "PWRS" && PWRSHover === false &&
        <img className={classes.__PWRSEnterImage} src={EnterWithPWRS} alt="Enter with pwers" />
      }
    {game_type==="Fantasy_Chase_The_Ace"&&(
      <>
      {!PWRSHover?(
        <div className={classes.chaseTheAce}>
       <p className={classes.chaseTheAceTitleWrp}>
         <span className={classes.chaseTheAceTitle}><span>{title}</span>{" "}Chase</span><br/>
        <span className={classes.chaseTheAcesubTitle}>The Ace</span>
       </p>
       </div>

       ):(
        <div className={classes.chaseTheAce_Hover}>
          <p className={classes.chaseTheAceTitleWrp}>
            <span ><span>{title}</span>{" "}Chase The Ace</span>
          </p>
          <ol type="number" className={classes.chaseTheAceList}>
            <li><span> Finish </span> in 1st place. </li>
            <li><span> Pick </span> the Ace of Spades. </li>
            <li><span> Win </span> the prize pool!</li>
          </ol>
          <ul className={classes.chaseTheAceSubList}>
            <li><img src={PrizeCup} alt="PrizeCup" /> Prize Pool increases until Ace is picked!</li>
            <li><img src={ClockIcon} alt="ClockIcon" />{getDateContentChase()}</li>
          </ul>
        </div>
       )}
       </>
    )}
      {/* {currency === "PWRS" && game_type.toLowerCase().includes("fantasy") && getPWRSBannerByLeague(title)} */}
      {currency === "PWRS" &&
      <div className={PWRSHover ? classes.__PWRSBannerImage_Hover : classes.__PWRSBannerImage}>
        <img src={pwrsCardBanner} alt="pwrsCardBanner" />
        <p>
          <span>{title}{" "}</span>
          {
          // game_type==="Rapid_Fantasy"?"Rapid Fantasy":
          (["NHL_Fantasy", "Fantasy_Promo", "Fantasy_Challenge", "Fantasy","Fantasy_Chase_The_Ace"].indexOf(game_type) !== -1 ? "Fantasy" : "PowerdFS")}
        </p>
      </div>
      }

      {currency === "PWRS" && PWRSHover &&
        <span className={classes.__PWRS_jackpot_text} style={{ textAlign: "center", color: "#fb6e00" }}>Pick Today & Win Today!</span>
      }

      {currency === "PWRS" && PWRSHover &&
        <div className={classes.____PWRSCardInfo}>
          <ul>
            <li><img src={PrizeCup} alt="PrizeCup" />{
              prize_currency === "USD" ? (
                `$`
              ) : (
                <img src={getCurrency(prize_currency)} width="20" alt="" />
              )
            } {numberWithCommas(prize)} Prize Pool</li>
            <li><img src={ClockIcon} alt="ClockIcon" />{game_set_start} | {start_time} ET</li>
            <li><img src={PWRSIcon} alt="PWRSIcon" />Enter with PWRS!</li>
          </ul>
        </div>
      }
      {getPrizeContestByGameType(game_type)}
      <div className={classes.__card_button} hidden={(currency === "PWRS"||game_type==="Fantasy_Chase_The_Ace") && !PWRSHover}>
        {userHasEntered ? (
          <OutlineButton
            title={`Entered`}
            styles={{ fontWeight: 600 }}
          />
        ) : total && Number(total) === Number(totalEntries) ?
          <OutlineButton
            title={`Full`}
            styles={{ fontWeight: 600 }}
          />
          : (
            game_type == "PowerdFs_challenge" || game_type == "PowerdFs_promo" || game_type == "Fantasy_Promo" || game_type == "Fantasy_Challenge" ||game_type=="Fantasy_Chase_The_Ace"? (
              <OutlineButton
                title={`Go to Entry Page`}
                onClick={onEnter}
                styles={{ fontWeight: 600 }}
              />
            ) : paid_game ? (
              <OutlineButton
                currencyString={currency}
                currency={getEnterCurrency(currency)}
                title={`Enter  •  `}
                entry_fee={entry_fee}
                onClick={onEnter}
                styles={{ fontWeight: 600, background: currency === "PWRS" ? "#fb6e00" : "", color: currency === "PWRS" ? "#000" : "" }}
              />
            ) : (
              <OutlineButton
                title={`Free Entry`}
                onClick={onEnter}
                styles={{ fontWeight: 600 }}
              />
            )
          )}
      </div>
      {(game_type.toLowerCase().includes("promo") || game_type.toLowerCase().includes("challenge")) &&
        <p className={classes.no_purchase_text}>No Purchase Necessary</p>
      }
      {(game_type==="Rapid_Fantasy") &&
        <p className={classes.no_purchase_text}>Starting Inning: {props?.inning_start}</p>
      }
      {currency === "PWRS" && PWRSHover &&
        <div>
          <OutlineButton
            title={`View Details`}
            onClick={() => {
              onDetailsClick(id);
            }}
            styles={{ fontWeight: 600, position:"absolute", bottom:"23px" }}
          />
        </div>
      }
      {game_type==="Fantasy_Chase_The_Ace"&& PWRSHover &&
        <div>
          <OutlineButton
            title={`View Details`}
            onClick={() => {
              onDetailsClick(id);
            }}
            styles={{ fontWeight: 600, position:"absolute", bottom:"23px" }}
          />
        </div>
      }
      <div className={classes.__power_center_card_status_and_details}>
        <div className={classes.__power_center_card_total} 
          //hidden={!total}
          >
         {Object.keys(user).length>0&&(<>
          {total ? (
            <p hidden={(currency === "PWRS" ||game_type==="Fantasy_Chase_The_Ace") && PWRSHover}>
              {totalEntries} <span>of {total}</span>
            </p>
          ) : (
            // <p>
            //   {totalEntries}{" "}
            //   <span>
            //     of <img src={InfiniteEntry} alt="infinite entry" style={{verticalAlign: "middle"}}/>
            //   </span>
            // </p>
            <div className={game_type==="Fantasy_Chase_The_Ace"?classes.__power_center_card_total_entries_chase:classes.__power_center_card_total_entries} hidden={(currency === "PWRS" ||game_type==="Fantasy_Chase_The_Ace")&& PWRSHover}>
              Entries :{" "} {totalEntries}
            </div>
          )}
          </>)}
        </div>
        <div className={classes.__power_center_card_details} hidden={(currency === "PWRS"||game_type==="Fantasy_Chase_The_Ace")}>
          <div
            className={classes.__power_center_card_details_link}
            onClick={() => {
              onDetailsClick(id);
            }}
          >
            Details
          </div>
          <div
            className={classes.__power_center_card_details_link_forward_arrow}
          >
            {">"}
          </div>
          </div>
          {game_type==="Fantasy_Chase_The_Ace" &&!PWRSHover&&
           <div className={classes.__power_center_card_details}>
           <div
             className={classes.__power_center_card_details_link_cards}
           >
             Cards Remaining: {props?.cardsRemaining}
           </div>
           {/* <div
             className={classes.__power_center_card_details_link_forward_arrow}
           >
           </div> */}
        </div>}
      </div>
    </div>
  ) : (
    <>{game_type==="Fantasy_Chase_The_Ace"?
    <PowerCenterCardDetailsChaseTheAce
    getDateContentChase={getDateContentChase}
    getCurrency={getCurrency}
    currency={currency}
    currencyImg={getEnterCurrency(currency)}
    prize_currency={prize_currency}
    Power={Power}
    PrizePayout={PrizePayout}
    PointsSystem={PointsSystem}
    entry_fee={entry_fee}
    title={title}
    onBackClick={() => onBackClick()}
    onNextClick={() => onNextClick()}
    onEnter={onEnter}
    game_set_start={game_set_start}
    prize={numberWithCommas(prize)}
    userHasEntered={userHasEntered}
    gameTargetFull={total ? Number(total) === Number(totalEntries) : false}
    game_type={game_type}
    chasePrizePool={props?.chasePrizePool}
  />
    :
    <PowerCenterCardDetails
      getCurrency={getCurrency}
      currency={currency}
      currencyImg={getEnterCurrency(currency)}
      prize_currency={prize_currency}
      Power={Power}
      PrizePayout={PrizePayout}
      PointsSystem={PointsSystem}
      entry_fee={entry_fee}
      title={title}
      onBackClick={() => onBackClick()}
      onNextClick={() => onNextClick()}
      onEnter={onEnter}
      game_set_start={game_set_start}
      prize={numberWithCommas(prize)}
      userHasEntered={userHasEntered}
      gameTargetFull={total ? Number(total) === Number(totalEntries) : false}
      game_type={game_type}
    />}
    </>
  );
};

export default PowerCenterCard;
