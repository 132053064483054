import React from 'react';

const Stadium = props => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="37" height="27" viewBox="0 0 37 27">
        <g transform="translate(-325 -1476) translate(296 1466) translate(29 10)" fillRule="nonzero" fill="currentColor">
            <circle cx="26.645" cy="1.841" r="1" />
            <path d="M29.945 2.226c-.361-.153-.778.016-.93.378-.154.36.015.778.377.93 1.38.584 2.566 1.28 3.474 2.034l-2.826 3.23c-3.024-1.452-7.015-2.313-11.14-2.402V1.493c1.53.031 3.041.163 4.504.394.388.06.752-.204.813-.592.061-.387-.204-.751-.591-.812-1.763-.278-3.592-.419-5.437-.419C9.047.064 0 3.702 0 9.23c0 1.327.23 1.278 3.64 12.938.496 1.662 1.817 2.934 3.533 3.403 2.562.701 5.786 1.198 8.847 1.364.406.021.748-.302.748-.71V22.73h2.843v3.495c0 .393.318.71.71.71h.038c3.061-.166 6.285-.663 8.846-1.364 1.717-.469 3.038-1.74 3.534-3.403 3.247-11.1 3.64-11.578 3.64-12.938 0-3.353-3.345-5.697-6.434-7.004zM17.48 12.79c-2.37.05-4.731.342-6.896.857l-3.27-3.737c2.792-1.262 6.412-2.01 10.166-2.094v4.974zM9.055 14.06c-.82.249-1.578.527-2.264.833-1.392-.61-2.573-1.33-3.461-2.108.598-.789 1.508-1.54 2.676-2.21l3.05 3.485zm9.134.144c3.036 0 6.52.45 9.416 1.44-2.799.909-6.073 1.402-9.416 1.402-3.342 0-6.616-.493-9.415-1.403 2.896-.99 6.379-1.44 9.415-1.44zm11.4.689c-.687-.306-1.445-.584-2.265-.833l3.05-3.485c1.167.67 2.077 1.421 2.675 2.21-.888.777-2.069 1.497-3.46 2.108zm-.523-4.982l-3.27 3.737c-2.165-.515-4.526-.808-6.896-.857V7.817c3.755.085 7.374.832 10.166 2.094zM17.48 1.493v4.903c-4.125.09-8.116.95-11.14 2.401l-2.827-3.23c2.875-2.397 8.201-3.955 13.967-4.074zM2.499 6.568l2.555 2.919c-1.131.678-2.049 1.442-2.712 2.256-.563-.737-.92-1.566-.92-2.513 0-.91.362-1.803 1.077-2.662zm28.878 15.194c-.354 1.188-1.307 2.1-2.547 2.438-2.26.62-5.065 1.073-7.798 1.269v-3.45c0-.392-.319-.71-.711-.71h-4.263c-.393 0-.71.318-.71.71v3.45c-2.734-.196-5.539-.65-7.8-1.269-1.24-.338-2.192-1.25-2.546-2.438l-2.278-7.63c3.863 3.129 10.388 4.335 15.465 4.335 4.23 0 8.373-.76 11.666-2.142 1.51-.633 2.783-1.368 3.8-2.192l-2.278 7.629zm2.66-10.019c-.663-.814-1.58-1.578-2.712-2.256l2.555-2.92c.715.86 1.078 1.753 1.078 2.663 0 .939-.354 1.77-.921 2.513z" />
        </g>
    </svg>
)

export default Stadium;