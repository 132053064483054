import React from 'react';
import { useDispatch } from 'react-redux';
import * as NHLActions from "../../actions/NHLActions";
import * as MLBActions from "../../actions/MLBActions";
import CashPowerBalance from "../../components/CashPowerBalance";
import PowerSidebar from "../../components/PowerCollapesible";
import RankCard from "../../components/RankCard";
import RightPrizeBlock from "../../components/RightPrizeBlock";
import RightRankBox from "../../components/RightRankBox";
import classes from "./sidebar.module.scss"
import _ from "underscore";
function SidebarNew(props) {
    const dispatch = useDispatch();
    const {
        gameType = "",
        reward = [],
        entryFees = 0,
        gameStatus = "",
        powersAvailable = [],
        challengeAmount = 0
    } = props.selectedTeam;
    
    const onClickStandings = async () => {
        if(props?.isMlb){
            await dispatch(MLBActions.getFinalStandingsMLB(props.gid));
        }else{
            await dispatch(NHLActions.getFinalStandings(props.gid));
        }
    };
    return (
        <div className={classes.__sidebar_container} style={props?.styles}>
            <div className={classes.__sidebar_container_2}>
                {gameType == "PowerdFs_challenge" ? (
                    <>
                        <RightPrizeBlock targetPrize={100} />
                        <RightRankBox  target={10} />
                    </>
                ) : (
                    <>
                        <CashPowerBalance
                            powerBalance={100}
                            cashBalance={parseFloat(
                                _.max(reward, function (ele) {
                                    return ele.amount;
                                }).amount
                            )}
                            styles={{
                                width: "100%",
                                marginTop: "-40px",
                            }}
                            cashTitle="Prize Pool"
                            powerTitle="Top Prize"
                            entryTitle="Entry Fee"
                            centered
                            showIcons={false}
                            entryFee={entryFees}
                            currency={"USD"}
                        />
                        <RankCard
                            ranks={{}}
                            inProgress={gameStatus}
                            league={props?.league}
                            isMlb={props.isMlb}
                            gameType={gameType}
                            targetPoints={challengeAmount}
                            currentWin={100000}
                            onClickStandings={onClickStandings}
                            prizePool={_.reduce(
                                reward,
                                function (memo, num) {
                                    return memo + parseInt(num.amount) * parseInt(num.prize);
                                },
                                0
                            )}
                            {...props}
                        />
                    </>
                )}
                <br /><br />
                {gameType.toLowerCase().includes("fantasy") ?
                    <>
                        <a href="https://fanatics.93n6tx.net/c/2068372/613226/9663" target="_blank" id="613226">
                            <img style={{ marginTop: "20px" }} src="https://a.impactradius-go.com/display-ad/9663-613226" border="0" alt="ad" width="180" />
                        </a>
                    </> :
                    <PowerSidebar
                        collapse={true}
                        swapCounts={4}
                        dwallCounts={1}
                        challengeCounts={1}
                        pointMultiplierCounts={1}
                        pointBooster15x={1}
                        pointBooster2x={1}
                        pointBooster3x={1}
                        retroBoostCounts={1}
                        powerUpCounts={1}
                        game={{}}
                        powers={powersAvailable}
                    />
                }
            </div>
        </div>
    )
}

export default SidebarNew
