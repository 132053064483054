const privacy = [
    {
        title: 'OUR COMMITMENT TO PRIVACY',
        content: (
                <p>
                    Your privacy is of utmost importance to us. PowerPlay Systems  ("POWERPLAY SYSTEMS INCORPORATED", "we" or "us") has prepared this notice to describe our practices regarding the personal information we collect from users of our website located at https://poweredfs.com (the "Website"), our mobile application ("Application"), and our online services available through Website and the Application("Services"). This notice does not apply to websites, applications or services that do not display or link to this statement or that display or link to different privacy statements.
                </p>
        )
    },
    {
        title: 'THE INFORMATION WE COLLECT',
        content: (
            <>
                <h3>Information You Provide to Us</h3>
                <p>We may collect personal information from you, such as your first and last name, location, email address, username and password, and the country in which you reside when you create an account to log in to our platform (" Account ").When you  withdraw funds or claim prizes from your Account, we may collect all information necessary to complete the transaction, including your name, address, phone number, billing information and social security number(only for taxation purposes where required). If you use our Application on your mobile device, we may collect your phone number and unique device id number.If you provide us feedback or contact us via email, we will collect your name and email address, as well as any other content included in the email, in order to send you a reply. We will collect your display name that you creat for your public profile (" Profile ") . When you participate in any Contest on our platform (each "Contest") other participants in the Contest (each "Participant") may be able to see the Display Name from your Profile. When you participate in one of our surveys, we may collect additional profile information. If you participate in a Contest on our Platform, we may ask you for your email address and/or home number (to notify you whether you win or not). We may also ask for first and last names, and sometimes post-office addresses to verify your identity. We may also collect information at other points in our Website and Services where it is clear we are collecting such information.</p>
                <h3>Information Collected via Technology Information Collected by our Platform</h3>
                <p>To make our Website more useful to you, our Website automatically tracks certain information about the visits to our Website, including your browser type, operating system, Internet Protocol (IP) address (a number that is automatically assigned to your computer when you use the Internet, which may vary from session to session), domain name, clickstream data, referring/exist pages, and/or a date/time stamp for your visit. We use this information to analyze trends, administer the Website, gather demographic information about our user base as a whole, and to improve our Website and Services and for product development purposes. For example, some of the information may be collected so that when you visit the Website or Services again, it will recognize you and the information appropriate to your interests.</p>
                <h3>Cookies</h3>
                <p>Like many online services, we use cookies to collect information. "Cookies" are small pieces of information that a website sends to your computer’s hard drive while you are viewing the website. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on our Website. This type of information is collected to make the Website and Services more useful to you and to tailor your experience with us to meet your special interests and expectations.</p>
                <p>Pixel Tags. In addition, we use "Pixel Tags" (also referred to as clear Gifs, Web beacons or Web bugs). Pixel tags are tiny graphic images with a unique identifier, similar in function to Cookies, that are used to track online movements of web users. In contrast to Cookies, which are stored on a computer’s hard drive or web browser, Pixel Tags are embedded invisibly in web pages. Pixel Tags also allow us to send email messages in a format that users can read, and tell us whether emails have been opened to, in part, ensure that we are sending only messages that are of interest to our users. We may also use this information to reduce or eliminate messages sent to a user.</p>
                <p>Threat Detection. We use certain third-party service providers, to provide threat detection, user authentication and fraud prevention services. These service providers may automatically collect certain information about your visits to the Website and the device you use to connect to our Services, in order to monitor for potential threats or bad actors. This information may include, among other things, your IP address and your device’s unique device identifier.</p>
                <p>Collection of Data in Connection with Advertising. We may use third parties to serve ads on the Website and in the Application. In connection with the delivery of advertising, certain third parties may automatically collect information about your visits to this and other websites, your IP address, your ISP, the browser you use to visit our Website. They do this by using Cookies, Pixel Tags, or other technologies. Information collected may be used, among other things, to deliver advertising targeted to your interests and to better understand the usage and visitation of our Website and the other sites tracked by these third parties. This policy does not apply to, and we are not responsible for, Cookies or Pixel Tags in third party ads, and we encourage you to check the privacy policies of advertisers and/or ad services to learn about their use of Cookies and other technologies. If you would like more information about this practice and to know your choices about not having this information used by these companies, click herewww.aboutads.info/choices or www.networkadvertising.org/.</p>
                <h3>Mobile Services</h3>
                <p>We may also collect non-personal information from your mobile device if you have downloaded our Application(s). This information is generally used to help us deliver the most relevant information to you. Examples of information that may be collected and used include how you use the Application(s), and information about the type of device you use. In addition, in the event our Application(s) crash on your mobile device, we will receive information about your mobile device model software version and device carrier, that allows us to identify and fix bugs and otherwise improve the performance of our Application(s).</p>
                <h3>Third Party Analytics</h3>
                <p>We use third party service providers, such as Google Analytics, to help analyze how users use our services ("Analytics Companies"). These Analytics Companies use Cookies to collect information such as how often users visit our Website, what pages they visit, and what other sites they used prior to coming to our Services. We use the information we get from Analytics Companies only to improve our Website, Application(s) and Services.</p>
                <p>Referral Affiliates. We may enter into agreements with companies that refer users to our Service by way of hyperlinks made available on the referring party’s website. We may automatically collect certain information, such as [your IP address, referring website, and your IP address] via the Cookies and Pixel Tags that are provided to us in connection with your use of the referring party’s website. This policy does not apply to, and we are not responsible for, Cookies or Pixel Tags provided by third party Websites.</p>
                <h3>Location Information</h3>
                <p>We may collect your location information including for the purpose of verifying whether you are eligible to participate in a Contest on our Platform.</p>
            </>
        )
    },
    {
        title: 'INTERACTION WITH THIRD PARTY SITES AND SERVICES',
        content: (
            <p>Certain services or other materials displayed on the Website may integrate, be integrated into, or be provided in connection with third-party services and content. We do not control those third-party services and content and our Terms of Use and Privacy Notice do not apply to those third-party services. You should read the terms of use agreements and privacy policies that apply to such third-party services and content. 3House is not responsible for the practices used by any third-party websites or services linked to or from our Website, including, without limitation, the information or content contained within them. In addition, you agree that we are not responsible and do not have control over any third-parties that you authorize to access information you provide to them. If you are using a third-party website or service and you allow them to access your personal information you do so at your own risk.</p>
        )
    },
    {
        title: 'HOW WE USE INFORMATION',
        content: (
            <>
                <p>General Use In general, the personal information you submit to us is used either to respond to the requests you make, or to aid us in serving you better. We use your personal information in the following ways: to facilitate the creation of and secure your Account on our network; to identify you as a user in our system and to otherwise administer and operate our Website, Application(s) and Services; to improve the quality of experience when you interact with our Website, Application(s) and Services; to send you administrative email notifications, such as security or support and maintenance advisories; to deliver customized or personalized content, information or advertising to respond to your inquiries and other requests; and to send newsletters, surveys, offers and other promotional materials related to our Services or on behalf of third parties and to monitor the effectiveness of our marketing programs.</p>
                <p>User Feedback. We may post user feedback on the Website from time to time. We will share your feedback with your first name and last initial only. If we choose to post your first and last name along with your feedback, we will obtain your consent prior to posting your name with your feedback. If you make any comments on a blog or forum associated with the Website, you should be aware that any personal information you submit there can be read, collected, or used by other users of the forums, and could be used to send you unsolicited messages. We are not responsible for the personally identifiable information you choose to submit in these blogs and forums.</p>
                <p>Creation of Anonymous Data. We may create anonymized data (data that is not reasonably associated with or linked to any data that would reasonably be expected to allow someone to identify you or contact you) from personal information by excluding information (such as your name) that makes the data personally identifiable to you. We use this anonymized data to analyze request and usage patterns so that we may enhance the content of our Services and improve Website navigation. We reserve the right to use anonymized data for any purpose and disclose anonymized data to third parties for any reason.</p>
            </>
        )
    },
    {
        title: 'DISCLOSURE OF YOUR PERSONAL DATA',
        content: (
            <>
                <p>We disclose your personal information as described below and as described elsewhere in this notice Third Parties Designated by You. When you use our Platform, the personal information you provide will be shared with third parties that you designate to receive such information. For example, you may elect to communicate with other users who are participating in the same Game as you.</p>
                <h3>Third Party Service Providers</h3>
                <p>We may share your personal information with third party service providers to: provide you the Services that we offer you through our Website and Application(s); to conduct quality assurance testing; to facilitate the creation of accounts; to provide account authentication and user verification services; to provide technical support; and/or provide other services to 3House.</p>
                <h3>Internal Revenue Service and Other Taxing Authorities</h3>
                <p>If your earnings from Games exceeds a certain threshold, in accordance with our interpretation of applicable law, we will disclose the amount of your earnings and other related information to the United States Internal Revenue Service and other appropriate taxing authorities.</p>
                <h3>Profiles and Players</h3>
                <p>When you participate in a Game, we may make your Profile available to other Players of the Game and enable you to communicate with other Players, in which case your communications will be disclosed.</p>
                <h3>Third Party Referrals</h3>
                <p>We may enter into agreements with companies that refer users to our Service by way of hyperlinks made available on the referring party’s website. We may automatically collect certain information, such as [your IP address, referring website, and your IP address] via the Cookies and Pixel Tags that are provided to us in connection with your use of the referring party’s website. This policy does not apply to, and we are not responsible for, Cookies or Pixel Tags provided by third party Websites. Branded Partners Co-Branded Partners are third parties with whom we may jointly offer a service or feature. You can tell when you are accessing a service or feature offered by a Co-Branded Partner because the Co-Branded Partner's name will be featured prominently. You may be asked to provide information about yourself to register for a service offered by a Co-Branded Partner. In doing so, you may be providing your information to both us and the Co-Branded Partner, or we may share your information with the Co-Branded Partner. Please note that the Co-Branded Partner's privacy policy may also apply to its use of your information.</p>
                <h3>Promotions</h3>
                <p>When you participate in a Game, we may use of your name, likeness, voice, opinions, and biographical information for publicity, advertising, trade, or promotional purposes without further payment, consideration, notice, or approval.</p>
                <h3>Social Networking Sites</h3>
                <p>Our Application and Services may enable you to post content to Social Networking Services (each an "SNS") (e.g., Facebook). If you choose to do this, we will provide information to such SNSs in accordance with your elections. You acknowledge and agree that you are solely responsible for your use of those websites and that it is your responsibility to review the terms of use and privacy policy of the third-party provider of such SNSs. We will not be responsible or liable for: (i) the availability or accuracy of such SNSs; (ii) the content, products or services on or availability of such SNSs; or (iii) your use of any such SNSs.</p>
            </>
        )
    },
]

export default privacy;