import React from 'react';

const CellHigh = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props?.size || "80"} height={props?.size || "50"} viewBox="0 0 80 50" {...props}>
        <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
            <g>
                <path fill="#4F6773" d="M64.167 50h-55C4.113 50 0 45.89 0 40.833V9.167C0 4.11 4.113 0 9.167 0h55c5.053 0 9.166 4.11 9.166 9.167V10h.804c3.216 0 5.833 2.617 5.833 5.833v18.334c0 3.216-2.617 5.833-5.833 5.833h-.804v.833c0 5.057-4.113 9.167-9.166 9.167z" transform="translate(-718 -630) translate(718 630)"/>
                <path fill="#202124" d="M9.167 5C6.87 5 5 6.87 5 9.167v31.666C5 43.13 6.87 45 9.167 45h55c2.296 0 4.166-1.87 4.166-4.167V37.5c0-1.38 1.12-2.5 2.5-2.5h3.304c.46 0 .833-.373.833-.833V15.833c0-.46-.373-.833-.833-.833h-3.304c-1.38 0-2.5-1.12-2.5-2.5V9.167C68.333 6.87 66.463 5 64.167 5h-55z" transform="translate(-718 -630) translate(718 630)"/>
                <path fill="#658391" d="M79.97 25h-5v9.167c0 .46-.373.833-.833.833h-3.304c-1.38 0-2.5 1.12-2.5 2.5v3.333c0 2.297-1.87 4.167-4.166 4.167h-55C6.87 45 5 43.13 5 40.833V25H0v15.833C0 45.89 4.113 50 9.167 50h55c5.053 0 9.166-4.11 9.166-9.167V40h.804c3.216 0 5.833-2.617 5.833-5.833V25z" transform="translate(-718 -630) translate(718 630)"/>
                <path fill="#DADADA" fill-opacity=".1" d="M74.97 25H5v15.833C5 43.13 6.87 45 9.167 45h55c2.296 0 4.166-1.87 4.166-4.167V37.5c0-1.38 1.12-2.5 2.5-2.5h3.304c.46 0 .833-.373.833-.833V25z" transform="translate(-718 -630) translate(718 630)"/>
                <g fill="#4CAF50">
                    <path d="M9.75 0h-7.5C1.008 0 0 1.12 0 2.5v25C0 28.88 1.008 30 2.25 30h7.5c1.242 0 2.25-1.12 2.25-2.5v-25C12 1.12 10.992 0 9.75 0zM23.75 0h-7.5C15.008 0 14 1.12 14 2.5v25c0 1.38 1.008 2.5 2.25 2.5h7.5c1.242 0 2.25-1.12 2.25-2.5v-25C26 1.12 24.992 0 23.75 0zM38.75 0h-7.5C30.008 0 29 1.12 29 2.5v25c0 1.38 1.008 2.5 2.25 2.5h7.5c1.242 0 2.25-1.12 2.25-2.5v-25C41 1.12 39.992 0 38.75 0zM52.75 0h-7.5C44.008 0 43 1.12 43 2.5v25c0 1.38 1.008 2.5 2.25 2.5h7.5c1.242 0 2.25-1.12 2.25-2.5v-25C55 1.12 53.992 0 52.75 0z" transform="translate(-718 -630) translate(718 630) translate(10 10)"/>
                </g>
                <path fill="#429846" d="M16 10h-3.75C11.008 10 10 11.12 10 12.5v25c0 1.38 1.008 2.5 2.25 2.5H16V10zM31 10h-4.375C25.176 10 24 11.12 24 12.5v25c0 1.38 1.176 2.5 2.625 2.5H31V10zM45 10h-4.375C39.176 10 38 11.12 38 12.5v25c0 1.38 1.176 2.5 2.625 2.5H45V10zM59 10h-3.75C54.008 10 53 11.12 53 12.5v25c0 1.38 1.008 2.5 2.25 2.5H59V10z" transform="translate(-718 -630) translate(718 630)"/>
            </g>
        </g>
    </g>
</svg>
)

export default CellHigh;