import { Carousel } from "react-responsive-carousel";
import { useSelector } from 'react-redux';
import moment from "moment";
import { hasText } from "../../utility/shared";
import styles from './styles.module.scss';
import ClockIcon from "../../assets/icons/nhl/clock.svg";
import HockeyIcon from "../../assets/icons/nhl/hockey.svg";
import SoccerJerseyIcon from "../../assets/icons/nhl/soccer-jersey.svg";
import { useState } from "react";
import SwapModalMobile from "../../components/mobilecomponents/SwapModal";
import * as nhlActions from "../../actions/NHLActions";
import { isEmpty } from "lodash";
import { getLocalStorage } from "../../utility/shared";
import * as CryptoJS from "crypto-js";
import { CONSTANTS } from "../../utility/constants";
//import cardTeamImage from '../../assets/images/fanatics.jpg';
const TeamManagerMobileCard = (props) => {
    const { playerData = {}, type = '', setSecondModal, playerList, starPlayerCount, loadingPlayerList, selectedTeam, toggleReplaceModal, updateReduxState, powersAvailables } = props;
    // const { gameID } = selectedTeam || {};
    const [showReplaceModal, setReplaceModalState] = useState(false);
    const closeRenderModal = () => {
        setReplaceModalState(false);
    };
    const { powersAvailable = [] } = selectedTeam;

    function getTeamFromLocalStorage() {
      const encData = getLocalStorage(CONSTANTS.LOCAL_STORAGE_KEYS.NHL_LIVE_GAME);
      const byteData = CryptoJS.AES.decrypt(encData, CONSTANTS.DATA_ENC_KEY);
      const decSelectedTeamData = JSON.parse(
        byteData.toString(CryptoJS.enc.Utf8)
      );
      return decSelectedTeamData;
    }

    const selectedTeamLocal = getTeamFromLocalStorage();
    const {gameType=""} = selectedTeamLocal;

    function isPowerAvailable(type) {
        let powerss = powersAvailables;
        if (powerss == undefined) {
          powerss = powersAvailable;
        }
        if(powerss == "") powerss = [];
        if (!powerss || powerss === undefined) {
          return 0;
        }
        
        let available = 0;
        if (type === "Swap Player") {
          type = "Swap";
        }
        for (var i = 0; i < powerss.length; i++) {
          if (type === "Point Booster") {
            if (
              powerss[i].powerName === "1.5x Point Booster" ||
              powerss[i].powerName === "2x Point Booster" ||
              powerss[i].powerName === "3x Point Booster"
            ) {
              available = 1;
              break;
            }
          } else {
            if (powerss[i].powerName === type) {
              available = 1;
              break;
            }
          }
        }
        return available;
      }

    const {
        fantasyPlayerPosition = "",
        full_name = "",
        id = "",
        match = {},
        positionID = 0,
        teamId = "",
        name = "",
        gameID,
    } = playerData;
    const { home = {}, away = {}, status = "scheduled", scheduled = "", id: matchId } = match;
    const { 
        live_match_events = {}, 
        match_status = [],
        live_clock = "20:00",
        live_period = 1,
        live_strength = "even",
        setNhlEventData = [],
        live_team_logs = [],
        fantasyTeam,
        teamDPts = 0
    } = useSelector((state) => state.nhl);
    const { playerActualScores = []}=live_team_logs
    const { 
      posD1Points = fantasyTeam?.posD1Points ?? 0,
      posD2Points = fantasyTeam?.posD2Points ?? 0,
      posXW1Points = fantasyTeam?.posXW1Points ?? 0,
      posXW2Points = fantasyTeam?.posXW2Points ?? 0,
      posXW3Points = fantasyTeam?.posXW3Points ?? 0,
      posCenterPoints =fantasyTeam?.posCenterPoints ?? 0,
      posGoaliePoints = fantasyTeam?.posGoaliePoints ?? 0,
      teamDActual = []
    } = live_team_logs?.team || {};


    


    // const getTeamPoints = (id, id2) => {
    //     let filteredData = match_status.filter(x => x.id == id);
    //     if(filteredData.length > 0)
    //     {
    //       let a = filteredData[filteredData.length - 1];
    //       if(a.away.id == id2){
    //         return a?.away?.points;
    //       }
    //       if(a.home.id == id2){
    //         return a?.home?.points;
    //       }
    //     }
    //     return false;
    // };
    const getTeamPoints = (id, id2) => {
      let a;
      let b=0
      // let filteredData = match_status.filter(x => x.id == id);
      Object.keys(setNhlEventData).map((item, i) => {
        if(item===id){
          if(setNhlEventData[item]?.length!==0){
            let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);
            if(liveClockData?.length > 0)
            {
              a = liveClockData[liveClockData?.length-1];
              if(a.away.id == id2){
                b=a?.away?.points
                return a?.away?.points;
              }
              if(a.home.id == id2){
                b=a?.home?.points
                return a?.home?.points;
              }
            }
          }
        }  
      // return false;
      })
      return b
      // return false;
    }
    const getTeamDetails = (id) => {
        let filteredData = match_status.filter(x => x?.id == id);
        if(filteredData?.length > 0)
        {
          let a = filteredData[filteredData.length - 1];
          return a;
        }
        return false;
    };
///live clock setup....

    const [timeLeft, setTimeLeft] = useState("20:00");
    const [timeLeftG, setTimeLeftG] = useState("20:00");
    const [timeLeftC, setTimeLeftC] = useState("20:00");
    const [timeLeftXW1, setTimeLeftXW1] = useState("20:00");
    const [timeLeftXW2, setTimeLeftXW2] = useState("20:00");
    const [timeLeftXW3, setTimeLeftXW3] = useState("20:00");
    const [timeLeftD1, setTimeLeftD1] = useState("20:00");
    const [timeLeftD2, setTimeLeftD2] = useState("20:00");
    
    const [checkClockData, setCheckClockData] = useState("20:00")
    const [checkClockDataG, setCheckClockDataG] = useState("20:00")
    const [checkClockDataXW1, setCheckClockDataXW1] = useState("20:00")
    const [checkClockDataXW2, setCheckClockDataXW2] = useState("20:00")
    const [checkClockDataXW3, setCheckClockDataXW3] = useState("20:00")
    const [checkClockDataD1, setCheckClockDataD1] = useState("20:00")
    const [checkClockDataD2, setCheckClockDataD2] = useState("20:00")
    const [checkClockDataC, setCheckClockDataC] = useState("20:00")
    
    const [firstRenderClock, setFirstRenderClock] = useState(true)
    const [firstRenderClockG, setFirstRenderClockG] = useState(true)
    const [firstRenderClockXW1, setFirstRenderClockXW1] = useState(true)
    const [firstRenderClockXW2, setFirstRenderClockXW2] = useState(true)
    const [firstRenderClockXW3, setFirstRenderClockXW3] = useState(true)
    const [firstRenderClockD1, setFirstRenderClockD1] = useState(true)
    const [firstRenderClockD2, setFirstRenderClockD2] = useState(true)
    const [firstRenderClockC, setFirstRenderClockC] = useState(true)
    
    const [clockWithNew, setClockWithNew] = useState(true)
    const [clockWithNewG, setClockWithNewG] = useState(true)
    const [clockWithNewXW1, setClockWithNewXW1] = useState(true)
    const [clockWithNewXW2, setClockWithNewXW2] = useState(true)
    const [clockWithNewXW3, setClockWithNewXW3] = useState(true)
    const [clockWithNewD1, setClockWithNewD1] = useState(true)
    const [clockWithNewD2, setClockWithNewD2] = useState(true)
    const [clockWithNewC, setClockWithNewC] = useState(true)

    const [checkEventIdC, setCheckEventIdC] = useState(0)
    const [checkEventIdXW1, setCheckEventIdXW1] = useState(0)
    const [checkEventIdXW2, setCheckEventIdXW2] = useState(0)
    const [checkEventIdXW3, setCheckEventIdXW3] = useState(0)
    const [checkEventIdD1, setCheckEventIdD1] = useState(0)
    const [checkEventIdD2, setCheckEventIdD2] = useState(0)
    const [checkEventIdG, setCheckEventIdG] = useState(0)
    const [checkEventIdTD, setCheckEventIdTD] = useState(0)


    const [periodForTD, setPeriodForTD] = useState(1)
    const [periodForC, setPeriodForC] = useState(1)
    const [periodForXW1, setPeriodForXW1] = useState(1)
    const [periodForXW2, setPeriodForXW2] = useState(1)
    const [periodForG, setPeriodForG] = useState(1)
    const [periodForXW3, setPeriodForXW3] = useState(1)
    const [periodForD1, setPeriodForD1] = useState(1)
    const [periodForD2, setPeriodForD2] = useState(1)
    const calculateTimeLeft = async(newClock,preClock,eventId,periodTD) => {
      // await clearInterval(interval)
      setClockWithNew(false)
      setFirstRenderClock(false)
      setCheckClockData(newClock)
      setCheckEventIdTD(eventId)
      if(timeLeft!=="00:00"){
        setPeriodForTD(periodTD)
    
      let myString1 = newClock
      let difference1;
      let myStringParts
      let newminute
      let newSecond
      let i=moment.duration(`00:${myString1}`).asSeconds()
      const interval =  setInterval(async () => {
        // if(checkEventIdTD===0){
        //   setClockWithNew(true)
        //   clearInterval(interval)
        // }
        myStringParts = myString1?.split(':')
        newminute= myStringParts[0]
        newSecond= myStringParts[1];
        let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
        let difference=upperTime-i
        if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
          let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
          difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
          setTimeLeft(difference1)
          i++
          }else{
            if(newClock=="00:00"){
              setPeriodForTD(periodTD+1)
              setTimeLeft("20:00")
            }
            setClockWithNew(true)
            clearInterval(interval)
          }
        }, 1000);
      }else{
        setTimeout(() => {
          setPeriodForTD(periodTD+1)
          setClockWithNew(true)
          setTimeLeft("20:00")
        }, 500);
      }
    
    };
    
    const timerComponents = [];
    timeLeft &&Object.keys(timeLeft).forEach((interval) => {
      if (!timeLeft[interval]) {
        return;
      }
      if(interval=='minutes')
      timerComponents.push(
        <span>
          {timeLeft[interval]}{":"}
        </span>
      );
      else{
        timerComponents.push(
          <span>
            {timeLeft[interval]}
          </span>
        );
      }
    });
    
    
    
      const getTeamData = (id) => {
        let a;
        let priviouseEvent;
        let upperTime;
        let lowerTime;
        let difference;
        Object.keys(setNhlEventData).forEach((item, i) => {
        if(item===id){
        // if(item!=='type'){
          if(setNhlEventData[item]?.length!==0){
            let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);
            if(liveClockData?.length > 0)
            {
              liveClockData=liveClockData.reverse()
              a = liveClockData[liveClockData?.length-1];
           
              if(liveClockData?.length > 1  && a?.event>checkEventIdTD){
                let i=1;
                liveClockData?.forEach((element,index) => {
                  if(liveClockData[index-i]?.clock==a?.clock){
                    i++;
                  }
                  else if(liveClockData[index-i]?.clock!=a?.clock){
                    priviouseEvent=liveClockData[index-i]
                  }
                });
                // priviouseEvent=liveClockData[liveClockData?.length-2]
                upperTime=moment.duration(`00:${priviouseEvent?.clock}`).asSeconds()
                lowerTime=moment.duration(`00:${a?.clock}`).asSeconds()
                difference=upperTime-lowerTime
              }
              if(a?.clock!==checkClockData){
                // if(a?.clock=="00:00"){
                //     // setTimeLeft("00:00")
                //     setTimeout(() => {
                //       setTimeLeft(a?.clock)
                //     }, 500);
                //   }
                if(timeLeft!=="00:00"){
                 if(priviouseEvent &&difference>0&&clockWithNew&&firstRenderClock) {
                   setTimeout(() => {
                    calculateTimeLeft(a?.clock,a?.clock,a?.event,a?.period);
                   }, 1000);
                  }  
                  else if(priviouseEvent && difference>0 &&clockWithNew&&!firstRenderClock) {
                    setTimeout(() => {
                      calculateTimeLeft(a?.clock,timeLeft,a?.event,a?.period);
                    }, 1000);
                  }
                }else{
                  // if(priviouseEvent &&difference>0&&clockWithNew&&firstRenderClock) {
                  //   setTimeout(() => {
                  //     calculateTimeLeft("00:00",priviouseEvent?.clock,a?.event);
                  //   }, 1000);
                  // }  
                  // else if(priviouseEvent && difference>0 &&clockWithNew&&!firstRenderClock) {
                  //   setTimeout(() => {
                  //     calculateTimeLeft("00:00",timeLeft,a?.event);
                  //   }, 1000);
                  // }
                  setTimeout(() => {
                    setPeriodForTD(a?.period+1)
                    setTimeLeft("20:00")
                    setCheckEventIdTD(0)
                  }, 500);
                } 
                // else {
                //   // calculateTimeLeft(a?.clock,"20:00");
                //   setTimeout(() => {
                //     setTimeLeft(a?.clock)
                //   }, 1000);
                // } 
                }
            }
            else{
              if(!a)
                a = false;
              }
            }
        }
        })
        if(a)
          return a
        };
    
        // let intervalG
        const calculateTimeLeftG = async(newClock,preClock,eventId,periodG) => {
          // await clearInterval(intervalG)
          setClockWithNewG(false)
          setFirstRenderClockG(false)
          setCheckEventIdG(eventId)
          setCheckClockDataG(newClock)
          if(timeLeftG!=="00:00"){
            setPeriodForG(periodG)
          let myString1 = newClock
          let difference1;
          let myStringParts
          let newminute
          let newSecond
          let i=moment.duration(`00:${myString1}`).asSeconds()
    
           const intervalG =  setInterval(async () => {
    
            myStringParts = myString1?.split(':')
            newminute= myStringParts[0]
            newSecond= myStringParts[1];
            let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
            let difference=upperTime-i
            if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
              let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
              difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
              setTimeLeftG(difference1)
              i++
              }else{
                if(newClock=="00:00"){
                  setTimeLeftG("20:00")
                  setPeriodForG(periodG+1)
                }
                setClockWithNewG(true)
                clearInterval(intervalG)
              }
            }, 1000);
          }else{
            setTimeout(() => {
              setPeriodForG(periodG+1)
              setClockWithNewG(true)
              setTimeLeftG("20:00")
            }, 500);
          }
        
        };
    
    
    const timerComponentsG = [];
    timeLeftG &&Object.keys(timeLeftG).forEach((interval) => {
      if (!timeLeftG[interval]) {
        return;
      }
      if(interval=='minutes')
      timerComponentsG.push(
        <span>
          {timeLeftG[interval]}{":"}
        </span>
      );
      else{
        timerComponentsG.push(
          <span>
            {timeLeftG[interval]}
          </span>
        );
      }
    });
    // console.log("setNhlEventData==>",setNhlEventData);
    const getTeamDataG = (id) => {
      let a;
      let priviouseEvent;
      let upperTime;
      let lowerTime;
      let difference;
      Object.keys(setNhlEventData).forEach((item, i) => {
      if(item===id){
      // if(item!=='type'){
        if(setNhlEventData[item]?.length!==0){
          let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);
          if(liveClockData?.length > 0)
          {
            liveClockData=liveClockData.reverse()
            a = liveClockData[liveClockData?.length-1];
           
            if(liveClockData?.length > 1 && a?.event>checkEventIdG){
              let i=1;
              liveClockData?.forEach((element,index) => {
                if(liveClockData[index-i]?.clock==a?.clock){
                  i++;
                }
                else if(liveClockData[index-i]?.clock!=a?.clock){
                  priviouseEvent=liveClockData[index-i]
                }
              });
              // priviouseEvent=liveClockData[liveClockData?.length-2]
              upperTime=moment.duration(`00:${priviouseEvent?.clock}`).asSeconds()
              lowerTime=moment.duration(`00:${a?.clock}`).asSeconds()
              difference=upperTime-lowerTime
            }
            if(a?.clock!==checkClockDataG){
              // if(a?.clock=="00:00"){
              //     // setTimeLeft("00:00")
              //     setTimeout(() => {
              //       setTimeLeftG(a?.clock)
              //     }, 500);
              //   }
              if(timeLeftG!=="00:00"){
               if(priviouseEvent &&difference>0&&clockWithNewG&&firstRenderClockG) {
                 setTimeout(() => {
                  calculateTimeLeftG(a?.clock,a?.clock,a?.event,a?.period);
                 }, 1000);
                } 
                else if(priviouseEvent && difference>0 &&clockWithNewG&&!firstRenderClockG) {
                  setTimeout(() => {
                    calculateTimeLeftG(a?.clock,timeLeftG,a?.event,a?.period);
                  }, 1000);
                } 
              }
                else{
                  // if(priviouseEvent &&difference>0&&clockWithNewG&&firstRenderClockG) {
                  //   setTimeout(() => {
                  //     calculateTimeLeftG("00:00",priviouseEvent?.clock,a?.event);
                  //   }, 1000);
                  // } 
                  // else if(priviouseEvent && difference>0 &&clockWithNewG&&!firstRenderClockG) {
                  //   setTimeout(() => {
                  //     calculateTimeLeftG("00:00",timeLeftG,a?.event);
                  //   }, 1000);
                  // } 
                  setTimeout(() => {
                    setPeriodForG(a?.period+1)
                    setTimeLeftG("20:00")
                    setCheckEventIdG(0)
                  }, 500);
                }
              // else {
              //   // calculateTimeLeftG(a?.clock,"20:00");
              //   setTimeout(() => {
              //     setTimeLeftG(a?.clock)
              //   }, 1000);
              // } 
              }
          }
          else{
            if(!a)
              a = false;
            }
          }
      }
      })
      if(a)
        return a
      };
    
    
    //get clock and period for XW1
    // let intervalXW1
    const calculateTimeLeftXW1 = async(newClock,preClock,eventId,periodXW1) => {
      // await clearInterval(intervalXW1)
      setClockWithNewXW1(false)
      setFirstRenderClockXW1(false)
      setCheckEventIdXW1(eventId)
      setCheckClockDataXW1(newClock)
      if(timeLeftXW1!=="00:00"){
        setPeriodForXW1(periodXW1)
        // setBoosterXW1State(true)
    
      let myString1 = newClock
      let difference1;
      let myStringParts
      let newminute
      let newSecond
      let i=moment.duration(`00:${myString1}`).asSeconds()
       const intervalXW1 =  setInterval(async () => {
      
        myStringParts = myString1?.split(':')
        newminute= myStringParts[0]
        newSecond= myStringParts[1];
        let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
        let difference=upperTime-i
        if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
          let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
          difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
          setTimeLeftXW1(difference1)
          i++
          }else{
            if(newClock=="00:00"){
              setPeriodForXW1(periodXW1+1)
              setTimeLeftXW1("20:00")
            }
            setClockWithNewXW1(true)
            // setBoosterXW1State(false)
            clearInterval(intervalXW1)
          }
        }, 1000);
      }else{
        setTimeout(() => {
          setPeriodForXW1(periodXW1+1)
          setClockWithNewXW1(true)
          setTimeLeftXW1("20:00")
    
        }, 500);
      }
    
    };
    
    
    const timerComponentsXW1 = [];
    timeLeftXW1 &&Object.keys(timeLeftXW1).forEach((interval) => {
      if (!timeLeftXW1[interval]) {
        return;
      }
      if(interval=='minutes')
      timerComponentsXW1.push(
        <span>
          {timeLeftXW1[interval]}{":"}
        </span>
      );
      else{
        timerComponentsXW1.push(
          <span>
            {timeLeftXW1[interval]}
          </span>
        );
      }
    });
    const getTeamDataXW1 = (id) => {
      let a;
      let priviouseEvent;
      let upperTime;
      let lowerTime;
      let difference;
      Object.keys(setNhlEventData).forEach((item, i) => {
      if(item===id){
      // if(item!=='type'){
        if(setNhlEventData[item]?.length!==0){
          let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);
          if(liveClockData?.length > 0)
          {
            liveClockData=liveClockData.reverse()
            a = liveClockData[liveClockData?.length-1];
          
            if(liveClockData?.length > 1  && a?.event>checkEventIdXW1){
              let i=1;
    
              liveClockData?.forEach((element,index) => {
                if(liveClockData[index-i]?.clock==a?.clock){
                  i++;
                }
                else if(liveClockData[index-i]?.clock!=a?.clock){
                  priviouseEvent=liveClockData[index-i]
                }
              });
              // priviouseEvent=liveClockData[liveClockData?.length-2]
              upperTime=moment.duration(`00:${priviouseEvent?.clock}`).asSeconds()
              lowerTime=moment.duration(`00:${a?.clock}`).asSeconds()
              difference=upperTime-lowerTime
            }
            if(a?.clock!==checkClockDataXW1){
              // if(a?.clock=="00:00"){
              //     // setTimeLeft("00:00")
              //     setTimeout(() => {
              //       setTimeLeftXW1(a?.clock)
              //     }, 500);
              //   }
              if(timeLeftXW1!=="00:00"){
    
                if(priviouseEvent &&difference>0&&clockWithNewXW1&&firstRenderClockXW1) {
                  setTimeout(() => {
                    calculateTimeLeftXW1(a?.clock,a?.clock,a?.event,a?.period);
                  }, 1000);
                }  
                else if(priviouseEvent && difference>0 &&clockWithNewXW1&&!firstRenderClockXW1) {
    
                  setTimeout(() => {
                    calculateTimeLeftXW1(a?.clock,timeLeftXW1,a?.event,a?.period);
                  }, 1000);
                }  
              }else{
                setTimeout(() => {
                  setPeriodForXW1(a?.period+1)
                  setTimeLeftXW1("20:00")
                  setCheckEventIdXW1(0)
                }, 500);
                // if(priviouseEvent &&difference>0&&clockWithNewXW1&&firstRenderClockXW1) {
                //    setTimeout(() => {
                //     calculateTimeLeftXW1("00:00",priviouseEvent?.eventData?.clock,a?.event);
                //  }, 1000);
                // }  
                // else if(priviouseEvent && difference>0 &&clockWithNewXW1&&!firstRenderClockXW1) {
                //    setTimeout(() => {
                //     calculateTimeLeftXW1("00:00",timeLeftXW1,a?.event);
                //  }, 1000);
                // } 
              }
              // else {
              //   // calculateTimeLeftXW1(a?.eventData?.clock,"20:00");
              //   setTimeout(() => {
              //     setTimeLeftXW1(a?.eventData?.clock)
              //   }, 1000);
              // } 
              }
          }
          else{
            if(!a)
              a = false;
            }
          }
      }
      })
      if(a)
        return a
      };
    // get clcok and period for XW2
    // let intervalXW2
    const calculateTimeLeftXW2 = async(newClock,preClock,eventId,periodXW2) => {
      // await clearInterval(intervalXW2)
      setClockWithNewXW2(false)
      setFirstRenderClockXW2(false)
      setCheckEventIdXW2(eventId)
      setCheckClockDataXW2(newClock)
      if(timeLeftXW2!=="00:00"){
        setPeriodForXW2(periodXW2)
        // setBoosterXW2State(true)
    
      let myString1 = newClock
      let difference1;
      let myStringParts
      let newminute
      let newSecond
      let i=moment.duration(`00:${myString1}`).asSeconds()
       const intervalXW2 =  setInterval(async () => {
      
        myStringParts = myString1?.split(':')
        newminute= myStringParts[0]
        newSecond= myStringParts[1];
        let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
        let difference=upperTime-i
        if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
          let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
          difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
          setTimeLeftXW2(difference1)
          i++
          }else{
            if(newClock=="00:00"){
              setTimeLeftXW2("20:00")
              setPeriodForXW2(periodXW2+1)
              setCheckEventIdXW2(0)
            }
            setClockWithNewXW2(true)
            // setBoosterXW2State(false)
            clearInterval(intervalXW2)
          }
        }, 1000);
      }else{
        setTimeout(() => {
          setPeriodForXW2(periodXW2+1)
          setClockWithNewXW2(true)
          setTimeLeftXW2("20:00")
    
        }, 500);
      }
    
    };
    
    
    const timerComponentsXW2 = [];
    timeLeftXW2 &&Object.keys(timeLeftXW2).forEach((interval) => {
      if (!timeLeftXW2[interval]) {
        return;
      }
      if(interval=='minutes')
      timerComponentsXW2.push(
        <span>
          {timeLeftXW2[interval]}{":"}
        </span>
      );
      else{
        timerComponentsXW2.push(
          <span>
            {timeLeftXW2[interval]}
          </span>
        );
      }
    });
    const getTeamDataXW2 = (id) => {
      let a;
      let priviouseEvent;
      let upperTime;
      let lowerTime;
      let difference;
      Object.keys(setNhlEventData).forEach((item, i) => {
      if(item===id){
      // if(item!=='type'){
        if(setNhlEventData[item]?.length!==0){
          let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);
          if(liveClockData?.length > 0)
          {
            liveClockData=liveClockData.reverse()
            a = liveClockData[liveClockData?.length-1];
            if(liveClockData?.length > 1  && a?.event>checkEventIdXW2){
              let i=1;
              liveClockData?.forEach((element,index) => {
                if(liveClockData[index-i]?.clock==a?.clock){
                  i++;
                }
                else if(liveClockData[index-i]?.clock!=a?.clock){
                  priviouseEvent=liveClockData[index-i]
                }
              });
              // priviouseEvent=liveClockData[liveClockData?.length-2]
              upperTime=moment.duration(`00:${priviouseEvent?.clock}`).asSeconds()
              lowerTime=moment.duration(`00:${a?.clock}`).asSeconds()
              difference=upperTime-lowerTime
            }
            if(a?.clock!==checkClockDataXW2){
              // if(a?.clock=="00:00"){
              //     // setTimeLeft("00:00")
              //     setTimeout(() => {
              //       setTimeLeftXW2(a?.clock)
              //     }, 500);
              //   }
              if(timeLeftXW2!=="00:00"){
              if(priviouseEvent &&difference>0&&clockWithNewXW2&&firstRenderClockXW2) {
                 setTimeout(() => {
                  calculateTimeLeftXW2(a?.clock,a?.clock,a?.event,a?.period);
                 }, 1000);
                }  
              else if(priviouseEvent && difference>0 &&clockWithNewXW2&&!firstRenderClockXW2) {
                 setTimeout(() => {
                  calculateTimeLeftXW2(a?.clock,timeLeftXW2,a?.event,a?.period);
                 }, 1000);
              }
            }else{
              // if(priviouseEvent &&difference>0&&clockWithNewXW2&&firstRenderClockXW2) {
              //    setTimeout(() => {
              //     calculateTimeLeftXW2("00:00",priviouseEvent?.clock,a?.event);
              //    }, 1000);
              //   }  
              // else if(priviouseEvent && difference>0 &&clockWithNewXW2&&!firstRenderClockXW2) {
              //    setTimeout(() => {
              //     calculateTimeLeftXW2("00:00",timeLeftXW2,a?.event);
              //    }, 1000);
              // }
              setTimeout(() => {
                setPeriodForXW2(a?.period+1)
                setTimeLeftXW2("20:00")
                setCheckEventIdXW2(0)
              }, 500);
            }  
              // else {
              //   // calculateTimeLeftXW2(a?.clock,"20:00");
              //   setTimeout(() => {
              //     setTimeLeftXW2(a?.clock)
              //   }, 1000);
              // } 
              }
            }
              // setTimeout(() => {
              //   setTimeLeftXW2(a?.clock)
              // }, 500);
          
          else{
            if(!a)
              a = false;
            }
          }
      }
      })
      if(a)
        return a
      };
    //get clock and perod for XW3
    // let intervalXW3
    const calculateTimeLeftXW3 = async(newClock,preClock,eventId,periodXW3) => {
      // await clearInterval(intervalXW3)
        // setTimeout(() => {
        // }, 500);
    
      setClockWithNewXW3(false)
      setFirstRenderClockXW3(false)
      setCheckEventIdXW3(eventId)
      setCheckClockDataXW3(newClock)
      if(timeLeftXW3!=="00:00"){
        setPeriodForXW3(periodXW3)
        // setBoosterXW3State(true)
    
      let myString1 = newClock
      let difference1;
      let myStringParts
      let newminute
      let newSecond
      let i=moment.duration(`00:${myString1}`).asSeconds()
       const intervalXW3 =  setInterval(async () => {
        // if(checkEventIdXW3===0){
        //   setClockWithNewXW3(true)
        //   clearInterval(intervalXW3)
        //   // setBoosterXW3State(false)
        // }
        myStringParts = myString1?.split(':')
        newminute= myStringParts[0]
        newSecond= myStringParts[1];
        let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
        let difference=upperTime-i
        if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
          let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
          difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
          setTimeLeftXW3(difference1)
          i++
          }else{
            if(newClock=="00:00"){
              setPeriodForXW3(periodXW3+1)
              setTimeLeftXW3("20:00")
              // setPeriodForXW3(periodForXW3+1)
            }
            setClockWithNewXW3(true)
            clearInterval(intervalXW3)
            // setBoosterXW3State(false)
    
          }
        }, 1000);
      }else{
        setTimeout(() => {
          setPeriodForXW3(periodXW3+1)
          setClockWithNewXW3(true)
          setTimeLeftXW3("20:00")
        }, 500);
      }
    };
    
    
    const timerComponentsXW3 = [];
    timeLeftXW3 &&Object.keys(timeLeftXW3).forEach((interval) => {
      if (!timeLeftXW3[interval]) {
        return;
      }
      if(interval=='minutes')
      timerComponentsXW3.push(
        <span>
          {timeLeftXW3[interval]}{":"}
        </span>
      );
      else{
        timerComponentsXW3.push(
          <span>
            {timeLeftXW3[interval]}
          </span>
        );
      }
    });
    const getTeamDataXW3 = (id) => {
    let a;
    let priviouseEvent;
    let upperTime;
    let lowerTime;
    let difference;
    Object.keys(setNhlEventData).forEach((item, i) => {
    if(item===id){
    // if(item!=='type'){
      if(setNhlEventData[item]?.length!==0){
        let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);
        if(liveClockData?.length > 0)
        {
          liveClockData=liveClockData.reverse()
          a = liveClockData[liveClockData?.length-1];
        
          if(liveClockData?.length > 1  && a?.event>checkEventIdXW3){
            let i=1;
            liveClockData?.forEach((element,index) => {
              if(liveClockData[index-i]?.clock==a?.clock){
                i++;
              }
              else if(liveClockData[index-i]?.clock!=a?.clock){
                priviouseEvent=liveClockData[index-i]
              }
            });
            // priviouseEvent=liveClockData[liveClockData?.length-2]
            upperTime=moment.duration(`00:${priviouseEvent?.clock}`).asSeconds()
            lowerTime=moment.duration(`00:${a?.clock}`).asSeconds()
            difference=upperTime-lowerTime
          }
          if(a?.clock!==checkClockDataXW3){
            // if(a?.clock=="00:00"){
            //     // setTimeLeft("00:00")
            //     setTimeout(() => {
            //       setTimeLeftXW3(a?.clock)
            //     }, 500);
            //   }
            if(timeLeftXW3!=="00:00"){
              if(priviouseEvent &&difference>0 &&clockWithNewXW3&&firstRenderClockXW3) {
                   setTimeout(() => {
                    calculateTimeLeftXW3(a?.clock,a?.clock,a?.event,a?.period);
                 }, 1000);
                }  
              else if(priviouseEvent && difference>0 &&clockWithNewXW3&&!firstRenderClockXW3) {
                 setTimeout(() => {
                  calculateTimeLeftXW3(a?.clock,timeLeftXW3,a?.event,a?.period);
                 }, 1000);
              }  
            }else{
            //   if(priviouseEvent &&difference>0 &&clockWithNewXW3&&firstRenderClockXW3) {
            //      setTimeout(() => {
            //       calculateTimeLeftXW3("00:00",priviouseEvent?.clock,a?.event);
            //      }, 1000);
            //   }  
            // else if(priviouseEvent && difference>0 &&clockWithNewXW3&&!firstRenderClockXW3) {
            //    setTimeout(() => {
            //     calculateTimeLeftXW3("00:00",timeLeftXW3,a?.event);
            //      }, 1000);
            // } 
            setTimeout(() => {
              setPeriodForXW3(a?.period+1)
              setTimeLeftXW3("20:00")
              setCheckEventIdXW3(0)
            }, 500);
          }  
            // else {
            //   // calculateTimeLeftXW3(a?.clock,"20:00");
            //   setTimeout(() => {
            //     setTimeLeftXW3(a?.clock)
            //   }, 1000);
            // } 
            }
        }
        else{
          if(!a)
            a = false;
          }
        }
    }
    })
    if(a)
      return a
    };
    //get clock and  period for D1
    // let intervalD1
    const calculateTimeLeftD1 =async (newClock,preClock,eventId,periodD1) => {
      // await clearInterval(intervalD1)
      setClockWithNewD1(false)
      setFirstRenderClockD1(false)
      setCheckEventIdD1(eventId)
      setCheckClockDataD1(newClock)
      if(timeLeftD1!=="00:00"){
        // setBoosterD1State(true)
        setPeriodForD1(periodD1)
    
      let myString1 = newClock
      let difference1;
      let myStringParts
      let newminute
      let newSecond
      let i=moment.duration(`00:${myString1}`).asSeconds()
       const intervalD1 =  setInterval(async () => {
      
        myStringParts = myString1?.split(':')
        newminute= myStringParts[0]
        newSecond= myStringParts[1];
        let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
        let difference=upperTime-i
        if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
          let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
          difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
          setTimeLeftD1(difference1)
          i++
          }else{
            if(newClock=="00:00"){
              setPeriodForD1(periodD1+1)
              setTimeLeftD1("20:00")
            }
            // setBoosterD1State(false)
            setClockWithNewD1(true)
            clearInterval(intervalD1)
        
          }
        }, 1000);
      }else{
        setTimeout(() => {
          setPeriodForD1(periodD1+1)
          setClockWithNewD1(true)
          setTimeLeftD1("20:00")
    
        }, 500);
      }
    
    };
    
    
    const timerComponentsD1 = [];
    timeLeftD1 &&Object.keys(timeLeftD1).forEach((interval) => {
      if (!timeLeftD1[interval]) {
        return;
      }
      if(interval=='minutes')
      timerComponentsD1.push(
        <span>
          {timeLeftD1[interval]}{":"}
        </span>
      );
      else{
        timerComponentsD1.push(
          <span>
            {timeLeftD1[interval]}
          </span>
        );
      }
    });
    let d1;
    
    const getTeamDataD1 = (id) => {
      let priviouseEvent;
      let upperTime;
      let lowerTime;
      let difference;
      Object.keys(setNhlEventData).forEach((item, i) => {
      if(item===id){
      // if(item!=='type'){
        if(setNhlEventData[item]?.length!==0){
          let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);
          if(liveClockData?.length > 0)
          {
            liveClockData=liveClockData.reverse()
            d1 = liveClockData[liveClockData?.length-1];
            if(liveClockData?.length > 1  && d1?.event>checkEventIdD1){
              let i=1;
              liveClockData?.forEach((element,index) => {
                if(liveClockData[index-i]?.clock==d1?.clock){
                  i++;
                }
                else if(liveClockData[index-i]?.clock!=d1?.clock){
                  priviouseEvent=liveClockData[index-i]
                }
              });
              // priviouseEvent=liveClockData[liveClockData?.length-2]
              upperTime=moment.duration(`00:${priviouseEvent?.clock}`).asSeconds()
              lowerTime=moment.duration(`00:${d1?.clock}`).asSeconds()
              difference=upperTime-lowerTime
            }
            if(d1?.clock!==checkClockDataD1){
              // if(d1?.clock=="00:00"){
              //     // setTimeLeft("00:00")
              //     setTimeout(() => {
              //       setTimeLeftD1(d1?.clock)
              //     }, 500);
              //   }
              if(timeLeftD1!=="00:00"){
                if(priviouseEvent &&difference>0&&clockWithNewD1&&firstRenderClockD1) {
                     setTimeout(() => {
                      calculateTimeLeftD1(d1?.clock,d1?.clock,d1?.event,d1?.period);
                 }, 1000);
                  }  
                else if(priviouseEvent && difference>0 &&clockWithNewD1&&!firstRenderClockD1) {
                   setTimeout(() => {
                    calculateTimeLeftD1(d1?.clock,timeLeftD1,d1?.event,d1?.period);
                 }, 1000);
                }   
              }else{
              //   if(priviouseEvent &&difference>0&&clockWithNewD1&&firstRenderClockD1) {
              //      setTimeout(() => {
              //       calculateTimeLeftD1("00:00",priviouseEvent?.clock,d1?.event);
              //    }, 1000);
              //   }  
              // else if(priviouseEvent && difference>0 &&clockWithNewD1&&!firstRenderClockD1) {
              //    setTimeout(() => {
              //     calculateTimeLeftD1("00:00",timeLeftD1,d1?.event);
              //    }, 1000);
              // }}
              setTimeout(() => {
                setPeriodForD1(d1?.period+1)
                setTimeLeftD1("20:00")
                setCheckEventIdD1(0)
    
              }, 500);
              } 
              // else {
              //   // calculateTimeLeftD1(a?.clock,"20:00");
              //   setTimeout(() => {
              //     setTimeLeftD1(d1?.clock)
              //   }, 1000);
              // } 
              }
          }
          else{
            if(!d1)
            d1 = false;
            }
          }
      }
      })
      if(d1)
        return d1
      };
    //get clock and period for D2
    // let intervalD2
    
    const calculateTimeLeftD2 = async(newClock,preClock,eventId,periodD1) => {
      // await clearInterval(intervalD2)
      setClockWithNewD2(false)
      setFirstRenderClockD2(false)
      setCheckEventIdD2(eventId)
      
      setCheckClockDataD2(newClock)
      if(timeLeftD2!=="00:00"){
        // setBoosterD2State(true)
        setPeriodForD2(periodD1)
      let myString1 = newClock
      let difference1;
      let myStringParts
      let newminute
      let newSecond
      let i=moment.duration(`00:${myString1}`).asSeconds()
       const intervalD2 =  setInterval(async () => {
    
        myStringParts = myString1?.split(':')
        newminute= myStringParts[0]
        newSecond= myStringParts[1];
        let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
        let difference=upperTime-i
        if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
          let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
          difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
          setTimeLeftD2(difference1)
          i++
          }else{
            if(newClock=="00:00"){
              setPeriodForD2(periodD1+1)
              setTimeLeftD2("20:00")
            }
            // setBoosterD2State(false)
            clearInterval(intervalD2)
            setClockWithNewD2(true)
          }
        }, 1000);
      }else{
        setTimeout(() => {
          setPeriodForD2(periodD1+1)
          setClockWithNewD2(true)
          setTimeLeftD2("20:00")
    
        }, 500);
      }
    
    };
    
    
    const timerComponentsD2 = [];
    timeLeftD2 &&Object.keys(timeLeftD2).forEach((interval) => {
      if (!timeLeftD2[interval]) {
        return;
      }
      if(interval=='minutes')
      timerComponentsD2.push(
        <span>
          {timeLeftD2[interval]}{":"}
        </span>
      );
      else{
        timerComponentsD2.push(
          <span>
            {timeLeftD2[interval]}
          </span>
        );
      }
    });
    let d2;
    const getTeamDataD2 = (id) => {
      let priviouseEvent;
      let upperTime;
      let lowerTime;
      let difference;
      Object.keys(setNhlEventData).forEach((item, i) => {
    
      if(item===id){
      // if(item!=='type'){
        if(setNhlEventData[item]?.length!==0){
          let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);
    
          if(liveClockData?.length > 0 )
          {
            liveClockData=liveClockData.reverse()
            d2 = liveClockData[liveClockData?.length-1];
        
            if(liveClockData?.length > 1 && d2?.event>checkEventIdD2){
              let i=1;
              liveClockData?.forEach((element,index) => {
                if(liveClockData[index-i]?.clock==d2?.clock){
                  i++;
                }
                else if(liveClockData[index-i]?.clock!=d2?.clock){
                  priviouseEvent=liveClockData[index-i]
                }
              });
              // priviouseEvent=liveClockData[liveClockData?.length-2]
              upperTime=moment.duration(`00:${priviouseEvent?.clock}`).asSeconds()
              lowerTime=moment.duration(`00:${d2?.clock}`).asSeconds()
              difference=upperTime-lowerTime
            }
    
            if(d2?.clock!==checkClockDataD2){  
              
              if(timeLeftD2!=="00:00"){
                if(priviouseEvent && difference>0 &&clockWithNewD2 &&firstRenderClockD2) {
                    setTimeout(() => {
                      calculateTimeLeftD2(d2?.clock,d2?.clock,d2?.event,d2?.period);
                    }, 1000);
                    } 
                  else if(priviouseEvent && difference>0 &&clockWithNewD2&&!firstRenderClockD2) {
                    setTimeout(() => {
                      calculateTimeLeftD2(d2?.clock,timeLeftD2,d2?.event,d2?.period);
                    }, 1000);
                  }
                }else{
                  // if(priviouseEvent && difference>0 &&clockWithNewD2 &&firstRenderClockD2) {
                  //   setTimeout(() => {
                  //     calculateTimeLeftD2("00:00",priviouseEvent?.clock,d2?.event);
                  //   }, 1000);
                  //   } 
                  // else if(priviouseEvent && difference>0 &&clockWithNewD2&&!firstRenderClockD2) {
                  //   setTimeout(() => {
                  //     calculateTimeLeftD2("00:00",timeLeftD2,d2?.event);
                  //   }, 1000);
                  // }
                  setTimeout(() => {
                    setPeriodForD2(d2?.period+1)
                    setTimeLeftD2("20:00")
                    setCheckEventIdD2(0)
                  }, 500);
                }  
              // else {
              //   // calculateTimeLeftD2(a?.clock,"20:00");
              //   setTimeout(() => {
              //     setTimeLeftD2(a?.clock)
              //   }, 1000);
              // } 
              }
          }
          else{
              if(!d2)
              d2 = false;
            }
          }
      }
      })
      if(d2)
        return d2
      };
    
    //get clock and period for C
    // let intervalC
    const calculateTimeLeftC = async(newClock,preClock,eventId,periodC) => {
      // await clearInterval(intervalC)
      setClockWithNewC(false)
      setFirstRenderClockC(false)
      setCheckEventIdC(eventId)
      setCheckClockDataC(newClock)
      if(timeLeftC!=="00:00"){
        // setBoosterCState(true)
        setPeriodForC(periodC)
      let myString1 = newClock
      let difference1;
      let myStringParts
      let newminute
      let newSecond
      let i=moment.duration(`00:${myString1}`).asSeconds()
       const intervalC =  setInterval(async () => {
      
        myStringParts = myString1?.split(':')
        newminute= myStringParts[0]
        newSecond= myStringParts[1];
        let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
        let difference=upperTime-i
        if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
          let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
          difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
          setTimeLeftC(difference1)
          i++
          }else{
            if(newClock=="00:00"){
              setPeriodForC(periodC+1)
              setTimeLeftC("20:00")
            }
            // setBoosterCState(false)
            setClockWithNewC(true)
            clearInterval(intervalC)
          }
        }, 1000);
      }else{
        setTimeout(() => {
          setPeriodForC(periodC+1)
          setClockWithNewC(true)
          setTimeLeftC("20:00")
    
        }, 500);
      }
    
    };
    
    const timerComponentsC = [];
    timeLeftC &&Object.keys(timeLeftC).forEach((interval) => {
      if (!timeLeftC[interval]) {
        return;
      }
      if(interval=='minutes')
      timerComponentsC.push(
        <span>
          {timeLeftC[interval]}{":"}
        </span>
      );
      else{
        timerComponentsC.push(
          <span>
            {timeLeftC[interval]}
          </span>
        );
      }
    });
    const getTeamDataC = (id) => {
      let a;
      let priviouseEvent;
      let upperTime;
      let lowerTime;
      let difference;
      Object.keys(setNhlEventData).forEach((item, i) => {
      if(item===id){
      // if(item!=='type'){
        if(setNhlEventData[item]?.length!==0){
          let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);
          if(liveClockData?.length > 0)
          {
            liveClockData=liveClockData.reverse()
            a = liveClockData[liveClockData?.length-1];
            if(liveClockData?.length > 1  && a?.event>checkEventIdC){
              let i=1;
              liveClockData?.map((element,index) => {
                if(liveClockData[index-i]?.clock==a?.clock){
                  i++;
                }
                else if(liveClockData[index-i]?.clock!=a?.clock){
                  priviouseEvent=liveClockData[index-i]
                }
    
              });
    
              // priviouseEvent=liveClockData[liveClockData?.length-2]
              upperTime=moment.duration(`00:${priviouseEvent?.clock}`).asSeconds()
              lowerTime=moment.duration(`00:${a?.clock}`).asSeconds()
              difference=upperTime-lowerTime
            }
            if(moment.duration(`00:${a?.clock}`).asSeconds()<moment.duration(`00:${checkClockDataC}`).asSeconds()){
              // if(a?.clock=="00:00"){
              //     // setTimeLeft("00:00")
              //     setTimeout(() => {
              //       setTimeLeftC(a?.clock)
              //     }, 500);
              //   }
              if(timeLeftC!=="00:00"){
               if(priviouseEvent &&difference>0&&clockWithNewC&&firstRenderClockC) {
                   setTimeout(() => {
                    calculateTimeLeftC(a?.clock,a?.clock,a?.event,a?.period);

                 }, 1000);
                }  
              else if(priviouseEvent && difference>0 &&clockWithNewC&&!firstRenderClockC) {
                 setTimeout(() => {
                  calculateTimeLeftC(a?.clock,timeLeftC,a?.event,a?.period);
                 }, 1000);
              }   
            }else{
              setTimeout(() => {
                setPeriodForC(a?.period+1)
                setTimeLeftC("20:00")
                setCheckEventIdC(0)
    
              }, 500);
            //   if(priviouseEvent &&difference>0&&clockWithNewC&&firstRenderClockC) {
            //      setTimeout(() => {
            //       calculateTimeLeftC("00:00",priviouseEvent?.clock,a?.event);
            //      }, 1000);
            //   }  
            // else if(priviouseEvent && difference>0 &&clockWithNewC&&!firstRenderClockC) {
            //    setTimeout(() => {
            //     calculateTimeLeftC("00:00",timeLeftC,a?.event);
            //      }, 1000);
            // } 
            }
              // else {
              //   // calculateTimeLeftC(a?.clock,"20:00");
              //   setTimeout(() => {
              //     setTimeLeftC(a?.clock)
              //   }, 1000);
              // } 
              }
          }
          else{
            if(!a)
              a = false;
            }
          }
      }
      })
      if(a)
        return a
      };
    const getStatus = (type) => {
        if (
          `${status}`?.toLocaleLowerCase() === "scheduled" &&
          moment().diff(moment(scheduled).format()) < 0
        ) {
          return `${moment(scheduled).format("MMM Do")} - ${moment(
            scheduled
          ).format("hh:mm A")}`;
        } 
        else if(type=="nhl" && (Object.keys(setNhlEventData).length)>1){
          let state=status
          Object.keys(setNhlEventData).map((item, i) => {
            let a;
            if(item===match?.id){
              if(setNhlEventData[item]?.length!==0){
                let liveClockData = setNhlEventData[item].filter(x => x?.id  == match?.id);
          
                if(liveClockData?.length > 0 )
                {
                  liveClockData=liveClockData.reverse()
                  a = liveClockData[liveClockData?.length-1];
                  if(a?.clock=="00:00"){
                    if(a?.period==1){
                      state= `End of 1st Period`
                    }else if(a?.period==2){
                      state= `End of 2nd Period`
                    }
                    else{
                      state= `End of ${a?.period}th Period`
                    }
                  }     
                else {
                  let getMatchStatusDetails = match_status?.filter(x => x?.id == match?.id);
                  if(getMatchStatusDetails.length > 0)
                  {
                    let a = getMatchStatusDetails[getMatchStatusDetails.length - 1];
                    state= a?.status;
                  }
                  // return '';
                  }
                }
                else {
                  let getMatchStatusDetails = match_status?.filter(x => x?.id == match?.id);
                  if(getMatchStatusDetails.length > 0)
                  {
                    let a = getMatchStatusDetails[getMatchStatusDetails.length - 1];
                    state= a?.status;
                  }
                  }
              }  
              else {
                let getMatchStatusDetails = match_status?.filter(x => x?.id == match?.id);
                if(getMatchStatusDetails.length > 0)
                {
                  let a = getMatchStatusDetails[getMatchStatusDetails.length - 1];
                  state= a?.status;
                }
                }
            }
            else {
              let getMatchStatusDetails = match_status?.filter(x => x?.id == match?.id);
              if(getMatchStatusDetails.length > 0)
              {
                let a = getMatchStatusDetails[getMatchStatusDetails.length - 1];
                state= a?.status;
              }
              }
          })       
          return state;
        }
        else {
          let getMatchStatusDetails = match_status?.filter(x => x?.id == match?.id);
          if(getMatchStatusDetails.length > 0)
          {
            let a = getMatchStatusDetails[getMatchStatusDetails.length - 1];
            return a?.status;
          }
          return status;
        }
        return status;
    };
    const RenderStatus = ({ success = false, danger = false }) => (
        <p className={styles.status}>
            {(getStatus("nhl")=='inprogress' ||getStatus("nhl")=='created')? (Object.keys(setNhlEventData).length)<=1?"Loading...":<p className={styles.inprogressgame}>In Progress</p>:getStatus("nhl")?.toLowerCase()==="scheduled"?"Starting Soon":getStatus("nhl")}
        </p>
    );
    const getLiveGameData = (id) => {
        let a=false;
        Object.keys(setNhlEventData).forEach((item, i) => {
        if(item===id){
          let liveClockData = setNhlEventData[item].filter(x => x?.id  == id);
            if(liveClockData?.length > 0)
            {
              a= liveClockData[liveClockData?.length-1];
              }
            }
          })
          return a
        };
    const onSwap = async (player,swapPlayer) => {
        // console.log("swapResponse", swapResponse);
        // console.log("props.swapCount", props.swapCount);
        if (props.swapCount === 0) {
          alert("You cannot swap the players.");
          return;
        }
        let getLiveData=await getLiveGameData(swapPlayer?.match?.id)

        const [swapablePlayer] =
          !isEmpty(playerList) &&
          playerList?.listData?.length &&
          playerList?.listData?.filter(
            (player1) =>
              player1?.id === swapPlayer?.id && player1?.match?.id === swapPlayer?.match?.id
          );
    
    
        if (swapablePlayer) {
          let payload = {
            gameID: gameID,
            userID: localStorage.getItem('PERSONA_USER_ID'),
            playerID: player?.id,
            prePlayerName:player?.full_name,
            swapPlayerID: swapPlayer?.id,
            swapPlayerPositionID: player?.fantasyPlayerPosition + player?.positionID,
            period: getLiveData? getLiveData?.period:live_period,
            timeApplied:getLiveData?getLiveData?.clock : live_clock
          }
          let swapResponse = await nhlActions.swapPlayer(payload);
          if(swapResponse.status == 200) {
            updateReduxState(player, swapablePlayer);
            closeRenderModal();
            props.getFantasyTeam();
            //props.useSwap(player, true);
          }
        }
      };

    return (
        <div className={styles.liveCards}>
            <div className={styles.overLine}></div>
            <div className={styles.positionHeading}>
                <span className={styles.position}>{type == "TD" ? "Team D" : fantasyPlayerPosition}{["XW","D"].indexOf(fantasyPlayerPosition) > -1 ? positionID : ""}:</span>
                {type == 'TD' ? (
                    <span className={styles.points}>{teamDPts} Pts</span>
                ) : (
                    <span className={styles.points}>
                        {fantasyPlayerPosition === "C" ? posCenterPoints : null}
                        {fantasyPlayerPosition === "G" ? posGoaliePoints : null}
                        {fantasyPlayerPosition === "D" && positionID === 1
                            ? posD1Points
                            : null}
                        {fantasyPlayerPosition === "D" && positionID === 2
                            ? posD2Points
                            : null}
                        {fantasyPlayerPosition === "XW" && positionID === 1
                            ? posXW1Points
                            : null}
                        {fantasyPlayerPosition === "XW" && positionID === 2
                            ? posXW2Points
                            : null}
                        {fantasyPlayerPosition === "XW" && positionID === 3
                            ? posXW3Points
                            : null}{" "} Pts
                    </span>
                )}
            </div>
            <div className={styles.mainDiv}>
                <div className={styles.leftSide + " leftSide"}>
                <Carousel
                autoPlay={false}
                showArrows={false}
                showIndicators={false}
                showStatus={false}
                infiniteLoop={true}
                interval={300000}
                swipeScrollTolerance={100}
                >
                    <div className={styles.cardStats}>
                        <p className={styles.playerName}>{type == "TD" ? name : full_name}</p>
                        <p className={styles.playersStats}>
                            {type == "TD" ? ( 
                                <>
                                    GA: {teamDActual && teamDActual?.goalsAgainst?teamDActual?.goalsAgainst:0} | SA: {teamDActual && teamDActual?.shotsAgainst?teamDActual?.shotsAgainst:0}
                                </> ) : (
                                    <>
                                        G: {playerActualScores?.find(x => x.playerID == id)?playerActualScores?.find(x => x.playerID == id)?.goals+playerActualScores?.find(x => x.playerID == id)?.overTimeGoals+playerActualScores?.find(x => x.playerID == id)?.shortHandedGoals : "0"} | A: {playerActualScores?.find(x => x.playerID == id)?playerActualScores?.find(x => x.playerID == id)?.assists:"0"} | SOG: {playerActualScores?.find(x => x.playerID ==  id)?playerActualScores?.find(x => x.playerID ==  id)?.shotsOnGoal:"0"}
                                    </>
                                )
                            }
                            
                        </p>
                        {<RenderStatus
                          success={
                            hasText(status, "batting") ||
                            hasText(status, "pitching") ||
                            hasText(status, "hitting")
                          }
                          danger={hasText(status, "deck")}
                        />}
                        <div className={styles.bottomStats}>
                            <p className={styles.teams}>
                                <span>
                                    <img src={HockeyIcon} alt="Hockey Icon" width={12} height={12} />
                                </span>
                                {type == "TD" && (
                                  <> 
                                  {getStatus("nhl")=="inprogress" && (Object.keys(setNhlEventData).length)<=1 ?
                                      <>Loading...</>:
                                    (
                                    playerData.alias == away?.alias ? 
                                        <><span>{away?.alias} {getTeamPoints(match?.id, away?.id) !== false ? getTeamPoints(match?.id, away?.id) : 0}</span> vs {home.alias} {getTeamPoints(match?.id, home?.id) !== false ? getTeamPoints(match?.id, home?.id) : 0}</>
                                    :
                                    playerData.alias == home?.alias && 
                                        <>{away?.alias} {getTeamPoints(match?.id, away?.id) !== false ? getTeamPoints(match?.id, away?.id) : 0} vs <span>{home.alias} {getTeamPoints(match?.id, home?.id) !== false ? getTeamPoints(match?.id, home?.id) : 0}</span></>
                                    )}
                                    </>
                                  )
                                }
                                {type == "" && (
                                    <> 
                                    {getStatus("nhl")=="inprogress" && (Object.keys(setNhlEventData).length)<=1 ?
                                        <>Loading...</>:
                                      (
                                   <>
                                    {teamId == match?.away?.id ? 
                                        <><span>{away?.alias} {getTeamPoints(match?.id, away?.id) !== false ? getTeamPoints(match?.id, away?.id) : 0}</span> vs {home.alias} {getTeamPoints(match?.id, home?.id) !== false ? getTeamPoints(match?.id, home?.id) : 0}</>
                                    :
                                    teamId == match?.home?.id && 
                                        <>{away?.alias} {getTeamPoints(match?.id, away?.id) !== false ? getTeamPoints(match?.id, away?.id) : 0} vs <span>{home.alias} {getTeamPoints(match?.id, home?.id) !== false ? getTeamPoints(match?.id, home?.id) : 0}</span></>
                                    }
                                 </> )}</>
                                 )
                                }
                            </p>
                            <p className={styles.periods}>
                                <span>
                                {  getStatus("nhl")!="closed" && getStatus("nhl")!="complete" &&(
                                    <img src={ClockIcon} alt="Hockey Icon" width={12} height={12} />
                                )}
                                </span>
                                {/* P{getTeamDetails(match?.id) !== false ? (getTeamDetails(match?.id)?.period) : (live_period)} | {getTeamDetails(match?.id) !== false ? (typeof getTeamDetails(match?.id)?.eventData !== "undefined") ? getTeamDetails(match?.id)?.eventData?.clock : live_clock : live_clock} */}
                                {/* {type != "TD"? (
                                 <>
                                    P{ getTeamData(playerData?.matchId) !== false ? (getTeamData(playerData?.matchId)?.period) : (1)} | {getTeamData(playerData?.matchId) !== false ? (typeof getTeamData(playerData?.matchId)?.eventData?.clock !== "undefined") ? timerComponents: live_clock : "20:00"}
                                 
                                </>
                                ):(
                                <>
                                    P{ getTeamData(playerData?.match?.id) !== false ? (getTeamData(playerData?.match?.id)?.period) : (1)} | {getTeamData(playerData?.match?.id) !== false ? (typeof getTeamData(playerData?.match?.id)?.eventData?.clock !== "undefined") ? timerComponents: live_clock : "20:00"}
                                </>
                                
                                )}
                             */}
                             {/* {console.log("playerData=>",playerData)} */}
                              {playerData?.fantasyPlayerPosition=="C" && getStatus("nhl")!="closed" && getStatus("nhl")!="complete" && (
                                    <>
                                    {getStatus("nhl")=="inprogress" && ((Object.keys(setNhlEventData).length)<=1 ||(timeLeftC=="20:00" &&getTeamDataC(playerData?.match?.id)?.clock!=="20:00")&&firstRenderClockC) ?
                                       <>Loading...</>:
                                    <>
                                      {/* P{ getTeamData(playerData?.matchId) !== false ? (getTeamData(playerData?.matchId)?.period) : (live_period)} | {getTeamData(playerData?.matchId) !== false ? (typeof getTeamData(playerData?.matchId)?.?.clock !== "undefined") ? getTeamData(playerData?.matchId)?.?.clock: live_clock : live_clock} */}
                                      P{periodForC} | {getTeamDataC(playerData?.match?.id) !== false ? (typeof getTeamDataC(playerData?.match?.id)?.clock !== "undefined") ? timerComponentsC: timerComponentsC : "20:00"}
                                      {/* { timerComponents} */}
                                      {/* {timer} */}
                                      {/* getTeamData(playerData?.match?.id)?.?.clock  */}
                                    </>
                                    }
                                </>
                                )}
                                {playerData?.fantasyPlayerPosition=="XW" &&playerData?.positionID==1 && getStatus("nhl")!="closed" && getStatus("nhl")!="complete" && (
                                  <>
                                    {getStatus("nhl")=="inprogress" && ((Object.keys(setNhlEventData).length)<=1 ||(timeLeftXW1=="20:00" &&getTeamDataXW1(playerData?.match?.id)?.clock!=="20:00")&&firstRenderClockXW1) ?
                                        <>Loading...</>:
                                    <>
                                      {/* P{ getTeamData(playerData?.match?.id) !== false ? (getTeamData(playerData?.match?.id)?.period) : (live_period)} | {getTeamData(playerData?.match?.id) !== false ? (typeof getTeamData(playerData?.match?.id)?.?.clock !== "undefined") ? getTeamData(playerData?.match?.id)?.?.clock: live_clock : live_clock} */}
                                      P{periodForXW1} | {getTeamDataXW1(playerData?.match?.id) !== false ? (typeof getTeamDataXW1(playerData?.match?.id)?.clock !== "undefined") ? timerComponentsXW1: timerComponentsXW1 : "20:00"}
                                      {/* { timerComponents} */}
                                    </>
                                  }</>
                                )}
                                {playerData?.fantasyPlayerPosition=="XW" &&playerData?.positionID==2 && getStatus("nhl")!="closed" && getStatus("nhl")!="complete" && (
                                 <>
                                 {getStatus("nhl")=="inprogress" && ((Object.keys(setNhlEventData).length)<=1||(timeLeftXW2=="20:00" &&getTeamDataXW2(playerData?.match?.id)?.clock!=="20:00")&&firstRenderClockXW2)  ?
                                     <>Loading...</>:
                                  <>
                                    {/* P{ getTeamData(playerData?.match?.id) !== false ? (getTeamData(playerData?.match?.id)?.period) : (live_period)} | {getTeamData(playerData?.match?.id) !== false ? (typeof getTeamData(playerData?.match?.id)?.clock !== "undefined") ? getTeamData(playerData?.match?.id)?.clock: live_clock : live_clock} */}
                                    P{periodForXW2}  | {getTeamDataXW2(playerData?.match?.id) !== false ? (typeof getTeamDataXW2(playerData?.match?.id)?.clock !== "undefined") ? timerComponentsXW2: timerComponentsXW2 : "20:00"}
                                    {/* { timerComponents} */}
                                </>}
                                </>
                                )}
                                {playerData?.fantasyPlayerPosition=="XW" &&playerData?.positionID==3 && getStatus("nhl")!="closed" && getStatus("nhl")!="complete" && (
                                //  {playerData?.fantasyPlayerPosition=="XW" &&playerData?.positionID==3  && (
                                  <>
                                  {getStatus("nhl")=="inprogress" && ((Object.keys(setNhlEventData).length)<=1 ||(timeLeftXW3=="20:00" &&getTeamDataXW3(playerData?.match?.id)?.clock!=="20:00")&&firstRenderClockXW3) ?
                                      <>Loading...</>:
                                   <>
                                    {/* P{ getTeamData(playerData?.match?.id) !== false ? (getTeamData(playerData?.match?.id)?.period) : (live_period)} | {getTeamData(playerData?.match?.id) !== false ? (typeof getTeamData(playerData?.match?.id)?.clock !== "undefined") ? getTeamData(playerData?.match?.id)?.clock: live_clock : live_clock} */}
                                    P{periodForXW3} | {getTeamDataXW3(playerData?.match?.id) !== false ? (typeof getTeamDataXW3(playerData?.match?.id)?.clock !== "undefined") ? timerComponentsXW3: timerComponentsXW3 : "20:00"}
                                    {/* { timerComponents} */}
                                  </>}
                                </>
                                )}
                                {playerData?.fantasyPlayerPosition=="D" &&playerData?.positionID==1 &&getStatus("nhl")!="closed" && getStatus("nhl")!="complete" &&  (
                                   <>
                                   {getStatus("nhl")=="inprogress" && ((Object.keys(setNhlEventData).length)<=1 ||(timeLeftD1=="20:00" &&getTeamDataD1(playerData?.match?.id)?.clock!=="20:00")&&firstRenderClockD1) ?
                                       <>Loading...</>:
                                     <>
                                      {/* P{ getTeamData(playerData?.match?.id) !== false ? (getTeamData(playerData?.match?.id)?.period) : (live_period)} | {getTeamData(playerData?.match?.id) !== false ? (typeof getTeamData(playerData?.match?.id)?.clock !== "undefined") ? getTeamData(playerData?.match?.id)?.clock: live_clock : live_clock} */}
                                      P{periodForD1}  | {getTeamDataD1(playerData?.match?.id) !== false ? (typeof getTeamDataD1(playerData?.match?.id)?.clock !== "undefined") ? timerComponentsD1: timerComponentsD1 : "20:00"}
                                      {/* { timerComponents} */}
                                  </>}
                                  </>
                                )}
                                {playerData?.fantasyPlayerPosition=="D" &&playerData?.positionID==2 && getStatus("nhl")!="closed" && getStatus("nhl")!="complete" && (
                                   <>
                                   {getStatus("nhl")=="inprogress" && ((Object.keys(setNhlEventData).length)<=1 ||(timeLeftD2=="20:00" &&getTeamDataD2(playerData?.match?.id)?.clock!=="20:00")&&firstRenderClockD2) ?
                                       <>Loading...</>:
                                  <>
                                    {/* P{ getTeamData(playerData?.match?.id) !== false ? (getTeamData(playerData?.match?.id)?.period) : (live_period)} | {getTeamData(playerData?.match?.id) !== false ? (typeof getTeamData(playerData?.match?.id)?.clock !== "undefined") ? getTeamData(playerData?.match?.id)?.clock: live_clock : live_clock} */}
                                    P{periodForD2} | {getTeamDataD2(playerData?.match?.id) !== false ? (typeof getTeamDataD2(playerData?.match?.id)?.clock !== "undefined") ? timerComponentsD2: timerComponentsD2 : "20:00"}
                                    {/* { timerComponents} */}
                                 </>}
                                </>
                                )}
                                {playerData?.fantasyPlayerPosition=="G" &&getStatus("nhl")!="closed" && getStatus("nhl")!="complete" &&  (
                                    <>
                                    {getStatus("nhl")=="inprogress" && ((Object.keys(setNhlEventData).length)<=1 ||(timeLeftG=="20:00" &&getTeamDataG(playerData?.match?.id)?.clock!=="20:00")&&firstRenderClockG) ?
                                        <>Loading...</>:
                                   <>
                                    {/* P{ getTeamData(playerData?.match?.id) !== false ? (getTeamData(playerData?.match?.id)?.period) : (live_period)} | {getTeamData(playerData?.match?.id) !== false ? (typeof getTeamData(playerData?.match?.id)?.clock !== "undefined") ? getTeamData(playerData?.match?.id)?.clock: live_clock : live_clock} */}
                                    P{periodForG} | {getTeamDataG(playerData?.match?.id) !== false ? (typeof getTeamDataG(playerData?.match?.id)?.clock !== "undefined") ? timerComponentsG: timerComponentsG : "20:00"}
                                    {/* { timerComponents} */}
                                  </>}
                                </>
                                )}
                                {type=="TD" &&getStatus("nhl")!="closed" && getStatus("nhl")!="complete" && (
                                   <>
                                   {getStatus("nhl")=="inprogress" && ((Object.keys(setNhlEventData).length)<=1 ||(timeLeft=="20:00" &&getTeamData(playerData?.match?.id)?.clock!=="20:00")&&firstRenderClock) ?
                                       <>Loading...</>:
                                    <>
                                        P{periodForTD}  | {getTeamData(playerData?.match?.id) !== false ? (typeof getTeamData(playerData?.match?.id)?.clock !== "undefined") ? timerComponents: timerComponents : "20:00"}
                                    </>}
                                  </>
                                
                                )}
                            </p>
                            <p className={styles.evens}>
                                <span>
                                    {/* <img src={SoccerJerseyIcon} alt="Hockey Icon" width={12} height={12} /> */}
                                </span>
                                {/* {getTeamData(match?.id) !== false && (getTeamData(match?.id)?.home?.strength)==="even" 
                                    ? "Even Strength"
                                    : (getTeamData(match?.id)?.home?.strength) === undefined ? "Even Strength" : (getTeamData(match?.id)?.home?.alias ?getTeamData(match?.id)?.home?.alias:getTeamData(match?.id)?.home?.name +" "+ getTeamData(match?.id)?.home?.strength)
                                } */}
                                  {/* {getTeamData(playerData?.match?.id) !== false && (getTeamData(playerDataData?.match?.id)?.home?.strength)==="even" 
                                    ? "Even Strength"
                                    : (getTeamData(playerData?.match?.id)?.home?.strength) ===undefined ? "Even Strength" : (getTeamData(playerDataData?.match?.id)?.home?.alias ?getTeamData(playerDataData?.match?.id)?.home?.alias:getTeamData(playerData?.match?.id)?.home?.name +" "+ getTeamData(playerData?.match?.id)?.home?.strength)
                                    } */}
                            </p>
                        </div>
                    </div>
                    {/* <div className={styles.playerStatsSection}>
                        <p className={styles.playerName}>Aaron Rodgers</p>
                        <p className={styles.pointSummary}>
                            Points Summary
                        </p>
                        <div className={styles.statsTable}>
                            <div className={styles.top}>
                                <p>Time</p>
                                <p>Type</p>
                                <p>Power</p>
                                <p>Pts</p>
                            </div>
                            <div className={styles.bottom}>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                                <div className={styles.top}>
                                    <p>P1: 20:00</p>
                                    <p>Goal</p>
                                    <p>2x</p>
                                    <p>4</p>
                                </div>
                            </div>
                        </div>
                        <p className={styles.status}>
                            Total Points: 27
                        </p>
                    </div>
                    <div className={styles.teamImage}>
                        <img src={cardTeamImage} />
                    </div> */}
                </Carousel>
                </div>
                <div className={styles.fixedRight}>
                    <div className={styles.points}>
                        <p>Points</p>
                        {type == 'TD' ? (
                            <span className={styles.points}>{teamDPts}</span>
                        ) : (
                            <span className={styles.points}>
                                {fantasyPlayerPosition === "C" ? 
                                playerActualScores?.find(x => x.playerID == playerData?.id)?.totalPlayerPoints
                                ?playerActualScores?.find(x => x.playerID == playerData?.id)?.totalPlayerPoints:0
                                : null}
                                {fantasyPlayerPosition === "G" ? playerActualScores?.find(x => x.playerID == playerData?.id)?.totalPlayerPoints
                                ?playerActualScores?.find(x => x.playerID == playerData?.id)?.totalPlayerPoints:0 : null}
                                {fantasyPlayerPosition === "D" && positionID === 1
                                  ? playerActualScores?.find(x => x.playerID == playerData?.id)?.totalPlayerPoints
                                  ?playerActualScores?.find(x => x.playerID == playerData?.id)?.totalPlayerPoints:0
                                  : null}
                                {fantasyPlayerPosition === "D" && positionID === 2
                                  ?playerActualScores?.find(x => x.playerID == playerData?.id)?.totalPlayerPoints
                                  ?playerActualScores?.find(x => x.playerID == playerData?.id)?.totalPlayerPoints:0
                                  : null}
                                {fantasyPlayerPosition === "XW" && positionID === 1
                                  ?playerActualScores?.find(x => x.playerID == playerData?.id)?.totalPlayerPoints
                                  ?playerActualScores?.find(x => x.playerID == playerData?.id)?.totalPlayerPoints:0
                                  : null}
                                {fantasyPlayerPosition === "XW" && positionID === 2
                                  ?playerActualScores?.find(x => x.playerID == playerData?.id)?.totalPlayerPoints
                                  ?playerActualScores?.find(x => x.playerID == playerData?.id)?.totalPlayerPoints:0
                                  : null}
                                {fantasyPlayerPosition === "XW" && positionID === 3
                                  ?playerActualScores?.find(x => x.playerID == playerData?.id)?.totalPlayerPoints
                                  ?playerActualScores?.find(x => x.playerID == playerData?.id)?.totalPlayerPoints:0
                                  : null}
                            </span>
                        )}
                    </div>
                    <div className={styles.powers}>
                        {/*In future if want to add third power please remove below comment*/}
                        {/* <button style={{ background: "none", borderWidth: 0 }}>
                            <img
                                src={`/images/retro-boostBig.svg`}
                                alt="PowerName"
                            />
                        </button> */}
                        {type == 'TD' ? (
                            <>
                              { (`${status}`?.toLocaleLowerCase() === "scheduled" &&
                                moment().diff(moment(scheduled).format()) < 0)||(`${status}`?.toLocaleLowerCase() === "closed")?
                              (
                                <button hidden={gameType?.toLowerCase().includes("fantasy")} style={{ background: "none", borderWidth: 0, opacity: 0.5 }}>
                                    <img
                                        src={`/images/challenge-power.svg`}
                                        alt="Challenge"
                                    />
                                </button>
                              ):(
                                <button hidden={gameType?.toLowerCase().includes("fantasy")} style={{ background: "none", borderWidth: 0, opacity: isPowerAvailable("Challenge") ? 1 : 0.5 }}>
                                    <img
                                        src={`/images/challenge-power.svg`}
                                        alt="Challenge"
                                    />
                                </button>
                              )}
                                {
                                 (`${status}`?.toLocaleLowerCase() === "scheduled" &&
                                 moment().diff(moment(scheduled).format()) < 0)||(`${status}`?.toLocaleLowerCase() === "closed")?
                                 (
                                  <button hidden={gameType?.toLowerCase().includes("fantasy")} style={{ background: "none", borderWidth: 0, opacity: 0.5 }}>
                                  <img
                                      src={`/images/sheilds.svg`}
                                      alt="D-Wall"
                                  />
                                   </button>
                                 ):(
                                  <button hidden={gameType?.toLowerCase().includes("fantasy")} style={{ background: "none", borderWidth: 0, opacity: isPowerAvailable("D-Wall") ? 1 : 0.5 }}>
                                  <img
                                      src={`/images/sheilds.svg`}
                                      alt="D-Wall"
                                  />
                                   </button>
                                 )
                                }
            
                            </>
                        ) : (
                            <>
                            {(`${status}`?.toLocaleLowerCase() === "scheduled" &&
                                 moment().diff(moment(scheduled).format()) < 0)||(`${status}`?.toLocaleLowerCase() === "closed")?
                                 (
                                  <button hidden={gameType?.toLowerCase().includes("fantasy")} style={{ background: "none", borderWidth: 0, opacity:  0.5 }} >
                                  <img
                                      src={`/images/repeat.svg`}
                                      alt="Swap"
                                  />
                                  </button>
                                 ):(
                                  <button hidden={gameType?.toLowerCase().includes("fantasy")} style={{ background: "none", borderWidth: 0, opacity: isPowerAvailable("Swap") ? 1 : 0.5 }} 
                                  onClick={() => {
                                     if( isPowerAvailable("Swap")) { toggleReplaceModal(playerData); setReplaceModalState(true) }
                                  }}>
                                  <img
                                      src={`/images/repeat.svg`}
                                      alt="Swap"
                                  />
                                 </button>
                                 )}
                                {
                                  (`${status}`?.toLocaleLowerCase() === "scheduled" &&
                                  moment().diff(moment(scheduled).format()) < 0)||(`${status}`?.toLocaleLowerCase() === "closed")?
                                  (
                                    <button hidden={gameType?.toLowerCase().includes("fantasy")} style={{ background: "none", borderWidth: 0, opacity:  0.5}}>
                                    <img
                                        src={`/images/xp.svg`}
                                        alt="Point Booster"
                                    />
                                      </button>
                                  ):
                                  (
                                    <button hidden={gameType?.toLowerCase().includes("fantasy")} style={{ background: "none", borderWidth: 0, opacity: isPowerAvailable("Point Booster") ? 1 : 0.5}} 
                                    onClick={() => {if( isPowerAvailable("Point Booster")) setSecondModal(true)}}>
                                    <img
                                        src={`/images/xp.svg`}
                                        alt="Point Booster"
                                    />
                                </button>
                                  )
                                }

                            </>
                        )}
                        
                    </div>
                </div>
            </div>
            <SwapModalMobile
                player={playerData}
                visible={showReplaceModal}
                onClose={closeRenderModal}
                onSwap={onSwap}
                scrollable={true}
                playerList={playerList}
                starPlayerCount={starPlayerCount}
                loading={loadingPlayerList}
                dataMain={selectedTeam}
                pageType="nhl"
            />
    </div>
    );
};
export default TeamManagerMobileCard;