import React from 'react'
import PropTypes from 'prop-types';

function DocIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={ props?.size || "20" } height={ props?.size || "21" } viewBox="0 0 20 21">
            <g fill="none" fillRule="evenodd">
                <g fill="#FFF" fillRule="nonzero">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <path d="M5.073 0C4.018 0 3.16.856 3.16 1.904l-.07 9.87 1.045.008.07-9.874c0-.473.39-.858.87-.858h10.49V0H5.075z" transform="translate(-570 -393) translate(0 50) translate(438 122) translate(110 213) translate(22 8) translate(.91 1.818)"/>
                                        <path d="M15.595 0c-1.13 0-2.05.916-2.05 2.042v11.337c0 .82-.668 1.487-1.49 1.487-.823 0-1.493-.667-1.493-1.487v-1.308c0-.296-.241-.537-.539-.537H.857c-.298 0-.54.24-.54.537v1.308c0 1.412 1.154 2.561 2.57 2.561v-1.074c-.822 0-1.491-.667-1.491-1.487v-.77h8.088v.77c0 1.412 1.153 2.561 2.57 2.561 1.417 0 2.57-1.149 2.57-2.561v-8.4h2.48c.298 0 .54-.24.54-.537v-2.4c0-1.126-.92-2.042-2.05-2.042zm.97 3.904h-1.94V2.042c0-.534.435-.967.97-.967.535 0 .97.433.97.967v1.862z" transform="translate(-570 -393) translate(0 50) translate(438 122) translate(110 213) translate(22 8) translate(.91 1.818)"/>
                                        <path d="M3.09 14.554H12.1V15.94H3.09zM8.15 2.772H5.654c-.268 0-.484.31-.484.693 0 .383.216.693.484.693H8.15c.267 0 .483-.31.483-.693 0-.383-.216-.693-.483-.693zM11.573 4.851H5.695c-.29 0-.526.31-.526.693 0 .383.236.693.526.693h5.878c.29 0 .526-.31.526-.693 0-.382-.235-.693-.526-.693zM11.573 6.93H5.695c-.29 0-.526.31-.526.694 0 .382.236.693.526.693h5.878c.29 0 .526-.31.526-.693 0-.383-.235-.694-.526-.694zM11.573 9.01H5.695c-.29 0-.526.31-.526.693 0 .382.236.693.526.693h5.878c.29 0 .526-.31.526-.693 0-.383-.235-.693-.526-.693z" transform="translate(-570 -393) translate(0 50) translate(438 122) translate(110 213) translate(22 8) translate(.91 1.818)"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

DocIcon.propTypes = {
    size: PropTypes.number,
}

export default DocIcon

