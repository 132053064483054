import React from 'react';
import classes from './footer.module.scss';

const Footer = (props) => {
    const {
        entry_fee = '',
        onBack = () => { },
        onNext = () => { },
        onEnter = () => { },
        myGameCenter = false,
        userHasEntered = false,
        gameTargetFull = false,
        game_type = "",
        currency = "",
        currencyString = "",
        isCompleted=false
    } = props || {};
    return (
        <div className={classes.__power_center_card_details_footer}>
            <div className={classes.__power_center_card_details_footer_back}>
                <div className={classes.__power_center_card_details_back_arrow}>
                    {"<"}
                </div>
                <div className={classes.__power_center_card_details_footer_back_text} onClick={onBack}>
                    Back
                </div>
            </div>
            {!isCompleted &&(<>
            {
                !myGameCenter
                &&
                <div className={classes.__power_center_card_details_enter}>
                    {userHasEntered == true ? (
                        <button style={{backgroundColor: currencyString === "PWRS" && game_type.toLowerCase().includes("fantasy") ? "#fb6e00":"", color: currencyString === "PWRS" && game_type.toLowerCase().includes("fantasy") ? "#000" :""}} >{`Entered`}</button>
                    ) : gameTargetFull === true ? (<button>{`Full`}</button>) : (
                        game_type == "PowerdFs_challenge" || game_type == "PowerdFs_promo" || ["Fantasy_Promo", "Fantasy_Challenge","Fantasy_Chase_The_Ace"].indexOf(game_type) !== -1 ? 
                        <button className={game_type === "Fantasy_Chase_The_Ace"&&classes.chaseGameButton} style={{backgroundColor: currencyString === "PWRS" && game_type.toLowerCase().includes("fantasy") ? "#fb6e00":"", color: currencyString === "PWRS" && game_type.toLowerCase().includes("fantasy") ? "#000" :""}} onClick={onEnter}>{game_type==="Fantasy_Chase_The_Ace"?"Go to Entry Page":`Enter`}</button> : <button style={{backgroundColor: currencyString === "PWRS" && game_type.toLowerCase().includes("fantasy") ? "#fb6e00":"", color: currencyString === "PWRS" && game_type.toLowerCase().includes("fantasy") ? "#000" :""}} onClick={onEnter}>{`Enter  •  `}
                        {currencyString==="PWRS" ?
                            currency && (
                            currency === "$" ? "$" :
                            <>{entry_fee}
                            <img style={{width:"auto", margin: "0 3px"}}
                                src={currency}
                                alt=""
                                height="15"
                            />
                            </>
                            ) 
                             :
                            currency && (
                                currency === "$" ? "$" :
                                <>
                                <img style={{width:"auto", margin: "0 3px"}}
                                    src={currency}
                                    alt=""
                                    height="15"
                                />{entry_fee}
                                </>
                                ) 
                         }
                        {entry_fee}
                        </button>
                    )}
                </div>
            }
            <div className={classes.__power_center_card_details_footer_next}>
                <div className={classes.__power_center_card_details_footer_next_text} onClick={onNext}>
                    Next
                </div>
                <div className={classes.__power_center_card_details_footer_forward_arrow}>
                    {">"}
                </div>
            </div>
            </>)}
        </div >
    );
};

export default Footer;