import React from 'react';
import classes from './header.module.scss';

const Header = (props) => {
    const {title = '', game_type =''} = props || {};
    return (
        <>{ game_type==="Fantasy_Chase_The_Ace"?
            <>
        <p className={classes.chaseTheAceTitleWrp}>
            <span><span>{title}</span>{" "}Chase The Ace</span>
            <p>{" "}{props?.getDateContentChase()}</p>

          </p></>:
            <div className={classes.__power_center_card_details_header}>
                <span className={classes.__power_center_card_details_header_hr + ' ' + classes.__power_center_card_details_header_hr_left}></span>
                <p className={classes.__power_center_card_details_header_title}>
                    <span className={classes.__power_center_card_details_header_title_first}>{title}</span> {
                   
                    ["NHL_Fantasy", "Fantasy_Promo","Rapid_Fantasy", "Fantasy_Challenge", "Fantasy"].indexOf(game_type) !== -1 ? "Fantasy" : "PowerdFS"}
                </p>
                <span className={classes.__power_center_card_details_header_hr + ' ' + classes.__power_center_card_details_header_hr_right}></span>
            </div>}
        </>
    );
};

export default Header;