import classes from './styles.module.scss';
import TeamManagerCard from './TeaamManagerCard';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as NHLActions from '../../actions/NHLActions';
import TeamManagerCardNFL from './TeamManagerCardNFL';
import TeamManagerCardNBA from './TeamManagerCardNBA';

const TeamManagerCardList = (props) => {
    const dispatch = useDispatch();
    const livePlayerss = useSelector(state => state.teamManager);
    const {liveMatchUpdates=[]} = useSelector(state => state.teamManager);

    // console.log("livePlayerss", livePlayerss);
    const [selectedPlayer, setSelectedPlayer] = useState({});
    const [selectedPlayerData, setSelectedPlayerData] = useState({});
    const [isDwallActive, setIsDwallActive] = useState(false);
    const [showPleaseWait, setShowPleaseWait] = useState(false);
    const {
        livePlayers:live_players = [],
        liveTeamD: live_teamD = {},
        live_clock = "20:00"
    } = livePlayerss;
    const {
        powersAvailable = []
    } = props.selectedTeam;
    
    async function useChallenge(selectedTeam, action) {
        if (action) {
          const current_match_id = selectedTeam.players[0].match_id;
          let requests = await dispatch(
            NHLActions.updateUserRemainingPowers(selectedTeam?.gameID, selectedTeam?.userID, 6, live_clock, live_teamD?.id)
          );
          if (requests.payload) {
              console.log(requests);
            //setPowers();
            props.onPowerApplied({
              fantasyTeamId: selectedTeam.team_id,
              matchId: current_match_id,
              powerId: 6,
              userId: selectedTeam?.userId,
              gameId: selectedTeam?.gameId,
            });
          } else {
            alert(
              "We are experiencing technical issues with the Power functionality. Please try again shortly."
            );
          }
        }
      }
      const findStarPlayer=(playerid)=>{
          let startData=false;
        props.fantasyPlayers.length>0 && props.fantasyPlayers?.map((item)=>{
            if(item?.id===playerid){
                 startData={
                    isStarPlayer:item?.isStarPlayer,
                    starCategory:item?.starCategory
                }
            }
        })
        return startData
      }
      const getSchedualTime=(matchId)=>{
          let schedualtime=""
          if(props?.getAllgames?.length>0){
          const data= props?.getAllgames?.filter((item)=>
            item?.id===matchId
          )
          schedualtime=data[0]?.scheduled
          }
           return schedualtime
      }
      const getOutcomes=(outcomes)=>{
        //   console.log("asdkjfbk=>",liveMatchUpdates);
        if(outcomes){
            if(outcomes?.current_inning_half==="TOP"){
                if(outcomes?.current_inning==1){
                    outcomes.top_or_bot="Top 1st"
                }
                else if(outcomes?.current_inning==2){
                    outcomes.top_or_bot="Top 2nd"
                }
                else if(outcomes?.current_inning==3){
                    outcomes.top_or_bot="Top 3rd"
                }
                else{
                    outcomes.top_or_bot=`Top ${outcomes?.current_inning}th`
                }
            }
            else if(outcomes?.current_inning_half==="BOTTOM"){
                if(outcomes?.current_inning==1){
                    outcomes.top_or_bot="Bot 1st"
                }
                else if(outcomes?.current_inning==2){
                    outcomes.top_or_bot="Bot 2nd"
                }
                else if(outcomes?.current_inning==3){
                    outcomes.top_or_bot="Bot 3rd"
                }
                else{
                    outcomes.top_or_bot=`Bot ${outcomes?.current_inning}th`
                }
            }
        }
        return outcomes
      }
    const row = (item, index) => {
        if(item !== undefined)
        {
            if(item?.type == 'td' || index == 7)
            {
                var data = {                    
                    fantasyPlayerPosition: 'TeamD',
                    full_name: item?.teamD?.name,
                    gameID: item?.gameId,
                    isFromAwayTeam: item?.isFromAwayTeam,
                    isFromHomeTeam: item?.isFromHomeTeam,
                    positionID: item?.positionID,
                    userID: item?.teamD?.userID,
                    match: {
                        away: item?.match?.away,
                        home: item?.match?.home,
                        status: item?.match?.status,
                        id: item?.teamD?.id,
                        matchId:item?.teamD?.matchId,
                        scheduled: getSchedualTime(item?.teamD?.matchId),
                        outcomes:getOutcomes(item?.match?.outcome)
                    }, 
                    id: item.id,
                    isStarPlayer: false,
                    playerId: ""
                }
            }
            else {
                var data = {
                    fantasyPlayerPosition: item?.fantasyPosition,
                    full_name: item?.full_name,
                    gameID: item?.gameId,
                    isFromAwayTeam: item?.isFromAwayTeam,
                    isFromHomeTeam: item?.isFromHomeTeam,
                    positionID: item?.position,
                    userID: item.userId,
                    match: {
                        away: item?.match?.away,
                        home: item?.match?.home,
                        status: item?.match?.status,
                        id: item?.matchId,
                        scheduled: getSchedualTime(item?.matchId),
                        outcomes:getOutcomes(item?.match?.outcome)
                    }, 
                    id: item?.id,
                    isStarPlayer: findStarPlayer(item.playerId)?.isStarPlayer,
                    isStartCatagory:findStarPlayer(item.playerId)?.starCategory,
                    playerId: item?.playerId
                }
            }
            if(props?.league==="MLB"){
                return <TeamManagerCard 
                selectedView={index == 10 ? 'fv': props.selectedView}
                key={index}
                data = {((typeof selectedPlayer?.gameId === "undefined") && index == 10) ? selectedPlayerData : data}
                powersAvailable={powersAvailable}
                useChallenge={useChallenge}
                isDwallActive={isDwallActive}
                setIsDwallActive={setIsDwallActive}
                setShowPleaseWait={setShowPleaseWait}
                showPleaseWait={showPleaseWait}
                setSinglePlayer={setSinglePlayer}
                selectedPlayer={selectedPlayer}
                singleViewRight={index == 10}
                league={props.league}
                fantasyPlayers={props.fantasyPlayers} fantasyTeam={props.fantasyTeam}
                />
            }
            else if(props?.league==="NFL"){
                return <TeamManagerCardNFL 
                selectedView={index == 10 ? 'fv': props.selectedView}
                key={index}
                data = {((typeof selectedPlayer?.gameId === "undefined") && index == 10) ? selectedPlayerData : data}
                powersAvailable={powersAvailable}
                useChallenge={useChallenge}
                isDwallActive={isDwallActive}
                setIsDwallActive={setIsDwallActive}
                setShowPleaseWait={setShowPleaseWait}
                showPleaseWait={showPleaseWait}
                setSinglePlayer={setSinglePlayer}
                selectedPlayer={selectedPlayer}
                singleViewRight={index == 10}
                league={props.league}
                fantasyPlayers={props.fantasyPlayers} fantasyTeam={props.fantasyTeam}
                />
            }
            else if(props?.league==="NBA"){
                return <TeamManagerCardNBA
                selectedView={index == 10 ? 'fv': props.selectedView}
                key={index}
                data = {((typeof selectedPlayer?.gameId === "undefined") && index == 10) ? selectedPlayerData : data}
                powersAvailable={powersAvailable}
                useChallenge={useChallenge}
                isDwallActive={isDwallActive}
                setIsDwallActive={setIsDwallActive}
                setShowPleaseWait={setShowPleaseWait}
                showPleaseWait={showPleaseWait}
                setSinglePlayer={setSinglePlayer}
                selectedPlayer={selectedPlayer}
                singleViewRight={index == 10}
                league={props.league}
                fantasyPlayers={props.fantasyPlayers} fantasyTeam={props.fantasyTeam}
                />
            }
        }
    };
    useEffect(() => {
        setSelectedPlayer(live_players[0])
    },[live_players]);
    const setSinglePlayer = (id) => {
        if(live_teamD.id === id) {
            var data = {
                fantasyPlayerPosition: 'TeamD',
                full_name: live_teamD.name,
                gameID: live_teamD.gameID,
                isFromAwayTeam: live_teamD.isFromAwayTeam,
                isFromHomeTeam: live_teamD.isFromHomeTeam,
                positionID: live_teamD.positionID,
                userID: live_teamD.userID,
                match: {
                    away: live_teamD?.match?.away,
                    home: live_teamD?.match?.home,
                    status: live_teamD?.match?.status,
                    id: live_teamD?.match?.id,
                    scheduled: live_teamD?.match?.scheduled
                }, 
                id: live_teamD.id,
                isStarPlayer: false
            }
            setSelectedPlayerData(data);
            setSelectedPlayer(live_teamD);
        }
        else {
            setSelectedPlayer(live_players.find(x => x.id === id));
        }
    };
    const nextButton = () => {
        var currentSelectedPlayer = selectedPlayer;
        var isTeamDPlayer = selectedPlayer.id === live_teamD.id;
        if(isTeamDPlayer) {
            setSelectedPlayer(live_players[0]);
        }
        else {
            var currentPlayerIndex = live_players.findIndex(x => x.id === currentSelectedPlayer.id);
            if(currentPlayerIndex == (live_players.length - 1))
            {
                setSinglePlayer(live_teamD.id);
            }
            else {
                currentPlayerIndex = currentPlayerIndex + 1;
                setSinglePlayer(live_players[currentPlayerIndex].id);
            }
        }
    };
    const prevButton = () => {
        var currentSelectedPlayer = selectedPlayer;
        var isTeamDPlayer = selectedPlayer.id === live_teamD.id;
        if(isTeamDPlayer) {
            setSelectedPlayer(live_players[live_players.length - 1]);
        }
        else {
            var currentPlayerIndex = live_players.findIndex(x => x.id === currentSelectedPlayer.id);
            if(currentPlayerIndex == 0)
            {
                setSinglePlayer(live_teamD.id);
            }
            else {
                currentPlayerIndex = currentPlayerIndex - 1;
                setSinglePlayer(live_players[currentPlayerIndex].id);
            }
        }
    };
    return (
        <div className={classes.teamManagerCardsList}>
            {props.selectedView.toLowerCase() == "s" && 
                <div className={classes.singleView}>
                    <div className={classes.singleLeftSide}>
                        <div className={classes.list}>
                            {live_players && live_players.map((item, index) => {
                                return row(item, index)
                            })}
                            {row(live_teamD, 7)}
                        </div>
                    </div>
                    <div className={classes.singleRightSide}>
                        <div className={classes.leftArrow} onClick={prevButton}></div>
                        {row(selectedPlayer, 10)}
                        <div className={classes.rightArrow} onClick={nextButton}></div>
                    </div>
                </div>
            }
            {props.selectedView.toLowerCase() !== "s" && 
                <div className={classes.list}>
                    {live_players && live_players.map((item, index) => {
                        return row(item, index)
                    })}
                    {live_teamD&&live_players?.length>0&&(
                    row(live_teamD, 7)
                    )}
                </div>
            }
        </div>
    );
};
export default TeamManagerCardList;