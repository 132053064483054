export function getApi() {
  const server = window.location.hostname;
  if (process.env.NODE_ENV === "development" || server!=="poweredfs.com") {
    return "https://api.powerplaysystems.com";
    // return "http://localhost:4000"
  }
  return "https://prod-ppgapi.powerplaysystems.com";
}

export function getApiNHL() {
  const server = window.location.hostname;
  if (process.env.NODE_ENV === "development" || server!=="poweredfs.com")  {
    return "https://nhl.powerplaysystems.com";
        // return "http://localhost:4001"

  }
  return "https://prod-nhl.powerplaysystems.com";
}

export function getNhlSocket(){
  const server = window.location.hostname;

  if(process.env.NODE_ENV === "development" || server!=="poweredfs.com"){
    return "https://ws.powerplaysystems.com/";
  }
  return "https://prod-ws.powerplaysystems.com/";
}

// export function getNewNHLSocket(){
//   if(process.env.NODE_ENV === "development"){
//     return "wss://nodeppgsockets.herokuapp.com";
//     // return "wss://playbyplay.powerplaysystems.com";
//   }
//   return "wss://nodeppgsockets.herokuapp.com";
//   // return "wss://playbyplay.powerplaysystems.com";
// }

export function getNewNHLSocket(){
  const server = window.location.hostname;
  if(process.env.NODE_ENV === "development" || server!=="poweredfs.com"){
    return "wss://playbyplay-spring.powerplaysystems.com/power-dfs";
  }
  return "wss://prod-ws.powerplaysystems.com/power-dfs";
}
export function getNewMLBSocket(){
  const server = window.location.hostname;
  if(process.env.NODE_ENV === "development" || server!=="poweredfs.com"){
    return "wss://playbyplay-spring.powerplaysystems.com/power-dfs/mlb";
  }
  return "wss://prod-ws.powerplaysystems.com/power-dfs/mlb";
}
export function getMLBApiURL() {
  const server = window.location.hostname;
  if(process.env.NODE_ENV === "development" || server!=="poweredfs.com"){
    return 'https://pce.powerplaysystems.com'
  }
  return 'https://prod-pce.powerplaysystems.com'
}