import React from 'react'

function Tick(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props?.size || "48"} height={props?.size || "48"} viewBox="0 0 48 48" {...props}>
            <g xmlns="http://www.w3.org/2000/svg" fill="none">
                <circle cx="13.835" cy="13.835" r="13.835" fill="#1BBC86"/>
                <path fill="#108C63" d="M27.67 13.835c0 7.61-6.225 13.836-13.835 13.836-4.41 0-8.3-1.989-10.809-5.188 2.335 1.902 5.362 3.026 8.648 3.026 7.609 0 13.835-6.226 13.835-13.835 0-3.286-1.124-6.313-3.027-8.647 3.2 2.507 5.189 6.398 5.189 10.808z"/>
                <path fill="#FFF" d="M21.358 9.858l-8.993 9.857c-.605.692-1.643.692-2.248 0l-3.805-4.237c-.518-.605-.518-1.556.087-2.161.605-.52 1.556-.52 2.162.086l2.68 3.026 7.87-8.56c.604-.605 1.556-.692 2.16-.087.606.52.606 1.557.087 2.076z"/>
            </g>
        </svg>
    )
}

export default Tick
