import React, { useEffect } from "react";
import * as $ from "jquery";
import { useHistory } from "react-router-dom";

import { getLocalStorage, redirectTo } from "../../utility/shared";
import { CONSTANTS } from "../../utility/constants";
import { socket } from "../../config/server_connection";

import "./userGateway.css";
import {getApiURL} from "../../config/apiURL"
import http from "../../config/http";

let REACT_APP_API_URL=getApiURL()
let _socket = null;

const UsersGateway = ({amount, paymentMethod, email}) => {
  const history = useHistory();
 
  // console.log("amount====>",amount,paymentMethod,email);
  const user_id = getLocalStorage("PERSONA_USER_ID");
  const token = getLocalStorage(CONSTANTS.LOCAL_STORAGE_KEYS.USER);

  useEffect(() => {
    _socket = socket();
    return function cleanUp() {
      _socket = null;
    };
  }, []);

  useEffect(() => {
    _socket?.on(CONSTANTS.SOCKET_EVENTS.PAYMENT.SUCCESS, (response) => {
      
    });
  }, [_socket]);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://api.myuser.com/js/checkout.js";
    // script.src="https://api.demo.convergepay.com/hosted-payments/Checkout.js"

    document.body.appendChild(script);

    setTimeout(async () => {
      $(".myuserPay-Paybutton").click();
    }, 1000);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // <script src="jquery.js"></script>
  // <script src="https://api.demo.convergepay.com/hosted-payments/Checkout.js"></script>

 
    //  var transactionToken;
    
    //  var callback = {
    //                onError: function (error) {
    //                      showResult("error", error);
    //                },
    //                onDeclined: function (response) {
    //                      showResult("declined", JSON.stringify(response));
    //                },
    //                onApproval: function (response) {
    //                      showResult("approval", JSON.stringify(response));
    //                },
    //                onCancelled: function () {
    //                      showResult("cancelled", "");
    //                }
    //      };
     
        
    //  function initiateEwallets () {
    //         var paymentData = {
    //                ssl_txn_auth_token: transactionToken
    //      };
    //         var visaCheckoutButtonOptions = {
    //                size: '154'  
    //         };
            
    //         window.ConvergeEmbeddedPayment.initVisaCheckout('visa-checkout-button', visaCheckoutButtonOptions, paymentData, callback);
    //         return false;
    //  }
  

  //    var callback = {
  //     onError: function (error) {
  //       console.log("kljasmnnnkjwmgn");

  //         console.log("error", error);
  //     },
  //     onDeclined: function (response) {
  //       console.log("kljasmnnnkjwmgn d");

  //         console.log("declined", JSON.stringify(response));
  //     },
  //     onApproval: function (response) {
  //       console.log("kljasmnnnkjwmgn a");

  //         console.log("approval", JSON.stringify(response));
  //     },
  //     onDCCDecision: function (response) {
  //         document.getElementById('ssl_conversion_rate').value = response.ssl_conversion_rate;
  //         console.log("DCC decision", JSON.stringify(response));
  //     }
  // };

  // function showResult (status, msg) {
  //     document.getElementById('txn_status').innerHTML = "<b>" + status + "</b>";
  //     document.getElementById('txn_response').innerHTML = msg;
  // }

  // const pay= ()=> {
  //   console.log("fdasffdasfdasffasdfdasfvsfdsff");
      // var token = document.getElementById('token').value;
      // var card = document.getElementById('card').value;
      // var exp = document.getElementById('exp').value;
      // var cvv = document.getElementById('cvv').value;
      // var paymentData = {
      //         ssl_txn_auth_token: token,
      //         ssl_card_number: card,
      //         ssl_exp_date: exp,
      //         ssl_cvv2cvc2: cvv
      // };
      // console.log("window==>",window);
      // window.ConvergeEmbeddedPayment.pay(paymentData, callback);
      // return false;
  // }

  
  return (
    <>
      <form
        className={"__form__"}
        action={`${REACT_APP_API_URL}/api/v1/users/account/update-myuserpay-balance`}
        method="post"
      >
        <input type="hidden" name="token" value={`Bearer ${token}`} />
        <input type="hidden" name="amount" value={amount} />
        <input type="hidden" name="user_id" value={user_id} />
        <script
          class="myuserPay-button"
          data-public_key={process.env.REACT_APP_MYUSERPAY_PUBLIC_KEY}
          data-amount={amount * 100}
          data-description="Deposit Money"
          data-name="Defy Games Demo"
          data-image="Item or your picture,logo"
          data-submit-ajax="1"
        ></script>
      </form>
       {/* <form name="myForm" method="POST" autocomplete="off" action="https://serverSdkEndpoint">
         <label for="cardNumber">Card Number</label>
         <input type="tel" id="cardNumber" name="card-number" /><br/><br/>
         <label for="cardholderName">Cardholder Name</label>
         <input type="text" id="cardholderName" name="cardholder-name" /><br/><br/>
         <p class="twoColumn">
         <label>Expiry Date</label>
         <input type="tel" id="expiryDateMM" name="expiry-date-mm" placeholder="MM" class="small" />
         <input type="tel" id="expiryDateYY" name="expiry-date-yy" placeholder="YY" class="small"  /></p>
         <p class="twoColumn">
         <label for="cvn">Security Code</label>
         <input type="tel" id="cvn" name="cvn" class="small" /></p>
         <p class="clearAll">
         <input value="Pay Now" type="submit" name="submit" onclick="validate();" /></p>
      </form> */}
   {/* <form>
             Token: <input id="token" type="hidden" name="token" value={token}/> <br/>
            Card Number: <input id="card" type="text" name="card" value="4111111111111111"/> <br/>
            Expiry Date: <input id="exp" type="text" name="exp" value="03/30"/> <br/>
            CVV2: <input id="cvv" type="text" name="cvv" value="123"/> <br/>
            <button >Pay</button>
        </form>
        <br/>
           
        Transaction Status:<div id="txn_status"></div>
        <br/>
        Transaction Response:<div id="txn_response"></div> */}
    </>
  );
};

export default UsersGateway;
