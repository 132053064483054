import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import classes from './styles.module.scss';
import Balance from "../../components/Balance";
import DocIcon from "../../icons/Doc";
import Trophy from "../../icons/Trophy";
import InfiniteEntry from "../../assets/invalid-name.svg";
import { showDepositForm } from "../../actions/uiActions";
import PrizeModal from "../../components/PrizeModal";
import ContestRulesPopUp from "../../components/ContestRulesPopUp";
import ChaseMLBheaderImage from "../../assets/MLBChaseBGTeamManager.png"
import RulesPopup from "../../components/Rules";
import { MLBPointSystem } from "./MLBpointSystem"

const TeamManagerHeader = (props) => {
    const { powerdfs_challenge_amount = 0, challenge_amount = 0 ,league="NHL", gameType=""} = props
    const { startDate, endDate, reward } = props?.selectedTeam
    const [enteredGames, setGamesEntered] = useState([]);
    const dispatch = useDispatch();
    const setShowDepositModal = () => dispatch(showDepositForm());
    const [showPrizeModal, setPrizeModalState] = useState(false);
    const [points, setPoints] = useState(0);
    const gameEnteredData = async () => {
      if(league=="NHL"){
        let res = await axios.get(
          `https://nhl.powerplaysystems.com/api/v1/services/fantasy/getPowerCenterGames?userID=${localStorage.PERSONA_USER_ID}`
        );
        if (res.data.code == 200) {
          if (JSON.stringify(enteredGames) !== JSON.stringify(res.data.Games))
            setGamesEntered(res.data.Games);
        }
      };
    };
    useEffect(() => {
        gameEnteredData();
    }, []);

    const getGameTypeText = (game_type) => {
        if (game_type === "PowerdFS") {
          return "";
        }
        else if (game_type === "PowerdFs_Recharge") {
          return "";
        }
        else if (game_type === "PowerdFs_open") {
          return "";
        }
        else if (game_type === "PowerdFs_promo" || game_type === "Fantasy_Promo") {
          if (props.isTeamSelectionPage) {
            return <><br /> Manager Challenge </>;
          }
          else {
            return "Manager Challenge";
          }
        }
        else if (game_type === "PowerdFs_challenge" || game_type === "Fantasy_Challenge") {
          if (props.isTeamSelectionPage) {
            return <><br /> {powerdfs_challenge_amount ? powerdfs_challenge_amount : challenge_amount + " "} Point Challenge </>;
          }
          else {
            return `${powerdfs_challenge_amount ? powerdfs_challenge_amount : challenge_amount} Point Challenge`;
          }
        }
        else if (game_type === "PowerdFs_Progressive") {
          if (props.isTeamSelectionPage) {
            return <><br /> Progressive Jackpot </>;
          }
          else {
            return "Progressive Jackpot";
          }
        }
        else if (game_type === "PowerdFs_One") {
          return "One";
        }
      }

    const getBackgroundImageWithStyle = () => {
      let backgroundImageStyle = {
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "inherit",
      };
      if(gameType==="Fantasy_Chase_The_Ace"){
         backgroundImageStyle.backgroundImage = `url(${ChaseMLBheaderImage})`;
      }
      else {
        backgroundImageStyle.backgroundImage = `url(/images/${props.league}BGTeamManager.png)`;
      }

      return backgroundImageStyle;
    }

    let gameDetails = {
      game_type: gameType,
      league: league,
      game_set_start: startDate,
      end_date: endDate,
      powerdfs_challenge_amount: challenge_amount,
      PointsSystems: MLBPointSystem,
      prizes: reward
    }

    return (
        <div className={classes.topHeader} style={getBackgroundImageWithStyle()}>
            <div className={classes.innerDiv}>
                <div className={classes.leagueName}>
                    {props.league}
                    <span className={classes.gameType}> {gameType==="Fantasy_Chase_The_Ace" ? "CHASE THE ACE" : ["Fantasy", "Fantasy_Challenge", "Fantasy_Promo","Rapid_Fantasy"].indexOf(props?.gameType) !== -1 ? "Fantasy" : "PowerdFS"}</span> {getGameTypeText(props?.gameType)}
                </div>
                <div className={classes.subTitle}>
                    <p>{props.subTitle}</p>
                </div>
                <div className={classes.topButtons}>
                    <ContestRulesPopUp
                    game_type={props?.gameType}
                    point={
                        typeof props?.selectedTeam?.pointSystem!== "undefined"
                        ? 
                            props?.selectedTeam?.pointSystem
                        : points
                    }
                    livePage={true}
                    isSelectedPage={false}
                    isEditPicks={false}
                    powers={
                        typeof props?.selectedTeam?.powersAvailable !== "undefined"
                        ? props?.selectedTeam?.powersAvailable
                        : []
                    }
                    component={({ showPopUp }) => (
                        <button onClick={showPopUp}>
                            <DocIcon /> Game Rules
                        </button>
                    )}
                    title={props.league}
                    />
                    <button onClick={() => setPrizeModalState(true)}>
                        <Trophy /> Prize Grid
                    </button>
                    <RulesPopup
                    component={({ showPopUp }) => (
                      <button
                        type="button"
                        onClick={showPopUp}
                      ><span>Contest Rules</span></button>
                    )}
                    gameDetails={gameDetails}
                    title="MLB"
                  />
                </div>
                
            </div>
            <div className={classes.balanceDiv}>
                    <Balance
                    entries={props.selectedTeam.enrolledUsers}
                    totalEntries={
                        enteredGames.find((x) => x?.gameID == 1382)?.targetUsers ?
                        enteredGames.find((x) => x?.gameID == 1382)?.targetUsers :
                        ""
                    }
                    livePage={true}
                    depositClicked={setShowDepositModal}
                    selectedTeam={props.selectedTeam}
                    showEntries={true}
                    />
                </div>
            <PrizeModal
                visible={showPrizeModal}
                sportsName={props.league}
                gameType={props?.gameType}
                data={props?.selectedTeam?.reward}
                chase_prize_pool={props?.selectedTeam?.chasePrizePool}
                onClose={() => setPrizeModalState(false)}
            />
        </div>
    );
};
export default TeamManagerHeader;