import React from 'react';
import classes from './prizeGrid.module.scss';
import CurrencyFormat from 'react-currency-format';
import ordinal from 'ordinal';
import PowerCurrency from '../../assets/power-orange.png';
import BtcCurrency from '../../assets/btc-blue.png';
import EthCurrency from '../../assets/ethereum-blue.png';

const PrizeGrid = (props) => {
    const {
        title = '',
        game_type = "",
        currency = "USD",
        currencyString = '',
        PrizePayout = [],
        game_set_start = '',
        start_time = '',
        showDateTime = true,
        getPWRSTitle = () => { },
        pwrsOnEnterButton = () => { }
    } = props || {};

    const data1 = [
        {
            title: '1st',
            value: '$2,000.00'
        },
        {
            title: '2nd',
            value: '$750.00'
        },
        {
            title: '3rd',
            value: '$350.00'
        },
        {
            title: '4th',
            value: '$200.00'
        },
        {
            title: '5th',
            value: '$150.00'
        },
    ];

    const data2 = [
        {
            title: '6th - 7th',
            value: '$100.00'
        },
        {
            title: '8th - 10th',
            value: '$80.00'
        },
        {
            title: '11th - 15th',
            value: '$60.00'
        },
        {
            title: '16th - 20th',
            value: '$50.00'
        },
        {
            title: '21st - 30th',
            value: '$40.00'
        },
    ];

    const getCurrency = (currency) => {
        if (currency?.toUpperCase() === 'PWRS') {
            return PowerCurrency;
        } else if (currency?.toUpperCase() === 'ETH') {
            return EthCurrency;
        } else if (currency?.toUpperCase() === 'BTC') {
            return BtcCurrency;
        }
    }

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return "st";
        }
        if (j == 2 && k != 12) {
            return "nd";
        }
        if (j == 3 && k != 13) {
            return "rd";
        }
        return "th";
    }

    return (

        <div className={classes.__prize_grid}>
            {showDateTime &&
                <div className={classes.__prize_grid_date_time}>
                    {game_set_start} | {start_time} ET
                </div>
            }
            <div className={classes.__my_game_center_card_powerdfs}>
                <p className={`text-left`} >
                    <span hidden={currencyString === "PWRS"} className={classes.__my_game_center_card_powerdfs_title_first} style={{ fontSize: '18px', color: 'white' }}>
                        {title}
                    </span>
                    <span hidden={currencyString === "PWRS"} className={classes.__my_game_center_card_powerdfs_title} style={{ fontSize: '18px' }}> {["NHL_Fantasy", "Fantasy_Promo", "Fantasy_Challenge", "Fantasy"].indexOf(game_type) !== -1 ? "Fantasy" : "PowerdFS"} </span>
                    <span className={`${classes.__my_game_center_card_powerdfs_subtitle}`} style={{ fontSize: '14px', opacity: currencyString === "PWRS" ? 1 : 0.6 }}>
                        Prize Grid
                    </span>
                </p>
            </div>
            {
                currencyString === "PWRS" &&
                getPWRSTitle()
            }
            <div className={classes.__prize_grid_data_container}>
                <div className={classes.__prize_grid_data_content}>
                    {
                        PrizePayout.map((item, index) =>
                            <>
                                {index < 5 && (
                                    <div className={classes.__prize_grid_data} key={index}>
                                        <div className={classes.__prize_grid_data_title_div}>
                                            {item?.from === item?.to ? (
                                                <p className={classes.__prize_grid_data_title}>
                                                    {
                                                        <>{(parseInt(item?.from))}<sup>{ordinal_suffix_of(parseInt(item?.from))}</sup></>
                                                    }
                                                </p>
                                            ) : (
                                                <p className={classes.__prize_grid_data_title}>
                                                    {
                                                        <>{(parseInt(item?.from))}<sup>{ordinal_suffix_of(parseInt(item?.from))}</sup></>
                                                    } -
                                                    {
                                                        <>{(parseInt(item?.to))}<sup>{ordinal_suffix_of(parseInt(item?.to))}</sup></>
                                                    }
                                                </p>
                                            )}
                                        </div>
                                        <div className={classes.__prize_grid_data_value_div}>
                                            <p className={classes.__prize_grid_data_value} style={{ display: "flex", color: currencyString === "PWRS" ? "#fb6e00" : "" }}>
                                                {currency === 'USD' ? "$" : (
                                                    <img
                                                        style={{ marginRight: 4, width: "auto" }}
                                                        src={getCurrency(currency)}
                                                        height="16px"
                                                        alt=""
                                                    />
                                                )}
                                                <CurrencyFormat value={item?.amount} displayType={'text'} thousandSeparator={true}
                                                    //prefix={'$'} 
                                                    renderText={value => <div>{value}.00</div>} />
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </>
                        )
                    }
                </div>
                <div className={classes.__prize_grid_data_content}>
                    {PrizePayout.map((item, index) =>
                        <>
                            {index >= 5 && (
                                <div className={classes.__prize_grid_data} key={index}>
                                    <div className={classes.__prize_grid_data_title_div}>
                                        {item?.from === item?.to ? (
                                            <p className={classes.__prize_grid_data_title}>
                                                {ordinal(parseInt(item?.from))}
                                            </p>
                                        ) : (
                                            <p className={classes.__prize_grid_data_title}>
                                                {ordinal(parseInt(item?.from))} - {ordinal(parseInt(item?.to))}
                                            </p>
                                        )}
                                    </div>
                                    <div className={classes.__prize_grid_data_value_div}>
                                        <p className={classes.__prize_grid_data_value}>{item?.amount}</p>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
                {
                    currencyString === "PWRS" &&
                    <div style={{ width: "327px", position:"absolute", bottom:"43px", left:"20px" }}>
                        {pwrsOnEnterButton()}
                    </div>
                }

        </div>
    );
};

export default PrizeGrid;