import React from 'react';
import classes from './aceSlide.module.scss';
import OrangeBtcCurrency from "../../assets/btc-orange.png";
import OrangeEthCurrency from "../../assets/ethereum-orange.png";
import PWRSIcon from '../../assets/power.png';
import Button from "../Button";
import AceCenterLogo from '../../assets/AceCenterLogo.png';
import leftImage from '../../assets/group-45@3x.png';
const AceSlide = (props) => {
  const {
    game_type= "",
    title= "",
    game_set_start="",
    start_time="",
    userHasEntered="",
    currency="",
    total="",
    totalEntries="",
    paid_game="",
    entry_fee="",
    onEnter= () => {}
  } = props;
  const getEnterCurrency = (currency) => {
    if (currency.toUpperCase() === "PWRS") {
      return PWRSIcon;
    } else if (currency.toUpperCase() === "BTC") {
      return OrangeBtcCurrency;
    } else if (currency.toUpperCase() === "ETH") {
      return OrangeEthCurrency;
    } else if (currency.toUpperCase() === "USD") {
      return "$"
    }
  };
  const aceButtonStyle = {
    width: 0,
    margin: "29px auto 0px auto",
    padding: "8px 131px 7px 132px",
    borderRadius: 6,
    backgroundColor: "#fb6e00",
    minWidth: 'auto',
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color:'#111',
  };
  return (
    <div className={classes.prizeGridChasewrp}>
        <div className={classes.__point_system_date_time}>
            {game_set_start} | {start_time} ET
        </div>
        <img src={AceCenterLogo} className={classes.AceCenterLogo}/>
        <img src={leftImage} className={classes.leftImage} />
        <div className={classes.__prize_grid_scroll_bar}>
            <p><span>Finish</span> in 1st place.</p> 
            <p><span>Pick</span> the Ace of Spades.</p> 
            <p><span>Win</span> the prize pool!</p>
        </div>
        <div className={classes.__power_center_card_enter}>
            {userHasEntered ? (
                    <>
                      <Button title={`Entered`} />
                    </>
                  ) : total && Number(total) === Number(totalEntries) ?
                    <Button title={`Full`} /> :
                    paid_game || paid_game === null ? (
                      <Button
                        currency={getEnterCurrency(currency)}
                        title={`Enter`}
                        entry_fee={entry_fee}
                        onClick={onEnter}
                        styles={aceButtonStyle}
                      />
                    ) : (
                      <Button title={`Enter  •  Free`} onClick={onEnter} />
                    )}
        </div>
    </div>
  );
};
export default AceSlide;