import React from 'react';

const Billards = props => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} width="25" height="25" viewBox="0 0 25 25">
        <g fill="currentColor" fillRule="nonzero">
            <path d="M22.35 4.65c-4.88-4.879-12.82-4.879-17.7 0C2.604 6.7 1.323 9.412 1.045 12.29c-.275 2.839.432 5.707 1.99 8.077.228.346.692.442 1.038.214.346-.227.442-.692.215-1.037C1.435 15.205 2.034 9.387 5.71 5.71c4.295-4.296 11.283-4.296 15.578 0 4.296 4.295 4.296 11.283 0 15.578-3.723 3.724-9.588 4.292-13.945 1.35-.343-.233-.808-.142-1.04.2-.232.344-.142.81.201 1.041 2.122 1.433 4.557 2.133 6.981 2.133 3.233 0 6.446-1.246 8.863-3.664 2.364-2.363 3.666-5.506 3.666-8.849s-1.302-6.486-3.666-8.85z" transform="translate(-537 -1477) translate(296 1466) translate(216) translate(24 10)" />
            <path d="M5.716 21.294l-.005-.005c-.293-.292-.765-.29-1.058.003-.292.293-.29.77.003 1.062.146.147.338.22.53.22s.383-.073.53-.22c.292-.292.292-.767 0-1.06zM12.216 14.918V19H10V8h4.04c1.31 0 2.297.312 2.962.935s.998 1.47.998 2.54c0 1.049-.343 1.885-1.03 2.508-.686.623-1.663.935-2.93.935h-1.824zM13.796 13c1.47 0 2.204-.498 2.204-1.493 0-.489-.172-.862-.516-1.12-.343-.258-.906-.387-1.688-.387H12v3h1.796z" transform="translate(-537 -1477) translate(296 1466) translate(216) translate(24 10)" />
            <path d="M13.5 3.833c-5.33 0-9.667 4.337-9.667 9.667s4.337 9.667 9.667 9.667 9.667-4.337 9.667-9.667S18.83 3.833 13.5 3.833zm0 17.835c-4.504 0-8.168-3.664-8.168-8.168S8.996 5.332 13.5 5.332s8.168 3.664 8.168 8.168-3.664 8.168-8.168 8.168z" transform="translate(-537 -1477) translate(296 1466) translate(216) translate(24 10)" />
        </g>
    </svg>
)

export default Billards;