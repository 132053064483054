import React, {useState, useEffect} from "react";
import classes from "./historyInfoComponent.module.scss";
import moment from "moment";
import moment1 from "moment-timezone";


import TickIcon from "../../assets/icons/correct-copy.png";

const HistoryInfoComponent = (props) => {
  let { isMobile = false, transactions = [] } = props || {};
  //transactions = transactions.filter(el => el?.type === "GameEntry" || el?.type === "GameEnded");
  const getLiveStandingsButton =  (game_id) => {
    props.getLiveStandings(game_id);
  };

  const getNHLLiveStandings =  (gameID) => {
    props.getNHLLiveStandings(gameID);
  };
  const getMLBLiveStandings=(gameID)=>{
    props.getMLBLiveStandings(gameID);

  }
  const TableRow = (props) => {
    const { transaction = {}, isMobile = false } = props || {};

    const getDate = (timestamp) => {
      const offset = moment1?.tz("America/New_York")?.format("Z");
      return moment.utc(timestamp, 'YYYY-MM-DD hh:mm A').utcOffset('-07:00').format("MMM D");
    };

    const getTime = (timestamp) => {
      const offset = moment1?.tz("America/New_York")?.format("Z");
      return moment.utc(timestamp, 'YYYY-MM-DD hh:mm A').utcOffset('-07:00').format("hh:mm A");
    };
    return (
      <>
        {isMobile ? (
          <>
            <div className={classes.row_mobile}>
              <div className={classes.row_top}>
                <div>
                  <div className={classes.row_top_details}>
                    Game ID: {transaction?.gameID || "--"}
                  </div>
                  <div className={classes.row_top_details}>
                      {transaction.GameEnteredAt && getDate(transaction.GameEnteredAt)}{" "}
                      {transaction.GameEnteredAt && getTime(transaction.GameEnteredAt)}{" "}
                  </div>
                  <div className={classes.row_game_type}>{transaction.league} </div>
                  <div className={classes.row_game_type}>{transaction?.gameType || "--"} </div>
                </div>
                <button 
                  hidden={transaction?.gameStatus?.toLowerCase() === "cancelled"}
                  onClick={() => {
                  transaction.league == "NHL" && getNHLLiveStandings(transaction?.gameID);
                  transaction.league == "MLB" && getMLBLiveStandings(transaction?.gameID);

                }}>Results</button>
              </div>

              <div className={classes.row_bottom}>
                <div className={classes.row_bottom_details}>
                  <span>Currency</span>
                  <p>{transaction?.currency?.toUpperCase()}</p>
                </div>

                <div className={classes.row_bottom_details}>
                  <span>Amount</span>
                  <p>{transaction.transaction_amount > 0 ? transaction.transaction_amount : "Free"}</p>
                </div>

                <div className={classes.row_bottom_details}>
                  <span>Status</span>
                  <p>{transaction?.gameStatus?.charAt(0).toUpperCase() + transaction?.gameStatus?.slice(1)}</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={classes.row}>
              <div className="mx-1 text-left text-ellipsis">{transaction.GameEnteredAt && getDate(transaction.GameEnteredAt)} </div>
              <div className="mx-1 text-left text-ellipsis">{transaction.GameEnteredAt && getTime(transaction.GameEnteredAt)} </div>
              <div className="mx-1 text-left text-ellipsis">{transaction?.league} </div>
              <div className="mx-1 text-left text-ellipsis">{transaction?.gameType || "--"} </div>
              <div className="mx-1 text-left text-ellipsis">{transaction?.gameID || "--"} </div>
              <div className="mx-1 text-left text-ellipsis">{transaction?.currency?.toUpperCase()}</div>
              <div className="mx-1 text-left text-ellipsis">
                {/* {transaction?.transaction_amount || transaction.transaction_amount === 0 ? '' : transaction.balance_result === 'increase' ? ` + ` : ' - '} */}
                {transaction.transaction_amount > 0 ? transaction.transaction_amount : "Free"}
              </div>
              <div className="mx-1 text-left text-ellipsis">{transaction?.gameStatus?.charAt(0).toUpperCase() + transaction?.gameStatus?.slice(1)}</div>
              <div className="mx-1 text-left text-ellipsis">
                <button
                type="button" hidden={transaction?.gameStatus?.toLowerCase() === "cancelled"}
                className={classes.viewButton}
                onClick={() => {
                  transaction.league == "NHL" && getNHLLiveStandings(transaction?.gameID);
                  transaction.league == "MLB" && getMLBLiveStandings(transaction?.gameID);
                }}
                >View</button>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <div className={`${classes.table_wrapper} w-100`} style={{ transform: 'none' }}>
      <div className={`${classes.table_header} w-100`}>
        <div className={classes.row}>
          <div className="mx-1 text-left">Date</div>
          <div className="mx-1 text-left">Time</div>
          <div className="mx-1 text-left">League</div>
          <div className="mx-1 text-left">Type</div>
          <div className="mx-1 text-left">Game Id</div>
          <div className="mx-1 text-left">Currency</div>
          <div className="mx-1 text-left">Amount</div>
          <div className="mx-1 text-left">Status</div>
          <div className="mx-1 text-left">Results</div>
        </div>
      </div>

      <div className={`${classes.table_body} w-100`}>
        {transactions.map(function (row, index) {
          return <TableRow transaction={row} isMobile={isMobile} />;
        })}
      </div>
    </div>
  );
};

export default HistoryInfoComponent;
