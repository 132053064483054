import React from "react";
import PropTypes from "prop-types";

function RechargeAll(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.size || "54"}
      height={props?.size + 2 || "56"}
      viewBox="0 0 54 56"
      style={{ width: "auto", height: "auto" }}
    >
      <defs>
        <linearGradient
          id="di5ngvhmqa"
          x1="35.019%"
          x2="67.721%"
          y1="39.758%"
          y2="84.553%"
        >
          <stop offset="0%" stopColor="#688FBD" />
          <stop offset="100%" stopColor="#30598A" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g transform="translate(-1298 -1118) translate(1083) translate(34 727) translate(22 29) translate(126 362) translate(33)">
                      <circle
                        cx="26.507"
                        cy="26.507"
                        r="26.507"
                        fill="url(#di5ngvhmqa)"
                      />
                      <path
                        fill="#F2F2F2"
                        fillRule="nonzero"
                        d="M33.12 24.349h-5.78l-1.409 1.439.577-1.44 4.332-10.79-11.66 13.555c-.239.278-.294.67-.14 1.004.154.334.488.548.856.549h5.781l1.409-1.44-.768 1.914-4.141 10.316 11.66-13.558c.237-.279.29-.67.137-1.002-.154-.332-.486-.546-.853-.547z"
                      />
                    </g>
                    <g fillRule="nonzero">
                      <g>
                        <path
                          fill="#FFF"
                          d="M9.026 5.286V1.152c0-.31-.353-.488-.599-.302L.15 7.107c-.197.148-.2.445-.007.598 8.838 7.014 8.25 6.592 8.436 6.592.205 0 .375-.167.375-.377V9.59l.072-4.304z"
                          transform="translate(-1298 -1118) translate(1083) translate(34 727) translate(22 29) translate(126 362) translate(33) rotate(3 -355.973 46.273) rotate(-90 5.728 5.24)"
                        />
                        <path
                          fill="#ECECEC"
                          d="M8.58 12.209c-.186 0 .36.39-7.472-5.826l-.958.724c-.197.148-.2.445-.007.598l8.205 6.511c.244.195.606.019.606-.296v-2.088c0 .21-.17.377-.375.377z"
                          transform="translate(-1298 -1118) translate(1083) translate(34 727) translate(22 29) translate(126 362) translate(33) rotate(3 -355.973 46.273) rotate(-90 5.728 5.24)"
                        />
                      </g>
                    </g>
                    <g fillRule="nonzero">
                      <g>
                        <path
                          fill="#FFF"
                          d="M9.026 5.286V1.152c0-.31-.353-.488-.599-.302L.15 7.107c-.197.148-.2.445-.007.598 8.838 7.014 8.25 6.592 8.436 6.592.205 0 .375-.167.375-.377V9.59l.072-4.304z"
                          transform="translate(-1298 -1118) translate(1083) translate(34 727) translate(22 29) translate(126 362) translate(33) rotate(-177 26.055 16.898) rotate(-90 5.728 5.24)"
                        />
                        <path
                          fill="#ECECEC"
                          d="M8.58 12.209c-.186 0 .36.39-7.472-5.826l-.958.724c-.197.148-.2.445-.007.598l8.205 6.511c.244.195.606.019.606-.296v-2.088c0 .21-.17.377-.375.377z"
                          transform="translate(-1298 -1118) translate(1083) translate(34 727) translate(22 29) translate(126 362) translate(33) rotate(-177 26.055 16.898) rotate(-90 5.728 5.24)"
                        />
                      </g>
                    </g>
                    <path
                      fill="#FFF"
                      fillRule="nonzero"
                      d="M44.635 45.432c.032.828-.614 1.524-1.442 1.555-.828.031-1.524-.615-1.555-1.443-.135-3.574-1.406-7.014-3.706-9.854-5.796-7.158-16.298-8.262-23.456-2.466-.644.522-1.588.422-2.11-.222-.52-.643-.422-1.588.222-2.11 8.446-6.838 20.837-5.536 27.676 2.91 2.712 3.349 4.213 7.412 4.371 11.63z"
                      transform="translate(-1298 -1118) translate(1083) translate(34 727) translate(22 29) translate(126 362) translate(33) rotate(147 28.334 36.748)"
                    />
                    <path
                      fill="#FFF"
                      fillRule="nonzero"
                      d="M33.263 31.79c-.497.663-1.437.797-2.1.3-.663-.496-.798-1.437-.301-2.1 2.145-2.862 3.322-6.335 3.322-9.99 0-9.21-7.466-16.677-16.677-16.677-.828 0-1.5-.672-1.5-1.5 0-.829.672-1.5 1.5-1.5C28.374.323 37.184 9.133 37.184 20c0 4.31-1.39 8.412-3.921 11.79z"
                      transform="translate(-1298 -1118) translate(1083) translate(34 727) translate(22 29) translate(126 362) translate(33) rotate(-73 26.596 16.356)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

RechargeAll.propTypes = {
  size: PropTypes.number,
};

export default RechargeAll;
