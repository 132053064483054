import React from 'react';

const Calendar2 = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props?.size || "14"} height={props?.size || "14"} viewBox="0 0 14 14" {...props}>
        <g fill={props?.color} fillOpacity=".6" fillRule="evenodd">
        <g fill="#FFF" fillRule="nonzero">
            <g>
                <g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path d="M4.81 7.71c0-.175-.138-.317-.309-.317H3.425c-.17 0-.308.142-.308.317v1.107c0 .175.138.317.308.317h1.076c.17 0 .309-.142.309-.317V7.71zM7.498 7.71c0-.175-.138-.317-.308-.317H6.114c-.17 0-.308.142-.308.317v1.107c0 .175.138.317.308.317H7.19c.17 0 .308-.142.308-.317V7.71zM10.187 7.71c0-.175-.138-.317-.308-.317H8.803c-.17 0-.308.142-.308.317v1.107c0 .175.138.317.308.317h1.076c.17 0 .308-.142.308-.317V7.71zM4.81 10.477c0-.175-.138-.317-.309-.317H3.425c-.17 0-.308.142-.308.317v1.107c0 .175.138.317.308.317h1.076c.17 0 .309-.142.309-.317v-1.107zM7.498 10.477c0-.175-.138-.317-.308-.317H6.114c-.17 0-.308.142-.308.317v1.107c0 .175.138.317.308.317H7.19c.17 0 .308-.142.308-.317v-1.107zM10.187 10.477c0-.175-.138-.317-.308-.317H8.803c-.17 0-.308.142-.308.317v1.107c0 .175.138.317.308.317h1.076c.17 0 .308-.142.308-.317v-1.107z" transform="translate(-290 -680) translate(110 446) translate(0 45) translate(30 22) translate(27 164) translate(123 1) translate(0 2)"/>
                                    <path d="M12.11 1.556v1.69c0 .765-.602 1.38-1.344 1.38h-.849c-.742 0-1.353-.615-1.353-1.38V1.55H4.74v1.697c0 .764-.61 1.38-1.353 1.38H2.54c-.743 0-1.345-.616-1.345-1.38v-1.69c-.65.02-1.183.572-1.183 1.25v9.909c0 .692.545 1.26 1.218 1.26h10.846c.672 0 1.218-.57 1.218-1.26V2.808c0-.68-.533-1.232-1.182-1.252zM11.718 12.1c0 .299-.236.541-.527.541h-9.1c-.29 0-.525-.242-.525-.541V6.98c0-.3.235-.542.526-.542h9.1c.29 0 .526.243.526.542v5.118z" transform="translate(-290 -680) translate(110 446) translate(0 45) translate(30 22) translate(27 164) translate(123 1) translate(0 2)"/>
                                    <path d="M2.536 3.72h.839c.255 0 .461-.212.461-.474V.474C3.836.212 3.63 0 3.375 0h-.84c-.254 0-.46.212-.46.474v2.772c0 .262.206.474.46.474zM9.906 3.72h.84c.254 0 .46-.212.46-.474V.474C11.207.212 11 0 10.747 0h-.84c-.254 0-.46.212-.46.474v2.772c0 .262.206.474.46.474z" transform="translate(-290 -680) translate(110 446) translate(0 45) translate(30 22) translate(27 164) translate(123 1) translate(0 2)"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default Calendar2;