import React from 'react';
import classes from './hitters.module.scss';
import OrangeBtcCurrency from "../../assets/btc-orange.png";
import OrangeEthCurrency from "../../assets/ethereum-orange.png";
import PWRSIcon from '../../assets/power.png';
import Button from "../Button";
import AceCenterLogo from '../../assets/AceCenterLogo.png';
import _ from 'underscore';
const HittersAce = (props) => {
    const {
        game_set_start="",
        start_time="",
        userHasEntered="",
        total="",
        totalEntries="",
        paid_game="",
        entry_fee="",
        onEnter= () => {},
        PointsSystem,
        currency=""
      } = props;
      console.log("props", props);
      const getEnterCurrency = (currency) => {
        if (currency.toUpperCase() === "PWRS") {
          return PWRSIcon;
        } else if (currency.toUpperCase() === "BTC") {
          return OrangeBtcCurrency;
        } else if (currency.toUpperCase() === "ETH") {
          return OrangeEthCurrency;
        } else if (currency.toUpperCase() === "USD") {
          return "$"
        }
      };
      const aceButtonStyle = {
        width: 0,
        margin: "20px auto 0px auto",
        padding: "8px 131px 7px 132px",
        borderRadius: 6,
        backgroundColor: "#fb6e00",
        minWidth: 'auto',
        fontFamily: "Poppins",
        fontSize: 16,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color:'#111',
      };
    const groupedPoints = _.groupBy(PointsSystem, 'type');
    return (
        <div className={classes.__hitters}>
            <img src={AceCenterLogo} className={classes.AceCenterLogo}/>
            <p className={classes.topTitle}>Point System</p>
            <p className={classes.headerTitle}>HITTERS</p>
            <div className={classes.__prize_grid_data_container}>
                <div className={classes.__prize_grid_data_content}>
                    {
                        groupedPoints['Hitter'].map((d, i) => {
                            if(i<5)
                            return (
                                <div className={classes.__hitters_data}>
                                <div className={classes.__hitters_data_title_div} style={{
                                    textAlign: "left"
                                }}>
                                    <p className={classes.__hitters_data_title}>{d.plays}</p>
                                </div>
                                <div className={classes.__hitters_data_value_div}>
                                    <p className={classes.__hitters_data_value} style={{
                                        color: "#fb6e00", fontWeight: "bold !important"
                                    }}>{d.action}{d.points} Pts</p>
                                </div>
                            </div>
                            );
                        })
                    }
                </div>
                <div className={classes.__prize_grid_data_content}>
                    {
                        groupedPoints['Hitter'].map((d, i) => {
                            if(i>4)
                            return (
                                <div className={classes.__hitters_data}>
                                <div className={classes.__hitters_data_title_div} style={{
                                    textAlign: "left"
                                }}>
                                    <p className={classes.__hitters_data_title}>{d.plays}</p>
                                </div>
                                <div className={classes.__hitters_data_value_div}>
                                    <p className={classes.__hitters_data_value} style={{
                                        color: "#fb6e00", fontWeight: "bold !important"
                                    }}>{d.action}{d.points} Pts</p>
                                </div>
                            </div>
                            );
                        })
                    }
                </div>
                
            </div>
            <div className={classes.__power_center_card_enter}>
                {userHasEntered ? (
                        <>
                        <Button title={`Entered`} />
                        </>
                    ) : total && Number(total) === Number(totalEntries) ?
                        <Button title={`Full`} /> :
                        paid_game || paid_game === null ? (
                        <Button
                            currency={getEnterCurrency(currency)}
                            title={`Enter`}
                            entry_fee={entry_fee}
                            onClick={onEnter}
                            styles={aceButtonStyle}
                        />
                        ) : (
                        <Button title={`Enter  •  Free`} onClick={onEnter} />
                        )}
            </div>
        </div>
    );
};

export default HittersAce;