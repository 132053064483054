import React from "react";
import PropTypes from "prop-types";

function Battery1_5x(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="30"
      viewBox="0 0 68 30"
      style={{ width: "auto", height: "auto" }}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill="#4F6773"
              fillRule="nonzero"
              d="M54.542 30H7.792C3.496 30 0 27.534 0 24.5v-19C0 2.466 3.496 0 7.792 0h46.75c4.295 0 7.791 2.466 7.791 5.5V6h.683c2.734 0 4.959 1.57 4.959 3.5v11c0 1.93-2.225 3.5-4.959 3.5h-.683v.5c0 3.034-3.496 5.5-7.791 5.5z"
              transform="translate(-20.000000, -272.000000) translate(54.000000, 287.000000) scale(1, -1) translate(-54.000000, -287.000000) translate(20.000000, 272.000000)"
            />
            <path
              fill="#202124"
              fillRule="nonzero"
              d="M6.569 3c-1.995 0-3.62 1.122-3.62 2.5v19c0 1.378 1.625 2.5 3.62 2.5H54.34c1.995 0 3.619-1.122 3.619-2.5v-2c0-.828.973-1.5 2.171-1.5h2.87c.4 0 .724-.224.724-.5v-11c0-.276-.325-.5-.724-.5h-2.87c-1.198 0-2.171-.672-2.171-1.5v-2c0-1.378-1.624-2.5-3.62-2.5H6.57z"
              transform="translate(-20.000000, -272.000000) translate(54.000000, 287.000000) scale(1, -1) translate(-54.000000, -287.000000) translate(20.000000, 272.000000)"
            />
            <path
              fill="#658391"
              fillRule="nonzero"
              d="M67.975 15h-4.25v5.5c0 .276-.318.5-.709.5h-2.808c-1.173 0-2.125.672-2.125 1.5v2c0 1.378-1.59 2.5-3.541 2.5H7.792C5.84 27 2.95 25.878 2.95 24.5V15H0v9.5C0 27.534 3.496 30 7.792 30h46.75c4.295 0 7.791-2.466 7.791-5.5V24h.683c2.734 0 4.959-1.57 4.959-3.5V15z"
              transform="translate(-20.000000, -272.000000) translate(54.000000, 287.000000) scale(1, -1) translate(-54.000000, -287.000000) translate(20.000000, 272.000000)"
            />
            <path
              fill="#DADADA"
              fillOpacity=".1"
              fillRule="nonzero"
              d="M63.724 15H2.95v9.5c0 1.378 1.624 2.5 3.619 2.5H54.34c1.995 0 3.619-1.122 3.619-2.5v-2c0-.828.973-1.5 2.171-1.5h2.87c.4 0 .724-.224.724-.5V15z"
              transform="translate(-20.000000, -272.000000) translate(54.000000, 287.000000) scale(1, -1) translate(-54.000000, -287.000000) translate(20.000000, 272.000000)"
            />
            <g>
              <path
                fill="#F44336"
                d="M1.459 0H4.74C5.547 0 6.2.79 6.2 1.765v14.47C6.2 17.21 5.547 18 4.741 18H1.46C.653 18 0 17.21 0 16.235V1.765C0 .79.653 0 1.459 0z"
                transform="translate(-20.000000, -272.000000) translate(54.000000, 287.000000) scale(1, -1) translate(-54.000000, -287.000000) translate(20.000000, 272.000000) translate(5.950000, 6.000000)"
              />
              <path
                fill="#D43A2F"
                d="M1.459 0H4.74C5.547 0 6.2.79 6.2 1.765v7.222H0V1.765C0 .79.653 0 1.459 0z"
                transform="translate(-20.000000, -272.000000) translate(54.000000, 287.000000) scale(1, -1) translate(-54.000000, -287.000000) translate(20.000000, 272.000000) translate(5.950000, 6.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

Battery1_5x.propTypes = {};

export default Battery1_5x;
