import { io } from "socket.io-client";
import { getApi, getNhlSocket ,getNewNHLSocket, getApiNHL,getNewMLBSocket} from "./environment";

export function socket() {
  const _socket = io(getApi(), {
    transports: ["websocket"],
    reconnection: true,
    upgrade: false,
  });
  return _socket;
}

export function socketNHL() {
  // console.log('getApiNHL()', getApiNHL())
  // const _socket = io(getApiNHL(), {
  //   transports: ["websocket"],
  //   reconnection: true,
  //   upgrade: false,
  // });
  // return _socket;
}

// console.log('socketNHL', socketNHL())
export async function newNHL(){
  const url = getNewNHLSocket();
  const webSocket = new WebSocket(url);
  return webSocket;
}
export async function newMLB(){
  const url = getNewMLBSocket();
  const webSocket = new WebSocket(url);
  return webSocket;
}
