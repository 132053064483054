import React from 'react';

import CurrencyFormat from 'react-currency-format';
import classes from './prizeGrid.module.scss';
import ordinal from 'ordinal';
import MLBPlayer from "../../assets/mlb-player.png";
import PowerCurrency from '../../assets/power-orange.png';
import BtcCurrency from '../../assets/btc-blue.png';
import EthCurrency from '../../assets/ethereum-blue.png';

const PrizeGrid = (props) => {
  const {
    PrizePayout,
    currency="USD",
    currencyString="",
    game_type=""
  } = props;

  const data = [
    {
      title: '1st',
      value: '$2,000.00'
    },
    {
      title: '2nd',
      value: '$750.00'
    },
    {
      title: '3rd',
      value: '$350.00'
    },
    {
      title: '4th',
      value: '$200.00'
    },
    {
      title: '5th',
      value: '$150.00'
    },
    {
      title: '6th - 7th',
      value: '$100.00'
    },
    {
      title: '8th - 10th',
      value: '$80.00'
    },
    {
      title: '11th - 15th',
      value: '$60.00'
    },
    {
      title: '16th - 20th',
      value: '$50.00'
    },
    {
      title: '21st - 30th',
      value: '$40.00'
    },
  ];

  const getCurrency = (currency) => {
    if (currency?.toUpperCase() === 'PWRS') {
      return PowerCurrency;
    } else if (currency?.toUpperCase() === 'ETH') {
      return EthCurrency;
    } else if (currency?.toUpperCase() === 'BTC') {
      return BtcCurrency;
    }
  }

  function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return "st";
    }
    if (j == 2 && k != 12) {
        return "nd";
    }
    if (j == 3 && k != 13) {
        return "rd";
    }
    return "th";
  }

  return (
    <div className={`${classes.__prize_grid}`}>
      <p className={currencyString==="PWRS" ? classes.__prize_grid_title_pwrs : classes.__prize_grid_title}>Prize Grid</p>
      <div className={classes.__prize_grid_scroll_bar}>
        {
          PrizePayout?.sort((a, b) => a.from - b.from)?.map((item, index) => {
            return (
              <div className={classes.__prize_grid_data}>
                <div className={classes.__prize_grid_data_title_div}>
                  {item?.from === item?.to ? (
                    <p className={classes.__prize_grid_data_title}>
                      {
                        <>{(parseInt(item?.from))}<sup>{ordinal_suffix_of(parseInt(item?.from))}</sup></>
                      }
                    </p>
                  ) : (
                    <p className={classes.__prize_grid_data_title}>
                      {
                        <>{(parseInt(item?.from))}<sup>{ordinal_suffix_of(parseInt(item?.from))}</sup></>
                      } - 
                      {
                        <>{(parseInt(item?.to))}<sup>{ordinal_suffix_of(parseInt(item?.to))}</sup></>
                      }
                    </p>
                  )}
                </div>
                <div className={classes.__prize_grid_data_value_div}>

                  <p className={classes.__prize_grid_data_value} style={{display:"flex", color: currencyString==="PWRS" ? "#fb6e00": ""}}>
                    {currency === 'USD' ? "$" : (
                      <img
                        style={{ marginRight: 4 }}
                        src={getCurrency(currency)}
                        height="16px"
                        alt=""
                      />
                    )}
                    <CurrencyFormat value={item?.amount} displayType={'text'} thousandSeparator={true} 
                      //prefix={currency === "BTC" ? '₿' : currency === "ETH" ? 'Ξ' : '$'} 
                      renderText={value => <div>{value}.00</div>} />
                  </p>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default PrizeGrid;
