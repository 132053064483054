import React from "react";
import classes from "./contest.module.scss";
import correctcopy from "../../assets/correct-copy-2.svg";
import ContestRulesPopUp from "../ContestRulesPopUp";
import PowerCurrency from '../../assets/power-orange.png';
import BtcCurrency from '../../assets/btc-blue.png';
import EthCurrency from '../../assets/ethereum-blue.png';
import _ from 'underscore';
import AceCenterLogo from '../../assets/AceCenterLogo.png';
import Button from "../Button";
import OrangeBtcCurrency from "../../assets/btc-orange.png";
import OrangeEthCurrency from "../../assets/ethereum-orange.png";
import PWRSIcon from '../../assets/power.png';
const data = [
  {
    heading: "The 8 roster positions:",
    teamRoster: [
      {
        count: 1,
        title: "P",
        value: "(Pitcher)",
      },
      {
        count: 1,
        title: "C",
        value: "(Catcher)",
      },
      {
        count: 1,
        title: "SS",
        value: "(Shortstop)",
      },
      {
        count: 2,
        title: "xB",
        value: "(1B, 2B or 3B)",
      },
      {
        count: 2,
        title: "OF",
        value: "(Outfielders)",
      },
      {
        count: 1,
        title: "Team Defense",
        value: "",
      },
    ],
  },
];

const getCurrency = (currency) => {
  if (currency?.toUpperCase() === 'PWRS') {
    return PowerCurrency;
  } else if (currency?.toUpperCase() === 'ETH') {
    return EthCurrency;
  } else if (currency?.toUpperCase() === 'BTC') {
    return BtcCurrency;
  }
}

const ContestRulesAceMobile = (props) => {
  const { title = "", game_type = "", isMobile = false, currency, currencyString, getPWRSTitle = () => { }, pwrsOnEnterButton = () => { } } = props || {};
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const d = new Date(props.game_set_start);
  const groupedPoints = _.groupBy(props.points, 'type');
  const point = props?.points
  const {
    game_set_start="",
    start_time="",
    userHasEntered="",
    total="",
    totalEntries="",
    paid_game="",
    entry_fee="",
    onEnter= () => {}
  } = props;
  const getEnterCurrency = (currency) => {
    if (currency.toUpperCase() === "PWRS") {
      return PWRSIcon;
    } else if (currency.toUpperCase() === "BTC") {
      return OrangeBtcCurrency;
    } else if (currency.toUpperCase() === "ETH") {
      return OrangeEthCurrency;
    } else if (currency.toUpperCase() === "USD") {
      return "$"
    }
  };
  const aceButtonStyle = {
    width: 0,
    margin: "10px auto 0px auto",
    padding: "8px 131px 7px 132px",
    borderRadius: 6,
    backgroundColor: "#fb6e00",
    minWidth: 'auto',
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color:'#111',
  };
  return (
    <div className={`
      ${props.isMobileGameCenter ? classes.__contest_rules_gamecenter : classes.__contest_rules
      }
    `}>
      <img src={AceCenterLogo} className={classes.AceCenterLogo}/>
      <p className={classes.topTitle}>Contest Rules</p>
      <ul className={classes.pwrs}>
        <li>
          <span style={{ color: "#fb6e00" }}>
            {/* {currency === "BTC" ? '₿' : currency === "ETH" ? 'Ξ' : '$'} */}
            {currency ? currency === 'USD' ? "$" : (
              <img
                style={{ marginRight: 4, width: "auto" }}
                src={getCurrency(currency)}
                height="14px"
                alt=""
                width="14px"
              />
            ) : "$"}
            {props.prize + " "}
          </span>
          increases until Ace is picked!
        </li>
        <li>Finish 1st to pick the Ace</li>
        <li>Pick players from any teams scheduled to play on <span style={{ color: "#fb6e00" }}>{monthNames[d.getUTCMonth()]} {("0" + d.getUTCDate()).slice(-2)}, {d.getUTCFullYear()}</span></li>
      </ul>
      <div className={classes.__power_center_card_enter}>
            {userHasEntered ? (
                    <>
                      <Button title={`Entered`} />
                    </>
                  ) : total && Number(total) === Number(totalEntries) ?
                    <Button title={`Full`} /> :
                    paid_game || paid_game === null ? (
                      <Button
                        currency={getEnterCurrency(currency)}
                        title={`Enter`}
                        entry_fee={entry_fee}
                        onClick={onEnter}
                        styles={aceButtonStyle}
                      />
                    ) : (
                      <Button title={`Enter  •  Free`} onClick={onEnter} />
                    )}
        </div>


      

      
    </div>
  );
};

export default ContestRulesAceMobile;
