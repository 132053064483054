import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import classes from './aceSlide.module.scss';
import _ from "underscore";
import { useMediaQuery } from "react-responsive";
import AceCenterLogo from '../../assets/AceCenterLogo.png';
import OrangeBtcCurrency from "../../assets/btc-orange.png";
import OrangeEthCurrency from "../../assets/ethereum-orange.png";
import PWRSIcon from '../../assets/power.png';
import Button from "../Button";
const ChaseTheAceDetailsMobile = (props) => {
  const {
    userHasEntered="",
    currency="",
    total="",
    totalEntries="",
    paid_game="",
    entry_fee="",
    onEnter= () => {}
  } = props;
  const getEnterCurrency = (currency) => {
    if (currency.toUpperCase() === "PWRS") {
      return PWRSIcon;
    } else if (currency.toUpperCase() === "BTC") {
      return OrangeBtcCurrency;
    } else if (currency.toUpperCase() === "ETH") {
      return OrangeEthCurrency;
    } else if (currency.toUpperCase() === "USD") {
      return "$"
    }
  };
  const aceButtonStyle = {
    width: 0,
    margin: "10px auto 0px auto",
    padding: "8px 131px 7px 132px",
    borderRadius: 6,
    backgroundColor: "#fb6e00",
    minWidth: 'auto',
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color:'#111',
  };
  return (
    <div className={`${classes.prizeGridChasewrp}`}>
        <img src={AceCenterLogo} className={classes.AceCenterLogo}/>
        <ul>
            <li><span>1</span> <p>Finish in 1st and you will pick one card from the deck.</p> </li>
            <li><span>2</span><p>If it's the Ace, you win! If not, the prize carries over.</p></li>
            <li><span>3</span><p>If it's the Ace, you win! If not, the prize carries over.</p></li>
        </ul>
        <div className={classes.__power_center_card_enter}>
            {userHasEntered ? (
                    <>
                      <Button title={`Entered`} />
                    </>
                  ) : total && Number(total) === Number(totalEntries) ?
                    <Button title={`Full`} /> :
                    paid_game || paid_game === null ? (
                      <Button
                        currency={getEnterCurrency(currency)}
                        title={`Enter`}
                        entry_fee={entry_fee}
                        onClick={onEnter}
                        styles={aceButtonStyle}
                      />
                    ) : (
                      <Button title={`Enter  •  Free`} onClick={onEnter} />
                    )}
        </div>
    </div>
  );
};

export default ChaseTheAceDetailsMobile;