import { useSelector, useDispatch } from 'react-redux';
import { useState, useCallback } from 'react';
import { addTrailingZerons, getIsPowerAvailable, getIsPowerLocked, getSocialTooltip } from '../../utility/shared';
import _ from "lodash";
import moment from "moment";
import classes from './styles.module.scss';
import swapIcon from '../../assets/swap-player.svg';
import XPIcon from '../../assets/point-multipliers-main.png';
import HockeyIcon from "../../assets/icons/nhl/hockey.svg";
import HelmetIcon from "../../assets/icons/nfl/helmet.svg";
import YardsIcon from "../../assets/icons/nfl/yards.svg";
import footBallIcon from "../../assets/icons/nfl/american-football.svg";


import BatsIcon from "../../assets/icons/mlb/bats.svg";

import ClockIcon from "../../assets/icons/nhl/clock.svg";
import img1 from '../../assets/combinedImageNew.png';
import ShieldIcon from '../../icons/ShieldIcon';
import Challenge from '../../icons/Challenge';
import ChallengePopUp from '../../components/ChallengePopup';
import DwallPopup from '../../components/DwallPopup';
import * as nhlActions from '../../actions/NHLActions';
import RenderModal from '../../components/SportsLiveCard/RenderModal';
import starPlayerIcon from '../../assets/star_power_2.png';
import jerseyIcon from '../../assets/basketball-jersey@2x.png';
import Star3 from '../../assets/star_player/star_3.png'
import Star2 from '../../assets/star_player/star_2.png'
import Star1 from '../../assets/star_player/star_1.png'
const TeamManagerCardNBA = (props) => {
    const dispatch = useDispatch();
    const { selectedView = "fv", data = {}, powersAvailable = [], league = ""} = props;
    const {
        fantasyPlayerPosition = "",
        full_name = "",
        gameID = 0,
        isFromAwayTeam = false,
        isFromHomeTeam = false,
        positionID = 0,
        userID = 0,
        match = {},
        id:pid = "",
        playerId = ""
    } = data;
    const { away = {}, home = {}, status = "scheduled", scheduled = ""} = match;
    const { 
        data: nhlData = [],
        live_team_logs = [],
        live_clock = "20:00", 
        setNhlEventData = [], 
        live_period = 0, 
        live_strength = "even",
        match_status = []
    } = useSelector((state) => state.nhl);
    const { playersActualScore=[],playersPoints={},teamDActualScore={},matchStatus=[]}= useSelector((state) => state.teamManager);
    const { 
        positionP = 0,
        positionC = 0,
        positionSS = 0,
        positionXB1 = 0,
        positionXB2 = 0,
        positionOF1 = 0,
        positionOF2 = 0,
        teamDPoints = 0,
    } = playersPoints;
    const { 
        posD1Points = 0,
        posD2Points = 0,
        posXW1Points = 0,
        posXW2Points = 0,
        posXW3Points = 0,
        posCenterPoints = 0,
        posGoaliePoints = 0,
        playerActualScore=[],
        teamDActual = []
    } = live_team_logs;
    const [timeLeft, setTimeLeft] = useState("20:00");
    const [checkClockData, setCheckClockData] = useState("20:00");
    const [showReplaceModal, setReplaceModalState] = useState(false);
    const [showscrollable, setShowscrollable] = useState(true);
    const [playerList, setPlayerList] = useState({});
    const [loadingPlayerList, setLoadingPlayerList] = useState(false);
    const closeRenderModal = () => {
        setReplaceModalState(false);
    };
    const calculateTimeLeft = (newClock) => {
        setCheckClockData(newClock);
        let myString1 = newClock
        let newminute1;
        let newSecond1;
        let difference1;
        let myStringParts1;
        let myStringParts;
        let newminute;
        let newSecond;
        let newClockData;
        let i = 0;
        const interval =  setInterval(async () => {
            if(newClockData === null || newClockData === undefined) {
                myStringParts1 = myString1?.split(':');
                newminute1 = myStringParts1[0]
                newSecond1 = myStringParts1[1];
                difference1 = moment({ minutes: "00", seconds: "10"}).add({ minutes: newminute1, seconds: newSecond1}).format('mm:ss');
                myStringParts = difference1.split(':');
                newminute= myStringParts[0];
                newSecond= myStringParts[1];
            }
            else{
                myStringParts = myString1?.split(':')
                newminute= myStringParts[0]
                newSecond= myStringParts[1];
                myStringParts1 = newClockData?.split(':')
                newminute1= myStringParts1[0]
                newSecond1= myStringParts1[1];
                difference1=moment({ minutes: newminute1, seconds: newSecond1}).subtract({ minutes: newminute1, seconds: newSecond1}).format('mm:ss');
            }
            let difference2=moment({ minutes: newminute, seconds: newSecond}).subtract({ minutes: newminute1, seconds: newSecond1}).format('mm:ss');
            let upperTime=moment.duration(`00:${difference1}`).asSeconds();
            let difference = upperTime - i;
            if (moment.duration(`00:${difference2}`).asSeconds() >=i) {
                let formatted = moment.utc(difference*1000).format('mm:ss');
                setTimeLeft(formatted)
                if (moment.duration(`00:${difference2}`).asSeconds() == i) {
                    newClockData = formatted;
                    return true
                }
                i++;
            }
        }, 1000);
    };
    const timerComponents = [];
    timeLeft && Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }
        if(interval=='minutes')
            timerComponents.push(
                <span>
                {timeLeft[interval]}{":"}
                </span>
            );
        else {
            timerComponents.push(
                <span>
                    {timeLeft[interval]}
                </span>
            );
        }
    });
    const getTeamData = (id) => {
        let liveClockData = setNhlEventData.filter(x => x?.id  == id);
        if(liveClockData?.length > 0)
        {
            let a = liveClockData[liveClockData.length - 1];
            if(a?.clock !== checkClockData)
                if(a?.clock=="00:00"){
                    setTimeout(() => {
                    setTimeLeft(a?.clock)
                    }, 500);
                }
                else {
                    calculateTimeLeft(a?.clock);
                }
            return a;
          }
        return false
    };
    const getTeamPoints = (id, id2) => {
        let filteredData = matchStatus.filter(x => x?.gameFeedId == id);
        if(filteredData?.length > 0)
        {
            let a = filteredData[filteredData.length - 1];
            if(a.away.id == id2){
                if(a?.away?.runs) return a?.away?.runs;
                else return false
            }
            if(a.home.id == id2){
                if(a?.home?.runs)return a?.home?.runs;
                else return false
            }
        }
        return false;
    };
    const getInningHalf = (id) => {
        let filteredData = matchStatus.filter(x => x?.gameFeedId == id);
        if(filteredData?.length > 0)
        {
            let a = filteredData[filteredData.length - 1];
            if(a?.outcome?.currentInningHalf){
                if(a?.outcome?.currentInningHalf==="TOP" &&a?.outcome?.currentInning){
                    if(a?.outcome?.currentInning==1){
                        return "Top 1st"
                    }
                    else if(a?.outcome?.currentInning==2){
                        return "Top 2nd"
                    }
                    else if(a?.outcome?.currentInning==3){
                        return "Top 3rd"
                    }
                    else{
                        return `Top ${a?.outcome?.currentInning}th`
                    }
                }
                else if(a?.outcome?.currentInningHalf==="BOTTOM" &&a?.outcome?.currentInning){
                    if(a?.outcome?.currentInning==1){
                        return "Bot 1st"
                    }
                    else if(a?.outcome?.currentInning==2){
                        return "Bot 2nd"
                    }
                    else if(a?.outcome?.currentInning==3){
                        return "Bot 3rd"
                    }
                    else{
                        return `Bot ${a?.outcome?.currentInning}th`
                    }
                }
                else return false
            }
            return false
        }
        return false;
    };
    const isGameOverOrNotStarted = () => {
        return (
          `${status}`?.toLocaleLowerCase() === "scheduled" ||
          getStatus() === "Game Over"
        );
      };
    const getStatus = () => {     
        if (
          `${status}`?.toLocaleLowerCase() === "scheduled" &&
          moment().diff(moment.utc(scheduled).local().format()) < 0
        ) {
          return `${moment.utc(scheduled).local().format("MMM Do")} - ${moment.utc(
            scheduled
          ).local().format("hh:mm A")}`;
        } 
        if(setNhlEventData?.length>0){
            let getMatchStatusDetails = match_status?.filter(x => x?.id == match?.id);
            if(getMatchStatusDetails.length > 0)
            {              
                let liveClockData = setNhlEventData.filter(x => x?.matchId  == match?.id);        
                if(liveClockData?.length > 0)
                {
                    let a = liveClockData[0];
                    if(a?.clock!==checkClockData){
                        setCheckClockData(a?.clock)
                        if(a?.clock=="00:00"){
                            return "End Period"
                        }
                        else {
                            return match?.status;
                        }
                    }
                }
            }
            return status;
          }
        return status;
      };
      const toggleReplaceModal = useCallback(async () => {
        
       
          setLoadingPlayerList(true);
          setReplaceModalState(true);
          const response = await dispatch(nhlActions.getFantasyPlayers(gameID));
          // console.log("respodesrrs==>",response);
          if (response?.filterdList && response?.filterdList?.length) {
            const _nhlData = [...response?.filterdList];
            const [swapablePlayerData] = _nhlData?.filter(
              (data) => data?.type === fantasyPlayerPosition?.toLocaleLowerCase()
            );
            if (
              swapablePlayerData &&
              swapablePlayerData?.listData &&
              swapablePlayerData?.listData?.length
            ) {
              const _time = moment(scheduled).clone().format("h:mm A");
    
              const newListData = swapablePlayerData?.listData?.filter(
                (data, index) => {
                  return (
                    moment(data?.match?.scheduled).clone().format("h:mm A") == _time
                  );
                }
              );
              const _dataToRender = {
                type: swapablePlayerData.type,
                listData: newListData,
              };
              setPlayerList(_dataToRender);
            }
          }
          setLoadingPlayerList(false);
        
      }, [nhlData]);
    const getPlayerStatsValues = (league) => {
        if(league === "NHL")
        {
            if (fantasyPlayerPosition == "G") {
                return  (                                           
                <>
                    Saves: {playerActualScore?.find(x => x.playerID == pid)?playerActualScore?.find(x => x.playerID == pid)?.saves : "0"}
                    <br />
                    GA: {Math.abs(playerActualScore?.find(x => x.playerID == pid)?playerActualScore?.find(x => x.playerID == pid)?.goalsAgainst : "")}
                </>
            ) } else { 
                if (fantasyPlayerPosition == "TeamD") { 
                return (<>
                    GA: {teamDActual && teamDActual?.goalsAgainst?teamDActual?.goalsAgainst:0}
                    <br />
                    SA: {teamDActual && teamDActual?.shotsAgainst?teamDActual?.shotsAgainst:0}
                </>) } else {
                return (<>
                    G: {playerActualScore?.find(x => x.playerID == pid)?playerActualScore?.find(x => x.playerID == pid)?.goals+playerActualScore?.find(x => x.playerID == pid)?.overTimeGoals+playerActualScore?.find(x => x.playerID == pid)?.shortHandedGoals : "0"} | A:{playerActualScore?.find(x => x.playerID == pid)?playerActualScore?.find(x => x.playerID == pid)?.assists:"0"}
                    <br />
                    SOG: {playerActualScore?.find(x => x.playerID ==  pid)?playerActualScore?.find(x => x.playerID ==  pid)?.shotsOnGoal:"0"}
                </>)
            } 
        }}
        else if(league === "MLB") {
            return (
                <>
                    IP: 0 | PC: 0
                    <br />
                    K: 0 | W: 0
                </>
            );
        }
        else if(league === "NFL") {
            return (<>{fantasyPlayerPosition == "TeamD"&& (<>
                     TD's against: 3
                     <br />
                    </>)}
                {fantasyPlayerPosition !== "TeamD"&&(<>{fantasyPlayerPosition !== "K"?(<>
                        TDs: 0
                        <br />
                        Rec Yds: 0
                        <br />
                        Rush Yds: 0
                    </>):(<>
                        FG&lt;= 39:2
                        <br />
                        FG&gt;= 40 &lt;= 49:2
                        <br />
                        FG&gt;=50:1 | XP: 2
                    </>)}
                </>)}
                </>
            );
        }
    };
    const getAveragePoints=()=>{
        let pointData=props.fantasyPlayers?.find(x => x.id === playerId)?.seasons[0]?.totals?.statistics?.hitting?.overall?.avg
        if(pointData){
            if(pointData<1){
                let data=addTrailingZerons(pointData)?.toString()?.split(".")[1]
                return "."+data
            }
            else{
                return addTrailingZerons(pointData)
            }
        }else return ".000"
    }
    const getPlayerStatsByType = (type) => {
        if(type === 'ERA') {
            if(props.fantasyPlayers.length > 0)
            {
                let eraData= props?.fantasyPlayers?.find(x => x.id === playerId)?.seasons[0]?.totals?.statistics?.pitching?.overall?.era
                if(eraData){
                    return parseFloat(eraData).toFixed(2)
                }
                return "0.00"
            }
            else
            return "0.00";
        }
        if(type === 'AVG') {
            if(props.fantasyPlayers.length > 0)
            return getAveragePoints();
            else
            return ".000";
        }
    };
    const getTeamStatsByType = () => {
        
        if(teamDActualScore?.wins!==undefined&&teamDActualScore?.loss!==undefined){
            return teamDActualScore?.wins+"-"+teamDActualScore?.loss
        }
        else if(props.fantasyTeam.length > 0)
            return ((props.fantasyTeam?.find(x => x.id === match?.id)?.statistics?.pitching?.overall?.games?.win??0) + "-" + (props.fantasyTeam.find(x => x.id === match?.id)?.statistics?.pitching?.overall?.games?.loss??0))
        else
            return 0;
    };
    return (
        <div className={`${classes.TeamManagerCardMain } ${selectedView.toLowerCase() =="fv"?classes.teamManagerMainPageCard:""}`}>
            {selectedView.toLowerCase() !== "s" ? (
                    <>
                        <div className={classes.topLine}></div>
                        <div className={classes.playerPosition}>

                            {/* {fantasyPlayerPosition === "XW" || fantasyPlayerPosition === "D"
                            ? fantasyPlayerPosition + positionID 
                            : fantasyPlayerPosition}: */}
                            {fantasyPlayerPosition === "QB" ? "C" : null}
                            {fantasyPlayerPosition === "RB1" ? "PG1" : null}
                            {fantasyPlayerPosition === "RB2" 
                            ? "PG2"
                            : null}
                            {fantasyPlayerPosition === "WR1" 
                            ? "SG1"
                            : null}
                            {fantasyPlayerPosition === "WR2" 
                            ? "SG2"
                            : null}
                            {fantasyPlayerPosition === "TE" 
                            ? "F1"
                            : null}
                            {fantasyPlayerPosition === "K" 
                            ? "F2"
                            : null}
                            {fantasyPlayerPosition === "TeamD"
                            ? "TeamD"
                            : null}
                            <span>
                            
                            {fantasyPlayerPosition === "QB" ? positionP : null}
                            {fantasyPlayerPosition === "RB1" ? positionC : null}
                            {fantasyPlayerPosition === "RB2" 
                            ? positionSS
                            : null}
                            {fantasyPlayerPosition === "WR1" 
                            ? positionXB1
                            : null}
                            {fantasyPlayerPosition === "WR2" 
                            ? positionXB2
                            : null}
                            {fantasyPlayerPosition === "TE" 
                            ? positionOF1
                            : null}
                            {fantasyPlayerPosition === "K" 
                            ? positionOF2
                            : null}
                            {fantasyPlayerPosition === "TeamD"
                            ? teamDPoints
                            : null}
                            {" "}Pts</span> 
                        </div>
                    </>
                ) : ""}
            {league === 'MLB' && <>
            <div className={
                    selectedView.toLowerCase() == "s" &&  pid === props.selectedPlayer.id ? `${classes.active}
                    ${classes.TeamManagerCard}` : props.singleViewRight ? `${classes.TeamManagerCard} ${classes.singleViewRight}` :`${classes.TeamManagerCard}`} onClick={() => {props.setSinglePlayer(pid)}}>
                    {props.data.isStarPlayer && 
                    <div className={classes.starPlayerDiv}>
                        {/* <img src={starPlayerIcon} /> */}
                        {
                            props.data.isStartCatagory === 3 ?
                            <img src={Star3} />
                            :
                            props.data.isStartCatagory === 2 ?
                                <img src={Star2} />
                                :
                                <img src={Star1} />
                        }
                    </div>}
    
                    <div className={classes.topPortion} style={{
                        paddingTop: props.data.isStarPlayer ? 0 : 0
                    }}>
                        <div className={classes.playerDiv} style={{
                            flexDirection: "column", alignItems: "flex-start"
                        }}>
                            <div className={classes.playerName}>{full_name}</div>
                            <p style={{
                                fontFamily: "Poppins",
                                fontSize: 13,
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                color: "#979797"
                            }}>
                           </p> 
                        </div>
                        
                        
                    </div>
                    <div className={classes.middlePortion}>
                        {selectedView.toLowerCase() == "s" ? (
                            <>
                                <div className={classes.middleItem}>
                                    <div className={classes.positions}>
                                        {fantasyPlayerPosition === "XW" || fantasyPlayerPosition === "D"
                                        ? fantasyPlayerPosition + positionID 
                                        : fantasyPlayerPosition}
                                    </div>
                                    <div className={classes.points}>
                                        Pts: 
                                        {fantasyPlayerPosition === "QB" ? positionP : null}
                                        {fantasyPlayerPosition === "RB1" ? positionC : null}
                                        {fantasyPlayerPosition === "RB2" 
                                        ? positionSS
                                        : null}
                                        {fantasyPlayerPosition === "WR1" 
                                        ? positionXB1
                                        : null}
                                        {fantasyPlayerPosition === "WR2" 
                                        ? positionXB2
                                        : null}
                                        {fantasyPlayerPosition === "TE" 
                                        ? positionOF1
                                        : null}
                                        {fantasyPlayerPosition === "K" 
                                        ? positionOF2
                                        : null}
                                        {fantasyPlayerPosition === "TeamD"
                                        ? teamDPoints
                                        : null}
                                    </div>
                                    <div className={classes.other}>
                                        <img src={ClockIcon} />
                                        P{ getTeamData(match?.id) !== false ? (getTeamData(match?.id)?.period) : (1)} <span>|</span> {getTeamData(match?.id) !== false ? (typeof getTeamData(match?.id)?.eventData?.clock !== "undefined") ? timerComponents: live_clock : "20:00"}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={props.singleViewRight ? `${classes.leftStats} ${classes.singleViewRight}` : `${classes.leftStats}`} style={{
                                    minHeight: 120
                                }}>
                                    <div className={classes.statisticsMLBFantasy}>
                                    {/* {playersActualScore?.map((item)=>{ */}
                                        
                                        {fantasyPlayerPosition == "P" && (
                                            <div className={classes.otherPlayer}>
                                                <p>Inning Pitched: {playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.inningsPitched??0}</p>
                                                <p>Strike-outs:  {playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.strikeOuts??0}</p>
                                                <p> Runs allowed:  {playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.runsAllowed??0}</p>
                                                <p> Walks:  {playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.bb??0}</p>
                                            </div>
                                        )}

                                        {(fantasyPlayerPosition !== "P" && fantasyPlayerPosition !== "TeamD") && 
                                            <div>
                                            <p>HR: {playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.hr?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.hr:0}</p>
                                            <p>S: {playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.s?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.s:0}</p>
                                            <p>D: {playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.d?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.d:0}</p>
                                            <p>T: {playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.t?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.t:0}</p>
                                            <p>BB: {playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.bb?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.bb:0}</p>
                                            </div>
                                        }
                                        {(fantasyPlayerPosition !== "P" && fantasyPlayerPosition !== "TeamD") && 
                                            <div>
                                            <p>SB: {playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.sb?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.sb:0}</p>
                                            <p>RS: {playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.rs?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.rs:0}</p>
                                            <p>RBI: {playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.rbi?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.rbi:0}</p>
                                            <p>Sac: {playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.sac?playersActualScore?.find(x => x.fantasyPosition == fantasyPlayerPosition)?.sac:0}</p>
                                            </div>
                                        }
                                        { fantasyPlayerPosition === "TeamD"&&
                                         <div className={classes.otherPlayer}>
                                         <p>ER Against: {teamDActualScore?.runsAgainst?teamDActualScore?.runsAgainst:0}</p>
                                         <p>HR Against: {teamDActualScore?.homeRunsAgainst?teamDActualScore?.homeRunsAgainst:0}</p>
                                         </div>
                                        }
                                        
                                        
                                    </div>
                                    
                                </div>
                                <div className={classes.rightStatsMLBFantasy}>
                                    <p>Points</p>
                                    <p className={classes.pointText}>
                                    {fantasyPlayerPosition === "QB" ? positionP : null}
                                    {fantasyPlayerPosition === "RB1" ? positionC : null}
                                    {fantasyPlayerPosition === "RB2" 
                                    ? positionSS
                                    : null}
                                    {fantasyPlayerPosition === "WR1" 
                                    ? positionXB1
                                    : null}
                                    {fantasyPlayerPosition === "WR2" 
                                    ? positionXB2
                                    : null}
                                    {fantasyPlayerPosition === "TE" 
                                    ? positionOF1
                                    : null}
                                    {fantasyPlayerPosition === "K" 
                                    ? positionOF2
                                    : null}
                                    {fantasyPlayerPosition === "TeamD"
                                    ? teamDPoints
                                    : null}
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                    {/* <div className={classes.statusPortion}>
                        {getStatus()}
                    </div> */}
                    {props.selectedView === "fv" && 
                        <div className={classes.bottomPortion}>
                            {league === "NHL" && <>
                                <div className={classes.bottomItem}>
                                    <img src={HockeyIcon} />
                                    {isFromAwayTeam == true ? (
                                        <><span>{away?.alias} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0}</span> vs {home?.alias} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</>
                                    ) : (
                                        <>{away?.alias} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0} vs <span>{home?.alias} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</span></>
                                    )}
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={ClockIcon} alt="Hockey Icon" />
                                        P{ getTeamData(match?.id) !== false ? (getTeamData(match?.id)?.period) : (1)} | {getTeamData(match?.id) !== false ? (typeof getTeamData(match?.id)?.eventData?.clock !== "undefined") ? timerComponents: live_clock : "20:00"}
                                </div>
                            </>}
    
                            {league === "MLB" && <>
                                <div className={classes.bottomItem}>
                                    <img src={BatsIcon} />
                                    {fantasyPlayerPosition == "TeamD" ? (
                                    isFromAwayTeam == true ? (
                                        <><span>{away?.name} {getTeamPoints(match?.matchId, match?.away?.id) !== false? getTeamPoints(match?.matchId, match?.away?.id):0}</span> vs {home?.name} {getTeamPoints(match?.matchId, match?.home?.id) !== false? getTeamPoints(match?.matchId, match?.home?.id):0}</>
                                    ) : (
                                        <>{away?.name} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0} vs <span>{home?.name} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</span></>
                                    )
                                    ):(
                                    isFromAwayTeam == true ? (
                                        <><span>{away?.name} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0}</span> vs {home?.name} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</>
                                    ) : (
                                        <>{away?.name} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0} vs <span>{home?.name} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</span></>
                                    ))}
                            
                                </div>
    
                                {/* <div className={classes.bottomItem}>
                                    <img src={HockeyIcon} />
                                    <>
                                        <span>J. Smith</span>
                                        <br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ERA: 3.23
                                    </>
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={HockeyIcon} />
                                    <>
                                        <span>J. Rogers</span>
                                        <br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.280 | 1/3 | S:0 | B:3
                                    </>
                                </div> */}
                                <div className={classes.bottomItem}>
                                    <img src={img1} width="11px"/>
                                    {(`${status}`?.toLocaleLowerCase() !== "scheduled" &&
                                        moment().diff(moment.utc(scheduled).local().format()) >= 0)? (  
                                        <>
                                            <>{getInningHalf(match?.id)!==false?getInningHalf(match?.id):(match?.outcomes?.top_or_bot??"Bot 1st")} | {match?.outcomes?.count?.outs??0} Out</>
                                        </>
                                    ):(
                                        scheduled?
                                        `${moment.utc(scheduled).local().format("MMM Do")} - ${moment.utc(
                                        scheduled
                                    ).local().format("hh:mm A")}`:'Loading...'
                                    )}
                                   
                                </div>
    
                            </>}
    
                            {league === "NFL" && <>
                                <div className={classes.bottomItem}>
                                    <img src={HockeyIcon} />
                                    {isFromAwayTeam == true ? (
                                        <><span>{away?.alias} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0}</span> vs {home?.alias} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</>
                                    ) : (
                                        <>{away?.alias} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0} vs <span>{home?.alias} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</span></>
                                    )}
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={HockeyIcon} />
                                    <>
                                        Yards to FD/EZ: <span>10/55</span>
                                    </>
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={HockeyIcon} />
                                    <>
                                        Down: <span>1/4</span>
                                    </>
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={ClockIcon} alt="Hockey Icon" />
                                        P{ getTeamData(match?.id) !== false ? (getTeamData(match?.id)?.period) : (1)} | {getTeamData(match?.id) !== false ? (typeof getTeamData(match?.id)?.eventData?.clock !== "undefined") ? timerComponents: live_clock : "20:00"}
                                </div>
                            </>}
                        </div>
                    }
                </div>
            </>}
            {league === 'NBA' && <>
            <div className={
                    selectedView.toLowerCase() == "s" &&  pid === props.selectedPlayer.id ? `${classes.active}
                    ${classes.TeamManagerCard}` : props.singleViewRight ? `${classes.TeamManagerCard} ${classes.singleViewRight}` :`${classes.TeamManagerCard}`} onClick={() => {props.setSinglePlayer(pid)}}>
                    {/* {props.data.isStarPlayer &&  */}
                    <div className={classes.starPlayerDiv}>
                        {/* <img src={starPlayerIcon} /> */}
                        {
                            fantasyPlayerPosition==="WR1"?
                            // props.data.isStartCatagory === 3 ?
                            <img src={Star3} />
                            :
                            props.data.isStartCatagory === 2 ?
                                <img src={Star2} />
                                :
                                props.data.isStartCatagory === 1 ?   
                                <img src={Star1} />
                                :""
                        }
                    </div>
                    {/* } */}
                    <div className={classes.topPortion} style={{
                        paddingTop: props.isStarPlayer ? 10 : 0
                    }}>
                        <div className={classes.playerDiv} style={{
                            flexDirection: "column", alignItems: "flex-start"
                        }}>
                            <div className={classes.playerName}>{full_name}</div>
                            <p style={{
                                fontFamily: "Poppins",
                                fontSize: 13,
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                color: "#979797"
                            }}>
                        { fantasyPlayerPosition === 'TeamD' ? "Team Fouls: 4" : "Fouls: 3"}</p>
                        </div>
                        
                        
                    </div>
                    <div className={classes.middlePortion}>
                        {selectedView.toLowerCase() == "s" ? (
                            <>
                                <div className={classes.middleItem}>
                                    <div className={classes.positions}>
                                        {fantasyPlayerPosition === "XW" || fantasyPlayerPosition === "D"
                                        ? fantasyPlayerPosition + positionID 
                                        : fantasyPlayerPosition}
                                    </div>
                                    <div className={classes.points}>
                                        Pts: {fantasyPlayerPosition === "C" ? posCenterPoints : null}
                                        {fantasyPlayerPosition === "G" ? posGoaliePoints : null}
                                        {fantasyPlayerPosition === "D" && positionID === 1
                                        ? posD1Points
                                        : null}
                                        {fantasyPlayerPosition === "D" && positionID === 2
                                        ? posD2Points
                                        : null}
                                        {fantasyPlayerPosition === "XW" && positionID === 1
                                        ? posXW1Points
                                        : null}
                                        {fantasyPlayerPosition === "XW" && positionID === 2
                                        ? posXW2Points
                                        : null}
                                        {fantasyPlayerPosition === "XW" && positionID === 3
                                        ? posXW3Points
                                        : null}
                                        {fantasyPlayerPosition === "TeamD"
                                        ? teamDPoints
                                        : null}
                                    </div>
                                    <div className={classes.other}>
                                        <img src={ClockIcon} />
                                        P{ getTeamData(match?.id) !== false ? (getTeamData(match?.id)?.period) : (1)} <span>|</span> {getTeamData(match?.id) !== false ? (typeof getTeamData(match?.id)?.eventData?.clock !== "undefined") ? timerComponents: live_clock : "20:00"}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                
                                <div className={props.singleViewRight ? `${classes.leftStats} ${classes.singleViewRight}` : `${classes.leftStats}`} style={{
                                    minHeight: 120
                                }}>
                                    <div className={classes.statisticsMLBFantasy + " "+ classes.statisticsNFLFantasy}>
                                        <div className={classes.pgpstext}>PGPs:</div>
                                        {(fantasyPlayerPosition === "TeamD") && 
                                            <div className={classes.otherPlayer}>
                                                {/* <p>PA: &nbsp;&nbsp;&nbsp;<span>24</span></p>
                                                <p>3PtA: <span>2</span></p> */}
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td><p>PA: </p></td>
                                                            <td><p>&nbsp;<span>24</span></p></td>
                                                        </tr>
                                                        <tr>
                                                            <td><p>3PtA: </p></td>
                                                            <td><p>&nbsp;<span>{" "}2</span></p></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                        {(fantasyPlayerPosition !== "TeamD") && 
                                            <div className={classes.otherPlayer}>
                                                <table>
                                                    <tbody className={classes.pgpbody}>
                                                        <tr>
                                                            <tr>
                                                                <td><p>2Pt: </p></td>
                                                                <td><p>&nbsp;<span>20</span></p></td>
                                                            </tr>
                                                            <tr>
                                                                <td><p>3Pt: </p></td>
                                                                <td><p>&nbsp;<span>12</span></p></td>
                                                            </tr>
                                                            <tr>
                                                                <td><p>A: </p></td>
                                                                <td><p>&nbsp;<span>7</span></p></td>
                                                            </tr>
                                                        </tr>
                                                        <tr className={classes.pgpcolumns}>
                                                            <tr>
                                                                <td><p>Bk: </p></td>
                                                                <td><p>&nbsp;<span>3</span></p></td>
                                                            </tr>
                                                            <tr>
                                                                <td><p>FT: </p></td>
                                                                <td><p>&nbsp;<span>3</span></p></td>
                                                            </tr>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        }

                                    </div>
                                    
                                </div>
                                <div className={classes.rightStatsMLBFantasy}>
                                    <p>Points</p>
                                    <p className={classes.pointText}>
                                        
                                        {/* {fantasyPlayerPosition === "C" ? posCenterPoints : null}
                                        {fantasyPlayerPosition === "G" ? posGoaliePoints : null}
                                        {fantasyPlayerPosition === "D" && positionID === 1
                                        ? posD1Points
                                        : null}
                                        {fantasyPlayerPosition === "D" && positionID === 2
                                        ? posD2Points
                                        : null}
                                        {fantasyPlayerPosition === "XW" && positionID === 1
                                        ? posXW1Points
                                        : null}
                                        {fantasyPlayerPosition === "XW" && positionID === 2
                                        ? posXW2Points
                                        : null}
                                        {fantasyPlayerPosition === "XW" && positionID === 3
                                        ? posXW3Points
                                        : null}
                                        {fantasyPlayerPosition === "TeamD"
                                        ? teamDPts
                                        : null}*/}
                                        0
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                    {/* <div className={classes.statusPortion}>
                        {getStatus()}
                    </div> */}
                    {props.selectedView === "fv" && 
                        <div className={classes.bottomPortion}>
                            {league === "NHL" && <>
                                <div className={classes.bottomItem}>
                                    <img src={HockeyIcon} />
                                    {isFromAwayTeam == true ? (
                                        <><span>{away?.alias} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0}</span> vs {home?.alias} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</>
                                    ) : (
                                        <>{away?.alias} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0} vs <span>{home?.alias} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</span></>
                                    )}
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={ClockIcon} alt="Hockey Icon" />
                                        P{ getTeamData(match?.id) !== false ? (getTeamData(match?.id)?.period) : (1)} | {getTeamData(match?.id) !== false ? (typeof getTeamData(match?.id)?.eventData?.clock !== "undefined") ? timerComponents: live_clock : "20:00"}
                                </div>
                            </>}
    
                            {league === "MLB" && <>
                                <div className={classes.bottomItem}>
                                    <img src={BatsIcon} />
                                    {fantasyPlayerPosition == "TeamD" ? (
                                    isFromAwayTeam == true ? (
                                        <><span>{away?.name} {getTeamPoints(match?.matchId, match?.away?.id) !== false? getTeamPoints(match?.matchId, match?.away?.id):0}</span> vs {home?.name} {getTeamPoints(match?.matchId, match?.home?.id) !== false? getTeamPoints(match?.matchId, match?.home?.id):0}</>
                                    ) : (
                                        <>{away?.name} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0} vs <span>{home?.name} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</span></>
                                    )
                                    ):(
                                    isFromAwayTeam == true ? (
                                        <><span>{away?.name} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0}</span> vs {home?.name} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</>
                                    ) : (
                                        <>{away?.name} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0} vs <span>{home?.name} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</span></>
                                    ))}
                                </div>
    
                                {/* <div className={classes.bottomItem}>
                                    <img src={HockeyIcon} />
                                    <>
                                        <span>J. Smith</span>
                                        <br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ERA: 3.23
                                    </>
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={HockeyIcon} />
                                    <>
                                        <span>J. Rogers</span>
                                        <br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.280 | 1/3 | S:0 | B:3
                                    </>
                                </div> */}
    
                                <div className={classes.bottomItem}>
                                    <img src={img1} width="11px"/>
                                   {(`${status}`?.toLocaleLowerCase() !== "scheduled" &&
                                        moment().diff(moment.utc(scheduled).local().format()) >= 0)? (  
                                        <>
                                            <>{getInningHalf(match?.id)!==false?getInningHalf(match?.id):(match?.outcomes?.top_or_bot??"Bot 1st")} | {match?.outcomes?.count?.outs??0} Out</>
                                        </>
                                    ):(
                                        scheduled?
                                        `${moment.utc(scheduled).local().format("MMM Do")} - ${moment.utc(
                                        scheduled
                                    ).local().format("hh:mm A")}`:'Loading...'
                                    )}
                                </div>
    
                            </>}

                            {league === "NBA" && <>
                                <div className={classes.bottomItem}>
                                    <img src={jerseyIcon}  width="11px"/>
                                    {isFromAwayTeam == true ? (
                                        <><span>{away?.alias} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0}</span> vs {home?.alias} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</>
                                    ) : (
                                        <>{away?.alias} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0} vs <span>{home?.alias} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</span></>
                                    )}
                                </div>
    
                                {/* <div className={classes.bottomItem}>
                                    <img src={HockeyIcon} />
                                    <>
                                        <span>J. Smith</span>
                                        <br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ERA: 3.23
                                    </>
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={HockeyIcon} />
                                    <>
                                        <span>J. Rogers</span>
                                        <br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.280 | 1/3 | S:0 | B:3
                                    </>
                                </div> */}
    
                                <div className={classes.bottomItem}>
                                    <img src={ClockIcon} width="11px"/>
                                    <>
                                        Q1 | 10:09
                                    </>
                                </div>
    
                            </>}
    
                            {league === "NFL" && <>
                                <div className={classes.bottomItem}>
                                    <img src={HockeyIcon} />
                                    {isFromAwayTeam == true ? (
                                        <><span>{away?.alias} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0}</span> vs {home?.alias} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</>
                                    ) : (
                                        <>{away?.alias} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0} vs <span>{home?.alias} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</span></>
                                    )}
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={HockeyIcon} />
                                    <>
                                        Yards to FD/EZ: <span>10/55</span>
                                    </>
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={HockeyIcon} />
                                    <>
                                        Down: <span>1/4</span>
                                    </>
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={ClockIcon} alt="Hockey Icon" />
                                        P{ getTeamData(match?.id) !== false ? (getTeamData(match?.id)?.period) : (1)} | {getTeamData(match?.id) !== false ? (typeof getTeamData(match?.id)?.eventData?.clock !== "undefined") ? timerComponents: live_clock : "20:00"}
                                </div>
                            </>}
                        </div>
                    }
                </div>
            </>}
            {(league !== 'MLB' && league !== 'NBA') && <>
                <div className={
                    selectedView.toLowerCase() == "s" &&  pid === props.selectedPlayer.id ? `${classes.active}
                    ${classes.TeamManagerCard}` : props.singleViewRight ? `${classes.TeamManagerCard} ${classes.singleViewRight}` :`${classes.TeamManagerCard}`} onClick={() => {props.setSinglePlayer(pid)}}>
                       {/* {props?.data?.isStarPlayer &&  */}
                    <div className={classes.starPlayerDiv}>
                        {/* <img src={starPlayerIcon} /> */}
                        {
                            fantasyPlayerPosition== 'QB'?
                            <img src={Star3} />
                            :
                            props.data.isStartCatagory === 2 ?
                                <img src={Star2} />
                                :
                                props.data.isStartCatagory === 1?
                                <img src={Star1} />:""
                        }
                    </div>
                    {/* } */}
    
                    <div className={classes.topPortion} style={{
                        paddingTop: props.isStarPlayer ? 10 : 0
                    }}>
                        <div className={classes.playerDiv}>
                            <div className={classes.playerName}>{full_name}</div>
                        </div>
                        {/* {fantasyPlayerPosition !== "TeamD" && 
                            (!getIsPowerAvailable(powersAvailable, "Swap")) ?
                                (
                                    <button
                                    className={classes.notAvailable}
                                    >
                                        <img src={swapIcon} />
                                    </button>
                                ) : getIsPowerLocked(powersAvailable, "Swap") ? 
                                    getSocialTooltip(<button>
                                        <img src={swapIcon} />
                                    </button>) : <button onClick={toggleReplaceModal}>
                                        <img src={swapIcon} />
                                    </button>
                        } */}
                        
                    </div>
                    <div className={classes.middlePortion}>
                        {selectedView.toLowerCase() == "s" ? (
                            <>
                                <div className={classes.middleItem}>
                                    <div className={classes.positions}>
                                        {fantasyPlayerPosition === "XW" || fantasyPlayerPosition === "D"
                                        ? fantasyPlayerPosition + positionID 
                                        : fantasyPlayerPosition}
                                    </div>
                                    <div className={classes.points}>
                                        Pts:  {fantasyPlayerPosition === "QB" ? positionP : null}
                                            {fantasyPlayerPosition === "RB1" ? positionC : null}
                                            {fantasyPlayerPosition === "RB2" 
                                            ? positionSS
                                            : null}
                                            {fantasyPlayerPosition === "WR1" 
                                            ? positionXB1
                                            : null}
                                            {fantasyPlayerPosition === "WR2" 
                                            ? positionXB2
                                            : null}
                                            {fantasyPlayerPosition === "TE" 
                                            ? positionOF1
                                            : null}
                                            {fantasyPlayerPosition === "K" 
                                            ? positionOF2
                                            : null}
                                            {fantasyPlayerPosition === "TeamD"
                                            ? teamDPoints
                                            : null}
                                    </div>
                                    <div className={classes.other}>
                                        <img src={ClockIcon} />
                                        P{ getTeamData(match?.id) !== false ? (getTeamData(match?.id)?.period) : (1)} <span>|</span> {getTeamData(match?.id) !== false ? (typeof getTeamData(match?.id)?.eventData?.clock !== "undefined") ? timerComponents: live_clock : "20:00"}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={props.singleViewRight ? `${classes.leftStats} ${classes.singleViewRight}` : `${classes.leftStats}`}>
                                    <div className={classes.statistics}>
                                        <p>PGPs</p>
                                        <p className={classes.values}>{getPlayerStatsValues(league)}</p>
                                    </div>
                                    {/* <div className={classes.powers}>
                                        {(fantasyPlayerPosition === "G" || fantasyPlayerPosition === 'TeamD') ? (
                                            <>
                                                {(isGameOverOrNotStarted || !getIsPowerAvailable(powersAvailable, "D-Wall")) ?
                                                (
                                                    <button
                                                    className={classes.notAvailable}
                                                    >
                                                        <ShieldIcon />
                                                    </button>
                                                ) : getIsPowerLocked(powersAvailable, "D-Wall") ? 
                                                    getSocialTooltip(<button>
                                                        <ShieldIcon />
                                                    </button>) : 
                                                    props.isDwallActive == false && 
                                                    <DwallPopup
                                                      component={({ showPopUp }) => (
                                                        <button
                                                          onClick={showPopUp}
                                                        >
                                                          <ShieldIcon />
                                                        </button>
                                                      )}
                                                      dwall={parseInt(powersAvailable.find(x => x.powerName == "D-Wall")?.amount)}
                                                      useDwall={props.useDwall}
                                                      setIsDwallActive={props.setIsDwallActive}
                                                      isDwallActive={props.isDwallActive}
                                                      setShowPleaseWait={props.setShowPleaseWait}
                                                    />
                                                    }
                                                    {(!getIsPowerAvailable(powersAvailable, "Challenge")) ?
                                                    (
                                                        <button
                                                        className={classes.notAvailable}
                                                        >
                                                            <Challenge />
                                                        </button>
                                                    ) : getIsPowerLocked(powersAvailable, "Challenge") ? 
                                                        getSocialTooltip(<button>
                                                            <Challenge />
                                                        </button>) : 
                                                        <ChallengePopUp
                                                        component={({ showPopUp }) => (
                                                          <button
                                                            onClick={showPopUp}
                                                            style={{ background: "none", border: 0 }}
                                                          >
                                                            <Challenge />
                                                          </button>
                                                        )}
                                                        challenge={parseInt(powersAvailable.find(x => x.powerName == "Challenge")?.amount)}
                                                        useChallenge={props.useChallenge}
                                                      />}
                                            </>
                                        ) : (
                                            (isGameOverOrNotStarted || !getIsPowerAvailable(powersAvailable, "1.5x Point Booster")) ?
                                            (
                                                <button
                                                className={classes.notAvailable}
                                                >
                                                    <img src={XPIcon} />
                                                </button>
                                            ) : getIsPowerLocked(powersAvailable, "1.5x Point Booster") ? 
                                                getSocialTooltip(<button>
                                                    <img src={XPIcon} />
                                                </button>) : <button>
                                                    <img src={XPIcon} />
                                                </button>
                                        )}
                                    </div> */}
                                </div>
                                <div className={classes.rightStats}>
                                    <p>Points</p>
                                    <p className={classes.pointText}>
                                    {fantasyPlayerPosition === "QB" ? positionP : null}
                                    {fantasyPlayerPosition === "RB1" ? positionC : null}
                                    {fantasyPlayerPosition === "RB2" 
                                    ? positionSS
                                    : null}
                                    {fantasyPlayerPosition === "WR1" 
                                    ? positionXB1
                                    : null}
                                    {fantasyPlayerPosition === "WR2" 
                                    ? positionXB2
                                    : null}
                                    {fantasyPlayerPosition === "TE" 
                                    ? positionOF1
                                    : null}
                                    {fantasyPlayerPosition === "K" 
                                    ? positionOF2
                                    : null}
                                    {fantasyPlayerPosition === "TeamD"
                                    ? teamDPoints
                                    : null}
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                    <div className={classes.statusPortion}>
                        {getStatus()}
                    </div>
                    {props.selectedView === "fv" && 
                        <div className={classes.bottomPortion}>
                            {league === "NHL" && <>
                                <div className={classes.bottomItem}>
                                    <img src={HockeyIcon} />
                                    {isFromAwayTeam == true ? (
                                        <><span>{away?.alias} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0}</span> vs {home?.alias} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</>
                                    ) : (
                                        <>{away?.alias} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0} vs <span>{home?.alias} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</span></>
                                    )}
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={ClockIcon} alt="Hockey Icon" />
                                        P{ getTeamData(match?.id) !== false ? (getTeamData(match?.id)?.period) : (1)} | {getTeamData(match?.id) !== false ? (typeof getTeamData(match?.id)?.eventData?.clock !== "undefined") ? timerComponents: live_clock : "20:00"}
                                </div>
                            </>}
    
                            {league === "MLB" && <>
                                <div className={classes.bottomItem}>
                                    <img src={BatsIcon} />
                                    {fantasyPlayerPosition == "TeamD" ? (
                                    isFromAwayTeam == true ? (
                                        <><span>{away?.name} {getTeamPoints(match?.matchId, match?.away?.id) !== false? getTeamPoints(match?.matchId, match?.away?.id):0}</span> vs {home?.name} {getTeamPoints(match?.matchId, match?.home?.id) !== false? getTeamPoints(match?.matchId, match?.home?.id):0}</>
                                    ) : (
                                        <>{away?.name} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0} vs <span>{home?.name} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</span></>
                                    )
                                    ):(
                                    isFromAwayTeam == true ? (
                                        <><span>{away?.name} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0}</span> vs {home?.name} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</>
                                    ) : (
                                        <>{away?.name} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0} vs <span>{home?.name} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</span></>
                                    ))}
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={BatsIcon} />
                                    <>
                                        <span>J. Smith</span>
                                        <br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ERA: 3.23
                                    </>
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={BatsIcon} />
                                    <>
                                        <span>J. Rogers</span>
                                        <br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.280 | 1/3 | S:0 | B:3
                                    </>
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={BatsIcon} />
                                    {(`${status}`?.toLocaleLowerCase() !== "scheduled" &&
                                        moment().diff(moment.utc(scheduled).local().format()) >= 0)? (  
                                        <>
                                            <>{getInningHalf(match?.id)!==false?getInningHalf(match?.id):(match?.outcomes?.top_or_bot??"Bot 1st")} | {match?.outcomes?.count?.outs??0} Out</>
                                        </>
                                    ):(
                                        scheduled?
                                        `${moment.utc(scheduled).local().format("MMM Do")} - ${moment.utc(
                                        scheduled
                                    ).local().format("hh:mm A")}`:'Loading...'
                                    )}
                                </div>
    
                            </>}
    
                            {league === "NFL" && <>
                                <div className={classes.bottomItem}>
                                    <img src={HelmetIcon} />
                                    {isFromAwayTeam == true ? (
                                        <><span>{away?.alias} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0}</span> vs {home?.alias} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</>
                                    ) : (
                                        <>{away?.alias} {getTeamPoints(match?.id, match?.away?.id) !== false? getTeamPoints(match?.id, match?.away?.id):0} vs <span>{home?.alias} {getTeamPoints(match?.id, match?.home?.id) !== false? getTeamPoints(match?.id, match?.home?.id):0}</span></>
                                    )}
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={YardsIcon} />
                                    <>
                                        Yards to FD/EZ: <span>10/55</span>
                                    </>
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={footBallIcon} />
                                    <>
                                        Down: <span>1/4</span>
                                    </>
                                </div>
    
                                <div className={classes.bottomItem}>
                                    <img src={ClockIcon} alt="Hockey Icon" />
                                        P{ getTeamData(match?.id) !== false ? (getTeamData(match?.id)?.period) : (1)} | {getTeamData(match?.id) !== false ? (typeof getTeamData(match?.id)?.eventData?.clock !== "undefined") ? timerComponents: live_clock : "20:00"}
                                </div>
                            </>}
                        </div>
                    }
                </div>
                <RenderModal
                    player={data}
                    visible={showReplaceModal}
                    onClose={closeRenderModal}
                    onSwap={() => {}}
                    scrollable={showscrollable}
                    playerList={playerList}
                    starPlayerCount={0}
                    loading={loadingPlayerList}
                    dataMain={{}}
                    pageType="nhl"
                />
            </>}
        </div>
    );
};
export default TeamManagerCardNBA;