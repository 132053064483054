import React from 'react'

function Ticket(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props?.size || "33"} height={props?.size || "33"} viewBox="0 0 33 33" style={{height: 'auto'}} {...props}>
            <g fill="none" fillRule="evenodd">
            <g fill="#FB6E00" fillRule="nonzero">
                <g>
                    <g>
                        <path d="M32.208 6.336h.528V0H0v6.336h.528c.873 0 1.584.71 1.584 1.584 0 .873-.71 1.584-1.584 1.584H0v6.336h8.448v5.152c0 1.51.56 2.936 1.584 4.037V26.4H8.976v3.168h1.056v3.168h12.672v-3.168h1.056V26.4h-1.056v-2.427c1.023-1.101 1.584-2.526 1.584-4.037V15.84h8.448V9.504h-.528c-.873 0-1.584-.71-1.584-1.584 0-.873.71-1.584 1.584-1.584zM21.648 31.68h-10.56v-2.112h10.56v2.112zm1.056-3.168H10.032v-1.056h12.672v1.056zm.528-8.576c0 1.303-.507 2.529-1.43 3.45l-.154.155V26.4h-10.56v-1.803l-.155-.154c-.922-.922-1.429-2.148-1.429-3.451V13.2c0-.29.237-.528.528-.528.873 0 1.584.71 1.584 1.584v3.432l.211.158c1.19.893 1.901 2.314 1.901 3.802h1.056c0-1.729-.785-3.384-2.112-4.48V15.84h10.56v4.096zm8.448-9.43v4.278h-4.224V13.2H26.4v1.584H12.672v-.528c0-1.456-1.184-2.64-2.64-2.64-.873 0-1.584.71-1.584 1.584v1.584H6.336V13.2H5.28v1.584H1.056v-4.277c1.203-.245 2.112-1.312 2.112-2.587 0-1.275-.909-2.342-2.112-2.587V1.056H5.28V2.64h1.056V1.056H26.4V2.64h1.056V1.056h4.224v4.277c-1.203.245-2.112 1.312-2.112 2.587 0 1.275.909 2.342 2.112 2.587z" transform="translate(-331 -509) translate(331 509)"/>
                        <path d="M16.368 2.112c-3.202 0-5.808 2.606-5.808 5.808s2.606 5.808 5.808 5.808 5.808-2.606 5.808-5.808-2.606-5.808-5.808-5.808zm0 10.56c-2.62 0-4.752-2.132-4.752-4.752 0-2.62 2.132-4.752 4.752-4.752 2.62 0 4.752 2.132 4.752 4.752 0 2.62-2.132 4.752-4.752 4.752z" transform="translate(-331 -509) translate(331 509)"/>
                        <path d="M14.491 5.897L15.077 6.775 16.368 5.915 16.368 10.56 17.424 10.56 17.424 4.752 16.208 4.752zM5.28 3.696L6.336 3.696 6.336 5.808 5.28 5.808zM5.28 6.864L6.336 6.864 6.336 8.976 5.28 8.976zM5.28 10.032L6.336 10.032 6.336 12.144 5.28 12.144zM26.4 3.696L27.456 3.696 27.456 5.808 26.4 5.808zM26.4 6.864L27.456 6.864 27.456 8.976 26.4 8.976zM26.4 10.032L27.456 10.032 27.456 12.144 26.4 12.144zM28.512 20.592L29.568 20.592 29.568 21.648 28.512 21.648zM28.512 22.704L29.568 22.704 29.568 23.76 28.512 23.76zM29.568 21.648L30.624 21.648 30.624 22.704 29.568 22.704zM27.456 21.648L28.512 21.648 28.512 22.704 27.456 22.704zM3.696 16.896L4.752 16.896 4.752 17.952 3.696 17.952zM3.696 19.008L4.752 19.008 4.752 20.064 3.696 20.064zM4.752 17.952L5.808 17.952 5.808 19.008 4.752 19.008zM2.64 17.952L3.696 17.952 3.696 19.008 2.64 19.008zM2.112 28.512L3.168 28.512 3.168 29.568 2.112 29.568zM2.112 30.624L3.168 30.624 3.168 31.68 2.112 31.68zM3.168 29.568L4.224 29.568 4.224 30.624 3.168 30.624zM1.056 29.568L2.112 29.568 2.112 30.624 1.056 30.624zM25.872 28.512L26.928 28.512 26.928 29.568 25.872 29.568zM25.872 30.624L26.928 30.624 26.928 31.68 25.872 31.68zM26.928 29.568L27.984 29.568 27.984 30.624 26.928 30.624zM24.816 29.568L25.872 29.568 25.872 30.624 24.816 30.624z" transform="translate(-331 -509) translate(331 509)"/>
                    </g>
                </g>
            </g>
        </g>
        </svg>
    )
}

export default Ticket
