import * as $ from "jquery";
import styles from './styles.module.scss';
import arrow from '../../assets/group-17@3x.png';
import moment from "moment";
import { useEffect } from "react";

const MyScoreDetailsMobileCard = (props) => {
    const {
        data = {}
    } = props;
    const getPoints = (item,poss) => {
        let pos1 = item?.type === "shotagainst" && item?.saved == true
        ? poss == "G1"? "SV": "SA"
        : item?.type === "shotagainst" && item?.saved == false ? 
        "GA" 
        : item?.type === "goalagainst"
        ? "GA"
        :
        item?.newmyscore_description? 
        item?.newmyscore_description[0]:
        item?.myscore_description?
        item?.myscore_description[0]:
        item?.goalScored>0?"G":
        item?.type[0];
        // console.log("pos1===>>", pos1, item);
        if(poss == "TeamD") {
          if(pos1=="GA"){
            return item?.goalAgainst
          }
          if(pos1=="SA"){
            return item?.shotAgainst
          }
          return item?.teamD;
        }
        else {
            // console.log("iiiiiiiiiiiiiii=>",pos1);

          if(pos1 == 'SV'){
            return item?.saves;
          }
          else if(pos1 == 'g') {
            return item?.goalScored;
          }
          else if(pos1 == 's') {
            return item?.newplayerPts ? item?.newplayerPts : item?.shotOnGoal ? item?.shotOnGoal : 0;
          }
          else {
            return item?.newplayerPts ? item?.newplayerPts : item?.playerPts ? item?.playerPts : 0;
          }
        }
      };
    return (
        <div className={styles.scoreCard}>
            <div className={styles.topPart}>
                <div className={styles.positions}>
                    {
                        data?.fantasyPosition == "TeamD" ? "TD" :
                        data?.fantasyPosition == "G1" ? "G" : 
                        data?.fantasyPosition == "C1" ? "C" : data?.fantasyPosition
                    }
                </div>
                <div className={styles.seperator}></div>
                <div className={styles.playerDetails}>
                    <p>{ data?.fantasyPosition == "TeamD" ? data?.teamName : data?.playerName }</p>
                    {/* <span></span> */}
                </div>
                <div className={styles.arrow}>
                    <button onClick={(e) => {
                        if($(e.target).parent().find('span').text() == "See less") {
                            $(e.target).parent().find('img').addClass("rotate");
                            $(e.target).parent().find('span').text("See More");
                            $(e.target).parent().parent().parent().next().find("div[class^='styles_statsPlayer']").hide();
                        }
                        else {
                            $(e.target).parent().find('img').removeClass("rotate");
                            $(e.target).parent().find('span').text("See less");
                            $(e.target).parent().parent().parent().next().find("div[class^='styles_statsPlayer']").show();
                        }
                    }}>
                        <span>See less</span>
                        <img src={arrow} width="14"/>
                    </button>
                </div>
            </div>
            <div className={styles.middlePart}>
                <div className={styles.statsPlayer + ' statsPlayerDiv'}>
                    <div className={styles.statItem}>
                        <p>Scoring Plays</p>
                        <div className={styles.itemBottom}>
                            <div className={styles.itemBottomPart}>
                                <p>Plays</p>
                                <span className={styles.spans}>
                                    {
                                        data?.type === "shotagainst" && data?.saved == true
                                        ? data.fantasyPosition == "G1"? "SV":"SA"
                                        : data?.type === "shotagainst" && data?.saved == false ? 
                                        "GA" 
                                        : data?.type === "goalagainst"
                                        ? "GA"
                                        : data?.newmyscore_description? 
                                        data?.newmyscore_description[0]:
                                        data?.myscore_description?
                                        data?.myscore_description[0]:
                                        data?.goalScored>0?"G":
                                        data?.type[0]
                                    }
                                </span>
                            </div>
                            <div className={styles.itemBottomPart}>
                                <p>Pts</p>
                                <span>{getPoints(data, data.fantasyPosition)}</span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.statItem}>
                        <p>Time Stamp</p>
                        <div className={styles.itemBottom}>
                            {/* <div className={styles.itemBottomPart} style={{marginTop:"28px"}}>
                                <span>{`P${data?.period + 1}`}</span>
                            </div> */}
                            <div className={styles.itemBottomPartTime} style={{marginTop:"28px"}}>
                                {/* <p>Pts</p> */}
                                <span>{`${moment.utc(data?.wall_clock).local().format("hh:mm:ss A")}`}</span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.statItem}>
                        <p>Game Clock</p>
                        <div className={styles.itemBottom}>
                            <div className={styles.itemBottomPart} style={{marginTop:"28px"}}>
                                {/* <p>RS</p> */}
                                <span>{`P${data?.period}`}</span>
                            </div>
                            <div className={styles.itemBottomPart} style={{marginTop:"28px"}}>
                                {/* <p>Pts</p> */}
                                <span>{data?.clock}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.totals}>
                    <div className={styles.totalItem}>
                        <p>Total Pts</p>
                        <div>{getPoints(data, data.fantasyPosition)}</div>
                    </div>
                    <div className={styles.totalItem}>
                        <p>Powers</p>
                        <div>-</div>
                    </div>
                    <div className={styles.totalItem}>
                        <p>My Score</p>
                        <div className={
                                        // data?.type === "shotagainst" && data?.saved == true
                                        // ? styles.success
                                        // : data?.type === "shotagainst" && data?.saved == false ? 
                                        // styles.danger
                                        // : data?.type === "goalagainst"
                                        // ? styles.danger
                                        // : styles.success
                                        getPoints(data, data.fantasyPosition) < 0 ? styles.danger : styles.success
                                    }>
                            {getPoints(data, data.fantasyPosition)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.bottomPart}>
                <p>Running Total</p>
                <div>
                    {
                    props?.runningTotal }
                </div>
            </div>
        </div>
    );
};
export default MyScoreDetailsMobileCard;