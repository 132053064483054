import React from 'react';

const Hockeys = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" {...props}>
        <g fill="currentColor" fillRule="nonzero">
            <path d="M25.292 21.23H18.79l-1.93-2.695L27.262 4.107c.794-1.086.56-2.608-.526-3.404-1.084-.795-2.612-.56-3.406.524l-9.468 13.166L4.403 1.227C3.61.143 2.081-.092.997.703c-1.08.79-1.325 2.31-.527 3.403l10.4 14.448-1.926 2.677H2.441c-1.344 0-2.437 1.093-2.437 2.437v1.624c0 1.344 1.093 2.437 2.437 2.437h7.376c.26 0 .506-.126.658-.337l3.376-4.682 3.409 4.685c.153.21.397.334.657.334h7.375c1.344 0 2.437-1.093 2.437-2.437v-1.624c0-1.344-1.093-2.437-2.437-2.437zm-.65-19.045c.265-.36.773-.436 1.134-.172.362.265.44.773.175 1.136-.002.001-4.98 6.907-4.98 6.907l-1.293-.969 4.964-6.902zm-22.86.964c-.265-.363-.187-.87.175-1.136.36-.264.869-.187 1.134.172l4.96 6.905-1.294.97L1.783 3.15zm5.925 8.23L9 10.41l3.862 5.375-.992 1.38-4.163-5.785zM3.253 26.104h-.812c-.448 0-.812-.364-.812-.812v-1.624c0-.448.364-.813.812-.813h.812v3.25zm3.303 0H4.878v-3.249h1.678v3.25zm2.845 0H8.18v-3.249h1.18c.261 0 .507-.125.66-.338l8.709-12.11 1.29.967L9.4 26.104zm10.151 0H18.33l-3.48-4.78 1.008-1.398 1.854 2.59c.152.213.398.34.66.34h1.18v3.248zm3.303 0h-1.678v-3.249h1.678v3.25zm3.25-.812c0 .448-.365.812-.813.812h-.812v-3.249h.812c.448 0 .812.365.812.813v1.624z" transform="translate(-832 -1477) translate(363 1466) translate(438) translate(31 11)" />
            <path d="M13.867 8.127c2.156 0 4.06-.94 4.06-2.437V2.44c0-1.64-2.168-2.436-4.06-2.436-2.151 0-4.062.937-4.062 2.437V5.69c0 1.64 2.169 2.437 4.062 2.437zm0-1.625c-1.582 0-2.437-.621-2.437-.812V4.42c.69.295 1.544.458 2.437.458.892 0 1.746-.163 2.436-.459v1.27c0 .18-.826.813-2.436.813zm0-4.873c1.559 0 2.436.613 2.436.812 0 .18-.826.812-2.436.812-1.582 0-2.437-.621-2.437-.812 0-.18.827-.812 2.437-.812z" transform="translate(-832 -1477) translate(363 1466) translate(438) translate(31 11)" />
        </g>
    </svg>
)

export default Hockeys;