import React from "react";

const Replace = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.size || "54"}
    height={props?.size || "54"}
    viewBox="0 0 54 54"
    style={{ height: "auto" }}
    {...props}
  >
    <defs>
      <linearGradient
        id="prefix__a"
        x1="35.019%"
        x2="67.721%"
        y1="39.758%"
        y2="84.553%"
      >
        <stop offset="0%" stopColor="#FB6E00" />
        <stop offset="100%" stopColor="#FA3800" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="26.507" cy="26.507" r="26.507" fill="url(#prefix__a)" />
      <g fillRule="nonzero">
        <path
          fill="#FFF"
          d="M32.631 24.208l-10.32-8.19c-.308-.244-.762-.022-.762.374v5.445H9.804c-2.832 0-5.736-.792-7.881-2.669-.336-.294-.854.006-.771.446.145.78.442 1.956 1.03 3.123 1.508 2.995 4.072 4.514 7.622 4.514h11.654v5.199c0 .18.101.344.26.424.157.08.349.065.494-.045l10.41-7.869c.117-.088.187-.226.189-.374.001-.147-.065-.287-.18-.378z"
          transform="translate(11 11)"
        />
        <path
          fill="#D9D9DD"
          d="M32.631 24.208l-1.346-1.068c-9.805 7.411-9.131 6.953-9.356 6.953-.258 0-.47-.21-.47-.474v2.83c0 .18.1.345.26.425.156.08.348.065.493-.045l10.41-7.869c.117-.088.187-.226.189-.374.001-.147-.065-.287-.18-.378z"
          transform="translate(11 11)"
        />
        <path
          fill="#FFF"
          d="M31.659 13.324c-.863-4.624-3.745-7.636-8.653-7.636H11.352v-5.2c0-.39-.444-.613-.753-.379L.189 7.978c-.248.187-.253.56-.01.752 11.116 8.822 10.378 8.291 10.611 8.291.258 0 .471-.21.471-.474V11.1h11.745c2.84 0 5.742.796 7.882 2.669.336.294.853-.006.771-.446z"
          transform="translate(11 11)"
        />
        <g fill="#D9D9DD">
          <path
            d="M10.79 7.355c-.233 0 .454.49-9.397-7.327L.188.938c-.247.187-.252.56-.009.753L10.5 9.88c.308.244.762.023.762-.373V6.881c0 .263-.213.474-.47.474zM31.659 6.284c-.102-.545-.278-1.284-.576-2.078-.314-.078-2.68-2.77-8.077-2.77h-8.864c-.121.027-.304.042-.56.042-1.711 0-2.2.948-2.32 1.695v.889h11.744c2.84 0 5.742.795 7.882 2.668.336.295.853-.006.771-.446z"
            transform="translate(11 11) translate(0 7.04)"
          />
        </g>
        <path
          fill="#D9D9DD"
          d="M18.896 24.42H9.804c-3.935 0-6.605-1.882-7.984-5.319-.35-.172-.74.132-.668.513.864 4.625 3.745 7.637 8.652 7.637h11.654v-.673c-.085-.837-.54-2.158-2.562-2.158z"
          transform="translate(11 11)"
        />
      </g>
    </g>
  </svg>
);

export default Replace;
