import React, {useState, useEffect} from "react";
import classes from "./challengepage.module.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useHistory } from "react-router-dom";
import PrizeModal from "../../components/PrizeModal";

import bg from "../../assets/baseball-player-bg.png";
import { redirectTo, getLocalStorage } from "../../utility/shared";
import _ from "underscore";
import moment from "moment";
import moment1 from "moment-timezone";
import RulesPopup from "../../components/Rules";

import ReplaceIcon from "../../icons/Replace";
import XpIcon from "../../icons/XPIcon";
import ShieldIcon from "../../icons/ShieldIcon";
import Challenge from "../../icons/Challenge";
import RetroBoostIcon from "../../icons/RetroBoost";
import PowerUpIcon from "../../icons/PowerUp";
import ContestRulesPopUp from "../../components/ContestRulesPopUp";
import NFLbg from "../../assets/promomodalnflbg.png";
import NHLbg from "../../assets/promomodalnhlbg.png";
import NBAbg from "../../assets/promomodalnbabg.png";
import MLBbg from "../../assets/baseball-player-bg.png";
import surveyMobileAd from '../../assets/surveyMobileAd.png';
import {getTeamsList, saveFreeEntry, savedFreeEntryTest} from ".././../actions/MLBActions"
import { CONSTANTS } from "../../utility/constants";
import { useDispatch } from "react-redux";
import { showDepositForm } from "../../actions/uiActions";
import fbicon from '../../assets/fbicon.svg';
import twiticon from '../../assets/twiticon.svg';
import axios from "axios";
import { checkEntryCode } from '../../actions/userActions';

const ChallengePage = (props) => {
  const history = useHistory();
  const [showPrizeModal, setPrizeModalState] = React.useState(false);
  const [showContestRulesModal, setContestRulesModalState] = React.useState(false);
  const [isSocialMediaMode, setSocialMediaMode] = useState(false);
  const [IsEssayMode, setIsEssayMode] = useState(false);


  const [getPowers, setPowers] = useState([]);
  const {
    state = []
  } = props.location || {}
  const [isFreeEntryMode, setIsFreeEntryMode] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [cashBalance, setCashBalance] = useState(0)
  const [freeEntryData, setFreeEntryData] = useState({
    MLBTeam: "",
    NHLTeam: "",
    NFLTeam: "",
    NBATeam: ""
  });
  const [teamData, setTeamData] = useState([]);
  const [userSurveySaved, setUserSurveySaved] = useState(false);
  const [freeEntryGameIDs, setFreeEntryGameIDs] = useState([])
  const [verifyEntryCode, setVerifyEntryCode] = useState("");
  const [getEntryCode, setEntryCode] = useState(null);

  
  const {game_id, game_type, Power, league = "MLB", powerdfs_challenge_amount = 0, cards_remaining=0,chase_prize_pool="",PointsSystem = [], topPrize = 0, game_set_start, start_time} = state || [];
  let SOCIAL_MEDIA_SHARING_TEXT = "Who else is playing fantasy sports on defygames.io? I think the best thing about the platform is there's no salary cap to deal with! Just makes it so much easier to enter and win."
  useEffect(() => {
    function checkUserData() {
      const item = localStorage.getItem('freeEntryGameIDs')

      if (item) {
        setFreeEntryGameIDs(JSON.parse(item))
      } else setFreeEntryGameIDs([])
    }

    window.addEventListener('storage', checkUserData)

    return () => {
      window.removeEventListener('storage', checkUserData)
    }
  }, [])

  useEffect(() => {
    checkSurveyTeam()
    setCashBalance(
      parseFloat(getLocalStorage(CONSTANTS.LOCAL_STORAGE_KEYS.CASH_BALANCE))
    );
  }, []);
 
  const checkSurveyTeam=async()=>{
    if(localStorage.getItem(CONSTANTS.PERSONA_USER_ID)){
      let user_id=localStorage.getItem(CONSTANTS.PERSONA_USER_ID)
      let res= await savedFreeEntryTest({user_id:user_id})
      if(res?.data){
        setUserSurveySaved(res?.data)
      }
    }
  }

  const checkValidation = () => {
    if(freeEntryData.MLBTeam === "")
    {
      setIsValidated(false);
    }
    else if(freeEntryData.NBATeam === "")
    {
      setIsValidated(false);
    }
    else if(freeEntryData.NHLTeam === "")
    {
      setIsValidated(false);
    }
    else if(freeEntryData.NFLTeam === "")
    {
      setIsValidated(false);
    }
    else {
      setIsValidated(true);
    }
  }; 

  useEffect(() => {
    checkValidation();
  },[freeEntryData]);

  useEffect(async () => {
    let a = await getTeamsList();
    if(a.status == 200)
    {
      setTeamData(a.data);
    }
  }, []);

  useEffect(() => {
    powersList();
  }, [state]);
  
  const getLocalDateTime = (date, time) => {

    const offset = moment1?.tz("America/New_York")?.format("Z");
    const localDateTime = moment.utc(date + " " + time, 'YYYY-MM-DD hh:mm A').utcOffset(offset).format('YYYY-MM-DD=hh:mm A')

    const splitted = localDateTime.split("=");

    return {
      date: splitted[0],
      time: splitted[1]
    }

    // const localDateTime = moment(moment.utc(date + ' ' + time, 'YYYY-MM-DD hh:mm A').toDate()).format('YYYY-MM-DD=hh:mm A')
    // const splitted = localDateTime.split("=");
    // return {
    //   date: splitted[0],
    //   time: splitted[1]
    // }
  }
  const dispatch = useDispatch();
  const setShowDepositModal = () => dispatch(showDepositForm());

  const redirectToUrl = (free) => {
    let item = props.location.state;
    if (cashBalance >= item?.entry_fee||free==="free"){
    let url = "/mlb-select-team";
    switch(league) {
      case "MLB": 
        url = "/mlb-select-team";
        break;
      case "NFL": 
        url = "/nfl-select-team";
        break
      case "NHL": 
        url = "/nhl-select-team";
        break
      case "NBA": 
        url = "/nba-select-team";
        break
      case "default":
        url = "/mlb-select-team";
        break
    }
    return redirectTo(props, {
      path: url,
      state: {
        game_id: item?.game_id,
        sport_id: item?.sports_id,
        start_date: getLocalDateTime(item?.start_date, item?.start_time)?.date,
        game_set_start: getLocalDateTime(item?.game_set_start, item?.start_time)?.date,
        start_time: getLocalDateTime(item?.game_set_start, item?.start_time)?.time,
        end_date: getLocalDateTime(item?.game_set_end, item?.end_time)?.date,
        outOf: item?.target,
        enrolledUsers: item?.enrolled_users,
        prizePool: _.reduce(
          item?.PrizePayouts,
          function (memo, num) {
            return memo + parseInt(num.amount) * parseInt(num.prize);
          },
          0
        ),
        topPrize: parseFloat(
          _.max(item?.PrizePayouts, function (ele) {
            return ele.amount;
          }).amount
        ),
        PointsSystem: item?.PointsSystems,
        Power: item?.Powers,
        prizes: item?.PrizePayouts ? item.PrizePayouts : item.prizes,
        paid_game: item?.is_game_paid,
        entry_fee: item?.entry_fee,
        currency: item?.currency,
        game_type: item?.game_type,
        free_entry_options:item?.free_entry_options, 
        chase_prize_pool:item?.chase_prize_pool,
        duration:item?.duration   
      },
    });
  }else{
    setShowDepositModal()
  }
}

  const onBack = () => {
    history.push("/power-center");
  }

  const showPopUp = () => {
    setContestRulesModalState(true);
  }

  const powersList = () => {
    let powersAvailable = Power;
    if(powersAvailable == undefined)
      return;
    
    let finalList = [];
    if(powersAvailable.findIndex(x => x.powerId == 4) >= 0)
    {
      finalList.push({
        powerName: "Player Swap",
        amount: powersAvailable.find(x => x.powerId == 4).amount,
        icon: (<ReplaceIcon />)
      });
    }
    if(powersAvailable.findIndex(x => x.powerId == 1) >= 0)
    {
      let amount = 0;
      if(finalList.findIndex(x => x.powerName == "Point Booster") >= 0)
      {
        amount = parseInt(finalList.find(x => x.powerName == "Point Booster").amount) + parseInt(powersAvailable.find(x => x.powerId == 1).amount);
        finalList[finalList.findIndex(x => x.powerName == "Point Booster")].amount = amount;
      }
      else {
        amount = powersAvailable.find(x => x.powerId == 1).amount;
        finalList.push({
          powerName: "Point Booster",
          amount: powersAvailable.find(x => x.powerId == 1).amount,
          icon: (<XpIcon />)
        });
      }
    }
    if(powersAvailable.findIndex(x => x.powerId == 2) >= 0)
    {
      let amount = 0;
      if(finalList.findIndex(x => x.powerName == "Point Booster") >= 0)
      {
        amount = parseInt(finalList.find(x => x.powerName == "Point Booster").amount) + parseInt(powersAvailable.find(x => x.powerId == 2).amount);
        finalList[finalList.findIndex(x => x.powerName == "Point Booster")].amount = amount;
      }
      else {
        amount = powersAvailable.find(x => x.powerId == 2).amount;
        finalList.push({
          powerName: "Point Booster",
          amount: powersAvailable.find(x => x.powerId == 2).amount,
          icon: (<XpIcon />)
        });
      }
    }
    if(powersAvailable.findIndex(x => x.powerId == 3) >= 0)
    {
      let amount = 0;
      if(finalList.findIndex(x => x.powerName == "Point Booster") >= 0)
      {
        amount = parseInt(finalList.find(x => x.powerName == "Point Booster").amount) + parseInt(powersAvailable.find(x => x.powerId == 3).amount);
        finalList[finalList.findIndex(x => x.powerName == "Point Booster")].amount = amount;
      }
      else {
        amount = powersAvailable.find(x => x.powerId == 3).amount;
        finalList.push({
          powerName: "Point Booster",
          amount: powersAvailable.find(x => x.powerId == 3).amount,
          icon: (<XpIcon />)
        });
      }
    }
    if(powersAvailable.findIndex(x => x.powerId == 6) >= 0)
    {
      finalList.push({
        powerName: "Challenge",
        amount: powersAvailable.find(x => x.powerId == 6).amount,
        icon: (<Challenge />)
      });
    }
    if(powersAvailable.findIndex(x => x.powerId == 5) >= 0)
    {
      finalList.push({
        powerName: "D-Wall",
        amount: powersAvailable.find(x => x.powerId == 5).amount,
        icon: (<ShieldIcon />)
      });
    }
    if(powersAvailable.findIndex(x => x.powerId == 10) >= 0)
    {
      finalList.push({
        powerName: "2x Retro Boost",
        amount: powersAvailable.find(x => x.powerId == 10).amount,
        icon: (<RetroBoostIcon />)
      });
    }
    if(powersAvailable.findIndex(x => x.powerId == 8) >= 0)
    {
      finalList.push({
        powerName: "Power-Up",
        amount: powersAvailable.find(x => x.powerId == 8).amount,
        icon: (<PowerUpIcon />)
      });
    }
    setPowers(finalList);
  };


  const getBG = () => {
    switch(league) {
      case "MLB": 
        return MLBbg;
      case "NFL": 
        return NFLbg;
      case "NHL": 
        return NHLbg;
      case "NBA": 
        return NBAbg;
      case "default":
        return MLBbg;
    }
  }
  const checkFreeEntryCode=async()=>{
    let data={
      game_id:state?.game_id,
      entryCode:getEntryCode
    }
    let res=await dispatch(checkEntryCode(data));
    if(res==="matched"){
      redirectToUrl("free")
    }else{
      setVerifyEntryCode("notMatch")
      setEntryCode("")
    }
  }
  const numberWithCommas = (x) => {
    if (x >= 10000) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return x;
  };
  return (
    <div>
      <Header />
      {!isFreeEntryMode ? (
        <>
          
          <div className={classes.mainContent} style={{
            backgroundImage: `url(${getBG()})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: "100%",
            backgroundPosition: "initial"
          }}>
            <div className={classes.backButton} onClick={onBack}>
              <span>
                {"< Back"}
              </span>
            </div>
            <>
                
                {game_type == "PowerdFs_promo" && 
                  <>
                    <div className={classes.title}>
                    {league} <span>PowerdFS</span> {game_type == "PowerdFs_promo" ? ("Promotional Contest") : (<><br /> {powerdfs_challenge_amount} Point Challenge</>)}
                    </div>
                    <div className={classes.gamePrize}>
                      <span className={classes.prize}>${topPrize}</span>
                      <span className={classes.giveaway}>Give Away</span>
                    </div>
                  </>
                }
                {game_type == "PowerdFs_challenge" && 
                  <>
                    <div className={classes.title}>
                    {league} <span>PowerdFS</span> <br />{powerdfs_challenge_amount} Point Challenge!
                      <br />
                      <span className={classes.dateTime}>
                        {/* {state?.start_date} */}
                        {game_set_start + " | " + start_time + " ET"}
                      </span>
                      <br />
                      <span className={classes.teamMessage}>
                        Manage your team to <br /> {powerdfs_challenge_amount} points and win
                      </span>
                      <span className={classes.prizeSpan}>
                        ${topPrize}!
                      </span>
                    </div>
                  </>
                }
                {game_type == "Fantasy_Challenge" && 
                  <>
                    <div className={classes.title}>
                      {league} <span>Fantasy</span> <br />{powerdfs_challenge_amount} Point Challenge!
                      <br />
                      <span className={classes.dateTime}>
                        {game_set_start + " | " + start_time + " ET"}
                      </span>
                      {/* <br />
                      <span className={classes.teamMessage}>
                        Manage your team to <br /> {powerdfs_challenge_amount} points and win
                      </span>
                      <span className={classes.prizeSpan}>
                        ${topPrize}!
                      </span> */}
                    </div>
                  </>
                }
                {game_type == "Fantasy_Promo" && 
                  <>
                    <div className={classes.title}>
                    {league} <span>Fantasy</span> <><br />Manager Challenge</>
                    </div>
                    {/* <div className={classes.gamePrize}>
                      <span className={classes.prize}>${topPrize}</span>
                      <span className={classes.giveaway}>Give Away</span>
                    </div> */}
                  </>
                }
                 {game_type == "Fantasy_Chase_The_Ace" && 
                  <>
                    <div className={classes.title}>
                    {league} <span>Chase The Ace</span> 
                    </div>
              
                  </>
                }
              </>
            
            <div className={classes.rectangle}>
              {(!game_type.toLowerCase().includes("fantasy")) && <>
                <span className={classes.youHaveThePower}>
                  You have the Powers to win!
                </span>
                <div className={classes.powers}> 
                  {typeof getPowers[0] !== "undefined" && 
                    <div className={classes.power}>
                      {getPowers[0].icon}
                      <span>{getPowers[0].powerName}</span>
                      <span className={classes.orange}> x{getPowers[0].amount}</span>
                    </div>
                  }
                  {typeof getPowers[1] !== "undefined" && 
                    <div className={classes.power}>
                      {getPowers[1].icon}
                      <span>{getPowers[1].powerName}</span>
                      <span className={classes.orange}> x{getPowers[1].amount}</span>
                    </div>
                  }
                </div>
                <div className={`${classes.powers} ${classes.margin}`}>
                  {typeof getPowers[2] !== "undefined" && 
                      <div className={classes.power}>
                        {getPowers[2].icon}
                        <span>{getPowers[2].powerName}</span>
                        <span className={classes.orange}> x{getPowers[2].amount}</span>
                      </div>
                    }
                    {typeof getPowers[3] !== "undefined" && 
                      <div className={classes.power}>
                        {getPowers[3].icon}
                        <span>{getPowers[3].powerName}</span>
                        <span className={classes.orange}> x{getPowers[3].amount}</span>
                      </div>
                    }
                </div>
                <div className={`${classes.powers} ${classes.margin}`}>
                  {typeof getPowers[4] !== "undefined" && 
                      <div className={classes.power}>
                        {getPowers[4].icon}
                        <span>{getPowers[4].powerName}</span>
                        <span className={classes.orange}> x{getPowers[4].amount}</span>
                      </div>
                    }
                    {typeof getPowers[5] !== "undefined" && 
                      <div className={classes.power}>
                        {getPowers[5].icon}
                        <span>{getPowers[5].powerName}</span>
                        <span className={classes.orange}> x{getPowers[5].amount}</span>
                      </div>
                    }
                </div>
              </>}
              {game_type == "Fantasy_Promo" && <>
                <div className={classes.youHaveThePower} style={{
                  textAlgin: "center"
                }}>
                  Manage your team to victory points and win
                </div>
                <div className={classes.prize} style={{
                  textAlgin: "center",
                  fontSize: 34,
                  fontWeight: "bold"
                }}>${topPrize}</div>
              </>}
              {game_type == "Fantasy_Challenge" && <>
                <div className={classes.youHaveThePower} style={{
                  textAlign: "center"
                }}>
                  Manage your team to {powerdfs_challenge_amount} points and win
                </div>
                <div className={classes.prize} style={{
                  textAlign: "center",
                  fontSize: 34,
                  fontWeight: "bold"
                }}>${topPrize}</div>
              </>}
              {game_type == "Fantasy_Chase_The_Ace" && <>
                <div className={classes.youHaveThePower} style={{
                  textAlign: "center"
                }}>
                Finish in 1st place, pick the Ace and win
                </div>
                <div className={classes.prize} style={{
                  textAlign: "center",
                  fontSize: 34,
                  fontWeight: "bold"
                }}>${numberWithCommas(chase_prize_pool)}</div>
              </>}
              <div className={classes.topButtons}>
                <div className={classes.leftButtons}>
                  <button
                    type="button"
                    onClick={() => setPrizeModalState(true)}
                  ><span>Prize Grid</span></button>
                  <ContestRulesPopUp
                    points={[state?.PrizePayout]}
                    powers={state?.Power}
                    component={({ showPopUp }) => (
                      <button
                        type="button"
                        onClick={showPopUp}
                      ><span>Game Rules</span></button>
                    )}
                    game_type={game_type}
                    title={league}
                  />
                  <RulesPopup
                  title={league}
                  component={({ showPopUp }) => (
                    <button
                      type="button"
                      onClick={showPopUp}
                    ><span>Constest Rules</span></button>
                    )}
                    gameDetails={state}
                  />
                </div>
              </div>
            </div>
            <div className={classes.extraDesc}>
              <p>No purchase necessary. Contest entry closes at <span>{game_set_start + " " + start_time}.</span></p>
              <p>Open to residents of Canada (excluding Quebec) and United States who are over the age of majority.</p>
            </div>
            <div className={classes.bottomButton}>
              <button onClick={redirectToUrl}>
                ${props?.location?.state?.entry_fee ? (props?.location?.state?.entry_fee) : 0}  • Enter & Get 5 Bonus PWRS!
              </button>
            </div>
            {console.log("state=>",state)}
            <div className={classes.freeEntry}  onClick={() => {
              if(state?.free_entry_options==="Fav Sport Survey"){
                setIsFreeEntryMode(true);
              }
              else if(state?.free_entry_options==="Social Media"){
                setIsFreeEntryMode(true);
                setSocialMediaMode(true)
              }
              else if(state?.free_entry_options==="Essay"){
                setIsFreeEntryMode(true);
                setIsEssayMode(true);
              }else{
                setIsFreeEntryMode(true);
              }
              }
            } style={{cursor: "pointer"}}>Free Entry</div>
            <div className={classes.rules}>
              <ul>
                {game_type == "PowerdFs_promo" && 
                  <li><div className={classes.Oval}></div>Twenty (20) prizes to be won. See full rules for complete details of all prizes.</li>
                }
                {game_type == "PowerdFs_challenge" && 
                  <li><div className={classes.Oval}></div>One (1) prize to be won. If there are multiple winners, the prize will be evenly devided. See full contest rules for complete details.</li>
                }
                {(game_type === "Fantasy_Promo" || game_type === "Fantasy_Challenge") && 
                  <li><div className={classes.Oval}></div>Five (5) prizes to be won. See full rules for complete details of all prizes.</li>
                }
                <li><div className={classes.Oval}></div><div className={classes.litext}>One entry per person.</div></li>
                <li><div className={classes.Oval}></div><div className={classes.litext}>
                  {game_type==="Fantasy_Chase_The_Ace"?`Odds of winning depend on the number of entries, participants fantasy sports knowledge, and the number of cards remaining.`:
                  game_type.toLowerCase().includes("fantasy")? "Odds of winning depend on the number of entries and participants fantasy sports knowledge."
                  : `Odds of winning depend on the number of participants and use of Powers.`}
                  </div> </li>
              </ul>
            </div>
          </div>
          
        </>
      ) :
      IsEssayMode?(<>
        <div className={classes.topButtonsFree}>
                      <div className={classes.closeButton}>
                        {/* <CloseIcon onClick={() => setIsEssayMode(false)} />back */}
                        
                      </div>
                    </div>
                    <div className={classes.modalForm}>
                   
                        <>
                        <div className={classes.modalContentWrp}>
                        <button className={classes.backButtontop} onClick={() => {setIsFreeEntryMode(false);setIsEssayMode(false); setSocialMediaMode(false);}}>
                          {"< Back"}
                        </button>
                          <h2>Get your free entry code!</h2>
                          <span>For your free entry code, write an <p>original 200 word essay</p> on one of the following topics:</span>
                          <ul>
                            <li><em>1.</em>Does fighting belong in hockey? Is the game better off without it?</li>
                            <li><em>2.</em>Propose an alternate scoring system for the NHL and explain the benefits of said scoring system.</li>
                            <li><em>3.</em>Baseball should implement a hard salary cap. Discuss.</li>
                            <li><em>4.</em>The CFL should adopt NFL rules and become a NFL minor league system. Discuss.</li>
                            <li><em>5.</em>Tell us about your favorite athletes and what about them inspires you.</li>
                          </ul>
                          <label>Email submissions to <a href="mailto:freeentry@defygames.io">freeentry@defygames.io</a>, we will do a quick plagiarism check and if all is good, we will email you <p>a code for free entry.</p></label>
                          <span>Submissions must be received <p>at least 4 hours before</p> the posted contest start time.</span>
                          <input type="text" placeholder="Enter Code" value={getEntryCode} onChange={(e)=>setEntryCode(e.target.value)}/>
                        </div>
                      </>
                   
                      {verifyEntryCode!=="notMatch"&&(
                      <div className={classes.bottomButtonsEssay}>
                        <button className={classes.backButtonEssay} onClick={() => {setIsFreeEntryMode(false);setIsEssayMode(false); setSocialMediaMode(false);}}>
                          {"< Back"}
                        </button>
                        <button className={classes.entryButtonEssay} onClick={() =>  checkFreeEntryCode()} disabled={!getEntryCode}>
                          {"Free Entry"}
                        </button>
                      </div>
                      )}
                    </div>
      </>):(<>{
      isSocialMediaMode?(
      <>
        <div className={classes.topButtonsFreeModal}>
           <div onClick={() => {setIsFreeEntryMode(false);setSocialMediaMode(false);setIsEssayMode(false);
           }}>
             {"< Back"}
           </div>
            
           </div>
         <div className={classes.modalForm}>
           <div className={classes.entryModalTitle}>
           <h2>Enter for Free!</h2>
           <p>Just share on Social Media and you are in!</p>
           </div>
           <div className={classes.entryModalButton}>




           {
                    (window.location.hostname === "defygames.io") ?
                      <button className={classes.entryButton}
                        onClick={() => {

                          var left = window.screen.width / 2 - 600 / 2,
                            top = window.screen.height / 2 - 600 / 2;
                          window.open(
                            `https://www.facebook.com/dialog/share?app_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&display=popup&href=https://defygames.io/&quote=${SOCIAL_MEDIA_SHARING_TEXT}&redirect_uri=https://defygames.io/power-center?fb=1_game_${game_id}_`,
                            "targetWindow",
                            "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600,left=" +
                            left +
                            ",top=" +
                            top
                          );

                        }}
                      >
                        <img src={fbicon} /> {"Share on Facebook"}
                      </button> :
                      <button className={classes.entryButton}
                        onClick={() => {

                          var left = window.screen.width / 2 - 600 / 2,
                            top = window.screen.height / 2 - 600 / 2;
                          window.open(
                            `https://www.facebook.com/dialog/share?app_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&display=popup&href=http://test.powerplaysystems.com&quote=${SOCIAL_MEDIA_SHARING_TEXT}&redirect_uri=https://test.powerplaysystems.com/power-center?fb=1_game_${game_id}_`,
                            "targetWindow",
                            "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600,left=" +
                            left +
                            ",top=" +
                            top
                          );

                        }}
                      >
                        <img src={fbicon} /> {"Share on Facebook"}
                      </button>
                  }
           </div>
           
           <div className={classes.entrybottomButtons}>
             <button className={classes.backButton} onClick={() => 
             {  setIsFreeEntryMode(false);
               setSocialMediaMode(false);
               setIsEssayMode(false);
             }}>
                 {"< Back"}
             </button>
               <button disabled={!freeEntryGameIDs.includes(game_id.toString())} className={classes.entryButton} onClick={()=>redirectToUrl("free")}>
                   {"Free Entry"}
               </button>
             </div>
           </div>
       </>
       
       ):
      userSurveySaved ? (
        <>
          <div className={classes.modalHeading}>
              <button className={classes.backButton} onClick={() => {setIsFreeEntryMode(false);setSocialMediaMode(false); setIsEssayMode(false);}}>
                  {"< Back"}
              </button>
          </div>
          <div className={classes.modalForm}>
            <a href="https://fanatics.93n6tx.net/gbL40g" target="_blank">
              <img src={surveyMobileAd} alt="survey Ad" style={{width: "100%"}}></img>
            </a>
          <div className={classes.bottomButtons}>
                    <button className={classes.entryButton} onClick={()=>redirectToUrl("free")}>
                        {"Free Entry"}
                    </button>
                    <button className={classes.backButton} onClick={() => setIsFreeEntryMode(false)}>
                        {"< Back"}
                    </button>
          </div>
          </div>
        </>
      ) : (
        <>
            <div className={classes.modalHeading}>
              <button className={classes.backButton} onClick={() => {setIsFreeEntryMode(false);setSocialMediaMode(false);setIsEssayMode(false);}}>
                  {"< Back"}
              </button>
              <p>Please complete the free entry survey below:</p>
            </div>
            <div className={classes.modalForm}>
                <div className={classes.formElem}>
                    <p>
                        What MLB team do you cheer for?
                    </p>
                    <select onChange={(e) => {
                      setFreeEntryData(prevState => {
                        return {
                        ...prevState,
                        MLBTeam: e.target.value
                        }
                      });
                    }}>
                        <option value="">Select your MLB team</option>
                        {teamData.mlb_teams && teamData.mlb_teams.map((item) => {
                            return  (
                              <option value={`${item.team_id}`}>{item.name}</option>
                            )
                        })}
                    </select>
                </div>

                <div className={classes.formElem}>
                    <p>
                        What NFL team do you cheer for?
                    </p>
                    <select onChange={(e) => {
                      setFreeEntryData(prevState => {
                        return {
                        ...prevState,
                        NFLTeam: e.target.value
                        }
                      });
                    }}>
                        <option value="">Select your NFL team</option>
                        {teamData.nfl_teams && teamData.nfl_teams.map((item, index) => {
                            return  (
                              <option value={`${item.team_id}`}>{item.name}</option>
                            )
                        })}
                    </select>
                </div>

                <div className={classes.formElem}>
                    <p>
                        What NBA team do you cheer for?
                    </p>
                    <select onChange={(e) => {
                      setFreeEntryData(prevState => {
                        return {
                        ...prevState,
                        NBATeam: e.target.value
                        }
                      });
                    }}> 
                        <option value="">Select your NBA team</option>
                        {teamData?.nba_teams && teamData?.nba_teams.map((item, index) => {
                            return  (
                              <option value={`${item.team_id}`}>{item.name}</option>
                            )
                        })}
                    </select>
                </div>

                <div className={classes.formElem}>
                    <p>
                        What NHL team do you cheer for?
                    </p>
                    <select onChange={(e) => {
                      setFreeEntryData(prevState => {
                        return {
                        ...prevState,
                        NHLTeam: e.target.value
                        }
                      });
                    }}>
                        <option value="">Select your NHL team</option>
                        {teamData.nhl_teams && teamData.nhl_teams.map((item, index) => {
                            return  (
                              <option value={`${item.team_id}`}>{item.name}</option>
                            )
                          })}
                    </select>
                </div>
            
                <div className={classes.bottomButtons}>
                    <button className={classes.entryButton} disabled={!isValidated} onClick={async() => {
                      const user_id = getLocalStorage("PERSONA_USER_ID");
                      let payload = {
                        "user_id" : parseInt(user_id),
                        "mlb_team_id" : parseInt(freeEntryData.MLBTeam),
                        "nhl_team_id" : parseInt(freeEntryData.NHLTeam),
                        "nfl_team_id" : parseInt(freeEntryData.NFLTeam),
                        "nba_team_id" : parseInt(freeEntryData.NBATeam)
                      }
                      let res = await saveFreeEntry(payload);
                      if (res.status === 200) {
                        redirectToUrl("free");
                      }
                    }}>
                        {"Free Entry"}
                    </button>
                    <button className={classes.backButton} onClick={() => setIsFreeEntryMode(false)}>
                        {"< Back"}
                    </button>
                </div>
            </div>
        </>
      )
      }</>)}
       {verifyEntryCode==="notMatch"&&(
          <>
            {/* <Modal visible={true}> */}
            <div className={classes.codegeneratemodal}>
                <h1>Wrong code entered</h1>
                <p>Please check the code and try again </p>
                <div className={classes.codegeneratebuttonwrp}>
                <button className={classes.codegeneratebutton} onClick={() => setVerifyEntryCode("")}>
                  {"OK"}
                </button>
              </div>
              </div>
          {/* </Modal> */}
          </>
        )}
      <Footer />
      <PrizeModal
        visible={showPrizeModal}
        sportsName={league}
        data={state?.prizes}
        gameType={game_type}
        onClose={() => setPrizeModalState(false)}
      />
    </div>
  );
};

export default ChallengePage;
