import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Input from "../../ui/Input/Input";

import Alert from "../../components/Alert";
import { isEmpty } from "lodash";
import formStyles from "../../scss/formstyles.module.scss";
import HeroSection from "../../components/CreateAccountsHeroSection/HeroSection";
import { getApiURL } from "../../config/apiURL";
import http from "../../config/http";

async function passwordReset(email, password, token) {
  const api = getApiURL()
  try {
    const response = await http.post(`${api}/api/v1/users/reset-password`, { email, password, token })
    return response.data
  } catch (error) {
    if (error.response && error.response.data) {
        return error.response.data
    }

    console.error(error)
    throw error
  }
}

function ResetPasswordPage(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [token, setToken] = useState((new URLSearchParams(window.location.search)).get('token'))
  const [loading, setLoadingState] = useState(false);
  const [passwordHasBeenReset, setPasswordHasBeenReset] = useState(false)
  const [error, setError] = useState(null)

  const onSubmit = useCallback((e) => {
    e?.preventDefault();
    if (loading) return

    if (password !== passwordConfirmation) {
        setError("Passwords don't match")
        return
    }

    setError(null)
    setLoadingState(true);

    passwordReset(email, password, token)
      .then((response) => {
        if (!response.success) {
            setError(response.reason ?? "Unknown error")
            return
        }

        setPasswordHasBeenReset(true)
      })
      .catch((error) => {
        setError("Unknown error")
      })
      .finally(() => {
        setLoadingState(false)
      })
  }, [setLoadingState, email, password, passwordConfirmation, token, setPasswordHasBeenReset, setError]);

  return (
    <div className={styles.root}>
      <Header isStick={true} />

      <HeroSection title={passwordHasBeenReset ? "ALL SET!" : "RESET PASSWORD"} />
      {error && <Alert danger renderMsg={() => <p>An error occurred while trying to reset your password: {error}</p>} />}
      {!passwordHasBeenReset ? (
        <div className={styles.container}>
          <form onSubmit={onSubmit} className={formStyles.root}>
            <Input
              type="email"
              title="E-mail"
              required
              value={email}
              onChange={(e) => setEmail(e?.target?.value)}
            />
            <Input
              type="password"
              title="New password"
              required
              value={password}
              onChange={(e) => setPassword(e?.target?.value)}
            />
            <Input
              type="password"
              title="Confirm new password"
              required
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e?.target?.value)}
            />

            <button
              className={formStyles.button}
              type="submit"
              disabled={loading || !email || !password || !passwordConfirmation}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </form>
          <p className={`${styles.blogSection}`}>
            Don't have an account?{" "}
            <Link to="/power-up">Click here to Power-up!</Link>
          </p>
          <p className={`${styles.blogSection1}`}>
            Remember Password? <Link to="/login">Click here to Login!</Link>
          </p>
        </div>

      ) : (
        <p className={`${styles.blogSection}`}>
          Your password has been reset! Redirecting you to the login page...
        </p>
      )}

      <Footer isBlack />
    </div >
  );
}

export default ResetPasswordPage;
