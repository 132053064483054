export const MLBPointSystem = [
    {
        "id": 12679,
        "PowerDfsGameId": 1809,
        "select": true,
        "type": "Hitter",
        "plays": "HR",
        "inningFrom": 1,
        "inningTo": 9,
        "action": "+",
        "points": "10",
        "createdAt": "2022-05-10T04:10:57.000Z",
        "updatedAt": "2022-05-10T04:10:57.000Z"
    },
    {
        "id": 12680,
        "PowerDfsGameId": 1809,
        "select": true,
        "type": "Hitter",
        "plays": "Triple",
        "inningFrom": 1,
        "inningTo": 9,
        "action": "+",
        "points": "8",
        "createdAt": "2022-05-10T04:10:57.000Z",
        "updatedAt": "2022-05-10T04:10:57.000Z"
    },
    {
        "id": 12681,
        "PowerDfsGameId": 1809,
        "select": true,
        "type": "Hitter",
        "plays": "Double",
        "inningFrom": 1,
        "inningTo": 9,
        "action": "+",
        "points": "5",
        "createdAt": "2022-05-10T04:10:57.000Z",
        "updatedAt": "2022-05-10T04:10:57.000Z"
    },
    {
        "id": 12682,
        "PowerDfsGameId": 1809,
        "select": true,
        "type": "Hitter",
        "plays": "Single",
        "inningFrom": 1,
        "inningTo": 9,
        "action": "+",
        "points": "3",
        "createdAt": "2022-05-10T04:10:57.000Z",
        "updatedAt": "2022-05-10T04:10:57.000Z"
    },
    {
        "id": 12683,
        "PowerDfsGameId": 1809,
        "select": true,
        "type": "Hitter",
        "plays": "Stolen Base",
        "inningFrom": 1,
        "inningTo": 9,
        "action": "+",
        "points": "5",
        "createdAt": "2022-05-10T04:10:57.000Z",
        "updatedAt": "2022-05-10T04:10:57.000Z"
    },
    {
        "id": 12684,
        "PowerDfsGameId": 1809,
        "select": true,
        "type": "Hitter",
        "plays": "RunScored",
        "inningFrom": 1,
        "inningTo": 9,
        "action": "+",
        "points": "2",
        "createdAt": "2022-05-10T04:10:57.000Z",
        "updatedAt": "2022-05-10T04:10:57.000Z"
    },
    {
        "id": 12685,
        "PowerDfsGameId": 1809,
        "select": true,
        "type": "Hitter",
        "plays": "RBI",
        "inningFrom": 1,
        "inningTo": 9,
        "action": "+",
        "points": "2",
        "createdAt": "2022-05-10T04:10:57.000Z",
        "updatedAt": "2022-05-10T04:10:57.000Z"
    },
    {
        "id": 12686,
        "PowerDfsGameId": 1809,
        "select": true,
        "type": "Hitter",
        "plays": "Walk",
        "inningFrom": 1,
        "inningTo": 9,
        "action": "+",
        "points": "1",
        "createdAt": "2022-05-10T04:10:57.000Z",
        "updatedAt": "2022-05-10T04:10:57.000Z"
    },
    {
        "id": 12687,
        "PowerDfsGameId": 1809,
        "select": true,
        "type": "Hitter",
        "plays": "Sacrifice",
        "inningFrom": 1,
        "inningTo": 9,
        "action": "+",
        "points": "1",
        "createdAt": "2022-05-10T04:10:57.000Z",
        "updatedAt": "2022-05-10T04:10:57.000Z"
    },
    {
        "id": 12688,
        "PowerDfsGameId": 1809,
        "select": true,
        "type": "Pitcher",
        "plays": "K",
        "inningFrom": 1,
        "inningTo": 9,
        "action": "+",
        "points": "3",
        "createdAt": "2022-05-10T04:10:57.000Z",
        "updatedAt": "2022-05-10T04:10:57.000Z"
    },
    {
        "id": 12689,
        "PowerDfsGameId": 1809,
        "select": true,
        "type": "Pitcher",
        "plays": "Outs",
        "inningFrom": 1,
        "inningTo": 9,
        "action": "+",
        "points": "1",
        "createdAt": "2022-05-10T04:10:57.000Z",
        "updatedAt": "2022-05-10T04:10:57.000Z"
    },
    {
        "id": 12690,
        "PowerDfsGameId": 1809,
        "select": true,
        "type": "Pitcher",
        "plays": "Walk",
        "inningFrom": 1,
        "inningTo": 9,
        "action": "-",
        "points": "1",
        "createdAt": "2022-05-10T04:10:57.000Z",
        "updatedAt": "2022-05-10T04:10:57.000Z"
    },
    {
        "id": 12691,
        "PowerDfsGameId": 1809,
        "select": true,
        "type": "Pitcher",
        "plays": "Runs Against",
        "inningFrom": 1,
        "inningTo": 9,
        "action": "-",
        "points": "1",
        "createdAt": "2022-05-10T04:10:57.000Z",
        "updatedAt": "2022-05-10T04:10:57.000Z"
    },
    {
        "id": 12692,
        "PowerDfsGameId": 1809,
        "select": true,
        "type": "Team_D",
        "plays": "HR Against",
        "inningFrom": 1,
        "inningTo": 9,
        "action": "-",
        "points": "5",
        "createdAt": "2022-05-10T04:10:57.000Z",
        "updatedAt": "2022-05-10T04:10:57.000Z"
    },
    {
        "id": 12693,
        "PowerDfsGameId": 1809,
        "select": true,
        "type": "Team_D",
        "plays": "Runs Against",
        "inningFrom": 1,
        "inningTo": 9,
        "action": "-",
        "points": "1",
        "createdAt": "2022-05-10T04:10:57.000Z",
        "updatedAt": "2022-05-10T04:10:57.000Z"
    }
]