import React from "react";
import classes from "./contest.module.scss";
import correctcopy from "../../assets/correct-copy-2.svg";
import ContestRulesPopUp from "../ContestRulesPopUp";
import PowerCurrency from '../../assets/power-orange.png';
import BtcCurrency from '../../assets/btc-blue.png';
import EthCurrency from '../../assets/ethereum-blue.png';
import _ from 'underscore';

const data = [
  {
    heading: "The 8 roster positions:",
    teamRoster: [
      {
        count: 1,
        title: "P",
        value: "(Pitcher)",
      },
      {
        count: 1,
        title: "C",
        value: "(Catcher)",
      },
      {
        count: 1,
        title: "SS",
        value: "(Shortstop)",
      },
      {
        count: 2,
        title: "xB",
        value: "(1B, 2B or 3B)",
      },
      {
        count: 2,
        title: "OF",
        value: "(Outfielders)",
      },
      {
        count: 1,
        title: "Team Defense",
        value: "",
      },
    ],
  },
];

const getCurrency = (currency) => {
  if (currency?.toUpperCase() === 'PWRS') {
    return PowerCurrency;
  } else if (currency?.toUpperCase() === 'ETH') {
    return EthCurrency;
  } else if (currency?.toUpperCase() === 'BTC') {
    return BtcCurrency;
  }
}

const ContestRules = (props) => {
  const { title = "", game_type = "", isMobile = false, currency, currencyString, getPWRSTitle = () => { }, pwrsOnEnterButton = () => { } } = props || {};
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const d = new Date(props.game_set_start);
  const groupedPoints = _.groupBy(props.points, 'type');
  const point = props?.points
  return (
    <div className={`
      ${props.isMobileGameCenter ? classes.__contest_rules_gamecenter : classes.__contest_rules
      }
    `}>
      {props?.isMobileGameCenter && props?.showDateTime &&
        <div className={classes.__point_system_date_time}>
          {props?.game_set_start} | {props?.start_time} ET
        </div>
      }
      {props.isMobileGameCenter ? (
        <p className={`${classes.__contest_rules_main_title} ${props?.showDateTime ? classes.__contest_rules_main_title_nopadding : ''}`}>
          <span hidden={currencyString === "PWRS"}>{title}</span>
          <span hidden={currencyString === "PWRS"} style={{ color: "#fb6e00", fontWeight: "bold" }}> {["NHL_Fantasy", "Fantasy_Promo", "Fantasy_Challenge", "Fantasy"].indexOf(game_type) !== -1 ? "Fantasy" : "PowerdFS"} </span>
          <span className={classes.subtitle} style={{ opacity: currencyString === "PWRS" ? 1 : 0.5 }}>Gameplay Rules</span></p>
      ) : (
        <p className={currencyString === "PWRS" ? classes.__contest_rules_main_title_pwrs : classes.__contest_rules_main_title}>Gameplay Rules</p>
      )}

      {
        isMobile && currencyString === "PWRS" &&
        getPWRSTitle()
      }

      <ul className={currencyString === "PWRS" ? classes.pwrs : ""}>
        <li>
          <span style={{ color: currencyString === "PWRS" ? "#fb6e00" : "" }}>
            {/* {currency === "BTC" ? '₿' : currency === "ETH" ? 'Ξ' : '$'} */}
            {currency ? currency === 'USD' ? "$" : (
              <img
                style={{ marginRight: 4, width: "auto" }}
                src={getCurrency(currency)}
                height="14px"
                alt=""
                width="14px"
              />
            ) : "$"}
            {props.prize + " "}
          </span>
          Prize Pool
        </li>
        {game_type?.toLowerCase().includes("fantasy") ? "" : <li>Live Play <span style={{ color: currencyString === "PWRS" ? "#fb6e00" : "" }}>Powers</span> included with entry fee</li>}
        <li>Pick players from any teams scheduled to play on <span style={{ color: currencyString === "PWRS" ? "#fb6e00" : "" }}>{monthNames[d.getUTCMonth()]} {("0" + d.getUTCDate()).slice(-2)}, {d.getUTCFullYear()}</span></li>
      </ul>



      <ContestRulesPopUp
        game_type={game_type}
        points={groupedPoints}
        point={point}
        isMyGameCenter={props?.myGameCenter}
        powers={props.powers}
        component={({ showPopUp }) => (
          <a
            onClick={showPopUp}
            href="#"
          >
            See Full Gameplay Rules
          </a>
        )}
        title={title}
      />

      {
        isMobile && currencyString === "PWRS" &&
        <div style={{ width: "327px", position:"absolute", bottom:"43px", left:"20px" }}>
          {pwrsOnEnterButton()}
        </div>
      }
    </div>
  );
};

export default ContestRules;
