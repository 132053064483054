import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import classes from "./index.module.scss";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Header3 from "../../../components/Header3";
import HeaderBgUri from "../../../assets/nhl-live.jpg";
import NHLLiveSportsHeader from "../../../components/NHLLiveSportsHeader";
import Card from "../../../components/PowerpickCard";
import SidebarBtnIcon from "../../../assets/nhl-sidebar-icon.png";
import RankCard from "../../../components/RankCard";
import SportsContestRules from "../../../components/SportsContestRules";
import MLBFooterImage from "../../../assets/NHL.png";
import NHLGear from "../../../assets/nhl-gear.png";
import LiveStandings from "../../../components/LiveStandings";
import { getLocalStorage, redirectTo } from "../../../utility/shared";
import moment from "moment";
import Replace from "../../../icons/Replace";
import {cloneDeep} from "lodash";
import { CONSTANTS } from "../../../utility/constants";
import * as NHLActions from "../../../actions/NHLActions";


let tempCounter = 0;
let lasPos = "";
let filteredData=[]
export default function MyScoreCard() {
  const { allTeamLogs } = useSelector((state) => state.nhl);
  const { swappedPlayers } = useSelector((state) => state.nhl);
  const { user = {} } = useSelector((state) => state.auth);
  const { user_id: userID } = user || {};

  const dispatch = useDispatch();

  // const { live_players } = useSelector((state) => state.nhl);
  // console.log("live_all_team_logs=>",useSelector((state)=>state.nhl));
  useEffect(() => {
    tempCounter = 0;
    filteredData=[];

  }, []);

  useEffect(() => {
    async function getdata(){
    const gameID = getLocalStorage(
      CONSTANTS.LOCAL_STORAGE_KEYS.NHL_LIVE_GAME_ID
    );
    let payload = {
      gameID: gameID,
      userID: userID,
    };
    await dispatch(NHLActions.getFantasyTeam(payload));
    }
    getdata()
  }, [])
  
  const Row = ({
    position,
    name,
    time,
    plays,
    pts,
    totalPts,
    powers,
    score,
    runningTotal,
    sStamp
  }) => (
    <div
      className={`${classes.card_row} ${classes.card_row_1} ${
        score < 0 ? classes.primary_bg : ""
      }`}
    >
      <span className={classes.child_1}>{position}</span>
      <span className={classes.child_2}>{name}</span>
      <span className={classes.child_3}>{sStamp}</span>
      <span className={classes.child_3}>{time}</span>
      <div className={classes.card_combine_row}>
        <span>
          <p className={classes.primary}>{plays}</p>
        </span>
        <span>
          <p className={classes.secondary}> {pts}</p>
        </span>
      </div>
      {/* <span className={`${classes.child_4} ${classes.center}`}><p className={classes.secondary}>{totalPts}</p></span> */}
      <span className={classes.center}>{powers}</span>
      <span className={classes.center}>
        <p className={score < 0 ? classes.danger : classes.success}>
          {score < 0 ? `${score}` : score}
        </p>
      </span>
      <span className={classes.center}>
        <p className={`${classes.primary} ${classes.border}`}>{runningTotal}</p>
      </span>
    </div>
  );
  const RowSwapPlayer = ({
    position,
    name,
    time,
    plays,
    powers,
   
    sStamp
  }) => (
    <div
      className={`${classes.card_row} ${classes.card_row_1}`}
    >
      <span className={classes.child_1}>{position}</span>
      <span className={classes.child_2}>{name}</span>
      <span className={classes.child_3}>{sStamp}</span>
      <span className={classes.child_3}>{time}</span>
      <div className={classes.card_combine_row}>
        <span  >
          <p className={classes.primaryPlayer} style={{ position: "relative" }}><span className={classes.longTxt}>{plays}</span></p>
        </span>
        <span  style={{ opacity: 0, visibility: "hidden" }}>
          <p className={classes.primaryPlayer}>{plays}</p>
        </span>
      </div>
      <span style={{ opacity: 0, visibility: "hidden" }} className={`${classes.child_4} ${classes.center}`}><p className={classes.secondary}></p></span>
      <span style={{ opacity: 0, visibility: "hidden" }}>{powers}</span>
      {/* <span style={{ opacity: 0, visibility: "hidden" }}>
        <p >
        </p>
      </span> */}
      <span style={{ opacity: 0, visibility: "hidden" }}>
        <p></p>
      </span>
     
    </div>
  );

  // const CalculateRow = ({ period, clock, fantasyScores }) => {
  //   return (
  //     <>
  //       {fantasyScores.map((item) => (
  //         <Row
  //           position="P1"
  //           name={item?.player?.full_name}
  //           time={`P${period + 1} | ${clock}`}
  //           plays={item.type === "shotagainst" ? "SA" : item.type[0]}
  //           pts={item?.playerPts}
  //           totalPts="8"
  //           powers="-"
  //           score={16}
  //           runningTotal="16"
  //         />
  //       ))}
  //     </>
  //   );
  // };
  const getPoints = (item,poss) => {
    let pos1 = item?.type === "shotagainst" && item?.saved == true
    ? poss == "G"? "SV": "SA"
    : item?.type === "shotagainst" && item?.saved == false ? 
    "GA" 
    : item?.type === "goalagainst"
    ? "GA"
    :
    item?.newmyscore_description? 
    item?.newmyscore_description[0]:
    item?.myscore_description?
    item?.myscore_description[0]:
    item?.goalScored>0?"G":
    item?.type[0];
    // console.log("pos1===>>", pos1, item);
    if(poss == "TD") {
      if(pos1=="GA"){
        return item?.goalAgainst
      }
      if(pos1=="SA"){
        return item?.shotAgainst
      }
      return item?.teamD;
    }
    else {
      if(pos1 == 'SV'){
        return item?.saves;
      }
      else if(pos1 == 'g') {
        return item?.goalScored;
      }
      else if(pos1 == 's') {
        return item?.newplayerPts ? item?.newplayerPts : item?.shotOnGoal ? item?.shotOnGoal : 0;
      }
      else {
        return item?.newplayerPts ? item?.newplayerPts : item?.playerPts ? item?.playerPts : 0;
      }
    }
  };
  return (
    <>
      <div className={classes.card_header}>
        <div className={classes.card_row}>
          <span className={classes.child_1}>Position</span>
          <span className={classes.child_2}>Name</span>
          <span className={classes.child_3}>Time-Stamp</span>
          <span className={classes.child_3}>Game Clock</span>
          <div className={classes.card_header_1}>
            <p>Scoring Plays</p>
            <div className={classes.card_combine_row}>
              <span>Plays</span>
              <span>Pts</span>
            </div>
          </div>
          {/* <span className={classes.child_4}>Total Pts</span> */}
          <span className={classes.center}>Powers</span>
          <span className={classes.center}>My Score</span>
          <span className={classes.center}>Running Total</span>
        </div>
      </div>

      <div className={classes.card_body}>
        {allTeamLogs?.map((item, index1) => {
          if(!(item?.fantasyPosition == "TeamD" && item?.type !== "shotagainst")){
            let itemData=filteredData?.filter((data)=>data?.eventId==item?.eventId && data?.fantasyPosition==item?.fantasyPosition)
            if(itemData?.length==0){
              filteredData.push(item)
              
              if(item?.goalScored > 0 && item?.shotOnGoal > 0){
                  const newItem = cloneDeep(item);
                  newItem.newmyscore_description="shot"
                  newItem.newplayerPts=3  
                  filteredData.push(newItem)  
                }
                if(item?.goalAgainst < 0 && item?.shotAgainst < 0 &&item?.fantasyPosition == "TeamD"){
                  const newItem = cloneDeep(item);
                  newItem.type="shotagainst"
                  newItem.saved=true

                  newItem.playerPts=-0.5  
                  filteredData.push(newItem)  
                }

              }
          }
          })}
          {
            swappedPlayers?.length>0 && swappedPlayers?.map((item)=>{
              let itemData=filteredData?.filter((data)=>data?.previousPlayerID==item?.previousPlayerID )
              if(itemData?.length==0){
               filteredData?.push(item)
              }
            })
          }
        {
          filteredData.sort(function(a,b){return new Date(a.wall_clock) - new Date(b.wall_clock)})?.map((item,index1)=>{
            if(!item?.previousPlayerID){
             if(!(item?.fantasyPosition == "TeamD" && item?.type !== "shotagainst"))
             {

             let poss = "";
             let i=1;
             
             let allPositionPoints = item?.positionPoints;
             for(const key in allPositionPoints) {
               if(allPositionPoints[key] !== 0) {
                 if(key.toLocaleLowerCase() == "centerpts") {
                   poss = "C";
                 }
                 else if(key.toLocaleLowerCase() == "teamdpts") {
                   poss = "TD";
                 }
                 else if(key.toLocaleLowerCase() == "goaliepts") {
                   poss = "G";
                 }
                 else {
                   poss = key.replace("pts", "");
                 }
               }}
          if(index1 == 0) {
            tempCounter = 0;
          }
          else {
            if(filteredData[index1-i]?.previousPlayerID){
              for(let j=index1-1;j>0;j--){
                if(filteredData[j]?.previousPlayerID){
                  i++;
                }else{
                  break
                }
              }
            }
            if(lasPos === "TD") {
              tempCounter += filteredData[index1-i]?.newplayerPts?filteredData[index1-i]?.newplayerPts:getPoints(filteredData[index1-i],lasPos)
              // tempCounter +=filteredData[index1-i]?.newplayerPts?filteredData[index1-i]?.newplayerPts:filteredData[index1-i]?.teamD;
            }
            else if(lasPos === "G") {
              tempCounter += filteredData[index1-i]?.newplayerPts?filteredData[index1-i]?.newplayerPts:getPoints(filteredData[index1-i],lasPos)
              // tempCounter += filteredData[index1-i]?.newplayerPts?filteredData[index1-i]?.newplayerPts:filteredData[index1-i]?.saves;
            }
            else if(lasPos !== "TD" && lasPos !== "G") {
              // tempCounter += filteredData[index1-i]?.newplayerPts?filteredData[index1-i]?.newplayerPts:filteredData[index1-i]?.playerPts;
              tempCounter += filteredData[index1-i]?.newplayerPts?filteredData[index1-i]?.newplayerPts:getPoints(filteredData[index1-i],lasPos)

              
            }
          }
          lasPos = poss;
          // console.log("lastPos", lasPos, tempCounter,item?.clock);
            return (
              <>
              {/* {poss!="TD" && console.log("item?.fantasyLog?.homeTeamD=2222==>",item?.fantasyLog?.playerPts,tempCounter)} */}
                <Row
                    position={item?.fantasyPosition == "TeamD" ? "TD" : item?.fantasyPosition == "G1" ? "G" : item?.fantasyPosition=="C1"?"C":item?.fantasyPosition}
                    name={poss == "TD" ? item?.teamName :item?.playerName}
                    time={`P${item?.period} | ${item?.clock}`}
                    sStamp={`${moment.utc(item?.wall_clock).local().format("hh:mm:ss A")}`}
                    plays={
                      item?.type === "shotagainst" && item?.saved == true
                        ? poss == "G"? "SV": "SA"
                        : item?.type === "shotagainst" && item?.saved == false ? 
                        "GA" 
                        : item?.type === "goalagainst"
                        ? "GA"
                        :
                        item?.newmyscore_description? 
                        item?.newmyscore_description[0]:
                        item?.myscore_description?
                        item?.myscore_description[0]:
                        item?.goalScored>0?"G":
                        item?.type[0]
                    }
                    pts={getPoints(item,poss)}
                    totalPts="8"
                    powers='-'
                    score={getPoints(item,poss)}
                    runningTotal={getPoints(item,poss) + tempCounter}
                  />
              </>
            );
          }
        }else {
          // swappedPlayers?.length>0 && swappedPlayers?.map((item)=>{
          return (
            <>
              <RowSwapPlayer
                  position={item?.newPlayerPosition == "TeamD" ? "TD" : item?.newPlayerPosition == "G1" ? "G" : item?.newPlayerPosition=="C1"?"C":item?.newPlayerPosition}
                  name={item?.previousPlayerName}
                  time={`P${item?.period + 1} | ${item?.clock}`}
                  sStamp={moment.utc(item?.updated_at).local().format("hh:mm:ss A")}
                  plays={`Player swapped for ${item?.newPlayerData?.full_name}`}
                  // pts={poss == "TD" ? item?.fantasyLog?.homeTeamD : item?.fantasyLog?.playerPts}
                  // totalPts="8"
                  // powers={<Replace
                  //   size={23}
                  // />}
                 
                 
                />
            </>
          );
          // })
        } 
       
        })}
        {/* {
          swappedPlayers?.length>0 && swappedPlayers?.map((item)=>{
          return (
            <>
              <RowSwapPlayer
                  position={item?.newPlayerPosition == "TeamD" ? "TD" : item?.newPlayerPosition == "G1" ? "G" : item?.newPlayerPosition=="C1"?"C":item?.newPlayerPosition}
                  name={item?.newPlayerData?.full_name}
                  time={`P${item?.period + 1} | ${item?.clock}`}
                  sStamp={moment.utc(item?.updated_at).local().format("hh:mm:ss A")}
                  plays={`Player swapped for ${item?.previousPlayerName}`}
                  // pts={poss == "TD" ? item?.fantasyLog?.homeTeamD : item?.fantasyLog?.playerPts}
                  // totalPts="8"
                  // powers={<Replace
                  //   size={23}
                  // />}
                 
                 
                />
            </>
          );
          })} */}
        {/* {live_team_logs?.teamLogs.map((item1, index) => {
          let { teamLogs = [] } = item1;
          
          return (
            <>
              {teamLogs.map((item, index1) => { 
                if(index == 1 && index1 == 0) {
                  tempCounter = 0;
                }
                else {
                  tempCounter += teamLogs[index1-1]?.fantasyLog?.playerPts;
                }
                console.log("index1", index, index1, tempCounter);
                let poss = "P1";
                if(item?.fantasyLog?.fantasyPlayerPosition)
                {
                  if(item?.fantasyLog?.fantasyPlayerPosition === "XW" || item?.fantasyLog?.fantasyPlayerPosition === " D") {
                    poss = item?.fantasyLog?.fantasyPlayerPosition + item?.fantasyLog?.positionID;
                  }
                  else {
                    poss = item?.fantasyLog?.fantasyPlayerPosition;
                  }
                }
                return (
                <Row
                  position={poss}
                  name={item?.fantasyLog?.player?.full_name}
                  time={`P${item?.period + 1} | ${item?.clock}`}
                  plays={
                    item?.fantasyLog?.type === "shotagainst"
                      ? "SA"
                      : item?.fantasyLog?.type === "goalagainst"
                      ? "GA"
                      : item?.fantasyLog?.type[0]
                  }
                  pts={item?.fantasyLog?.playerPts}
                  totalPts="8"
                  powers="-"
                  score={item?.fantasyLog?.playerPts}
                  runningTotal={item?.fantasyLog?.playerPts + tempCounter}
                />
              )})}
            </>
          );
        })} */}
      </div>
    </>
    
  );
}
