import React from 'react';

const Trash = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 10 13" {...props}>
        <g fill="currentColor" fillRule="nonzero">
            <path d="M8.572 17.664l-.411-8.68h7.812l-.411 8.68c-.014.33-.286.59-.617.59H9.188c-.33 0-.602-.26-.616-.59zm4.797-6.524v4.959c0 .138.112.25.25.25h.401c.139 0 .25-.112.25-.25v-4.96c0-.137-.111-.25-.25-.25h-.4c-.139 0-.25.113-.25.25zm-1.753 0v4.959c0 .138.112.25.25.25h.401c.138 0 .25-.112.25-.25v-4.96c0-.137-.112-.25-.25-.25h-.4c-.139 0-.251.113-.251.25zm-1.753 0v4.959c0 .138.112.25.25.25h.4c.14 0 .251-.112.251-.25v-4.96c0-.137-.112-.25-.25-.25h-.4c-.14 0-.251.113-.251.25zm6.384-4.509c.213 0 .387.174.387.387v1.216H7.5V7.018c0-.213.173-.387.387-.387h2.656V6.13c0-.071.058-.129.13-.129h2.788c.072 0 .13.058.13.13v.501h2.656z" transform="translate(-1366 -1032) translate(1083 546) translate(34 164) translate(0 251) translate(0 33) translate(14 14) translate(228 18)" />
        </g>
    </svg>
)

export default Trash;