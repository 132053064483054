import React from "react";
import classes from "./hitters.module.scss";
import _ from "underscore";
import { useHistory } from "react-router-dom";

const data = [
  {
    heading: "Hitters",
    hitters: [
      {
        title: "Single", // play
        value: "+ 3 Pts", // points
      },
      {
        title: "Double",
        value: "+ 5 Pts",
      },
      {
        title: "Tripple",
        value: "+ 8 Pts",
      },
      {
        title: "Home Run",
        value: "+ 10 Pts",
      },
      {
        title: "Run Batted in",
        value: "+ 2 Pts",
      },
      {
        title: "Run",
        value: "+ 2 Pts",
      },
      {
        title: "Base on Balls",
        value: "+ 2 Pts",
      },
      {
        title: "Hit by Pitch",
        value: "+ 2 Pts",
      },
      {
        title: "Stolen Base",
        value: "+ 5 Pts",
      },
    ],
  },
];

const Hitters = ({ PointsSystem, currencyString, game_typem, title }) => {
  const history = useHistory();
  const getKeyData=(keys)=>{
    if(keys.toLowerCase()==="homerun"){
      return "Home Runs"
    }
    if(keys.toLowerCase()==="hitterdouble"){
      return "Double"
    } 
    if(keys.toLowerCase()==="hittersingle"){
      return "Single"
    } 
    if(keys.toLowerCase()==="hittertriple"){
      return "Triple"
    } if(keys.toLowerCase()==="hitterwalk"){
      return "Walk"
    } if(keys.toLowerCase()==="runscored"){
      return "Run Scored"
    } if(keys.toLowerCase()==="stolenbase"){
      return "Stolen Base"
    } if(keys.toLowerCase()==="runsbattedin"){
      return "RBI"
    } if(keys.toLowerCase()==="strikeout"){
      return "Strike Out"
    } if(keys.toLowerCase()==="pitcherwalk"){
      return "Walk "
    } if(keys.toLowerCase()==="runsscoredagainst"){
      return "Runs Against"
    } if(keys.toLowerCase()==="homerunsagainst"){
      return "HR Against "
    }
    return keys
  }
  const groupedPoints = _.groupBy(PointsSystem, "type");
  return (
    <div className={classes.__hitters}>
      <p className={currencyString === "PWRS" ? classes.__hitters_main_title_pwrs : classes.__hitters_main_title}>Point System</p>
      <div className={classes.__hitters_scroll_bar}>
        {
          title === "MLB" && history.location.pathname === "/my-game-center" ? <>
            <div className={`${classes.__point_system_heading} ${classes.__point_game_handling}`}>
              {Object.keys(PointsSystem).map((item) => {
                return (
                  <div hidden={item === "id"}>
                    <div className={classes.__hitters_heading} >{item}</div>
                    {
                      Object.keys(PointsSystem[item]).map((keys) => {
                        return (
                          <div className={classes.__hitters_data}>
                            <div className={classes.__hitters_data_title_div}>
                              <p className={classes.__hitters_data_title}>{
                                getKeyData(keys)
                              }</p>
                            </div>
                            <div className={classes.__hitters_data_value_div}>
                              <p className={classes.__hitters_data_value}>{PointsSystem[item][keys]} Pts</p>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>

                )
              })}

            </div>
          </> :
            Object.keys(groupedPoints).map((d, i) => {
              return (
                <>
                  <div className={classes.__hitters_heading} style={{ color: currencyString === "PWRS" ? "#fb6e00" : "" }}>
                    {Object.keys(groupedPoints)[i]}
                  </div>
                  {groupedPoints[Object.keys(groupedPoints)[i]].map(
                    (item, index) => {
                      return (
                        <div className={classes.__hitters_data}>
                          <div className={classes.__hitters_data_title_div}>
                            <p className={classes.__hitters_data_title}>
                              {item?.plays}
                            </p>
                          </div>
                          <div className={classes.__hitters_data_value_div}>
                            <p className={classes.__hitters_data_value} style={{ color: currencyString === "PWRS" ? "#fb6e00" : "" }}>
                              {item?.action}
                              {item?.points} Pts
                            </p>
                          </div>
                        </div>
                      );
                    }
                  )}
                </>
              );
            })}
      </div>
    </div>
  );
};

export default Hitters;
