import React from 'react';

import styles from "./styles.module.scss";

export const PaymentCardInputs = ({
    onFieldChangeHandler = (e) => { },
    cardFirstName,
    cardLastName,
    CardNumber = '',
    CardExpMonth = '',
    CardExpYear = '',
    CardIDNumber = '',
    price = ''
}) => {
    return (
        <div className={`${styles.card_wrp} w-100 d-block`}>
            <div className="row">
                <div className="col-md-6">
                    <div className={`${styles.card_field} w-100 d-block`}>
                        <h6>Cardholder First Name</h6>
                        <input
                        type="text"
                        name="cardFirstName"
                        placeholder="e.g. John"
                        onChange={onFieldChangeHandler}
                        value={cardFirstName}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className={`${styles.card_field} w-100 d-block`}>
                        <h6>Cardholder Last Name</h6>
                        <input
                        type="text"
                        name="cardLastName"
                        placeholder="e.g. Smith"
                        onChange={onFieldChangeHandler}
                        value={cardLastName}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className={`${styles.card_field} w-100 d-block`}>
                        <h6>Card Number</h6>
                        <input
                        type="number"
                        name="CardNumber"
                        placeholder="e.g. 1234 5678 1234 5678"
                        onChange={onFieldChangeHandler}
                        value={CardNumber}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className={`${styles.card_field} w-100 d-block`}>
                        <h6>Expiry Month</h6>
                        <input
                        type="number"
                        name="CardExpMonth"
                        placeholder="MM"
                        onChange={onFieldChangeHandler}
                        value={CardExpMonth}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className={`${styles.card_field} w-100 d-block`}>
                        <h6>Expiry Year</h6>
                        <input
                        type="number"
                        name="CardExpYear"
                        placeholder="YY"
                        onChange={onFieldChangeHandler}
                        value={CardExpYear}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className={`${styles.card_field} w-100 d-block`}>
                        <h6>CVV</h6>
                        <input
                        type="number"
                        name="CardIDNumber"
                        className={styles.cvvInput}
                        maxLength={3}
                        minLength={3}
                        placeholder="e.g. 123"
                        onChange={onFieldChangeHandler}
                        value={CardIDNumber}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}