export function getApiURL() {
    const server = window.location.hostname;
    if (process.env.NODE_ENV === "development"|| server!=="poweredfs.com") {
      return "https://api.powerplaysystems.com";
      // return "http://localhost:4000"
    }
    return "https://prod-ppgapi.powerplaysystems.com";
  }
  
export function setPersonaRedirect(){
  const server = window.location.hostname;
  if (process.env.NODE_ENV === "development"|| server!=="poweredfs.com") {
    return "https://test.powerplaysystems.com/login";
  }
  return "https://poweredfs.com/login";
}  

export function getFEURL(){
  const server = window.location.hostname;
  if (process.env.NODE_ENV === "development"|| server!=="poweredfs.com") {
    return "https://test.powerplaysystems.com";

  }
  return "https://poweredfs.com";
}  
export function getApiNHLServer() {
  // console.log("hostname==>",window.location.hostname);
  const server = window.location.hostname;
  if (process.env.NODE_ENV === "development"|| server!=="poweredfs.com")  {
    return "https://nhl.powerplaysystems.com";
    // return "http://localhost:4001"

  }
  return "https://prod-nhl.powerplaysystems.com";
}
export function getTeamManagerServer() {
  const server = window.location.hostname;
  if (process.env.NODE_ENV === "development"|| server!=="poweredfs.com")  {
    return "https://nhl.powerplaysystems.com";
  }
  return "https://prod-nhl.powerplaysystems.com";
}