import classes from "./index.module.scss";
import SidebarBtnIcon from "../../assets/nhl-sidebar-icon.png";
import LiveStandings from "../LiveStandings";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { getApiNHL } from "../../config/environment";

export function setNumberComma(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const RightRankBox = ({
    current_winning = 0,
    target = 200,
    prize_to_go = 160,
    game_id = 0,
    onClickStandings = () => {},
    gameType="",
    prizePool="",
    gameStatus="",
}) => 
    {
    const { gameID, liveStandings = [] } = useSelector((state) => state.nhl);
    const { user_id } = useSelector((state) => state.auth.user);

    const [showModal, setModalState] = useState(false);
    const [currentWinnings, setCurrentWinnings] = useState(0);
    const [liveStandingData, setLiveStandingData] = useState([]);

    useEffect(async() => {
        if(gameType.toLowerCase().includes("challenge") && gameID!==0){
            let res = await axios.get(`${getApiNHL()}/api/v1/services/fantasy/getChallengeGameRanking?gameID=${gameID}`);
            if (res.data.code == 200) {
                setLiveStandingData(res.data.finalPoints);
            } else setLiveStandingData(liveStandings)
        }
        else setLiveStandingData(liveStandings)
    },[gameID])
    
    useEffect(() => {
        if(liveStandingData.length > 0) {
            liveStandingData.forEach((element,index) => {
            if(element?._id?.userID == user_id) {
              setCurrentWinnings(element?.prize);
            }
          });
        }
      }, [liveStandingData]);

    useEffect(() => {
        if (gameID) {
            onClickStandings();
        }
    }, [gameID]);
    
    const toggleLiveStandingModal = async () => {
        onClickStandings();
        if (!showModal) {
          setModalState(!showModal);
        }
    };
    
    const closeModal = () => {
        setModalState(false);
    };

    return (
        <div className={classes.rightRankBox_div}>
            <p className={classes.winningTitle}>Currently Winning:</p>
            <div className={classes.prizeDigits}>${currentWinnings 
                ? setNumberComma(currentWinnings ? currentWinnings.toFixed(2) : 0) : 0}</div>
            <div className={classes.pointsDiv}>
                <div className={classes.targetPoints}>
                    <p className={classes.title}>Target:</p>
                    <p className={classes.digit}>{target} Pts</p>
                </div>
                <div className={classes.toGoPoints}>
                    <p className={classes.title}>Points To Go:</p>
                    <p className={classes.digit}>{prize_to_go >= 0 ? prize_to_go : 0} Pts</p>
                </div>
            </div>
            <button onClick={toggleLiveStandingModal}>
                <img
                    src={SidebarBtnIcon}
                    width={19}
                    style={{ marginRight: 5, marginTop: -4 }}
                />{" "}
                See Full Standings
            </button>
            <LiveStandings
                gameType={gameType} 
                targetPoints={target}
                gameStatus={gameStatus}
                isInProgressGame={true}
                visible={showModal}
                onClose={closeModal}
                liveStandingData={liveStandings}
                prizePool={prizePool}
            />
        </div>
    );
};
export default RightRankBox;