import React from 'react';
import classes from './teamRoster.module.scss';
import OrangeBtcCurrency from "../../assets/btc-orange.png";
import OrangeEthCurrency from "../../assets/ethereum-orange.png";
import PWRSIcon from '../../assets/power.png';
import Button from "../Button";
import AceCenterLogo from '../../assets/AceCenterLogo.png';
const data1 = [
    {
        count: 1,
        title: 'P',
        value: '(Pitcher)'
    },
    {
        count: 1,
        title: 'C',
        value: '(Catcher)'
    },
    {
        count: 1,
        title: 'SS',
        value: '(Shortstop)'
    },
];

const data2 = [
    {
        count: 2,
        title: 'xB',
        value: '(1B, 2B or 3B)'
    },
    {
        count: 2,
        title: 'OF',
        value: '(Outfielders)'
    },
    {
        count: 1,
        title: 'Team Defense',
        value: ''
    },
];

const data = [
    {
      type: "MLB",
      heading: "The 8 roster positions:",
      teamRoster: {
          d1: [{
            count: 1,
            title: "P",
            value: "(Pitcher)",
          },
          {
            count: 1,
            title: "C",
            value: "(Catcher)",
          },
          {
            count: 1,
            title: "SS",
            value: "(Shortstop)",
          }
          ],
          d2: [{
            count: 2,
            title: "xB",
            value: "(1B, 2B or 3B)",
          },{
            count: 2,
            title: "OF",
            value: "(Outfielders)",
          },
          {
            count: 1,
            title: "Team Defense",
            value: "",
          }]
        }
    },
    {
      type: "NFL",
      heading: "The 8 roster positions:",
      teamRoster:
          {
              d1: [{
                count: 1,
                title: "QB",
                value: "(Quarterback)",
              },
              {
                count: 2,
                title: "RB",
                value: "(Running Backs)",
              },
              {
                count: 2,
                title: "WR",
                value: "(Wide Receivers)",
              },],
              d2: [{
                count: 1,
                title: "TE",
                value: "(Tight End)",
              },
              {
                count: 1,
                title: "K",
                value: "(Kicker)",
              },
              {
                count: 1,
                title: "Team Defense",
                value: "",
              }]
          }



    },
    {
      type: "NHL",
      heading: "The 8 roster positions:",
      teamRoster:
          {
              d1: [{
                count: 1,
                title: "C",
                value: "(Center)",
              },
              {
                count: 3,
                title: "XW",
                value: "(Wingers)",
              },
              {
                count: 2,
                title: "D",
                value: "(Defensemen)",
              },],
              d2: [{
                count: 1,
                title: "G",
                value: "(Goalie)",
              },
              {
                count: 1,
                title: "Team Defense",
                value: "",
              }]
          }



    },
    {
      type: "NBA",
      heading: "The 8 roster positions:",
      teamRoster:
          {
              d1: [{
                count: 1,
                title: "C",
                value: "(Center)",
              },
              {
                count: 2,
                title: "PG",
                value: "(Point Guard)",
              },
              {
                count: 2,
                title: "SG",
                value: "(Shooting Guard)",
              }],
              d2: [{
                count: 2,
                title: "F",
                value: "(Small/Power Forward)",
              },
              {
                count: 1,
                title: "Team Defense",
                value: "",
              }]
          }



    },
  ];

const TeamRosterAce = (props) => {

    const {
        title = '',
        game_type = '',
        game_set_start = '',
        start_time = '',
        currencyString = '',
        getPWRSTitle=()=>{},
        pwrsOnEnterButton = () => { }
    } = props || {};
    const {
        userHasEntered="",
        total="",
        totalEntries="",
        paid_game="",
        entry_fee="",
        onEnter= () => {},
        PointsSystem,
        currency=""
      } = props;
      console.log("props", props);
      const getEnterCurrency = (currency) => {
        if (currency.toUpperCase() === "PWRS") {
          return PWRSIcon;
        } else if (currency.toUpperCase() === "BTC") {
          return OrangeBtcCurrency;
        } else if (currency.toUpperCase() === "ETH") {
          return OrangeEthCurrency;
        } else if (currency.toUpperCase() === "USD") {
          return "$"
        }
      };
      const aceButtonStyle = {
        width: 0,
        margin: "0px auto 0px auto",
        padding: "8px 131px 7px 132px",
        borderRadius: 6,
        backgroundColor: "#fb6e00",
        minWidth: 'auto',
        fontFamily: "Poppins",
        fontSize: 16,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color:'#111',
      };
    let finalData = data[data.findIndex(x => x.type == title)];

    return (
        <div className={classes.__team_roster}>
            <img src={AceCenterLogo} className={classes.AceCenterLogo}/>
            <p className={classes.topTitle}>Point System</p>
            <div style={{color:"#fb6e00"}} className={classes.__team_roster_heading}>The 8 roster positions:</div>

            <div className={classes.__team_roster_data_container} style={{marginLeft: currencyString === "PWRS" ? "20px" :""}}>
                <div className={classes.__team_roster_data_content}>
                    {
                        finalData?.teamRoster?.d1.map((item, index) => {
                            return (
                                <div className={classes.__team_roster_data} style={{marginBottom: currencyString === "PWRS" ? "0px" :""}} key={index}>
                                    <div className={classes.__team_roster_data_title_div}>
                                    <div className={classes.__team_roster_data_count_div}>
                                            <p className={classes.__team_roster_data_count} style={{
                                                paddingRight: 5
                                            }}>{item.count}</p>
                                        </div>
                                        <p className={classes.__team_roster_data_title} style={{color: "#fb6e00"}}>{item.title}</p>
                                        
                                    </div>
                                    <div className={classes.__team_roster_data_value_div}>
                                        <p className={classes.__team_roster_data_value}  style={{
                                                fontSize: 14
                                            }}>{item.value}</p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <div className={classes?.__team_roster_data_content}>
                    {
                        finalData?.teamRoster?.d2.map((item, index) => {
                            return (
                                <div className={classes.__team_roster_data} key={index}>
                                    <div className={classes.__team_roster_data_title_div}>
                                    <div className={classes.__team_roster_data_count_div}>
                                            <p className={classes.__team_roster_data_count} style={{
                                                paddingRight: 5
                                            }}>{item.count}</p>
                                        </div>
                                        <p className={classes.__team_roster_data_title} style={{color: "#fb6e00"}}>{item.title}</p>
                                       
                                    </div>
                                    {item.value !== "" &&
                                        <div className={classes.__team_roster_data_value_div}>
                                            <p className={classes.__team_roster_data_value} style={{
                                                fontSize: 14
                                            }}>{item.value}</p>
                                        </div>
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className={classes.__power_center_card_enter}>
                {userHasEntered ? (
                        <>
                        <Button title={`Entered`} />
                        </>
                    ) : total && Number(total) === Number(totalEntries) ?
                        <Button title={`Full`} /> :
                        paid_game || paid_game === null ? (
                        <Button
                            currency={getEnterCurrency(currency)}
                            title={`Enter`}
                            entry_fee={entry_fee}
                            onClick={onEnter}
                            styles={aceButtonStyle}
                        />
                        ) : (
                        <Button title={`Enter  •  Free`} onClick={onEnter} />
                        )}
            </div>
        </div>
    );
};

export default TeamRosterAce;
