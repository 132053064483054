import React from 'react';
import classes from './teamRoster.module.scss';

const data1 = [
    {
        count: 1,
        title: 'P',
        value: '(Pitcher)'
    },
    {
        count: 1,
        title: 'C',
        value: '(Catcher)'
    },
    {
        count: 1,
        title: 'SS',
        value: '(Shortstop)'
    },
];

const data2 = [
    {
        count: 2,
        title: 'xB',
        value: '(1B, 2B or 3B)'
    },
    {
        count: 2,
        title: 'OF',
        value: '(Outfielders)'
    },
    {
        count: 1,
        title: 'Team Defense',
        value: ''
    },
];

const data = [
    {
      type: "MLB",
      heading: "The 8 roster positions:",
      teamRoster: {
          d1: [{
            count: 1,
            title: "P",
            value: "(Pitcher)",
          },
          {
            count: 1,
            title: "C",
            value: "(Catcher)",
          },
          {
            count: 1,
            title: "SS",
            value: "(Shortstop)",
          }
          ],
          d2: [{
            count: 2,
            title: "xB",
            value: "(1B, 2B or 3B)",
          },{
            count: 2,
            title: "OF",
            value: "(Outfielders)",
          },
          {
            count: 1,
            title: "Team Defense",
            value: "",
          }]
        }
    },
    {
      type: "NFL",
      heading: "The 8 roster positions:",
      teamRoster:
          {
              d1: [{
                count: 1,
                title: "QB",
                value: "(Quarterback)",
              },
              {
                count: 2,
                title: "RB",
                value: "(Running Backs)",
              },
              {
                count: 2,
                title: "WR",
                value: "(Wide Receivers)",
              },],
              d2: [{
                count: 1,
                title: "TE",
                value: "(Tight End)",
              },
              {
                count: 1,
                title: "K",
                value: "(Kicker)",
              },
              {
                count: 1,
                title: "Team Defense",
                value: "",
              }]
          }



    },
    {
      type: "NHL",
      heading: "The 8 roster positions:",
      teamRoster:
          {
              d1: [{
                count: 1,
                title: "C",
                value: "(Center)",
              },
              {
                count: 3,
                title: "XW",
                value: "(Wingers)",
              },
              {
                count: 2,
                title: "D",
                value: "(Defensemen)",
              },],
              d2: [{
                count: 1,
                title: "G",
                value: "(Goalie)",
              },
              {
                count: 1,
                title: "Team Defense",
                value: "",
              }]
          }



    },
    {
      type: "NBA",
      heading: "The 8 roster positions:",
      teamRoster:
          {
              d1: [{
                count: 1,
                title: "C",
                value: "(Center)",
              },
              {
                count: 2,
                title: "PG",
                value: "(Point Guard)",
              },
              {
                count: 2,
                title: "SG",
                value: "(Shooting Guard)",
              }],
              d2: [{
                count: 2,
                title: "F",
                value: "(Small/Power Forward)",
              },
              {
                count: 1,
                title: "Team Defense",
                value: "",
              }]
          }



    },
  ];

const TeamRoster = (props) => {

    const {
        title = '',
        game_type = '',
        game_set_start = '',
        start_time = '',
        currencyString = '',
        getPWRSTitle=()=>{},
        pwrsOnEnterButton = () => { }
    } = props || {};
    let finalData = data[data.findIndex(x => x.type == title)];

    return (
        <div className={classes.__team_roster}>
            <div className={classes.__team_roster_date_time}>
                {game_set_start} | {start_time} ET
            </div>
            <div className={classes.__my_game_center_card_powerdfs} style={{marginBottom: currencyString === "PWRS" ? "5px" :""}}>
                <p className={`text-left`} >
                    <span hidden={currencyString === "PWRS"} className={classes.__my_game_center_card_powerdfs_title_first} style={{ fontSize: '18px', color: 'white' }}>
                        {title}
                    </span>
                    <span hidden={currencyString === "PWRS"} className={classes.__my_game_center_card_powerdfs_title} style={{ fontSize: '18px' }}> {["NHL_Fantasy", "Fantasy_Promo", "Fantasy_Challenge", "Fantasy"].indexOf(game_type) !== -1 ? "Fantasy" : "PowerdFS"} </span>
                    <span className={`${classes.__my_game_center_card_powerdfs_subtitle}`} style={{ fontSize: '14px', opacity: currencyString === "PWRS" ? 1:0.6 }}>
                        Team Roaster
                    </span>
                </p>
            </div>
            {
                currencyString === "PWRS" &&
                getPWRSTitle()
            }
            <div style={{color:currencyString === "PWRS" ? "#fb6e00" : "", marginLeft: currencyString === "PWRS" ? "20px" :""}} className={classes.__team_roster_heading}>The 8 roster positions:</div>

            <div className={classes.__team_roster_data_container} style={{marginLeft: currencyString === "PWRS" ? "20px" :""}}>
                <div className={classes.__team_roster_data_content}>
                    {
                        finalData?.teamRoster?.d1.map((item, index) => {
                            return (
                                <div className={classes.__team_roster_data} style={{marginBottom: currencyString === "PWRS" ? "0px" :""}} key={index}>
                                    <div className={classes.__team_roster_data_title_div}>
                                        <p className={classes.__team_roster_data_title} style={{color:currencyString === "PWRS" ? "#fb6e00" : ""}}>{item.title}</p>
                                        <div className={classes.__team_roster_data_count_div}>
                                            <p className={classes.__team_roster_data_count}>{item.count}</p>
                                        </div>
                                    </div>
                                    <div className={classes.__team_roster_data_value_div}>
                                        <p className={classes.__team_roster_data_value}>{item.value}</p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <div className={classes?.__team_roster_data_content}>
                    {
                        finalData?.teamRoster?.d2.map((item, index) => {
                            return (
                                <div className={classes.__team_roster_data} key={index}>
                                    <div className={classes.__team_roster_data_title_div}>
                                        <p className={classes.__team_roster_data_title} style={{color:currencyString === "PWRS" ? "#fb6e00" : ""}}>{item.title}</p>
                                        <div className={classes.__team_roster_data_count_div}>
                                            <p className={classes.__team_roster_data_count}>{item.count}</p>
                                        </div>
                                    </div>
                                    {item.value !== "" &&
                                        <div className={classes.__team_roster_data_value_div}>
                                            <p className={classes.__team_roster_data_value}>{item.value}</p>
                                        </div>
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            {
                currencyString === "PWRS" &&
                <div style={{ width: "327px", position: "absolute", bottom: "43px", left: "20px" }}>
                    {pwrsOnEnterButton()}
                </div>
            }
        </div>
    );
};

export default TeamRoster;
