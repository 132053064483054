import React, { useState } from "react";
import classes from "./index.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import MLBPlayer from "../../assets/baseball-player-faded.png";
import MLBPlayerFlipped from "../../assets/image.png";

import NFLPlayer from "../../assets/nfl-player-mobile.png";
import NBAPlayer from "../../assets/nba-player-mobile.png";
import NHLPlayer from "../../assets/nhl-player-mobile.png";
import NHLPlayerFlipped from "../../assets/nhl-player-mobile-left.png";
import InfiniteEntry from "../../assets/invalid-name.svg";

import NHLFantasyPromoBG from "../../assets/NHLFantasyPromoMobileCardBg@2x.png";
import NHLFantasyChallengeBG from "../../assets/NHLFantasyChallengeMobileCardBG@2x.png";
import nhlFantasyPWRSmobile from "../../assets/nhlFantasyPWRSmobile.png";
import mlbFantasyPWRSmobile from "../../assets/mlbFantasyPWRSmobile.png";

import nhlFantasyPWRSmobileDark from "../../assets/nhlFantasyPWRSmobileDark.png";
import mlbFantasyPWRSmobileDark from "../../assets/mlbFantasyPWRSmobileDark.png";

import EnterWithPWRS from '../../assets/enterWithPWRS.png'

import PWRSIcon from '../../assets/power.png'
import ClockIcon from '../../assets/clockIcon.svg'
import PrizeCup from '../../assets/prizecup.svg'

import OrangePowerCurrency from "../../assets/power-orange.png";
import OrangeBtcCurrency from "../../assets/btc-orange.png";
import OrangeEthCurrency from "../../assets/ethereum-orange.png";

import PowerCurrency from "../../assets/power-white.png";
import BtcCurrency from "../../assets/btc-white.png";
import EthCurrency from "../../assets/ethereum-white.png";

import PowerCenterCardDetails from "../PowerCenterCardDetails";
import OutlineButton from "../OutlineButton";
import PrizeGrid from "./PrizeGrid";
import PowersAvailable from "./PowersAvailable";
import PointSystem from "./PointSystem";
import TeamRoster from "./TeamRoster";
import Pitchers from "./Pitcher";
import ContestRules from "../PowerCenterCardDetails/ContestRules";
import "./carousel.scss";
import pwrsCardBanner from '../../assets/pwrsCardBanner.svg'
import AceBgMobile from '../../assets/AceBgMobile.png';
import AceCenterLogo from '../../assets/AceCenterLogo.png';
import Button from "../Button";
import ContestRulesAce from "../PowerCenterCardDetails/ContestRulesChaseTheAce";
import ChaseTheAceDetails from "../PowerCenterCardDetails/ChaseTheAceDetails";
import AceSlide2 from "./AceSlide2";
import ChaseTheAceDetailsMobile from "./ChaseTheDetailsMobile";
import ContestRulesAceMobile from "./ContestRulesAceMobile";
import HittersAce from "./HittersAce";
import PitchersAce from "./PitchersAce";
import TeamRosterAce from "./TeamRoasterAce";
const PowerCenterMobileCard  = (props) => {
  console.log("PowerCenterMobileCard", props);
  const {
    id = null,
    title = "",
    prize = null,
    currency = "$",
    prize_currency = "USD",
    outOf = null,
    total = null,
    game_type = "",
    game_set_start = "",
    start_time = "",
    entry_fee = null,
    paid_game = false,
    targeted_game = false,
    percent = null,
    totalPoints = null,
    PointsSystem = [],
    Power = [],
    PrizePayout = [],
    showDetails = false,
    onDetailsClick = () => { },
    onBackClick = () => { },
    onNextClick = () => { },
    onEnter = () => { },
    userHasEntered = false,
    totalEntries = 0,
    powerdfs_challenge_amount = 0,
    chasePrizePool = 0,
    cardsRemaining = 0
  } = props || {};

  const [currentSlideNumber, setCurrentSlideNumber] = useState(0)

  const getBackgroundImageWithStyle = () => {
    let backgroundImageStyle = {
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "inherit",
    };
    if (title === "MLB" && title !== "PWRS") {
      if (game_type === "Fantasy_Challenge") {
        backgroundImageStyle.backgroundImage = `url(${MLBPlayerFlipped})`;
        backgroundImageStyle.backgroundPosition = "215px -5px";
        backgroundImageStyle.backgroundSize = "contain";
      }
      else if (game_type === "Fantasy_Chase_The_Ace") {
        backgroundImageStyle.backgroundImage = `url(${AceBgMobile})`;
        backgroundImageStyle.backgroundSize = "cover";
      }
      else if (game_type.toLowerCase().includes("fantasy")) {
        backgroundImageStyle.backgroundImage = `url(${MLBPlayer})`;
        backgroundImageStyle.backgroundPosition = "-16px -13px";
        backgroundImageStyle.backgroundSize = "contain";
      }
      else {
        backgroundImageStyle.backgroundImage = `url(${MLBPlayer})`;
        backgroundImageStyle.backgroundPosition = "-16px -13px";
        backgroundImageStyle.backgroundSize = "contain";
      }
    } else if (title === "MLB" && currency === "PWRS" && game_type.toLowerCase().includes("fantasy")) {
      if (currentSlideNumber === 0) {
        backgroundImageStyle.backgroundImage = `url(${mlbFantasyPWRSmobile})`;
      } else {
        backgroundImageStyle.backgroundImage = `url(${mlbFantasyPWRSmobileDark})`;
      }
      backgroundImageStyle.backgroundSize = "contain";
    } else if (title === "NFL") {
      backgroundImageStyle.backgroundImage = `url(${NFLPlayer})`;
      backgroundImageStyle.backgroundPosition = "-16px 10px";
    } else if (title === "NBA") {
      backgroundImageStyle.backgroundImage = `url(${NBAPlayer})`;
      backgroundImageStyle.backgroundPosition = "-157px -4px";
    } else if (title === "NHL" && currency !== "PWRS" && (game_type === "PowerdFs_One" || game_type === "Fantasy_Promo")) {
      backgroundImageStyle.backgroundImage = `url(${NHLFantasyPromoBG})`;
      backgroundImageStyle.backgroundSize = "cover";
    } else if (title === "NHL" && currency !== "PWRS" && (game_type === "Fantasy_Challenge")) {
      backgroundImageStyle.backgroundImage = `url(${NHLFantasyChallengeBG})`;
      backgroundImageStyle.backgroundSize = "cover";
    }
    else if (title === "NHL" && currency !== "PWRS" && (game_type === "PowerdFs_challenge" || game_type === "PowerdFs_promo")) {
      backgroundImageStyle.backgroundImage = `url(${NHLPlayerFlipped})`;
      backgroundImageStyle.backgroundSize = "contain";
      backgroundImageStyle.backgroundPosition = "145px -5px";
    }
    else if (title === "NHL" && currency === "PWRS") {
      if (currentSlideNumber === 0) {
        backgroundImageStyle.backgroundImage = `url(${nhlFantasyPWRSmobile})`;
      } else {
        backgroundImageStyle.backgroundImage = `url(${nhlFantasyPWRSmobileDark})`;
      }
      // backgroundImageStyle.backgroundPosition = "-16px -13px";
      backgroundImageStyle.backgroundSize = "contain";
    } else {
      backgroundImageStyle.backgroundImage = `url(${NHLPlayer})`;
      backgroundImageStyle.backgroundPosition = "0px 27px";
    }
    return backgroundImageStyle;
  };

  const getPWRSTitle = () => {
    return (
      <div className={classes.__pwers_header_title}>
        <img src={pwrsCardBanner} alt="pwrsCardBanner" />
        <p>
          {title}{" "}
          {["NHL_Fantasy", "Fantasy_Promo", "Fantasy_Challenge", "Fantasy"].indexOf(game_type) !== -1 ? "Fantasy" : "PowerdFS"}
        </p>
      </div>
    )
  }

  const numberWithCommas = (x) => {
    if (x >= 10000) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return x;
  };

  const getCurrency = (currency) => {
    if (currency.toUpperCase() === "BTC") {
      return BtcCurrency;
    } else if (currency.toUpperCase() === "ETH") {
      return EthCurrency;
    } else if (currency.toUpperCase() === "PWRS") {
      return PowerCurrency;
    }
  };

  const getEnterCurrency = (currency) => {
    if (currency.toUpperCase() === "PWRS") {
      return PWRSIcon;
    } else if (currency.toUpperCase() === "BTC") {
      return OrangeBtcCurrency;
    } else if (currency.toUpperCase() === "ETH") {
      return OrangeEthCurrency;
    } else if (currency.toUpperCase() === "USD") {
      return "$"
    }
  };

  const pwrsOnEnterButton = () => {
    return (
      <div className={classes.__power_center_card_enter} style={{ width: "100%" }}>
        {userHasEntered ? (
          <>
            <OutlineButton styles={{ width: "90%", background: "#fb6e00", color: "#000" }} title={`Entered`} />
          </>
        ) : total && Number(total) === Number(totalEntries) ?
          <OutlineButton styles={{ width: "90%", background: "#fb6e00", color: "#000" }} title={`Full`} /> :
          paid_game || paid_game === null ? (
            <OutlineButton
              styles={{ width: "90%", background: "#fb6e00", color: "#000" }}
              currency={getEnterCurrency(currency)}
              title={`Enter  •  `}
              entry_fee={entry_fee}
              onClick={onEnter}
            />
          ) : (
            <OutlineButton styles={{ width: "90%", background: "#fb6e00", color: "#000" }} title={`Enter  •  Free`} onClick={onEnter} />
          )}
      </div>
    )
  }
  const aceButtonStyle = {
    width: 0,
    margin: "10px auto 0px auto",
    padding: "8px 131px 7px 132px",
    borderRadius: 6,
    backgroundColor: "#fb6e00",
    minWidth: 'auto',
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color:'#111',
  };
  return !showDetails ? (
    <div
      className={classes.__power_center_card}
      style={getBackgroundImageWithStyle()}
    >
      {currency === "PWRS" ?

        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          autoPlay={false}
          infiniteLoop={true}
          interval={300000}
          className={`carousel control-dots` + classes.pwrs_li}
          swipeScrollTolerance={100}
          onChange={(e) => setCurrentSlideNumber(e)}
        >
          {currency === "PWRS" &&

            <div className={classes.__power_center_card_container_pwrs}>
              <div className={classes.__power_center_card_date_time_pwrs}>
                {game_set_start} | {start_time} ET
              </div>
              <img className={classes.__PWRSEnterImage} src={EnterWithPWRS} alt="Enter with pwers" />
              {/* {getPWRSBannerByLeague(title)} */}
              <div className={classes.__PWRSBannerImageDiv}>
                <img className={classes.__PWRSBannerImage} src={pwrsCardBanner} alt="pwrsCardBanner" />
                <p>
                  <span>{title}{" "}</span>
                  {["NHL_Fantasy", "Fantasy_Promo", "Fantasy_Challenge", "Fantasy"].indexOf(game_type) !== -1 ? "Fantasy" : "PowerdFS"}
                </p>
              </div>
              <div>
                <p className={classes.__PWRS_prize_pool_prize}>
                  {currency === "USD" ? (
                    `$`
                  ) : currency === "PWRS" ? (
                    prize_currency === "USD" ? (
                      `$`
                    ) : (
                      <img src={getCurrency(prize_currency)} style={{ width: "22px", margin: "9px 0px" }} alt="" />
                    )
                  ) : (
                    <img src={getCurrency(currency)} style={{ width: "22px", margin: "9px 0px" }} alt="" />
                  )}
                  {prize}
                </p>
                <p className={classes.__PWRS_prize_pool_text}>Prize Pool</p>
              </div>

              <div className={classes.__pwrs_card_total}>
                {total || total == null ? (
                  <p>
                    {totalEntries} <span>of {total}</span>
                  </p>
                ) : (
                  <p>
                    {totalEntries}{" "}
                    <span>
                      of <img src={InfiniteEntry} alt="infinite entry" />
                    </span>
                  </p>
                )}
              </div>
            </div>

          }

          {(game_type == "Fantasy_Promo" || game_type == "Fantasy_Challenge") && currency !== "PWRS" ? (
            <div className={classes.__power_center_card_container}>
              <div className={classes.fantasyCard}>
                <div className={classes.__power_center_card_date_time_fantasy}>
                  {game_set_start} | {start_time} ET
                </div>
                <p className={classes.__power_center_card_powerdfs_title}>
                  <span
                    className={classes.__power_center_card_powerdfs_title_first}
                  >
                    {title}
                  </span>{" "}
                  Fantasy
                </p>
                <p className={classes.__power_center_card_powerdfs_title}>
                  <span
                    className={classes.__power_center_card_powerdfs_title_first}
                  >
                    {game_type == "Fantasy_Challenge" ? powerdfs_challenge_amount + " Point Challenge" : "Manager Challenge"}
                  </span>
                </p>
                <div className={classes.__power_center_card_prize_pool}>
                  {game_type == "Fantasy_Challenge" ? (
                    <p className={classes.manage_your_team_to}>
                      If your team gets  {powerdfs_challenge_amount} points you win!
                    </p>
                  ) : (
                    <span className={classes.manage_your_team_to}>
                      Manage your team to victory and win!
                    </span>
                  )}
                  <p
                    className={
                      classes.__power_center_card_prize_pool_price
                    }
                  >
                    {currency === "USD" ? (
                      `$`
                    ) : currency === "PWRS" ? (
                      prize_currency === "USD" ? (
                        `$`
                      ) : (
                        <img src={getCurrency(prize_currency)} style={{ width: "22px", margin: "9px 0px" }} alt="" />
                      )
                    ) : (
                      <img src={getCurrency(currency)} style={{ width: "22px", margin: "9px 0px" }} alt="" />
                    )}
                    {prize}
                  </p>
                  <p
                    className={
                      classes.__my_game_center_card_prize_pool_common +
                      " " +
                      classes.__my_game_center_card_prize_pool_text
                    }
                  >
                    Prize Pool
                  </p>
                  <div className={classes.__power_center_card_enter}>
                    {userHasEntered ? (
                      <>
                        <OutlineButton title={`Entered`} />
                      </>
                    ) : total && Number(total) === Number(totalEntries) ?
                      <OutlineButton title={`Full`} />
                      : (game_type == "Fantasy_Promo" || game_type == "Fantasy_Challenge") ?
                        <OutlineButton
                          //currency={getEnterCurrency(currency)}
                          title={`Go to Entry Page`}
                          //entry_fee={}
                          onClick={onEnter}
                        /> : paid_game || paid_game === null ? (
                          <OutlineButton
                            currencyString={currency}
                            currency={getEnterCurrency(currency)}
                            title={`Enter  •  `}
                            entry_fee={entry_fee}
                            onClick={onEnter}
                          />
                        ) : (
                          <OutlineButton title={`Enter  •  Free`} onClick={onEnter} />
                        )}
                  </div>
                  <span className={classes.__power_center_card_game_id}>ID {id}</span>
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.__power_center_card_container}>

              <div className={classes.__power_center_card_content} style={{ paddingRight: "0px" }}>
                <span className={classes.__power_center_card_game_id} style={{ margin: "5px 22px" }}>ID {id}</span>
                {getPWRSTitle()}
                <div className={classes.__pwers_title}>
                  Pick Today <br />
                  & Win Today!
                </div>
                <div className={classes.__pwers__date_time}>
                  {game_set_start} | {start_time} ET
                </div>
                <div className={classes.____PWRSCardInfo}>
                  <ul>
                    <li><img src={PrizeCup} alt="PrizeCup" />{
                      prize_currency === "USD" ? (
                        `$`
                      ) : (
                        <img src={getCurrency(prize_currency)} width="20" alt="" />
                      )
                    } {numberWithCommas(prize)} Prize Pool</li>
                    <li><img src={ClockIcon} alt="ClockIcon" />{game_set_start} | {start_time} ET</li>
                    <li><img src={PWRSIcon} alt="PWRSIcon" />Enter with PWRS!</li>
                  </ul>
                </div>
                {pwrsOnEnterButton()}
                {/* <div className={classes.__power_center_card_enter} style={{ width: "100%" }}>
                  {userHasEntered ? (
                    <>
                      <OutlineButton styles={{ width: "90%", background: "#fb6e00", color: "#000" }} title={`Entered`} />
                    </>
                  ) : total && Number(total) === Number(totalEntries) ?
                    <OutlineButton styles={{ width: "90%", background: "#fb6e00", color: "#000" }} title={`Full`} /> :
                    paid_game || paid_game === null ? (
                      <OutlineButton
                        styles={{ width: "90%", background: "#fb6e00", color: "#000" }}
                        currency={getEnterCurrency(currency)}
                        title={`Enter  •  `}
                        entry_fee={entry_fee}
                        onClick={onEnter}
                      />
                    ) : (
                      <OutlineButton styles={{ width: "90%", background: "#fb6e00", color: "#000" }} title={`Enter  •  Free`} onClick={onEnter} />
                    )}
                </div> */}
              </div>
            </div>
          )}

          <ContestRules
            pwrsOnEnterButton={pwrsOnEnterButton}
            getPWRSTitle={getPWRSTitle}
            isMobile={true}
            currencyString={currency}
            currency={currency === "PWRS" ? prize_currency : currency}
            title={title}
            game_type={game_type}
            game_set_start={game_set_start}
            start_time={start_time}
            prize={prize}
            powers={Power}
            points={PointsSystem}
            isMobileGameCenter={true}
            showDateTime={true}
          />
          <PrizeGrid
            pwrsOnEnterButton={pwrsOnEnterButton}
            getPWRSTitle={getPWRSTitle}
            title={title}
            currencyString={currency}
            currency={currency === "PWRS" ? prize_currency : currency}
            game_type={game_type}
            PrizePayout={PrizePayout}
            game_set_start={game_set_start}
            start_time={start_time}
          />
          <PowersAvailable
            getPWRSTitle={getPWRSTitle}
            currencyString={currency}
            game_type={game_type}
            title={title}
            Power={Power}
            game_set_start={game_set_start}
            start_time={start_time}
          />
          <PointSystem
            pwrsOnEnterButton={pwrsOnEnterButton}
            getPWRSTitle={getPWRSTitle}
            currencyString={currency}
            game_type={game_type}
            title={title}
            PointsSystem={PointsSystem}
            game_set_start={game_set_start}
            start_time={start_time}
          />
          <Pitchers
            pwrsOnEnterButton={pwrsOnEnterButton}
            getPWRSTitle={getPWRSTitle}
            currencyString={currency}
            title={title}
            // showDateTime={false}
            game_type={game_type}
            PointsSystem={PointsSystem}
            game_set_start={game_set_start}
            start_time={start_time}
          />
          <TeamRoster
            pwrsOnEnterButton={pwrsOnEnterButton}
            getPWRSTitle={getPWRSTitle}
            currencyString={currency}
            title={title}
            game_type={game_type}
            game_set_start={game_set_start}
            start_time={start_time}
          />
        </Carousel>

        : 
        game_type == "Fantasy_Chase_The_Ace" ? 
        
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          autoPlay={false}
          infiniteLoop={true}
          interval={300000}
          className=".carousel .control-dots"
          swipeScrollTolerance={100}
        >
         <div className={classes.__power_center_card_container} style={{
              flexDirection: "column"
            }}>
              <div className={classes.__power_center_card_total_ace}>
                  {total || total == null ? (
                    <p>
                      {totalEntries} <span>of {total}</span>
                    </p>
                  ) : (
                    <p>
                      {totalEntries}{" "}
                      <span>
                        of <img src={InfiniteEntry} alt="infinite entry" />
                      </span>
                    </p>
                  )}
                </div>
              <div className={classes.cardsRemaining}>
                Cards Remaining: {cardsRemaining}
              </div>
              <img src={AceCenterLogo} className={classes.AceCenterLogo}/>
              <p className={classes.prizePoolAce}>Prize Pool</p>
              <p className={classes.prizeAce}>
                {currency === "USD" ? (
                      `$`
                    ) : currency === "PWRS" ? (
                      prize_currency === "USD" ? (
                        `$`
                      ) : (
                        <img src={getCurrency(prize_currency)} style={{ width: "22px", margin: "9px 0px" }} alt="" />
                      )
                    ) : (
                      <img src={getCurrency(currency)} style={{ width: "22px", margin: "9px 0px" }} alt="" />
                    )}
                    {numberWithCommas(chasePrizePool)}
              </p>
              {userHasEntered ? (
                    <>
                      <Button title={`Entered`} />
                    </>
                  ) : total && Number(total) === Number(totalEntries) ?
                    <Button title={`Full`} /> :
                    paid_game || paid_game === null ? (
                      <Button
                        currency={getEnterCurrency(currency)}
                        title={`Enter`}
                        entry_fee={entry_fee}
                        onClick={onEnter}
                        styles={aceButtonStyle}
                      />
                    ) : (
                      <Button title={`Enter  •  Free`} onClick={onEnter} />
                    )}
              <p className={classes.bottomCornerText}>No purchase<br />necessary</p>
            </div>
          
          
            <AceSlide2
            game_type={game_type}
            title={title}
            game_set_start={game_set_start}
            start_time={start_time} 
            userHasEntered={userHasEntered}
            currency={currency}
            total={total}
            totalEntries={totalEntries}
            paid_game={paid_game}
            entry_fee={entry_fee}
            onEnter={onEnter}
            />

<ChaseTheAceDetailsMobile
           game_type={game_type}
           title={title}
           game_set_start={game_set_start}
           start_time={start_time} 
           userHasEntered={userHasEntered}
           currency={currency}
           total={total}
           totalEntries={totalEntries}
           paid_game={paid_game}
           entry_fee={entry_fee}
           onEnter={onEnter}
          />


<ContestRulesAceMobile
            currency={currency === "PWRS" ? prize_currency : currency}
            title={title}
            game_type={game_type}
            game_set_start={game_set_start}
            start_time={start_time}
            prize={prize}
            powers={Power}
            points={PointsSystem}
            isMobileGameCenter={true}
            showDateTime={true}
            userHasEntered={userHasEntered}
           total={total}
           totalEntries={totalEntries}
           paid_game={paid_game}
           entry_fee={entry_fee}
           onEnter={onEnter}
          />


          
<HittersAce
            game_type={game_type}
            title={title}
            game_set_start={game_set_start}
            start_time={start_time} 
            userHasEntered={userHasEntered}
            currency={currency}
            total={total}
            totalEntries={totalEntries}
            paid_game={paid_game}
            entry_fee={entry_fee}
            onEnter={onEnter}
            PointsSystem={PointsSystem}
          />
          <PitchersAce
            game_type={game_type}
            title={title}
            game_set_start={game_set_start}
            start_time={start_time} 
            userHasEntered={userHasEntered}
            currency={currency}
            total={total}
            totalEntries={totalEntries}
            paid_game={paid_game}
            entry_fee={entry_fee}
            onEnter={onEnter}
            PointsSystem={PointsSystem}
          />
          <TeamRosterAce
            game_type={game_type}
            title={title}
            game_set_start={game_set_start}
            start_time={start_time} 
            userHasEntered={userHasEntered}
            currency={currency}
            total={total}
            totalEntries={totalEntries}
            paid_game={paid_game}
            entry_fee={entry_fee}
            onEnter={onEnter}
          />

          
          
          
        </Carousel>

        :
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          autoPlay={false}
          infiniteLoop={true}
          interval={300000}
          className=".carousel .control-dots"
          swipeScrollTolerance={100}
        >
          {(game_type == "Fantasy_Promo"|| game_type == "Fantasy_Challenge" || game_type == "PowerdFs_challenge" || game_type == "PowerdFs_promo") ? (
            <div className={classes.__power_center_card_container}>
              <div className={classes.fantasyCard}>
                <div className={classes.__power_center_card_date_time_fantasy}>
                  {game_set_start} | {start_time} ET
                </div>
                {!game_type.toLowerCase().includes("fantasy") &&
                  <div className={classes.__power_center_card_you_have_power}>
                    You have the <br />
                    Powers to win!
                  </div>
                }
                <p className={classes.__power_center_card_powerdfs_title}>
                  <span
                    className={classes.__power_center_card_powerdfs_title_first}
                  >
                    {title}
                  </span>{" "}
                  {game_type.toLowerCase().includes("fantasy") ? "Fantasy" : "PowerdFs"}
                </p>
                <p className={classes.__power_center_card_powerdfs_title}>
                  <span
                    className={classes.__power_center_card_powerdfs_title_first}
                  >
                    {game_type == "Fantasy_Challenge" ? powerdfs_challenge_amount + " Point Challenge" : "Manager Challenge"}
                  </span>
                </p>
                <div className={classes.__power_center_card_prize_pool}>
                  {game_type.toLowerCase().includes("challenge") ? (
                    <p className={classes.manage_your_team_to}>
                      If your team gets  {powerdfs_challenge_amount} points you win!
                    </p>
                  ) : (
                    <span className={classes.manage_your_team_to}>
                      Manage your team to victory and win!
                    </span>
                  )}
                  <p
                    className={
                      classes.__power_center_card_prize_pool_price
                    }
                  >
                    {currency === "USD" ? (
                      `$`
                    ) : currency === "PWRS" ? (
                      prize_currency === "USD" ? (
                        `$`
                      ) : (
                        <img src={getCurrency(prize_currency)} style={{ width: "22px", margin: "9px 0px" }} alt="" />
                      )
                    ) : (
                      <img src={getCurrency(currency)} style={{ width: "22px", margin: "9px 0px" }} alt="" />
                    )}
                    {prize}
                  </p>
                  <p
                    className={
                      classes.__my_game_center_card_prize_pool_common +
                      " " +
                      classes.__my_game_center_card_prize_pool_text
                    }
                  >
                    Prize Pool
                  </p>
                  <div className={classes.__power_center_card_enter}>
                    {userHasEntered ? (
                      <>
                        <OutlineButton title={`Entered`} />
                      </>
                    ) : total && Number(total) === Number(totalEntries) ?
                      <OutlineButton title={`Full`} />
                      : (game_type == "Fantasy_Promo" || game_type == "Fantasy_Challenge") ?
                        <OutlineButton
                          //currency={getEnterCurrency(currency)}
                          title={`Go to Entry Page`}
                          //entry_fee={}
                          onClick={onEnter}
                        /> : paid_game || paid_game === null ? (
                          <OutlineButton
                            currency={getEnterCurrency(currency)}
                            title={`Enter  •  `}
                            entry_fee={entry_fee}
                            onClick={onEnter}
                          />
                        ) : (
                          <OutlineButton title={`Enter  •  Free`} onClick={onEnter} />
                        )}
                  </div>
                  <span className={classes.__power_center_card_game_id}>ID {id}</span>
                </div>
              </div>
            </div>
          ) : game_type == "Fantasy_Chase_The_Ace" ? (
            <div className={classes.__power_center_card_container} style={{
              flexDirection: "column"
            }}>
              <div className={classes.__power_center_card_total_ace}>
                  {total || total == null ? (
                    <p>
                      {totalEntries} <span>of {total}</span>
                    </p>
                  ) : (
                    <p>
                      {totalEntries}{" "}
                      <span>
                        of <img src={InfiniteEntry} alt="infinite entry" />
                      </span>
                    </p>
                  )}
                </div>
              <div className={classes.cardsRemaining}>
                Cards Remaining: {cardsRemaining}
              </div>
              <img src={AceCenterLogo} className={classes.AceCenterLogo}/>
              <p className={classes.prizePoolAce}>Prize Pool</p>
              <p className={classes.prizeAce}>
                {currency === "USD" ? (
                      `$`
                    ) : currency === "PWRS" ? (
                      prize_currency === "USD" ? (
                        `$`
                      ) : (
                        <img src={getCurrency(prize_currency)} style={{ width: "22px", margin: "9px 0px" }} alt="" />
                      )
                    ) : (
                      <img src={getCurrency(currency)} style={{ width: "22px", margin: "9px 0px" }} alt="" />
                    )}
                    {chasePrizePool}
              </p>
              {userHasEntered ? (
                    <>
                      <Button title={`Entered`} />
                    </>
                  ) : total && Number(total) === Number(totalEntries) ?
                    <Button title={`Full`} /> :
                    paid_game || paid_game === null ? (
                      <Button
                        currency={getEnterCurrency(currency)}
                        title={`Enter`}
                        entry_fee={entry_fee}
                        onClick={onEnter}
                        styles={aceButtonStyle}
                      />
                    ) : (
                      <Button title={`Enter  •  Free`} onClick={onEnter} />
                    )}
              <p className={classes.bottomCornerText}>No purchase<br />necessary</p>
            </div>
          ) : (
            <div className={classes.__power_center_card_container} >
              <div style={{ flex: 1 }}></div>
              <div className={classes.__power_center_card_content}>
                <span className={classes.__power_center_card_game_id}>ID {id}</span>
                <div className={classes.__power_center_card_date_time}>
                  {game_set_start} | {start_time} ET
                </div>
                <div className={classes.__power_center_card_powerdfs}>
                  <p className={classes.__power_center_card_powerdfs_title}>
                    <span
                      className={classes.__power_center_card_powerdfs_title_first}
                    >
                      {title}
                    </span>{" "}
                    {/* {title !== "Levels" && "PowerdFS"} */}
                    {game_type.toLowerCase().includes("fantasy") ? "Fantasy" : "PowerdFS"}
                    <span className={classes.__power_center_card_powerdfs_title_first}>{game_type == "Fantasy_Challenge" && "200 Challenge Points"}</span>
                  </p>
                </div>
                <div className={classes.__power_center_card_prize_pool}>
                  <p
                    className={
                      classes.__power_center_card_prize_pool_common +
                      " " +
                      classes.__power_center_card_prize_pool_price
                    }
                  >
                    {currency === "USD" ? (
                      `$`
                    ) : currency === "PWRS" ? (
                      prize_currency === "USD" ? (
                        `$`
                      ) : (
                        <img src={getCurrency(prize_currency)} style={{ width: "22px", margin: "9px 0px" }} alt="" />
                      )
                    ) : (
                      <img src={getCurrency(currency)} style={{ width: "22px", margin: "9px 0px" }} alt="" />
                    )}
                    {prize}
                  </p>
                  <p
                    className={
                      classes.__power_center_card_prize_pool_common +
                      " " +
                      classes.__power_center_card_prize_pool_text
                    }
                  >
                    Prize Pool
                  </p>
                </div>
                <div className={classes.__power_center_card_total}>
                  {total || total == null ? (
                    <p>
                      {totalEntries} <span>of {total}</span>
                    </p>
                  ) : (
                    <p>
                      {totalEntries}{" "}
                      <span>
                        of <img src={InfiniteEntry} alt="infinite entry" />
                      </span>
                    </p>
                  )}
                </div>
                <div className={classes.__power_center_card_enter}>
                  {userHasEntered ? (
                    <>
                      <OutlineButton title={`Entered`} />
                    </>
                  ) : total && Number(total) === Number(totalEntries) ?
                    <OutlineButton title={`Full`} /> :
                    paid_game || paid_game === null ? (
                      <OutlineButton
                        currency={getEnterCurrency(currency)}
                        title={`Enter  •  `}
                        entry_fee={entry_fee}
                        onClick={onEnter}
                      />
                    ) : (
                      <OutlineButton title={`Enter  •  Free`} onClick={onEnter} />
                    )}
                </div>
              </div>
            </div>
          )}
          
          
          

          <ContestRules
          currency={currency === "PWRS" ? prize_currency : currency}
          title={title}
          game_type={game_type}
          game_set_start={game_set_start}
          start_time={start_time}
          prize={prize}
          powers={Power}
          points={PointsSystem}
          isMobileGameCenter={true}
          showDateTime={true}
        />

<PrizeGrid
            title={title}
            currency={currency === "PWRS" ? prize_currency : currency}
            game_type={game_type}
            PrizePayout={PrizePayout}
            game_set_start={game_set_start}
            start_time={start_time}
          />
          <PowersAvailable
            game_type={game_type}
            title={title}
            Power={Power}
            game_set_start={game_set_start}
            start_time={start_time}
          />
          
        <PointSystem
          game_type={game_type}
          title={title}
          PointsSystem={PointsSystem}
          game_set_start={game_set_start}
          start_time={start_time}
        />
        <Pitchers
          title={title}
          game_type={game_type}
          PointsSystem={PointsSystem}
          game_set_start={game_set_start}
          start_time={start_time}
        />
         <TeamRoster
          title={title}
          game_type={game_type}
          game_set_start={game_set_start}
          start_time={start_time}
        />

          
          
          
        </Carousel>
      }

    </div>
  ) : (
    <PowerCenterCardDetails
      Power={Power}
      PrizePayout={PrizePayout}
      PointsSystem={PointsSystem}
      entry_fee={entry_fee}
      title={title}
      onBackClick={() => onBackClick()}
      onNextClick={() => onNextClick()}
      onEnter={onEnter}
      prize={prize}
      userHasEntered={userHasEntered}
      game_set_start={game_set_start}
    />
  );
};

export default PowerCenterMobileCard;
