import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import classes from "./index.module.scss";
import CalenderIcon from "../../icons/Calendar2";
import StadiumIcon from "../../icons/Stadium2";
import Tick2 from "../../icons/Tick2";
import PowerPlayIcon from "../../assets/token.png";
import StarIcon from "../../icons/Star";
import TDShirtImage from "../../assets/td.png";
import TDBadge from "../../assets/tdBadge.png";
import ForwardArrow from "../../icons/ForwardArrow";
import { isEmpty } from "lodash";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import DeleteIcon from "../../assets/delete.png";
import PlusIcon from "../../assets/plus.png";
import ClockIcon from "../../icons/Clock2";
import DeleteIcon2 from "../../assets/delete.svg";
import checkIcon from "../../assets/check2.svg"

// function SportsTeamSelectionCard(props) {
//   const selector_all_data = useSelector((state) => state?.nfl?.allData);
//   const [currentStep, setCurrentStep] = useState(0);

//   const {
//     item = {},
//     loading = false,
//     onSelectDeselect = (team_id, matchId) => {},
//     disabled = false,
//     isSelected = false,
//     btnTitle = "+ Select",
//     btnIcon = "",
//     nflCard = false,
//   } = props || {};

//   const {
//     team_id = "",
//     name = "",
//     teamBName = "",
//     teamBCity = "",
//     city = "",
//     nfl_team_season_stats: awayTeamStats = [],
//     date = "",
//     time = "",
//     steps = [],
//     playerStats = {},
//     match_id,
//     venue = {},
//   } = item || {};

//   const mlbStates = item.nfl_team_season_stats;

//   const { venue_id = "", name: stadium = "" } = venue || {};

//   const {
//     wins = 0,
//     loses = 0,
//     average_runs_against = 0,
//   } = awayTeamStats[0] || {};

//   const qbDetails = (match_id) => {
//     let temp = [];
//     for (let i = 0; i < selector_all_data.length; i++) {
//       let rec = selector_all_data[i];
//       if (rec.match_id == match_id) {
//         if (rec.type == "QB" || rec.type == "qb") {
//           temp.push(rec);
//         }
//       }
//     }
//     return temp;
//   };

//   const RenderMLBState = (team_action) => {
//     let match_id = props?.item?.match_id;
//     let team_id = props?.item?.team_id;
//     let qbArray = qbDetails(match_id);

//     let a;
//     if (team_action.team == "home") {
//       if (qbArray[0]?.homeTeam == team_action.name) {
//         a = qbArray[0];
//       }
//       if (qbArray[1]?.homeTeam == team_action.name) {
//         a = qbArray[1];
//       }
//     }
//     if (team_action.team == "away") {
//       if (qbArray[0]?.homeTeam == team_action.name) {
//         a = qbArray[0];
//       }
//       if (qbArray[1]?.homeTeam == team_action.name) {
//         a = qbArray[1];
//       }
//     }
//     return (
//       <div
//         className={`${classes.card_state_mlb} ${isSelected && classes.active}`}
//       >
//         <div>
//           <p>
//             <span>QB</span> {a?.playerName}
//           </p>
//         </div>
//       </div>
//     );
//   };

//   const RenderOtherState = () => (
//     <div className={`${classes.card_state} ${isSelected && classes.active}`}>
//       <div className={classes.card_state_title}>
//         <span>W</span>
//         <span>L</span>
//         <span>ARA</span>
//         <span>FPPG</span>
//       </div>
//       <div className={classes.card_state_values}>
//         <span>{wins}</span>
//         <span>{loses}</span>
//         <span>{0}</span>
//         <span>{0}</span>
//       </div>
//     </div>
//   );

//   const nextStep = () => {
//     let _currentStep = currentStep;
//     if (currentStep < steps?.length - 1) {
//       _currentStep++;
//     } else {
//       _currentStep = 0;
//     }

//     setCurrentStep(_currentStep);
//   };

//   return (
//     <div
//       className={`${classes.container_body_card} ${nflCard && classes.inset}`}
//       className={`${classes.container_body_card} ${nflCard && classes.inset} ${
//         isSelected ? classes.activeBorder : ""
//       }`}
//     >
//       <div className={classes.container_body_card_1}>
//         <div className={classes.container_body_left}>
//           {/* {isStarPlayer && (
//             <span
//               className={`${classes.container_body_card_start_power} ${
//                 nflCard && classes.inset
//               }`}
//             >
//               {" "}
//               {nflCard ? (
//                 <StarIcon solidcolor="#000" />
//               ) : (
//                 <img src={PowerPlayIcon} />
//               )}{" "}
//               Star Power{" "}
//             </span>
//           )} */}
//           <div
//             className={`${classes.container_body_card_header} ${
//               isSelected ? classes.header_flex : ""
//             }`}
//           >
//             <p
//               className={`${classes.container_selected_p} ${
//                 isSelected ? classes.active : ""
//               }`}
//             >
//               <span>{city}</span> {name}
//             </p>
//             {!isSelected ? (
//               <button
//                 onClick={() => onSelectDeselect(team_id, match_id)}
//                 className={disabled && classes.disabled}
//                 disabled={disabled}
//               >
//                 {" "}
//                 + Select
//               </button>
//             ) : (
//               <div className={classes.container_selected}>
//                 <p className={classes.container_selected_p_1}>
//                   <Tick2 />{" "}
//                 </p>
//                 <img
//                   src={DeleteIcon}
//                   onClick={() => onSelectDeselect(team_id, match_id)}
//                 />
//               </div>
//             )}
//           </div>
//           {nflCard && currentStep === 1 && (
//             <div className={classes.card_mlb_vs}>
//               <p>Vs {teamBCity + " " + teamBName}</p>
//             </div>
//           )}

//           <div
//             className={`
//                 ${classes.container_body_card_state} 
//                 ${isSelected && classes.active}`}
//           >
//             {nflCard && currentStep === 1 ? (
//               <RenderMLBState team="home" name={name} />
//             ) : isEmpty(playerStats) ? (
//               <RenderMLBState team="home" name={name} />
//             ) : (
//               <p>No Data</p>
//             )}
//           </div>

//           {currentStep === 0 && (
//             <>
//               <div
//                 className={`${classes.team_vs} ${
//                   isSelected ? classes.selectedColor : ""
//                 }`}
//               >
//                 <p>Vs {teamBCity + " " + teamBName}</p>
//               </div>
//               <div
//                 className={`
//                 ${classes.container_body_card_state} 
//                 ${isSelected && classes.active}`}
//               >
//                 {nflCard && currentStep === 1 ? (
//                   <RenderMLBState team="away" name={teamBName} />
//                 ) : isEmpty(playerStats) ? (
//                   <RenderMLBState team="away" name={teamBName} />
//                 ) : (
//                   <p>No Data</p>
//                 )}
//               </div>
//             </>
//           )}
//           {/* <div className={classes.divider}></div> */}
//         </div>

//         <div className={classes.container_body_right}>
//           <div className={classes.right_img}>
//             <img src={TDShirtImage} />
//           </div>
//           <img src={TDBadge} className={classes.img_badge} />
//           <p>Get Your Gear!</p>
//         </div>
//       </div>

//       <div className={classes.container_card_footer_main}>
//         <div className={classes.card_footer}>
//           <p className={classes.container_body_footer}>
//             <span>
//               <ClockIcon /> {time}
//             </span>
//             <span>
//               <CalenderIcon /> {date}
//             </span>
//             <span>
//               <StadiumIcon /> {stadium}
//             </span>
//           </p>
//         </div>

//         {/* {nflCard && (
//           <div className={classes.forwardArrow} onClick={nextStep}>
//             <ForwardArrow color="#fb6e00" />
//           </div>
//         )} */}
//       </div>
//     </div>
//   );
// }
const defaultTitles = ["GP", "W", "L", "APA"];

function SportsTeamSelectionCard(props) {
  const selector_all_data = useSelector((state) => state?.mlb?.allData);
  const [currentStep, setCurrentStep] = useState(0);
  const {
    item = {},
    loading = false,
    onSelectDeselect = (id, matchId) => {},
    disabled = false,
    isSelected = false,
    btnTitle = "+ Select",
    btnIcon = "",
    nflCard = false,
    image,
    
  } = props || {};
  const {
    id = "",
    name = "",
    market = "",
    nhl_team_season_stats: awayTeamStats = [],
    steps = [],
    playerStats = {},
    match_id,
    venue = {},
    teamStats = {},
    teamB = {},
    matchVenue = {},
    matchScheduled = "",
    is_starTeamD,
    is_starCategory=0
  } = item || {};
  const isMobile = useMediaQuery({ query: "(max-width: 414px)" });
  const { name: stadium = "" } = matchVenue || {};
  const { name: teamBName } = teamB;
  const date = moment(matchScheduled).format("yyyy-MM-DD"),
    time = moment(matchScheduled).format("h:mm A");
    const RenderItem = ({ value }) => <span>{value}</span>;

    const RenderDefault = () => (
      <>
        <div className={classes.card_state_title}>
          {defaultTitles?.map((title, index) => (
            <span key={index.toString()} className={classes.state_step_1_title}>
              {title}
            </span>
          ))}
        </div>
  
        <div className={classes.card_state_values}>
        <RenderItem value={0}/>
        <RenderItem value={0}/>    
        <RenderItem value={0}/>
        <RenderItem value={0}/>
        </div>
      </>
    );
    const RenderData = () => {
          return <RenderDefault />;
      }
  return (
    <div
      className={`${classes.container_body_card} ${nflCard && classes.inset}  ${isMobile && classes.mobile_team_d} 
      
      ${isMobile && classes.mobile_team_d}
      ${
        isSelected ? classes.activeBorder : ""
      }`}
    >
      <div className={classes.container_body_card_1}>
        <div className={classes.container_body_left}>
          {/* {is_starTeamD && (
            <span
              className={`${classes.container_body_card_start_power} ${
                nflCard && classes.inset
              }`}
            >
              {" "}
              {nflCard ? (
                <StarIcon solidcolor="#000" />
              ) : (
                <img src={PowerPlayIcon} />
              )}{" "}
              Star Power{" "}
            </span>
          )} */}
          {is_starTeamD ? (
            <span className={classes.container_body_card_start_power}>
              <>
                {/* <StarIcon solidcolor="#000" /> <p>Star Power</p> */}
                {
                  is_starCategory === 3 ?
                    (<><StarIcon solidcolor="#000" /><StarIcon solidcolor="#000" /><StarIcon solidcolor="#000" /> <p>Star Power</p> </>)
                    :
                    is_starCategory === 2 ?
                      (<><StarIcon solidcolor="#000" /><StarIcon solidcolor="#000" /> <p>Star Power</p> </>)
                      :
                      (<><StarIcon solidcolor="#000" /> <p>Star Power</p> </>)
                }
              </>
            </span>
          ) : null}
          <div
            className={`${classes.container_body_card_header} ${
              isSelected ? classes.header_flex : ""
            }`}
          >
            <p
              className={`${classes.container_selected_p} ${
                isSelected ? classes.active : ""
              }`}
            >
              {!isMobile? !isSelected ? (
              <>
              <img
                onClick={() => onSelectDeselect(id, match_id)}
                // className={disabled && classes.disabled}
                src={PlusIcon}
                disabled={disabled}
                style = {btnTitle !== "Swap" ? {
                  marginRight: 5,
                  position: 'relative',
                  top: '-3px'
                } : {}}
              />
                {/* {" "}
                + Select
              </button> */}
              </>
            ) : (
              // <div className={classes.container_selected}>
              //   <p className={classes.container_selected_p_1}>
              //     <Tick2 />{" "}
              //   </p>
                <img
                  src={DeleteIcon}
                  onClick={() => onSelectDeselect(id, match_id)}
                  style = {btnTitle !== "Swap" ? {
                    marginRight: 5,
                    position: 'relative',
                    top: '-3px'
                  } : {}}
                />
              // </div>
            ):<></>}
              <span>{market} {name}{item?.isFromHome &&!item?.isFromAway?('(H)'):('(A)')}</span> 
                    {/* <div title={getProjectedPitcherColor(id, 'tooltip')} style={{
                      width: 10, height: 10, background: getProjectedPitcherColor(id, 'color'), borderRadius: 100, display: 'inline-block', marginLeft: 5
                    }}></div> */}
                    
            </p>
            
          </div>
          {nflCard && currentStep === 1 && (
            <div className={classes.card_mlb_vs}>
              <p>Vs {teamB?.market} {teamBName}</p>
            </div>
          )}
          <div
            className={`
                ${classes.container_body_card_state} 
                ${isSelected && classes.active}`}
          >
              
          <div className={classes.team_state_point}>
          <div className={`${classes.card_state} ${isSelected ? classes.active : ""
             }`}>
            {RenderData()}
           </div>
        </div>
          </div>
         
            
          {currentStep === 0 && (
            <>
              <div
                className={`${classes.team_vs} ${
                  isSelected ? classes.selectedColor : ""
                }`}
              >
                <p>Vs {teamB?.market} {teamBName}</p>
              </div>
              <div
                className={`
                ${classes.container_body_card_state} 
                ${isSelected && classes.active}`}
              >
                {/* {nflCard && currentStep === 1 ? (
                  <RenderMLBState team="away" name={teamBName} />
                ) : isEmpty(playerStats) ? (
                  <RenderMLBState team="away" name={teamBName} />
                ) : (
                  <p>No Data</p>
                )} */}
              </div>
            </>
          )}
           <div
            className={`
                ${classes.container_body_card_state} 
                ${isSelected && classes.active}`}
          >
            {/* {nflCard && currentStep === 1 ? (
              <RenderMLBState team="home" name={name} />
            ) : isEmpty(playerStats) ? (
              <RenderMLBState team="home" name={name} />
            ) */}
            

            {/* <NHLTeamStats teamStats={teamStats} /> */}
          </div>
        </div>
        

        <div className={classes.container_body_right}>
          {image?.length>0 && (
          <div onClick={()=> window.open(image[0].trackingLink, "_blank")}>
            <div className={classes.right_img}>
              <img src={require(`../../assets/mlb_teamd/${image[0].shortName}.png`).default } alt={TDShirtImage}  />
              {/* <img src={TDShirtImage} alt="" /> */}
            </div>
          </div>

          )}
            {image?.length==0 && (
          <div className={classes.right_img}>
            <img src={TDShirtImage} alt="" />
          </div>
          )}
          {/* <img src={TDBadge} className={classes.img_badge} alt=""/>
          <p>Get Your Gear!</p> */}
        </div>
      </div>

      <div className={classes.container_card_footer_main}>
        <div className={classes.card_footer}>
          <div className={classes.divider}></div>
          <p className={classes.container_body_footer}>
            <span>
              <ClockIcon /> {time}
            </span>
            <span>
              <CalenderIcon /> {date}
            </span>
            <span>
              <StadiumIcon /> {stadium}
            </span>
          </p>
        </div>

        {/* {nflCard && (
          <div className={classes.forwardArrow} onClick={nextStep}>
            <ForwardArrow color="#fb6e00" />
          </div>
        )} */}
          
      </div>
      {isMobile&&
       <div className={classes.footer_selected}>
       <p
           className={`${classes.container_selected_p} ${isSelected ? classes.active : ""
             }`}
         >
      {
        nflCard ? (      
                <>
                {!isSelected ? (
                  <>
                    <button 
                      style={{position: btnTitle !== "+ Select" ? 'absolute':'relative',
                        right: btnTitle !== "+ Select" ? '16px': '', 
                        fontSize: 12,
                        fontWeight: 600, 
                        backgroundColor: btnTitle === "+ Select" ? 'transparent':'',
                        height: btnTitle === "+ Select" ? '0':'',
                        width: btnTitle === "+ Select" ? '0':'',
                        display: btnTitle === "+ Select" ? 'contents' : ''}}
                        onClick={() => {
                          if (nflCard) {
                              onSelectDeselect(id, match_id);
                          }
                      }}>
                      + Select
                      {btnTitle !== "+ Select" && btnTitle}
                    </button>

                {/* {btnIcon && btnIcon} {btnTitle || "+"}
              </button> */}
              </>
            ) : (
              // <div className={classes.container_selected}>
              //   <p className={classes.container_selected_p_1}>
              //     <Tick2 /> Selected{" "}
                <div className={classes.footer__after_selected}>
                  <p>
                    <img
                      src={checkIcon}
                    />
                    Selected
                  </p>
                  <img
                    src={DeleteIcon2}
                    style = {btnTitle !== "Swap" ? {
                      // backgroundColor: "#f2f2f233",
                      // border: "none",
                      // color: "#a9a9a9",
                      // fontSize: 12,
                      // fontWeight: 600,
                      // width: 84,
                      // height: 28,
                      // borderRadius: 4,
                      // display: "flex",
                      // alignItems: "center",
                      // justifyContent: "space-evenly"
                    } : {}}
                    onClick={() => onSelectDeselect(id, match_id)}
                  />
                </div>
              //   </p>
              // </div>
            )}
                 
                </>
              ) : (
                <>
                </>
              )
        }</p></div>
      }
    </div>
  );
}

SportsTeamSelectionCard.propTypes = {
  item: {},
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  nflCard: PropTypes.bool,
  steps: PropTypes.array,
  onSelectDeselect: PropTypes.func,
};

export default SportsTeamSelectionCard;
