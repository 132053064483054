import { getApiURL } from '../../../config/apiURL';
import http from '../../../config/http';

import { getCaptchaToken } from "./captcha"

/**
 * @param  {number} amount - Payment amount (USD)
 * @returns {Promise<string>} Link to the payment page
 */
async function getLink(amount) {
    const captchaToken = await getCaptchaToken("DEPOSIT")

    const apiUrl = getApiURL()
    const response = await http.post(
        `${apiUrl}/api/v1/users/coinbase-link-generate`, { amount, captchaToken }
    )

    if (!response.data.success) {
        console.error(response.data.error)
        throw new Error("Could not get a link")
    } else {
        return response.data.url
    }
}

const paymentHandler = {
    type: "crypto",
    handler: getLink
}

export default paymentHandler