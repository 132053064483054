import React from 'react';

const Phone = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g fill="currentColor" fillRule="nonzero">
            <path d="M19.582 14.582c-.533-.311-1.155-.4-1.644-.267-.355.09-.666.311-.888.667-.445.577-1.022 1.2-1.156 1.288-1.066.71-1.91.622-2.798-.267l-4.677-4.717c-.022-.03-.047-.058-.077-.08l-.016-.014-.295-.297c-.888-.933-.977-1.733-.266-2.8.133-.132.755-.71 1.288-1.154.311-.222.578-.533.666-.889.134-.488 0-1.066-.31-1.643-.09-.134-1.91-3.244-3.599-4.132-.844-.444-1.91-.267-2.577.4l-1.11 1.11C-.1 4.01-.587 6.585.746 9.43c.444.932 1.11 1.865 1.954 2.71l9.107 9.107c.711.71 1.422 1.288 2.177 1.688 1.244.71 2.488 1.066 3.643 1.066 1.644 0 3.199-.71 4.576-2.088l1.11-1.155c.711-.666.845-1.733.4-2.577-.888-1.688-3.998-3.51-4.131-3.598zm3.065 5.597l-1.11 1.111c-2.089 2.088-4.443 2.399-7.153.889-.666-.356-1.333-.889-1.955-1.51l-9.107-9.152c-.8-.8-1.377-1.644-1.777-2.488C.39 6.54.79 4.364 2.745 2.409l1.11-1.11c.4-.4 1.022-.489 1.466-.222 1.466.755 3.243 3.731 3.243 3.776.222.355.311.71.222.977-.044.133-.133.267-.31.355l-.045.045c-.355.355-1.288 1.11-1.466 1.377-.258.38-.434.758-.529 1.13-.306-.616-.55-1.327-.626-2.107-.044-.267-.222-.445-.489-.4-.266.044-.444.266-.4.489.311 3.11 2.755 5.108 2.844 5.197l4.709 4.71c1.2 1.199 2.488 1.332 3.91.4.31-.178 1.065-1.067 1.376-1.467v-.044c.09-.178.178-.267.356-.311.266-.045.622.044.977.222 0 0 3.02 1.777 3.776 3.243.267.489.178 1.11-.222 1.51z" transform="translate(-739 -698) translate(519 690) translate(220 8)" />
            <path d="M17.805 18.225c-2.71-.267-4.576-2.088-4.576-2.088-.178-.178-.444-.178-.622 0-.178.177-.178.444 0 .622.044.089 2.044 2.043 5.153 2.354.223 0 .4-.177.445-.4 0-.266-.178-.444-.4-.488z" transform="translate(-739 -698) translate(519 690) translate(220 8)" />
        </g>
    </svg>
)

export default Phone;