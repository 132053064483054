import React, { Component } from "react";
import { connect } from 'react-redux'

import ChooseItem from "../../ui/ChooseItem/ChooseItem";
import styles from "./styles.module.scss";
import Ethereum from "../../assets/ethereum_small.png";

import elavonPaymentHandler from "./payment-handlers/elavon-payment-handler";
import coinbasePaymentHandler from "./payment-handlers/coinbase-payment-handler";
import PaymentForm from "./PaymentForm";


function getPaymentHandler(currency) {
  if (currency !== "USD") return coinbasePaymentHandler

  return elavonPaymentHandler
}

const values = [
  { value: 25, title: "$25" },
  { value: 100, title: "$100" },
  { value: 250, title: "$250" },
  { value: 500, title: "$500" },
]

const currencies = {
  USD: {
    title: (
      <>
        <b>$</b> USD
      </>
    ),
  },
  BTC: {
    title: (
      <>
        <b>₿</b> BTC
      </>
    ),
  },
  ETH: {
    title: (
      <>
        <img src={Ethereum} alt="" className={styles.EthereumImage} width={'15px'} height={'24px'} /> ETH
      </>
    ),
  },
}

class DepositAmountForm extends Component {
  state = {
    BTC: 58680,
    ETH: 2092,
    form: {
      currency: this.props.formCurrency,
      price: this.props.formCurrency === "USD" ? 25 : (this.props.formCurrency === "BTC" ? 0.0005 : 0.015),
      paymentMetod:
        this.props.country === "Canada" ? "EFT" : "Credit or Debit Card",
      walletAddress: "",
    },
    isOtherAmount: false,
    city: this.props.city,
    address: this.props.address,
    phoneNumber: this.props.phoneNumber,
    zip: this.props.zip,
    currency: this.props.currency ? this.props.currency : "USD",
    country: this.props.country,
    CardIDNumber: this.props.CardIDNumber,
    CardNumber: this.props.CardNumber,
    CardExpYear: this.props.CardExpYear,
    expiredate: this.props.expiredate,
    cardFirstName: this.props.cardFirstName,
    cardLastName: this.props.cardLastName,
    canadianVisible: this.props.country === "Canada",
    dispatch: this.props.dispatch,
    currentAmount: 25,
    paymentInfo: this.props.paymentInfo
  };

  onCurrencyChange = (e) => {
    const { value } = e.target;
    const newForm = { ...this.state.form };
    newForm.currency = value;
    newForm.price = values[0].value;
    this.setState({ form: newForm });
  };

  onPriceChange = (e) => {
    const { value, type } = e.target;
    const newForm = { ...this.state.form };
    newForm.price = +value;
    this.setState({ form: newForm, isOtherAmount: type === "number", currentAmount: value });
  };

  onPaymentMethodChange = (e) => {
    const newForm = { ...this.state.form };
    newForm.paymentMetod = e.target.value;
    this.setState({ form: newForm });
  };

  onWalletAddressChange = (e) => {
    const newForm = { ...this.state.form };
    newForm.walletAddress = e.target.value;
    this.setState({ form: newForm });
  };

  onFieldChangeHandler = (e) => {
    if(e.target.name==="CardExpMonth" && e.target.value >= 0 && e.target.value <=12 || e.target.value===""){
      this.setState({
        [e.target.name]: (e.target.value.substring(e.target.value.length - 2)),
      });
    }
    if(e.target.name==="CardExpYear" && e.target.value >= 0 && e.target.value <=99 || e.target.value===""){
      this.setState({
        [e.target.name]: (e.target.value.substring(e.target.value.length - 2)),
      });
    } 
    if(e.target.name==="CardIDNumber" && e.target.value >= 0 && e.target.value <=999 || e.target.value===""){
      this.setState({
        [e.target.name]: (e.target.value.substring(e.target.value.length - 3)),
      });
    }
    if (["cardFirstName", "cardLastName", "CardNumber"].includes(e.target.name)) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  render() {
    const { currency, price } = this.state.form;
    const { isOtherAmount, cardFirstName, cardLastName, CardNumber, CardIDNumber, CardExpMonth, CardExpYear } = this.state;
    const paymentHandler = getPaymentHandler(currency)

    return (
      <>
        <section className={styles.formSection}>
          <h6>Select Currency</h6>
          <div className="row align-items-center">
            {Object.keys(currencies).map((key, index) => (
              <div className="col-auto mx-0 my-2 px-1">
                <ChooseItem
                  name="currency"
                  title={currencies[key].title}
                  value={key}
                  key={index}
                  checked={currency === key}
                  onChange={this.onCurrencyChange}
                />
              </div>
            ))}
          </div>
        </section>
        <section className={`${styles.formSection}`}>
          <h6>Select Amount (USD)</h6>
          <div className="row align-items-center">
            {values.map((data, index) => (
              <div className="col-auto mx-0 my-2 px-1">
                <ChooseItem
                  name="price"
                  key={index}
                  onChange={this.onPriceChange}
                  {...data}
                  checked={!isOtherAmount && price === data.value}
                />
              </div>
            ))}
            <div className="col-auto mx-0 my-2 px-1">
              <ChooseItem
                name="price"
                title="Other"
                helperText="Your Amount"
                type="number"
                onChange={this.onPriceChange}
                value={isOtherAmount ? price : ""}
              />
            </div>
          </div>
        </section>
        {
          paymentHandler
            ? (
              <PaymentForm
                amount={price}
                number={CardNumber}
                expirationMonth={CardExpMonth}
                expirationYear={CardExpYear}
                cvv={CardIDNumber}
                paymentHandler={paymentHandler}
                onSuccess={this.props.onSuccess}
                onDeclined={this.props.onDeclined}
                onPending={this.props.onPending}
                didChangeField={this.onFieldChangeHandler}
                firstName={cardFirstName}
                lastName={cardLastName}
              />
            )
            : (
              <button className={`${styles.submitbtn} w-100 d-block`} disabled>Coming Soon !</button>
            )
        }
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(DepositAmountForm)

