import React from 'react';

const Time = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props?.size || "28"} height={props?.size || "28"} viewBox="0 0 23 28" {...props}>
    <defs>
        <linearGradient id="grsd9zyrta" x1="50%" x2="50%" y1="100%" y2="0%">
            <stop offset="0%" stopColor="#ADDCFF"/>
            <stop offset="50.28%" stopColor="#EAF6FF"/>
            <stop offset="100%" stopColor="#EAF6FF"/>
        </linearGradient>
        <linearGradient id="zq1u1ki6kb" x1="50%" x2="50%" y1="100%" y2="0%">
            <stop offset="0%" stopColor="#FFB355"/>
            <stop offset="100%" stopColor="#F40"/>
        </linearGradient>
    </defs>
    <g fill="none">
        <path fill="url(#grsd9zyrta)" d="M21.835 4.7L19.59 2.707c-.31-.276-.785-.248-1.06.063-.275.31-.245.783.064 1.059l.56.498-1.49 1.677c-.276.31-.248.784.063 1.058.3.27.776.254 1.059-.062l1.49-1.676.562.498c.306.273.783.25 1.059-.063.275-.31.246-.783-.063-1.059zm-19.612.623L3.713 7c.283.316.758.332 1.06.062.31-.274.338-.749.062-1.058l-1.49-1.677.56-.498c.31-.276.339-.75.063-1.06-.275-.31-.751-.338-1.059-.062L.665 4.7c-.31.276-.338.75-.063 1.06.276.311.753.335 1.06.062l.56-.499zm10.55-.604c.165-.005.326-.064.456-.172.171-.143.271-.353.271-.577V1.5h.75c.415 0 .75-.335.75-.75S14.665 0 14.25 0h-6c-.415 0-.75.335-.75.75s.335.75.75.75H9v2.47c0 .224.1.434.271.577.128.107.286.167.45.173C5.067 5.457 1.5 9.494 1.5 14.35c0 5.376 4.374 9.75 9.75 9.75S21 19.726 21 14.35c0-4.858-3.572-8.898-8.228-9.63zm-1.523-.108c-.248 0-.494.02-.738.056.474-.076 1.002-.076 1.476 0-.244-.037-.49-.056-.738-.056z" transform="translate(0 1)"/>
        <path fill="url(#zq1u1ki6kb)" d="M13.72 10.82l-3 3c-.293.293-.293.767 0 1.06.293.293.767.293 1.06 0l3-3c.293-.293.293-.767 0-1.06-.293-.293-.767-.293-1.06 0zm-.835-7.585c-.945-.18-2.355-.18-3.24-.015C4.14 4 0 8.785 0 14.35 0 20.56 5.04 25.6 11.25 25.6S22.5 20.56 22.5 14.35c0-5.565-4.14-10.35-9.615-11.115zM12 22.528V20.35c0-.415-.335-.75-.75-.75s-.75.335-.75.75v2.212c-3.948-.359-7.103-3.514-7.462-7.462H5.25c.415 0 .75-.335.75-.75s-.335-.75-.75-.75H3.038c.359-3.948 3.514-7.103 7.462-7.462V8.35c0 .415.335.75.75.75s.75-.335.75-.75V6.172c3.943.363 7.104 3.483 7.462 7.428H17.25c-.415 0-.75.335-.75.75s.335.75.75.75h2.212c-.358 3.944-3.52 7.065-7.462 7.428z" transform="translate(0 1)"/>
    </g>
</svg>
)

export default Time;