import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import classes from "./pointSystem.module.scss";
import _ from "underscore";
import { useMediaQuery } from "react-responsive";

const ChaseTheAceDetails = (props) => {
  const history = useHistory();
  const { title = "", PointsSystem = [], game_type = '', currencyString='' } = props || {};
  const groupedPoints = _.groupBy(PointsSystem, 'type');
  const typeOne = Object.keys(groupedPoints);
  const isMobile = useMediaQuery({ query: "(max-width: 414px)" });
  return (
    <div style={{height: currencyString==="PWRS" ? "280px": ""}} className={`${classes.chase_point_system}`}>
        <ul>
            <li><span>1</span> <p>After each contest, the winner will <b>pick a card</b> from a playing card deck on a live video call.</p> </li>
            <li><span>2</span><p>If the Ace of Spades is selected, you <b>win the prize pool.</b> </p></li>
            <li><span>3</span><p>If the If the Ace of Spades is not selected, the unclaimed prize pool carries over to the next contest and the # of cards in the deck is reduced by one.</p></li>
            <li><span>4</span><p><b>The prize pool  keeps growing</b> until we have a winner!</p></li>
        </ul>
    </div>
  );
};

export default ChaseTheAceDetails;
