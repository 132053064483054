import React from "react";
import PropTypes from "prop-types";

function XP1_5(props) {
  const { active = false, size = 0 } = props || {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || "40"}
      height={size || "40"}
      viewBox="0 0 40 40"
      style={{ height: "auto" }}
      {...props}
    >
      <defs>
        <linearGradient
          id="kfcrd4go2a"
          x1="35.019%"
          x2="67.721%"
          y1="39.758%"
          y2="84.553%"
        >
          <stop offset="0%" stopColor="#FB6E00" />
          <stop offset="100%" stopColor="#FA3800" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-193 -938) translate(174 919) translate(19 19)">
                  <circle cx="20" cy="20" r="20" fill="url(#kfcrd4go2a)" />
                  <g fillRule="nonzero">
                    <g>
                      <path
                        fill="#ECECEC"
                        d="M12.187 9.419l-3.23-3.23 3.23-3.23c.123-.122.191-.288.191-.461 0-.173-.068-.339-.19-.461L10.341.19c-.255-.255-.668-.255-.923 0l-3.23 3.23L2.96.19c-.254-.255-.668-.255-.922 0L.19 2.037c-.255.254-.255.668 0 .922l3.23 3.23L.19 9.42c-.255.255-.255.668 0 .923l1.846 1.845c.122.123.288.191.461.191.173 0 .34-.068.462-.19l3.23-3.23 3.229 3.23c.122.122.288.19.461.19.173 0 .34-.068.462-.19l1.845-1.846c.255-.255.255-.668 0-.923z"
                        transform="translate(9.908 4.475) translate(4.468 .317)"
                      />
                      <path
                        fill="#FFF"
                        d="M.191 10.342l1.846 1.845c.122.123.288.191.461.191.173 0 .34-.068.461-.19l3.23-3.23V3.42L2.96.19c-.254-.255-.668-.255-.922 0L.19 2.037c-.255.254-.255.668 0 .922l3.23 3.23L.19 9.42c-.255.255-.255.668 0 .923z"
                        transform="translate(9.908 4.475) translate(4.468 .317)"
                      />
                    </g>
                    <path
                      fill="#FFF"
                      d="M5.591 28.2V15.948H.634v2.773h1.838v9.48h3.12zm4.594 0v-2.963H7.117V28.2h3.068zm6.603.035c1.364 0 2.421-.39 3.172-1.17.751-.78 1.127-1.84 1.127-3.18 0-.74-.133-1.416-.399-2.028-.265-.612-.676-1.1-1.23-1.465-.555-.364-1.243-.546-2.063-.546-.508 0-.98.078-1.413.234-.433.156-.77.373-1.014.65v-2.478h5.512v-2.6h-8.198v7.418h2.877c.093-.265.266-.482.52-.65.254-.167.572-.251.953-.251.497 0 .884.144 1.162.433.277.29.416.7.416 1.231 0 .555-.13 1-.39 1.335-.26.335-.65.502-1.17.502-.45 0-.806-.127-1.066-.381s-.42-.578-.477-.97h-2.894c.023.773.22 1.455.589 2.045.37.589.895 1.048 1.577 1.377.682.33 1.485.494 2.41.494z"
                      transform="translate(9.908 4.475)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

XP1_5.propTypes = {
  size: PropTypes.number,
  active: PropTypes.bool,
};

export default XP1_5;
