import React from "react";
import PropTypes from "prop-types";

import classes from "./playerStat.module.scss";
import {
  addTrailingZerons,
  removeZeroBeforeDecimalPoint,
} from "../../utility/shared";

const defaultTitles = ["AVG", "HR", "RBI", "OPS"];
const titlesP = ["ERA", "W-L", "K/9", "WHIP"];

function MLBPlayerStat(props) {
  const { active = false, playerStats = {}, position = "" } = props || {};
  const {
    hits = 0,
    doubles = 0,
    triples = 0,
    home_runs = 0,
    stolen_bases = 0,
    runs_batted_in = 0,
    batting_average = 0,
    wins = 0,
    losses = 0,
    innings_pitched = 0,
    strike_outs = 0,
    earned_runs_average = 0,
    base_on_balls = 0,
    walks_hits_per_innings_pitched = 0,
    ops = 0,
    
  } = playerStats || {};

  const getTwoDecimal = (value) => {
    if (value !== 0) return parseFloat(value).toFixed(2);

    return value;
  };

  const RenderItem = ({ value }) => <span>{value}</span>;

  const RenderDefault = () => (
    <>
      <div className={classes.card_state_title}>
        {defaultTitles?.map((title, index) => (
          <span key={index.toString()} className={classes.state_step_1_title}>
            {title}
          </span>
        ))}
      </div>

      <div className={classes.card_state_values}>
        <RenderItem
          value={
            playerStats?.statistics?.hitting?.overall?.avg ? playerStats?.statistics?.hitting?.overall?.avg !== 0
              ? addTrailingZerons(playerStats?.statistics?.hitting?.overall?.avg) < 1 ?
              `.${
                  addTrailingZerons(playerStats?.statistics?.hitting?.overall?.avg)?.toString()?.split(".")[1]
              }` : addTrailingZerons(playerStats?.statistics?.hitting?.overall?.avg)
              : playerStats?.statistics?.hitting?.overall?.avg:0
          }
        />
        <RenderItem value={playerStats?.statistics?.hitting?.overall?.onbase?.hr?playerStats?.statistics?.hitting?.overall?.onbase?.hr:0} />
        <RenderItem value={playerStats?.statistics?.hitting?.overall?.rbi?playerStats?.statistics?.hitting?.overall?.rbi:0} />
        <RenderItem value={playerStats?.statistics?.hitting?.overall?.ops?removeZeroBeforeDecimalPoint(playerStats?.statistics?.hitting?.overall?.ops):removeZeroBeforeDecimalPoint(0)} />
        {/* <RenderItem value={0} /> */}
      </div>
    </>
  );

  const RenderP = () => (
    <>
      <div className={classes.card_state_title}>
        {titlesP?.map((title, index) => (
          <span key={index.toString()} className={classes.state_step_1_title}>
            {title}
          </span>
        ))}
      </div>
      <div className={classes.card_state_values}>
        <RenderItem value={playerStats?.statistics?.pitching?.overall?.era ? getTwoDecimal(playerStats?.statistics?.pitching?.overall?.era) : "0.00"} />
        <RenderItem value={`${playerStats?.statistics?.pitching?.overall?.games?.win ? playerStats?.statistics?.pitching?.overall?.games?.win : 0}-${playerStats?.statistics?.pitching?.overall?.games?.loss ? playerStats?.statistics?.pitching?.overall?.games?.loss : 0}`} />
        <RenderItem value={playerStats?.statistics?.pitching?.overall?.k9??0} />
        <RenderItem value={playerStats?.statistics?.pitching?.overall?.whip ? getTwoDecimal(playerStats?.statistics?.pitching?.overall?.whip) : 0} />
        {/* <RenderItem value={0} /> */}
      </div>
    </>
  );

  const RenderData = () => {
    switch (position) {
      case "p" || "P":
        return <RenderP />;

      default:
        return <RenderDefault />;
    }
  };

  return (
    <div className={`${classes.card_state} ${active && classes.active}`}>
      {RenderData()}
    </div>
  );
}

MLBPlayerStat.propTypes = {
  playerStats: PropTypes.object,
  active: PropTypes.bool,
  position: PropTypes.string,
};

export default MLBPlayerStat;
