import React from "react";
import PropTypes from "prop-types";

function XP3(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.size || "24"}
      height={props?.size || "24"}
      viewBox="0 0 24 24"
      style={{ height: "auto" }}
      {...props}
    >
      <defs>
        <linearGradient
          id="p97xhls7ma"
          x1="35.019%"
          x2="67.721%"
          y1="39.758%"
          y2="84.553%"
        >
          <stop offset="0%" stopColor="#688FBD" />
          <stop offset="100%" stopColor="#30598A" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-1025 -1507) translate(957 516) translate(0 991) translate(68)">
                <circle
                  cx="12.138"
                  cy="12.11"
                  r="11.647"
                  fill="url(#p97xhls7ma)"
                />
                <path
                  fill="#F2F2F2"
                  fillRule="nonzero"
                  d="M16.306 16.297c.69 0 1.28-.122 1.773-.366.492-.244.867-.584 1.125-1.021.257-.437.386-.936.386-1.498 0-1.168-.63-1.955-1.89-2.36v-.068c.487-.102.894-.345 1.22-.732.327-.386.49-.897.49-1.532 0-.515-.126-.977-.379-1.387-.253-.409-.623-.73-1.11-.966-.488-.234-1.073-.351-1.753-.351-1.077 0-1.914.276-2.512.828-.598.552-.92 1.343-.966 2.373h2.305c.027-.377.138-.678.33-.904.194-.225.456-.338.787-.338.304 0 .546.09.725.27.18.179.269.434.269.765 0 .764-.52 1.136-1.56 1.118h-.427v1.918h.441c1.16 0 1.74.4 1.74 1.2 0 .323-.1.585-.297.787-.198.203-.467.304-.808.304-.423 0-.745-.124-.966-.373-.22-.248-.34-.588-.359-1.02h-2.304c.055 1.13.411 1.973 1.07 2.525.657.552 1.547.828 2.67.828z"
                />
                <g fillRule="nonzero">
                  <g>
                    <path
                      fill="#ECECEC"
                      d="M7.202 5.44L5.294 3.574 7.202 1.71c.073-.07.113-.166.113-.266s-.04-.196-.113-.267L6.112.11c-.151-.147-.395-.147-.546 0L3.658 1.976 1.748.11c-.15-.147-.394-.147-.544 0L.113 1.176c-.15.147-.15.386 0 .533l1.909 1.866L.113 5.44c-.15.147-.15.386 0 .533l1.09 1.066c.073.07.171.11.273.11.103 0 .2-.04.273-.11l1.909-1.866L5.566 7.04c.073.07.17.11.273.11.102 0 .2-.04.273-.11l1.09-1.066c.15-.147.15-.386 0-.533z"
                      transform="translate(3.847 8.013) translate(0 .32)"
                    />
                    <path
                      fill="#FFF"
                      d="M.113 5.973l1.09 1.066c.073.07.171.11.273.11.103 0 .2-.04.273-.11l1.909-1.866V1.976L1.748.11c-.15-.147-.394-.147-.544 0L.113 1.176c-.15.147-.15.386 0 .533l1.909 1.866L.113 5.44c-.15.147-.15.386 0 .533z"
                      transform="translate(3.847 8.013) translate(0 .32)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

XP3.propTypes = {
  size: PropTypes.number,
};

export default XP3;
