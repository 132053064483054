import React, { useCallback, useState } from "react";
import Header from "../../../components/HeaderMobile/Header";
import ThreeBoxes from '../../../components/mobilecomponents/ThreeBoxes';
import Tabs from "../../../components/mobilecomponents/Tabs/Tabs";
import TeamManagerMobileCard from './teamManagerMobileCard';
import styles from './styles.module.scss';
import footerLogo from '../../../assets/images/bitmap.png';
import { useDispatch, useSelector } from "react-redux";
import MyScoreDetailsMobileCard from './myScoreDetailsMobileCard';
import MyScore from "../../../components/mobilecomponents/LiveMatch/MyScore";
import LiveMatch from "../../../components/mobilecomponents/LiveMatch/LiveMatch";
import "../../../components/mobilecomponents/LiveMatch/live_match.scss";
import * as MLBActions from "../../../actions/MLBActions";
import * as NHLActions from "../../../actions/NHLActions";
import { CardType } from "../../../components/SportsLiveCard/CardType";
import moment from "moment";
import { truncate, isEmpty } from "lodash";
import BoosterPopUp from "../../../components/mobilecomponents/BoosterPopUp/BoosterPopUp";
import { getLocalStorage } from "../../../utility/shared";
import * as CryptoJS from "crypto-js";
import { CONSTANTS } from "../../../utility/constants";

const TeamManagerMobile = (props) => {
  const [state, setState] = useState(1);
  function getTeamFromLocalStorage() {
    const encData = getLocalStorage(CONSTANTS.LOCAL_STORAGE_KEYS.MLB_LIVE_GAME);
    const byteData = CryptoJS.AES.decrypt(encData, CONSTANTS.DATA_ENC_KEY);
    const decSelectedTeamData = JSON.parse(
      byteData.toString(CryptoJS.enc.Utf8)
    );
    return decSelectedTeamData;
  }
  const gameID = localStorage.getItem("mlb_live_game_id");
  const userID = localStorage.getItem("PERSONA_USER_ID");
  const livePlayerss = useSelector(state => state.teamManager);
  const selectedTeam = getTeamFromLocalStorage();
  const {
    livePlayers: live_players = [],
    liveTeamD: live_teamD = {},
    live_clock = "20:00",
    live_team_logs = []
  } = livePlayerss;

  const { challenge_amount,
    currency,
    enrolledUsers,
    entryFees,
    gameStatus,
    gameType,
    id,
    league,
    pointSystem,
    prizeCurrency,
    reward,
    startDate,
    status,
    targetUsers } = selectedTeam
  const {
    teamLogs = []
  } = live_team_logs;

  const {
    data = [],
    onChangeXp = (xp, player) => { },
    getFantasyTeam = () => { },
    live_totalTeamPts,
    score,
    powers
  } = props || {};

  const [swap, setSwap] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [rankss, setRanks] = useState({});
  const [liveStandingData, setLiveStandingData] = useState([]);
  const [currentWinnings, setCurrentWinnings] = useState(0);
  const [leader, setLeader] = useState(0);
  const [currentRank, setCurrentRank] = useState(0);
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [loadingPlayerList, setLoadingPlayerList] = useState(false);
  const [swapPlayerList, setPlayerList] = useState({});
  const [filteredPlayerList, setFilteredPlayerList] = useState({});
  const [showReplaceModal, setReplaceModalState] = useState(false);

  const dispatch = useDispatch();


  const { match = {} } = data || {};
  const { date_time = "", scheduled = "" } = match || {};
  const boostModal = (value, player = {}, type, close) => {
    if (value) {
      setSelectedPlayer(player);
    } else {
      setSelectedPlayer({});
    }
    setSecondModal(close);
  };

  let filteredData = []


  const swapModal = (value, player = {}) => {
    if (value) {
      setSelectedPlayer(player);
    } else {
      setSelectedPlayer({});
    }
  };



  const RowSwapPlayer = ({
    position,
    name,
    time,
    plays,
    powers,

    sStamp
  }) => (
    <div className={styles.scoreCard}>
      <div className={styles.topPart}>
        <div className={styles.positions}>
          {position}
        </div>
        <div className={styles.seperator}></div>
        <div className={styles.playerDetails}>
          <p>{name}</p>
        </div>
      </div>
      <div className={styles.middlePart}>
        <div className={styles.statsPlayer + ' statsPlayerDiv'}>
          <div className={styles.statItem}>
            <p>Time Stamp</p>
            <div className={styles.itemBottom}>

              <div className={styles.itemBottomPartTime} style={{ marginTop: "20px" }}>
                <span>{sStamp}</span>
              </div>
            </div>
          </div>

          <div className={styles.statItem}>
            <p>Game Clock</p>
            <div className={styles.itemBottom}>
              <div className={styles.itemBottomPart} style={{ marginTop: "20px" }}>
                <span>{time.split("|")[0]}</span>
              </div>
              <div className={styles.itemBottomPart} style={{ marginTop: "20px" }}>
                <span>{time.split("|")[1]}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottomPart}>
        <div>
          {plays}
        </div>
      </div>
    </div>
  );

  const getGameTypeText = (game_type) => {
    console.log("inside func => ", challenge_amount)
    if (game_type === "PowerdFS") {
      return "";
    }
    else if (game_type === "PowerdFs_Recharge") {
      return "";
    }
    else if (game_type === "PowerdFs_open") {
      return "";
    }
    else if (game_type === "PowerdFs_promo" || game_type === "Fantasy_Promo") {
      if (props.isTeamSelectionPage) {
        return <><br /> Manager Challenge </>;
      }
      else {
        return "Manager Challenge";
      }
    }
    else if (game_type === "PowerdFs_challenge" || game_type === "Fantasy_Challenge") {
      if (props.isTeamSelectionPage) {
        return <><br /> {challenge_amount + " "} Point Challenge </>;
      }
      else {
        return `${challenge_amount} Point Challenge`;
      }
    }
    else if (game_type === "PowerdFs_Progressive") {
      if (props.isTeamSelectionPage) {
        return <><br /> Progressive Jackpot </>;
      }
      else {
        return "Progressive Jackpot";
      }
    }
    else if (game_type === "PowerdFs_One") {
      return "One";
    }
  }

  const Gameheader = ({ league = "", gameType = "" }) => {
    return (
      <section className={styles.topSection} style={{ paddingTop: "35px" }}>
        <div className={styles.topTitle}>
          {league} <span>{["Fantasy", "Fantasy_Challenge", "Fantasy_Promo"].indexOf(gameType) !== -1 ? "Fantasy" : "PowerdFS"}</span> {getGameTypeText(gameType)}
        </div>
        <div className={styles.entries}>
          {targetUsers !== 0 ?
            <>
              Entries : {enrolledUsers} <span>/ {targetUsers}</span>
            </>
            :
            <>
              Entries : {enrolledUsers}
            </>
          }

        </div>
      </section>
    )
  }

  return (
    <div className={styles.NHLLivemobile + " " + "nhlmobilelive"}>
      <Header cardType={'mlb'} />
      <Gameheader league={league} gameType={gameType} />
      <ThreeBoxes showTime={true} gameID={gameID} league={league}/>
      <Tabs state={state} setState={setState} />
      {state === 1 && <section className='cardsSection'>
        {live_players && live_players.map((item, index) => {
          return <TeamManagerMobileCard
            powersAvailables={powers}
            getFantasyTeam={getFantasyTeam}
            updateReduxState={() => { }}
            showReplaceModal={showReplaceModal}
            setReplaceModalState={setReplaceModalState}
            toggleReplaceModal={() => { }}
            loadingPlayerList={loadingPlayerList}
            selectedTeam={selectedTeam}
            starPlayerCount={0}
            playerList={swapPlayerList}
            setSecondModal={setSecondModal}
            playerData={item}
            playerIndex={index}
            type=''
          />;
        })}
        {Object.keys(live_teamD).length !== 0 && live_teamD.constructor === Object &&
          <TeamManagerMobileCard
            getFantasyTeam={getFantasyTeam}
            updateReduxState={() => { }}
            showReplaceModal={showReplaceModal}
            setReplaceModalState={setReplaceModalState}
            toggleReplaceModal={() => { }}
            loadingPlayerList={loadingPlayerList}
            selectedTeam={selectedTeam}
            starPlayerCount={0}
            playerList={swapPlayerList}
            setSecondModal={setSecondModal}
            playerData={live_teamD}
            playerIndex={0}
            type='TD'
          />
        }
      </section>}

      {state === 2 && <section className='scoreBoardSection'>
        {teamLogs.length == 0 && <h2 style={{
          textAlign: "center",
          marginBottom: 50
        }}>No Logs Yet</h2>}
        {teamLogs && teamLogs?.map((item, index1) => {
          if (!(item?.fantasyPosition == "TeamD" && item?.fantasyLog?.type !== "shotagainst")) {
            let itemData = filteredData?.filter((data) => data?.eventID == item?.eventID && data?.fantasyPosition == item?.fantasyPosition)
            if (itemData?.length == 0) {
              filteredData.push(item)
            }
          }
        })}
        {filteredData?.length > 0 && filteredData.sort(function (a, b) { return new Date(a.updated_at) - new Date(b.updated_at) })?.map((item, index) => {
          if (!(item?.fantasyPosition == "TeamD" && item?.fantasyLog?.type != "shotagainst")) {
            if (index === 0) filteredData[index].runningTotal = (item.fantasyPosition == "TeamD") ? item?.fantasyLog?.homeTeamD : item?.fantasyLog?.playerPts;
            else {
              filteredData[index].runningTotal =
                (filteredData[index - 1]?.runningTotal) + ((item.fantasyPosition == "TeamD") ? item?.fantasyLog?.homeTeamD : item?.fantasyLog?.playerPts);
            }
            return <MyScoreDetailsMobileCard
              data={item}
              index={index}
              runningTotal={
                filteredData[index].runningTotal
              }
            />
          }
        })}
      </section>}
      <section className={styles.footer}>
        {/* <img src={footerLogo} /> */}
      </section>
      <section className='matchWrapper'>
        <LiveMatch
          live_totalTeamPts={live_totalTeamPts}
          gameType={gameType}
          gameStatus={gameStatus}
          gameID={gameID}
          swap={swap}
          setSwap={setSwap}
          secondModal={secondModal}
          setSecondModal={setSecondModal}
          boostModal={boostModal}
          swapModal={swapModal}
          ranks={rankss}
          currentWinnings={currentWinnings}
          currentRank={currentRank}
          leader={leader}
          score={score}
          counts={{}}
          onChangeXp={onChangeXp}
          data={data}
          selectedPlayer={selectedPlayer}
          gameInfo={props?.gameInfo}
        />
      </section>
      <BoosterPopUp secondModal={secondModal} boostModal={boostModal} counts={props.counts} onChangeXp={onChangeXp} data={data} selectedPlayer={selectedPlayer} />

    </div>
  );
};
export default TeamManagerMobile;