import React from 'react'
import PropTypes from 'prop-types'

function EmployeeIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={ props?.size || "24" } height={ props?.size && (parseInt(props?.size || 0) + 3) || "27" } viewBox="0 0 24 27" style={{height: 'auto'}}>
            <g fill="none" fillRule="evenodd">
                <g fill="#A7A7A7" fillRule="nonzero">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <path d="M13.457 21.619c.472-.487.763-1.15.763-1.879 0-1.489-1.211-2.7-2.7-2.7s-2.7 1.211-2.7 2.7c0 .73.291 1.392.763 1.879-1.129.665-1.888 1.893-1.888 3.296v.45c0 .373.302.675.675.675h6.3c.373 0 .675-.302.675-.675v-.45c0-1.403-.76-2.63-1.888-3.296zM11.52 18.39c.744 0 1.35.606 1.35 1.35 0 .744-.606 1.35-1.35 1.35-.744 0-1.35-.606-1.35-1.35 0-.744.606-1.35 1.35-1.35zm-2.465 6.3c.114-1.26 1.176-2.25 2.465-2.25s2.35.99 2.465 2.25h-4.93zM21.152 18.919c.472-.487.763-1.15.763-1.879 0-1.489-1.211-2.7-2.7-2.7s-2.7 1.211-2.7 2.7c0 .73.291 1.392.763 1.879-1.129.665-1.888 1.893-1.888 3.296v.45c0 .373.302.675.675.675h6.3c.373 0 .675-.302.675-.675v-.45c0-1.403-.76-2.63-1.888-3.296zm-1.937-3.229c.744 0 1.35.606 1.35 1.35 0 .744-.606 1.35-1.35 1.35-.744 0-1.35-.606-1.35-1.35 0-.744.606-1.35 1.35-1.35zm-2.465 6.3c.114-1.26 1.176-2.25 2.465-2.25s2.35.99 2.465 2.25h-4.93zM5.762 18.919c.472-.487.763-1.15.763-1.879 0-1.489-1.211-2.7-2.7-2.7s-2.7 1.211-2.7 2.7c0 .73.291 1.392.763 1.879C.76 19.584 0 20.812 0 22.215v.45c0 .373.302.675.675.675h6.3c.373 0 .675-.302.675-.675v-.45c0-1.403-.76-2.63-1.888-3.296zM3.825 15.69c.744 0 1.35.606 1.35 1.35 0 .744-.606 1.35-1.35 1.35-.744 0-1.35-.606-1.35-1.35 0-.744.606-1.35 1.35-1.35zm-2.465 6.3c.114-1.26 1.176-2.25 2.465-2.25s2.35.99 2.465 2.25H1.36z" transform="translate(-1182 -1444) translate(1083 516) translate(34 331) translate(0 179) translate(20 24) translate(10 385) translate(23) translate(12 9)"/>
                                                <g>
                                                    <path d="M5.785 15.36c-.072 0-.144-.016-.21-.049-3.037-1.478-5.55-3.074-5.55-7.63V2.4c0-.202.126-.383.316-.452L5.621.03c.106-.039.222-.039.328 0l5.28 1.92c.19.069.316.25.316.451v5.28c0 4.557-2.514 6.153-5.55 7.631-.066.033-.138.049-.21.049zM.985 2.737V7.68c0 3.58 1.616 5.085 4.8 6.665 3.184-1.58 4.8-3.085 4.8-6.665V2.737L5.785.991l-4.8 1.746z" transform="translate(-1182 -1444) translate(1083 516) translate(34 331) translate(0 179) translate(20 24) translate(10 385) translate(23) translate(12 9) translate(5.5)"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

EmployeeIcon.propTypes = {
    size: PropTypes.number,
}

export default EmployeeIcon

