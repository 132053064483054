import React from "react";
import PropTypes from "prop-types";
import ordinal from "ordinal";

import classes from "./index.module.scss";
import Modal from "../Modal";
import CloseIcon from "../../icons/Close";
import CupIcon from "../../icons/Cup";

import OrangePowerCurrency from "../../assets/power-orange.png";
import OrangeBtcCurrency from "../../assets/btc-orange.png";
import OrangeEthCurrency from "../../assets/ethereum-orange.png";

import chaseImage from "../../assets/background-chase-dark.png";

function PrizeModal(props) {
  const {
    visible = false,
    sportsName = "",
    data = [],
    onClose = () => { },
    currency = "USD",
    gameType = "",
    chase_prize_pool = 0,
  } = props || {};

  const getPrizeCurrency = (prize_currency) => {
    if (prize_currency?.toUpperCase() === "PWRS") {
      return OrangePowerCurrency;
    } else if (prize_currency?.toUpperCase() === "BTC") {
      return OrangeBtcCurrency;
    } else if (prize_currency?.toUpperCase() === "ETH") {
      return OrangeEthCurrency;
    } else if (prize_currency?.toUpperCase() === "USD") {
      return "$"
    }
  };

  const Item = ({ place, payout }) => (
    <div className={classes.item}>
      <div className={classes.item_text}>{place}</div>
      <div className={`${classes.item_text} ${classes.primary}`}>
        {currency === 'USD' ? "$" : (
          <img
            style={{ marginRight: 4 }}
            src={getPrizeCurrency(currency)}
            height="20px"
            alt=""
          />
        )}
        {payout}
      </div>
    </div>
  );

  const numberWithCommas = (x) => {
    if (x >= 10000) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return x;
  };

  const getBackgroundImage = () => {
    let backgroundImageStyle = {
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "inherit",
      backgroundPosition: "center",
      backgroundSize: "cover"
    };
    if (gameType === "Fantasy_Chase_The_Ace") {
      backgroundImageStyle.backgroundImage = `url(${chaseImage})`
    }
    return backgroundImageStyle
  }

  return (
    <Modal visible={visible}>
      <div className={classes.wrapper} style={getBackgroundImage()}>
        <CupIcon className={classes.cup_svg} />
        <div className={classes.header}>
          <div className={classes.close_icon}>
            <CloseIcon onClick={onClose} />
          </div>
          <p className={classes.title}>
            {sportsName} <span>{gameType === "Fantasy_Chase_The_Ace" ? "Chase The Ace" : gameType.toLowerCase().includes("fantasy") ? "Fantasy" : "PowerdFS"}</span>
          </p>
          <span className={classes.title_prize}>prize Grid</span>
        </div>


        {gameType === "Fantasy_Chase_The_Ace" ?
          <div className={classes.chase_the_ace_body}>
            <div className={classes.chase_the_ace_prizepool_text}>
              <span>Finish in 1st </span>and you will win <span>{data[0]?.amount} Power Tokens</span> and move on to the «Chase the Ace» selection round!
            </div>
            <div className={classes.chase_the_ace_prizepool}>
              <p>Current Prize Pool:</p>
              <p className={classes.chase_the_ace_prize}>${numberWithCommas(chase_prize_pool)}</p>
            </div>
          </div>
          :
          <div className={classes.modal_body}>
            <div className={classes.table}>
              <div className={classes.table_header}>
                <div className={classes.header_text}>Place</div>
                <div className={classes.header_text}>Prize</div>
              </div>

              <div className={classes.table_body}>
                {data &&
                  data?.length &&
                  data?.map((item, ind) => {
                    return (
                      <>
                        {item?.from === item?.to ? (
                          <p
                            className={classes.__prize_grid_data_title}
                            key={ind}
                          >
                            {
                              <Item
                                place={ordinal(parseInt(item?.from))}
                                payout={item?.amount}
                                key={ind + "--"}
                              />
                            }
                          </p>
                        ) : (
                          <p
                            className={classes.__prize_grid_data_title}
                            key={ind}
                          >
                            {
                              <Item
                                place={`${ordinal(
                                  parseInt(item?.from)
                                )} - ${ordinal(parseInt(item?.to))}`}
                                payout={item?.amount}
                                key={ind + "--"}
                              />
                            }
                          </p>
                        )}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        }

      </div>
    </Modal>
  );
}

PrizeModal.propTypes = {
  visible: PropTypes.bool,
  sportsName: PropTypes.string,
  data: PropTypes.array,
  onClose: PropTypes.func,
};

export default PrizeModal;
