import { getApiURL } from '../../../config/apiURL';
import http from '../../../config/http';

import { getCaptchaToken } from "./captcha"

/**
 * @param  {number} amount - Payment amount
 * @param {string} captchaToken - reCAPTCHA assessment token
 */
async function getToken(amount, captchaToken) {
    const apiUrl = getApiURL()
    const response = await http.post(
        `${apiUrl}/api/v1/evalon/create-transaction-token`, { amount, captchaToken }
    )

    if (!response.data.success) {
        console.error(response.error)
        throw new Error("Could not get a transaction token")
    } else {
        return response.data.token
    }
}

/**
 * @param  {string} token - Transaction token from getToken
 * @param  {string} number - Card number with no spaces
 * @param  {string} expirationDate - Card expiration date with like MMYY
 * @param  {string} cvv - Card CVV
 */
function convergePay(token, number, expirationDate, cvv) {
    return new Promise((resolve, reject) => {
        window.ConvergeEmbeddedPayment.pay(
            {
                ssl_txn_auth_token: token,
                ssl_card_number: number,
                ssl_exp_date: expirationDate,
                ssl_cvv2cvc2: cvv
            },
            {
                onError: (error) => {
                    reject(error)
                },
                onDeclined: (response) => {
                    resolve({ result: "declined", reason: response.errorMessage })
                },
                onApproval: (response) => {
                    resolve({ result: "approved", transactionId: response['ssl_txn_id'] })
                }
            }
        );
    })
}

/**
 * @param  {string} transactionId - Transaction id (given from ssl_txn_id)
 */
async function registerTransaction(transactionId) {
    const apiUrl = getApiURL()
    const userId = localStorage.getItem("PERSONA_USER_ID")

    const response = await http.post(
        `${apiUrl}/api/v1/evalon/register-transaction`, { userId, transactionId }
    )

    return response.data
}

/**
 * @param  {string} number - Card number with no spaces
 * @param  {number} expirationMonth - Card expiration month
 * @param  {number} expirationYear - Card expiration year
 * @param  {string} cvv - Card CVV
 * @param  {string} firstName - Cardholder first name
 * @param  {string} lastName - Cardholder last name
 * @param  {number} amount - Payment amount
 */
async function elavonPaymentHandler(number, expirationMonth, expirationYear, cvv, firstName, lastName, amount) {
    const captchaToken = await getCaptchaToken("DEPOSIT")
    const token = await getToken(amount, captchaToken)
    const payment = await convergePay(
        token,
        number.replaceAll(" ", ""),
        `${expirationMonth.toString().padStart(2, "0")}${expirationYear}`,
        cvv
    )
    if (payment.result === "declined") {
        return payment
    }

    const backendResponse = await registerTransaction(payment.transactionId)
    if (!backendResponse.balanceUpdated)
        return {
            result: "declined",
            reason: backendResponse.message
        }

    return { result: "approved" }
}

const paymentHandler = {
    type: "card",
    handler: elavonPaymentHandler
}

export default paymentHandler