import React, { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Hitters from "./Hitters";
import classes from "./index.module.scss";
import PointSystem from "./PointSystem";
import PowersAvailable from "./PowersAvailable";
import PrizeGrid from "./PrizeGrid";
import TeamRoster from "./TeamRoster";
import ContestRules from "./ContestRules";
import MLBFantasyPWRSBanner from '../../assets/mlbFantasyPWRSBannerTitle.png'
import NHLFantasyPWRSBanner from '../../assets/nhlFantasyPWRSBannerTitle.png'
import MLBPlayer from "../../assets/mlb-player.png";
import MLBFantasyPWRSDark from '../../assets/mlbFantasyPWRSDark.png'
import NHLFantasyPWRSDark from '../../assets/nhlFantasyPWRSDark.png'
import MLBPlayerOppsite from "../../assets/baseball-player-copy.png";
import NFLPlayer from "../../assets/nflCardBg.png";
import NBAPlayer from "../../assets/nbaCardBg.png";
import NHLPlayer from "../../assets/new-hockey-playerlogo.png";
import onenflbg from "../../assets/group-3-one-nfl.png";
import onenhlbg from "../../assets/group-3-one-nhl.png";
import pwrsCardBanner from '../../assets/pwrsCardBanner.svg'
import chaseImageDark from "../../assets/background-chase-dark.png";

import { getAllGames } from "../../actions/powerCenterActions";

const PowerCenterCardDetails = (props) => {
  const {
    entry_fee = "",
    title = "",
    onBackClick = () => { },
    onNextClick = () => { },
    myGameCenter = false,
    PointsSystem = [],
    Power = [],
    PrizePayout = [],
    onEnter = () => { },
    game_set_start = "",
    prize = "",
    userHasEntered = false,
    gameTargetFull = false,
    game_type = "",
    currency = "",
    currencyImg = "",
    prize_currency = "USD",
    hideCard = () => { },
    getCurrency = () => { },
  } = props || {};

  const [currentIndex, setCurrentIndex] = useState(0);
  const getBackgroundImageWithStyle = () => {
    let backgroundImageStyle = {
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "inherit",
      height: "100%",
      width: "100%",
      position: "absolute",
      opacity: 0.5,
    };
    if ( game_type==="Fantasy_Chase_The_Ace") {
      backgroundImageStyle.backgroundImage = `url(${chaseImageDark})`;
      backgroundImageStyle.backgroundSize = "contain";}
    else if (title === "MLB1") {
      backgroundImageStyle.backgroundImage = `url(${MLBPlayerOppsite})`;
      //backgroundImageStyle.backgroundPosition = "106px 60px";
    } else if (title === "MLB" && currency !== "PWRS") {
      backgroundImageStyle.backgroundImage = `url(${MLBPlayerOppsite})`;
      backgroundImageStyle.backgroundPosition = "100px 0px";
    } else if (title === "MLB" && currency === "PWRS" && game_type.toLowerCase().includes("fantasy")) {
      backgroundImageStyle.backgroundImage = `url(${MLBFantasyPWRSDark})`;
      backgroundImageStyle.backgroundSize = "contain";
    } else if (title === "NFL") {
      backgroundImageStyle.backgroundImage = `url(${NFLPlayer})`;
      //backgroundImageStyle.backgroundPosition = "65px 60px";
    } else if (title === "NBA") {
      backgroundImageStyle.backgroundImage = `url(${NBAPlayer})`;
      //backgroundImageStyle.backgroundPosition = "-75px 68px";
    } else if (title === "NHL" && game_type === "PowerdFs_One") {
      backgroundImageStyle.backgroundImage = `url(${onenhlbg})`;
    } else if (title === "NHL" && currency === "PWRS") {
      backgroundImageStyle.backgroundImage = `url(${NHLFantasyPWRSDark})`;
      backgroundImageStyle.backgroundSize = "contain";
    } else if (title === "NFL" && game_type === "PowerdFs_One") {
      backgroundImageStyle.backgroundImage = `url(${onenflbg})`;
    } else {
      backgroundImageStyle.backgroundImage = `url(${NHLPlayer})`;
      //backgroundImageStyle.backgroundPosition = "36px 106px";
    }
    return backgroundImageStyle;
  };

  const getPWRSBannerByLeague = (League) => {
    if (League === "NHL") {
      return (
        <div>
          <img className={classes.__pwers_header_title} src={NHLFantasyPWRSBanner} alt="NHLFantasyPWRSBanner" />
        </div>
      )
    } else if (League === "MLB") {
      return (
        <div>
          <img className={classes.__pwers_header_title} src={MLBFantasyPWRSBanner} alt="MLBFantasyPWRSBanner" />
        </div>
      )
    }
  }

  return (
    <div className={classes.__power_center_card_details}>
      <div style={getBackgroundImageWithStyle()}></div>
      <div style={{ zIndex: 1 }}>
        {
          currency === "PWRS" ?

            //getPWRSBannerByLeague(title)
            <>
              <div className={classes.__pwers_header_title}>
                <img src={pwrsCardBanner} alt="pwrsCardBanner" />
                <p>{title} {
                 game_type==="Fantasy_Chase_The_Ace"?"Chase The Ace": 
                ["NHL_Fantasy", "Fantasy_Promo","Rapid_Fantasy", "Fantasy_Challenge", "Fantasy"].indexOf(game_type) !== -1 ? "Fantasy" : "PowerdFS"}</p>
              </div>
            </>
            :
            <Header title={title} game_type={game_type} />
        }
        {currentIndex == 0 && (
          <PrizeGrid
            game_type={game_type}
            currencyString={currency}
            currency={currency === "PWRS" ? prize_currency : currency}
            PrizePayout={PrizePayout} onEnter={onEnter} />
        )}
        {currentIndex == 1 && title !== "NHL" && (
          <Hitters game_type={game_type} title={title} currencyString={currency} PointsSystem={PointsSystem} onEnter={onEnter} />
        )}
        {currentIndex == 1 && title === "NHL" && (
          <PointSystem
            game_type={game_type}
            currencyString={currency}
            PointsSystem={PointsSystem}
            onEnter={onEnter}
            title={title}
          />
        )}
        {currentIndex == 2 && (
          <PowersAvailable title={title} Power={Power} onEnter={onEnter} game_type={game_type} />
        )}
        {currentIndex == 3 && <TeamRoster game_type={game_type} currencyString={currency} league={title} onEnter={onEnter} />}
        {currentIndex == 4 && (
          <ContestRules
            currencyString={currency}
            currency={currency === "PWRS" ? prize_currency : currency}
            game_type={game_type}
            game_set_start={game_set_start}
            prize={prize}
            powers={Power}
            points={PointsSystem}
            isMobileGameCenter={false}
            showDateTime={false}
            title={title}
            myGameCenter={myGameCenter}
          />
        )}
        <Footer
          currency={currencyImg}
          currencyString={currency}
          onEnter={onEnter}
          entry_fee={entry_fee}
          onBack={() => {
            if (currentIndex === 3 && game_type.toLowerCase().includes("fantasy")) {
              setCurrentIndex(currentIndex - 2);
            }
            else if (currentIndex > 0) {
              setCurrentIndex(currentIndex - 1);
            } else {
              onBackClick();
            }
          }}
          onNext={() => {
            if (currentIndex === 1 && game_type.toLowerCase().includes("fantasy")) {
              setCurrentIndex(currentIndex + 2);
            }
            else if (currentIndex < 2) {
              setCurrentIndex(currentIndex + 1);
            } else if (currentIndex < 4) {
              setCurrentIndex(currentIndex + 1);
            } else if (currentIndex === 4) {
              onNextClick();
              hideCard(false);
            }
          }}
          myGameCenter={myGameCenter}
          userHasEntered={userHasEntered}
          gameTargetFull={gameTargetFull}
          game_type={game_type}
        />
      </div>
    </div>
  );
};

export default PowerCenterCardDetails;
