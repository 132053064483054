import React from 'react';

const Trophy = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
        <g fill="#FFF" fillRule="nonzero">
            <path d="M16.774 1.247h-2.218c.008-.245.013-.493.013-.74 0-.28-.227-.507-.506-.507H3.217c-.279 0-.506.227-.506.506 0 .248.005.496.013.741H.506c-.28 0-.506.227-.506.506C0 4.022.593 6.162 1.67 7.78c1.063 1.599 2.48 2.516 4.012 2.606.347.377.714.685 1.096.92v2.25h-.85c-1.026 0-1.861.835-1.861 1.862v.85H4.03c-.28 0-.506.226-.506.506s.226.506.506.506h9.218c.28 0 .506-.227.506-.506 0-.28-.226-.507-.506-.507h-.036v-.849c0-1.027-.835-1.862-1.862-1.862h-.85v-2.25c.383-.235.75-.543 1.097-.92 1.531-.09 2.949-1.007 4.013-2.606 1.076-1.618 1.669-3.758 1.669-6.027 0-.28-.227-.506-.506-.506zM2.512 7.22c-.888-1.335-1.41-3.08-1.489-4.96H2.78c.183 2.311.725 4.447 1.573 6.142.135.27.276.525.423.766-.842-.318-1.622-.983-2.264-1.948zm9.689 8.2v.848H5.079v-.849c0-.468.381-.85.85-.85h5.422c.469 0 .85.382.85.85zm-2.712-1.863H7.791v-1.81c.278.073.561.111.849.111.288 0 .571-.038.85-.11v1.81zm.314-3.014c-.023.009-.045.02-.066.033-.358.178-.725.27-1.097.27s-.74-.092-1.097-.27c-.021-.013-.043-.024-.066-.034-.397-.21-.781-.526-1.144-.943-.02-.027-.041-.052-.065-.075-.36-.428-.7-.955-1.01-1.575-.93-1.86-1.467-4.307-1.528-6.936h9.82c-.06 2.63-.598 5.076-1.529 6.936-.31.62-.649 1.147-1.009 1.575-.024.023-.046.048-.065.075-.363.417-.747.734-1.144.944zm4.965-3.323c-.642.965-1.422 1.63-2.264 1.948.147-.24.288-.496.423-.766.848-1.695 1.39-3.83 1.573-6.141h1.757c-.08 1.88-.601 3.624-1.49 4.959z" transform="translate(-806 -311) translate(0 80) translate(335 82) translate(136 135) translate(261) translate(74 12) translate(0 2)" />
        </g>
    </svg>
)

export default Trophy;