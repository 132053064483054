import React, { useCallback, useState } from "react";
import Header from "../../components/HeaderMobile/Header";
import ThreeBoxes from '../../components/mobilecomponents/ThreeBoxes';
import Tabs from "../../components/mobilecomponents/Tabs/Tabs";
import TeamManagerMobileCard from './teamManagerMobileCard';
import styles from './styles.module.scss';
import footerLogo from '../../assets/images/bitmap.png';
import { useDispatch, useSelector } from "react-redux";
import MyScoreDetailsMobileCard from './myScoreDetailsMobileCard';
import MyScore from "../../components/mobilecomponents/LiveMatch/MyScore";
import LiveMatch from "../../components/mobilecomponents/LiveMatch/LiveMatch";
import "../../components/mobilecomponents/LiveMatch/live_match.scss";
import * as MLBActions from "../../actions/MLBActions";
import * as NHLActions from "../../actions/NHLActions";
import { CardType } from "../../components/SportsLiveCard/CardType";
import moment from "moment";
import { truncate, isEmpty } from "lodash";
import BoosterPopUp from "../../components/mobilecomponents/BoosterPopUp/BoosterPopUp";
import {cloneDeep} from "lodash";
import { getLocalStorage } from "../../utility/shared";
import { CONSTANTS } from "../../utility/constants";
const NHLLiveMobile = (props) => {
  const [state, setState] = useState(1);
  const gameID = localStorage.getItem("nhl_live_game_id");
  const userID = localStorage.getItem("PERSONA_USER_ID");

  const {
    data: nhlData = [],
    selectedTeam = {},
    match_status = [],
    swappedPlayers = [],
    live_players = [],
    is_loading = false,
    live_teamD = {},
    live_team_logs = {},
    starPlayerCount = 0,
    allTeamLogs,

  } = useSelector((state) => state.nhl);

  const {
    teamLogs = []
  } = live_team_logs;
  const {
    enrolledUsers = "",
    outof = "",
    data = [],
    ranks = {},
    onChangeXp = (xp, player) => { },
    gameInfo = {},
    prizePool = 0,
    loading = true,
    swapCounts = 0,
    dwallCounts = 0,
    challengeCounts = 0,
    pointMultiplierCounts = 0,
    pointBooster15x = 0,
    pointBooster2x = 0,
    pointBooster3x = 0,
    retroBoostCounts = 0,
    powerUpCounts = 0,
    setPlayerToSwap = () => { },
    onPowerApplied = () => { },
    POWER_IDs = {},
    setPowers = () => { },
    cardType = "mlb",
    gameStatus,
    getFantasyTeam = () => { },
    gameType,
    live_totalTeamPts,
    score,
    powers
  } = props || {};
  // console.log("props of nhlpowerdfs live new  => ", props)
  const [swap, setSwap] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [rankss, setRanks] = useState({});
  const [liveStandingData, setLiveStandingData] = useState([]);
  const [currentWinnings, setCurrentWinnings] = useState(0);
  const [leader, setLeader] = useState(0);
  const [currentRank, setCurrentRank] = useState(0);
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [loadingPlayerList, setLoadingPlayerList] = useState(false);
  const [swapPlayerList, setPlayerList] = useState({});
  const [filteredPlayerList, setFilteredPlayerList] = useState({});
  const [showReplaceModal, setReplaceModalState] = useState(false);

  const dispatch = useDispatch();
  const { data: mlbData = [] } = useSelector((state) => state.mlb);
  // const { data: nhlData = [] } = useSelector((state) => state.nhl);

  const { data: nflData = [] } = useSelector((state) => state.nfl);
  const { liveStandings = [] } = useSelector((state) => state.nhl);
  const { user_id } = useSelector((state) => state.auth.user);
  const { match = {} } = data || {};
  const { date_time = "", scheduled = "" } = match || {};


  React.useEffect(() => {
      async function getdata(){
      let payload = {
        gameID: gameID,
        userID: userID,
      };
      await dispatch(NHLActions.getFantasyTeam(payload));
      }
      getdata()
    }, [state==2])
  React.useEffect(async () => {

    if (JSON.stringify(rankss) !== JSON.stringify(props?.ranks)) {
      if (
        props?.ranks?.ranking !== 0 &&
        props?.ranks?.game_id !== 0 &&
        props?.score?.ranking !== 0 &&
        props?.team_id?.ranking !== 0
      )
        setRanks(props.ranks);
      if (props?.ranks?.game_id !== 0) {
        let liveStandingsData = await dispatch(
          MLBActions.getLiveStandings(props?.ranks?.game_id)
        );
        if (typeof liveStandingsData !== "undefined") {
          if (liveStandingsData.payload.error == false) {
            if (
              JSON.stringify(liveStandingsData.payload.data) !==
              JSON.stringify(liveStandingData)
            ) {
              var finalArr = [];
              var res = liveStandingsData.payload.data.powerDFSRanking;

              var user_id = parseInt(localStorage.PERSONA_USER_ID);
              var userRec = "";
              var leaderScore = 0;
              for (var i = 0; i < res.length; i++) {
                if (res[i].ranking == 1) {
                  setLeader(res[i].score);
                }

                if (res[i].team.user.user_id == user_id) {
                  userRec = res[i];
                  setCurrentRank(userRec.ranking);
                  setCurrentWinnings(userRec?.winnings?.amount);
                } else {
                  finalArr.push(res[i]);
                }
              }
              if (userRec !== "") {
                finalArr.unshift(userRec);
              }
              if (JSON.stringify(liveStandingData) !== JSON.stringify(finalArr))
                setLiveStandingData(finalArr);
            }
            //setModalState(!showModal);
          } else {
            // alert("We are experiencing technical issues with the Power functionality. Please try again shortly.");
          }
        }
      }
      else {
        dispatch(NHLActions.getFinalStandings(gameID))
      }
    }
  }, [ranks]);

  React.useEffect(() => {
    let leaderScore = 0;
    if (liveStandings.length > 0) {
      liveStandings.forEach((element, index) => {
        if (element?._id?.userID == user_id) {
          setCurrentRank(element?.rank);
          setCurrentWinnings(element?.prize);
        }
        leaderScore = Math.max(...liveStandings.map(o => o.totalValue));
        setLeader(leaderScore);
      });
    }
  }, [liveStandings]);
  const getPoints = (item,poss) => {
    let pos1 = item?.type === "shotagainst" && item?.saved == true
    ? poss == "G1"? "SV": "SA"
    : item?.type === "shotagainst" && item?.saved == false ? 
    "GA" 
    : item?.type === "goalagainst"
    ? "GA"
    :
    item?.newmyscore_description? 
    item?.newmyscore_description[0]:
    item?.myscore_description?
    item?.myscore_description[0]:
    item?.goalScored>0?"G":
    item?.type[0];
    // console.log("pos1===>>", pos1, item);
    if(poss == "TeamD") {
      if(pos1=="GA"){
        return item?.goalAgainst
      }
      if(pos1=="SA"){
        return item?.shotAgainst
      }
      return item?.teamD;
    }
    else {
      if(pos1 == 'SV'){
        return item?.saves;
      }
      else if(pos1 == 'g') {
        return item?.goalScored;
      }
      else if(pos1 == 's') {
        return item?.newplayerPts ? item?.newplayerPts : item?.shotOnGoal ? item?.shotOnGoal : 0;
      }
      else {
        return item?.newplayerPts ? item?.newplayerPts : item?.playerPts ? item?.playerPts : 0;
      }
    }
  };
  const boostModal = (value, player = {}, type, close) => {
    if (value) {
      setSelectedPlayer(player);
    } else {
      setSelectedPlayer({});
    }
    setSecondModal(close);
  };
  const toggleReplaceModal = useCallback(
    async (player) => {
      if (cardType === CardType.MLB) {
        setLoadingPlayerList(true);
        setSwap(true);
        const response = await dispatch(
          MLBActions.mlbData(props?.gameInfo?.game_id)
        );
        if (response?.filterdList && response?.filterdList?.length) {
          const _mlbData = [...response?.filterdList];
          const [swapablePlayerData] = _mlbData?.filter(
            (data) =>
              data?.type === `${player?.player?.type}`?.toLocaleLowerCase()
          );
          if (
            swapablePlayerData &&
            swapablePlayerData?.listData &&
            swapablePlayerData?.listData?.length
          ) {
            const _time = moment(player?.match?.date_time)
              .clone()
              .format("h:mm A");
            const newListData = swapablePlayerData?.listData?.filter(
              (data, index) =>
                `${data?.time}` === _time &&
                data?.playerId !== player?.player_id &&
                data[index]?.player_id !== player?.player_id
            );
            const _dataToRender = {
              type: swapablePlayerData.type,
              listData: newListData,
            };
            setPlayerList(_dataToRender);
            setFilteredPlayerList(_dataToRender);
            //setSwap(true)
          }
        }
        setLoadingPlayerList(false);
      }

      if (cardType === CardType.NHL) {
        setLoadingPlayerList(true);
        setReplaceModalState(true);
        const response = await dispatch(NHLActions.getFantasyPlayers(gameID));
        if (response?.filterdList && response?.filterdList?.length) {
          const _nhlData = [...response?.filterdList];
          const [swapablePlayerData] = _nhlData?.filter(
            (data) => data?.type === player?.fantasyPlayerPosition?.toLocaleLowerCase()
          );
          if (
            swapablePlayerData &&
            swapablePlayerData?.listData &&
            swapablePlayerData?.listData?.length
          ) {
            const _time = moment(player?.match?.scheduled).clone().format("h:mm A");

            const newListData = swapablePlayerData?.listData?.filter(
              (data, index) => {
                return (
                  moment(data?.match?.scheduled).clone().format("h:mm A") == _time
                );
              }
            );
            const _dataToRender = {
              type: swapablePlayerData.type,
              listData: newListData,
            };
            setPlayerList(_dataToRender);
          }
        }
        setLoadingPlayerList(false);
      }
    },
    [mlbData, nhlData]
  );
  let tempCounter = 0;
  let lasPos = "";
  let filteredData = []

  const onSwap = (playerId, match_id) => {
    if (swapCounts === 0) {
      alert("You cannot swap the players.");
      return;
    }
    const [swapablePlayer] =
      !isEmpty(data) &&
      swapPlayerList?.listData?.length &&
      swapPlayerList?.listData?.filter(
        (player) =>
          player?.playerId === playerId && player?.match_id === match_id
      );
    if (swapablePlayer) {
      props.updateReduxState(data, swapablePlayer);
      toggleReplaceModal();
      setSwap(false);
      props.useSwap(true);
    }
  };
  const swapModal = (value, player = {}) => {
    if (value) {
      setSelectedPlayer(player);
    } else {
      setSelectedPlayer({});
    }
    toggleReplaceModal(player);
    //setSwap(!swap);
  };
  const searchPlayerList = (searchTerm) => {
    let searchText = searchTerm;
    if (searchText == "") {
      setFilteredPlayerList(swapPlayerList);
      return;
    }
    let listData = filteredPlayerList?.listData;
    let filteredSearch = listData.filter((x) => {
      let splittedName = x.playerName.split(" ");
      let found = 0;
      if (splittedName.length > 0) {
        for (let i = 0; i < splittedName.length; i++) {
          if (splittedName[i].startsWith(searchText)) {
            found = 1;
          }
        }
      }
      if (x.homeTeam.startsWith(searchText)) {
        found = 1;
      }
      if (found) {
        return x;
      }
    });
    let oldFilteredList = {
      type: swapPlayerList.type,
      listData: filteredSearch,
    };
    if (JSON.stringify(oldFilteredList) !== JSON.stringify(filteredPlayerList))
      setFilteredPlayerList(oldFilteredList);
  };

  const updateReduxState = (currentPlayer, newPlayer) => {
    if (!currentPlayer || !newPlayer) return;
    const { userID, gameID } = selectedTeam || {};
    setPlayerToSwap(currentPlayer);
    onPowerApplied({
      fantasyTeamId: currentPlayer?.team?.id,
      matchId: currentPlayer.match?.id,
      playerId: currentPlayer.id,
      playerId2: newPlayer.id,
      matchIdP2: newPlayer?.match?.i,
      powerId: POWER_IDs.SWAP_POWER,
      userId: userID,
      gameId: gameID,
    });
  };
  const RowSwapPlayer = ({
    position,
    name,
    time,
    plays,
    powers,

    sStamp
  }) => (
    <div className={styles.scoreCard}>
      <div className={styles.topPart}>
        <div className={styles.positions}>
          {position}
        </div>
        <div className={styles.seperator}></div>
        <div className={styles.playerDetails}>
          <p>{name}</p>
        </div>
      </div>
      <div className={styles.middlePart}>
        <div className={styles.statsPlayer + ' statsPlayerDiv'}>
          <div className={styles.statItem}>
            <p>Time Stamp</p>
            <div className={styles.itemBottom}>

              <div className={styles.itemBottomPartTime} style={{ marginTop: "20px" }}>
                <span>{sStamp}</span>
              </div>
            </div>
          </div>

          <div className={styles.statItem}>
            <p>Game Clock</p>
            <div className={styles.itemBottom}>
              <div className={styles.itemBottomPart} style={{ marginTop: "20px" }}>
                <span>{time.split("|")[0]}</span>
              </div>
              <div className={styles.itemBottomPart} style={{ marginTop: "20px" }}>
                <span>{time.split("|")[1]}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottomPart}>
        <div>
          {plays}
        </div>
      </div>
    </div>
  );
    
  return (
    <div className={styles.NHLLivemobile + " " + "nhlmobilelive"}>
      <Header cardType={'nhl'} />
      <section className={styles.topSection}>
        <div className={styles.topTitle}>
          NHL <span>{gameType === "NHL_Fantasy" ? 'Fantasy' : 'PowerdFS'}</span>
        </div>
        <div className={styles.entries}>
          Entries {enrolledUsers} <span>/ {outof}</span>
        </div>
      </section>
      <ThreeBoxes showTime={true} gameID={gameID} league="NHL" />
      <Tabs state={state} setState={setState}/>
      {state === 1 && <section className='cardsSection'>
        {live_players && live_players.map((item, index) => {
          return <TeamManagerMobileCard
            powersAvailables={powers}
            getFantasyTeam={getFantasyTeam}
            updateReduxState={updateReduxState}
            showReplaceModal={showReplaceModal}
            setReplaceModalState={setReplaceModalState}
            toggleReplaceModal={toggleReplaceModal}
            loadingPlayerList={loadingPlayerList}
            selectedTeam={selectedTeam}
            starPlayerCount={starPlayerCount}
            playerList={swapPlayerList}
            setSecondModal={setSecondModal}
            playerData={item}
            playerIndex={index}
            type=''
          />;
        })}
        {Object.keys(live_teamD).length !== 0 && live_teamD.constructor === Object &&
          <TeamManagerMobileCard
            getFantasyTeam={getFantasyTeam}
            updateReduxState={updateReduxState}
            showReplaceModal={showReplaceModal}
            setReplaceModalState={setReplaceModalState}
            toggleReplaceModal={toggleReplaceModal}
            loadingPlayerList={loadingPlayerList}
            selectedTeam={selectedTeam}
            starPlayerCount={starPlayerCount}
            playerList={swapPlayerList}
            setSecondModal={setSecondModal}
            playerData={live_teamD}
            playerIndex={0}
            type='TD'
          />
        }
      </section>}
      {state === 2 && <section className='scoreBoardSection'>
        {allTeamLogs?.map((item, index1) => {
          if (!(item?.fantasyPosition == "TeamD" && item?.type !== "shotagainst")) {
            let itemData = filteredData?.filter((data) => data?.eventId == item?.eventId && data?.fantasyPosition == item?.fantasyPosition)
            // setFilterDataCheck(...filteredData,itemData)
            // console.log("itemData==>",itemData);
            if (itemData?.length == 0) {
              filteredData.push(item)
              if(item?.goalScored > 0 && item?.shotOnGoal > 0){
                const newItem = cloneDeep(item);
                newItem.newmyscore_description="shot"
                newItem.newplayerPts=3  
                filteredData.push(newItem)  
              }
              if(item?.goalAgainst < 0 && item?.shotAgainst < 0 &&item?.fantasyPosition == "TeamD"){
                const newItem = cloneDeep(item);
                newItem.type="shotagainst"
                newItem.saved=true

                newItem.playerPts=-0.5  
                filteredData.push(newItem)  
              }
            }
          }
        })}
         {
            swappedPlayers?.length>0 && swappedPlayers?.map((item)=>{
              let itemData=filteredData?.filter((data)=>data?.previousPlayerID==item?.previousPlayerID )
              if(itemData?.length==0){
               filteredData?.push(item)
              }
            })
          }
        {filteredData?.length > 0 && filteredData.sort(function (a, b) { return new Date(a.wall_clock) - new Date(b.wall_clock) })?.map((item, index) => {
        if(!item?.previousPlayerID){
         if (!(item?.fantasyPosition == "TeamD" && item?.type != "shotagainst")) {
            let i=1;
            if (index === 0) filteredData[index].runningTotal = getPoints(item, item.fantasyPosition);
            else {
              if(filteredData[index-i]?.previousPlayerID){
                for(let j=index-1;j>0;j--){
                  if(filteredData[j]?.previousPlayerID){
                    i++;
                  }else{
                    break
                  }
                }
              }

              filteredData[index].runningTotal =
                (filteredData[index - i]?.runningTotal) + getPoints(item, item.fantasyPosition);
            }
            return <MyScoreDetailsMobileCard
              data={item}
              index={index}
              runningTotal={
                filteredData[index].runningTotal
              }
            />
          }
        }
        else {
          // swappedPlayers?.length>0 && swappedPlayers?.map((item)=>{
          return (
            <>
              <RowSwapPlayer
                  position={item?.newPlayerPosition == "TeamD" ? "TD" : item?.newPlayerPosition == "G1" ? "G" : item?.newPlayerPosition=="C1"?"C":item?.newPlayerPosition}
                  name={item?.previousPlayerName}
                  time={`P${item?.period + 1} | ${item?.clock}`}
                  sStamp={moment.utc(item?.updated_at).local().format("hh:mm:ss A")}
                  plays={`Player swapped for ${item?.newPlayerData?.full_name}`}
                  // pts={poss == "TD" ? item?.fantasyLog?.homeTeamD : item?.fantasyLog?.playerPts}
                  // totalPts="8"
                  // powers={<Replace
                  //   size={23}
                  // />}
                 
                 
                />
            </>
          );
          // })
        } 
        })}
    
      </section>}
      <section className={styles.footer}>
        {/* <img src={footerLogo} /> */}
      </section>
      <section className='matchWrapper'>
        <LiveMatch
          live_totalTeamPts={live_totalTeamPts}
          gameType={gameType}
          gameStatus={gameStatus}
          gameID={gameID}
          swap={swap}
          setSwap={setSwap}
          secondModal={secondModal}
          setSecondModal={setSecondModal}
          boostModal={boostModal}
          swapModal={swapModal}
          ranks={rankss}
          currentWinnings={currentWinnings}
          currentRank={currentRank}
          leader={leader}
          score={score}
          counts={props.counts}
          onChangeXp={onChangeXp}
          data={data}
          selectedPlayer={selectedPlayer}
          gameInfo={props?.gameInfo}
        />
      </section>
      <BoosterPopUp secondModal={secondModal} boostModal={boostModal} counts={props.counts} onChangeXp={onChangeXp} data={data} selectedPlayer={selectedPlayer} />
    </div>
  );
};
export default NHLLiveMobile;