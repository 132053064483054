import React from 'react'
import PropTypes from 'prop-types'

function ContestRulesIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props?.size || "52"} height={props?.size || "70"} viewBox="0 0 52 70" style={{height: 'auto'}}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <path fill="#FFE0B2" d="M47.71 54.69V64H5.817c-2.57 0-4.654-2.084-4.654-4.655 0-2.57 2.084-4.654 4.654-4.654H47.71z" transform="translate(-50 -1752) translate(50 1752)"/>
                        <path fill="#DFC49C" d="M44.218 54.691L47.709 54.691 47.709 64 44.218 64z" transform="translate(-50 -1752) translate(50 1752)"/>
                        <path fill="#FB6E00" d="M50.036 2.327v51.2c0 .643-.52 1.164-1.163 1.164H5.818c-1.235-.002-2.42.488-3.293 1.361-.873.874-1.363 2.059-1.361 3.293V5.818c0-2.57 2.084-4.654 4.654-4.654h43.055c.642 0 1.163.52 1.163 1.163z" transform="translate(-50 -1752) translate(50 1752)"/>
                        <path fill="#B14D00" d="M48.873 1.164h-3.491c.642 0 1.163.52 1.163 1.163v51.2c0 .643-.52 1.164-1.163 1.164h3.49c.643 0 1.164-.521 1.164-1.164v-51.2c0-.642-.52-1.163-1.163-1.163z" transform="translate(-50 -1752) translate(50 1752)"/>
                        <path fill="#FB6E00" d="M10.473 59.345v9.31l4.134-2.067c.328-.164.713-.164 1.04 0l4.135 2.067v-9.31h-9.31zM19.782 11.636h16.29c.643 0 1.164.521 1.164 1.164v11.636c0 3.214-2.605 5.819-5.818 5.819h-6.982c-3.213 0-5.818-2.605-5.818-5.819V12.8c0-.643.521-1.164 1.164-1.164z" transform="translate(-50 -1752) translate(50 1752)"/>
                        <path fill="#B14D00" d="M36.073 11.636h-3.491c.642 0 1.163.521 1.163 1.164v11.636c0 3.214-2.604 5.819-5.818 5.819h3.491c3.213 0 5.818-2.605 5.818-5.819V12.8c0-.643-.52-1.164-1.163-1.164z" transform="translate(-50 -1752) translate(50 1752)"/>
                        <path fill="#FF9801" d="M25.6 30.255L30.255 30.255 30.255 39.564 25.6 39.564z" transform="translate(-50 -1752) translate(50 1752)"/>
                        <path fill="#FB6E00" d="M23.273 39.564h9.309c.642 0 1.163.52 1.163 1.163v3.491H22.11v-3.49c0-.643.521-1.164 1.164-1.164z" transform="translate(-50 -1752) translate(50 1752)"/>
                        <path fill="#B14D00" d="M32.582 39.564H29.09c.643 0 1.164.52 1.164 1.163v3.491h3.49v-3.49c0-.643-.52-1.164-1.163-1.164z" transform="translate(-50 -1752) translate(50 1752)"/>
                        <path fill="#000" d="M51.2 53.527v-51.2C51.2 1.042 50.158 0 48.873 0H5.818C2.606.004.004 2.606 0 5.818v53.527c.004 3.212 2.606 5.815 5.818 5.819H9.31v3.49c-.002.405.206.781.55.995.343.214.772.234 1.134.053l4.134-2.074 4.135 2.074c.361.18.791.16 1.134-.053.344-.214.552-.59.55-.994v-3.491h29.09c.643 0 1.164-.521 1.164-1.164 0-.643-.521-1.164-1.164-1.164h-1.163v-6.981c1.285 0 2.327-1.042 2.327-2.328zM2.327 5.818c0-1.928 1.563-3.49 3.491-3.49h43.055v51.2H5.818c-1.259-.002-2.484.407-3.49 1.163V5.818zm16.291 60.954l-2.45-1.226c-.656-.324-1.426-.324-2.082 0l-2.45 1.226v-6.263h6.982v6.263zm27.927-3.936h-25.6V60.51H25.6c.643 0 1.164-.52 1.164-1.164 0-.642-.521-1.163-1.164-1.163H6.982c-.643 0-1.164.52-1.164 1.163s.521 1.164 1.164 1.164h2.327v2.327H5.82c-1.929 0-3.492-1.563-3.492-3.49 0-1.929 1.563-3.491 3.491-3.491h40.727v6.981z" transform="translate(-50 -1752) translate(50 1752)"/>
                        <path fill="#000" d="M31.418 60.51h2.327c.643 0 1.164-.522 1.164-1.165 0-.642-.52-1.163-1.164-1.163h-2.327c-.642 0-1.163.52-1.163 1.163s.52 1.164 1.163 1.164zM6.982 50.036V5.818c0-.642-.521-1.163-1.164-1.163-.642 0-1.163.52-1.163 1.163v44.218c0 .643.52 1.164 1.163 1.164s1.164-.521 1.164-1.164zM18.289 27.742c1.218 2.262 3.578 3.674 6.147 3.676V38.4h-1.163c-1.286 0-2.328 1.042-2.328 2.327v3.491c0 .643.521 1.164 1.164 1.164h11.636c.643 0 1.164-.521 1.164-1.164v-3.49c0-1.286-1.042-2.328-2.327-2.328h-1.164v-6.982c2.57-.002 4.93-1.414 6.148-3.676 5.236-1.163 6.355-8.205 6.59-11.287.047-.646-.177-1.282-.618-1.756-.442-.474-1.061-.742-1.709-.74H38.4V12.8c0-1.285-1.042-2.327-2.327-2.327H19.782c-1.285 0-2.327 1.042-2.327 2.327v1.164h-3.436c-.646 0-1.263.268-1.703.742-.44.473-.665 1.108-.618 1.752.235 3.08 1.36 10.126 6.59 11.284zm23.547-11.46c-.169 2.205-.841 6.884-3.46 8.627.011-.157.024-.313.024-.473v-8.145l3.436-.01zm-9.254 26.773h-9.31v-2.328h9.31v2.328zM29.09 38.4h-2.327v-6.982h2.327V38.4zm-9.31-25.6h16.292v11.636c0 2.571-2.084 4.655-4.655 4.655h-6.982c-2.57 0-4.654-2.084-4.654-4.655V12.8zm-2.326 3.49v8.146c0 .16.012.316.024.473-2.62-1.746-3.292-6.421-3.46-8.618h3.436z" transform="translate(-50 -1752) translate(50 1752)"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

ContestRulesIcon.propTypes = {
    size: PropTypes.number,
}

export default ContestRulesIcon

