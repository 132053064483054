import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";

import classes from "./index.module.scss";
import Modal from "../Modal";
import { setNumberComma } from "../../utility/shared";
import SearchInput from "../SearchInput";
import CloseIcon from "../../icons/Close";
import TeamPointsModal from '../../pages/MyGameCenter/TeamPointsModal';
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { getPersonaUserId } from "../../actions/personaActions";
import { getLocalStorage } from "../../utility/shared";
import { CONSTANTS } from "../../utility/constants";
import axios from "axios";
import { getApiNHL, getMLBApiURL } from "../../config/environment";

function LiveStandings(props) {
  const { visible = false, onClose = () => { }, league="NHL",isMobile = false, completed = false, endTime = "", gameSetEnd = "" , userAccount={}, isInProgressGame=false,gameStatus="", gameType="", targetPoints=0 } = props || {};
  const getCurrentTime = () => {

    const offset = moment?.tz("America/New_York")?.format("Z");
    const dateTime = completed !== true ?
      moment(moment().format("YYYY-MM-DD HH:mm:ss") + offset).format("MMM DD, YYYY | hh:mm A") :
      moment(moment(`${gameSetEnd} ${endTime}`).format("YYYY-MM-DD HH:mm:ss") + offset).format("MMM DD, YYYY | hh:mm a")

    return dateTime;
  };
  
  const [filteredData, setFilteredData] = useState([]);
  const [filteredString, setFilteredString] = useState("");
  const [teamPointsModal, setTeamPointsModal] = useState(false);
  const [teamPointsModalId, setTeamPointsModalId] = useState("");
  const [liveStandingData, setLiveStandingData] = useState(props.liveStandingData || []);
  const { user_id } = useSelector((state) => state.auth.user);
  const [totalUserWon, setTotalUserWon] = useState(0);
  
  useEffect(() => {
    async function getData() {
      const gameID = props.game_id || getLocalStorage(league === "NHL"
        ? CONSTANTS.LOCAL_STORAGE_KEYS.NHL_LIVE_GAME_ID
        : CONSTANTS.LOCAL_STORAGE_KEYS.MLB_LIVE_GAME_ID);

      const challenge = gameType.toLowerCase().includes("challenge")

      if (league === "NHL" && challenge) {
        let res = await axios.get(`${getApiNHL()}/api/v1/services/fantasy/getChallengeGameRanking?gameID=${gameID}`);
        if (res.data.code == 200) {
          setLiveStandingData(res.data.finalPoints);
          return;
        }
      }

      if (league === "MLB" && challenge) {
        let res = await axios.get(`${getMLBApiURL()}/mlb/api/v1/games/get-fantasy-game-rankings?gameId=${gameID}`);
        if (res?.data) {
          let finalPoints = res?.data?.rankings;
          if(finalPoints?.length>0 ){
            finalPoints?.map((item,index)=>{
              finalPoints[index].totalValue=item?.totalPoints
              finalPoints[index]._id={
                user_display_name:item.name,
                userID:item.userId,
                gameID:item?.gameId
              }
            })
          }
          setLiveStandingData(finalPoints);
          return;
        }
      }

      setLiveStandingData(props.liveStandingData)
    }
    
    getData()
  }, [props.game_id, gameType, league, props.liveStandingData, setLiveStandingData])
  
  useEffect(() => {
    let obj = liveStandingData;
    if (!!obj && obj.length > 0) {
      obj = [...obj]
      obj.sort(function(a,b){return (b.totalValue) - (a.totalValue)})?.forEach((item, index) => {
        if (!!item._id) {
          if (item._id.userID === user_id) {
            if (index !== 0) {
              obj.unshift(item);
              obj.splice(index + 1, 1);
            }
          }
        }
      })
    }
    setFilteredData(obj);
  }, [liveStandingData])

  useEffect(() => {
    let x = liveStandingData?.filter((x) => x.totalValue >= targetPoints).length
    setTotalUserWon(x)
  },[liveStandingData])

  const onSearch = (e) => {
    const { value } = e?.target || {};
    setFilteredString(value);
    if (!isEmpty(value)) {
      const result = liveStandingData?.filter((data) => {
        if(data?._id){
          const [firstName, lastName] = `${data?._id?.user_display_name}`.split(
            " "
          );
          
          if (firstName && lastName) {
            return firstName?.startsWith(value) || lastName?.startsWith(value);
          }
          let aaa = data?._id?.user_display_name;
  
          return aaa?.toLowerCase()?.startsWith(value);
      }
      else{
        const [firstName, lastName] = `${data?.team?.user?.display_name}`.split(
          " "
        );
        
        if (firstName && lastName) {
          return firstName?.startsWith(value) || lastName?.startsWith(value);
        }
        let aaa = data?.team?.user?.display_name;

        return aaa?.toLowerCase()?.startsWith(value);
      }
      });
      setFilteredData(result);
      console.log("result---",result);
    } else {
      setFilteredData(liveStandingData);
    }
  };
  const handleViewTeam = (id) => {
    setTeamPointsModal(true);
    setTeamPointsModalId(id)
  }

  const Row = (item, ind) => (
    <>
      <div className={`${classes.table_row} ${item?._id?.userID == getPersonaUserId()  && classes.active}`} key={ind}>
        <span>{item?.rank}</span>
        <span className={classes.cliped_span}>{item?._id?.user_display_name}</span>
        <span>{item?.totalValue}</span>
        <span>${gameStatus!=="Activated"? 
          gameType.toLowerCase().includes("challenge") && item?.totalValue >= targetPoints ? Number(props.prizePool/totalUserWon).toFixed(2) : 
          gameType.toLowerCase().includes("challenge") && item?.totalValue < targetPoints ? 0 :
          (item?.prize ? isMobile ? Number(setNumberComma(parseFloat(item?.prize).toFixed(2))) : Number(setNumberComma(parseFloat(item?.prize).toFixed(2))) : 0):0}</span>
        <span>
          {/* {ind !== 0 && ( */}
            <button className={classes.button_btn} onClick={()=>handleViewTeam(item?._id?.userID)}>
              {isMobile ? "Team" : "View Team"}
            </button>
          {/* )} */}
          {
            teamPointsModal &&teamPointsModalId===item?._id?.userID
            &&
            <TeamPointsModal
              isMlb={props?.isMlb}
              gameStatus={gameStatus}
              isVisible={teamPointsModal}
              onClose={() => setTeamPointsModal(false)}
              item={item}
              gameId={item?._id?.gameID}
              userId={item?._id?.userID}
            />
          }
        </span>
      </div>
    </>
  );

  return (
    <Modal visible={visible} onClose={onClose} iconStyle={{ display: "none" }}>
      <div
        className={`${classes.container} ${isMobile ? classes.mobileContainer : ""
          }`}
      >
        <div className={classes.modal_icon}>
           <CloseIcon className={classes.svg} onClick={onClose} />
        </div>
        <div className={classes.header}>
          <div className={classes.topHeadingLeft}>
           {isInProgressGame &&( <p className={classes.header_p}>Live Standings {gameType.toLowerCase().includes("challenge") ? ` - ${targetPoints} Point Challenge` : ""}</p>)}
           {!isInProgressGame &&( <p className={classes.header_p}>{completed !== true && !userAccount ? gameType.toLowerCase().includes("challenge") ? `Live Standings - ${targetPoints} Point Challenge` : "Live Standings" : gameType.toLowerCase().includes("challenge") ? `Final Standings - ${targetPoints} Point Challenge` : "Final Standings"}</p>)}
            <span>{`${getCurrentTime()} ET`}</span>
          </div>

          <div className={classes.header_right}>
            <p className={classes.header_p}>
             $<>{setNumberComma(props.prizePool, 2)}</>
           
            </p>
            <span>Prize Pool</span>
          </div>
        </div>
        <div className={classes.divider} />

        <div className={classes.body}>
          <div className={classes.body_header}>
            <SearchInput
            search={filteredString}
              placeholder="Search by Display name"
              onSearch={onSearch}
            />
          </div>

          <div className={classes.body_table}>
            <div className={classes.table_header}>
              <span>Place</span>
              <span>Name</span>
              <span>Points</span>
              <span>Winning</span>
              <span>Action</span>
            </div>
            <div className={classes.table_content}>
              {filteredData !== undefined && filteredString !== "" ? (
                filteredData?.length ? (
                  gameType.toLowerCase().includes("challenge") ? 
                  <>
                    {filteredData?.filter((x) => x.totalValue >= targetPoints).map(Row)}
                    <div className={classes.target_line}>{targetPoints} Point Target</div>
                    {filteredData?.filter((x) => x.totalValue < targetPoints).map(Row)}
                  </>
                  : filteredData.map(Row)
                ) : (
                  <h2>No data found.</h2>
                )
              ) : liveStandingData && liveStandingData?.length ? (
                gameType.toLowerCase().includes("challenge") ? 
                  <>
                    {liveStandingData?.filter((x) => x.totalValue >= targetPoints).map(Row)}
                    <div className={classes.target_line}>{targetPoints} Point Target</div>
                    {liveStandingData?.filter((x) => x.totalValue < targetPoints).map(Row)}
                  </>
                   : liveStandingData.map(Row)
              ) : (
                <h2>No data found.</h2>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal >
  );
}

export default LiveStandings;
