import {React, useState,useEffect,useRef} from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";

import "./stats.scss";
import HockeyIcon from "../../assets/icons/nhl/hockey.svg";
import ClockIcon from "../../assets/icons/nhl/clock.svg";
import SoccerIcon from "../../assets/icons/nhl/soccer.svg";
import SoccerJerseyIcon from "../../assets/icons/nhl/soccer-jersey.svg";
import * as NHLActions from "../../actions/NHLActions";


function NHLFooterStats(props) {
  const {
    player = {},
    isTeamD = false,
    teamD = {},
    onClickBack = () => {},
    onClickDetails = () => {},
    showSummary = false,
    largeView = false,
    title = "",
    cardType,
    status,
    setBoosterCState=(state)=>{},
    setBoosterXW1State=(state)=>{},
    setBoosterXW2State=(state)=>{},
    setBoosterXW3State=(state)=>{},
    setBoosterD1State=(state)=>{},
    setBoosterD2State=(state)=>{},
    setDWellGState=(state)=>{},
  } = props || {};
  const {
    live_match_events = {},
    match_status = [],
    
  } = useSelector((state) => state.nhl);
  const {setNhlEventData=[]}=useSelector((state)=>state.nhl)
  const {live_players=[]}=useSelector((state)=>state.nhl)
  // console.log("live--->",live_players);

   
  const getTeamPoints = (id, id2) => {
    let a;
    let b=0
    // let filteredData = match_status.filter(x => x.id == id);
    Object.keys(setNhlEventData).map((item, i) => {
      
      if(item===id){
        if(setNhlEventData[item]?.length!==0){
          let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);
          if(liveClockData?.length > 0)
          {
            a = liveClockData[0];
            if(a.away.id == id2){
              b=a?.away?.points
              return a?.away?.points;
            }
            if(a.home.id == id2){
              b=a?.home?.points
              return a?.home?.points;
            }
          }
        }
      }  
    // return false;
    })
    return b
    // return false;
  }
  
  // const getTeamPoints = (id, id2) => {
  //   let filteredData = match_status.filter(x => x.id == id);
  //   if(filteredData.length > 0 && (Object.keys(filteredData[filteredData.length - 1]).length)>1)
  //   {
  //     let a = filteredData[filteredData.length - 1];
  //     if(a.away.id == id2){
  //       return a?.away?.points;
  //     }
  //     if(a.home.id == id2){
  //       return a?.home?.points;
  //     }
  //   }
  //   return false;
  // };

///live clock setup....

const [timeLeft, setTimeLeft] = useState("20:00");
const [timeLeftG, setTimeLeftG] = useState("20:00");
const [timeLeftC, setTimeLeftC] = useState("20:00");
const [timeLeftXW1, setTimeLeftXW1] = useState("20:00");
const [timeLeftXW2, setTimeLeftXW2] = useState("20:00");
const [timeLeftXW3, setTimeLeftXW3] = useState("20:00");
const [timeLeftD1, setTimeLeftD1] = useState("20:00");
const [timeLeftD2, setTimeLeftD2] = useState("20:00");

const [checkClockData, setCheckClockData] = useState("20:00")
const [checkClockDataG, setCheckClockDataG] = useState("20:00")
const [checkClockDataXW1, setCheckClockDataXW1] = useState("20:00")
const [checkClockDataXW2, setCheckClockDataXW2] = useState("20:00")
const [checkClockDataXW3, setCheckClockDataXW3] = useState("20:00")
const [checkClockDataD1, setCheckClockDataD1] = useState("20:00")
const [checkClockDataD2, setCheckClockDataD2] = useState("20:00")
const [checkClockDataC, setCheckClockDataC] = useState("20:00")

const [firstRenderClock, setFirstRenderClock] = useState(true)
const [firstRenderClockG, setFirstRenderClockG] = useState(true)
const [firstRenderClockXW1, setFirstRenderClockXW1] = useState(true)
const [firstRenderClockXW2, setFirstRenderClockXW2] = useState(true)
const [firstRenderClockXW3, setFirstRenderClockXW3] = useState(true)
const [firstRenderClockD1, setFirstRenderClockD1] = useState(true)
const [firstRenderClockD2, setFirstRenderClockD2] = useState(true)
const [firstRenderClockC, setFirstRenderClockC] = useState(true)

const [clockWithNew, setClockWithNew] = useState(true)
const [clockWithNewG, setClockWithNewG] = useState(true)
const [clockWithNewXW1, setClockWithNewXW1] = useState(true)
const [clockWithNewXW2, setClockWithNewXW2] = useState(true)
const [clockWithNewXW3, setClockWithNewXW3] = useState(true)
const [clockWithNewD1, setClockWithNewD1] = useState(true)
const [clockWithNewD2, setClockWithNewD2] = useState(true)
const [clockWithNewC, setClockWithNewC] = useState(true)

const [checkEventIdC, setCheckEventIdC] = useState(0)
const [checkEventIdXW1, setCheckEventIdXW1] = useState(0)
const [checkEventIdXW2, setCheckEventIdXW2] = useState(0)
const [checkEventIdXW3, setCheckEventIdXW3] = useState(0)
const [checkEventIdD1, setCheckEventIdD1] = useState(0)
const [checkEventIdD2, setCheckEventIdD2] = useState(0)
const [checkEventIdG, setCheckEventIdG] = useState(0)
const [checkEventIdTD, setCheckEventIdTD] = useState(0)


const [periodForTD, setPeriodForTD] = useState(1)
const [periodForC, setPeriodForC] = useState(1)
const [periodForXW1, setPeriodForXW1] = useState(1)
const [periodForXW2, setPeriodForXW2] = useState(1)
const [periodForG, setPeriodForG] = useState(1)
const [periodForXW3, setPeriodForXW3] = useState(1)
const [periodForD1, setPeriodForD1] = useState(1)
const [periodForD2, setPeriodForD2] = useState(1)

const [clockcountDown, setClockcountDown] = useState(false)
useEffect(() => {
  setTimeout(() => {
    setClockcountDown(true)
  }, 2200);
}, [])

const getTeamPoints1 = (id) => {
  let filteredData = match_status.filter(x => x?.id == id);
  if(filteredData?.length > 0)
  {
    let a = filteredData[filteredData.length - 1];
    return a;
  }
  return false;
};
// let interval;
const calculateTimeLeft = async(newClock,preClock,eventId,periodTD) => {
  // await clearInterval(interval)
  setClockWithNew(false)
  setFirstRenderClock(false)
  setCheckClockData(newClock)
  setCheckEventIdTD(eventId)
  if(timeLeft!=="00:00"){
    setPeriodForTD(periodTD)

  let myString1 = newClock
  let difference1;
  let myStringParts
  let newminute
  let newSecond
  let i=moment.duration(`00:${myString1}`).asSeconds()
  const interval =  setInterval(async () => {
    // if(checkEventIdTD===0){
    //   setClockWithNew(true)
    //   clearInterval(interval)
    // }
    myStringParts = myString1?.split(':')
    newminute= myStringParts[0]
    newSecond= myStringParts[1];
    let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
    let difference=upperTime-i
    if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
      let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
      difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
      setTimeLeft(difference1)
      i++
      }else{
        if(newClock=="00:00"){
          setPeriodForTD(periodTD+1)
          setTimeLeft("20:00")
        }
        setClockWithNew(true)
        clearInterval(interval)
      }
    }, 1000);
  }else{
    setTimeout(() => {
      setPeriodForTD(periodTD+1)
      setClockWithNew(true)
      setTimeLeft("20:00")
    }, 500);
  }

};

const timerComponents = [];
timeLeft &&Object.keys(timeLeft).forEach((interval) => {
  if (!timeLeft[interval]) {
    return;
  }
  if(interval=='minutes')
  timerComponents.push(
    <span>
      {timeLeft[interval]}{":"}
    </span>
  );
  else{
    timerComponents.push(
      <span>
        {timeLeft[interval]}
      </span>
    );
  }
});



  const getTeamData = (id) => {
    let a;
    let priviouseEvent;
    let upperTime;
    let lowerTime;
    let difference;
    Object.keys(setNhlEventData).forEach((item, i) => {
    if(item===id){
    // if(item!=='type'){
      if(setNhlEventData[item]?.length!==0){
        let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);
        if(liveClockData?.length > 0)
        {
          liveClockData=liveClockData.reverse()
          a = liveClockData[liveClockData?.length-1];
       
          if(liveClockData?.length > 1  && a?.event>checkEventIdTD){
            let i=1;
            liveClockData?.forEach((element,index) => {
              if(liveClockData[index-i]?.clock==a?.clock){
                i++;
              }
              else if(liveClockData[index-i]?.clock!=a?.clock){
                priviouseEvent=liveClockData[index-i]
              }
            });
            // priviouseEvent=liveClockData[liveClockData?.length-2]
            upperTime=moment.duration(`00:${priviouseEvent?.clock}`).asSeconds()
            lowerTime=moment.duration(`00:${a?.clock}`).asSeconds()
            difference=upperTime-lowerTime
          }
          if(a?.clock!==checkClockData){
            // if(a?.clock=="00:00"){
            //     // setTimeLeft("00:00")
            //     setTimeout(() => {
            //       setTimeLeft(a?.clock)
            //     }, 500);
            //   }
            if(timeLeft!=="00:00"){
             if(priviouseEvent &&difference>0&&clockWithNew&&firstRenderClock) {
               setTimeout(() => {
                calculateTimeLeft(a?.clock,a?.clock,a?.event,a?.period);
               }, 1000);
              }  
              else if(priviouseEvent && difference>0 &&clockWithNew&&!firstRenderClock) {
                setTimeout(() => {
                  calculateTimeLeft(a?.clock,timeLeft,a?.event,a?.period);
                }, 1000);
              }
            }else{
              // if(priviouseEvent &&difference>0&&clockWithNew&&firstRenderClock) {
              //   setTimeout(() => {
              //     calculateTimeLeft("00:00",priviouseEvent?.clock,a?.event);
              //   }, 1000);
              // }  
              // else if(priviouseEvent && difference>0 &&clockWithNew&&!firstRenderClock) {
              //   setTimeout(() => {
              //     calculateTimeLeft("00:00",timeLeft,a?.event);
              //   }, 1000);
              // }
              setTimeout(() => {
                setPeriodForTD(a?.period+1)
                setTimeLeft("20:00")
                setCheckEventIdTD(0)
              }, 500);
            } 
            // else {
            //   // calculateTimeLeft(a?.clock,"20:00");
            //   setTimeout(() => {
            //     setTimeLeft(a?.clock)
            //   }, 1000);
            // } 
            }
        }
        else{
          if(!a)
            a = false;
          }
        }
    }
    })
    if(a)
      return a
    };

    // let intervalG
    const calculateTimeLeftG = async(newClock,preClock,eventId,periodG) => {
      // await clearInterval(intervalG)
      setClockWithNewG(false)
      setFirstRenderClockG(false)
      setCheckEventIdG(eventId)
      setCheckClockDataG(newClock)
      if(timeLeftG!=="00:00"){
        setPeriodForG(periodG)
        setDWellGState(true)
      let myString1 = newClock
      let difference1;
      let myStringParts
      let newminute
      let newSecond
      let i=moment.duration(`00:${myString1}`).asSeconds()

       const intervalG =  setInterval(async () => {

        myStringParts = myString1?.split(':')
        newminute= myStringParts[0]
        newSecond= myStringParts[1];
        let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
        let difference=upperTime-i
        if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
          let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
          difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
          setTimeLeftG(difference1)
          i++
          }else{
            if(newClock=="00:00"){
              setTimeLeftG("20:00")
              setPeriodForG(periodG+1)
            }
            setClockWithNewG(true)
            setDWellGState(false)
            clearInterval(intervalG)
          }
        }, 1000);
      }else{
        setTimeout(() => {
          setPeriodForG(periodG+1)
          setClockWithNewG(true)
          setTimeLeftG("20:00")
        }, 500);
      }
    
    };


const timerComponentsG = [];
timeLeftG &&Object.keys(timeLeftG).forEach((interval) => {
  if (!timeLeftG[interval]) {
    return;
  }
  if(interval=='minutes')
  timerComponentsG.push(
    <span>
      {timeLeftG[interval]}{":"}
    </span>
  );
  else{
    timerComponentsG.push(
      <span>
        {timeLeftG[interval]}
      </span>
    );
  }
});
// console.log("setNhlEventData==>",setNhlEventData);
const getTeamDataG = (id) => {
  let a;
  let priviouseEvent;
  let upperTime;
  let lowerTime;
  let difference;
  Object.keys(setNhlEventData).forEach((item, i) => {
  if(item===id){
  // if(item!=='type'){
    if(setNhlEventData[item]?.length!==0){
      let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);
      if(liveClockData?.length > 0)
      {
        liveClockData=liveClockData.reverse()
        a = liveClockData[liveClockData?.length-1];
       
        if(liveClockData?.length > 1 && a?.event>checkEventIdG){
          let i=1;
          liveClockData?.forEach((element,index) => {
            if(liveClockData[index-i]?.clock==a?.clock){
              i++;
            }
            else if(liveClockData[index-i]?.clock!=a?.clock){
              priviouseEvent=liveClockData[index-i]
            }
          });
          // priviouseEvent=liveClockData[liveClockData?.length-2]
          upperTime=moment.duration(`00:${priviouseEvent?.clock}`).asSeconds()
          lowerTime=moment.duration(`00:${a?.clock}`).asSeconds()
          difference=upperTime-lowerTime
        }
        if(a?.clock!==checkClockDataG){
          // if(a?.clock=="00:00"){
          //     // setTimeLeft("00:00")
          //     setTimeout(() => {
          //       setTimeLeftG(a?.clock)
          //     }, 500);
          //   }
          if(timeLeftG!=="00:00"){
           if(priviouseEvent &&difference>0&&clockWithNewG&&firstRenderClockG) {
             setTimeout(() => {
              calculateTimeLeftG(a?.clock,a?.clock,a?.event,a?.period);
             }, 1000);
            } 
            else if(priviouseEvent && difference>0 &&clockWithNewG&&!firstRenderClockG) {
              setTimeout(() => {
                calculateTimeLeftG(a?.clock,timeLeftG,a?.event,a?.period);
              }, 1000);
            } 
          }
            else{
              // if(priviouseEvent &&difference>0&&clockWithNewG&&firstRenderClockG) {
              //   setTimeout(() => {
              //     calculateTimeLeftG("00:00",priviouseEvent?.clock,a?.event);
              //   }, 1000);
              // } 
              // else if(priviouseEvent && difference>0 &&clockWithNewG&&!firstRenderClockG) {
              //   setTimeout(() => {
              //     calculateTimeLeftG("00:00",timeLeftG,a?.event);
              //   }, 1000);
              // } 
              setTimeout(() => {
                setPeriodForG(a?.period+1)
                setTimeLeftG("20:00")
                setCheckEventIdG(0)
              }, 500);
            }
          // else {
          //   // calculateTimeLeftG(a?.clock,"20:00");
          //   setTimeout(() => {
          //     setTimeLeftG(a?.clock)
          //   }, 1000);
          // } 
          }
      }
      else{
        if(!a)
          a = false;
        }
      }
  }
  })
  if(a)
    return a
  };


//get clock and period for XW1
// let intervalXW1
const calculateTimeLeftXW1 = async(newClock,preClock,eventId,periodXW1) => {
  // await clearInterval(intervalXW1)
  setClockWithNewXW1(false)
  setFirstRenderClockXW1(false)
  setCheckEventIdXW1(eventId)
  setCheckClockDataXW1(newClock)
  if(timeLeftXW1!=="00:00"){
    setPeriodForXW1(periodXW1)
    setBoosterXW1State(true)

  let myString1 = newClock
  let difference1;
  let myStringParts
  let newminute
  let newSecond
  let i=moment.duration(`00:${myString1}`).asSeconds()
   const intervalXW1 =  setInterval(async () => {
  
    myStringParts = myString1?.split(':')
    newminute= myStringParts[0]
    newSecond= myStringParts[1];
    let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
    let difference=upperTime-i
    if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
      let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
      difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
      setTimeLeftXW1(difference1)
      i++
      }else{
        if(newClock=="00:00"){
          setPeriodForXW1(periodXW1+1)
          setTimeLeftXW1("20:00")
        }
        setClockWithNewXW1(true)
        setBoosterXW1State(false)
        clearInterval(intervalXW1)
      }
    }, 1000);
  }else{
    setTimeout(() => {
      setPeriodForXW1(periodXW1+1)
      setClockWithNewXW1(true)
      setTimeLeftXW1("20:00")

    }, 500);
  }

};


const timerComponentsXW1 = [];
timeLeftXW1 &&Object.keys(timeLeftXW1).forEach((interval) => {
  if (!timeLeftXW1[interval]) {
    return;
  }
  if(interval=='minutes')
  timerComponentsXW1.push(
    <span>
      {timeLeftXW1[interval]}{":"}
    </span>
  );
  else{
    timerComponentsXW1.push(
      <span>
        {timeLeftXW1[interval]}
      </span>
    );
  }
});
const getTeamDataXW1 = (id) => {
  let a;
  let priviouseEvent;
  let upperTime;
  let lowerTime;
  let difference;
  Object.keys(setNhlEventData).forEach((item, i) => {
  if(item===id){
  // if(item!=='type'){
    if(setNhlEventData[item]?.length!==0){
      let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);
      if(liveClockData?.length > 0)
      {
        liveClockData=liveClockData.reverse()
        a = liveClockData[liveClockData?.length-1];
      
        if(liveClockData?.length > 1  && a?.event>checkEventIdXW1){
          let i=1;

          liveClockData?.forEach((element,index) => {
            if(liveClockData[index-i]?.clock==a?.clock){
              i++;
            }
            else if(liveClockData[index-i]?.clock!=a?.clock){
              priviouseEvent=liveClockData[index-i]
            }
          });
          // priviouseEvent=liveClockData[liveClockData?.length-2]
          upperTime=moment.duration(`00:${priviouseEvent?.clock}`).asSeconds()
          lowerTime=moment.duration(`00:${a?.clock}`).asSeconds()
          difference=upperTime-lowerTime
        }
        if(a?.clock!==checkClockDataXW1){
          // if(a?.clock=="00:00"){
          //     // setTimeLeft("00:00")
          //     setTimeout(() => {
          //       setTimeLeftXW1(a?.clock)
          //     }, 500);
          //   }
          if(timeLeftXW1!=="00:00"){

            if(priviouseEvent &&difference>0&&clockWithNewXW1&&firstRenderClockXW1) {
              setTimeout(() => {
                calculateTimeLeftXW1(a?.clock,a?.clock,a?.event,a?.period);
              }, 1000);
            }  
            else if(priviouseEvent && difference>0 &&clockWithNewXW1&&!firstRenderClockXW1) {

              setTimeout(() => {
                calculateTimeLeftXW1(a?.clock,timeLeftXW1,a?.event,a?.period);
              }, 1000);
            }  
          }else{
            setTimeout(() => {
              setPeriodForXW1(a?.period+1)
              setTimeLeftXW1("20:00")
              setCheckEventIdXW1(0)
            }, 500);
            // if(priviouseEvent &&difference>0&&clockWithNewXW1&&firstRenderClockXW1) {
            //    setTimeout(() => {
            //     calculateTimeLeftXW1("00:00",priviouseEvent?.eventData?.clock,a?.event);
            //  }, 1000);
            // }  
            // else if(priviouseEvent && difference>0 &&clockWithNewXW1&&!firstRenderClockXW1) {
            //    setTimeout(() => {
            //     calculateTimeLeftXW1("00:00",timeLeftXW1,a?.event);
            //  }, 1000);
            // } 
          }
          // else {
          //   // calculateTimeLeftXW1(a?.eventData?.clock,"20:00");
          //   setTimeout(() => {
          //     setTimeLeftXW1(a?.eventData?.clock)
          //   }, 1000);
          // } 
          }
      }
      else{
        if(!a)
          a = false;
        }
      }
  }
  })
  if(a)
    return a
  };
// get clcok and period for XW2
// let intervalXW2
const calculateTimeLeftXW2 = async(newClock,preClock,eventId,periodXW2) => {
  // await clearInterval(intervalXW2)
  setClockWithNewXW2(false)
  setFirstRenderClockXW2(false)
  setCheckEventIdXW2(eventId)
  setCheckClockDataXW2(newClock)
  if(timeLeftXW2!=="00:00"){
    setPeriodForXW2(periodXW2)
    setBoosterXW2State(true)

  let myString1 = newClock
  let difference1;
  let myStringParts
  let newminute
  let newSecond
  let i=moment.duration(`00:${myString1}`).asSeconds()
   const intervalXW2 =  setInterval(async () => {
  
    myStringParts = myString1?.split(':')
    newminute= myStringParts[0]
    newSecond= myStringParts[1];
    let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
    let difference=upperTime-i
    if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
      let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
      difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
      setTimeLeftXW2(difference1)
      i++
      }else{
        if(newClock=="00:00"){
          setTimeLeftXW2("20:00")
          setPeriodForXW2(periodXW2+1)
          setCheckEventIdXW2(0)
        }
        setClockWithNewXW2(true)
        setBoosterXW2State(false)
        clearInterval(intervalXW2)
      }
    }, 1000);
  }else{
    setTimeout(() => {
      setPeriodForXW2(periodXW2+1)
      setClockWithNewXW2(true)
      setTimeLeftXW2("20:00")

    }, 500);
  }

};


const timerComponentsXW2 = [];
timeLeftXW2 &&Object.keys(timeLeftXW2).forEach((interval) => {
  if (!timeLeftXW2[interval]) {
    return;
  }
  if(interval=='minutes')
  timerComponentsXW2.push(
    <span>
      {timeLeftXW2[interval]}{":"}
    </span>
  );
  else{
    timerComponentsXW2.push(
      <span>
        {timeLeftXW2[interval]}
      </span>
    );
  }
});
const getTeamDataXW2 = (id) => {
  let a;
  let priviouseEvent;
  let upperTime;
  let lowerTime;
  let difference;
  Object.keys(setNhlEventData).forEach((item, i) => {
  if(item===id){
  // if(item!=='type'){
    if(setNhlEventData[item]?.length!==0){
      let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);
      if(liveClockData?.length > 0)
      {
        liveClockData=liveClockData.reverse()
        a = liveClockData[liveClockData?.length-1];
        if(liveClockData?.length > 1  && a?.event>checkEventIdXW2){
          let i=1;
          liveClockData?.forEach((element,index) => {
            if(liveClockData[index-i]?.clock==a?.clock){
              i++;
            }
            else if(liveClockData[index-i]?.clock!=a?.clock){
              priviouseEvent=liveClockData[index-i]
            }
          });
          // priviouseEvent=liveClockData[liveClockData?.length-2]
          upperTime=moment.duration(`00:${priviouseEvent?.clock}`).asSeconds()
          lowerTime=moment.duration(`00:${a?.clock}`).asSeconds()
          difference=upperTime-lowerTime
        }
        if(a?.clock!==checkClockDataXW2){
          // if(a?.clock=="00:00"){
          //     // setTimeLeft("00:00")
          //     setTimeout(() => {
          //       setTimeLeftXW2(a?.clock)
          //     }, 500);
          //   }
          if(timeLeftXW2!=="00:00"){
          if(priviouseEvent &&difference>0&&clockWithNewXW2&&firstRenderClockXW2) {
             setTimeout(() => {
              calculateTimeLeftXW2(a?.clock,a?.clock,a?.event,a?.period);
             }, 1000);
            }  
          else if(priviouseEvent && difference>0 &&clockWithNewXW2&&!firstRenderClockXW2) {
             setTimeout(() => {
              calculateTimeLeftXW2(a?.clock,timeLeftXW2,a?.event,a?.period);
             }, 1000);
          }
        }else{
          // if(priviouseEvent &&difference>0&&clockWithNewXW2&&firstRenderClockXW2) {
          //    setTimeout(() => {
          //     calculateTimeLeftXW2("00:00",priviouseEvent?.clock,a?.event);
          //    }, 1000);
          //   }  
          // else if(priviouseEvent && difference>0 &&clockWithNewXW2&&!firstRenderClockXW2) {
          //    setTimeout(() => {
          //     calculateTimeLeftXW2("00:00",timeLeftXW2,a?.event);
          //    }, 1000);
          // }
          setTimeout(() => {
            setPeriodForXW2(a?.period+1)
            setTimeLeftXW2("20:00")
            setCheckEventIdXW2(0)
          }, 500);
        }  
          // else {
          //   // calculateTimeLeftXW2(a?.clock,"20:00");
          //   setTimeout(() => {
          //     setTimeLeftXW2(a?.clock)
          //   }, 1000);
          // } 
          }
        }
          // setTimeout(() => {
          //   setTimeLeftXW2(a?.clock)
          // }, 500);
      
      else{
        if(!a)
          a = false;
        }
      }
  }
  })
  if(a)
    return a
  };
//get clock and perod for XW3
// let intervalXW3
const calculateTimeLeftXW3 = async(newClock,preClock,eventId,periodXW3) => {
  // await clearInterval(intervalXW3)
    // setTimeout(() => {
    // }, 500);

  setClockWithNewXW3(false)
  setFirstRenderClockXW3(false)
  setCheckEventIdXW3(eventId)
  setCheckClockDataXW3(newClock)
  if(timeLeftXW3!=="00:00"){
    setPeriodForXW3(periodXW3)
    setBoosterXW3State(true)

  let myString1 = newClock
  let difference1;
  let myStringParts
  let newminute
  let newSecond
  let i=moment.duration(`00:${myString1}`).asSeconds()
   const intervalXW3 =  setInterval(async () => {
    // if(checkEventIdXW3===0){
    //   setClockWithNewXW3(true)
    //   clearInterval(intervalXW3)
    //   setBoosterXW3State(false)
    // }
    myStringParts = myString1?.split(':')
    newminute= myStringParts[0]
    newSecond= myStringParts[1];
    let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
    let difference=upperTime-i
    if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
      let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
      difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
      setTimeLeftXW3(difference1)
      i++
      }else{
        if(newClock=="00:00"){
          setPeriodForXW3(periodXW3+1)
          setTimeLeftXW3("20:00")
          // setPeriodForXW3(periodForXW3+1)
        }
        setClockWithNewXW3(true)
        clearInterval(intervalXW3)
        setBoosterXW3State(false)

      }
    }, 1000);
  }else{
    setTimeout(() => {
      setPeriodForXW3(periodXW3+1)
      setClockWithNewXW3(true)
      setTimeLeftXW3("20:00")
    }, 500);
  }
};


const timerComponentsXW3 = [];
timeLeftXW3 &&Object.keys(timeLeftXW3).forEach((interval) => {
  if (!timeLeftXW3[interval]) {
    return;
  }
  if(interval=='minutes')
  timerComponentsXW3.push(
    <span>
      {timeLeftXW3[interval]}{":"}
    </span>
  );
  else{
    timerComponentsXW3.push(
      <span>
        {timeLeftXW3[interval]}
      </span>
    );
  }
});
const getTeamDataXW3 = (id) => {
let a;
let priviouseEvent;
let upperTime;
let lowerTime;
let difference;
Object.keys(setNhlEventData).forEach((item, i) => {
if(item===id){
// if(item!=='type'){
  if(setNhlEventData[item]?.length!==0){
    let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);
    if(liveClockData?.length > 0)
    {
      liveClockData=liveClockData.reverse()
      a = liveClockData[liveClockData?.length-1];
    
      if(liveClockData?.length > 1  && a?.event>checkEventIdXW3){
        let i=1;
        liveClockData?.forEach((element,index) => {
          if(liveClockData[index-i]?.clock==a?.clock){
            i++;
          }
          else if(liveClockData[index-i]?.clock!=a?.clock){
            priviouseEvent=liveClockData[index-i]
          }
        });
        // priviouseEvent=liveClockData[liveClockData?.length-2]
        upperTime=moment.duration(`00:${priviouseEvent?.clock}`).asSeconds()
        lowerTime=moment.duration(`00:${a?.clock}`).asSeconds()
        difference=upperTime-lowerTime
      }
      if(a?.clock!==checkClockDataXW3){
        // if(a?.clock=="00:00"){
        //     // setTimeLeft("00:00")
        //     setTimeout(() => {
        //       setTimeLeftXW3(a?.clock)
        //     }, 500);
        //   }
        if(timeLeftXW3!=="00:00"){
          if(priviouseEvent &&difference>0 &&clockWithNewXW3&&firstRenderClockXW3) {
               setTimeout(() => {
                calculateTimeLeftXW3(a?.clock,a?.clock,a?.event,a?.period);
             }, 1000);
            }  
          else if(priviouseEvent && difference>0 &&clockWithNewXW3&&!firstRenderClockXW3) {
             setTimeout(() => {
              calculateTimeLeftXW3(a?.clock,timeLeftXW3,a?.event,a?.period);
             }, 1000);
          }  
        }else{
        //   if(priviouseEvent &&difference>0 &&clockWithNewXW3&&firstRenderClockXW3) {
        //      setTimeout(() => {
        //       calculateTimeLeftXW3("00:00",priviouseEvent?.clock,a?.event);
        //      }, 1000);
        //   }  
        // else if(priviouseEvent && difference>0 &&clockWithNewXW3&&!firstRenderClockXW3) {
        //    setTimeout(() => {
        //     calculateTimeLeftXW3("00:00",timeLeftXW3,a?.event);
        //      }, 1000);
        // } 
        setTimeout(() => {
          setPeriodForXW3(a?.period+1)
          setTimeLeftXW3("20:00")
          setCheckEventIdXW3(0)
        }, 500);
      }  
        // else {
        //   // calculateTimeLeftXW3(a?.clock,"20:00");
        //   setTimeout(() => {
        //     setTimeLeftXW3(a?.clock)
        //   }, 1000);
        // } 
        }
    }
    else{
      if(!a)
        a = false;
      }
    }
}
})
if(a)
  return a
};
//get clock and  period for D1
// let intervalD1
const calculateTimeLeftD1 =async (newClock,preClock,eventId,periodD1) => {
  // await clearInterval(intervalD1)
  setClockWithNewD1(false)
  setFirstRenderClockD1(false)
  setCheckEventIdD1(eventId)
  setCheckClockDataD1(newClock)
  if(timeLeftD1!=="00:00"){
    setBoosterD1State(true)
    setPeriodForD1(periodD1)

  let myString1 = newClock
  let difference1;
  let myStringParts
  let newminute
  let newSecond
  let i=moment.duration(`00:${myString1}`).asSeconds()
   const intervalD1 =  setInterval(async () => {
  
    myStringParts = myString1?.split(':')
    newminute= myStringParts[0]
    newSecond= myStringParts[1];
    let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
    let difference=upperTime-i
    if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
      let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
      difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
      setTimeLeftD1(difference1)
      i++
      }else{
        if(newClock=="00:00"){
          setPeriodForD1(periodD1+1)
          setTimeLeftD1("20:00")
        }
        setBoosterD1State(false)
        setClockWithNewD1(true)
        clearInterval(intervalD1)
    
      }
    }, 1000);
  }else{
    setTimeout(() => {
      setPeriodForD1(periodD1+1)
      setClockWithNewD1(true)
      setTimeLeftD1("20:00")

    }, 500);
  }

};


const timerComponentsD1 = [];
timeLeftD1 &&Object.keys(timeLeftD1).forEach((interval) => {
  if (!timeLeftD1[interval]) {
    return;
  }
  if(interval=='minutes')
  timerComponentsD1.push(
    <span>
      {timeLeftD1[interval]}{":"}
    </span>
  );
  else{
    timerComponentsD1.push(
      <span>
        {timeLeftD1[interval]}
      </span>
    );
  }
});
let d1;

const getTeamDataD1 = (id) => {
  let priviouseEvent;
  let upperTime;
  let lowerTime;
  let difference;
  Object.keys(setNhlEventData).forEach((item, i) => {
  if(item===id){
  // if(item!=='type'){
    if(setNhlEventData[item]?.length!==0){
      let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);
      if(liveClockData?.length > 0)
      {
        liveClockData=liveClockData.reverse()
        d1 = liveClockData[liveClockData?.length-1];
        if(liveClockData?.length > 1  && d1?.event>checkEventIdD1){
          let i=1;
          liveClockData?.forEach((element,index) => {
            if(liveClockData[index-i]?.clock==d1?.clock){
              i++;
            }
            else if(liveClockData[index-i]?.clock!=d1?.clock){
              priviouseEvent=liveClockData[index-i]
            }
          });
          // priviouseEvent=liveClockData[liveClockData?.length-2]
          upperTime=moment.duration(`00:${priviouseEvent?.clock}`).asSeconds()
          lowerTime=moment.duration(`00:${d1?.clock}`).asSeconds()
          difference=upperTime-lowerTime
        }
        if(d1?.clock!==checkClockDataD1){
          // if(d1?.clock=="00:00"){
          //     // setTimeLeft("00:00")
          //     setTimeout(() => {
          //       setTimeLeftD1(d1?.clock)
          //     }, 500);
          //   }
          if(timeLeftD1!=="00:00"){
            if(priviouseEvent &&difference>0&&clockWithNewD1&&firstRenderClockD1) {
                 setTimeout(() => {
                  calculateTimeLeftD1(d1?.clock,d1?.clock,d1?.event,d1?.period);
             }, 1000);
              }  
            else if(priviouseEvent && difference>0 &&clockWithNewD1&&!firstRenderClockD1) {
               setTimeout(() => {
                calculateTimeLeftD1(d1?.clock,timeLeftD1,d1?.event,d1?.period);
             }, 1000);
            }   
          }else{
          //   if(priviouseEvent &&difference>0&&clockWithNewD1&&firstRenderClockD1) {
          //      setTimeout(() => {
          //       calculateTimeLeftD1("00:00",priviouseEvent?.clock,d1?.event);
          //    }, 1000);
          //   }  
          // else if(priviouseEvent && difference>0 &&clockWithNewD1&&!firstRenderClockD1) {
          //    setTimeout(() => {
          //     calculateTimeLeftD1("00:00",timeLeftD1,d1?.event);
          //    }, 1000);
          // }}
          setTimeout(() => {
            setPeriodForD1(d1?.period+1)
            setTimeLeftD1("20:00")
            setCheckEventIdD1(0)

          }, 500);
          } 
          // else {
          //   // calculateTimeLeftD1(a?.clock,"20:00");
          //   setTimeout(() => {
          //     setTimeLeftD1(d1?.clock)
          //   }, 1000);
          // } 
          }
      }
      else{
        if(!d1)
        d1 = false;
        }
      }
  }
  })
  if(d1)
    return d1
  };
//get clock and period for D2
// let intervalD2

const calculateTimeLeftD2 = async(newClock,preClock,eventId,periodD1) => {
  // await clearInterval(intervalD2)
  setClockWithNewD2(false)
  setFirstRenderClockD2(false)
  setCheckEventIdD2(eventId)
  
  setCheckClockDataD2(newClock)
  if(timeLeftD2!=="00:00"){
    setBoosterD2State(true)
    setPeriodForD2(periodD1)
  let myString1 = newClock
  let difference1;
  let myStringParts
  let newminute
  let newSecond
  let i=moment.duration(`00:${myString1}`).asSeconds()
   const intervalD2 =  setInterval(async () => {

    myStringParts = myString1?.split(':')
    newminute= myStringParts[0]
    newSecond= myStringParts[1];
    let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
    let difference=upperTime-i
    if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
      let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
      difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
      setTimeLeftD2(difference1)
      i++
      }else{
        if(newClock=="00:00"){
          setPeriodForD2(periodD1+1)
          setTimeLeftD2("20:00")
        }
        setBoosterD2State(false)
        clearInterval(intervalD2)
        setClockWithNewD2(true)
      }
    }, 1000);
  }else{
    setTimeout(() => {
      setPeriodForD2(periodD1+1)
      setClockWithNewD2(true)
      setTimeLeftD2("20:00")

    }, 500);
  }

};


const timerComponentsD2 = [];
timeLeftD2 &&Object.keys(timeLeftD2).forEach((interval) => {
  if (!timeLeftD2[interval]) {
    return;
  }
  if(interval=='minutes')
  timerComponentsD2.push(
    <span>
      {timeLeftD2[interval]}{":"}
    </span>
  );
  else{
    timerComponentsD2.push(
      <span>
        {timeLeftD2[interval]}
      </span>
    );
  }
});
let d2;
const getTeamDataD2 = (id) => {
  let priviouseEvent;
  let upperTime;
  let lowerTime;
  let difference;
  Object.keys(setNhlEventData).forEach((item, i) => {

  if(item===id){
  // if(item!=='type'){
    if(setNhlEventData[item]?.length!==0){
      let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);

      if(liveClockData?.length > 0 )
      {
        liveClockData=liveClockData.reverse()
        d2 = liveClockData[liveClockData?.length-1];
    
        if(liveClockData?.length > 1 && d2?.event>checkEventIdD2){
          let i=1;
          liveClockData?.forEach((element,index) => {
            if(liveClockData[index-i]?.clock==d2?.clock){
              i++;
            }
            else if(liveClockData[index-i]?.clock!=d2?.clock){
              priviouseEvent=liveClockData[index-i]
            }
          });
          // priviouseEvent=liveClockData[liveClockData?.length-2]
          upperTime=moment.duration(`00:${priviouseEvent?.clock}`).asSeconds()
          lowerTime=moment.duration(`00:${d2?.clock}`).asSeconds()
          difference=upperTime-lowerTime
        }

        if(d2?.clock!==checkClockDataD2){  
          
          if(timeLeftD2!=="00:00"){
            if(priviouseEvent && difference>0 &&clockWithNewD2 &&firstRenderClockD2) {
                setTimeout(() => {
                  calculateTimeLeftD2(d2?.clock,d2?.clock,d2?.event,d2?.period);
                }, 1000);
                } 
              else if(priviouseEvent && difference>0 &&clockWithNewD2&&!firstRenderClockD2) {
                setTimeout(() => {
                  calculateTimeLeftD2(d2?.clock,timeLeftD2,d2?.event,d2?.period);
                }, 1000);
              }
            }else{
              // if(priviouseEvent && difference>0 &&clockWithNewD2 &&firstRenderClockD2) {
              //   setTimeout(() => {
              //     calculateTimeLeftD2("00:00",priviouseEvent?.clock,d2?.event);
              //   }, 1000);
              //   } 
              // else if(priviouseEvent && difference>0 &&clockWithNewD2&&!firstRenderClockD2) {
              //   setTimeout(() => {
              //     calculateTimeLeftD2("00:00",timeLeftD2,d2?.event);
              //   }, 1000);
              // }
              setTimeout(() => {
                setPeriodForD2(d2?.period+1)
                setTimeLeftD2("20:00")
                setCheckEventIdD2(0)
              }, 500);
            }  
          // else {
          //   // calculateTimeLeftD2(a?.clock,"20:00");
          //   setTimeout(() => {
          //     setTimeLeftD2(a?.clock)
          //   }, 1000);
          // } 
          }
      }
      else{
          if(!d2)
          d2 = false;
        }
      }
  }
  })
  if(d2)
    return d2
  };

//get clock and period for C
// let intervalC
const calculateTimeLeftC = async(newClock,preClock,eventId,periodC) => {
  // await clearInterval(intervalC)
  setClockWithNewC(false)
  setFirstRenderClockC(false)
  setCheckEventIdC(eventId)
  setCheckClockDataC(newClock)
  if(timeLeftC!=="00:00"){
    setBoosterCState(true)
    setPeriodForC(periodC)
  let myString1 = newClock
  let difference1;
  let myStringParts
  let newminute
  let newSecond
  let i=moment.duration(`00:${myString1}`).asSeconds()
   const intervalC =  setInterval(async () => {
  
    myStringParts = myString1?.split(':')
    newminute= myStringParts[0]
    newSecond= myStringParts[1];
    let upperTime=moment.duration(`00:${preClock}`).asSeconds()  
    let difference=upperTime-i
    if (moment.duration(`00:${preClock}`).asSeconds() >=i) {
      let formatted = moment.utc(difference*1000).format('mm:ss').split(":");
      difference1=moment({ minutes: formatted[0], seconds: formatted[1]}).add({ minutes: newminute, seconds: newSecond}).format('mm:ss');
      setTimeLeftC(difference1)
      i++
      }else{
        if(newClock=="00:00"){
          setPeriodForC(periodC+1)
          setTimeLeftC("20:00")
        }
        setBoosterCState(false)
        setClockWithNewC(true)
        clearInterval(intervalC)
      }
    }, 1000);
  }else{
    setTimeout(() => {
      setPeriodForC(periodC+1)
      setClockWithNewC(true)
      setTimeLeftC("20:00")

    }, 500);
  }

};

const timerComponentsC = [];
timeLeftC &&Object.keys(timeLeftC).forEach((interval) => {
  if (!timeLeftC[interval]) {
    return;
  }
  if(interval=='minutes')
  timerComponentsC.push(
    <span>
      {timeLeftC[interval]}{":"}
    </span>
  );
  else{
    timerComponentsC.push(
      <span>
        {timeLeftC[interval]}
      </span>
    );
  }
});
const getTeamDataC = (id) => {
  let a;
  let priviouseEvent;
  let upperTime;
  let lowerTime;
  let difference;
  Object.keys(setNhlEventData).forEach((item, i) => {
  if(item===id){
  // if(item!=='type'){
    if(setNhlEventData[item]?.length!==0){
      let liveClockData = setNhlEventData[item].filter(x => x?.matchId  == id);
      if(liveClockData?.length > 0)
      {
        liveClockData=liveClockData.reverse()
        a = liveClockData[liveClockData?.length-1];
        if(liveClockData?.length > 1  && a?.event>checkEventIdC){
          let i=1;
          liveClockData?.map((element,index) => {
            if(liveClockData[index-i]?.clock==a?.clock){
              i++;
            }
            else if(liveClockData[index-i]?.clock!=a?.clock){
              priviouseEvent=liveClockData[index-i]
            }

          });

          // priviouseEvent=liveClockData[liveClockData?.length-2]
          upperTime=moment.duration(`00:${priviouseEvent?.clock}`).asSeconds()
          lowerTime=moment.duration(`00:${a?.clock}`).asSeconds()
          difference=upperTime-lowerTime
        }
        if(moment.duration(`00:${a?.clock}`).asSeconds()<moment.duration(`00:${checkClockDataC}`).asSeconds()){
          // if(a?.clock=="00:00"){
          //     // setTimeLeft("00:00")
          //     setTimeout(() => {
          //       setTimeLeftC(a?.clock)
          //     }, 500);
          //   }
          if(timeLeftC!=="00:00"){
           if(priviouseEvent &&difference>0&&clockWithNewC&&firstRenderClockC) {
               setTimeout(() => {
                calculateTimeLeftC(a?.clock,a?.clock,a?.event,a?.period);
             }, 1000);
            }  
          else if(priviouseEvent && difference>0 &&clockWithNewC&&!firstRenderClockC) {
             setTimeout(() => {
              calculateTimeLeftC(a?.clock,timeLeftC,a?.event,a?.period);
             }, 1000);
          }   
        }else{
          setTimeout(() => {
            setPeriodForC(a?.period+1)
            setTimeLeftC("20:00")
            setCheckEventIdC(0)

          }, 500);
        //   if(priviouseEvent &&difference>0&&clockWithNewC&&firstRenderClockC) {
        //      setTimeout(() => {
        //       calculateTimeLeftC("00:00",priviouseEvent?.clock,a?.event);
        //      }, 1000);
        //   }  
        // else if(priviouseEvent && difference>0 &&clockWithNewC&&!firstRenderClockC) {
        //    setTimeout(() => {
        //     calculateTimeLeftC("00:00",timeLeftC,a?.event);
        //      }, 1000);
        // } 
        }
          // else {
          //   // calculateTimeLeftC(a?.clock,"20:00");
          //   setTimeout(() => {
          //     setTimeLeftC(a?.clock)
          //   }, 1000);
          // } 
          }
      }
      else{
        if(!a)
          a = false;
        }
      }
  }
  })
  if(a)
    return a
  };
  //Player Details
  const { match, OppGoalie = "0", team = {},teamId="" ,matchId=""} = player || {};
  const { home, away } = match || {};
  //TeamD Details
  const { name = "", teamB = {}, alias = "", match: teamDMatch = {} } = teamD || {};
  const {
    live_clock = "20:00",
    live_period = 0,
    live_strength = "even",
  } = useSelector((state) => state.nhl);
  return (
    <div>
      {isTeamD ? (
        <div className="footer_stats_row">
          <img src={HockeyIcon} alt="Hockey Icon" width={12} height={12} />
         {
            status=="inprogress" && (Object.keys(setNhlEventData).length)<=1 ?
              <p>Loading...</p>:
            <>
              <p className={alias == teamDMatch?.away?.alias ? "bold_text" : ""}>{teamDMatch?.away?.alias} {getTeamPoints(teamDMatch?.id, teamDMatch?.away?.id) !== false? getTeamPoints(teamDMatch?.id, teamDMatch?.away?.id):0}</p><p> vs</p>
              <p className={alias == teamDMatch?.home?.alias ? "bold_text" : ""}>{teamDMatch?.home?.alias} {getTeamPoints(teamDMatch?.id, teamDMatch?.home?.id) !== false ? getTeamPoints(teamDMatch?.id, teamDMatch?.home?.id):0}</p>
            </>
          }
        </div>
      ) : (
        <div className="footer_stats_row">
          <img src={HockeyIcon} alt="Hockey Icon" width={12} height={12} />
          <>
          {
            status=="inprogress" && (Object.keys(setNhlEventData).length)<=1 ?
              <p>Loading...</p>:
              <>
                <p className={teamId == match?.away?.id ? "bold_text" : ""}>{match?.away?.alias} {getTeamPoints(matchId, match?.away?.id) !== false? getTeamPoints(matchId, match?.away?.id):0}</p><p> vs </p>
                <p className={teamId == match?.home?.id ? "bold_text" : ""}> {match?.home?.alias} {getTeamPoints(matchId, match?.home?.id) !== false ? getTeamPoints(matchId, match?.home?.id):0}</p>
              </>
            }
          </>
          {/* {team?.id == match?.away?.id && 
            <><p>{match?.home?.alias} {getTeamPoints(match?.id) !== false? getTeamPoints(match?.id)?.home?.points:0} vs</p>
            <p className="bold_text"> {match?.away?.alias} {getTeamPoints(match?.id) !== false ? getTeamPoints(match?.id)?.away?.points:0}</p></>
          }
          {team?.id == match?.home?.id && 
            <><p>{match?.away?.alias} {getTeamPoints(match?.id) !== false? getTeamPoints(match?.id)?.away?.points:0} vs</p>
            <p className="bold_text"> {match?.home?.alias} {getTeamPoints(match?.id) !== false ? getTeamPoints(match?.id)?.home?.points:0}</p></>
          } */}
        </div>
      )}

      {/* <div className="footer_stats_row">
        <img src={SoccerIcon} alt="Hockey Icon" width={12} height={12} />
        <p>{away?.alias} G: {OppGoalie}</p>
      </div> */}
      <div className="footer_stats_row">
        {  status!="closed" && status!="complete" &&(
        <img src={ClockIcon} alt="Hockey Icon" width={12} height={12} />
        )}
      {cardType!=="nhl" ?(
         <p>
          P{getTeamPoints1(match?.id) !== false ? (getTeamPoints1(match?.id)?.period) : (live_period)} | {getTeamPoints1(match?.id) !== false ? (typeof getTeamPoints1(match?.id)?.eventData !== "undefined") ? getTeamPoints1(match?.id)?.eventData?.clock : live_clock : live_clock}
         </p> 
          ):(
            <>
            {player?.fantasyPlayerPosition=="C" &&  status!="closed" && status!="complete"&& (
                <>
                {/* P{ getTeamData(player?.matchId) !== false ? (getTeamData(player?.matchId)?.period) : (live_period)} | {getTeamData(player?.matchId) !== false ? (typeof getTeamData(player?.matchId)?.eventData?.clock !== "undefined") ? getTeamData(player?.matchId)?.eventData?.clock: live_clock : live_clock} */}
                {/* P{ (getTeamDataC(player?.matchId) != false && getTeamDataC(player?.matchId) != undefined)   ? (getTeamDataC(player?.matchId)?.period) : 1} | {getTeamDataC(player?.matchId) !== false ? (typeof getTeamDataC(player?.matchId)?.eventData?.clock !== "undefined") ? timerComponentsC: timerComponentsC : "20:00"} */}
                {/* { timerComponentsC} */}
                {
                   status=="inprogress" && ((Object.keys(setNhlEventData).length)<=1 ||(timeLeftC=="20:00" && getTeamDataC(player?.match?.id)?.clock!=="20:00" && firstRenderClockC) )?
                   <p>Loading...</p>:
                  <p>
                 {!clockcountDown &&<> {!clockcountDown?"":`P${periodForC} |`} {getTeamDataC(player?.matchId) !== false && (typeof getTeamDataC(player?.matchId)?.clock !== "undefined") ?!clockcountDown?"Loading...": timerComponentsC: "20:00"}</>}
                 {clockcountDown &&<> P{periodForC} | {getTeamDataC(player?.matchId) !== false && (typeof getTeamDataC(player?.matchId)?.clock !== "undefined") ? timerComponentsC : "20:00"}</>}
                  </p>
                }
                {/* getTeamData(player?.matchId)?.eventData?.clock  */}
              </>
              )}
              {player?.fantasyPlayerPosition=="XW" &&player?.positionID==1  && status!="closed" && status!="complete"&&   (
                <>
                {
                   status=="inprogress" && ((Object.keys(setNhlEventData).length)<=1 ||(timeLeftXW1=="20:00" &&getTeamDataXW1(player?.match?.id)?.clock!=="20:00")&& firstRenderClockXW1 ) ?
                   <p>Loading...</p>:
                  <p>
                  {/* P{ getTeamData(player?.matchId) !== false ? (getTeamData(player?.matchId)?.period) : (live_period)} | {getTeamData(player?.matchId) !== false ? (typeof getTeamData(player?.matchId)?.clock !== "undefined") ? getTeamData(player?.matchId)?.clock: live_clock : live_clock} */}
                  {/* P{ getTeamDataXW1(player?.matchId) != false && getTeamDataXW1(player?.matchId) != undefined  ? (getTeamDataXW1(player?.matchId)?.period) : 1} | {getTeamDataXW1(player?.matchId) !== false ? (typeof getTeamDataXW1(player?.matchId)?.clock !== "undefined") ? timerComponentsXW1: timerComponentsXW1 : "20:00"} */}
                  {!clockcountDown && <>{!clockcountDown?"":`P${periodForXW1} |`} {getTeamDataXW1(player?.matchId) !== false && (typeof getTeamDataXW1(player?.matchId)?.clock !== "undefined") ? !clockcountDown?"Loading...": timerComponentsXW1: "20:00"}</>}
                  {clockcountDown && <> P{periodForXW1} | {getTeamDataXW1(player?.matchId) !== false && (typeof getTeamDataXW1(player?.matchId)?.clock !== "undefined") ?timerComponentsXW1: "20:00"}</>}
                 </p>
                }
              </>
              )}

               {player?.fantasyPlayerPosition=="XW" &&player?.positionID==2 &&   status!="closed" && status!="complete"&& (
                <>
                {
                    status=="inprogress" && ((Object.keys(setNhlEventData).length)<=1 ||(timeLeftXW2=="20:00" &&getTeamDataXW2(player?.match?.id)?.clock!=="20:00")&&firstRenderClockXW2)?
                   <p>Loading...</p>:
                    <p>
                    {/* P{ getTeamData(player?.matchId) !== false ? (getTeamData(player?.matchId)?.period) : (live_period)} | {getTeamData(player?.matchId) !== false ? (typeof getTeamData(player?.matchId)?.clock !== "undefined") ? getTeamData(player?.matchId)?.clock: live_clock : live_clock} */}
                    {/* P{ getTeamDataXW2(player?.matchId) != false &&getTeamDataXW2(player?.matchId) != undefined ? (getTeamDataXW2(player?.matchId)?.period) : 1} | {getTeamDataXW2(player?.matchId) !== false ? (typeof getTeamDataXW2(player?.matchId)?.clock !== "undefined") ? timerComponentsXW2: timerComponentsXW2 : "20:00"} */}
                    {!clockcountDown&&<>{!clockcountDown?"":`P${periodForXW2} |`} {getTeamDataXW2(player?.matchId) !== false && (typeof getTeamDataXW2(player?.matchId)?.clock !== "undefined") ? !clockcountDown?"Loading...":timerComponentsXW2 : "20:00"}</>}
                    {clockcountDown&&<> P{periodForXW2} | {getTeamDataXW2(player?.matchId) !== false && (typeof getTeamDataXW2(player?.matchId)?.clock !== "undefined") ? timerComponentsXW2: "20:00"}</>}
                    </p>
                }</> 
              )}
               {/* {player?.fantasyPlayerPosition=="XW" &&player?.positionID==3 &&status!="closed" && status!="complete" &&   ( */}
                {player?.fantasyPlayerPosition=="XW" &&player?.positionID==3  && status!="closed" && status!="complete"&&  (
                <>
                {
                    status=="inprogress" && ((Object.keys(setNhlEventData).length)<=1||(timeLeftXW3=="20:00" &&getTeamDataXW3(player?.match?.id)?.clock!=="20:00") &&firstRenderClockXW3)?
                       <p>Loading...</p>:
                      <p>
                      {/* P{ getTeamData(player?.matchId) !== false ? (getTeamData(player?.matchId)?.period) : (live_period)} | {getTeamData(player?.matchId) !== false ? (typeof getTeamData(player?.matchId)?.clock !== "undefined") ? getTeamData(player?.matchId)?.clock: live_clock : live_clock} */}
                      {!clockcountDown &&<>{!clockcountDown?"":`P${periodForXW3} |`} {getTeamDataXW3(player?.matchId) !== false || (typeof getTeamDataXW3(player?.matchId)?.clock !== "undefined") ?!clockcountDown?"Loading...": timerComponentsXW3: "20:00"}</>}
                      {clockcountDown&&<> P{periodForXW3} | {getTeamDataXW3(player?.matchId) !== false && (typeof getTeamDataXW3(player?.matchId)?.clock !== "undefined") ? timerComponentsXW3 : "20:00"}</>}

                      {/* { timerComponents} */}
                   </p>
                  }
                </>
              )}
               {player?.fantasyPlayerPosition=="D" &&player?.positionID==1 && status!="closed" && status!="complete"&& (
                <>
                {
                   status=="inprogress" && ((Object.keys(setNhlEventData).length)<=1||(timeLeftD1=="20:00" &&getTeamDataD1(player?.match?.id)?.clock!=="20:00")&&firstRenderClockD1)?
                   <p>Loading...</p>:
                    <p>
                    {/* P{ getTeamData(player?.matchId) !== false ? (getTeamData(player?.matchId)?.period) : (live_period)} | {getTeamData(player?.matchId) !== false ? (typeof getTeamData(player?.matchId)?.clock !== "undefined") ? getTeamData(player?.matchId)?.clock: live_clock : live_clock} */}
                    {/* P{ getTeamDataD1(player?.matchId) != false &&  getTeamDataD1(player?.matchId) !=undefined  ? (getTeamDataD1(player?.matchId)?.period) : 1} | {getTeamDataD1(player?.matchId) !== false ? (typeof getTeamDataD1(player?.matchId)?.clock !== "undefined") ? timerComponentsD1: timerComponentsD1 : "20:00"} */}
                    {!clockcountDown && <>{!clockcountDown?"":`P${periodForD1} |`} {getTeamDataD1(player?.matchId) !== false && (typeof getTeamDataD1(player?.matchId)?.clock !== "undefined") ?!clockcountDown?"Loading...": timerComponentsD1: "20:00"}</>}
                    {clockcountDown &&<> P{periodForD1} | {getTeamDataD1(player?.matchId) !== false && (typeof getTeamDataD1(player?.matchId)?.clock !== "undefined") ? timerComponentsD1: "20:00"}</>}

                  </p>
                }</>
              )}
               {player?.fantasyPlayerPosition=="D" &&player?.positionID==2 &&  status!="closed" && status!="complete"&&  (
               <>
               {
                   status=="inprogress" && ((Object.keys(setNhlEventData).length)<=1 ||(timeLeftD2=="20:00" &&getTeamDataD2(player?.match?.id)?.clock!=="20:00")&&firstRenderClockD2)?
                   <p>Loading...</p>:
                  <p>
                    {/* P{ getTeamData(player?.matchId) !== false ? (getTeamData(player?.matchId)?.period) : (live_period)} | {getTeamData(player?.matchId) !== false ? (typeof getTeamData(player?.matchId)?.clock !== "undefined") ? getTeamData(player?.matchId)?.clock: live_clock : live_clock} */}
                    {/* P{ getTeamDataD2(player?.matchId) != false  ? (getTeamDataD2(player?.matchId)?.period) : 1} | {getTeamDataD2(player?.matchId) !== false ? (typeof getTeamDataD2(player?.matchId)?.clock !== "undefined") ? timerComponentsD2: timerComponentsD2 : "20:00"} */}
                    {!clockcountDown&&<>{!clockcountDown?"":`P${periodForD2} |`} {getTeamDataD2(player?.matchId) !== false && (typeof getTeamDataD2(player?.matchId)?.clock !== "undefined") ?!clockcountDown?"Loading...":timerComponentsD2 : "20:00"}</>}
                    {clockcountDown&&<> P{periodForD2} | {getTeamDataD2(player?.matchId) !== false && (typeof getTeamDataD2(player?.matchId)?.clock !== "undefined") ? timerComponentsD2:  "20:00"}</>}
                  </p>
                }</>
              )}
             {player?.fantasyPlayerPosition=="G" &&  status!="closed" && status!="complete"&& (
                <>
                {
                   status=="inprogress" && ((Object.keys(setNhlEventData).length)<=1 ||(timeLeftG=="20:00" &&getTeamDataG(player?.match?.id)?.clock!=="20:00")&&firstRenderClockG) ?
                   <p>Loading...</p>:
                    <p>
                    {/* P{ getTeamData(player?.matchId) !== false ? (getTeamData(player?.matchId)?.period) : (live_period)} | {getTeamData(player?.matchId) !== false ? (typeof getTeamData(player?.matchId)?.clock !== "undefined") ? getTeamData(player?.matchId)?.clock: live_clock : live_clock} */}
                    {/* P{ getTeamDataG(player?.matchId) != false && getTeamDataG(player?.matchId) !=undefined ? (getTeamDataG(player?.matchId)?.period) : 1} | {getTeamDataG(player?.matchId) !== false ? (typeof getTeamDataG(player?.matchId)?.clock !== "undefined") ? timerComponentsG: timerComponentsG : "20:00"} */}
                    {/* { timerComponents} */}
                    {!clockcountDown&&<>{!clockcountDown?"":`P${periodForG} |`}  {getTeamDataG(player?.matchId) !== false && (typeof getTeamDataG(player?.matchId)?.clock !== "undefined") ? !clockcountDown?"Loading...": timerComponentsG : "20:00"}</>}
                    {clockcountDown&&<> P{periodForG} | {getTeamDataG(player?.matchId) !== false && (typeof getTeamDataG(player?.matchId)?.clock !== "undefined") ? timerComponentsG:  "20:00"}</>}
                  </p>
                }</>
              )}
              {
                isTeamD &&  status!="closed" && status!="complete"&&  (
              <>
              {
                   status=="inprogress" && ((Object.keys(setNhlEventData).length)<=1 ||(timeLeft=="20:00" &&getTeamData(teamDMatch?.id)?.clock!=="20:00")&&firstRenderClock)?
                   <p>Loading...</p>:
                  <p>
                    {/* P{ getTeamData(player?.matchId) !== false ? (getTeamData(player?.matchId)?.period) : (live_period)} | {getTeamData(player?.matchId) !== false ? (typeof getTeamData(player?.matchId)?.clock !== "undefined") ? getTeamData(player?.matchId)?.clock: live_clock : live_clock} */}
                    {/* P{ getTeamData(teamDMatch?.id) !== false &&  getTeamData(teamDMatch?.id) !==undefined? (getTeamData(teamDMatch?.id)?.period) : (1)} | {getTeamData(teamDMatch?.id) !== false ? (typeof getTeamData(teamDMatch?.id)?.clock !== "undefined") ? timerComponents: timerComponents : "20:00"} */}
                    {!clockcountDown&&<>{!clockcountDown?"":`P${periodForTD} |`} {getTeamData(teamDMatch?.id) !== false && (typeof getTeamData(teamDMatch?.id)?.clock !== "undefined") ?!clockcountDown?"Loading...":timerComponents : "20:00"}</>}
                    {clockcountDown&&<> P{periodForTD} | {getTeamData(teamDMatch?.id) !== false && (typeof getTeamData(teamDMatch?.id)?.clock !== "undefined") ? timerComponents : "20:00"}</>}
                  </p>
              }</>
              )}
              {/* {console.log("livvvvvvvvvvv--->",live_clock)} */}
           </>
      )}
      </div>
      <div className="footer_stats_row">
        {/* <img src={SoccerJerseyIcon} alt="Hockey Icon" width={12} height={12} /> */}
        {cardType!=="nhl" ?(
          <p>
            {live_strength !== "even" ? isTeamD ? teamB.alias + " - " : player?.team?.alias + " - " : ""}
            {live_strength === "even"
              ? "Even Strength"
              : _.startCase(_.toLower(live_strength))}{" "}
          </p>
        ):(
          <p>
            {/* <div> <button onClick={timer}>Start/Reset</button></div> */}
            {/* <div> {timerCount}</div> */}
            {/* {console.log("player?.home?.name==>",(getTeamData(player?.matchId)?.home?.name))} */}
            {/* {getTeamData(player?.matchId) !== false && (getTeamData(player?.matchId)?.eventData?.strength)!=="even" ? isTeamD ? teamB.alias + " - " : player?.team?.alias + " - " : ""} */}
            
            {/* Strength set from here..... */}
          
            {/* {getTeamData(player?.matchId) !== false && (getTeamData(player?.matchId)?.home?.strength)==="even" 
              ? "Even Strength"
              : (getTeamData(player?.matchId)?.home?.strength) ===undefined ? "Even Strength" : (getTeamData(player?.matchId)?.home?.alias ?getTeamData(player?.matchId)?.home?.alias:getTeamData(player?.matchId)?.home?.name +" "+ getTeamData(player?.matchId)?.home?.strength)
            } */}
          </p>
        )}
      </div>
    </div>
  );
}

NHLFooterStats.propTypes = {};

export default NHLFooterStats;
