import { URLS } from "../config/urls";

import axios from "axios";
import {getApiURL} from "../config/apiURL"
let REACT_APP_API_URL=getApiURL()

export const GET_ALL_POWER_GAMES = "GET_ALL_POWER_GAMES";

export function getAllGames(user_id) {
  return (dispatch) => {
    const server = window.location.hostname;
    const env =
      (["test.powerplaysystems.com", "localhost", "192.168.127.43"].indexOf(server) > -1) ? "dev" : "prod";
    const api = `${REACT_APP_API_URL}/${URLS.GAMES.ALL_POWER_GAMES}?status=Activated&user_id=${user_id}&forenvironment=${env}`;
    axios
      .get(api)
      .then((res) => {
        dispatch({
          type: GET_ALL_POWER_GAMES,
          payload: res?.data,
        });
        return res?.data;
      })
      // .then((res) => {
      //   var temp = [];
      //   var resData = res?.data;
      //   for(var i = 0; i < resData.length; i++) {
      //     var hasIndex = temp.findIndex(x => x.game_id === resData[i].game_id);
      //     if(hasIndex === -1) {
      //       temp.push(resData[i]);
      //     }
      //   }
      //   dispatch({
      //     type: GET_ALL_POWER_GAMES,
      //     payload: temp,
      //   });
      //   return temp;
      // })
      .catch((er) => {
        console.log(er);
        return er?.message;
      });
  };
}
