import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Input from "../../ui/Input/Input";

import Alert from "../../components/Alert";
import { isEmpty } from "lodash";
import formStyles from "../../scss/formstyles.module.scss";
import HeroSection from "../../components/CreateAccountsHeroSection/HeroSection";
import { getApiURL } from "../../config/apiURL";
import http from "../../config/http";

async function requestPasswordReset(email) {
  const api = getApiURL()
  const response = await http.post(`${api}/api/v1/users/request-password-reset`, { email })

  if (!response.data.success) {
      console.error(response.error)
      throw new Error("Could not request password reset")
  } else {
      return true
  }
}

function ResetPasswordRequestPage(props) {
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoadingState] = useState(false);
  const [emailSent, setEmailSent] = useState(false)
  const [error, setError] = useState(null)

  const onSendEmailSubmit = useCallback((e) => {
    if (loading) return

    e?.preventDefault();
    setError(null)
    setLoadingState(true);

    requestPasswordReset(userEmail)
      .then(() => {
        setEmailSent(true)
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setLoadingState(false)
      })
  }, [userEmail, setLoadingState, loading, setError, setEmailSent]);

  return (
    <div className={styles.root}>
      <Header isStick={true} />

      <HeroSection title={emailSent ? "ALL SET!" : "RESET PASSWORD"} />
      {error && <Alert danger renderMsg={() => <p>An error occurred while trying to send the password reset request. Please try again later.</p>} />}
      {!emailSent ? (
        <div className={styles.container}>
          <form onSubmit={onSendEmailSubmit} className={formStyles.root}>
            <Input
              type="email"
              title="E-mail"
              required
              value={userEmail}
              onChange={(e) => setUserEmail(e?.target?.value)}
            />

            <button
              className={formStyles.button}
              type="submit"
              disabled={loading || isEmpty(userEmail)}
            >
              {loading ? "Loading..." : "Send Email"}
            </button>
          </form>
          <p className={`${styles.blogSection}`}>
            Don't have an account?{" "}
            <Link to="/power-up">Click here to Power-up!</Link>
          </p>
          <p className={`${styles.blogSection1}`}>
            Remember Password? <Link to="/login">Click here to Login!</Link>
          </p>
        </div>

      ) : (
        <p className={`${styles.blogSection}`}>
          If this email address is registered with us, an email with instructions on how to reset your password has been sent to it.
        </p>
      )}

      <Footer isBlack />
    </div >
  );
}

export default ResetPasswordRequestPage;
