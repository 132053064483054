import AdImg from "../../assets/img.jpg";
import { CONSTANTS } from "../../utility/constants";

const { P, C, SS, XB, OF, D } = CONSTANTS.FILTERS.MLB;

export const dummyData = [
  {
    type: P,
    listData: [
      {
        playerId: 1,
        match_id: 15,
        name: "Nathan McKinnen",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        injured: true,
        position: "sp",
        steps: [
          {
            titles: ["ERA", "W-L", "k", "whip", "fppg"],
            step: [21, "8-4", 13, 31, 20.0],
          },
          {
            titles: ["W", "L", "IP", "K", "ERA", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 0, 7, 14.8, 8.9],
              },
              {
                title: "last 10 games",
                values: [10, 8, 17, 39, 18.8, 23.3],
              },
              {
                title: "2020-2021",
                values: [1, 0, 0, 7, 14.8, 23.4],
              },
            ],
          },
          {
            step: {
              ad: AdImg,
            },
          },
        ],
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 2,
        match_id: 15,
        name: "Conner McDavid",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        isStarPlayer: true,
        position: "if",
        steps: [
          {
            titles: ["ERA", "W-L", "k", "whip", "fppg"],
            step: [21, 18, 13, 31, 20.0],
          },
          {
            titles: ["ERA", "W-L", "k", "whip", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 0, 7, 14.8],
              },
              {
                title: "last 10 games",
                values: [10, 8, 17, 39, 18.8],
              },
              {
                title: "2020-2021",
                values: [1, 0, 0, 7, 14.8],
              },
            ],
          },
          {
            step: {
              ad: AdImg,
            },
          },
        ],
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 3,
        match_id: 15,
        name: "Sebastian Aho",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        position: "if",
        steps: [
          {
            titles: ["ERA", "W-L", "k", "whip", "fppg"],
            step: [21, 18, 13, 31, 20.0],
          },
          {
            titles: ["ERA", "W-L", "k", "whip", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 0, 7, 14.8],
              },
              {
                title: "last 10 games",
                values: [10, 8, 17, 39, 18.8],
              },
              {
                title: "2020-2021",
                values: [1, 0, 0, 7, 14.8],
              },
            ],
          },
          {
            step: {
              ad: AdImg,
            },
          },
        ],
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 4,
        name: "Chris Carpenter",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        position: "of",
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
    ],
  },
  {
    type: C,
    listData: [
      {
        playerId: 11,
        name: "Nathan McKinnen",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        position: "c",
        steps: [
          {
            titles: ["avg", "hr", "rbi", "fppg"],
            step: [21, 18, 13, 20.0],
          },
          {
            titles: ["h", "2b", "hr", "rbi", "avg", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 1, 0, 0, 14.8],
              },
              {
                title: "last 10 games",
                values: [10, 8, 1, 1, 9, 18.8],
              },
              {
                title: "2020-2021",
                values: [1, 0, 0, 2, 1, 14.8],
              },
            ],
          },
          {
            step: {
              ad: AdImg,
            },
          },
        ],
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 22,
        name: "Conner McDavid",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        isStarPlayer: true,
        position: "c",
        steps: [
          {
            titles: ["avg", "hr", "rbi", "fppg"],
            step: [21, 18, 13, 20.0],
          },
          {
            titles: ["h", "2b", "hr", "rbi", "avg", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 1, 0, 0, 14.8],
              },
              {
                title: "last 10 games",
                values: [10, 8, 1, 1, 9, 18.8],
              },
              {
                title: "2020-2021",
                values: [1, 0, 0, 2, 1, 14.8],
              },
            ],
          },
          {
            step: {
              ad: AdImg,
            },
          },
        ],
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 33,
        name: "Sebastian Aho",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        position: "c",
        steps: [
          {
            titles: ["avg", "hr", "rbi", "fppg"],
            step: [21, 18, 13, 20.0],
          },
          {
            titles: ["h", "2b", "hr", "rbi", "avg", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 1, 0, 0, 14.8],
              },
              {
                title: "last 10 games",
                values: [10, 8, 1, 1, 9, 18.8],
              },
              {
                title: "2020-2021",
                values: [1, 0, 0, 2, 1, 14.8],
              },
            ],
          },
          {
            step: {
              ad: AdImg,
            },
          },
        ],
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 44,
        name: "Chris Carpenter",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        position: "c",
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
    ],
  },
  {
    type: SS,
    listData: [
      {
        playerId: 55,
        name: "Nathan McKinnen",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        position: "ss",
        steps: [
          {
            titles: ["avg", "hr", "rbi", "fppg"],
            step: [21, 18, 13, 20.0],
          },
          {
            titles: ["h", "2b", "hr", "rbi", "avg", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 0, 3, 1.4, 14.8],
              },
              {
                title: "last 10 games",
                values: [10, 8, 0.9, 1, 9, 18.8],
              },
              {
                title: "2020-2021",
                values: [1, 0, 1, 2, 0, 14.8],
              },
            ],
          },
          {
            step: {
              ad: AdImg,
            },
          },
        ],
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 66,
        name: "Conner McDavid",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        isStarPlayer: true,
        position: "ss",
        steps: [
          {
            titles: ["avg", "hr", "rbi", "fppg"],
            step: [21, 18, 13, 20.0],
          },
          {
            titles: ["h", "2b", "hr", "rbi", "avg", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 0, 3, 1.4, 14.8],
              },
              {
                title: "last 10 games",
                values: [10, 8, 0.9, 1, 9, 18.8],
              },
              {
                title: "2020-2021",
                values: [1, 0, 1, 2, 0, 14.8],
              },
            ],
          },
          {
            step: {
              ad: AdImg,
            },
          },
        ],
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 77,
        name: "Sebastian Aho",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        position: "ss",
        steps: [
          {
            titles: ["avg", "hr", "rbi", "fppg"],
            step: [21, 18, 13, 20.0],
          },
          {
            titles: ["h", "2b", "hr", "rbi", "avg", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 1, 0, 0, 14.8],
              },
              {
                title: "last 10 games",
                values: [10, 8, 1, 1, 9, 18.8],
              },
              {
                title: "2020-2021",
                values: [1, 0, 0, 2, 1, 14.8],
              },
            ],
          },
          {
            step: {
              ad: AdImg,
            },
          },
        ],
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 88,
        name: "Chris Carpenter",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        position: "ss",
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
    ],
  },
  {
    type: D,
    listData: [
      {
        playerId: 70,
        name: "Toronto Blue Jays",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        position: "sp",
        steps: [
          {
            titles: ["w", "l", "ara", "fppg"],
            step: [21, 18, 13, -20],
          },
          {
            titles: ["h", "2b", "hr", "rbi", "avg", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 1, 0, 0, 14.8],
              },
              {
                title: "last 10 games",
                values: [10, 8, 1, 1, 9, 18.8],
              },
              {
                title: "2020-2021",
                values: [1, 0, 0, 2, 1, 14.8],
              },
            ],
          },
        ],
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 71,
        name: "Tampa Bay Lightening",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        isStarPlayer: true,
        position: "of",
        steps: [
          {
            titles: ["w", "l", "ara", "fppg"],
            step: [21, 18, 13, -20],
          },
          {
            titles: ["h", "2b", "hr", "rbi", "avg", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 1, 0, 0, 14.8],
              },
              {
                title: "last 10 games",
                values: [10, 8, 1, 1, 9, 18.8],
              },
              {
                title: "2020-2021",
                values: [1, 0, 0, 2, 1, 14.8],
              },
            ],
          },
        ],
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 72,
        name: "Columbus Blue Jackets",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        position: "if",
        steps: [
          {
            titles: ["w", "l", "ara", "fppg"],
            step: [21, 18, 13, -20],
          },
          {
            titles: ["h", "2b", "hr", "rbi", "avg", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 1, 0, 0, 14.8],
              },
              {
                title: "last 10 games",
                values: [10, 8, 1, 1, 9, 18.8],
              },
              {
                title: "2020-2021",
                values: [1, 0, 0, 2, 1, 14.8],
              },
            ],
          },
        ],
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 73,
        name: "Chris Carpenter",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        position: "of",
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
    ],
  },
  {
    type: OF,
    listData: [
      {
        playerId: 74,
        name: "Nathan McKinnen",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        position: "cf",
        steps: [
          {
            titles: ["avg", "hr", "rbi", "fppg"],
            step: [21, 18, 13, 20.0],
          },
          {
            titles: ["h", "2b", "hr", "rbi", "avg", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 1, 0, 0, 14.8],
              },
              {
                title: "last 10 games",
                values: [10, 8, 1, 1, 9, 18.8],
              },
              {
                title: "2020-2021",
                values: [1, 0, 0, 2, 1, 14.8],
              },
            ],
          },
          {
            step: {
              ad: AdImg,
            },
          },
        ],
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 75,
        name: "Conner McDavid",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        isStarPlayer: true,
        position: "lf",
        steps: [
          {
            titles: ["ERA", "W-L", "k", "whip", "fppg"],
            step: [21, 18, 13, 31, 20.0],
          },
          {
            titles: ["h", "2b", "hr", "rbi", "avg", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 1, 0, 0, 14.8],
              },
              {
                title: "last 10 games",
                values: [10, 8, 1, 1, 9, 18.8],
              },
              {
                title: "2020-2021",
                values: [1, 0, 0, 2, 1, 14.8],
              },
            ],
          },
          {
            step: {
              ad: AdImg,
            },
          },
        ],
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 76,
        name: "Sebastian Aho",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        position: "rf",
        steps: [
          {
            titles: ["ERA", "W-L", "k", "whip", "fppg"],
            step: [21, 18, 13, 31, 20.0],
          },
          {
            titles: ["h", "2b", "hr", "rbi", "avg", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 1, 0, 0, 14.8],
              },
              {
                title: "last 10 games",
                values: [10, 8, 1, 1, 9, 18.8],
              },
              {
                title: "2020-2021",
                values: [1, 0, 0, 2, 1, 14.8],
              },
            ],
          },
          {
            step: {
              ad: AdImg,
            },
          },
        ],
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 799,
        name: "Chris Carpenter",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        position: "lf",
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
      },
    ],
  },
  {
    type: XB,
    listData: [
      {
        playerId: 78,
        name: "Nathan McKinnen",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        position: "1b",
        steps: [
          {
            titles: ["gp", "g", "a", "pts", "fppg"],
            step: [21, 18, 13, 31, 20.0],
          },
          {
            titles: ["h", "2b", "hr", "rbi", "avg", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 1, 0, 0, 14.8],
              },
              {
                title: "last 10 games",
                values: [10, 8, 1, 1, 9, 18.8],
              },
              {
                title: "2020-2021",
                values: [1, 0, 0, 2, 1, 14.8],
              },
            ],
          },
          {
            step: {
              ad: AdImg,
            },
          },
        ],
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 79,
        name: "Conner McDavid",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        isStarPlayer: true,
        position: "2b",
        steps: [
          {
            titles: ["gp", "g", "a", "pts", "fppg"],
            step: [21, 18, 13, 31, 20.0],
          },
          {
            titles: ["h", "2b", "hr", "rbi", "avg", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 1, 0, 0, 14.8],
              },
              {
                title: "last 10 games",
                values: [10, 8, 1, 1, 9, 18.8],
              },
              {
                title: "2020-2021",
                values: [1, 0, 0, 2, 1, 14.8],
              },
            ],
          },
          {
            step: {
              ad: AdImg,
            },
          },
        ],
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 80,
        name: "Conner McDavid",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        position: "3b",
        steps: [
          {
            titles: ["gp", "g", "a", "pts", "fppg"],
            step: [21, 18, 13, 31, 20.0],
          },
          {
            titles: ["h", "2b", "hr", "rbi", "avg", "fppg"],
            step: [
              {
                title: "last game",
                values: [1, 0, 1, 0, 0, 14.8],
              },
              {
                title: "last 10 games",
                values: [10, 8, 1, 1, 9, 18.8],
              },
              {
                title: "2020-2021",
                values: [1, 0, 0, 2, 1, 14.8],
              },
            ],
          },
          {
            step: {
              ad: AdImg,
            },
          },
        ],
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
      {
        playerId: 81,
        name: "Sebastian Aho",
        avgVal: 4,
        homeTeam: "Arizona Diamondbacks",
        awayTeam: "Baltimore Orioles",
        time: "01:10 PM",
        date: "2020-09-28",
        stadium: "Empower Field",
        position: "2b",
        stats: {
          val1: "ip:3.1 | pc:34",
          val2: "k:4 | w:3",
        },
        points: 6,
        status: "batting",
        playerStats: {
          battingPlayer: {
            playerName: "J. Rogers",
            stats: ".280 | 1/3 | s:0 | b:3",
          },
        },
        statHistory: {
          last_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          last_10_game: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
          2020_2021: {
            hits: 0,
            doubles: 0,
            triples: 0,
            home_runs: 0,
            stolen_bases: 0,
            runs_batted_in: 0,
            batting_average: 0,
            wins: 0,
            losses: 1,
            innings_pitched: 146,
            strike_outs: 89,
            earned_runs_average: 5,
            base_on_balls: 1,
            walks_hits_per_innings_pitched: 1.3333,
          },
        },
        pointsSummary: [
          { status: "p1: 19:59", type: "hr", power: "2x", pts: 4 },
          { status: "p2: 13:45", type: "triple", power: "", pts: 2 },
          { status: "p3: 00:01", type: "single", power: "3x", pts: 0 },
          { status: "ot: 04:58", type: "double", power: "1x", pts: 1 },
          { status: "p1: 19:59", type: "walk", power: "4x", pts: 9 },
        ],
        totalPts: 27,
      },
    ],
  },
];
