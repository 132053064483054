import styles from "./styles.module.scss";
import Header from "../../components/Header/Header";
import HeroSection from "../../components/CreateAccountsHeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import formStyles from "../../scss/formstyles.module.scss";
import backImage from '../../assets/path-2@2x.png';
import topMiddleImage from '../../assets/group-6@2x.png';
import { getLocalStorage, redirectTo } from "../../utility/shared";
function VPNPage(props) {
    const subTitle = <>
    Let’s start your new experience our ground-breaking live-play <br /> games
    where you have the Power to control your destiny!
  </>;
      const title =  <>
      Get Ready <br /> to Power-Up!
    </>;
  return (
    <div className={styles.root}>
      <Header isStick={true} />
      <HeroSection title={title} subTitle={''} />
      <div className={styles.container}>
      <form className={formStyles.root} style={{marginBottom: "5rem"}}>
          <button className={styles.backButton} onClick={() => {
            redirectTo(props, { path: "/login" });
          }}><img src={backImage} /> Back</button>
          <img src={topMiddleImage} className={styles.topMiddleImage}/>
          <p className={styles.mainText}>We detected that you are connecting via a VPN.</p>
          <p className={styles.subText}>We are unable to allow access to the Defy Games site via a VPN connection as it prohibits us from meeting regulatory requirements regarding your location.</p>
          <button
            className={formStyles.button}
            onClick={() => {
              redirectTo(props, { path: "/login" });
            }}
          >
            GO BACK
          </button>
      </form>
      </div>
      <Footer isBlack />
    </div>
  );
}

export default VPNPage;
