import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classes from "./index.module.scss";
import Modal from "../Modal";
import PrizeModal from "../PrizeModal";
import CloseIcon from "../../icons/Close";
import bg from "../../assets/group-31.png";
import { urlencoded } from "body-parser";
import { redirectTo, getLocalStorage } from "../../utility/shared";
import _ from "underscore";
import moment from "moment";
import moment1 from "moment-timezone";

import NFLbg from "../../assets/group-31.png";
import NHLbg from "../../assets/NHLPopupBg.png";
import NBAbg from "../../assets/NBAPopupBg.png";
import MLBbg from "../../assets/MLBPopupBg.png";
import NHLPromoBG from '../../assets/NHLPromoBG.png';
import surveyAd from '../../assets/surveyAd.png';

import ReplaceIcon from "../../icons/Replace";
import XpIcon from "../../icons/XPIcon";
import ShieldIcon from "../../icons/ShieldIcon";
import Challenge from "../../icons/Challenge";
import RetroBoostIcon from "../../icons/RetroBoost";
import PowerUpIcon from "../../icons/PowerUp";
import ContestRulesPopUp from "../../components/ContestRulesPopUp";
import SwapIcon from "../../icons/Swap";
import { getTeamsList, savedFreeEntryTest, saveFreeEntry } from ".././../actions/MLBActions"
import RulesPopup from "../../components/Rules";
import { CONSTANTS } from "../../utility/constants";
import fbicon from '../../assets/fbicon.svg';
import twiticon from '../../assets/twiticon.svg';
import { checkEntryCode } from "../../actions/userActions";
import { useDispatch } from "react-redux";

function PromoModal(props) {
  const [getPowers, setPowers] = useState([]);
  const [isFreeEntryMode, setIsFreeEntryMode] = useState(false);
  const [IsEssayMode, setIsEssayMode] = useState(false);
  const [isSocialMediaMode, setSocialMediaMode] = useState(false);

  const [isValidated, setIsValidated] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [gameStartDate, setGameStartDate] = useState("");
  const [gameStartTime, setGameStartTime] = useState("");
  const [verifyEntryCode, setVerifyEntryCode] = useState("");
  const [freeEntryGameIDs, setFreeEntryGameIDs] = useState([])
  const [getEntryCode, setEntryCode] = useState(null);
  const dispatch = useDispatch();

  const [freeEntryData, setFreeEntryData] = useState({
    MLBTeam: "",
    NHLTeam: "",
    NFLTeam: "",
    NBATeam: ""
  });

  let SOCIAL_MEDIA_SHARING_TEXT = "Who else is playing fantasy sports on defygames.io? I think the best thing about the platform is there's no salary cap to deal with! Just makes it so much easier to enter and win."
  const { visible = false, onClose = () => { }, item = {}, cashBalance = 0, userSurveySaved = false, adImageNHL = [], setHaveBalance, setShowDepositModal = () => { } } =
    props || {};
  const { game_id = '', league = "MLB", Powers = [],cards_remaining=0, PointsSystems = [], PrizePayouts = [], game_type = "", powerdfs_challenge_amount = 0, chase_the_ace_prize_pool = 0 } = item || {};
  // useEffect(() => {
  //   console.log("useEffect called promomod")
  //   if(localStorage.getItem("freeEntryGameIDs")){
  //     setFreeEntryGameIDs(JSON.parse(localStorage.getItem("freeEntryGameIDs")))
  //   } else {
  //     setFreeEntryGameIDs([])
  //   }
  // },[getLocalStorage("freeEntryGameIDs"),getLocalStorage("_expiredTime")])
  useEffect(() => {
    function checkUserData() {
      const item = localStorage.getItem('freeEntryGameIDs')

      if (item) {
        setFreeEntryGameIDs(JSON.parse(item))
      } else setFreeEntryGameIDs([])
    }
    window.addEventListener('storage', checkUserData)

    return () => {
      window.removeEventListener('storage', checkUserData)
    }
  }, [localStorage.getItem('freeEntryGameIDs')])

  // function freeEntry(trustate){
  //   setTimeout(() => {
  //     if(trustate){
  //       console.log("inside set time out ",trustate)
  //       if(localStorage.getItem("freeEntryGameIDs")){
  //         setFreeEntryGameIDs(JSON.parse(localStorage.getItem("freeEntryGameIDs")))
  //       } else {
  //         setFreeEntryGameIDs([])
  //       } 
  //       freeEntry(trustate)
  //     }
  //   },2000)
  // }

  const checkFreeEntryCode=async()=>{
    let data={
      game_id:item?.game_id,
      entryCode:getEntryCode
    }
    let res=await dispatch(checkEntryCode(data));
    if(res==="matched"){
      redirectToUrl("free")
    }else{
      setVerifyEntryCode("notMatch")
      setEntryCode("")
    }
  }
  const getBG = () => {
    switch (league) {
      case "MLB":
        return MLBbg;
      case "NFL":
        return NFLbg;
      case "NHL": {
        if (game_type === "Fantasy_Promo") {
          return NHLPromoBG;
        }
        else {
          return NHLbg;
        }
      }
      case "NBA":
        return NBAbg;
      case "default":
        return MLBbg;
    }
  }
  const [showPrizeModal, setPrizeModalState] = React.useState(false);

  const getLocalDateTime = (date, time) => {
    const localDateTime = moment.utc(date + " " + time, "YYYY-MM-DD hh:mm A").local().format("YYYY-MM-DD=hh:mm A");
    const splitted = localDateTime.split("=");

    return {
      date: splitted[0],
      time: splitted[1],
    };
  };

  const powersList = () => {
    let powersAvailable = item?.Powers;
    if (powersAvailable == undefined)
      return;

    let finalList = [];
    if (powersAvailable.findIndex(x => x.powerId == 4) >= 0) {
      finalList.push({
        powerName: "Player Swap",
        amount: powersAvailable.find(x => x.powerId == 4).amount,
        icon: (<ReplaceIcon />)
      });
    }
    if (powersAvailable.findIndex(x => x.powerId == 1) >= 0) {
      let amount = 0;
      if (finalList.findIndex(x => x.powerName == "Point Booster") >= 0) {
        amount = parseInt(finalList.find(x => x.powerName == "Point Booster").amount) + parseInt(powersAvailable.find(x => x.powerId == 1).amount);
        finalList[finalList.findIndex(x => x.powerName == "Point Booster")].amount = amount;
      }
      else {
        amount = powersAvailable.find(x => x.powerId == 1).amount;
        finalList.push({
          powerName: "Point Booster",
          amount: powersAvailable.find(x => x.powerId == 1).amount,
          icon: (<XpIcon />)
        });
      }
    }
    if (powersAvailable.findIndex(x => x.powerId == 2) >= 0) {
      let amount = 0;
      if (finalList.findIndex(x => x.powerName == "Point Booster") >= 0) {
        amount = parseInt(finalList.find(x => x.powerName == "Point Booster").amount) + parseInt(powersAvailable.find(x => x.powerId == 2).amount);
        finalList[finalList.findIndex(x => x.powerName == "Point Booster")].amount = amount;
      }
      else {
        amount = powersAvailable.find(x => x.powerId == 2).amount;
        finalList.push({
          powerName: "Point Booster",
          amount: powersAvailable.find(x => x.powerId == 2).amount,
          icon: (<XpIcon />)
        });
      }
    }
    if (powersAvailable.findIndex(x => x.powerId == 3) >= 0) {
      let amount = 0;
      if (finalList.findIndex(x => x.powerName == "Point Booster") >= 0) {
        amount = parseInt(finalList.find(x => x.powerName == "Point Booster").amount) + parseInt(powersAvailable.find(x => x.powerId == 3).amount);
        finalList[finalList.findIndex(x => x.powerName == "Point Booster")].amount = amount;
      }
      else {
        amount = powersAvailable.find(x => x.powerId == 3).amount;
        finalList.push({
          powerName: "Point Booster",
          amount: powersAvailable.find(x => x.powerId == 3).amount,
          icon: (<XpIcon />)
        });
      }
    }
    if (powersAvailable.findIndex(x => x.powerId == 6) >= 0) {
      finalList.push({
        powerName: "Challenge",
        amount: powersAvailable.find(x => x.powerId == 6).amount,
        icon: (<Challenge />)
      });
    }
    if (powersAvailable.findIndex(x => x.powerId == 5) >= 0) {
      finalList.push({
        powerName: "D-Wall",
        amount: powersAvailable.find(x => x.powerId == 5).amount,
        icon: (<ShieldIcon />)
      });
    }
    if (powersAvailable.findIndex(x => x.powerId == 10) >= 0) {
      finalList.push({
        powerName: "2x Retro Boost",
        amount: powersAvailable.find(x => x.powerId == 10).amount,
        icon: (<RetroBoostIcon />)
      });
    }
    if (powersAvailable.findIndex(x => x.powerId == 8) >= 0) {
      finalList.push({
        powerName: "Power-Up",
        amount: powersAvailable.find(x => x.powerId == 8).amount,
        icon: (<PowerUpIcon />)
      });
    }
    setPowers(finalList);
  };

  const redirectToUrl = (free) => {
    if (cashBalance >= item?.entry_fee || free === "free") {
      let item = props.item;
      let url = "/mlb-select-team";
      switch (item?.league) {
        case "MLB":
          url = "/mlb-select-team";
          break;
        case "NFL":
          url = "/nfl-select-team";
          break
        case "NHL":
          url = "/nhl-select-team";
          break
        case "NBA":
          url = "/nba-select-team";
          break
        case "default":
          url = "/mlb-select-team";
          break
      }
      return redirectTo(props.propss, {
        path: url,
        state: {
          isFreeEntry: free === "free" ? true : false,
          game_id: item?.game_id,
          sport_id: item?.sports_id,
          start_date: getLocalDateTime(item?.start_date, item?.start_time)?.date,
          game_set_start: getLocalDateTime(item?.game_set_start, item?.start_time)?.date,
          start_time: getLocalDateTime(item?.game_set_start, item?.start_time)?.time,
          end_date: getLocalDateTime(item?.end_date, item?.end_time)?.date,
          outOf: item?.target,
          enrolledUsers: item?.enrolled_users,
          prizePool: _.reduce(
            item?.PrizePayouts,
            function (memo, num) {
              return memo + parseInt(num.amount) * parseInt(num.prize);
            },
            0
          ),
          topPrize: parseFloat(
            _.max(item?.PrizePayouts, function (ele) {
              return ele.amount;
            }).amount
          ),
          PointsSystem: item?.PointsSystems,
          Power: item?.Powers,
          prizes: item?.PrizePayouts,
          paid_game: item?.is_game_paid,
          entry_fee: item?.entry_fee,
          currency: item?.currency,
          game_type: item?.game_type == "PowerdFS" ? "Fantasy_Challenge" : item?.game_type,
          powerdfs_challenge_amount: item?.powerdfs_challenge_amount,
          free_entry_options:item?.free_entry_options, 
          chase_prize_pool:item?.chase_the_ace_prize_pool,
          duration:item?.duration    
        },
      });
    } else {
      setHaveBalance(false)
      setShowDepositModal()
      onClose()
    }
  }

  const redirectToContestRules = () => {
    let item = props.item;
    return redirectTo(props.propss, {
      path: `/contest-rules`,
      state: {
        game_id: item?.game_id,
        game_type: item?.game_type,
        start_date: getLocalDateTime(item?.start_date, item?.start_time)?.date,
        game_set_start: getLocalDateTime(item?.game_set_start, item?.start_time)?.date,
        start_time: getLocalDateTime(item?.game_set_start, item?.start_time)?.time,
        end_date: getLocalDateTime(item?.end_date, item?.end_time)?.date,
        prizePool: _.reduce(
          item?.PrizePayouts,
          function (memo, num) {
            return memo + parseInt(num.amount) * parseInt(num.prize);
          },
          0
        ),
        topPrize: parseFloat(
          _.max(item?.PrizePayouts, function (ele) {
            return ele.amount;
          }).amount
        ),
        PointsSystem: item?.PointsSystems,
        Power: item?.Powers,
        prizes: item?.PrizePayouts,
        paid_game: item?.is_game_paid,
        entry_fee: item?.entry_fee,
        currency: item?.currency,
        league: item?.league,
        powerdfs_challenge_amount: item?.powerdfs_challenge_amount
      },
    });
  }

  const checkValidation = () => {
    if (freeEntryData.MLBTeam === "") {
      setIsValidated(false);
    }
    else if (freeEntryData.NBATeam === "") {
      setIsValidated(false);
    }
    else if (freeEntryData.NHLTeam === "") {
      setIsValidated(false);
    }
    else if (freeEntryData.NFLTeam === "") {
      setIsValidated(false);
    }
    else {
      setIsValidated(true);
    }
  };

  useEffect(() => {
    checkValidation();
  }, [freeEntryData]);

  useEffect(async () => {
    let a = await getTeamsList();
    if (a.status == 200) {
      setTeamData(a.data);
    }
  }, []);

  useEffect(() => {
    powersList();
    setGameStartDate(getLocalDateTime(item?.start_date, item?.start_time)?.date)
    setGameStartTime(getLocalDateTime(item?.start_date, item?.start_time)?.time)
  }, [item]);

  let totalPrizes = _.reduce(
    item?.PrizePayouts,
    function (memo, num) {
      return memo + parseInt(num.prize)
    },
    0
  )

  var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'Ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
  var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
  let n;

  function inWords(num) {
    if ((num = num.toString()).length > 9) return 'overflow';
    n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : '';
    return str;
  }
  // var app = app || {};
  // app.twitter = {
  //   share: function () {
  //     alert('clicked');

  //     window.twttr.ready(function (twttr) {
  //       twttr.events.bind('tweet', function (event) {
  //         console.log("twitter events",event);

  //         alert('published');
  //       });
  //     });

  //     var popup = window.open('https://twitter.com/intent/tweet?text=TEST', 'popupwindow', 'scrollbars=yes,width=800,height=400');

  //     popup.focus();
  //   }
  // };
  const numberWithCommas = (x) => {
    if (x >= 10000) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return x;
  };
  return (<>
    <Modal visible={visible}>
      <div className={classes.wrapper} style={{ width: 780, backgroundImage: isFreeEntryMode && userSurveySaved ? `` : `url(${getBG()})`, backgroundColor: "#242529", backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%;', borderRadius: 20, paddingBottom: 20 }}>
        <div className={classes.modal_body}>
         {!IsEssayMode?(<>
          {!isFreeEntryMode ? (
            <>
              <div className={classes.topButtons}>
                <div className={classes.leftButtons}>
                  <button
                    type="button"
                    onClick={() => {
                      setPrizeModalState(true)
                    }}
                  ><span>Prize Grid</span></button>
                  <ContestRulesPopUp
                    points={_.groupBy(
                      PointsSystems,
                      "type"
                    )}
                    powers={Powers}
                    component={({ showPopUp }) => (
                      <button
                        type="button"
                        onClick={showPopUp}
                      ><span>Game Rules</span></button>
                    )}
                    title={props?.item?.league}
                    game_type={props?.item?.game_type}
                  />
                  <RulesPopup
                    component={({ showPopUp }) => (
                      <button
                        type="button"
                        onClick={showPopUp}
                      ><span>Contest Rules</span></button>
                    )}
                    gameDetails={props?.item}
                  />
                </div>
                <div className={classes.closeButton}>
                  <CloseIcon onClick={onClose} />
                </div>
              </div>
              <div className={classes.title}>
                {league} <span>{game_type === "Fantasy_Chase_The_Ace"?"Chase The Ace":((game_type === "Fantasy_Promo" || game_type === "Fantasy_Challenge") && (league === "NHL" || league === "MLB") ? 'Fantasy' : 'PowerdFS')}</span> {game_type==="Fantasy_Chase_The_Ace"?"":(item.game_type == "PowerdFs_promo" ? "Promotional Contest" : game_type === "Fantasy_Promo" ? "Manager Challenge" : item?.powerdfs_challenge_amount + " Point Challenge")}
              </div>
              {!((game_type === "Fantasy_Promo" || game_type === "Fantasy_Challenge") && league === "NHL") &&
                <div className={classes.gamePrize}>
                  <span className={classes.prize}>
                  {game_type==="Fantasy_Chase_The_Ace"?<>${numberWithCommas(item?.chase_the_ace_prize_pool)}</>:<>
                    ${item?.PrizePayouts?.length > 0 ? parseFloat(
                    _.reduce(
                      item?.PrizePayouts,
                      function (memo, num) {
                        return (
                          parseFloat(memo) +
                          parseFloat(num.amount == "" ? 0 : num.amount) *
                          parseInt(num.prize == "" || num.prize == null ? 1 : num.prize)
                        );
                      },
                      0
                    )
                  ) : 0}</>}</span>
                  <span className={classes.giveaway}>Prize Pool</span>
                </div>
              }

              <div className={classes.rectangle} style={{
                textAlign: "center"
              }}>
                {((game_type.toLowerCase().includes("fantasy")) && (league === "NHL" || league === "MLB")) ? (
                  <>
                    {game_type==="Fantasy_Chase_The_Ace"?
                    <span className={classes.youHaveThePower}>
                      Finish in 1st place, pick the Ace and win
                    </span>:
                    <span className={classes.youHaveThePower}>
                      Manage your team to {game_type === "Fantasy_Challenge" ? `${powerdfs_challenge_amount} points ` : `victory`} and win
                    </span>}
                    <br />
                    <span className={classes.prize} style={{
                      fontSize: 48,
                      fontWeight: "bold"
                    }}>
                      {game_type==="Fantasy_Chase_The_Ace"?<>${numberWithCommas(item?.chase_the_ace_prize_pool)}</>:<>
                      ${item?.PrizePayouts?.length > 0 ? parseFloat(
                      _.reduce(
                        item?.PrizePayouts,
                        function (memo, num) {
                          return (
                            parseFloat(memo) +
                            parseFloat(num.amount == "" ? 0 : num.amount) *
                            parseInt(num.prize == "" || num.prize == null ? 1 : num.prize)
                          );
                        },
                        0
                      )
                    ) : 0}</>}</span>
                  </>
                ) : (
                  <>
                    <span className={classes.youHaveThePower}>
                      You have the Powers to win!
                    </span>
                    <div className={classes.powers}>
                      {typeof getPowers[0] !== "undefined" &&
                        <div className={classes.power}>
                          {getPowers[0].icon}
                          <span>{getPowers[0].powerName}</span>
                          <span className={classes.orange}> x{getPowers[0].amount}</span>
                        </div>
                      }
                      {typeof getPowers[1] !== "undefined" &&
                        <div className={classes.power}>
                          {getPowers[1].icon}
                          <span>{getPowers[1].powerName}</span>
                          <span className={classes.orange}> x{getPowers[1].amount}</span>
                        </div>
                      }
                    </div>
                    <div className={`${classes.powers} ${classes.margin}`}>
                      {typeof getPowers[2] !== "undefined" &&
                        <div className={classes.power}>
                          {getPowers[2].icon}
                          <span>{getPowers[2].powerName}</span>
                          <span className={classes.orange}> x{getPowers[2].amount}</span>
                        </div>
                      }
                      {typeof getPowers[3] !== "undefined" &&
                        <div className={classes.power}>
                          {getPowers[3].icon}
                          <span>{getPowers[3].powerName}</span>
                          <span className={classes.orange}> x{getPowers[3].amount}</span>
                        </div>
                      }
                    </div>
                    <div className={`${classes.powers} ${classes.margin}`}>

                      {typeof getPowers[4] !== "undefined" &&
                        <div className={classes.power}>
                          {getPowers[4].icon}
                          <span>{getPowers[4].powerName}</span>
                          <span className={classes.orange}> x{getPowers[4].amount}</span>
                        </div>
                      }
                      {typeof getPowers[5] !== "undefined" &&
                        <div className={classes.power}>
                          {getPowers[5].icon}
                          <span>{getPowers[5].powerName}</span>
                          <span className={classes.orange}> x{getPowers[5].amount}</span>
                        </div>
                      }
                    </div>
                  </>
                )}
              </div>
              <div className={classes.extraDesc}>
                <p>No purchase necessary. Contest entry closes at <span>{gameStartDate + " " + gameStartTime} ET.</span></p>
                <p>Open to residents of Canada (excluding Quebec) and United States who are over the age of majority.</p>
              </div>
              <div className={classes.rules}>
                <ul style={{ listStyle: "none" }}>
                  {item.game_type == "PowerdFs_promo" &&
                    <li><div className={classes.Oval}></div>Twenty (20) prizes to be won. See full rules for complete details of all prizes.</li>
                  }
                  {item.game_type == "PowerdFs_challenge" &&
                    <li><div className={classes.Oval}></div>One (1) prize to be won. If there are multiple winners, the prize will be evenly devided. See full contest rules for complete details.</li>
                  }
                  {(game_type === "Fantasy_Promo" || game_type === "Fantasy_Challenge") &&
                    <li><div className={classes.Oval}></div>
                      {game_type === "Fantasy_Challenge" ? "One (1) prize to be won. See full rules for complete details."
                        : `${inWords(totalPrizes)} (${totalPrizes}) prizes to be won. See full rules for complete details of all prizes.`}
                    </li>
                  }
                  <li><div className={classes.Oval}></div>One entry per person.</li>
                  <li><div className={classes.Oval}></div>
                    {game_type==="Fantasy_Chase_The_Ace"?`Odds of winning depend on the number of entries, participants fantasy sports knowledge, and the number of cards remaining.`:
                    game_type.toLowerCase().includes("fantasy")? "Odds of winning depend on the number of entries and participants fantasy sports knowledge."
                      : `Odds of winning depend on the number of participants and use of Powers.`}
                  </li>
                </ul>
              </div>
              <div className={classes.bottomButton}>
                <button onClick={redirectToUrl}>
                  ${props?.item?.entry_fee ? props?.item?.entry_fee : 0}  •  {((game_type === "Fantasy_Promo" || game_type === "Fantasy_Challenge"||game_type==="Fantasy_Chase_The_Ace") && (league === "NHL" || league === "MLB")) ? "Enter & Get 5 Bonus PWRS!" : "Enter & Unlock Powers!"}
                </button>
              </div>
              <span className={classes.freeEntry} onClick={() => {
                if (item?.free_entry_options === 'Fav Sport Survey') {
                  setFreeEntryData({
                    MLBTeam: "",
                    NHLTeam: "",
                    NFLTeam: "",
                    NBATeam: ""
                  });
                  setIsValidated(false);
                  setIsFreeEntryMode(true);
                } else if (item?.free_entry_options === "Social Media") {
                  setSocialMediaMode(true);
                  setIsFreeEntryMode(true);
                }else if (item?.free_entry_options === "Essay") {
                  setSocialMediaMode(true);
                  setIsEssayMode(true);
                }
                 else {
                  setFreeEntryData({
                    MLBTeam: "",
                    NHLTeam: "",
                    NFLTeam: "",
                    NBATeam: ""
                  });
                  setIsValidated(false);
                  setIsFreeEntryMode(true);
                }
              }}>Free Entry</span>
            </>
          )
            : isSocialMediaMode ? (<>
              <div className={classes.topButtonsFreeModal}>
                <div onClick={() => {
                  setIsFreeEntryMode(false);
                  setSocialMediaMode(false);
                }}>
                  {"< Back"}
                </div>
                <div className={classes.closeButtonFree}>
                  <CloseIcon onClick={() => setIsFreeEntryMode(false)} />
                </div>
              </div>
              <div className={classes.modalForm}>
                <div className={classes.entryModalTitle}>
                  <h2>Enter for Free!</h2>
                  <p>Just share on Social Media and you are in!</p>
                </div>
                <div className={classes.entryModalButton}>
                  {/* {console.log("window.location.hostname => ", window.location.hostname)} */}
                  {
                    (window.location.hostname === "poweredfs.com") ?
                      <button className={classes.entryButton}
                        onClick={() => {

                          var left = window.screen.width / 2 - 600 / 2,
                            top = window.screen.height / 2 - 600 / 2;
                          window.open(
                            `https://www.facebook.com/dialog/share?app_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&display=popup&href=https://defygames.io/&quote=${SOCIAL_MEDIA_SHARING_TEXT}&redirect_uri=https://defygames.io/power-center?fb=1_game_${game_id}_`,
                            "targetWindow",
                            "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600,left=" +
                            left +
                            ",top=" +
                            top
                          );
                          // window.open(
                          //   `https://www.facebook.com/dialog/share?app_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&display=popup&href=https://defygames.io/&quote=${SOCIAL_MEDIA_SHARING_TEXT}&redirect_uri=https://defygames.io/power-center?fb=1_game_${game_id}_`,
                          //   "targetWindow",
                          //   "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600,left=" +
                          //   left +
                          //   ",top=" +
                          //   top
                          // );
                        }}
                      // onClick={()=>redirectToUrl("free")}
                      >
                        <img src={fbicon} /> {"Share on Facebook"}
                      </button> :
                      <button className={classes.entryButton}
                        onClick={() => {

                          var left = window.screen.width / 2 - 600 / 2,
                            top = window.screen.height / 2 - 600 / 2;
                          window.open(
                            `https://www.facebook.com/dialog/share?app_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&display=popup&href=http://test.powerplaysystems.com&quote=${SOCIAL_MEDIA_SHARING_TEXT}&redirect_uri=https://test.powerplaysystems.com/power-center?fb=1_game_${game_id}_`,
                            "targetWindow",
                            "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600,left=" +
                            left +
                            ",top=" +
                            top
                          );
                          // window.open(
                          //   `https://www.facebook.com/dialog/share?app_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&display=popup&href=http://test.powerplaysystems.com&quote=${SOCIAL_MEDIA_SHARING_TEXT}&redirect_uri=https://test.powerplaysystems.com/power-center?fb=1_game_${game_id}_`,
                          //   "targetWindow",
                          //   "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600,left=" +
                          //   left +
                          //   ",top=" +
                          //   top
                          // );
                        }}
                      // onClick={()=>redirectToUrl("free")}
                      >
                        <img src={fbicon} /> {"Share on Facebook"}
                      </button>
                  }
                  {/* {
                    (window.location.hostname === "defygames.io") ?
                      <button className={classes.entryButton}
                        //onClick={() => redirectToUrl("free")}
                        onClick={() => {
                          var left = window.screen.width / 2 - 600 / 2,
                            top = window.screen.height / 2 - 600 / 2;
                          window.open(
                            `https://twitter.com/intent/tweet?text=${SOCIAL_MEDIA_SHARING_TEXT}&redirect_uri=https://defygames.io//power-center?fb=1_game_${game_id}_`,
                            "targetWindow",
                            "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600,left=" +
                            left +
                            ",top=" +
                            top
                          );
                          // setTempTwitter(true)
                          let gameArray = JSON.parse(localStorage.getItem("freeEntryGameIDs"))
                          if (!gameArray.includes(game_id.toString())) {
                            gameArray.push(game_id.toString())
                            localStorage.setItem("freeEntryGameIDs", JSON.stringify(gameArray))
                          }
                        }}
                      >
                        <img src={twiticon} /> {"Share on Twitter"}
                      </button> :
                      <button className={classes.entryButton}
                        // onClick={() => app.twitter.share()}
                        onClick={() => {
                          var left = window.screen.width / 2 - 600 / 2,
                            top = window.screen.height / 2 - 600 / 2;
                          window.open(
                            `https://twitter.com/intent/tweet?text=${SOCIAL_MEDIA_SHARING_TEXT}&callback_url=https://test.powerplaysystems.com/power-center?fb=1_game_${game_id}_`,
                            "targetWindow",
                            "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600,left=" +
                            left +
                            ",top=" +
                            top
                          );
                          // setTempTwitter(true)
                          let gameArray = JSON.parse(localStorage.getItem("freeEntryGameIDs"))
                          if (!gameArray.includes(game_id.toString())) {
                            gameArray.push(game_id.toString())
                            localStorage.setItem("freeEntryGameIDs", JSON.stringify(gameArray))
                          }
                        }}
                      >
                        <img src={twiticon} /> {"Share on Twitter"}
                      </button>
                  } */}

                </div>

                <div className={classes.entrybottomButtons}>
                  <button className={classes.backButton} onClick={() => {
                    setIsFreeEntryMode(false);
                    setSocialMediaMode(false);
                  }}>
                    {"< Back"}
                  </button>
                  {console.log("game_id =======>", game_id)}
                  {console.log("freeEntryGameIDs", freeEntryGameIDs)}
                  {console.log("freeEntryGameIDs.includes(game_id)", freeEntryGameIDs.includes(game_id.toString()))}
                  <button disabled={!freeEntryGameIDs.includes(game_id.toString())} className={classes.entryButton} onClick={() => redirectToUrl("free")}>
                    {"Free Entry"}
                  </button>
                </div>
              </div>
            </>) :
              (

                userSurveySaved ? (
                  <>

                    <div className={classes.topButtonsFree}>
                      <div className={classes.closeButton}>
                        <CloseIcon onClick={() => setIsFreeEntryMode(false)} />
                      </div>
                    </div>
                    <div className={classes.modalForm}>
                      {adImageNHL?.length > 0 ? (
                        <div className={classes.right_img}>
                          <img src={require(`../../assets/surveyTeam/${adImageNHL[0].shortName}.png`).default} alt="survey Ad"
                            width="780px" style={{ marginLeft: '-40px', marginTop: '30px' }} />
                          <div onClick={() => window.open(adImageNHL[0].trackingLink, "_blank")}>
                            <button className={classes.shopButton}>Shop Now!</button>
                          </div>

                        </div>
                      ) : (
                        <>
                          <img src={surveyAd} alt="survey Ad" width="780px" style={{ marginLeft: '-40px', marginTop: '30px' }}></img>
                          <a href="https://fanatics.93n6tx.net/gbL40g" target="_blank">
                            {/* <div onClick={()=> window.open(image[0].trackingLink, "_blank")}> */}
                            <button className={classes.shopButton}>Shop Now!</button>
                            {/* </div> */}

                          </a>
                        </>
                      )}
                      <div className={classes.bottomButtons}>
                        <button className={classes.backButton} onClick={() => setIsFreeEntryMode(false)}>
                          {"< Back"}
                        </button>
                        <button className={classes.entryButton} onClick={() => redirectToUrl("free")}>
                          {"Free Entry"}
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.topButtonsFree}>
                      <div className={classes.closeButton}>
                        <CloseIcon onClick={() => setIsFreeEntryMode(false)} />
                      </div>
                    </div>
                    <div className={classes.modalHeading}>
                      <p>Please complete the free entry survey below:</p>
                    </div>
                    <div className={classes.modalForm}>
                      <div className={classes.formElem}>
                        <p>
                          What MLB team do you cheer for?
                        </p>
                        <select className="text-black" onChange={(e) => {
                          setFreeEntryData(prevState => {
                            return {
                              ...prevState,
                              MLBTeam: e.target.value
                            }
                          });
                        }}>
                          <option value="">Select your MLB team</option>
                          {teamData.mlb_teams && teamData.mlb_teams.map((item, index) => {
                            return (
                              <option className="promo_options" value={`${item.team_id}`}>{item.name}</option>
                            )
                          })}
                        </select>
                      </div>

                      <div className={classes.formElem}>
                        <p>
                          What NFL team do you cheer for?
                        </p>
                        <select className="text-black" onChange={(e) => {
                          setFreeEntryData(prevState => {
                            return {
                              ...prevState,
                              NFLTeam: e.target.value
                            }
                          });
                        }}>
                          <option value="">Select your NFL team</option>
                          {teamData.nfl_teams && teamData.nfl_teams.map((item, index) => {
                            return (
                              <option value={`${item.team_id}`}>{item.name}</option>
                            )
                          })}
                        </select>
                      </div>
                      {console.log("teamData==>", teamData)}
                      <div className={classes.formElem}>
                        <p>
                          What NBA team do you cheer for?
                        </p>
                        <select className="text-black" onChange={(e) => {
                          setFreeEntryData(prevState => {
                            return {
                              ...prevState,
                              NBATeam: e.target.value
                            }
                          });
                        }}>
                          <option value="">Select your NBA team</option>
                          {teamData?.nba_teams && teamData?.nba_teams.map((item, index) => {
                            return (
                              <option value={`${item.team_id}`}>{item.name}</option>
                            )
                          })}
                        </select>
                      </div>

                      <div className={classes.formElem}>
                        <p>
                          What NHL team do you cheer for?
                        </p>
                        <select className="text-black" onChange={(e) => {
                          setFreeEntryData(prevState => {
                            return {
                              ...prevState,
                              NHLTeam: e.target.value
                            }
                          });
                        }}>
                          <option value="">Select your NHL team</option>
                          {teamData.nhl_teams && teamData.nhl_teams.map((item, index) => {
                            return (
                              <option value={`${item.team_id}`}>{item.name}</option>
                            )
                          })}
                        </select>
                      </div>

                      <div className={classes.bottomButtons}>
                        <button className={classes.backButton} onClick={() => setIsFreeEntryMode(false)}>
                          {"< Back"}
                        </button>
                        <button className={classes.entryButton} disabled={!isValidated} onClick={async () => {
                          const user_id = getLocalStorage("PERSONA_USER_ID");
                          let payload = {
                            "user_id": parseInt(user_id),
                            "mlb_team_id": parseInt(freeEntryData.MLBTeam),
                            "nhl_team_id": parseInt(freeEntryData.NHLTeam),
                            "nfl_team_id": parseInt(freeEntryData.NFLTeam),
                            "nba_team_id": parseInt(freeEntryData.NBATeam)
                          }
                          let res = await saveFreeEntry(payload);
                          if (res.status === 200) {
                            redirectToUrl("free");
                          }
                        }}>
                          {"Free Entry"}
                        </button>
                      </div>
                    </div>
                  </>
                )
              )
          }
          </>):(<>
            <>

                    <div className={classes.topButtonsFree}>
                      <div className={classes.closeButton}>
                        <CloseIcon onClick={() => setIsEssayMode(false)} />
                      </div>
                    </div>
                    <div className={classes.modalForm}>
                   
                        <>
                        <div className={classes.modalContentWrp}>
                          <h2>Get your free entry code!</h2>
                          <span>For your free entry code, write an <p>original 200 word essay</p> on one of the following topics:</span>
                          <ul>
                            <li><em>1.</em>Does fighting belong in hockey? Is the game better off without it?</li>
                            <li><em>2.</em>Propose an alternate scoring system for the NHL and explain the benefits of said scoring system.</li>
                            <li><em>3.</em>Baseball should implement a hard salary cap. Discuss.</li>
                            <li><em>4.</em>The CFL should adopt NFL rules and become a NFL minor league system. Discuss.</li>
                            <li><em>5.</em>Tell us about your favorite athletes and what about them inspires you.</li>
                          </ul>
                          <label>Email submissions to <a href="mailto:freeentry@defygames.io">freeentry@defygames.io</a>, we will do a quick plagiarism check and if all is good, we will email you <p>a code for free entry.</p></label>
                          <span>Submissions must be received <p>at least 4 hours before</p> the posted contest start time.</span>
                          <input type="text" placeholder="Enter Code" value={getEntryCode} onChange={(e)=>setEntryCode(e.target.value)}/>
                        </div>
                        </>
                      
                      <div className={classes.bottomButtonsEssay}>
                        <button className={classes.backButtonEssay} onClick={() => setIsEssayMode(false)}>
                          {"< Back"}
                        </button>
                        <button className={classes.entryButtonEssay} onClick={() => checkFreeEntryCode()} disabled={!getEntryCode}>
                          {"Free Entry"}
                        </button>
                      </div>
                    </div>
                    {verifyEntryCode==="notMatch"&&(
                      <>
                        <Modal visible={true}>
                          <div className={classes.codegeneratemodal}>
                            <h1>Wrong code entered</h1>
                            <p>Please check the code and try again </p>
                            <div className={classes.codegeneratebuttonwrp}>
                            <button className={classes.codegeneratebutton} onClick={() => setVerifyEntryCode("")}>
                              {"OK"}
                            </button>
                          </div>
                          </div>
                          
                          
                      </Modal>
                      </>
                    )}
                  </>
          </>)}
        </div>
      </div>
    </Modal>
    <PrizeModal
      chase_prize_pool={chase_the_ace_prize_pool}
      gameType={game_type}
      visible={showPrizeModal}
      sportsName={props?.item?.league}
      data={props?.item?.PrizePayouts}
      onClose={() => setPrizeModalState(false)}
    />
    {/* //isSocialMediaMode */}
    {/* <FreeEntryModal
      visible={showFreeEntryModal}
      onClose={onCloseFreeEntryModal}
    /> */}
  </>
  );
}

PromoModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func
};

export default PromoModal;
