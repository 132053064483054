import { useEffect, useState, Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';
import { newMLB } from "../../config/server_connection";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import TeamManagerCard from './TeaamManagerCard';
import TeamManagerHeader from './TeamManagerHeader';
import Footer from "../../components/Footer/Footer";
import SidebarNew from "../../components/SideBarNew";
import * as TeamManagerActions from "../../actions/teamManagerActions";
import classes from './styles.module.scss';
import { CONSTANTS } from "../../utility/constants";
import NHLLiveSportsHeader from "../../components/NHLLiveSportsHeader";
import { redirectTo } from "../../utility/shared";
import TeamManagerCardList from './TeamManagerCardList';
import footerLogo from '../../assets/images/bitmap.png';
import ScoreBoard from './ScoreBoard';
import * as NHLActions from "../../actions/NHLActions";
import { getLocalStorage, printLog, getTeamFromLocalStorage } from "../../utility/shared";
import TeamManagerMobileMLB from './Mobile/TeamManagerMobileMLB';
import TeamManagerMobile from './Mobile/TeamManagerMobile';
import * as CryptoJS from "crypto-js";
import { nhlData, mlbData, selectedTeamData, nbaData, nfaData } from './NHLData';
import { getMLBApiURL } from '../../config/environment';
import http from '../../config/http';
import Card from "../../components/PowerpickCard";

let SET_MLB_API_SERVER_URL = getMLBApiURL()

let newSocket = null;
const TeamManager = (props) => {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ query: '(max-width: 1024)' });
  const [compressedView, setCompressedView] = useState(false);
  const [selectedView, setSelectedView] = useState(CONSTANTS.NHL_VIEW.FV);
  const [activeTab, setActiveTab] = useState(0);
  // const selectedTeam = selectedTeamData;
  const [fantasyPlayers, setFantasyPlayers] = useState([]);
  const [fantasyTeam, setFantasyTeam] = useState({});
  const [getAllgames, setAllgames] = useState([]);
  // const {
  //   selectedTeam = {},
  // } = useSelector((state) => state.mlb);
  const [screenSize, setScreenSize] = useState(window.screen.width);
  const { playersPoints = {} } = useSelector((state) => state.teamManager);

  function getTeamFromLocalStorage() {
    const encData = getLocalStorage(CONSTANTS.LOCAL_STORAGE_KEYS.MLB_LIVE_GAME);
    const byteData = CryptoJS.AES.decrypt(encData, CONSTANTS.DATA_ENC_KEY);
    const decSelectedTeamData = JSON.parse(
      byteData.toString(CryptoJS.enc.Utf8)
    );
    return decSelectedTeamData;
  }
  const selectedTeam = getTeamFromLocalStorage()
  const { gameType, challenge_amount } = selectedTeam
  const {
    positionP = 0,
    positionC = 0,
    positionSS = 0,
    positionXB1 = 0,
    positionXB2 = 0,
    positionOF1 = 0,
    positionOF2 = 0,
    teamDPoints = 0,
    total_points = positionP +
    positionC +
    positionSS +
    positionXB1 +
    positionXB2 +
    positionOF1 +
    positionOF2 +
    teamDPoints
  } = playersPoints;
  window.onresize = () => {
    setScreenSize(window.screen.width);
  };

  const {
    league = ""
  } = useSelector((state) => state.teamManager);
  const handleChangeTab = () => {
    setActiveTab(activeTab === 0 ? 1 : 0);
  };

  let userID = localStorage.getItem("PERSONA_USER_ID")
  let gameid = parseInt(getLocalStorage(CONSTANTS.LOCAL_STORAGE_KEYS.MLB_LIVE_GAME_ID))
  useEffect(() => {
    async function getData() {
      if(props.match.params.league.toLowerCase() === 'mlb'){

        if (gameid !== 0) {
          newSocket = await newMLB();
          // console.log("=====>",newSocket);

          if (gameid !== undefined && userID !== undefined) {
            // newSocket.send(JSON.stringify({gameID:gid,userID:userID}));
            newSocket.onopen = (event) => {
              // newSocket.send(JSON.stringify({ deleteConnection:false,gameID: gid, userID: userID }));
              // console.log('socket opened',event)
              if (event)
                newSocket.send(JSON.stringify({ gameId: gameid, userId: userID }));
            };
            
          }
          newSocket.onmessage = (event) => {
            if (event?.data[0] == '{') {
              // console.log("live events TEST =>", JSON.parse(event?.data));
            if (event?.data[0] == '{') {
              console.log("live events TEST =>", JSON.parse(event?.data));
              // if (JSON.parse(event?.data).type == "MatchEvents") {
              //   // console.log("live event==>",JSON.parse(eventData).length)
              //   // if((Object.keys(event?.data).length)>1)
              //   dispatch({
              //     type: NHLActions.NHL_UPDATE_STATE,
              //     payload: {
              //       setNhlEventData: JSON.parse(event?.data),
              //     },
              //   });
              // } 
              if (JSON.parse(event?.data).type == "TeamData") {
                dispatch({
                  type: CONSTANTS.TEAM_MANAGER.ACTIONTYPES.UPDATE_FULL_DATA,
                  payload: {
                    playersPoints:JSON.parse(event?.data).team
                  }
              });
              } if (JSON.parse(event?.data).type == "MatchStatus") {
                dispatch({
                  type: CONSTANTS.TEAM_MANAGER.ACTIONTYPES.UPDATE_FULL_DATA,
                  payload: {
                    matchStatus:JSON.parse(event?.data).MatchStatus
                  }
              });            
            }
            if (JSON.parse(event?.data).type == "MatchUpdates") {
              dispatch({
                type: CONSTANTS.TEAM_MANAGER.ACTIONTYPES.UPDATE_FULL_DATA,
                payload: {
                  liveMatchUpdates:JSON.parse(event?.data)
                }
            });            
          }
              // if (JSON.parse(event?.data).type == "PlayersOnIce") {
              //   dispatch({
              //     type: NHLActions.NHL_UPDATE_STATE,
              //     payload: {
              //       live_eventData: JSON.parse(event?.data)?.data,
              //     },
              //   });
              // }
            };
            }
          }
        }
      }
    }
    getData();

  }, [dispatch, userID, gameid]);


  useEffect(() => {
    if(props.match.params.league.toLowerCase() === 'mlb'){
      newSocket = newMLB();
      const unloadCallback = (event) => {
        newSocket.send(JSON.stringify({ deleteConnection: true, gameID: gameid, userID: userID }))
        newSocket.close();

        return "";
      };
      window.addEventListener("beforeunload", unloadCallback);
      return () => {
        newSocket.send(JSON.stringify({ deleteConnection: true, gameID: gameid, userID: userID }))
        newSocket.close();
        window.removeEventListener("beforeunload", unloadCallback);
      }
    }
  }, []);

  useEffect(() => {
    async function getData() {
      dispatch({
        type: CONSTANTS.TEAM_MANAGER.ACTIONTYPES.UPDATE_FULL_DATA,
        payload: {
          playersPoints: []
        }
    });
    if(props.match.params.league.toLowerCase() === 'mlb'){
      newSocket = newMLB();
    }

    let payload = {
      gameID: 1543,
      userID: localStorage.getItem("PERSONA_USER_ID")
    };
    var data = {};
    if (props.match.params.league && props.match.params.league.toLowerCase() === 'nhl') {
      data = nhlData;
    }
    else if (props.match.params.league && props.match.params.league.toLowerCase() === 'mlb') {
      data = mlbData;
    }
    else if (props.match.params.league && props.match.params.league.toLowerCase() === 'nba') {
      data = nfaData;
    }
    else {
      data = nfaData;
    }
    // console.log("game id", parseInt(getLocalStorage(
    //   CONSTANTS.LOCAL_STORAGE_KEYS.MLB_LIVE_GAME_ID
    // )));
     
    let userID=localStorage.getItem("PERSONA_USER_ID")
    if(props.match.params.league.toLowerCase() === 'mlb'){
      let gid=parseInt(getLocalStorage(CONSTANTS.LOCAL_STORAGE_KEYS.MLB_LIVE_GAME_ID))
      const response=await http.get(`${SET_MLB_API_SERVER_URL}/mlb/api/v1/team/get-fantasy-team?gameId=${gid}&userId=${userID}`)
      await dispatch(TeamManagerActions.updateTeamManagerDataDummy(gid, userID, response?.data));

      const responseplayers=await http.get(`${SET_MLB_API_SERVER_URL}/mlb/api/v1/games/get-fantasy-game-players?gameId=${gid}`)
      if(responseplayers.status === 200) {
        setFantasyPlayers(responseplayers?.data?.players);
        setFantasyTeam(responseplayers?.data?.teams)
        setAllgames(responseplayers?.data?.games)
      }
    }
    else if(props.match.params.league.toLowerCase() === "nfl"){
      await dispatch(TeamManagerActions.updateTeamManagerDataDummy(1563, userID, data));
    }
    else if(props.match.params.league.toLowerCase() === "nba"){
      await dispatch(TeamManagerActions.updateTeamManagerDataDummy(1563, userID, data));
    }
  }
    getData()
  }, []);


  const setView = (viewType = CONSTANTS.NHL_VIEW.FV) => {
    switch (viewType) {
      case CONSTANTS.NHL_VIEW.FV:
        setCompressedView(false);
        break;
      case CONSTANTS.NHL_VIEW.C:
        setCompressedView(true);
        break;
      case CONSTANTS.NHL_VIEW.S:
        break;
    }
    setSelectedView(viewType);
  };

  const getSubtitle = (gameType) => {
    if(gameType === "Fantasy_Chase_The_Ace"){
      return "Prize Pool increases until the Ace is picked!"
    }
    else {
      return "Power your team to victory and win big!"
    }
  }

  return (
    <Fragment>
      {screenSize < 550 ? (
        props.match.params.league.toLowerCase() === 'mlb' ? 
        <TeamManagerMobileMLB 
        isMlb={true}
        league={props.match.params.league} 
        selectedView={selectedView} 
        selectedTeam={selectedTeam} 
        onPowerApplied={() => { }} 
        fantasyPlayers={fantasyPlayers} 
        getAllgames={getAllgames}
        fantasyTeam={fantasyTeam}
        /> : <TeamManagerMobile 
        />
      ) : (
        <>
          <Header />
          <TeamManagerHeader
            challenge_amount={challenge_amount}
            league={props.match.params.league}
            gameType={props.match.params.league==="MLB"?gameType:"Fantasy"}
            subTitle={getSubtitle(gameType)}
            selectedTeam={selectedTeam}
          />
          <div className={classes.container}>
            <div className={classes.container_left_side}>
              <NHLLiveSportsHeader
                btnTitle1="Full View"
                btnTitle2="Compressed"
                btnTitle3="Single"
                selectedView={selectedView}
                onFullView={() => setView(CONSTANTS.NHL_VIEW.FV)}
                onCompressedView={() => setView(CONSTANTS.NHL_VIEW.C)}
                onSingleView={() => setView(CONSTANTS.NHL_VIEW.S)}
                activeTab={activeTab}
                handleChangeTab={handleChangeTab}
                // teamManagerLink="/nhl-live-powerdfs"
                // scoreDetailLink="/nhl-live-powerdfs/my-score-details"
                onGoBack={() => {
                  redirectTo(props, { path: "/my-game-center" });
                }}
                state={{}}
                {...props}
              />
              <Card ranks={{ score: total_points }}>

              {activeTab == 0 ? (
                <>
                  <TeamManagerCardList 
                  league={props.match.params.league} 
                  selectedView={selectedView} 
                  selectedTeam={selectedTeam} 
                  onPowerApplied={() => { }} 
                  fantasyPlayers={fantasyPlayers} 
                  getAllgames={getAllgames}
                  fantasyTeam={fantasyTeam}/>
                 
                </>
              ) : (
                <ScoreBoard />
              )}
            </Card>
              <div>
                <div
                  className={classes.left_side_disclaimer_text}
                >
                  Live data disclaimer: While we endeavor to ensure that live scores and other event information are correct, we assume no liability for any delay, error or other inaccuracy of such data.
                </div>
                <section className={classes.footer}>
                  {/* <img src={footerLogo} /> */}
                </section>
                <div className={classes.brandImage}>
                  <a href="https://fanatics.93n6tx.net/c/2068372/784121/9663" target="_top" id="784121">
                    <img src="//a.impactradius-go.com/display-ad/9663-784121" border="0" alt="" width="728" height="90" />
                  </a>
                </div>
              </div>
            </div>

            {!isMobile && <div className={classes.sidebar_container}>
              <SidebarNew
                league={props.match.params.league}
                isMlb={props.match.params.league.toLowerCase() === 'mlb'}
                selectedTeam={selectedTeam}
                gid={gameid}
              />
            </div>}


          </div>

          <Footer isBlack={true} />
        </>
      )}
    </Fragment>
  );
};
export default TeamManager;