import React from 'react';

const DiamondAce = props => (
<>
<g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
            <g transform="translate(0 1)">
                <path fill="#DF3439" d="M5.386 17.439c.674 1.36 1.658 2.543 2.871 3.455-1.211.908-2.195 2.085-2.871 3.44-.689-1.356-1.676-2.538-2.888-3.456 1.212-.918 2.2-2.1 2.888-3.455v.016zM67.452 77.218c.683 1.354 1.665 2.536 2.871 3.455-1.206.92-2.188 2.101-2.871 3.455-.683-1.354-1.665-2.536-2.871-3.455 1.206-.92 2.188-2.1 2.871-3.455zM36.11 43.102c1.714 3.297 4.168 6.151 7.17 8.339-2.998 2.191-5.452 5.044-7.17 8.338-1.74-3.288-4.202-6.139-7.202-8.338 3.004-2.196 5.467-5.048 7.203-8.339zM7.592 14.795L5.97 5.954H4.867l-1.623 8.84h-.746v.925h2.206v-.924h-.648l.26-1.363h2.173l.26 1.363h-.714v.924H8.24v-.924h-.649zm-3.066-2.369l.876-4.688.876 4.688H4.526zM65.23 86.789l1.622 8.825h1.103l1.622-8.825h.681v-.941h-2.125v.94h.633l-.243 1.347h-2.158l-.243-1.346h.665v-.941H64.58v.94h.649zm3.082 2.433l-.876 4.688-.925-4.688h1.8z"/>
            </g>
        </g>
    </g>
</>
)

export default DiamondAce