import React from 'react';

const Document = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19" {...props}>
        <g fill="currentColor" fillRule="nonzero">
            <path d="M5.856 0C4.638 0 3.647.988 3.647 2.198l-.08 11.393 1.205.009.08-11.398c0-.546.45-.99 1.004-.99h12.11V0H5.857z" transform="translate(-525 -310) translate(0 80) translate(335 82) translate(136 135) translate(54 12) translate(0 1)" />
            <path d="M18.002 0c-1.305 0-2.366 1.057-2.366 2.357v13.087c0 .946-.772 1.716-1.722 1.716-.949 0-1.722-.77-1.722-1.716v-1.51c0-.342-.278-.62-.622-.62H.99c-.345 0-.623.278-.623.62v1.51c0 1.63 1.33 2.956 2.966 2.956v-1.24c-.95 0-1.722-.77-1.722-1.716v-.89h9.337v.89c0 1.63 1.33 2.956 2.966 2.956 1.636 0 2.967-1.326 2.967-2.956V5.747h2.863c.344 0 .623-.277.623-.62v-2.77c0-1.3-1.061-2.357-2.365-2.357zm1.12 4.507h-2.24v-2.15c0-.616.502-1.117 1.12-1.117.617 0 1.12.501 1.12 1.117v2.15z" transform="translate(-525 -310) translate(0 80) translate(335 82) translate(136 135) translate(54 12) translate(0 1)" />
            <path d="M3.567 16.8H13.967V18.400000000000002H3.567zM9.409 3.2H6.525c-.308 0-.558.358-.558.8 0 .442.25.8.558.8h2.884c.308 0 .558-.358.558-.8 0-.442-.25-.8-.558-.8zM13.36 5.6H6.573c-.335 0-.607.358-.607.8 0 .442.272.8.607.8h6.785c.336 0 .608-.358.608-.8 0-.442-.272-.8-.608-.8zM13.36 8H6.573c-.335 0-.607.358-.607.8 0 .442.272.8.607.8h6.785c.336 0 .608-.358.608-.8 0-.442-.272-.8-.608-.8zM13.36 10.4H6.573c-.335 0-.607.358-.607.8 0 .442.272.8.607.8h6.785c.336 0 .608-.358.608-.8 0-.442-.272-.8-.608-.8z" transform="translate(-525 -310) translate(0 80) translate(335 82) translate(136 135) translate(54 12) translate(0 1)" />
        </g>
    </svg>
)

export default Document;