import React from 'react';

const SuperBall = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={ props.size || "27" } height={ props.size || "27" } viewBox="0 0 27 27" {...props} style={{ height: 'auto'}}>
        <g fill="currentColor" fillRule="nonzero">
            <path d="M25.664 1.416l-.125-.331-.33-.125c-.113-.042-2.78-1.028-6.533-.956-2.205.042-4.331.444-6.32 1.194-2.484.938-4.754 2.423-6.745 4.413-1.99 1.991-3.475 4.26-4.413 6.746-.75 1.988-1.152 4.114-1.194 6.32-.073 3.75.914 6.42.956 6.531l.125.331.33.125c.109.04 2.595.96 6.138.96.13 0 .262-.001.395-.004 2.205-.042 4.331-.444 6.32-1.194 2.484-.938 4.754-2.423 6.745-4.413 1.99-1.991 3.475-4.26 4.413-6.746.75-1.987 1.152-4.114 1.194-6.32.073-3.75-.914-6.42-.956-6.531zm-.608 6.652c-.019.69-.075 1.37-.17 2.04l-8.369-8.37c.67-.094 1.35-.151 2.039-.17l6.5 6.5zm-.075-2.281l-4.143-4.143c1.657.156 2.907.489 3.479.664.176.57.51 1.818.664 3.479zM1.568 18.557c.019-.69.076-1.37.17-2.04l8.369 8.368c-.67.095-1.35.152-2.039.171l-6.5-6.5zm.075 2.28l4.143 4.143c-1.657-.156-2.908-.49-3.479-.664-.176-.57-.51-1.818-.664-3.479zm18.266-.928c-1.823 1.824-3.894 3.184-6.157 4.044-.587.223-1.19.412-1.803.569l-9.847-9.848c.153-.6.339-1.19.556-1.767.859-2.276 2.224-4.36 4.057-6.192 1.823-1.824 3.894-3.184 6.157-4.043.588-.224 1.19-.413 1.803-.57l9.847 9.848c-.153.6-.338 1.19-.556 1.767-.859 2.276-2.224 4.36-4.057 6.192z" transform="translate(-395 -1476) translate(363 1466) translate(32 10)" />
            <path d="M17.39 8.131L15.888 9.633 14.784 8.529 13.68 9.632 14.785 10.736 13.312 12.209 12.208 11.105 11.105 12.208 12.209 13.312 10.736 14.785 9.632 13.68 8.529 14.783 9.633 15.888 8.131 17.39 9.234 18.493 10.736 16.991 11.841 18.095 12.944 16.992 11.839 15.888 13.312 14.415 14.416 15.52 15.52 14.416 14.415 13.312 15.888 11.839 16.992 12.944 18.095 11.841 16.991 10.736 18.493 9.234z" transform="translate(-395 -1476) translate(363 1466) translate(32 10)" />
        </g>
    </svg>
)

export default SuperBall;