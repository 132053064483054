export async function getCaptchaToken(action) {
    return new Promise((resolve, reject) => {
        window.grecaptcha.enterprise.ready(async () => {
            try {
                const token = await window.grecaptcha.enterprise.execute('6LcLv6cfAAAAADaOEzj6T_8z1nBV404ZWa_H94VS', { action })
                resolve(token)
            } catch (error) {
                reject(error)
            }
        })
    })
}
